import React, { useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectSearchBox,
  Hits,
  connectCurrentRefinements,
  Configure,
} from 'react-instantsearch-dom';
import { Hit, connectRefinementList } from 'react-instantsearch-core';

import { algoliaConf as conf } from 'config';
import { Link, useHistory } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';
import { TemplateSearchResultType, UserType } from 'types/types';
import { Button, Popover, Space, Input, Tag } from 'antd';
import LoadingScreen from 'screens/LoadingScreen';
import { useTranslation } from 'react-i18next';
import DeleteTemplatePane from './DeleteTemplatePane';
import { useRefresh } from 'frameworks/algoliasearch/hooks';
import { isGlobalAdmin } from 'model/users';

const { Search } = Input;

const client = algoliasearch(conf.appId, conf.key);

const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <Search
      className="flex-grow"
      value={currentRefinement}
      style={{ fontSize: '16px', marginRight: '8px' }}
      enterButton
      onChange={(event) => refine(event.target.value)}
    />
  );
});

const AppAdminTemplateBrowseScreen = ({
  userId,
  userType,
}: {
  userId: string;
  userType: UserType;
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const refresh = useRefresh();

  const [deleteVisible, setDeleteVisible] = useState<Record<string, boolean>>(
    {}
  );

  const [deletedTemplates, setDeletedTemplates] = useState<Set<string>>(
    new Set()
  );

  const handleDeleteVisibleChange = (key: string) => (visible: boolean) => {
    setDeleteVisible({
      ...deleteVisible,
      [key]: visible,
    });
  };

  const RefinementComponent = ({ refine }: any) => {
    const Component = useMemo(
      () =>
        ({ hit }: { hit: Hit<TemplateSearchResultType> }) =>
          deletedTemplates.has(hit.templateId) ? null : (
            <SessionRow hit={hit} refine={refine} />
          ),
      [refine]
    );

    return <Hits hitComponent={Component} />;
  };

  const RefinementList = connectRefinementList(RefinementComponent);

  const SessionRow = ({
    hit,
    refine,
  }: {
    hit: Hit<TemplateSearchResultType>;
    refine: (value: string[]) => any;
  }) => {
    return (
      <div className="my-4 flex flex-col items-start space-y-4 rounded-2xl border border-surfaces-divider bg-white py-4 px-8">
        <Link className="font-semibold underline" to={`/${hit.accessCode}`}>
          {hit.name}
        </Link>
        {/* <div className="flex flex-row items-center space-x-2">
          <Button
            type="link"
            onClick={event => {
              event.preventDefault();
              refine([hit.community]);
            }}
          >
            {hit.community}
          </Button>
        </div> */}
        <Space>
          {/* <Popover
            content={
              <DuplicateSessionForm
                {...hit}
                close={() => handleDuplicateVisibleChange(hit.sessionId)(false)}
                onSubmit={async (title: string) => {
                  const [accessCode] = await duplicateSession(
                    hit.sessionId,
                    title,
                    userId
                  );
                  if (accessCode) {
                    history.push(
                      `/${accessCode}?modeRequested=editor&withSettings=true`
                    );
                  }
                }}
              />
            }
            title="Create a new WAP session"
            trigger="click"
            visible={duplicateVisible[hit.sessionId]}
            onVisibleChange={handleDuplicateVisibleChange(hit.sessionId)}
          >
            <Button>{t('common:Duplicate')}</Button>
          </Popover>
          <CopyButton
            textToCopy={`${domainUrl}/${hit.accessCode}`}
            title={t('common:CopyLink')}
          /> */}
          {isGlobalAdmin(userType) && (
            <Popover
              content={
                <DeleteTemplatePane
                  templateId={hit.templateId}
                  templateName={hit.name}
                  close={() => handleDeleteVisibleChange(hit.templateId)(false)}
                  confirm={() => {
                    setDeletedTemplates(
                      new Set([hit.templateId, ...deletedTemplates])
                    );
                  }}
                />
              }
              title={`Remove ${hit.name}`}
              trigger="click"
              visible={deleteVisible[hit.templateId]}
              onVisibleChange={handleDeleteVisibleChange(hit.templateId)}
            >
              <Button>{t('common:Delete')}</Button>
            </Popover>
          )}
          <Button onClick={() => history.push(`/template/${hit.accessCode}`)}>
            Edit Template Description
          </Button>
          <Button
            onClick={() =>
              history.push(`/${hit.accessCode}/dashboard/session_plan`)
            }
          >
            {t('sessions:peerLearningPlan')}
          </Button>
        </Space>
      </div>
    );
  };

  const CurrentRefinements = connectCurrentRefinements(({ items, refine }) => (
    <>
      {items.length > 0 ? (
        items.map((item) =>
          item.items ? (
            item.items.map((nested) => (
              <Tag
                key={item.label + item.attribute}
                closable={true}
                onClose={() => {
                  refine(nested.value);
                }}
              >
                {item.label}
                {nested.label}
              </Tag>
            ))
          ) : (
            <Tag
              key={item.attribute}
              closable={true}
              onClose={() => {
                refine(item.value);
              }}
            >
              {item.label}
            </Tag>
          )
        )
      ) : (
        <p className="italic">{t('misc:noAppliedFilters')}</p>
      )}
    </>
  ));

  const ClearRefinements = useMemo(
    () =>
      connectCurrentRefinements(({ items, refine }: any) => {
        return (
          <Button
            onClick={() => {
              refine(items);
            }}
          >
            {t('common:Clear')}
          </Button>
        );
      }),
    [t]
  );

  console.log(refresh);

  return isLoaded() ? (
    <div className="w-full">
      <InstantSearch
        searchClient={client}
        indexName={conf.templatesIndexName}
        refresh={refresh}
      >
        {!isGlobalAdmin(userType) && <Configure filters={[[]]} />}
        <div className="w-full">
          <SearchBox />
        </div>
        <div className="mt-2 flex w-full flex-row text-left">
          <p className="mr-1">{t('common:Filters')}</p>
          <CurrentRefinements />
        </div>
        <div className="flex-rpw mt-2 flex w-full">
          <div className="flex-grow" />
          <ClearRefinements clearsQuery />
        </div>
        <RefinementList attribute="community" />
      </InstantSearch>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default AppAdminTemplateBrowseScreen;
