import { useEffect, useState } from 'react';
import { useField } from 'formik';
import _ from 'lodash';

export const useOnChange = <T>(
  name: string,
  onChangeValue?: (value: T, hasError: boolean) => void
) => {
  const [lastValue, setLastValue] = useState<any>(undefined);
  const [lastError, setLastError] = useState<any>(undefined);
  const [isInitialised, setInitialised] = useState(false);
  const [field, meta] = useField<T>(name);

  const hasError = !!(meta.touched && meta.error);

  useEffect(() => {
    setLastValue(meta.initialValue);
    setLastError(!!(meta.initialTouched && meta.initialError));
    setInitialised(true);
  }, [meta.initialValue, meta.initialTouched, meta.initialError]);

  useEffect(() => {
    if (
      (!_.isEqual(field.value, lastValue) || hasError !== lastError) &&
      isInitialised
    ) {
      onChangeValue?.(field.value, hasError);
      setLastValue(field.value);
      setLastError(hasError);
    }
  }, [
    field.value,
    lastValue,
    hasError,
    lastError,
    isInitialised,
    onChangeValue,
  ]);
};
