import React from 'react';
import { useField } from 'formik';

import { useOnChange } from './hooks';
import DatetimePicker from 'components/DatetimePicker';

export type DatetimePickerInputProps = {
  name: string;
  label?: string;
  readOnly?: boolean;
  errorMode?: boolean;
  wrap?: boolean;
  onChangeValue?: (value: Date, hasError: boolean) => void;
};

const DatetimePickerInput = ({
  name,
  label,
  readOnly,
  errorMode,
  wrap,
  onChangeValue,
}: DatetimePickerInputProps): JSX.Element => {
  const [field, , helpers] = useField<Date>(name);

  useOnChange(name, onChangeValue);

  return (
    <div>
      {label ? (
        <label htmlFor={name} className="text-lg font-semibold">
          {label}
        </label>
      ) : null}
      <div className={`mt-2 w-full`}>
        <DatetimePicker
          value={field.value}
          onValueChange={(value) => helpers.setValue(value)}
          readOnly={readOnly}
          errorMode={errorMode}
          wrap={wrap}
        />
      </div>
    </div>
  );
};

export default DatetimePickerInput;
