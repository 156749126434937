import React, { useMemo } from 'react';
import _ from 'lodash';

export type AvatarSize = 'sm' | 'base' | 'lg' | 'xl';

export type AvatarProps = {
  name: string;
  size?: AvatarSize;
  bgColorClass?: string;
};

const sizeToFontSize: { [x in AvatarSize]: string } = {
  sm: 'text-xs',
  base: 'text-sm',
  lg: 'text-lg',
  xl: 'text-3xl',
};

const sizeToCircleSize: { [x in AvatarSize]: string } = {
  sm: 'w-6 h-6',
  base: 'w-8 h-8',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
};

const Avatar = ({
  name,
  size = 'base',
  bgColorClass,
}: AvatarProps): JSX.Element => {
  const [firstname = 'name', lastname = ''] = useMemo(
    () => (name && name.length > 0 ? name.split(' ') : ['Unknow', 'User']),
    [name]
  );

  return (
    <div
      className={`${
        sizeToCircleSize[size]
      } flex items-center justify-center rounded-full ${
        bgColorClass || 'bg-primary'
      }`}
    >
      <p className={`font-inter font-bold text-white ${sizeToFontSize[size]}`}>
        {`${_.trim(firstname).charAt(0).toUpperCase()}${
          size !== 'sm' ? _.trim(lastname).charAt(0).toUpperCase() : ''
        }`}
      </p>
    </div>
  );
};

export default Avatar;
