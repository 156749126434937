import React from 'react';
import { Wap } from 'assets';

export type LogoProps = { className?: string };

const Logo = ({ className = '' }: LogoProps): JSX.Element => {
  return (
    <a href="/home">
      <Wap className={`${className} fill-current text-primary`} />
    </a>
  );
};

export default Logo;
