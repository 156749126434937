import Avatar from 'components/Avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Users } from 'types/types';
import _ from 'lodash';

export type ParticipantListProps = {
  participants: Users;
  marginClasses: string;
};

const ParticipantsList = ({
  participants,
  marginClasses,
}: ParticipantListProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">
        {t('common:participants')} ({_.size(participants)})
      </h2>
      <div className="flex flex-row flex-wrap items-center justify-center gap-x-4 space-y-2">
        {Object.entries(participants).map(([userId, user]) => (
          <div key={userId} className="flex flex-row items-center space-x-1">
            <Avatar size="sm" name={user?.name || t('users:UnknownUser')} />
            <p>{(user?.name || t('users:UnknownUser')).split(' ')[0]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParticipantsList;
