import React from 'react';
import { useField } from 'formik';

import { useOnChange } from 'frameworks/formik/hooks';
import SelectorInput, { BaseSelectorProps } from 'components/SelectorInput';

export type SelectorProps<T extends object> = {
  name: string;
  label?: string;
  onChangeValue?: (value: string, hasError: boolean) => void;
} & BaseSelectorProps<T>;

const Selector = <T extends object>({
  name,
  options,
  label,
  descriptionKey,
  onChangeValue,
}: SelectorProps<T>): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  useOnChange(name, onChangeValue);

  return (
    <div className={'w-full'}>
      {label && (
        <label className="text-lg font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <SelectorInput
        value={field.value}
        onChange={(id) => {
          helpers.setValue(id);
        }}
        options={options}
        descriptionKey={descriptionKey}
      />
      {hasError ? (
        <div className="font-medium text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Selector;
