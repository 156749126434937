import React, { useState, useRef } from 'react';

import moment from 'moment';

import { PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import TitlePlan from 'componentsOld/ContentPlan/TitlePlan';

import { Button, Table, Space, Input, Popover, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Community } from 'types/types';
import { ColumnsType } from 'antd/lib/table';
import { WithId } from 'types/typesUtils';
import CreateCommunityForm from './CreateCommunityForm';
import MergeCommunityForm from './MergeCommunityForm';
import {
  changeCommunityName,
  setAdminsOfCommunity,
} from 'model/communitiesManagement';
import UsersSelector from 'componentsOld/UsersSelector';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const FacilitatorsForm = ({
  communityId,
}: {
  communityId: string;
}): JSX.Element => {
  const { t } = useTranslation();

  useFirebaseConnect([
    {
      path: `adminsOfCommunities/${communityId}`,
    },
  ]);

  const adminsOfCommunity: Record<string, true> = useSelector(
    (state: any) => state.firebase.data.adminsOfCommunities?.[communityId]
  );

  return (
    <div>
      <h4>{t('communities:CommunityAnimators')}</h4>
      {isLoaded(adminsOfCommunity) ? (
        <UsersSelector
          selected={adminsOfCommunity || {}}
          // filter={userId => usersOfCommunity?.[userId]}
          onChange={async (fac, old) =>
            setAdminsOfCommunity(communityId, fac, old)
          }
        />
      ) : (
        <LoadingOutlined spin />
      )}
    </div>
  );
};

const AppAdminSessionCommunitiesManagementScreen = (): JSX.Element => {
  type CommunityWithId = WithId<Community>;

  const [visible, setVisible] = useState<boolean>(false);
  const [mergeWithVisible, setMeregWithVisible] = useState<
    Record<string, boolean>
  >({});

  const searchInput = useRef<null | Input>(null);

  useFirebaseConnect([
    {
      path: `communities`,
    },
  ]);

  const communities: Record<string, Community> = useSelector(
    (state: any) => state.firebase.data.communities || {}
  );

  const communitiesArray: CommunityWithId[] = Object.entries(communities).map(
    ([id, community]) => ({
      ...community,
      id,
    })
  );

  const handleMergeWithVisibleChange = (key: string) => (visible: boolean) => {
    setMeregWithVisible({
      ...mergeWithVisible,
      [key]: visible,
    });
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const hide = () => setVisible(false);

  const columns: ColumnsType<CommunityWithId> = [
    {
      title: 'Name',
      dataIndex: 'name',
      // width: 400,
      sorter: (a: CommunityWithId, b: CommunityWithId) =>
        (a.name + '').localeCompare(b.name),
      ...getColumnSearchProps('name'),
      render: (_: any, record: CommunityWithId) => (
        <Link
          className="font-semibold underline"
          to={`/community/${record.id}`}
        >
          <Text
            editable={{
              onChange: (val) => changeCommunityName(record.id, val),
            }}
          >
            {record.name}
          </Text>
        </Link>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: (date: number) => moment(date).format('DD/MM/YYYY'),
      defaultSortOrder: 'descend',
      sorter: (a: CommunityWithId, b: CommunityWithId) => {
        return a.createdAt - b.createdAt;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: CommunityWithId) => (
        <Space>
          {/* <Link to={`/${record.code}/admin/details`} style={styles.link}>
              <Button>{`Details`}</Button>
            </Link> */}
          <Popover
            content={
              <MergeCommunityForm
                sourceCommunityId={record.id}
                communities={communities}
                close={() => handleMergeWithVisibleChange(record.id)(false)}
              />
            }
            title={`Merge ${record.name} with`}
            trigger="click"
            visible={mergeWithVisible[record.id]}
            onVisibleChange={handleMergeWithVisibleChange(record.id)}
          >
            <Button>{`Merge with`}</Button>
          </Popover>
        </Space>
      ),
    },
  ];

  const isLoading = !isLoaded(communities);
  return isLoading ? (
    <TitlePlan>{`Loading...`}</TitlePlan>
  ) : (
    <>
      <div className="mb-4 flex w-full flex-row">
        <div className="flex-grow" />
        <Popover
          content={<CreateCommunityForm close={hide} />}
          title="Create a new WAP community"
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Button type="primary" icon={<PlusCircleOutlined />}>
            Community
          </Button>
        </Popover>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={communitiesArray}
        expandable={{
          expandedRowRender: (record, index) => {
            return <FacilitatorsForm communityId={record.id} />;
          },
        }}
      />
    </>
  );
};

export default AppAdminSessionCommunitiesManagementScreen;
