import {
  Document,
  Paragraph,
  ISectionOptions,
  TextRun,
  Table,
  TableOfContents,
  ImageRun,
  ExternalHyperlink,
} from 'docx';

import { ELEMENT_EMBED_LINK } from 'frameworks/plate/plugins/embed-link';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  TNode,
} from '@udecode/plate';

export type DocFragment = Paragraph | Table | TableOfContents;

const serializeChildren = (nodes: TNode[]): (TextRun | ExternalHyperlink)[] => {
  return nodes.map((node) => {
    if (node.text) {
      if (node.bold || node.underline || node.italic) {
        return new TextRun({
          text: node.text,
          bold: !!node.bold,
          italics: !!node.italic,
          underline: node.underline ? {} : undefined,
        });
      }
      return new TextRun(node.text);
    } else {
      switch (node.type) {
        case 'a':
          return new ExternalHyperlink({
            children: serializeChildren(node.children),
            link: node.url,
          });
        default:
          return new TextRun({ text: '' });
      }
    }
  });
};

const handleImage = async (url: string) => {
  try {
    const resp = await fetch(url, {
      mode: 'cors',
    });

    const img = await resp.blob();
    const arrayBuffer = await img.arrayBuffer();

    return new Paragraph({
      children: [
        new ImageRun({
          data: arrayBuffer,
          transformation: {
            width: 200,
            height: 200,
          },
        }),
      ],
    });
  } catch (e) {
    console.error(e);
    return new Paragraph({
      text: url as string,
    });
  }
};

export const serializeElement = async (node: TNode) => {
  switch (node.type) {
    case ELEMENT_IMAGE:
      return handleImage(node.url as string);
    case ELEMENT_EMBED_LINK:
    case ELEMENT_LINK:
      return new Paragraph({
        children: [
          new ExternalHyperlink({
            children: [
              new TextRun({
                text: node.url as string,
                style: 'Hyperlink',
              }),
            ],
            link: node.url as string,
          }),
        ],
      });
    case ELEMENT_H1:
      return new Paragraph({
        children: serializeChildren(node.children),
        style: 'Titre2',
      });
    case ELEMENT_H2:
      return new Paragraph({
        children: serializeChildren(node.children),
        style: 'Titre3',
      });

    case ELEMENT_PARAGRAPH:
    case 'paragraph':
      return new Paragraph({
        children: node.children ? serializeChildren(node.children) : [],
      });
    default:
      console.log('Not supported yet', node);
  }
};

export const serializeFragments = async (
  nodes: TNode[]
): Promise<DocFragment[]> => {
  const elems = await Promise.all(
    nodes.map((node: TNode) => serializeElement(node))
  );
  return elems.filter((res) => res !== undefined) as DocFragment[];
};

export const serialize = async (nodes: TNode[]): Promise<ISectionOptions> => {
  return {
    children: await serializeFragments(nodes),
  };
};

export const sectionsToDoc = (sections: ISectionOptions[]) => {
  return new Document({ sections });
};
