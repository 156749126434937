import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import { ActionRecord, Users } from 'types/types';
import { computeOrderedVotes } from 'model/votes';
import PostBlock from './blocks/PostBlock';
import Button from 'components/Button';

export type BestFeedbacksProps = {
  sessionId: string;
  postsSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  votes: Nullable<Record<string, string>>;
  participants: Users;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const BestPosts = ({
  sessionId,
  postsSummaries,
  votes,
  posts,
  participants,
  allowAutomatedSynthesis,
  marginClasses,
}: BestFeedbacksProps) => {
  const [viewAll, setViewAll] = useState(false);
  const { t } = useTranslation();

  const top3PostsIds = computeOrderedVotes(votes, posts || {}).slice(
    0,
    viewAll ? undefined : 3
  );

  return _.size(top3PostsIds) > 0 ? (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:Top3BestPosts')}</h2>
      <div className="space-y-4">
        {top3PostsIds.map(([userId]) => {
          return posts?.[userId] ? (
            <PostBlock
              key={userId}
              sessionId={sessionId}
              allowAutomatedSynthesis={allowAutomatedSynthesis}
              userId={userId}
              post={posts[userId]}
              postSummary={postsSummaries?.[userId] || null}
              user={participants[userId]}
            />
          ) : null;
        })}
      </div>
      <Button
        text={viewAll ? t('common:seeLess') : t('common:seeMore')}
        onClick={() => setViewAll((viewAll) => !viewAll)}
        size="sm"
      />
    </div>
  ) : null;
};

export default BestPosts;
