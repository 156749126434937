import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActivityStepper from './ActivityStepper';
import ExtraStep from './ActivityStepper/ExtraStep';

import {
  OrderedActivities,
  SessionTemplate,
  Productions,
  ActivitiesMeta,
  Session,
  Activity,
  SessionStatusState,
  UserType,
} from 'types/types';
import { Nullable } from 'types/typesUtils';
import { Mode } from 'types/ui';
import {
  addActivity,
  deleteActivity as removeActivity,
  startActivity,
  pauseActivity,
  resumeActivity,
  updateActivity,
  activateActivity,
  reindexSessionActivities,
} from 'model/activitiesManagement';
import { setSessionState } from 'model/sessions';

import MatchGroupModal from './MatchGroupModal';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { ChevronDoubleUp } from 'assets/icons';

export type ActivitiesListProps = {
  userType: UserType;
  session: Session;
  sessionState: Nullable<SessionStatusState>;
  activities: OrderedActivities;
  productions: Record<string, Productions>;
  selectedActivity: string;
  enabledActivities: Record<string, boolean>;
  activitiesMeta: ActivitiesMeta;
  mode: Mode;
  sessionPath: string;
  sessionId: string;
  sessionTemplate: SessionTemplate;
  onActivitySettings: (activityName: string) => void;
  secondaryLeftPaneHidden?: boolean;
  toggleSecondaryLeftPane?: () => void;
  smallView?: boolean;
};

const ActivitiesList = ({
  userType,
  session,
  sessionState,
  activities,
  productions,
  selectedActivity,
  enabledActivities,
  activitiesMeta,
  mode,
  sessionPath,
  sessionId,
  sessionTemplate,
  onActivitySettings,
  secondaryLeftPaneHidden,
  smallView = false,
}: ActivitiesListProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [smallActive, setSmallActive] = useState(smallView);

  const { pathname } = useLocation();

  const [groupModal, setGroupModal] = useState<{
    groupActivityName: string;
    targetActivityName: string;
  } | null>(null);

  const [warningInfoModal, setWarningInfoModal] = useState({
    onConfirm: () => {},
    message: t('common:messageContinue'),
  });
  const [showWarningModal, setShowWarningModal] = useState(false);

  const onClickStepSelect = (name: string) => {
    history.push(`/${sessionPath}/${name}`);
    smallView && setSmallActive(true);
  };

  const deleteActivity = (name: string) => {
    return removeActivity(sessionId, sessionTemplate, name);
  };

  const insertActivityBelow = (
    prevName: string,
    name: string,
    sourceActivityName: string
  ) => {
    return addActivity(
      sessionId,
      session.language || 'fr',
      sessionTemplate,
      prevName,
      name,
      sourceActivityName
    );
  };
  const toggleNextActivity = (currentActivityName: string) => {
    const activityIndex = activities.findIndex(
      (activity) => activity.name === currentActivityName
    );

    const next =
      activityIndex === -1 ? undefined : activities[activityIndex + 1];

    if (next) {
      toggleActivity(next.name);
    }
  };

  const canToggleActivity = (nextActivityName: string): boolean => {
    const enabledActivity = Object.entries(enabledActivities).find(
      ([_, enabled]) => enabled
    );

    if (!enabledActivity) return true;

    const currentActivityName = enabledActivity[0];
    const nextActivityIndex = activities.findIndex(
      (activity) => activity.name === nextActivityName
    );
    const currentActivityIndex = activities.findIndex(
      (activity) => activity.name === currentActivityName
    );

    if (currentActivityIndex === null) return true;
    if (nextActivityIndex === currentActivityIndex) return true;

    const remainingTimeMs =
      activitiesMeta[currentActivityName]?.remainingTimeMs;
    const remainingTimestamp =
      activitiesMeta[currentActivityName]?.remainingTimestamp;

    let isCurrentActivityTimeEllapsed = true;
    if (remainingTimestamp && remainingTimeMs) {
      const shouldEndAtMs = remainingTimestamp + remainingTimeMs;
      isCurrentActivityTimeEllapsed = shouldEndAtMs < Date.now();
    }

    let canToggle = true;
    if (nextActivityIndex > currentActivityIndex + 1) {
      const forgetSteps = nextActivityIndex - (currentActivityIndex + 1);
      canToggle = false;
      setWarningInfoModal({
        message: t('sessions:messageBypassSteps', {
          count: forgetSteps,
        }),
        onConfirm: () => toggleActivity(nextActivityName),
      });
    } else if (nextActivityIndex < currentActivityIndex) {
      canToggle = false;
      setWarningInfoModal({
        message: t('sessions:messageStepBefore'),
        onConfirm: () => toggleActivity(nextActivityName),
      });
    } else if (!isCurrentActivityTimeEllapsed) {
      canToggle = false;
      setWarningInfoModal({
        message: t('sessions:messageStepNotFinished'),
        onConfirm: () => toggleActivity(nextActivityName),
      });
    }
    return canToggle;
  };

  const toggleActivity = (name: string) => {
    const activity = activities.find((activity) => activity.name === name);
    if (activity) {
      const grouping = activity.grouping;
      if (grouping.mode === 'All') {
        activateActivity(sessionId, name);
      } else {
        const groupActivity = activity;

        setGroupModal({
          groupActivityName: groupActivity.name,
          targetActivityName: name,
        });
      }
    }
    smallView && setSmallActive(true);
  };

  const resetActivityTime = (activity: Activity) => {
    return startActivity(sessionId, activity);
  };

  const stopActivityTime = (activity: Activity, remainingMs: number) => {
    return pauseActivity(sessionId, activity, remainingMs);
  };

  const resumeActivityTime = (activity: Activity) => {
    return resumeActivity(sessionId, activity);
  };

  const updateActivityTime = (activity: Activity, remainingMs: number) => {
    return updateActivity(sessionId, activity, remainingMs);
  };

  const reindexActivities = (orderedActivities: OrderedActivities) =>
    reindexSessionActivities(sessionId, orderedActivities);

  const autoSwitch = !!session.enableStepsAutomatically;

  const sessionHomeSelected = pathname.startsWith(
    `/${sessionPath}/facilitate/home`
  );

  const sessionDashboardSelected = pathname.startsWith(
    `/${sessionPath}/facilitate/dashboard`
  );

  return (
    <div className="h-full">
      {!secondaryLeftPaneHidden && (
        <>
          {groupModal && (
            <MatchGroupModal
              sessionId={sessionId}
              activities={sessionTemplate.activities}
              activityName={groupModal?.groupActivityName || ''}
              autoShowGroups={autoSwitch}
              visible={!!groupModal}
              productions={productions}
              showGroups={() => {
                activateActivity(sessionId, groupModal!.targetActivityName);
                setGroupModal(null);
                history.push(
                  `/${sessionPath}/${groupModal!.targetActivityName}/facilitate`
                );
              }}
              editGroups={() => {
                setGroupModal(null);
                history.push(
                  `/${sessionPath}/${
                    groupModal!.targetActivityName
                  }/grouping/edit_groups`
                );
              }}
            />
          )}
          {smallView && !smallActive ? (
            <div
              onClick={() => setSmallActive((smallActive) => !smallActive)}
              className="flex w-full cursor-pointer items-center justify-center p-2 hover:bg-surfaces-strong"
            >
              <ChevronDoubleUp
                className={`h-6 w-8 ${
                  smallActive ? 'rotate-180' : ''
                } stroke-3/2`}
              />
              {smallActive
                ? t('sessions:ViewAllActivities')
                : t('sessions:ReduceActivityList')}
            </div>
          ) : undefined}
          {mode === 'facilitator' && (!smallActive || sessionHomeSelected) ? (
            <ExtraStep
              done={['live', 'finished'].includes(sessionState || 'kickoff')}
              isEnabled={[
                'kickoff',
                'pre_conception',
                'assign_roles',
                'not_started',
              ].includes(sessionState || 'kickoff')}
              label={t('common:SessionHome')}
              onEnable={() => {
                setSessionState(sessionId, 'not_started');
              }}
              onSelect={() => {
                smallView && setSmallActive(true);
                history.push(`/${sessionPath}/facilitate/home`);
              }}
              isSelected={sessionHomeSelected}
            />
          ) : null}
          <div
            className={
              mode === 'facilitator' && !smallActive
                ? 'relative mt-4 w-full border-t-2 border-surfaces-strong pt-6'
                : ''
            }
          >
            {mode === 'facilitator' && !smallActive ? (
              <div className="absolute -top-5 w-full">
                <div className=" mx-auto flex w-max rounded-full border-2 border-surfaces-strong bg-white py-1 px-2 font-bold">
                  {t('sessions:SessionLive')}
                </div>
              </div>
            ) : null}
            <ActivityStepper
              userType={userType}
              lng={session.language || 'fr'}
              orderedActivities={activities}
              selectedActivity={selectedActivity}
              mode={mode}
              toggleActivity={(name) => {
                if (sessionState !== 'live') {
                  setSessionState(sessionId, 'live');
                }
                return canToggleActivity(name)
                  ? toggleActivity(name)
                  : setShowWarningModal(true);
              }}
              toggleNextActivity={toggleNextActivity}
              autoSwitchActivites={autoSwitch}
              enabledActivities={
                sessionState === 'live' ? enabledActivities : {}
              }
              activitiesMeta={activitiesMeta}
              onClickStepSelect={onClickStepSelect}
              onDeleteActivity={deleteActivity}
              onInsertActivityBelow={insertActivityBelow}
              onActivitySettings={onActivitySettings}
              onResetActivityTime={resetActivityTime}
              onStopActivityTime={stopActivityTime}
              onResumeActivityTime={resumeActivityTime}
              onUpdateActivityTime={updateActivityTime}
              smallMode={smallActive}
              reindexActivities={reindexActivities}
            />
          </div>

          {mode === 'facilitator' &&
          (!smallActive || sessionDashboardSelected) ? (
            <div
              className={
                !smallActive
                  ? 'relative mt-8 w-full border-t-2 border-surfaces-strong  pt-4'
                  : ''
              }
            >
              {!smallActive ? (
                <div className="absolute -top-5 w-full">
                  <div className=" mx-auto flex w-max rounded-full border-2 border-surfaces-strong bg-white py-1 px-2 font-bold">
                    {t('sessions:SessionFinished')}
                  </div>
                </div>
              ) : null}
              <ExtraStep
                isEnabled={sessionState === 'finished'}
                label={t('common:SessionDashboard')}
                onEnable={() => {
                  setSessionState(sessionId, 'finished');
                }}
                onSelect={() => {
                  smallView && setSmallActive(true);
                  history.push(`/${sessionPath}/facilitate/dashboard`);
                }}
                isSelected={pathname.startsWith(
                  `/${sessionPath}/facilitate/dashboard`
                )}
              />
            </div>
          ) : null}
          <Modal
            open={showWarningModal}
            onClose={() => setShowWarningModal(false)}
            body={<div className="text-center">{warningInfoModal.message}</div>}
            className={'w-96'}
            compact
            footer={
              <div className="flex items-center space-x-4">
                <Button
                  text={t('common:Cancel')}
                  size="sm"
                  design="secondary"
                  onClick={() => setShowWarningModal(false)}
                />
                <Button
                  text={t('common:Ok')}
                  size="md"
                  onClick={() => {
                    warningInfoModal.onConfirm();
                    setShowWarningModal(false);
                  }}
                />
              </div>
            }
          />
        </>
      )}
      {smallView ? (
        <div
          onClick={() => setSmallActive((smallActive) => !smallActive)}
          className="flex w-full cursor-pointer items-center justify-center p-2 hover:bg-surfaces-strong"
        >
          <ChevronDoubleUp
            className={`h-6 w-8 ${smallActive ? 'rotate-180' : ''} stroke-3/2`}
          />
          {smallActive
            ? t('sessions:ViewAllActivities')
            : t('sessions:ReduceActivityList')}
        </div>
      ) : undefined}
    </div>
  );
};

export default ActivitiesList;
