import React, { useMemo } from 'react';
import { useFirebaseConnect } from 'react-redux-firebase';
import _ from 'lodash';

import { useSelectorArray } from 'services/firebase';
import { TaskData, UserType } from 'types/types';

import { Task as TaskType } from '../../tasks';

import TasksListForm from '../blocks/TasksListForm';
import { useTranslation } from 'react-i18next';

export type FollowUpViewProps = {
  userId: string;
  userType: UserType;
  sessionId: string;
  tasks: TaskType[];
  accessCode: string;
};

const FollowUpView = ({
  userId,
  userType,
  sessionId,
  tasks,
  accessCode,
}: FollowUpViewProps): JSX.Element => {
  const { t } = useTranslation();

  const loadArray = [`sessionsNextData/${sessionId}/tasks`];

  useFirebaseConnect(loadArray);

  const [, data]: [boolean, any] = useSelectorArray(
    loadArray || [],
    (data: any) => data
  );

  const tasksData: Record<string, TaskData> = useMemo(
    () => _.get(data, `sessionsNextData.${sessionId}.tasks`),
    [data, sessionId]
  );

  return (
    <>
      <div className="flex flex-row items-center space-x-3">
        <h1 className="text-4xl font-semibold">{t('sessions:NextSteps')}</h1>
      </div>

      <TasksListForm
        tasks={tasks}
        sessionId={sessionId}
        userId={userId}
        userType={userType}
        accessCode={accessCode}
        tasksData={tasksData}
      />
    </>
  );
};

export default FollowUpView;
