import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Activity } from 'types/types';
import { useUsers } from 'model/users';

import Tag from 'components/Tag';
import Editor from 'frameworks/plate/RevisionedEditor';
import Blockquote from 'components/Blockquote';
import Alert from 'components/Alert';

import { UserGroup, User } from 'assets/icons';
import { useSessionParticipantActivityProductions } from 'model/productions';

type Criterium = {
  description: string;
  value: string;
};

type Question = {
  value: string | number | boolean;
  description: string;
  type: 'number' | 'boolean' | 'string';
};

export type ActivityUserDetailsProps = {
  participantId: string;
  userId?: string;
  sessionId: string;
  activity: Activity;
  index: number;
  setActivityProduction?: (
    productions: Record<string, any>,
    visibility: boolean
  ) => void;
  showPersonalDetails?: boolean;
};

const ActivityUserDetails = ({
  userId,
  participantId,
  sessionId,
  activity,
  index,
  setActivityProduction,
  showPersonalDetails = false,
}: ActivityUserDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  const dataProduction = useSessionParticipantActivityProductions(
    sessionId,
    activity.name,
    participantId
  );

  const noData =
    !dataProduction?.post &&
    !dataProduction?.votes?.length &&
    !dataProduction?.docs &&
    !dataProduction?.questions?.length;

  const isSecretActivity =
    !showPersonalDetails && dataProduction?.questions?.length > 0;

  const isDocumentviewable =
    showPersonalDetails ||
    !dataProduction?.docsAuthorizedUsersId ||
    (dataProduction?.docsAuthorizedUsersId &&
      dataProduction.docsAuthorizedUsersId[userId || '']);

  useEffect(() => {
    if (
      dataProduction &&
      setActivityProduction &&
      activity.productions &&
      !isSecretActivity
    ) {
      setActivityProduction(
        dataProduction,
        !dataProduction?.docs || (dataProduction?.docs && isDocumentviewable)
      );
    }
  }, [
    dataProduction,
    setActivityProduction,
    activity.productions,
    isSecretActivity,
    isDocumentviewable,
  ]);

  const docs = Object.keys(dataProduction?.docs || {});

  const [users] = useUsers(
    Object.values<{ post: string; userId: string }>(
      dataProduction?.votes || {}
    ).reduce<Record<string, string>>((userIds, vote) => {
      if (vote.userId) {
        userIds[vote.userId] = '';
      }
      return userIds;
    }, {}) || {}
  );

  return activity.productions && !isSecretActivity ? (
    <div className="bi-avoid relative border-b border-surfaces-divider bg-white py-4 pb-16">
      <div className="flex">
        <div className="flex w-full flex-col justify-center">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="m-1 mr-4 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary-soft text-xl font-semibold text-primary">
                {index + 1}
              </div>
              {activity.grouping?.mode !== 'All' ? (
                <UserGroup className="h-8 w-8 stroke-2" />
              ) : (
                <User className="h-8 w-8 stroke-2" />
              )}
              <label
                htmlFor={activity.name}
                className="ml-2 text-lg font-semibold"
              >
                {activity.humanName || activity.name}
              </label>
            </div>
          </div>
          {!noData ? (
            <div className="mt-4 ml-2">
              {dataProduction?.post ? (
                <>
                  <h3 className="font-semibold">
                    {t('sessions:myExperienceTitle')}
                  </h3>
                  <Blockquote quote={dataProduction?.post} />
                </>
              ) : null}
              {dataProduction?.criteria?.length > 0 ? (
                <>
                  <h3 className="mt-4 font-semibold">
                    {t('sessions:criteriaResponse')}
                  </h3>
                  <div className="flex flex-row items-center space-x-4">
                    {dataProduction?.criteria.map(
                      (criterium: Criterium, index: number) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center justify-center space-x-2"
                          >
                            <p>{criterium.description}</p>

                            <Tag
                              key={index}
                              className="mt-2"
                              text={
                                criterium.value
                                  ? criterium.value
                                  : t('common:noResponse')
                              }
                              color={criterium.value ? 'primary' : 'warning'}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              ) : null}
              {dataProduction?.votes?.length > 0 ? (
                <>
                  <h3 className="mt-4 font-semibold">
                    {t('sessions:defaultMyVotesTitle')}
                  </h3>
                  <div className="mt-2 flex flex-col space-y-4">
                    {dataProduction?.votes.map(
                      (
                        vote: { post: string; userId: string },
                        index: number
                      ) => {
                        return (
                          <Blockquote
                            quote={vote.post}
                            username={
                              users[vote.userId]?.name || t('common:unknowUser')
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </>
              ) : null}
              {docs.length > 0 ? (
                isDocumentviewable ? (
                  docs.map((docId) => {
                    return (
                      <Editor
                        key={docId}
                        showBorder={false}
                        readOnly={true}
                        metaId={docId}
                      />
                    );
                  })
                ) : (
                  <div className="mt-4">
                    <Alert
                      title={t('sessions:documentPrivate')}
                      message={t('sessions:documentPrivateDescription')}
                      type="error"
                    />
                  </div>
                )
              ) : null}
              {dataProduction?.questions?.length > 0 ? (
                <>
                  <h3 className="mt-4 font-semibold">
                    {t('sessions:feedbackTitle')}
                  </h3>
                  <div className="mt-2 flex flex-col space-y-4">
                    {dataProduction?.questions.map(
                      (question: Question, index: number) => {
                        return (
                          <div className="flex flex-row items-center space-x-2">
                            <p>
                              {question.description}
                              <span className="ml-2 font-semibold">
                                {question.type === 'boolean'
                                  ? question.value
                                    ? t('common:yes')
                                    : t('common:no')
                                  : question.value}
                              </span>
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              ) : null}
            </div>
          ) : (
            <div className="mt-4">
              <Alert
                title={t('common:noData')}
                message={t('sessions:noInformationForThisactivity')}
                type="warning"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ActivityUserDetails;
