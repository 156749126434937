import { usePlateEditorRef } from '@udecode/plate-core';
import { ToolbarButton, ToolbarButtonProps } from '@udecode/plate-toolbar';
import React, { useRef, useEffect } from 'react';

import { FilePlugin, uploadFiles } from './withFileUpload';

export type ToolbarUploadProps = {
  options: FilePlugin;
  accept?: string;
} & ToolbarButtonProps;

export const ToolbarUpload = ({
  options,
  accept,
  ...props
}: ToolbarUploadProps) => {
  const editor = usePlateEditorRef()!;
  const filePicker = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (filePicker.current) {
      const el = filePicker.current;
      const upload = () => {
        uploadFiles(editor, el.files, options);
      };

      el.addEventListener('input', upload);
      return () => el.removeEventListener('input', upload);
    }
    // TODO: unsuscribe listener
  }, [filePicker, editor, options]);

  return (
    <>
      <ToolbarButton
        onMouseDown={(event) => {
          if (filePicker.current) {
            filePicker.current.click();
          }
        }}
        {...props}
      />
      <input ref={filePicker} type="file" hidden={true} accept={accept} />
    </>
  );
};
