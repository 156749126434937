import React, { useEffect } from 'react';

export type DocumentTitleProps = {
  title: string;
  children?: React.ReactNode;
};

const DocumentTitle = ({
  title,
  children,
}: DocumentTitleProps): JSX.Element => {
  useEffect(() => {
    const previous = document.title;
    document.title = title;

    return () => {
      document.title = previous;
    };
  }, [title]);

  return <>{children}</>;
};

export default DocumentTitle;
