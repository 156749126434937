import React from 'react';

const Dropzone = ({ onDragEnter }: { onDragEnter: () => void }) => {
  return (
    <div
      onDragEnter={onDragEnter}
      className={`flex h-6 w-full items-center space-x-2`}
    >
      <div className={`h-6 w-6 rounded-full bg-surfaces-strong`}></div>
      <div className={`h-full grow rounded-md bg-surfaces-strong`}></div>
    </div>
  );
};

export default Dropzone;
