import Link from 'components/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Activity, SessionStatusState } from 'types/types';
import { Nullable } from 'types/typesUtils';

export type SessionHomeFacilitationScreenProps = {
  sessionState: Nullable<SessionStatusState>;
  sessionPath: string;
  selectedActivity: Nullable<Activity>;
};

const SessionHomeFacilitationScreen = ({
  sessionState,
  sessionPath,
  selectedActivity,
}: SessionHomeFacilitationScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const h1ClassName = 'text-xl font-semibold mb-2';
  return (
    <div className="flex flex-col items-center">
      {sessionState !== 'live' && sessionState !== 'finished' ? (
        <>
          <h1 className={h1ClassName}>{t('sessions:SessionIsNotStarted')}</h1>
          <p>
            {t('sessions:ParticipantsAreRedirectedTo')}
            <Link
              to={{ pathname: `${sessionPath}/home` }}
              text={t('common:SessionHome')}
            />
          </p>
          <p>{t('sessions:ActivateNextStepToStartSession')}</p>
        </>
      ) : sessionState === 'live' ? (
        <>
          <h1 className={h1ClassName}>{t('sessions:SessionIsLive')}</h1>
          <p>{t('sessions:SessionHomeIsNotEnbaled')}</p>
          {selectedActivity && (
            <p className="space-x-1">
              <span>{t('common:BackToOngoingStep')}</span>
              <Link
                to={{
                  pathname: `${sessionPath}/${selectedActivity.name}/facilitate`,
                }}
                text={selectedActivity.humanName}
              />
            </p>
          )}
        </>
      ) : (
        <>
          <h1 className={h1ClassName}>{t('sessions:SessionIsFinished')}</h1>
          <p>{t('sessions:SessionHomeIsNotEnbaled')}</p>
        </>
      )}
    </div>
  );
};

export default SessionHomeFacilitationScreen;
