import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { Tab } from '@headlessui/react';
import { Listbox } from '@headlessui/react';
import _ from 'lodash';

import { DotsHorizontal, Selector } from 'assets/icons';

const DISPLAY_INDEX_LIMIT_FOR_TABS = 1;
const SELECTOR_BUTTON_WIDTH = 24;

export type TabInfo = {
  label: string;
  key: string;
};

export type TabsProps = {
  tabs: TabInfo[];
  selected: string | undefined;
  onChange: (key: string) => void;
  alignement?: 'left' | 'right' | 'center';
  controlled?: boolean;
};

const Tabs = ({
  tabs,
  selected,
  onChange,
  alignement = 'left',
  controlled = false,
}: TabsProps): JSX.Element => {
  const initial = tabs.findIndex(({ key }) => key === selected);
  const parentRef = useRef<HTMLDivElement>(null);
  const tabsWidth = useRef<number[]>(tabs.map(() => 0));

  const [displayIndex, setDisplayIndex] = useState(tabs.length - 1);

  const updateDispalyIndex = _.debounce(() => {
    if (parentRef.current && tabsWidth.current) {
      const parentWidth = parentRef.current.offsetWidth || 0;
      let indexFound = false;
      const totalSize = tabsWidth.current.reduce((prev, tab, index, array) => {
        const selectorButtonWidth =
          index === array.length - 1 ? 0 : SELECTOR_BUTTON_WIDTH;
        if (!indexFound && prev + tab + selectorButtonWidth > parentWidth) {
          indexFound = true;
          setDisplayIndex(index - 1 > 0 ? index - 1 : 0);
        }
        return prev + tab;
      }, 0);
      if (!indexFound) {
        setDisplayIndex(
          totalSize > parentRef.current.offsetWidth
            ? tabs.length - 2
            : tabs.length - 1
        );
      }
    }
  }, 50);

  useEffect(() => {
    updateDispalyIndex();
  }, [updateDispalyIndex]);

  useEffect(() => {
    window.addEventListener('resize', updateDispalyIndex);
    updateDispalyIndex();
    return () => {
      window.removeEventListener('resize', updateDispalyIndex);
    };
  }, [updateDispalyIndex]);

  const displayedTabs = useMemo(() => {
    return displayIndex >= DISPLAY_INDEX_LIMIT_FOR_TABS
      ? tabs.filter((tab, index) => index <= displayIndex)
      : [];
  }, [tabs, displayIndex]);

  const hiddenTabs = useMemo(() => {
    return displayIndex >= DISPLAY_INDEX_LIMIT_FOR_TABS
      ? tabs.filter((tab, index) => index > displayIndex)
      : tabs;
  }, [tabs, displayIndex]);

  const isTabSelected = initial <= displayIndex;

  return (
    <div className="flex w-full flex-row items-center">
      <div
        className="invisible-scrollbar flex-grow overflow-x-hidden"
        ref={parentRef}
      >
        <Tab.Group
          key={
            controlled
              ? initial
              : displayIndex !== tabs.length - 1
              ? displayIndex
              : undefined
          }
          defaultIndex={initial}
        >
          <Tab.List
            className={`flex items-center pr-4 ${
              alignement === 'center'
                ? 'justify-center'
                : alignement === 'right'
                ? 'justify-end'
                : 'justify-start'
            }`}
          >
            {displayedTabs.map((tab, index) => (
              <div
                key={tab.key}
                ref={(el) => {
                  if (el?.offsetWidth) {
                    tabsWidth.current[index] = el?.offsetWidth;
                  }
                }}
              >
                <Tab as={Fragment}>
                  {({ selected }: { selected: boolean }) => (
                    <button
                      onClick={() => onChange(tab.key)}
                      className={`
               min-w-max whitespace-nowrap px-4 py-4 text-lg capitalize
                ${
                  selected && isTabSelected
                    ? 'border-b-2 border-primary font-semibold'
                    : 'hover:text-primary'
                }
                `}
                    >
                      {tab.label}
                    </button>
                  )}
                </Tab>
              </div>
            ))}
            {displayIndex !== tabs.length - 1 ? (
              <div className="mr-1 flex-grow">
                <Listbox value={initial} onChange={(value: number) => {}}>
                  <Listbox.Button className="flex w-full items-center">
                    {displayIndex < DISPLAY_INDEX_LIMIT_FOR_TABS &&
                    initial < tabs.length ? (
                      <div className="group flex w-full min-w-max cursor-pointer flex-row items-center justify-between rounded-lg border border-surfaces-divider p-2 capitalize">
                        <span className="block text-lg group-hover:font-medium">
                          {tabs[initial]?.label}
                        </span>
                        <span className="pointer-events-none flex text-black group-hover:text-primary">
                          <Selector
                            className="h-6 w-6 stroke-3/2 group-hover:stroke-5/2"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    ) : (
                      <DotsHorizontal className="h-6 w-6 rounded-full stroke-2 p-1 hover:bg-primary-soft hover:text-primary" />
                    )}
                  </Listbox.Button>
                  <Listbox.Options
                    className={`absolute z-20 mt-1 rounded-md border border-surfaces-divider bg-white py-1 text-base`}
                  >
                    {hiddenTabs.map((tab, index) => {
                      return (
                        <Listbox.Option
                          key={tab.key}
                          value={
                            displayIndex < DISPLAY_INDEX_LIMIT_FOR_TABS
                              ? index
                              : displayIndex + 1 + index
                          }
                          as={Fragment}
                        >
                          {({ selected }) => {
                            return (
                              <div
                                onClick={() => onChange(tab.key)}
                                className={`flex w-full min-w-max cursor-pointer flex-row items-center py-1 px-4 text-lg capitalize text-black hover:text-primary ${
                                  selected &&
                                  (!isTabSelected ||
                                    displayIndex < DISPLAY_INDEX_LIMIT_FOR_TABS)
                                    ? 'ml-1 border-l-2 border-primary font-semibold '
                                    : ''
                                }`}
                              >
                                {tab.label}
                              </div>
                            );
                          }}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Listbox>
              </div>
            ) : null}
          </Tab.List>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Tabs;
