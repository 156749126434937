import React, { useState, useMemo } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Combobox } from '@headlessui/react';

import { Community, Communities } from 'types/baseTypes';

import { Check, Selector } from 'assets/icons';
import { useLocalSearch } from 'services/localSearching';
import { useDeepEqualMemo } from 'utils/utils';

import { useOnChange } from 'frameworks/formik/hooks';

export type CommunitySelectorProps = {
  name: string;
  label?: string;
  onChangeValue?: (value: Record<string, boolean>, hasError: boolean) => void;
  communitiesAvailable: Communities;
};

const CommunitySelector = ({
  name,
  label,
  onChangeValue,
  communitiesAvailable,
}: CommunitySelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const [field, meta, helpers] = useField(name);

  const hasError = !!(meta.touched && meta.error);

  useOnChange(name, onChangeValue);

  const searchKeys: (keyof Community)[] = useMemo(() => ['name'], []);

  const communitiesAvailableMemoize =
    useDeepEqualMemo(communitiesAvailable) || {};

  const filteredCommunities = useLocalSearch(
    communitiesAvailableMemoize,
    searchKeys,
    filter
  );

  const communities = _.sortBy(Object.entries(filteredCommunities || {}), [
    function ([, community]) {
      return community.name.toLowerCase();
    },
  ]);

  return (
    <div className={'w-full'}>
      {label && (
        <label className="text-lg font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <Combobox
        value={field.value}
        onChange={(id) => {
          helpers.setValue(id);
        }}
      >
        {({ open }) => (
          <div className="relative z-20 mt-1">
            <div className="relative flex w-full cursor-default flex-col rounded-md border border-surfaces-divider bg-white p-3 text-left focus:outline-none sm:text-sm">
              {open && (
                <p className="absolute left-3 -top-2 bg-white px-1 text-xs text-primary">
                  {communitiesAvailableMemoize[field.value]?.name || ''}
                </p>
              )}
              <Combobox.Button className="flex w-full cursor-pointer items-center">
                <Combobox.Input
                  className="flex min-w-0 grow text-lg placeholder-black-soft outline-none"
                  displayValue={(communityId: string) =>
                    communitiesAvailableMemoize[communityId]?.name || ''
                  }
                  onChange={(event) => setFilter(event.target.value)}
                />
                <Selector
                  className="h-6 w-6 shrink-0 stroke-2 text-black-soft"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>

            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md border bg-white py-1 shadow-lg">
              {communities.length === 0 && filter !== '' ? (
                <div className="space-y-2 py-2 px-3 text-lg">
                  {<p className="">{t('common:NoResult')}</p>}
                </div>
              ) : (
                communities.map(([communityId, community]) => (
                  <Combobox.Option
                    key={communityId}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-primary-soft text-primary' : 'text-black'
                      }`
                    }
                    value={communityId}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {community.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 text-primary`}
                          >
                            <Check
                              className="h-6 w-6 stroke-2"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        )}
      </Combobox>
      {hasError ? (
        <div className="font-medium text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CommunitySelector;
