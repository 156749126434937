import React from 'react';
import { BORDER_RADIUS } from 'constants/Dimensions';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    borderRadius: BORDER_RADIUS,
  },
};

const ColorBanner = ({
  color,
  active,
  children,
  center,
  style,
  className,
  onClick,
  ...props
}: {
  color?: string;
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}): JSX.Element => (
  <div
    className={`flex ${className || ''}`}
    style={{
      ...styles.container,
      ...{
        // override styles base on props
        backgroundColor: color,
        alignItems: center ? 'center' : 'flex-start',
        opacity: active ? 1 : 0.33,
      },
      ...style, // apply your own style if desired
    }} // pass props like onClick just bellow
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

ColorBanner.defaultProps = {
  active: true,
};

export default ColorBanner;
