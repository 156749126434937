import React, { useState } from 'react';

import { Form, Typography, Input } from 'antd';
import { Rule } from 'antd/lib/form';

const { Text } = Typography;

const ValidatedEditableText = ({
  value,
  rules,
  onSave,
}: {
  value: string;
  rules?: Rule[];
  onSave?: (val: string) => Promise<void>;
}): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const save = async () => {
    try {
      const values = await form.validateFields();
      setEditing(false);
      onSave && (await onSave(values.val));
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  return editing ? (
    <Form form={form} size="small" component={false}>
      <Form.Item
        style={{
          margin: 0,
        }}
        name={'val'}
        initialValue={value}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`
        //   }
        // ]}
        rules={rules}
      >
        <Input
          onPressEnter={() => {
            console.log('enteered');
            save();
          }}
          onBlur={save}
          //   onMouseLeave={event => {
          //     if (event.target !== document.activeElement) {
          //       save();
          //     }
          //   }}
          //   onKeyPress={e => {
          //     console.log(e.key);
          //     if (e.key === 'Escape') {
          //       e.stopPropagation();
          //       save();
          //     }
          //   }}
        />
      </Form.Item>
    </Form>
  ) : (
    <Text editable={{ editing: false, onStart: () => setEditing(true) }}>
      {value}
    </Text>
  );
};

export default ValidatedEditableText;

export const ValidatedEditableInteger = ({
  value,
  rules,
  onSave,
}: {
  value: number;
  rules?: Rule[];
  onSave?: (val: number) => void;
}): JSX.Element => {
  const augmentedRules = [
    {
      validator: async (ignored: any, val: string) => {
        const num = parseInt(val);
        if (isNaN(num)) {
          throw new Error('Input must be a valid integer');
        }
      },
    },
    ...(rules || []),
  ];

  const handleSave =
    onSave &&
    (async (val: string) => {
      return onSave(parseInt(val));
    });
  return (
    <ValidatedEditableText
      value={`${value}`}
      rules={augmentedRules}
      onSave={handleSave}
    />
  );
};
