import React from 'react';
import Page from 'componentsOld/Page';
import Button from 'componentsOld/Button';
import MDReactComponent from 'componentsOld/MDReactComponent';

const ContentDone = ({ content, buttonLink, editable, setContent }) => {
  return (
    <Page>
      <div style={{ height: '50px' }} />
      {(editable || content) && (
        <MDReactComponent
          text={content}
          editable={editable}
          setText={setContent}
        />
      )}

      {buttonLink && (
        <a href={buttonLink} rel="noopener noreferrer" target="_blank">
          <Button>Join the next step</Button>
        </a>
      )}
    </Page>
  );
};

export default ContentDone;
