import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type To = {
  pathname: string;
  state?: any;
  search?: string;
};

export type LinkProps = {
  to?: To;
  className?: string;
  text: string;
  external?: boolean;
  color?: string;
  size?: 'sm' | 'base' | 'lg';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Link = ({
  to,
  className = '',
  text,
  external = false,
  color = 'text-primary',
  size = 'base',
  onClick,
}: LinkProps): JSX.Element => {
  const computedClassName = `font-normal  ${
    size === 'base'
      ? 'text-base md:text-lg 3xl:text-xl'
      : size === 'sm'
      ? 'text-sm'
      : 'text-base md:text-2xl 3xl:text-4xl'
  } cursor-pointer hover:underline focus:outline-none focus:underline ${className} ${color} hover:${color}`;
  return onClick ? (
    <button className={computedClassName} onClick={onClick}>
      {text}
    </button>
  ) : external && to ? (
    <a
      href={to.pathname}
      target="_blank"
      rel="noopener noreferrer"
      className={computedClassName}
    >
      {text}
    </a>
  ) : to ? (
    <RouterLink to={to} className={computedClassName}>
      {text}
    </RouterLink>
  ) : (
    <></>
  );
};

export default Link;

type LinkWithIconProps = LinkProps & { children: JSX.Element };

export const LinkWithIcon = ({ children, ...props }: LinkWithIconProps) => {
  return (
    <div className="flex flex-row items-center space-x-1 text-primary">
      <Link {...props} />
      {children}
    </div>
  );
};
