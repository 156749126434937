import React, { useEffect, useState } from 'react';
import { useServerTimeOffset } from 'model/dataHooks';
import { useTimer } from 'utils/utils';
import { ActivityMeta } from 'types/types';

type WithProgressProps = {
  durationMs?: number;
  remainingTimeMs?: number;
};

type ProgressProps = {
  progress: number | null;
};

const withProgress =
  <P extends ProgressProps>(
    updatePeriodMs: number,
    Component: React.ComponentType<P>
  ) =>
  (props: WithProgressProps & Omit<P, keyof ProgressProps>): JSX.Element => {
    const [progress, setProgress] = useState<number | null>(null);
    const { durationMs, remainingTimeMs } = props;
    const time = useTimer(updatePeriodMs);

    useEffect(() => {
      if (durationMs && remainingTimeMs) {
        setProgress(1 - remainingTimeMs / durationMs);
      } else {
        setProgress(null);
      }
    }, [time, durationMs, remainingTimeMs]);

    return <Component {...(props as P)} progress={progress} />;
  };

export const useActivityProgress = (
  updatePeriodMs: number,
  durationMs?: number,
  meta?: ActivityMeta
) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [remainingMs, setRemainingMs] = useState<number | null>(null);

  const time = useTimer(updatePeriodMs);
  const offset = useServerTimeOffset();

  useEffect(() => {
    if (
      meta?.remainingTimeMs !== null &&
      meta?.remainingTimeMs !== undefined &&
      meta?.remainingTimestamp
    ) {
      const _remainingTimeMs = meta?.remainingTimeMs;
      const now = Date.now() + offset;
      if (meta?.isPaused || _remainingTimeMs === 0) {
        setRemainingMs(_remainingTimeMs);
      } else {
        setRemainingMs(
          Math.max(_remainingTimeMs - (now - meta?.remainingTimestamp), 0)
        );
      }
    } else {
      setRemainingMs(null);
    }
  }, [time, meta, offset]);

  useEffect(() => {
    if (durationMs && remainingMs) {
      setProgress(1 - remainingMs / durationMs);
    } else {
      setProgress(null);
    }
  }, [remainingMs, durationMs]);

  return [progress, remainingMs];
};

export default withProgress;
