import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import RadioGroup, { RadioGroupProps } from 'components/RadioGroup';
import { InformationCircle } from 'assets/icons';
import Tooltip from 'components/Tooltip';

export type RadioGroupInputProps<T> = {
  name: string;
  label?: string;
  disabledMessage?: string;
} & Omit<RadioGroupProps<T>, 'value' | 'setValue'>;

const RadioGroupInput = <T,>({
  name,
  label,
  design,
  options,
  disabled,
  disabledMessage,
}: RadioGroupInputProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const [field, , helpers] = useField(name);
  return (
    <div>
      {label ? (
        <div className="flex items-center space-x-2">
          <label htmlFor={name} className="text-lg font-semibold">
            {label}
          </label>
          {disabled && disabledMessage ? (
            <div className="flex cursor-default space-x-2 text-center text-warning">
              <p className="text-sm">{t('common:updateImpossible')}</p>
              <Tooltip
                content={<p className="w-52">{disabledMessage}</p>}
                showOnClick
              >
                <InformationCircle className="h-5 w-5 stroke-2" />
              </Tooltip>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={`mt-2 w-full`}>
        <RadioGroup<T>
          value={field.value}
          setValue={(value) => helpers.setValue(value)}
          design={design}
          options={options}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RadioGroupInput;
