import SmileyRating from 'components/SmileyRating';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RSet } from 'types/types';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';

export type SessionValuableProps = {
  valuable: Nullable<RSet<number>>;
  marginClasses: string;
};

const SessionValuable = ({ valuable, marginClasses }: SessionValuableProps) => {
  const { t } = useTranslation();

  return valuable ? (
    <div className={`${marginClasses} flex flex-col items-start space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:UsefullSession')}</h2>
      <SmileyRating
        value={_.mean(
          Object.values(valuable || {}).filter((val) => _.isNumber(val))
        )}
        showValue
        readOnly
      />
    </div>
  ) : null;
};

export default SessionValuable;
