import React, { Fragment } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const Select = <T,>({
  value,
  c,
  options,
  onChange,
}: {
  value: T;
  options: {
    value: T;
    label: string;
  }[];
  c: Convertor<T, string>;
  onChange: (val: T) => void;
}) => {
  return (
    <select
      className="w-fit border-0 border-b-4 border-primary bg-white outline-none"
      onChange={(e) => onChange(c.from(e.target.value))}
      value={c.to(value)}
    >
      {options.map(({ value: val, label }) => {
        const stringValue = c.to(val);
        return (
          <option key={stringValue} value={stringValue}>
            {label}
          </option>
        );
      })}
    </select>
  );
};

export const MultiSelect = <T,>({
  selection,
  c,
  options,
  onChange,
  maxSelectionSize,
}: {
  maxSelectionSize?: number;
  selection: Record<string, T>;
  options: {
    value: T;
    label: string;
  }[];
  c: Convertor<T, string>;
  onChange: (val: Record<string, T>) => void;
}) => {
  const { t } = useTranslation();
  const availableOptions = options.filter(
    (options) => !selection[c.to(options.value)]
  );

  const selectionArray = Object.values(selection);

  return (
    <div className="inline border-0 border-b-4 border-primary pb-[1px] pl-1 outline-none">
      {selectionArray.map((sel, index) => (
        <Fragment key={c.to(sel)}>
          {options.find(({ value }) => c.to(sel) === c.to(value))!.label}
          <span
            className="mr-1 ml-0.5 cursor-default font-bold"
            onClick={() => {
              const { [c.to(sel)]: _ignored, ...rest } = selection;
              console.log(rest);
              onChange(rest);
            }}
          >
            &times;
          </span>
          {index === selectionArray.length - 2 && (
            <>{' ' + t('default:and') + ' '}</>
          )}
          {index < selectionArray.length - 2 && <>{', '}</>}
        </Fragment>
      ))}
      {availableOptions.length > 0 &&
        (!maxSelectionSize || selectionArray.length < maxSelectionSize) && (
          <select
            className="border-0 border-b-4 border-primary bg-white outline-0"
            onChange={(e) => {
              const id = e.target.value;
              const val = c.from(e.target.value);
              onChange({
                ...selection,
                [id]: val,
              });
            }}
            style={{ width: selectionArray.length === 0 ? '80px' : '20px' }}
            // defaultValue={c.to(value)}
          >
            <option key={'placeholder'} disabled selected>
              {selectionArray.length === 0 ? t('default:none') : ''}
            </option>
            {availableOptions.map(({ value: val, label }) => {
              const stringValue = c.to(val);
              return (
                <option key={stringValue} value={stringValue}>
                  {label}
                </option>
              );
            })}
          </select>
        )}
      {availableOptions.length === 0 && selectionArray.length === 0 && 'none'}
    </div>
  );
};

interface From<T, U> {
  from: (val: U) => T;
}

interface To<T, U> {
  to: (val: T) => U;
}

export interface Convertor<T, U> extends From<T, U>, To<T, U> {}

export const combineConvertors = <T, U, V>(
  c1: Convertor<T, U>,
  c2: Convertor<U, V>
): Convertor<T, V> => {
  return {
    from: (val: V) => c1.from(c2.from(val)),
    to: (val: T) => c2.to(c1.to(val)),
  };
};

export const stringConvertor = {
  from: (val: string) => val,
  to: (val: string) => val,
};

export const numberConvertor = {
  from: (val: string) => parseFloat(val),
  to: (val: number) => `${val}`,
};

export const integerConvertor = {
  from: (val: string) => parseInt(val, 10),
  to: (val: number) => `${val}`,
};

export const optionalIntegerConvertor = {
  from: (val: string) => {
    if (val === '') {
      return null;
    }

    const num = parseInt(val, 10);
    if (_.isNaN(num)) {
      return null;
    }

    return num;
  },
  to: (val: number | null) => `${val === null ? '' : val}`,
};

export const booleanConvertor = {
  from: (val: string) => val === 'true',
  to: (val: boolean) => `${!!val}`,
};

export const Input = <T,>({
  c,
  value,
  onChange,
}: {
  c: Convertor<T, string>;
  value: T;
  onChange: (val: T) => void;
}): JSX.Element => {
  return (
    <input
      value={c.to(value)}
      onChange={(e) => onChange(c.from(e.target.value))}
      className="w-44 border-0 border-b-4 border-primary outline-none"
    />
  );
};

export const OptionalInput = <T,>({
  c,
  value,
  onChange,
}: {
  c: Convertor<T | null, string>;
  value: T | null;
  onChange: (val: T | null) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <input
      className="min-w-[140px] border-0 border-b-4 border-primary outline-none"
      style={{
        width: value === null ? '140px' : `${`${value}`.length + 0.9}ch`,
        minWidth: '0px',
      }}
      placeholder={t('default:infinite')}
      value={c.to(value)}
      onChange={(e) => {
        return onChange(c.from(e.target.value));
      }}
    />
  );
};

export const Form = ({ children }: { children?: React.ReactNode }) => {
  return <form className="text-lg">{children}</form>;
};

export const Paragraph = ({ children }: { children?: React.ReactNode }) => {
  return <div>{children}</div>;
};

export const RangedNumberSelect = ({
  start,
  end,
  value,
  onChange,
}: {
  start: number;
  end: number;
  value: number;
  onChange: (val: number) => void;
}) => {
  const options = _.range(start, end).map((num) => ({
    value: num,
    label: `${num}`,
  }));

  return (
    <Select
      c={numberConvertor}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};
