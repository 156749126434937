import React, { useState } from 'react';
import { getFormattedPeriod } from 'services/datetimes';

import { Typography, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const DateTimeInterval = ({ scheduleDateTime, editable, submitValue }) => {
  const [editing, setEditing] = useState(false);

  const onEditStart = () => {
    setEditing(true);
  };

  const valueChanged = (val) => {
    setEditing(false);
    submitValue && submitValue(val);
  };

  return editing ? (
    <RangePicker
      showTime
      defaultValue={scheduleDateTime}
      minuteStep={10}
      format="YYYY-MM-DD HH:mm"
      onChange={valueChanged}
    />
  ) : (
    <Text
      editable={editable && { editing: false, onStart: onEditStart }}
      disabled={!scheduleDateTime}
    >
      {scheduleDateTime
        ? getFormattedPeriod(scheduleDateTime)
        : 'No date specified'}
    </Text>
  );
};

export default DateTimeInterval;
