import _ from 'lodash';
import { fetchData } from 'services/firebase';
import i18next from 'i18next';
import { memoize } from 'utils/utils';

import {
  SessionTemplate,
  Activity,
  ActivityData,
  Productions,
  Sessions,
  TrueSet,
  Community,
  Activities,
  Session,
} from 'types/types';

import { fetchUser } from 'model/users';
import { getDoc, isDocUpdated, textualizeDoc } from 'model/docManagement';
import { exportAsCsv } from 'utils/export';
import { mapValuesAsync } from 'utils/pureUtils';
import { TNode } from '@udecode/plate';

export const exportUsers = async (sessionId: string) => {
  const sessionNextTemplate: SessionTemplate | null = await fetchData(
    `/sessionsNextTemplates/${sessionId}`
  );
  const usersSession: Record<string, boolean> | null = await fetchData(
    `/sessionsNextData/${sessionId}/users/participants`
  );

  const activitiesData: Record<string, ActivityData> | null = await fetchData(
    `/sessionsNextData/${sessionId}/activities`
  );

  const sessionTitle =
    (await fetchData(`/sessionsNext/${sessionId}/title`)) || '';

  const sessionGroupPrefix: string =
    (await fetchData(`/sessionsNext/${sessionId}/groupPrefix`)) || '';

  const users = await Promise.all(
    Object.keys(usersSession || {}).map(async (key) => [
      key,
      await fetchUser(key),
    ])
  );

  const orderedActivities = _.sortBy(
    Object.values(sessionNextTemplate?.activities || {}),
    'index'
  ).filter(
    (activity) =>
      // contenus step
      !activity.screens['explore']
  );

  const activitiesName = orderedActivities.map((activity) => {
    return activity.humanName || activity.name;
  });

  let criteriaNames: string[] = [];
  let criteria: Record<string, string>[] = [];

  if (sessionNextTemplate?.activities.post) {
    const criteriaProductions = Object.values(
      sessionNextTemplate?.activities.post.productions || {}
    ).filter((prod) => prod.name.startsWith('crit'));

    criteriaNames = criteriaProductions.map(
      (production) => production.description || production.name
    );

    criteria = await Promise.all(
      criteriaProductions.map(async (production) => {
        const criterium: Record<string, string> | null = await fetchData(
          `/sessionsNextData/${sessionId}/activities/post/productions/${production.name}`
        );
        return criterium || {};
      })
    );
  }

  const rows = await Promise.all(
    users
      .filter(([key, user]) => {
        if (!user) {
          console.log(key, user);
        }
        return user?.email?.length > 0;
      })
      .map(async ([key, user]) => [
        user.email,
        user.name,
        ...criteria.map((crit) => crit[key] || ''),
        ...(await Promise.all(
          orderedActivities.map(async (activity) => {
            const productions = activitiesData?.[activity.name]?.productions;
            const groupingActivityName =
              activity?.grouping?.mode === 'Groups' ? activity.name : null;

            const activityGrouping = groupingActivityName
              ? activitiesData?.[groupingActivityName]?.grouping
              : null;
            const groupId = activityGrouping?.groupOfUser
              ? activityGrouping?.groupOfUser?.[key]
              : null;

            const multiplicity = Object.values(activity.productions || {}).find(
              (production) => production.multiplicity
            )?.multiplicity;

            let searchPath = key;
            if (groupId) {
              if (multiplicity === 'Each') {
                searchPath = `${groupId}.${key}`;
              } else {
                searchPath = `${groupId}`;
              }
            }

            const screens: any[] = Object.values(activity?.screens || {});
            const dataBindings: Record<string, string> =
              screens.length > 0 ? screens[0].dataBindings || {} : {};

            let activityProductions = Object.entries(dataBindings).reduce<
              Array<{ type: 'other' | 'doc'; key: string }>
            >((prev, [key, bond]) => {
              const [type, , productionKey] = bond.split('.');
              if (type === 'productions') {
                if (['post', 'votes', 'valuable'].includes(key)) {
                  prev.push({ type: 'other', key: productionKey });
                } else if (key === 'docs') {
                  prev.push({ type: 'doc', key: productionKey });
                }
              }
              return prev;
            }, []);

            const group =
              (groupId && activityGrouping?.groups?.[groupId]) || null;

            const hasParticpate = await activityProductions.reduce<
              Promise<boolean>
            >(async (prev, { type, key: productionKey }) => {
              const production = _.get(
                productions?.[productionKey],
                searchPath
              );
              let hasEditProduction: boolean = production;
              if (type === 'doc') {
                hasEditProduction = await Object.keys(production || {}).reduce<
                  Promise<boolean>
                >(
                  async (docEdited, docId) =>
                    (await docEdited) || (await isDocUpdated(docId)),
                  Promise.resolve(false)
                );
              }
              return (await prev) || hasEditProduction;
            }, Promise.resolve(false));

            return hasParticpate
              ? group
                ? i18next.t('common:groupX', {
                    number: group.number || 1,
                    groupPrefix: sessionGroupPrefix,
                  })
                : i18next.t('common:Present')
              : i18next.t('common:Absent');
          })
        )),
      ])
  );

  exportAsCsv(
    [
      [
        i18next.t('common:Email'),
        i18next.t('common:Name'),
        ...criteriaNames,
        ...activitiesName,
      ],
      ...rows,
    ],
    `WAP_${sessionTitle}__users.csv`
  );
};

export const exportFeedbacks = async (sessionId: string) => {
  const sessionTitle =
    (await fetchData(`/sessionsNext/${sessionId}/title`)) || '';

  const feedbacks: Record<string, Record<string, unknown>> = (await fetchData(
    `/sessionsNextData/${sessionId}/activities/feedback/productions/`
  )) || {};

  const feedbacksProductions: Productions =
    (await fetchData(
      `/sessionsNextTemplates/${sessionId}/activities/feedback/productions/`
    )) || {};

  const feedbacksByUser = Object.entries(feedbacks).reduce(
    (prec: Record<string, Record<string, unknown>>, [name, all]) => {
      Object.entries(all || {}).forEach(([userId, resp]) => {
        const cur = prec[userId];
        prec[userId] = {
          ...cur,
          [name]: resp,
        };
      });
      return prec;
    },
    {}
  );

  const users = _.keyBy(
    await Promise.all(
      Object.keys(feedbacksByUser).map(async (userId) => {
        const user = await fetchUser(userId);
        user.id = userId;
        return user;
      })
    ),
    (user) => user.id
  );

  let criteriaNames: string[] = [];
  let criteria: Record<string, string>[] = [];

  const post: Activity | null = await fetchData(
    `/sessionsNextTemplates/${sessionId}/activities/post/`
  );

  if (post) {
    const criteriaProductions = Object.values(post.productions || {}).filter(
      (prod) => prod.name.startsWith('crit')
    );

    criteriaNames = criteriaProductions.map(
      (production) => production.description || production.name
    );

    criteria = await Promise.all(
      criteriaProductions.map(async (production) => {
        let criterium: Record<string, string> =
          (await fetchData(
            `/sessionsNextData/${sessionId}/activities/post/productions/${production.name}`
          )) || {};
        return criterium;
      })
    );
  }

  const rows = Object.entries(feedbacksByUser).map(([key, feedbacks]) => {
    const res = [users[key]?.email, users[key]?.name];
    res.push(...criteria.map((crit) => crit[key] || ''));
    Object.entries(feedbacksProductions || {}).forEach(([name, production]) => {
      if (production.type === 'boolean') {
        res.push(
          feedbacks[name] ? i18next.t('common:yes') : i18next.t('common:no')
        );
      } else if (production.type === 'number') {
        res.push(_.isNumber(feedbacks[name]) ? `${feedbacks[name]}` : '');
      } else {
        res.push(feedbacks[name] || '');
      }
    });
    return res;
  });

  const headers = [i18next.t('common:Email'), i18next.t('common:Name')];

  headers.push(...criteriaNames);

  Object.values(feedbacksProductions || {}).forEach(({ description, name }) => {
    headers.push(description || name);
  });

  exportAsCsv([headers, ...rows], `WAP_${sessionTitle}__feedbacks.csv`);
};

export const exportCommunitySessionsParticipants = async (
  communityId: string
): Promise<void> => {
  const community = await fetchData<Community>(`communities/${communityId}`);

  if (!community) {
    return;
  }

  const sessions: Sessions = await fetchData<Sessions>(
    'sessionsNext',
    {},
    (ref) => ref.orderByChild('communityId').equalTo(communityId)
  );

  const participantsBySessions = await mapValuesAsync(
    sessions,
    (_val, sessionId) =>
      fetchData<TrueSet>(`sessionsNextData/${sessionId}/users/participants`, {})
  );

  const participantsSet = Object.values(participantsBySessions || {}).reduce(
    (prec: TrueSet, participants) => ({ ...prec, ...participants }),
    {}
  );

  console.log(participantsSet);

  const participants = await mapValuesAsync(participantsSet, (_val, userId) =>
    fetchUser(userId)
  );

  const users = Object.values(participants)
    .filter((user) => user && user.email)
    .map((user) => [user.name, user.email]);

  console.log(users);

  exportAsCsv(users, `${community.name}_participants.csv`);
};

export const exportAllUsersProduction = async (sessionId: string) => {
  type CsvExtractType = [string[], ((userId: string) => Promise<string>)[]];

  const session: Session = (await fetchData(`/sessionsNext/${sessionId}`))!;

  const activitiesData: any =
    (await fetchData(`/sessionsNextData/${sessionId}/activities`)) || {};

  const activities: Activities =
    (await fetchData(`/sessionsNextTemplates/${sessionId}/activities`)) || {};

  const usersSet: TrueSet =
    (await fetchData(`/sessionsNextData/${sessionId}/users/participants`)) ||
    {};

  const extractUser = (): CsvExtractType => {
    const getUser = memoize(fetchUser);
    return [
      [i18next.t('common:Email'), i18next.t('common:Name')],
      [
        async (userId: string) => (await getUser(userId)).email,
        async (userId: string) => (await getUser(userId)).name,
      ],
    ];
  };

  const extractPostActivity = (
    post: Activity,
    postData: Record<string, Record<string, string>>
  ): CsvExtractType => {
    const criteriaProductions = Object.values(post.productions || {}).filter(
      (prod) => prod.name.startsWith('crit')
    );

    const criteriaNames = criteriaProductions.map(
      (production) => production.description || production.name
    );

    return [
      [post.humanName || post.name, ...criteriaNames],
      [
        async (userId: string) => postData?.['post']?.[userId] || '',
        ...criteriaProductions.map(
          (crit) => async (userId: string) => postData?.[crit.name]?.[userId]
        ),
      ],
    ];
  };

  const extractDocumentActivity = (
    activity: Activity,
    activityData: any
  ): CsvExtractType => {
    const production = Object.values(activity.productions || {}).find(
      (production) => production.type === 'document'
    )!;

    const extractDocId = (
      groupId: string | undefined,
      userId: string
    ): string | undefined => {
      let ref = activityData?.[production.name];
      if (production.mode === 'ByGroup' && groupId) {
        ref = ref?.[groupId!];
      }
      if (production.multiplicity === 'Each' || production.mode === 'ByUser') {
        ref = ref?.[userId];
      }

      const docIdRecord = Object.entries(ref || {})[0];

      if (docIdRecord?.[1] === true) {
        return docIdRecord[0];
      }
    };

    const getGroup = (userId: string) => {
      const activityName = activity.name;

      return activitiesData?.[activityName]?.grouping?.groupOfUser?.[userId];
    };

    const getGroupName = (groupId: string | undefined) => {
      if (!groupId) {
        return '';
      }
      const activityName = activity.name;

      return (
        activitiesData?.[activityName]?.grouping?.groups?.[groupId]?.number ||
        'N/A'
      );
    };

    const headers = [];
    const extractors = [];

    if (activity.grouping.mode !== 'All') {
      headers.push(
        `${activity.humanName || activity.name} - ${i18next.t(
          'settings:Group'
        )}`
      );
      extractors.push(async (userId: string) => {
        const groupId = getGroup(userId);
        return groupId !== undefined
          ? i18next.t('common:groupX', {
              groupPrefix: session.groupPrefix || '',
              number: getGroupName(groupId),
            })
          : '';
      });
    }

    headers.push(`${activity.humanName || activity.name}`);

    const getCleanDoc = memoize(async (docId: string): Promise<string> => {
      const doc = (await getDoc(docId))?.content as TNode[];
      return textualizeDoc(doc);
    });

    extractors.push(async (userId: string) => {
      const groupId = getGroup(userId);
      const docId = extractDocId(groupId, userId);
      return (docId && (await getCleanDoc(docId))) || '';
    });

    return [headers, extractors];
  };

  const extractFeedbackActivity = (
    feedback: Activity,
    feedbackData: Record<string, Record<string, string>>
  ): CsvExtractType => {
    const feedbackProductions = Object.values(
      feedback.productions || {}
    ).filter((prod) => !!prod.description);

    const feedbackNames = feedbackProductions.map(
      ({ description, name }) =>
        `${feedback.humanName || feedback.name} - ${description || name}`
    );

    return [
      feedbackNames,
      feedbackProductions.map((production) => async (userId: string) => {
        const val = feedbackData?.[production.name]?.[userId];
        if (val === null || val === undefined) {
          return '';
        } else if (production.type === 'boolean') {
          return val ? i18next.t('common:yes') : i18next.t('common:no');
        } else if (production.type === 'number') {
          return _.isNumber(val) ? `${val}` : '';
        } else {
          return val || '';
        }
      }),
    ];
  };

  const extractActivity = (
    activity: Activity,
    activityData: any
  ): CsvExtractType => {
    if (activity.productions?.post) {
      return extractPostActivity(activity, activityData);
    } else if (activity.name === 'feedback') {
      return extractFeedbackActivity(activity, activityData);
    } else if (
      Object.values(activity.productions || {}).find(
        (production) => production.type === 'document'
      )
    ) {
      return extractDocumentActivity(activity, activityData);
    } else if (activity.name === 'choose') return [[], []];
    else {
      return extractFeedbackActivity(activity, activityData);
    }
  };

  const extractAll = (): CsvExtractType => {
    const [headers, extractors] = extractUser();
    const orderedActivities = _.sortBy(activities, 'index');
    orderedActivities.forEach((activity) => {
      const [h, e] = extractActivity(
        activity,
        activitiesData[activity.name]?.productions || {}
      );
      headers.push(...h);
      extractors.push(...e);
    });

    return [headers, extractors];
  };

  const [headers, extractors] = extractAll();

  const usersIds = Object.keys(usersSet);

  const rows = await Promise.all(
    usersIds.map((userId) =>
      Promise.all(extractors.map((extract) => extract(userId)))
    )
  );

  exportAsCsv([headers, ...rows], `WAP_${session.title}__full_extract.csv`);
};
