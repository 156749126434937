import React, { cloneElement } from 'react';
import { Alternatives, replaceText } from 'utils/utils';

export type FillInSentenceProps = {
  text: string;
  alternatives?: Alternatives<JSX.Element>;
  root?: boolean;
};

const FillInSentence = ({
  text,
  alternatives = [],
}: FillInSentenceProps): JSX.Element => {
  const newLines = text.split('\n');
  if (newLines.length > 1) {
    return (
      <>
        {newLines.map((line, index) => {
          return line ? (
            <div key={index}>
              <FillInSentence text={line} alternatives={alternatives} />
            </div>
          ) : (
            <br key={index} />
          );
        })}
      </>
    );
  } else {
    return replaceText(
      text,
      alternatives,
      (value, context) => cloneElement(value, { placeholder: context.matched }),
      (values) => (
        <>{values.map((value, index) => cloneElement(value, { key: index }))}</>
      ),
      (value) => <>{value}</>
    );
  }
};

export default FillInSentence;
