import moment from 'moment';
import _ from 'lodash';

import { domainUrl } from 'config';
import { DEFAULT_SESSION_TYPE_VALUE } from 'constants/AppConfig';
import { fetchData } from 'services/firebase';
import {
  Community,
  Session,
  SessionLanguage,
  SessionTypeType,
  TrueSet,
  User,
} from 'types/baseTypes';

const buildErrorMessageTags = (
  language: SessionLanguage | undefined,
  sessionType: SessionTypeType,
  sessionLocation: string | undefined,
  sessionMeetingUrl: string | undefined
) => {
  const sessionLocationTag =
    (sessionType === 'on_site' || sessionType === 'hybrid') && !sessionLocation
      ? `<p style=" width: max-content; white-space:pre-line;margin-left: 20px; background-color: #ffebe6; padding: 8px; border: 1px solid #de350b;">${
          language === 'en'
            ? "You didn't specify the event location"
            : "Le lieu n'est pas renseigné"
        }</p>`
      : `<p style="white-space:pre-line;margin-left: 20px;">${sessionLocation}</p>`;

  let sessionMeetingUrlTag = `<a href="${sessionMeetingUrl || ''}">${
    language === 'en'
      ? ' video conference link'
      : ' ce lien de visio-conférence'
  }</a>`;

  if (
    (sessionType === 'digital' || sessionType === 'hybrid') &&
    !sessionMeetingUrl
  ) {
    sessionMeetingUrlTag += `<p style=" width: max-content; white-space:pre-line;margin-left: 20px; background-color: #ffebe6; padding: 8px; border: 1px solid #de350b;">${
      language === 'en'
        ? "You didn't specify the videoconference link"
        : "Le lien de la visio-conférence n'est pas renseigné"
    }</p>`;
  }
  return [sessionLocationTag, sessionMeetingUrlTag];
};

export const buildCallToPostEmail = async (
  sessionId: string
): Promise<string> => {
  const session: Session | null = await fetchData(`/sessionsNext/${sessionId}`);

  const sessionSubject = session?.title;
  const scheduledAt = session?.scheduledAt;
  const session_date = scheduledAt
    ? moment(scheduledAt[0]).format('Do MMMM')
    : null;

  const sessionStartTime = scheduledAt
    ? moment(scheduledAt[0]).format('H[h]mm')
    : null;
  const sessionEndTime = scheduledAt
    ? moment(scheduledAt[1]).format('H[h]mm')
    : null;

  const sessionDateMinus2d = scheduledAt
    ? moment(scheduledAt[0]).subtract(2, 'days').format('Do MMMM')
    : null;

  const sessionType = session?.type || DEFAULT_SESSION_TYPE_VALUE;
  const sessionLocation = session?.location;
  const sessionMeetingUrl = session?.meetingUrl;
  const sessionLink = `${domainUrl}/${session?.accessCode}`;
  const community: Community | null = await fetchData(
    `communities/${session?.communityId}`
  );
  const organizers: TrueSet | null = await fetchData(
    `sessionsNextData/${sessionId}/users/organizers`
  );
  const organizerUserIds = Object.keys(organizers || {});
  const organizer: User | null =
    organizerUserIds.length > 0
      ? await fetchData(`users/${organizerUserIds[0]}`)
      : null;
  const communityName = community?.name || organizer?.name;

  const [sessionLocationTag, sessionMeetingUrlTag] = buildErrorMessageTags(
    session?.language,
    sessionType,
    sessionLocation,
    sessionMeetingUrl
  );

  return session?.language === 'en'
    ? `<p>Subject : Participate to peer-to-peer learning experience with WAP about <b>${sessionSubject}</b></p>

    <p>We invite you to a peer-to-peer session on <b>${session_date} from ${sessionStartTime} to ${sessionEndTime}</b> about <b>${sessionSubject}</b> ?</>

    <p>🙌 In a 100% collaborative and lively way you will:</p>
    <ul>
    <li>Get concrete feedback from your peers</li>
    <li>Discover best practices as well as effective techniques and tools</li>
    <li>Leave with a synthesis of your sharings</li>
    </ul>
    
    <p>🎥 Want to know more ? Watch this <a href="https://www.youtube.com/watch?v=vkIOJtA8Q6Y">1 min video</a>.</p>

    <p>>>>>> To participate, create your account on the peer-to-peer learning platform wap.live and answer the question at this link: <a href="${sessionLink}">${sessionLink}</a>, no later than  <b>${sessionDateMinus2d}</b> <<<<< <p/>
    
    </p>

    ${
      sessionType === 'on_site'
        ? `<p>⏰ We will meet <b>on ${session_date}</b>, few minutes before <b>${sessionStartTime}</b> to make sure everything is working well, at the following location:<br />${sessionLocationTag}</p>`
        : sessionType === 'digital'
        ? `<p>⏰ We will meet <b>on ${session_date}</b> at this ${sessionMeetingUrlTag} few minutes before <b>${sessionStartTime}</b> to make sure everything is working well.</p>`
        : `<p style="margin-bottom: 0;">⏰ We will meet <b>on ${session_date}</b> few minutes before <b>${sessionStartTime}</b> to make sure everything is working well:</p>
        <ul style="margin: 0;"><li>at this ${sessionMeetingUrlTag}</li></ul>
        <p style="margin: 0;">OR</p>
        <ul style="margin: 0;"><li>at the following location:</li></ul>
        ${sessionLocationTag}`
    }
    
    <p>Bring your computer with a recent version of Chrome or Firefox (no tablets)</p>
    
    <p>This session is organized by <b>${communityName}</b> with the startup WAP - We Are Peers, specialized in peer-to-peer learning. (Top 100 Edtech Startup in 2021 by Holoniq.)</p>`
    : `<p>Objet : Apprendre entre pairs avec la méthode WAP sur <b>${sessionSubject}</b></p>
    
    <p>Nous vous invitons à une session d'échanges entre pairs le <b>${session_date} entre ${sessionStartTime} et ${sessionEndTime}</b> à propos de <b>${sessionSubject}</b> ?</>

    <p>🙌 D'une manière 100% participative et vivante vous allez :</p>
    <ul>
    <li>Obtenir des retours d'expérience concrets et validés par vos pairs</li>
    <li>Découvrir des bonnes pratiques, techniques et outils efficaces</li>
    <li>Repartir avec une synthèse de tous vos partages</li>
    </ul>
    
    <p>🎥 Vous souhaitez en savoir plus ? Visionnez cette courte <a href="https://www.youtube.com/watch?v=9BVjOp7Aj-0">vidéo de 1 min</a>.</p>

    <p>>>>>> Pour participer, créez votre compte sur la plateforme d'apprentissage entre pairs wap.live et répondez à la question posée à ce lien : <a href="${sessionLink}">${sessionLink}</a>, au plus tard avant le <b>${sessionDateMinus2d}</b> <<<<< <p/>
    
    ${
      sessionType === 'on_site'
        ? `<p>⏰ RDV <b>le ${session_date}</b>, quelques minutes avant <b>${sessionStartTime}</b> pour vérifier que tout est OK, à cette adresse :<br />${sessionLocationTag}</p>`
        : sessionType === 'digital'
        ? `<p>⏰ RDV <b>le ${session_date} sur</b> ${sessionMeetingUrlTag} quelques minutes avant <b>${sessionStartTime}</b> pour vérifier que tout est OK.</p>`
        : `<p style="margin-bottom: 0;">⏰ RDV <b>le ${session_date}</b> quelques minutes avant <b>${sessionStartTime}</b> pour vérifier que tout est OK :</p>
        <ul style="margin: 0;"><li>sur ${sessionMeetingUrlTag}</li></ul>
        <p style="margin: 0;">OU</p>
        <ul style="margin: 0;"><li>à cette adresse :</li></ul>
        ${sessionLocationTag}`
    }
    
    <p>Munissez-vous de votre ordinateur avec une version récente de Chrome ou Firefox (pas de tablettes)</p>
    
    ${
      communityName
        ? `<p>Cette session est organisée par <b>${communityName}</b> avec la startup WAP - We Are Peers, spécialisée dans l'apprentissage entre pairs. (Top 100 Edtech Startup en 2021 par Holoniq.) </p>`
        : ''
    }`;
};

export const buildReCallToPostEmail = async (
  sessionId: string
): Promise<string> => {
  const session: Session | null = await fetchData(`/sessionsNext/${sessionId}`);
  const posts: Record<string, string> | null = await fetchData(
    `/sessionsNextData/${sessionId}/activities/post/productions/post`
  );

  const bestPosts = Object.values(posts || {}).sort((a, b) => {
    return b.length - a.length;
  });

  const sessionSubject = session?.title;
  const scheduledAt = session?.scheduledAt;
  const session_date = scheduledAt
    ? moment(scheduledAt[0]).format('Do MMMM')
    : null;

  const sessionStartTime = scheduledAt
    ? moment(scheduledAt[0]).format('H[h]mm')
    : null;
  const sessionEndTime = scheduledAt
    ? moment(scheduledAt[1]).format('H[h]mm')
    : null;

  const sessionLink = `${domainUrl}/${session?.accessCode}`;

  return session?.language === 'en'
    ? `<p>Subject : D-7 => Participate to peer-to-peer learning experience with WAP about <b>${sessionSubject}</b></p>

    <p>We invite you on <b>${session_date} from ${sessionStartTime} to ${sessionEndTime}</b> for a peer-to-peer learning session on <b>${sessionSubject}</b>.</>
  
    <p>🎥 Want to know more ? Watch this <a href="https://www.youtube.com/watch?v=vkIOJtA8Q6Y">1 min video</a>.</p>

    <p><b>>>>>>There is still time to create your account and post your answer to the questions asked on: <a href="${sessionLink}">${sessionLink}</a> <<<<<</b><p/>
    
    
    ${
      bestPosts.length > 0
        ? '<p>Need inspiration ? Here are some experiences already published by your peers on the platform</p>'
        : ''
    }
    ${
      bestPosts.length > 0
        ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[0]}</blockquote>`
        : ''
    }
    ${
      bestPosts.length > 1
        ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[1]}</blockquote>`
        : ''
    }
    ${
      bestPosts.length > 2
        ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[2]}</blockquote>`
        : ''
    }
    
    <p>Get started! All experiences are worth sharing.</p>`
    : `<p>Objet : J-7 => Apprendre entre pairs avec la méthode WAP sur <b>${sessionSubject}</b></p>

  <p>Nous nous retrouvons le <b>${session_date} de ${sessionStartTime} à ${sessionEndTime}</b> pour un atelier d'apprentissage entre pairs sur <b>${sessionSubject}</b>.</>
  
  <p>🎥 Vous êtes curieux et avez envie d'en savoir plus ? Visionnez cette courte <a href="https://www.youtube.com/watch?v=9BVjOp7Aj-0">vidéo de 1 min</a>.</p>

  <p><b>>>>>> Il est encore temps de créer votre compte et poster votre réponse aux questions posées sur : <a href="${sessionLink}">${sessionLink}</a> <<<<<</b><p/>
  
  
  ${
    bestPosts.length > 0
      ? '<p>Pour vous inspirer voici quelques expériences déjà publiées par vos pairs sur la plateforme</p>'
      : ''
  }
  ${
    bestPosts.length > 0
      ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[0]}</blockquote>`
      : ''
  }
  ${
    bestPosts.length > 1
      ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[1]}</blockquote>`
      : ''
  }
  ${
    bestPosts.length > 2
      ? `<blockquote style="margin: 0px 0px 20px 10px; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; background-color: #f8fafc;">${bestPosts[2]}</blockquote>`
      : ''
  }
  
  <p>Lancez-vous ! Toutes les expériences sont intéressantes à partager.</p>`;
};

export const buildRemindEventEmail = async (
  sessionId: string
): Promise<string> => {
  const session: Session | null = await fetchData(`/sessionsNext/${sessionId}`);

  const sessionSubject = session?.title;
  const scheduledAt = session?.scheduledAt;
  const sessionStartTime = scheduledAt
    ? moment(scheduledAt[0]).format('H[h]mm')
    : null;
  const sessionEndTime = scheduledAt
    ? moment(scheduledAt[1]).format('H[h]mm')
    : null;

  const sessionType = session?.type || DEFAULT_SESSION_TYPE_VALUE;
  const sessionLocation = session?.location;
  const sessionMeetingUrl = session?.meetingUrl;

  const sessionLink = `${domainUrl}/${session?.accessCode}`;

  const [sessionLocationTag, sessionMeetingUrlTag] = buildErrorMessageTags(
    session?.language,
    sessionType,
    sessionLocation,
    sessionMeetingUrl
  );

  return session?.language === 'en'
    ? `<p>Subject : D-Day => Participate to peer-to-peer learning experience with WAP about <b>${sessionSubject}</b></p>

  <p>Hello,</p>

  <p>You will participate to the peer to peer learning session from <b>${sessionStartTime} to ${sessionEndTime}</b>.</p>

  <p>Before the session:</p>
  <ul>
  <li>If necessary, create your account and post your answer to the questions asked on: <a href="${sessionLink}">${sessionLink}</a></li> 
  <li>Bring your computer with a recent version of Chrome or Firefox (no tablets)</li>
  </ul>

  ${
    sessionType === 'on_site'
      ? `<p>⏰ We will meet <b>today</b> few minutes before <b>${sessionStartTime}</b> at the following location:<br />${sessionLocationTag}</p>`
      : sessionType === 'digital'
      ? `<p>⏰ We will meet <b>today</b> at this ${sessionMeetingUrlTag} few minutes before <b>${sessionStartTime}</b>.</p>`
      : `<p style="margin-bottom: 0;">⏰ We will meet <b>today</b> few minutes before <b>${sessionStartTime}</b>:</p>
      <ul style="margin: 0;"><li>at this ${sessionMeetingUrlTag}</li></ul>
      <p style="margin: 0;">OR</p>
      <ul style="margin: 0;"><li>at the following location:</li></ul>
      ${sessionLocationTag}`
  }

  <p>Have a good day, see you soon</p>

  `
    : `<p>Objet : Jour J => Apprendre entre pairs avec la méthode WAP sur <b>${sessionSubject}</b></p>
  
  <p>Bonjour,</p> 

  <p>Vous allez participer à une session d'apprentissage entre pairs aujourd'hui entre <b>${sessionStartTime} et ${sessionEndTime}</b>.</p>
  
  <p>Avant la session :</p>
  <ul>
  <li>Si ce n'est pas déjà fait, créez votre compte sur la plateforme d'apprentissage entre pairs et répondez à la question à ce lien : <a href="${sessionLink}">${sessionLink}</a></li> 
  <li>Munissez-vous de votre ordinateur avec une version récente de Chrome ou Firefox (pas de tablettes)</li>
  </ul>

  ${
    sessionType === 'on_site'
      ? `<p>⏰ RDV <b>aujourd'hui</b> quelques minutes avant <b>${sessionStartTime}</b> à cette adresse :<br />${sessionLocationTag}</p>`
      : sessionType === 'digital'
      ? `<p>⏰ RDV <b>aujourd'hui sur</b> ${sessionMeetingUrlTag} quelques minutes avant <b>${sessionStartTime}</b>.</p>`
      : `<p style="margin-bottom: 0;">⏰ RDV <b>aujourd'hui</b> quelques minutes avant <b>${sessionStartTime}</b> :</p>
      <ul style="margin: 0;"><li>sur ${sessionMeetingUrlTag}</li></ul>
      <p style="margin: 0;">OU</p>
      <ul style="margin: 0;"><li>à cette adresse :</li></ul>
      ${sessionLocationTag}`
  }

  <p>Belle journée et à tout à l'heure</p>
  `;
};

export const buildConclusionEmail = async (
  sessionId: string
): Promise<string> => {
  const session: Session | null = await fetchData(`/sessionsNext/${sessionId}`);
  const posts: Session | null = await fetchData(
    `/sessionsNextData/${sessionId}/activities/post/productions/post`
  );

  const sessionSubject = session?.title;
  const nbPosts = _.size(posts || {});

  return session?.language === 'en'
    ? `<p>Objet : Peer learning workshop: ${sessionSubject}</p>
  
    <p>Hi,</p> 
  
    <p>Thanks for attending the <b>"${sessionSubject}"</b> We Are Peers workshop!</p>
  
    <p><b>${nbPosts} good practices</b> have been derived from your experiences.</p>
  
    <p>The outcome ? <b>A rich synthesis written in less than 1h</b> by ${nbPosts} participants.</p>
    
    <p>We also hoped that we proved to you through this worshop how efficient peer learning is.</p>
  
    <p>Please, give us a review of your WAP experience <a href="https://g.page/r/CYliDO9exbQgEB0/review">sur notre page Google</a> (5 stars 😇).</p>
  
    <p>Have a nice day,</p>

  `
    : `<p>Objet : Atelier d'apprentissage entre pairs WAP: ${sessionSubject}</p>
  
  <p>Bonjour à tous,</p> 

  <p>Merci pour votre participation à l'Atelier We Are Peers <b>${sessionSubject}</b> !</p>

  <p>Vous avez identifié <b>${nbPosts} pratiques</b> tirées de votre propre expérience.</p>

  <p>Résultat ? <b>Une synthèse co-écrite en moins de 1h</b> à ${nbPosts} participants.</p>
  
  <p>Par cette expérience, nous espérons aussi vous avoir prouvé l'efficacité de l'apprentissage entre pairs.</p>

  <p>Je vous invite à laisser un avis sur votre expérience WAP <a href="https://g.page/r/CYliDO9exbQgEB0/review">sur notre page Google</a> (5 étoiles 😇).</p>

  <p>Bonne journée,</p>
  `;
};
