import React from 'react';

import { DocMeta, Hit, User } from 'types/types';
import { WithId } from 'types/typesUtils';

import Tag from 'components/Tag';

import Avatar from 'components/Avatar';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Configure,
  connectHits,
  InstantSearch,
} from 'react-instantsearch-core';
import { client } from 'services/searching';
import { algoliaConf as conf } from 'config';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';

export type FullDocMeta = WithId<Hit> & {
  authors: Record<string, WithId<User>>;
  community: string;
};

export const DocCard = ({
  hit: doc,
  onDocClick,
}: {
  hit: FullDocMeta;
  onDocClick?: (url: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const { url, path } = useRouteMatch();

  const pathParts = path.slice(1).split('/');
  const nbParts = pathParts.length;

  const baseUrl = `/${url
    .slice(1)
    .split('/')
    .slice(0, pathParts[nbParts - 1] === ':docId' ? nbParts - 1 : nbParts)
    .join('/')}`;

  const history = useHistory();

  const authorArray = doc.authors ? Object.values(doc.authors) : null;

  return (
    <>
      <div
        onClick={() => {
          onDocClick?.(doc.id);
          history.push(`${baseUrl}/${doc.id}`);
        }}
        className="flex flex-row items-center rounded-xl border border-surfaces-divider p-6 hover:cursor-pointer hover:shadow"
      >
        <div className="mr-6 flex flex-grow flex-col space-y-1">
          {authorArray && authorArray.length > 0 ? (
            <div className="flex flex-row items-center space-x-2">
              <Avatar name={authorArray[0].name} size="sm" />
              <p>
                {authorArray[0].name}
                {authorArray.length > 1 && (
                  <span className="italic"> et al.</span>
                )}
              </p>
            </div>
          ) : null}
          <p className="break-all text-lg font-semibold line-clamp-2">
            {doc.title || doc.name || t('misc:NoTitle')}
          </p>
          <p className="flex-grow text-base text-black-soft line-clamp-3">
            {doc.abstract}
          </p>
          <div className="space-y-2">
            <p className="text-black-soft">
              {moment(doc.createdAt).format(t('misc:shortDateFormat'))}
            </p>
            <div className="flex-wrap space-y-1 space-x-2">
              {doc.type ? <Tag text={doc.type} color="gray" /> : null}
              {doc.community ? <Tag text={doc.community} color="gray" /> : null}
            </div>
          </div>
        </div>

        <img
          className="h-36 w-48 shrink-0 rounded-lg object-cover"
          src={doc.coverUrl || '/photography.jpg'}
          alt=""
        />
      </div>
    </>
  );
};

export type DocListProps = {
  hits: FullDocMeta[];
  title?: React.ReactNode;
  onDocClick?: (docId: string) => void;
};

export const DocList = ({ hits, title, onDocClick }: DocListProps) => {
  return (
    <div className="space-y-4">
      {hits.length ? title : null}
      <div className="space-y-3">
        {hits.map((hit) => (
          <DocCard key={hit.objectID} hit={hit} onDocClick={onDocClick} />
        ))}
      </div>
    </div>
  );
};

export const DocListHits: (props: Omit<DocListProps, 'hits'>) => JSX.Element =
  connectHits(DocList as any) as any;

export type LinkedDocsProps = {
  meta: DocMeta;
};

export const LinkedDocs = ({ meta }: LinkedDocsProps): JSX.Element => {
  const authorsIdsArray = meta.authorsIds
    ? Object.keys(meta.authorsIds || {})
    : Object.keys(meta.authors || {});

  const filters = `sessionId:${meta.sessionId} AND (${authorsIdsArray
    .map((id) => `authorsIds:${id}`)
    .join(' OR ')}) AND NOT id:${meta.id}`;

  return (
    <InstantSearch searchClient={client} indexName={conf.docsIndexName}>
      <Configure filters={filters} hitsPerPage={3} />
      <DocListHits
        title={
          <h3 className="text-xl font-semibold">{i18n.t('misc:LinkedDocs')}</h3>
        }
      />
    </InstantSearch>
  );
};
