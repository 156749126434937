import { QrCode } from 'assets/icons';
import ActionsButton from 'components/ActionButton';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';

export type QrCodeActionButtonProps = {
  url: string;
};

const QrCodeActionButton = ({ url }: QrCodeActionButtonProps): JSX.Element => {
  const [modalVisible, setViewModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={modalVisible}
        onClose={() => setViewModal(false)}
        body={
          <div className="sm:w-96 mx-12 mb-12">
            <h1 className="text-center font-semibold mb-8 text-2xl">
              {t('sessions:ScanQrCode')}
            </h1>
            <QRCodeSVG size={384} className="w-full" value={url} />
          </div>
        }
      />
      <ActionsButton
        key="sessionQrCode"
        label={t('sessions:QRCode')}
        size="sm"
        icon={<QrCode />}
        onClick={() => {
          setViewModal((val) => !val);
        }}
      />
    </>
  );
};

export default QrCodeActionButton;
