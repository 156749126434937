import moment from 'moment';
import React from 'react';

import i18n from 'services/i18n';

import { DayPickerInputProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const CustomDayPickerInput = (props: DayPickerInputProps): JSX.Element => {
  return (
    <>
      <DayPickerInput
        {...props}
        classNames={{
          container: 'DayPickerInput',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay:
            'absolute bg-white left-0 rounded-md mt-1 z-30 border border-surfaces-divider',
        }}
        dayPickerProps={{
          ...props.dayPickerProps,
          firstDayOfWeek:
            i18n.language === 'fr' ? 1 /* Monday*/ : 0 /* Sunday */,
          weekdayElement: (props) => {
            return (
              <span className="table-cell text-center">
                {moment.weekdaysMin(props.weekday)}
              </span>
            );
          },
          renderDay: (day: Date) => {
            return (
              <div className="flex h-4 w-4 flex-row items-center justify-center">
                <div className="">{day.getDate()}</div>
              </div>
            );
          },
          modifiersStyles: {
            selected: {
              color: 'white',
              backgroundColor: ' #0800c3',
              fontWeight: 'bold',
            },
          },
        }}
      />
    </>
  );
};

export default CustomDayPickerInput;
