import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Activity } from 'types/types';
import { useActivityProductionData } from 'model/productions';

import Post from 'blocks/Post';
import Spinner from 'components/Spinner';

import { Heart } from 'assets/icons';
import { computeOrderedVotes } from 'model/votes';

export type ChooseFacilitationScreenProps = {
  sessionId: string;
  activity: Activity;
  postActivity: Activity;
};

const ChooseFacilitationScreen = ({
  sessionId,
  activity,
  postActivity,
}: ChooseFacilitationScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const [votes, , , votesLoaded] = useActivityProductionData(
    sessionId,
    activity,
    activity.productions!['vote']!
  );

  const [posts, , users, postsLoaded] = useActivityProductionData(
    sessionId,
    postActivity,
    postActivity.productions!['post']!
  );

  const bestPosts = useMemo(
    () => computeOrderedVotes(votes).slice(0, 5),
    [votes]
  );

  return votesLoaded && postsLoaded ? (
    <div className="mx-auto flex h-full w-full max-w-3xl flex-col p-4">
      <h1 className="text-2xl font-semibold">
        {activity.humanName || activity.name || t('sessions:Choices')}
      </h1>
      <p>
        {t('sessions:NbPeopleHavingChoosen', {
          votesCount: _.size(votes),
          total: _.size(posts),
        })}
      </p>

      {bestPosts.length > 0 ? (
        <>
          <h2 className="mt-4 text-lg font-semibold">
            {t('sessions:bestPosts')}
          </h2>
          <div className="mt-2 space-y-2">
            {bestPosts.map(([userId, likes], index) => (
              <Post
                key={index}
                compact
                content={posts[userId]}
                userName={users[userId]?.name || t('users:UnknownUser')}
                actionIcon={
                  <div className="relative h-10 w-10">
                    <div className="absolute inset-0 flex">
                      <Heart
                        className={`m-auto w-full fill-current stroke-1 text-red-500`}
                      />
                    </div>
                    <div className="absolute inset-0 flex">
                      <p className="m-auto text-sm font-semibold text-white">
                        {likes}
                      </p>
                    </div>
                  </div>
                }
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  ) : (
    <Spinner className="h-8 w-8 text-primary" />
  );
};

export default ChooseFacilitationScreen;
