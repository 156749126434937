import { UserActivity, Users } from 'types/baseTypes';
import { UserType, User } from 'types/types';
import { auth, database, NOW, functions } from 'services/firebase';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useMemo } from 'react';
import { useMemoCompare } from 'utils/utils';
import {
  createCredentials,
  getAuthToken,
  sendPasswordResetEmail,
  confirmPasswordReset as confirmPasswordRst,
  verifyPasswordResetCode as verifyPasswordRstCode,
  regenerateCustomToken,
  updateEmail,
  updatePassword,
} from 'services/auth';
import { currentEmail, setCurrentEmail } from 'global';
import { isDevelopper } from 'constants/AppConfig';

export const addUsers = functions.httpsCallable('addUsers');

const ACTIVE_DELAY = 30 * 60000;

const db = database;

export const refreshCurrentEmail = async (userId: string) => {
  const email = (await db.ref(`users/${userId}/email`).get()).val();
  setCurrentEmail(email);
};

export const authenticateUser = async (email: string, password: string) => {
  const token = await getAuthToken(email, password);
  const { user } = await auth.signInWithCustomToken(token);
  setCurrentEmail(email);
  return user;
};

export const createUser = async (
  email: string,
  password: string,
  displayName: string
): Promise<string> => {
  const userId = await createCredentials(email, password, displayName);
  if (userId) {
    await authenticateUser(email, password);
  }
  return userId;
};

export const sendResetPasswordEmail = async (
  email: string,
  continueUrl: string
): Promise<void> => {
  let actionCodeSettings = {
    url: continueUrl,
  };
  await sendPasswordResetEmail(email, actionCodeSettings);
};

export const confirmPasswordReset = async (
  code: string,
  password: string
): Promise<void> => {
  await confirmPasswordRst(code, password);
};

export const verifyPasswordResetCode = async (
  code: string
): Promise<string> => {
  return await verifyPasswordRstCode(code);
};

export const updateMyName = async (newName: string) => {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    return database.ref(`users/${userId}`).update({
      name: newName,
    });
  }
};

export const reauthenticateUser = async (
  password: string
): Promise<boolean> => {
  const user = auth.currentUser;
  if (user && currentEmail) {
    try {
      console.log('getting token');
      const currentToken = await user.getIdToken();
      const token = await regenerateCustomToken(
        currentToken,
        currentEmail,
        password
      );
      console.log('resigning token');
      await auth.signInWithCustomToken(token);
      console.log('done');
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  } else {
    return false;
  }
};

export const updateMyEmail = async (newEmail: string) => {
  const user = auth.currentUser;
  if (user && currentEmail) {
    const userId = user.uid;

    const currentToken = await user.getIdToken();
    await updateEmail(currentToken, currentEmail, newEmail);
    setCurrentEmail(newEmail);

    return database.ref(`users/${userId}`).update({
      email: newEmail,
    });
  }
};

export const updateMyPassword = async (password: string) => {
  const user = auth.currentUser;
  if (user && currentEmail) {
    const currentToken = await user.getIdToken();
    await updatePassword(currentToken, currentEmail, password);
  }
};

export const deleteUser = async (userId: string) => {
  await db.ref(`users/${userId}`).remove();
  // all cleaning logic is launched in a DatabaseTriggerFunction
};

export const fetchUser = (userId: string) =>
  database
    .ref(`users/${userId}`)
    .once('value')
    .then((snap) => snap.val());

export const fetchUserActivity = (userId: string) =>
  database
    .ref(`usersActivity/${userId}`)
    .once('value')
    .then((snap) => snap.val());

export const fetchUserName = (userId: string) =>
  database
    .ref(`users/${userId}/name`)
    .once('value')
    .then((snap) => snap && snap.val());

export const observeUserName = (
  userId: string,
  callback: (name: string) => void
) => {
  const ref = database.ref(`users/${userId}/name`);
  ref.on('value', (snap) => callback(snap.val()));
  return () => ref.off();
};

export const setUserName = (userId: string, userName: string) =>
  database.ref(`users/${userId}/name`).set(userName);

export const setUserLastActive = (userId: string) => {
  database.ref(`usersActivity/${userId}/lastTimeActive`).set(NOW);
};

export const setUserExpertMode = (userId: string, expertMode: boolean) =>
  database.ref(`users/${userId}/expertMode`).set(expertMode);

export const isUserActive = (userActivity: UserActivity) => {
  const now = Date.now();
  return (
    userActivity?.lastTimeActive &&
    userActivity.lastTimeActive > now - ACTIVE_DELAY
  );
};

export const getTemporaryUser = (name: string) => {
  const ref = database.ref('users');
  const id = ref.push().key;
  const tempUser = {
    name,
    isTemporaryUser: true,
  };
  ref.child(id!).set(tempUser);
  return { ...tempUser, id };
};

export const observerUsers = (callback: any) => {
  const onUsersChange = (usersSnapshot: any) => {
    const users = usersSnapshot.val();
    callback(
      users &&
        Object.keys(users)
          .map((key) => {
            const user = users[key];
            return {
              id: key,
              email: user.email,
              ...user,
            };
          })
          .filter((user) => !user.isTemporaryUser && !!user.email)
    );
  };
  const ref = database.ref('users');
  ref.on('value', onUsersChange);
  return () => ref.off('value', onUsersChange);
};

export const observeTypesForUsers = (
  callback: (typeForUser: UserType) => void
) => {
  const ref = database.ref('typeForUser');
  const handler = (snap: any) => callback(snap.val());
  ref.on('value', handler);
  return () => ref.off('value', handler);
};

export const changeUserType = (userId: string, userType: UserType) =>
  database.ref('typeForUser').child(userId).set(userType);

// this does not seem to work well enough to be used in prod
// actually becomes blocking for many users
export const checkIfUserExists = async (email: string) => {
  if (!email) {
    return false;
  }

  try {
    await auth.signInWithEmailAndPassword(
      email,
      '----wap-----this-password-needs-to-be-long-enough'
    );
    console.error(
      'THIS SHOULD NEVER HAPPEN. Apart if someone is weird enough to have this fake password as its own password.'
    );
    return true;
  } catch ({ code }) {
    return code !== 'auth/user-not-found';
  }
};

export const findRelevantUser = async (
  sessionId: string,
  activityName: string,
  groupId?: string
) => {
  const groups: Record<string, Record<string, boolean>> = (
    await db
      .ref(
        `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/`
      )
      .once('value')
  ).val() || {};

  //we find a user in selected group
  const selectedGroupUsers = groupId ? groups?.[groupId]?.users : {};
  const selectedGroupUserIds = Object.keys(selectedGroupUsers || {});
  if (selectedGroupUserIds.length > 0) {
    return selectedGroupUserIds[0];
  }

  // else we find user into another grop of this activity
  const groupsData = Object.values(groups);
  for (const group of groupsData) {
    const userIds = Object.keys(group.users);
    if (userIds[0]) {
      return userIds[0];
    }
  }

  const sessionUsers = (
    await db
      .ref(`sessionsNextData/${sessionId}/users/participants`)
      .once('value')
  ).val();

  const users = Object.keys(sessionUsers || {});

  //  else we find the first active user
  for (const userId of users) {
    const userActivity = (
      await db.ref(`usersActivity/${userId}`).once('value')
    ).val();
    if (isUserActive(userActivity)) {
      return userId;
    }
  }

  // else we return first user
  return users[0];
};

export const useUsers = <T>(
  userIds: Record<string, T>
): [Record<string, User>, boolean] => {
  useFirebaseConnect(Object.keys(userIds).map((userId) => `users/${userId}`));
  const allUsers: Record<string, User> | null = useSelector((state: any) => {
    return state.firebase?.data?.users;
  });

  const users = useMemo(() => {
    return _.pick(allUsers || {}, Object.keys(userIds));
  }, [allUsers, userIds]);

  const memoizedUsers = useMemoCompare(users, _.isEqual);

  return [
    memoizedUsers,
    !!allUsers &&
      isLoaded(
        allUsers,
        ...Object.keys(userIds).map((userId) => allUsers[userId])
      ),
  ];
};

export const useUser = (userId: string): [User, boolean] => {
  useFirebaseConnect({
    path: `users/${userId}`,
    storeAs: `specificUsers/${userId}`,
  });
  const user = useSelector((state: any) => {
    return state.firebase?.data?.specificUsers?.[userId];
  });
  return [user, isLoaded(user)];
};

export const useUserType = (userId: string): [UserType, boolean] => {
  useFirebaseConnect(`typeForUser/${userId}`);
  const userType = useSelector((state: any) => {
    return state.firebase?.data?.typeForUser?.[userId];
  });
  return [userType || 'participant', isLoaded(userType)];
};

export const getUserFromEmail = async (
  email: string
): Promise<[string, User] | null> => {
  const users: Users = (
    await database.ref('users').orderByChild('email').equalTo(email).get()
  ).val();

  return _.size(users) ? Object.entries(users)[0] : null;
};

export const isGlobalAdmin = (userType: UserType): boolean =>
  isDevelopper() || userType === 'admin';
