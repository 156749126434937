import React from 'react';

type SurfaceProps = {
  children: React.ReactNode;
  className?: string;
  flat?: boolean;
};

const Surface = ({
  children,
  className = '',
  flat = false,
}: SurfaceProps): JSX.Element => {
  return (
    <div
      className={`z-10 bg-white ${
        flat ? '' : 'border shadow-sm'
      } rounded-md  ${className}`}
    >
      {children}
    </div>
  );
};

export default Surface;
