import { functions } from './firebase';

const getTokenFirebase = functions.httpsCallable('agora-getToken');

export const getToken = async (
  channelName: string,
  uid: string
): Promise<string> => {
  const result = await getTokenFirebase({
    channelName,
    uid,
  });

  return result.data;
};
