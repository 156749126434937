import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import GroupAssignment from 'assets/undraw/GroupAssignment';

import { Activity } from 'types/types';
import { useGroups } from 'model/dataHooks';
import Spinner from 'components/Spinner';
import { addUserToGroup } from 'model/groupManagement';

export type GroupAssignementViewProps = {
  canJoinGroup?: boolean;
  activity?: Activity;
  sessionId: string;
  userId: string;
  groupPrefix: string;
};

export type GroupPickerProps = {
  sessionId: string;
  activity?: Activity;
  onGroupClick?: (groupId: string, activityName: string) => void;
  groupPrefix: string;
};

const GroupPicker = ({
  sessionId,
  activity,
  onGroupClick,
  groupPrefix,
}: GroupPickerProps): JSX.Element => {
  const [searchString, setSearchString] = useState<string>('');
  const { t } = useTranslation();

  const [groups, loaded, activityName] = useGroups(sessionId, activity);

  const groupsArray = Object.entries(groups || {});

  return !loaded ? (
    <Spinner />
  ) : (
    <>
      <h2 className="mt-10 px-5 text-center text-xl md:px-10 md:text-2xl xl:px-20 xl:text-3xl">
        {t('sessions:selectYourGroup')}
      </h2>
      <div className="my-4 w-full">
        <SearchInput
          placeholder={t('common:Search')}
          value={searchString}
          onChange={(value) => setSearchString(value)}
        />
      </div>
      <div className="mt-4 grid grid-cols-4 gap-4 overflow-y-auto p-1">
        {groupsArray
          .filter(([, { number }]) =>
            t('common:groupX', {
              number: number,
              groupPrefix: groupPrefix || '',
            })
              .toUpperCase()
              .includes(searchString.toUpperCase())
          )
          .map(([groupId, { number }]) => {
            return (
              <button
                key={groupId}
                onClick={() => onGroupClick?.(groupId, activityName!)}
                className="cursor-pointer rounded-md bg-primary-soft py-4 text-center text-lg uppercase text-primary hover:bg-primary hover:text-white"
              >
                {t('common:groupX', {
                  number: number,
                  groupPrefix: groupPrefix || '',
                })}
              </button>
            );
          })}
      </div>
    </>
  );
};

const GroupAssignementView = ({
  canJoinGroup = false,
  sessionId,
  activity,
  userId,
  groupPrefix,
}: GroupAssignementViewProps): JSX.Element => {
  const { t } = useTranslation();

  return canJoinGroup ? (
    <GroupPicker
      sessionId={sessionId}
      activity={activity}
      onGroupClick={(groupId, activityName) =>
        addUserToGroup(sessionId, activityName, groupId, userId)
      }
      groupPrefix={groupPrefix}
    />
  ) : (
    <>
      <h2 className="mt-10 px-5 text-center text-xl md:px-10 md:text-2xl xl:px-20 xl:text-3xl">
        {t('sessions:missingGroupMessage')}
      </h2>
      <GroupAssignment className="mx-auto mt-10 w-72 fill-current text-primary md:w-96" />
    </>
  );
};

export default GroupAssignementView;
