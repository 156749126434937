import React from 'react';
import { useTranslation } from 'react-i18next';
import { Confetti } from 'assets/misc';

const CongratulationsView = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex max-w-lg flex-col items-center">
      <Confetti className="h-40 w-40" />
      <h1 className="mt-8 text-center text-3xl font-semibold">
        {t('sessions:congratsPostTitle')}
      </h1>
      <p className="mt-4  mb-12 text-center text-xl font-semibold text-gray-500">
        {t('sessions:congratsPostDescription')}
      </p>
    </div>
  );
};

export default CongratulationsView;
