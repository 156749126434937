import * as React from 'react';

function SvgXx(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" {...props}>
      <path
        d="M.5.5h638.9v478.9H.5z"
        fill="#fff"
        fillRule="evenodd"
        stroke="#adb5bd"
        strokeWidth={20}
      />
      <path
        d="M.5.5l639 479M639.5.5l-639 479"
        fill="none"
        stroke="#adb5bd"
        strokeWidth={20}
      />
    </svg>
  );
}

export default SvgXx;
