import React, { Fragment, useMemo, createElement } from 'react';
import { RadioGroup } from '@headlessui/react';
import {
  Angry,
  Pouting,
  Neutral,
  Slightly,
  Happy,
  Furious,
  Skeptical,
  HeartEyes,
} from 'assets/smileys';

export type SmileyNoteType = number | null;
type SmileyIcon = React.FunctionComponent<any>;

type SmileyRatingProps = {
  expressive?: boolean;
  value?: SmileyNoteType;
  setValue?: (value: SmileyNoteType) => void;
  showValue?: boolean;
  readOnly?: boolean;
};

const SmileyRating = ({
  expressive = false,
  value = null,
  setValue = () => {},
  showValue = false,
  readOnly = false,
}: SmileyRatingProps): JSX.Element => {
  const ratingIcons = useMemo<SmileyIcon[]>(() => {
    return expressive
      ? [Furious, Angry, Skeptical, Happy, HeartEyes]
      : [Pouting, Angry, Neutral, Slightly, Happy];
  }, [expressive]);

  return (
    <RadioGroup
      value={value !== null ? Math.round(value) : value}
      onChange={setValue}
    >
      <div className="flex flex-row items-center justify-center space-x-3">
        {ratingIcons.map((Icon, index) => {
          return (
            <RadioGroup.Option key={index} as={Fragment} value={index + 1}>
              {({ checked }) => (
                <div className="flex flex-col items-center">
                  <div
                    className={`h-12 w-12 rounded-full bg-gray-soft p-2  ${
                      checked ? 'opacity-100' : 'opacity-50'
                    } ${!readOnly ? 'cursor-pointer hover:opacity-100' : ''}`}
                  >
                    {createElement(Icon, {
                      className: `w-8 h-8 rounded-full ${
                        checked ? 'ring-2 ring-offset-2 ring-primary' : ''
                      }`,
                    })}
                  </div>
                  {showValue && value && Math.round(value) === index + 1 ? (
                    <p className="text-lg font-semibold text-primary">
                      {value && Math.round(value * 10) / 10}
                    </p>
                  ) : null}
                </div>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
};

export default SmileyRating;
