import React, { useState } from 'react';

import { Cell, Pie, PieChart as PC, Sector } from 'recharts';

const COLORS = [
  '#0026e4',
  //  '#3351e9',
  '#5a72ed',
  //  '#8092f1',
  '#a5b2f5',
  //  'cbd2f9#',
  '#f3f4fd',
];

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text
        fontSize={10}
        x={cx}
        y={cy}
        dy={-90}
        textAnchor="middle"
        fill={fill}
      >
        {payload.name} ({payload.value})
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 2}
        outerRadius={outerRadius + 2}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return percent >= 0.25 ? (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={10}
    >
      <tspan>{name}</tspan>
      <tspan x={x} dy={10}>{`${(percent * 100).toFixed(0)}%`}</tspan>
    </text>
  ) : null;
};

export type PieCharData = {
  name: string;
  value: number;
}[];

export type PieChartProps = {
  data: PieCharData;
};

const PieChart = ({ data }: PieChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const onPieEnter = (_dummy: any, index: number) => {
    setActiveIndex(index);
  };

  const onPieExit = (_dummy: any, index: number) => {
    setActiveIndex(undefined);
  };

  return (
    <PC width={200} height={200}>
      <Pie
        onMouseEnter={onPieEnter}
        onMouseLeave={onPieExit}
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        label={renderCustomizedLabel}
        isAnimationActive={false}
        dataKey="value"
        nameKey="name"
        labelLine={false}
        data={data}
        fill="indigo"
        paddingAngle={1}
      >
        {data.map((_value: any, index: number) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PC>
  );
};

export default PieChart;
