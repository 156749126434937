import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { RoleBar } from 'types/types';
import { useCommunities } from 'model/communitiesManagement';

const { Option } = Select;

const CommunitySelector = ({
  userId,
  role,
  selected,
  onChange,
}: {
  userId: string;
  role: RoleBar;
  selected?: string;
  onChange: (val: string) => Promise<void>;
}): JSX.Element => {
  const [communities, loading] = useCommunities(userId, role);

  const communitiesArray = _.sortBy(
    Object.entries(communities || {}).map(([key, community]) => ({
      id: key,
      ...community,
    })),
    'name'
  );

  return (
    <Select
      disabled={loading}
      style={{ minWidth: '160px' }}
      showSearch
      value={selected}
      onChange={onChange}
      filterOption={(input, option) => {
        return option?.data?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {communitiesArray.map(({ id, name }) => (
        <Option key={id} value={id} data={name}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default CommunitySelector;
