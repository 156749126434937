import React, { useMemo } from 'react';
import { List, Space, Card, Button, Tag } from 'antd';
import _ from 'lodash';

import { MailOutlined } from '@ant-design/icons';
import { FullDocMeta } from './types';
import { User } from 'types/types';
import { WithId } from 'types/typesUtils';

const ContentList = ({
  docs,
  selectDoc,
  user,
}: {
  docs: FullDocMeta[];
  selectDoc?: (doc: FullDocMeta) => void;
  user?: WithId<User>;
}) => {
  const orderedDocs = useMemo(() => _.sortBy(docs, ['type', 'id']), [docs]);

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={orderedDocs}
      renderItem={(item) => (
        <Card
          hoverable={!!selectDoc}
          style={{ marginBottom: '12px', marginTop: '12px' }}
          onClick={(event) => {
            selectDoc?.(item);
          }}
          bodyStyle={{ padding: '0px' }}
        >
          <List.Item
            key={item.id}
            extra={
              <img
                style={{
                  width: '200px',
                  height: '150px',
                  objectFit: 'cover',
                }}
                alt="logo"
                src={item.coverUrl || '/photography.jpg'}
              />
            }
          >
            <List.Item.Meta title={item.title || item.name} />

            <div>
              {item.abstract || 'This document is empty'}...
              {<Button type="link">See more</Button>}
            </div>
            <div style={{ marginTop: '8px' }}>
              <Tag>Type: {item.type}</Tag>
              <Tag>Community: {item.community}</Tag>
            </div>
          </List.Item>
          <Space
            // size="large"
            style={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: '20px',
              paddingBottom: '5px',
              width: '100%',
            }}
          >
            {item.authors &&
              Object.values(item.authors).map((user: WithId<User>) => (
                <Button
                  key={user.id}
                  icon={<MailOutlined />}
                  // size="small"
                  href={`mailto:${user.email}`}
                  style={{ marginBottom: '10px' }}
                  onClick={(event) => event.stopPropagation()}
                >
                  {user.name}
                </Button>
              ))}
          </Space>
        </Card>
      )}
    />
  );
};

export default ContentList;
