import React, { useState, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { Mode } from 'types/ui';
import { PostItem } from 'types/ui';

import { buildPost } from 'utils/dataGenerator';
import { useUsers } from 'model/users';
import { useEstimatedParticipantsCount } from 'model/sessions';

import LayoutFormPreview from 'blocks/LayoutFormPreview';

import List from 'components/List';
import Post from 'blocks/Post';
import LikeIcon from 'blocks/LikeIcon';
import Button from 'components/Button';

import { VOTES_PER_USER } from 'constants/AppConfig';
import { getGeneratedName } from 'services/nameGenerator';

type VoteScreenViewProps = {
  yourChoices: string;
  moreTopics: string;
  noTopic: string;
  thanks: string;
  posts: PostItem[];
  myPost: PostItem | null;
  setVotes: (userId: string) => void;
  unsetVotes: (userId: string) => void;
  noLimit: boolean;
};

const VoteScreenView = ({
  yourChoices,
  moreTopics,
  noTopic,
  thanks,
  posts,
  myPost,
  setVotes,
  unsetVotes,
  noLimit,
}: VoteScreenViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [hasValidate, setHasValidate] = useState(false);
  const selectedPost = posts.filter((post) => post.isLiked);
  const nbMaxVotes = noLimit ? Number.MAX_VALUE : VOTES_PER_USER;

  const renderHeartIcon = (
    liked: boolean,
    userId: string,
    disabled: boolean
  ) => {
    return (
      <LikeIcon
        disabled={disabled}
        liked={liked}
        onClick={() => {
          if (liked) {
            unsetVotes(userId);
          } else if (!disabled) {
            setVotes(userId);
          }
        }}
      />
    );
  };
  return !hasValidate ? (
    <div className="space-y-4">
      <div>
        <List<PostItem>
          title={yourChoices || t('sessions:defaultMyVotesTitle')}
          items={selectedPost}
          itemsPerPage={20}
          showCount={false}
          renderItem={(post) => {
            const userName = post.author?.name || t('common:unknowUser');
            return (
              <Post
                content={post.content}
                userName={userName}
                actionIcon={renderHeartIcon(true, post.author.userId, false)}
              />
            );
          }}
          renderEmptyList={() => {
            return (
              <div className="rounded-lg bg-warning-soft p-3 font-semibold text-warning">
                {noTopic || 'You have not chosen any topic'}
              </div>
            );
          }}
        />

        <div className="mt-4 flex flex-row justify-end">
          <Button
            text={t('common:validate')}
            onClick={() => setHasValidate(true)}
          />
        </div>
      </div>
      <List<PostItem>
        title={moreTopics || t('sessions:defaultParticipantsVotesTitle')}
        items={posts}
        itemsPerPage={20}
        showCount={false}
        renderItem={(post) => {
          const userName = post.author?.name || t('common:unknowUser');

          const liked =
            selectedPost.findIndex((_post) => _.isEqual(_post, post)) !== -1;
          const disabled = selectedPost.length >= nbMaxVotes;
          return (
            <Post
              content={post.content}
              userName={userName}
              actionIcon={renderHeartIcon(liked, post.author.userId, disabled)}
            />
          );
        }}
      />
    </div>
  ) : (
    <div className="text-center">
      <h3 className="text-xl font-semibold">
        {thanks || t('sessions:defaultThankyouMessage')}
      </h3>
      <div className="mt-4 flex flex-row justify-center">
        <Button text="Back" onClick={() => setHasValidate(false)} />
      </div>
    </div>
  );
};

type VoteScreenFromProps = {
  yourChoices: string;
  setYourChoices: (yourChoice: string) => void;
  moreTopics: string;
  setMoreTopics: (moreTopics: string) => void;
  noTopic: string;
  setNoTopic: (noTopic: string) => void;
  thanks: string;
  setThanks: (thanks: string) => void;
  simulatedParticipantsCount: number;
};

const VoteScreenForm = ({
  yourChoices,
  setYourChoices,
  moreTopics,
  setMoreTopics,
  noTopic,
  setNoTopic,
  thanks,
  setThanks,
  simulatedParticipantsCount,
}: VoteScreenFromProps): JSX.Element => {
  const { t } = useTranslation();
  const commonFieldClassName = 'w-full p-3 border rounded-md mt-2';
  const commonLabelClassName = 'text-lg text-primary';
  return (
    <Formik
      initialValues={{
        yourChoices: yourChoices,
        moreTopics: moreTopics,
        noTopic: noTopic,
        thanks: thanks,
        participantsCount: simulatedParticipantsCount,
      }}
      validate={(values) => {
        if (setYourChoices && values.yourChoices !== yourChoices) {
          setYourChoices(values.yourChoices);
        }
        if (setMoreTopics && values.moreTopics !== moreTopics) {
          setMoreTopics(values.moreTopics);
        }
        if (setNoTopic && values.noTopic !== noTopic) {
          setNoTopic(values.noTopic);
        }
        if (setThanks && values.thanks !== thanks) {
          setThanks(values.thanks);
        }
        return {};
      }}
      onSubmit={() => {
        // Do nothing
      }}
    >
      <Form className="space-y-4">
        <div>
          <label htmlFor="yourChoices" className={`${commonLabelClassName}`}>
            {t('sessions:votesYourchoicesLabel')}
          </label>
          <Field
            id="yourChoices"
            name="yourChoices"
            className={`${commonFieldClassName}`}
          />
        </div>
        <div>
          <label htmlFor="moreTopics" className={`${commonLabelClassName}`}>
            {t('sessions:votesMoreTopicsLabel')}
          </label>
          <Field
            id="moreTopics"
            name="moreTopics"
            className={`${commonFieldClassName}`}
          />
        </div>
        <div>
          <label htmlFor="noTopic" className={`${commonLabelClassName}`}>
            {t('sessions:votesNoTopicLabel')}
          </label>
          <Field
            id="noTopic"
            name="noTopic"
            className={`${commonFieldClassName}`}
          />
        </div>
        <div>
          <label htmlFor="thanks" className={`${commonLabelClassName}`}>
            {t('sessions:votesThankyouLabel')}
          </label>
          <Field
            id="thanks"
            name="thanks"
            className={`${commonFieldClassName}`}
          />
        </div>
      </Form>
    </Formik>
  );
};

type VoteScreenProps = VoteScreenViewProps &
  VoteScreenFromProps & {
    userId: string;
    sessionId: string;
    mode: Mode;
    votes: any;
    posts: Record<string, string>;
  };

const VoteScreen = (props: VoteScreenProps): JSX.Element => {
  const [simulatedVotes, setSimultatedVote] = useState<Record<string, boolean>>(
    {}
  );
  const simulatedParticipantsCount = useEstimatedParticipantsCount(
    props.sessionId
  );

  const { posts, setVotes, unsetVotes, myPost, ...restProps } = props;
  const votes = useMemo(() => props.votes || {}, [props.votes]);

  const [users] = useUsers(posts || {});

  const [currentPostUserId, currentPostContent] = Object.entries(
    posts || {}
  ).find(([userId]) => props.userId === userId) || [props.userId, ''];
  const userPost =
    currentPostUserId && (currentPostContent || props.mode === 'participant')
      ? {
          author: {
            userId: currentPostUserId,
            name: users[currentPostUserId]?.name,
          },
          content: currentPostContent,
          isLiked: !!votes[currentPostUserId],
        }
      : buildPost();

  const postsArray =
    _.size(posts || {}) > 0
      ? Object.entries(posts || {})
          .filter(([userId]) => props.userId !== userId)
          .map(([userId, content]) => {
            return {
              author: {
                userId: userId,
                name:
                  props.mode === 'participant' || props.mode === 'preview'
                    ? getGeneratedName(userId)
                    : users[userId]?.name,
              },
              content: content,
              isLiked:
                props.mode === 'participant'
                  ? !!votes[userId]
                  : !!simulatedVotes[userId],
            };
          })
      : Array.from({ length: simulatedParticipantsCount }, (_, i) => {
          const post = buildPost(i, simulatedVotes);
          post.author.name = getGeneratedName(`${i * 2}`);
          return post;
        });

  const handleSetVote =
    props.mode !== 'editor' && props.mode !== 'preview'
      ? setVotes
      : (userId: string) =>
          setSimultatedVote((votes: Record<string, boolean>) => {
            votes[userId] = true;
            return { ...votes };
          });
  const handleUnSetVote =
    props.mode !== 'editor' && props.mode !== 'preview'
      ? unsetVotes
      : (userId: string) =>
          setSimultatedVote((votes: Record<string, boolean>) => {
            delete votes[userId];
            return { ...votes };
          });

  return (
    <div className="mt-8">
      <LayoutFormPreview
        mode={props.mode}
        form={
          <VoteScreenForm
            {...restProps}
            simulatedParticipantsCount={simulatedParticipantsCount}
          />
        }
        view={
          <VoteScreenView
            myPost={userPost}
            posts={postsArray}
            setVotes={handleSetVote}
            unsetVotes={handleUnSetVote}
            {...restProps}
          />
        }
      />
    </div>
  );
};

export default VoteScreen;
