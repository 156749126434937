import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Form, Popconfirm, Button, Select } from 'antd';
import './editableTable.css';

const { Option } = Select;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  rules,
  options,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && !options) {
      inputRef.current.focus();
    }
  }, [editing, options]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`
        //   }
        // ]}
        rules={rules}
      >
        {options ? (
          <Select onChange={save}>
            {options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [...props.columns];

    if (this.props.handleDelete) {
      this.columns.push({
        title: 'Actions',
        dataIndex: 'operation',
        render: (_, record) => (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => this.props.handleDelete(record.key)}
          >
            <Button>Delete</Button>
          </Popconfirm>
        ),
      });
    }
  }

  render() {
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          options: col.options,
          title: col.title,
          rules: col.rules,
          handleSave: this.props.handleSave,
        }),
      };
    });
    return (
      <div>
        <Table
          size="small"
          pagination={false}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={this.props.dataSource}
          columns={columns}
        />
        {this.props.handleAdd && (
          <Button
            onClick={this.props.handleAdd}
            size="small"
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            Add
          </Button>
        )}
      </div>
    );
  }
}

export default EditableTable;
