import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTopics } from 'model/productions';
import { useGroups } from 'model/dataHooks';
import { useParticipants } from 'model/sessions';
import { addRootNode, addNode } from 'frameworks/mind-elixir/utils';
import { colors } from 'frameworks/mind-elixir/colors';

import { LinkWithIcon } from 'components/Link';
import { PlayCircle } from 'assets/icons';
import MindMap from 'frameworks/mind-elixir/MindMap';
import Spinner from 'components/Spinner';
import { Activities, Activity, Group, Topic } from 'types/types';

const UNKNOWN_TOPIC_ID = 'unknown';

export type GroupsOverviewProps = {
  sessionId: string;
  activities: Activities;
  activity: Activity;
  groupPrefix: string;
  showMindmap: (show: boolean) => void;
};

const GroupsOverview = ({
  sessionId,
  activities,
  activity,
  groupPrefix,
  showMindmap,
}: GroupsOverviewProps): JSX.Element => {
  const { t } = useTranslation();

  const [topics, isTopicsLoaded] = useTopics(sessionId);
  const [groups, isGroupLoaded] = useGroups(sessionId, activity);
  const [participants, isParticipantsLoaded] = useParticipants(sessionId);

  const topicsGroups = useMemo(() => {
    return Object.values(groups || {}).reduce<Record<string, Group[]>>(
      (prev, group) => {
        const topicId = group.topic || UNKNOWN_TOPIC_ID;

        if (prev[topicId]) {
          prev[topicId].push(group);
        } else {
          prev[topicId] = [group];
        }

        return prev;
      },
      {}
    );
  }, [groups]);

  const mindMapMemoized = useMemo(() => {
    if (!isTopicsLoaded && isGroupLoaded && isParticipantsLoaded)
      return <Spinner className="h-6 w-6 text-primary" />;

    const rootNode = addRootNode(
      activities?.post?.screens?.create?.content.template.editableContent
        .question
    );

    if (topicsGroups[UNKNOWN_TOPIC_ID]) {
      const unknownTopic: Topic = {
        description: t('misc:UnknownTopic'),
        color: 'gray',
      };
      topics[UNKNOWN_TOPIC_ID] = unknownTopic;
    }

    Object.entries(topics).forEach(([topicId, topic]) => {
      if (rootNode?.nodeData?.children) {
        const topicColor = colors[topic.color];
        const newtopicsChild = addNode({
          id: topicId,
          label: topic.description,
          style: topicColor
            ? {
                background: topicColor.default,
                color: 'white',
              }
            : {},
        });
        if (topicsGroups[topicId]) {
          topicsGroups[topicId].forEach((group) => {
            if (newtopicsChild?.children) {
              const groupNode = addNode({
                label: t('common:groupX', {
                  number: group.number || 1,
                  groupPrefix: groupPrefix,
                }),
              });
              Object.keys(group.users).forEach((userId) => {
                if (groupNode?.children) {
                  groupNode.children.push(
                    addNode({
                      label:
                        participants[userId]?.name || t('users:UnknownUser'),
                    })
                  );
                }
              });
              newtopicsChild.children.push(groupNode);
            }
          });
        }
        rootNode.nodeData.children.push(newtopicsChild);
      }
    });
    return <MindMap readOnly={true} data={rootNode} />;
  }, [
    activities?.post?.screens?.create?.content.template.editableContent
      .question,
    topics,
    isTopicsLoaded,
    topicsGroups,
    isGroupLoaded,
    isParticipantsLoaded,
    participants,
    t,
    groupPrefix,
  ]);

  return (
    <div className="w-full">
      <div className="flex grow flex-row items-center space-x-4">
        <h2 className="text-2xl font-semibold">
          {t('facilitator:GroupsOverview')}
        </h2>
        <LinkWithIcon
          text={t('facilitator:BackToGroupAnimation')}
          onClick={() => {
            showMindmap(false);
          }}
        >
          <PlayCircle className="h-6 w-6 shrink-0 stroke-3/2" />
        </LinkWithIcon>
      </div>
      <div className="mt-4">{mindMapMemoized}</div>
    </div>
  );
};

export default GroupsOverview;
