import React, { forwardRef } from 'react';
import Lens from 'assets/icons/Lens';
import X from 'assets/icons/X';
import RoundedButton from './RoundedButton';

export type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  hideCloseButton?: boolean;
  onClose?: () => void;
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { value, placeholder, onChange, hideCloseButton = false, onClose },
    ref
  ): JSX.Element => {
    return (
      <div className="flex flex-row items-center space-x-3 overflow-hidden rounded-lg border border-surfaces-divider py-2 px-5">
        <Lens className="h-6 w-6 shrink-0 text-black-soft" />
        <input
          ref={ref}
          className="flex min-w-0 grow text-lg placeholder-black-soft outline-none"
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        {!hideCloseButton ? (
          <RoundedButton
            className="shrink-0"
            onClick={() => {
              onChange('');
              onClose?.();
            }}
            size="sm"
          >
            <X className="stroke-2" />
          </RoundedButton>
        ) : null}
      </div>
    );
  }
);

export default SearchInput;
