import * as React from 'react';

function SvgPost(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 843.579 640.517"
      {...props}
    >
      <path
        d="M424.443 0H48.125A48.18 48.18 0 000 48.125v441.876a48.18 48.18 0 0048.125 48.125h376.318a48.18 48.18 0 0048.125-48.125V48.125A48.18 48.18 0 00424.443 0z"
        fill="#f2f2f2"
      />
      <path
        d="M424.444 13.854H48.125a34.31 34.31 0 00-34.271 34.271v441.876a34.31 34.31 0 0034.271 34.27h376.319a34.31 34.31 0 0034.27-34.27V48.125a34.31 34.31 0 00-34.27-34.27z"
        fill="#fff"
      />
      <path
        d="M367.808 142.34H177.506a8.014 8.014 0 010-16.028h190.302a8.014 8.014 0 110 16.028zM400.866 169.387h-223.36a8.014 8.014 0 010-16.028h223.36a8.014 8.014 0 010 16.028zM367.808 263.553H177.506a8.014 8.014 0 010-16.028h190.302a8.014 8.014 0 110 16.028zM400.866 290.6h-223.36a8.014 8.014 0 010-16.027h223.36a8.014 8.014 0 010 16.028zM367.808 384.767H177.506a8.014 8.014 0 010-16.028h190.302a8.014 8.014 0 110 16.028zM400.866 411.814h-223.36a8.014 8.014 0 010-16.028h223.36a8.014 8.014 0 010 16.028z"
        fill="#f2f2f2"
      />
      <path
        d="M134.877 181.666H67.531a3.847 3.847 0 01-3.843-3.843v-59.947a3.847 3.847 0 013.843-3.843h67.346a3.847 3.847 0 013.843 3.843v59.947a3.847 3.847 0 01-3.843 3.843zM134.877 302.88H67.531a3.847 3.847 0 01-3.843-3.843v-59.948a3.847 3.847 0 013.843-3.842h67.346a3.847 3.847 0 013.843 3.842v59.948a3.847 3.847 0 01-3.843 3.842zM134.877 424.093H67.531a3.847 3.847 0 01-3.843-3.843v-59.947a3.847 3.847 0 013.843-3.843h67.346a3.847 3.847 0 013.843 3.843v59.947a3.847 3.847 0 01-3.843 3.843zM755.955 417.91l2.985-26.108 14.97-130.908 2.985-26.109a48.18 48.18 0 00-42.346-53.28l-339.663-38.84a48.18 48.18 0 00-53.28 42.346l-.008.067-20.925 182.99-.007.068a48.18 48.18 0 0042.346 53.28l339.662 38.84a48.18 48.18 0 0053.28-42.346z"
        fill="#e6e6e6"
      />
      <path
        d="M742.19 416.337l3.536-30.924 13.868-121.278 3.536-30.924a34.31 34.31 0 00-30.155-37.942l-339.663-38.84a34.31 34.31 0 00-37.942 30.156l-.008.067-20.924 182.99-.008.068a34.31 34.31 0 0030.156 37.942l339.662 38.84a34.31 34.31 0 0037.942-30.155z"
        fill="#fff"
      />
      <path
        d="M686.593 250.416l-189.07-21.62a8.014 8.014 0 111.822-15.924l189.07 21.62a8.014 8.014 0 01-1.822 15.924zM716.365 281.045L494.45 255.669a8.014 8.014 0 111.82-15.924l221.915 25.375a8.014 8.014 0 01-1.821 15.925zM710.177 334.641l-327.17-37.411a8.014 8.014 0 011.822-15.925l327.17 37.412a8.014 8.014 0 11-1.822 15.924zM708.156 361.634l-328.221-37.532a8.014 8.014 0 011.82-15.924l328.222 37.531a8.014 8.014 0 01-1.821 15.925zM704.156 387.298l-327.17-37.411a8.014 8.014 0 111.821-15.925l327.17 37.411a8.014 8.014 0 01-1.82 15.925zM702.135 414.29l-328.221-37.53a8.014 8.014 0 011.82-15.925l328.222 37.531a8.014 8.014 0 11-1.821 15.925z"
        fill="#e6e6e6"
      />
      <path
        d="M450.703 263.025l-66.91-7.65a3.847 3.847 0 01-3.381-4.255l6.81-59.56a3.847 3.847 0 014.255-3.38l66.91 7.65a3.847 3.847 0 013.381 4.255l-6.81 59.559a3.847 3.847 0 01-4.255 3.381z"
        fill="current"
      />
      <path
        d="M842.58 640.358h-219a1 1 0 010-2h219a1 1 0 010 2z"
        fill="#3f3d56"
      />
      <path
        d="M760.346 258.32a9.377 9.377 0 008.42 11.656l6.774 20.329 13.358-1.104-9.97-28.574a9.428 9.428 0 00-18.582-2.307z"
        fill="#ffb8b8"
      />
      <path
        d="M770.576 391.445a12.084 12.084 0 0019.98-6.323l10.58-48.473a47.876 47.876 0 00-1.235-24.98l-12.382-38.175a4 4 0 00-5.596-2.343l-8.08 4.046a4 4 0 00-2.051 4.694l.815 2.802a134.703 134.703 0 011.028 71.484l-6.58 25.33a12.084 12.084 0 003.521 11.938z"
        fill="current"
      />
      <path
        fill="#ffb8b8"
        d="M728.243 629.901l-11.432-.001-5.437-44.096 16.873.001-.004 44.096z"
      />
      <path
        d="M708.644 626.633h22.048v13.882h-35.93a13.882 13.882 0 0113.882-13.882z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M789.864 629.901l-11.432-.001-5.437-44.096 16.873.001-.004 44.096z"
      />
      <path
        d="M770.266 626.633h22.047v13.882h-35.93a13.882 13.882 0 0113.883-13.882z"
        fill="#2f2e41"
      />
      <circle cx={756.383} cy={291.865} r={24.561} fill="#ffb8b8" />
      <path
        d="M672.4 452.043a9.377 9.377 0 0112.093-7.78l13.726-16.454 12.034 5.903-19.71 22.964a9.428 9.428 0 01-18.142-4.633z"
        fill="#ffb8b8"
      />
      <path
        d="M762.363 316.425a26.205 26.205 0 10-4.91-51.826c-5.401-3.28-11.68-5.486-17.975-4.925s-12.518 4.298-14.872 10.162.175 13.595 6.027 15.978c3.752 1.528 7.99.787 11.993.17s8.372-1.018 11.856 1.048 5.051 7.606 1.93 10.189a10.738 10.738 0 00-3.084 11.82c1.569 4.056 5.85 7.315 9.035 7.384zM792.836 451.072l4.357 165.34a4 4 0 01-4.202 4.1l-14.353-.733a4 4 0 01-3.746-3.367L757.82 509.023a2 2 0 00-3.927-.122l-23.108 103.397a4 4 0 01-5.244 2.896l-14.243-.87a4 4 0 01-2.65-4.053l12.434-160.764z"
        fill="#2f2e41"
      />
      <path
        d="M799.828 372.54c2.403-25.054-16.02-47.717-41.126-49.5-10.818-.768-27.612-1.042-33.149 13.137-16.677 42.713 12.518 41.676 2.46 78.683s-18.944 39.128-5.142 41.8 75.867 18.447 73.318-7.6c-1.566-16.01 1.242-51.526 3.64-76.52z"
        fill="current"
      />
      <path
        d="M730.507 331.832A12.084 12.084 0 01746.8 345.01l-7.84 48.991a47.876 47.876 0 01-10.265 22.807l-25.46 31.024a4 4 0 01-6.064.14l-6.047-6.717a4 4 0 01-.196-5.118l1.78-2.311a134.703 134.703 0 0027.045-66.18l3.117-25.984a12.084 12.084 0 017.636-9.83z"
        fill="current"
      />
    </svg>
  );
}

export default SvgPost;
