import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo, useState } from 'react';
import { OrderedActivities } from 'types/types';
import * as Yup from 'yup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

export type ActivitiesDescriptions = Record<string, string>;

export type TemplateDetailsFormProps = {
  activities: OrderedActivities;
  activitiesDescription: ActivitiesDescriptions;
  onSubmit: (descriptions: ActivitiesDescriptions) => Promise<void>;
  onBack: () => void;
};

const TemplateDetailsForm = ({
  activities,
  activitiesDescription,
  onBack,
  onSubmit,
}: TemplateDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState<boolean>(false);

  const schema = useMemo(
    () =>
      Yup.object().shape(
        _.mapValues(_.keyBy(activities, 'name'), (_activity) =>
          Yup.string().required('Requis !')
        )
      ),
    [activities]
  );

  const submit = async (values: ActivitiesDescriptions) => {
    setProcessing(true);
    try {
      await onSubmit(values);
    } catch (e) {
      console.error(e);
      setProcessing(false);
    }
  };

  return (
    <div className="mx-auto max-w-xl text-left">
      <h2 className="mt-8 text-lg font-semibold text-primary">
        {t('common:StepXOverY', { step: 2, length: 2 })}
      </h2>
      <h1 className="pb-8 text-3xl font-semibold">
        {t('templates:FillDescriptionsForEachStep')}
      </h1>

      <Formik
        initialValues={activitiesDescription}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          submitCount,
        }: FormikProps<ActivitiesDescriptions>) => {
          const hasError = (name: string): boolean =>
            !!(errors[name] && (submitCount || touched[name]));
          return (
            <Form>
              {activities.map((activity, index) => {
                return (
                  <div key={activity.name} className="mb-12 flex flex-row">
                    <div className="m-1 flex h-10 w-10 items-center justify-center rounded-full bg-primary-soft text-xl font-semibold text-primary">
                      {index + 1}
                    </div>
                    <div className="ml-3 flex flex-grow flex-col">
                      <p className="p-0 text-base">
                        {activity.duration
                          ? t('common:Xminutes', {
                              duration: activity.duration,
                            })
                          : t('common:NoTimeLimit')}
                      </p>
                      <label
                        htmlFor={activity.name}
                        className="text-lg font-semibold"
                      >
                        {activity.humanName || activity.name}
                      </label>
                      <Field
                        as="textarea"
                        className={`h-18 mt-2 w-full resize-none border p-3 ${
                          hasError(activity.name)
                            ? 'border-danger'
                            : 'border-surfaces-divider'
                        }`}
                        id={activity.name}
                        name={activity.name}
                        values={activity.name}
                        placeholder={t('templates:ExThisStepAllowTo')} //"Ex: Cette étape permet de ..."
                      />
                      {hasError(activity.name) && (
                        <div className="mt-2 font-medium text-danger">
                          {errors[activity.name]}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="flex flex-row items-center">
                <button
                  type="button"
                  onClick={onBack}
                  className="py-3 px-11 text-base font-semibold text-primary"
                >
                  {t('common:Return')}
                </button>
                <div className="flex-grow" />
                <Button
                  type="submit"
                  text={t('common:Submit')}
                  loading={processing}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TemplateDetailsForm;
