import Copyable from 'components/Copyable';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type EmergingQuestionProps = {
  question: string;
  marginClasses: string;
};

const EmergingQuestion = ({
  question,
  marginClasses,
}: EmergingQuestionProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">
        {t('sessions:emergenceQuestion')}
      </h2>
      <Copyable textToCopy={question} top={2}>
        <p className="whitespace-pre-line text-2xl">{question}</p>
      </Copyable>
    </div>
  );
};

export default EmergingQuestion;
