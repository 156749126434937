import { useEffect, useReducer, useState } from 'react';

import AgoraRTC, {
  IAgoraRTCClient,
  AREAS,
  IAgoraRTCRemoteUser,
  UID,
  ILocalVideoTrack,
  ILocalAudioTrack,
} from 'agora-rtc-sdk-ng';
import { getToken } from 'services/agora';
import _ from 'lodash';
import { usePrevious } from 'utils/utils';
import { getUserIdSharingScreen, stopShareScreen } from 'model/meeting';

const APP_ID = '5579471b76f14a1b9511185023943ab2';

AgoraRTC.setLogLevel(3); // 1

AgoraRTC.setArea({
  areaCode: [AREAS.EUROPE],
});

type MeetingType = 'group' | 'plenary';

type MeetingState = {
  client: IAgoraRTCClient | null;
  type: MeetingType | null;
  localVideoTrack: ILocalVideoTrack | null;
  localAudioTrack: ILocalAudioTrack | null;
  localVideoTrackError: boolean;
  localAudioTrackError: boolean;
};

type Action =
  | {
      type: 'newClient';
      client: IAgoraRTCClient;
      meetingType: MeetingType;
    }
  | {
      type: 'reinit';
    }
  | {
      type: 'localTracks';
      localVideoTrack: ILocalVideoTrack | null;
      localAudioTrack: ILocalAudioTrack | null;
      localAudioTrackError: boolean;
      localVideoTrackError: boolean;
    };

const reducer = (state: MeetingState, action: Action): MeetingState => {
  switch (action.type) {
    case 'newClient':
      return { ...state, client: action.client, type: action.meetingType };
    case 'reinit':
      return init();
    case 'localTracks':
      return {
        ...state,
        localVideoTrack: action.localVideoTrack,
        localAudioTrack: action.localAudioTrack,
        localAudioTrackError: action.localAudioTrackError,
        localVideoTrackError: action.localVideoTrackError,
      };
    default:
      throw new Error('Action not supported');
  }
};

const init = (): MeetingState => {
  return {
    client: null,
    type: null,
    localAudioTrack: null,
    localVideoTrack: null,
    localAudioTrackError: false,
    localVideoTrackError: false,
  };
};

export const useMeeting = (
  enabled: boolean,
  meetingName: string,
  type: MeetingType,
  role: 'facilitator' | 'participant',
  uid: string
): [
  IAgoraRTCClient | null,
  ILocalVideoTrack | null,
  ILocalAudioTrack | null,
  boolean,
  boolean,
  () => Promise<ILocalVideoTrack | undefined>
] => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, init());

  useEffect(() => {
    if (enabled) {
      const client = AgoraRTC.createClient({
        codec: 'vp8', //TODO: investigate best codec
        mode: type === 'group' ? 'rtc' : 'live',
      });

      client.enableAudioVolumeIndicator();

      dispatch({ type: 'newClient', client, meetingType: type });

      return () => {
        dispatch({ type: 'reinit' });
        client.leave();
      };
    }
  }, [type, enabled]);

  useEffect(() => {
    if (enabled && state.client && state.type === 'plenary') {
      if (role === 'facilitator') {
        state.client.setClientRole('host');
      }
    }
  }, [state.client, role, enabled, state.type]);

  useEffect(() => {
    if (enabled && state.client && uid && meetingName) {
      const client = state.client;

      console.log('MEETING NAME:', meetingName);

      getToken(meetingName, uid)
        .then((token) => {
          return client?.join(APP_ID, meetingName, token, uid);
        }) // TODO : use token
        .then(async () => {
          let localAudioTrack = null;
          let localVideoTrack = null;
          let localAudioTrackError = false;
          let localVideoTrackError = false;

          const initMicrophone = async () => {
            try {
              localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
              await client.publish(localAudioTrack);
            } catch (e) {
              localAudioTrackError = true;
              console.error(e);
            }
          };

          const initCamera = async () => {
            try {
              localVideoTrack = await AgoraRTC.createCameraVideoTrack();
              await client.publish(localVideoTrack);
            } catch (e) {
              localVideoTrackError = true;
              console.error(e);
            }
          };

          await Promise.all([initMicrophone(), initCamera()]);

          dispatch({
            type: 'localTracks',
            localVideoTrack,
            localAudioTrack,
            localVideoTrackError,
            localAudioTrackError,
          });
        })
        .catch((err: unknown) => {
          console.error(err);
          //TODO: do something
        });

      console.log('############ CONNECTING', enabled, uid, meetingName);

      return () => {
        client?.leave().catch((err) => {
          console.error(err);
          //TODO: do something
        });
      };
    }
  }, [state.client, enabled, uid, meetingName]);

  const startLocalScreenTrack = async () => {
    if (state.client) {
      const track = (await AgoraRTC.createScreenVideoTrack(
        {}
      )) as ILocalVideoTrack;
      return track;
    }
  };

  return [
    state.client,
    state.localVideoTrack,
    state.localAudioTrack,
    state.localVideoTrackError,
    state.localAudioTrackError,
    startLocalScreenTrack,
  ];
};

export type MeetingUser = {
  user: IAgoraRTCRemoteUser;
  speaking: boolean;
  level: number;
};
export const useMeetingUsers = (
  client: IAgoraRTCClient | null,
  sharingUserId: string | null,
  mustSort: (nbUsers: number) => boolean,
  nbMockUsers: number = 0
) => {
  const [users, updateUsers] = useState<MeetingUser[]>([]);

  const oldChannelName = usePrevious(client?.channelName);

  useEffect(() => {
    if (client?.channelName !== oldChannelName) {
      updateUsers(
        _.range(nbMockUsers).map((id) => ({
          user: {
            uid: id,
            hasVideo: false,
            hasAudio: false,
          },
          speaking: false,
          level: 0,
        }))
      );
    }
  }, [client?.channelName, oldChannelName, nbMockUsers]);

  const upsertUser = (newUser: IAgoraRTCRemoteUser) => {
    updateUsers((users) => {
      const newArray = [...users];
      const found = users.findIndex((user) => user.user.uid === newUser.uid);
      if (found !== -1) {
        newArray[found].user = newUser;
      } else {
        newArray.push({ user: newUser, speaking: false, level: 0 });
      }

      return newArray;
    });
  };

  const deleteUser = (newUser: IAgoraRTCRemoteUser) => {
    updateUsers((users) => {
      const newArray = [...users];
      const found = users.findIndex((user) => user.user.uid === newUser.uid);
      if (found !== -1) {
        newArray.splice(found, 1);
      }
      return newArray;
    });
  };

  useEffect(() => {
    const cli = client;
    if (cli) {
      const cbJoined = (user: IAgoraRTCRemoteUser) => {
        upsertUser(user);
      };

      const cbPublished = (
        user: IAgoraRTCRemoteUser,
        mediaType: 'audio' | 'video'
      ) => {
        console.log('publish', mediaType);
        if (cli && mediaType === 'audio') {
          cli
            .subscribe(user, 'audio')
            .then(() => {
              user.audioTrack?.play();
            })
            .catch((err) => console.error(err));
        }
        upsertUser(user);
      };

      const cbUnpublished = (
        user: IAgoraRTCRemoteUser,
        mediaType: 'audio' | 'video'
      ) => {
        console.log('Unpublish', mediaType);
        upsertUser(user);
      };

      const cbUserLeft = async (user: IAgoraRTCRemoteUser) => {
        deleteUser(user);
        const meetingName = client.channelName;
        if (meetingName) {
          const userId = await getUserIdSharingScreen(meetingName);
          if (userId === user.uid) {
            await stopShareScreen(meetingName);
          }
        }
      };

      const cbSpeaker = (volumes: { level: number; uid: UID }[]) => {
        updateUsers((users) => {
          const newUsers = [...users];
          volumes.forEach((volume) => {
            const user = users.find((user) => user.user.uid === volume.uid);
            if (user) {
              user.level = volume.level;
              if (volume.level > 5) {
                user.speaking = true;
              } else {
                user.speaking = false;
              }
            }
          });
          return newUsers;
        });
      };

      console.log('SUBSCRIBING');
      cli.on('user-joined', cbJoined);
      cli.on('user-published', cbPublished);
      cli.on('user-unpublished', cbUnpublished);
      cli.on('volume-indicator', cbSpeaker);
      cli.on('user-left', cbUserLeft);

      return () => {
        console.log('UNSUBSCRIBE');
        cli.off('user-joined', cbJoined);
        cli.off('user-published', cbPublished);
        cli.off('user-unpublished', cbUnpublished);
        cli.off('volume-indicator', cbSpeaker);
        cli.off('user-left', cbUserLeft);
      };
    }
  }, [client]);

  useEffect(() => {
    const [sharing, notSharing] = _.partition(
      Object.values(users),
      (user) => user.user.uid === sharingUserId
    );

    const haveToSort = mustSort(_.size(users));

    const remainging = haveToSort
      ? _.sortBy(notSharing, [
          (user) => !user.speaking,
          (user) => !user.user.hasVideo,
        ])
      : notSharing;

    const newUsers = [...sharing, ...remainging];

    if (!usersArrayEqual(newUsers, users)) {
      updateUsers(newUsers);
    }
  }, [users, sharingUserId, mustSort]);

  return users;
};

const usersArrayEqual = (a: MeetingUser[], b: MeetingUser[]) => {
  return (
    a.length === b.length &&
    _.every(_.zip(a, b), ([ael, bel]) => ael!.user.uid === bel!.user.uid)
  );
};
