import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { reauthenticateUser } from 'model/users';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Alert from 'components/Alert';
import Input from 'frameworks/formik/Input';

export type ModalReauthenticateUserProps = {
  open: boolean;
  onClose: () => void;
  onResult: (result: boolean) => void;
};

const ModalReauthenticateUser = ({
  open,
  onClose,
  onResult,
}: ModalReauthenticateUserProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null
  );
  return (
    <Modal
      className={'max-w-screen-xs sm:max-w-lg md:w-192'}
      open={open}
      onClose={onClose}
      body={
        <>
          <h2 className="mb-10 text-center text-2xl font-semibold uppercase">
            {t('profile:authenticateMessage')}
          </h2>
          {globalErrorMessage ? (
            <Alert
              title={t('profile:authenticateErrorTitle')}
              message={globalErrorMessage}
              type="error"
            />
          ) : null}
          <Formik
            initialValues={{ password: '' }}
            validationSchema={Yup.object({
              password: Yup.string().required(t('form:fieldRequired')),
            })}
            onSubmit={async ({ password }) => {
              setGlobalErrorMessage(null);
              setLoading(true);
              let result;
              try {
                result = await reauthenticateUser(password);
                if (!result) {
                  setGlobalErrorMessage(t('firebase:wrongPassword'));
                }
                onResult(result);
              } catch (e) {
                result = false;
                setGlobalErrorMessage(t('firebase:unknownError'));
              }
              setLoading(false);
            }}
          >
            <Form className="space-y-6">
              <Input
                label={t('login:passwordLabel')}
                type="password"
                name="password"
                autoComplete="current-password"
              />
              <div className="flex flex-row justify-end">
                <Button
                  type="submit"
                  text={t('users:Authenticate')}
                  loading={loading}
                />
              </div>
            </Form>
          </Formik>
        </>
      }
    />
  );
};

export default ModalReauthenticateUser;
