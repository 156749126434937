import Listbox from 'components/Listbox';
import React from 'react';
import { connectSortBy } from 'react-instantsearch-dom';

export type SortByProps = {
  items: {
    label: string;
    value: string;
    isRefined: boolean;
  }[];
  currentRefinement: string;
  refine: (newRefinement: string) => void;
};

const SortBy = ({ items, currentRefinement, refine }: SortByProps) => {
  const options = items.map(({ value, label }) => ({
    value,
    description: label,
  }));

  return (
    <Listbox
      items={options}
      defaultValue={currentRefinement}
      setValue={refine}
    />
  );
};

export default connectSortBy(SortBy);
