import { auth } from 'services/firebase';

export const identify = () => {
  setTimeout(() => {
    if (auth.currentUser) {
      window.FS?.identify(auth.currentUser.uid);
    }
  }, 500);
};

export const setSession = (sessionId, sessionName) => {
  window.FS?.setUserVars({
    sessionId,
    sessionName,
  });
};

export const setGroup = (groupId, groupNumber) => {
  window.FS?.setUserVars({
    groupId,
    groupNumber,
  });
};
