const WHITE50 = '#999';
const WHITE80 = '#d1d1d1';
const WHITE85 = '#d6d5d9';
const WHITE90 = '#ebebeb';
const WHITE95 = '#f5f5f5';
const WHITE100 = '#fff';

const WHITE = WHITE100;
const BLACK = '#2a2a2a';
const GREY = '#cfd8dc';

const PRIMARY = '#333';
const PRIMARY_LIGHT = WHITE90;
const ACCENT = '#0800c3';
const ERROR = '#c93b49';
const ERROR_SOFT = '#c93b4915';
const ERROR_MEDIUM = '#c93b4944';
const SUCCESS = '#3bc949';

const colors = {
  colorPrimary: PRIMARY,
  colorPrimaryLight: PRIMARY_LIGHT,
  colorAccent: ACCENT,
  colorError: ERROR,
  colorErrorSoft: ERROR_SOFT,
  colorErrorMedium: ERROR_MEDIUM,
  colorSuccess: SUCCESS,
  colorWhite: WHITE,
  colorBlack: BLACK,
  colorDisabled: GREY,

  colorTextLightBackground: BLACK,
  colorTextDarkBackground: WHITE,

  colorBackgroundItem: WHITE,
  colorInputHeaderActive: WHITE90,
  colorInputHeaderInactive: WHITE95,

  colorGrayVote: WHITE90,
  colorGrayBackground: WHITE95,
  colorGrayText: WHITE85,
  colorGrayTextOnGrayBackground: WHITE50,
  colorGrayBorder: WHITE80,

  colorSeparator: 'rgba(0, 0, 0, 0.1)',

  // GROUPS
  colorRedHeaderText: 'rgba(230,70,92,1)',
  colorRedHeaderBackground: 'rgba(230,70,92,0.1)',
  colorBlueHeaderText: 'rgba(120,203,255,1)',
  colorBlueHeaderBackground: 'rgba(120,203,255,0.1)',
  colorGreenHeaderText: 'rgba(175,209,54,1)',
  colorGreenHeaderBackground: 'rgba(175,209,54,0.1)',
  colorYellowHeaderText: 'rgba(252,213,51,1)',
  colorYellowHeaderBackground: 'rgba(252,213,51,0.1)',
  colorPurpleHeaderText: 'rgba(37,64,209,1)',
  colorPurpleHeaderBackground: 'rgba(37,64,209,0.1)',
  colorGreyHeaderText: 'rgba(89,89,89,1)',
  colorGreyHeaderBackground: 'rgba(89,89,89,0.1)',
};

export default colors;
