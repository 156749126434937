import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StickyNote from 'components/StickyNote';
import FormattedText from 'components/FormattedText';

import { Pencil, Plus, Trash, Eye, EyeOff } from 'assets/icons';
import { useEffect } from 'react';

export type ActivityNoteProps = {
  defaultNote?: string;
  insertNote: (note: string) => void;
  removeNote: () => void;
  readOnly?: boolean;
};

const ActivityNote = ({
  insertNote,
  removeNote,
  defaultNote,
  readOnly = true,
}: ActivityNoteProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [note, setNote] = useState<string>('');
  const [editingNote, setEditingNote] = useState(false);
  const [previewNote, setPreviewNote] = useState(true);

  useEffect(() => {
    setNote(defaultNote || '');
  }, [defaultNote]);

  return editingNote ? (
    <StickyNote className="min-h-44">
      <div>
        <textarea
          autoFocus
          className="rounded-none bg-yellow-50 placeholder-yellow-300"
          placeholder={t('sessions:addNoteHere')}
          rows={8}
          value={note}
          onChange={(event) => {
            setNote(event.target.value);
          }}
        />
        <div className="mt-2 flex justify-center space-x-2">
          <button
            className="rounded-full border border-yellow-600 py-1 px-2 text-yellow-600"
            onClick={() => setEditingNote(false)}
          >
            {t('common:Cancel')}
          </button>
          <button
            className="rounded-full bg-yellow-600 py-1 px-2 text-yellow-100"
            onClick={() => {
              insertNote(note);
              setEditingNote(false);
            }}
          >
            {t('common:Insert')}
          </button>
        </div>
      </div>
    </StickyNote>
  ) : defaultNote && previewNote ? (
    <StickyNote className="w-full">
      <div className="w-full">
        <div className="flex w-full justify-end space-x-2">
          <EyeOff
            className="h-6 w-6 cursor-pointer rounded-full bg-yellow-500 stroke-2 p-1 text-yellow-100 hover:bg-yellow-600"
            onClick={() => setPreviewNote(false)}
          />
          {!readOnly ? (
            <>
              <Trash
                className="h-6 w-6 cursor-pointer rounded-full bg-yellow-500 stroke-2 p-1 text-yellow-100 hover:bg-yellow-600"
                onClick={removeNote}
              />
              <Pencil
                className="h-6 w-6 cursor-pointer rounded-full bg-yellow-500 stroke-2 p-1 text-yellow-100 hover:bg-yellow-600"
                onClick={() => setEditingNote(true)}
              />
            </>
          ) : null}
        </div>
        <FormattedText className="mt-2 whitespace-pre-line">
          {defaultNote}
        </FormattedText>
      </div>
    </StickyNote>
  ) : defaultNote && !previewNote ? (
    <StickyNote onClick={() => setPreviewNote(true)}>
      <Eye className="h-6 w-6" />
    </StickyNote>
  ) : !readOnly ? (
    <StickyNote onClick={() => setEditingNote(true)}>
      <Plus className="h-6 w-6" />
    </StickyNote>
  ) : null;
};

export default ActivityNote;
