import { HardcodedScreenType } from 'constants/HardCodedScreens';
import { ActivityLight, Session } from './baseTypes';
import { ColorNames } from './ui';
export * from './baseTypes';

export type CommunityRole = 'member' | 'organizer' | 'guest';

export type DataBindings = Record<string, DataPath>;

export type ScreenTemplate = {
  type: 'HardcodedScreenTemplate';
  name: HardcodedScreenType;
  editableContent: Record<string, any>;
  params?: Record<string, any>;
};

export type Screen = {
  name: string;
  index: number;
  humanName?: string;
  dataBindings: DataBindings;
  content: {
    template: ScreenTemplate;
  };
};

export type ScreenProp = 'humanName';

export type Screens = Record<string, Screen>;

export type CriteriaMode =
  | 'ONE_OF_EACH'
  | 'EXCLUDE_EACH_OTHERS'
  | 'JOIN_TOGETHER'
  | 'ONE_IN_EACH';

export type AugmentedCriteriaMode = CriteriaMode | 'HARD_SPLIT';

export const CRITERIA_MODES = [
  'ONE_OF_EACH',
  'EXCLUDE_EACH_OTHERS',
  'JOIN_TOGETHER',
  'ONE_IN_EACH',
  'HARD_SPLIT',
];

export type Criterium = {
  name: string;
  source: DataPath;
  mode?: AugmentedCriteriaMode;
  optionalArgument?: string;
};
export type Criteria = Record<string, Criterium>;

export type MatchingMode = 'algo' | 'empty' | 'custom_skema' | 'same_as';

export type MatchedUsers = 'all' | 'active' | 'activity';

type CommonGroupSettingsProperties = {
  membersCanChangeAndJoin?: boolean;
  matchedUsers?: MatchedUsers;
  sourceActivity?: string;
};

export type AlgoSettings = {
  mode: 'algo' | 'custom_skema';
  votes: DataPath;
  topicsActivityName?: string; //activityName, if present use this topics as hard split
  criteria?: Criteria;
  minPeopleByGroup: number;
  maxPeopleByGroup: number;
} & CommonGroupSettingsProperties;

export type GroupSettings = (
  | AlgoSettings
  | {
      mode: 'empty';
      numberOfGroups: number;
    }
  | {
      mode: 'same_as';
      activity: string;
    }
) &
  CommonGroupSettingsProperties;

export type Grouping =
  | {
      mode: 'All';
    }
  | {
      mode: 'Groups';
      settings: GroupSettings;
    };

export type GroupingMode = 'All' | 'Groups';

export type GroupingProp = 'mode' | 'activity' | 'settings';

export type Activity = ActivityLight & {
  humanName: string;
  index: number;
  stepper?: boolean;
  grouping: Grouping;
  screens: Screens;
  defaultSelectedScreen?: string;
  groupingModalContent?: string;
  duration?: number;
  facilitationNote?: string;
};

export type ActivityProp = 'humanName' | 'stepper' | 'duration';

export type Activities = Record<string, Activity>;

export type OrderedActivities = Activity[];

export type ActivityMeta = {
  enabledAt: number;
  remainingTimestamp: number;
  remainingTimeMs: number;
  isPaused: boolean;
};

export type ActivitiesMeta = Record<string, ActivityMeta>;

export type SessionTemplate = {
  activities: Activities;
};

export type Color = string;

export type SessionProp = keyof Session;

export type DataPath = string;

export type UserType = 'admin' | 'participant';
export const ROLES: UserType[] = ['admin', 'participant'];

export type Role = 'participant' | 'facilitator' | 'organizer';
export type RoleBar = Role | 'superadmin';

export type MatchingStatus = 'NotRun' | 'Error' | 'Done' | number;

export type FeedbackAnswerType<T> = {
  userId: string;
  value: T;
};

export type FeedbackType = {
  index: number;
  description: string;
  meta: any;
} & (
  | {
      type: 'string';
      answers: FeedbackAnswerType<string>[];
    }
  | {
      type: 'number';
      answers: FeedbackAnswerType<number>[];
    }
  | {
      type: 'boolean';
      answers: FeedbackAnswerType<boolean>[];
    }
);

export type TaskData = {
  authorId?: string;
  completedAt?: number;
  waiting?: {
    authorId?: string;
    at?: number;
  };
};

export type ProductionMeta = {
  topic?: string;
};

export type Topic = {
  description: string;
  color: ColorNames;
};

export type LoadableString = 'loading' | 'error' | null | string;

export type ActionRecord = {
  userId: string;
  timestamp: number;
};

export * from './baseTypes';
