import React, { Fragment } from 'react';

import ChevronLeftIcon from 'assets/icons/ChevronLeft';
import ChevronRightIcon from 'assets/icons/ChevronRight';
import _ from 'lodash';

export type PaginationProps = {
  currentPage: number;
  nbPages: number;
  onPageChange: (page: number) => void;
};

const Pagination = ({
  currentPage,
  nbPages,
  onPageChange,
}: PaginationProps): JSX.Element => {
  const paginationBulletStyle =
    'cursor-pointer flex items-center justify-center p-1 text-sm rounded-full full hover:bg-primary-soft hover:text-primary';

  return (
    <>
      {nbPages > 1 ? (
        <div className="inline-flex items-center justify-end space-x-2">
          {currentPage > 0 ? (
            <span
              onClick={() => onPageChange(currentPage - 1)}
              className={`${paginationBulletStyle}`}
            >
              <ChevronLeftIcon className="h-5 w-5 stroke-2" />
            </span>
          ) : null}
          {_.range(nbPages)
            .reduce<Array<{ page: number; index: number }>>(
              (list, page, index) => {
                const deltaIndex = Array.from({ length: 2 }, (_, i) => i + 1);

                const authorizedIndex = [
                  0,
                  ...deltaIndex.map((delta) => currentPage - delta),
                  currentPage,
                  ...deltaIndex.map((delta) => currentPage + delta),
                  nbPages - 1,
                ];

                if (authorizedIndex.includes(index))
                  list.push({ page: page, index: index });
                return list;
              },
              []
            )
            .map((page, index, array) => {
              let addEllispsis = false;
              if (index > 0 && page.index - array[index - 1].index > 1) {
                addEllispsis = true;
              }

              return (
                <Fragment key={index}>
                  {addEllispsis ? <span>{'...'}</span> : null}
                  <span
                    onClick={() => onPageChange(page.index)}
                    className={`h-5 w-5 ${paginationBulletStyle} ${
                      page.index === currentPage
                        ? 'bg-primary-soft text-primary'
                        : ''
                    }`}
                  >
                    <span>{page.index + 1}</span>
                  </span>
                </Fragment>
              );
            })}
          {currentPage < nbPages - 1 ? (
            <span
              onClick={() => onPageChange(currentPage + 1)}
              className={`${paginationBulletStyle}`}
            >
              <ChevronRightIcon className="h-5 w-5 stroke-2" />
            </span>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
