import { database as db, fetchData } from 'services/firebase';
import _ from 'lodash';
import {
  Activity,
  Activities,
  Productions,
  User,
  UserActivity,
  AugmentedCriteriaMode,
  AlgoSettings,
  TrueSet,
  Session,
  ProductionMeta,
} from 'types/types';
import { dataPathToRef } from './dataPathUtils';
import { addGroup, getGroups, setMatchingStatus } from './groupManagement';
import { mapValuesAsync } from 'utils/utils';
import { fetchUser, fetchUserActivity, isUserActive } from './users';
import { isActivityEnabled } from './sessions';
import {
  makeComplexMatch,
  makeCustomSkemaMatch,
  MatcherReturn,
  makeFromActivity,
} from 'services/matcher';
import { generateProductions, getPosts } from './productions';

const NB_BATCH_WRITES = 10;

type UserCriteria = Record<string, string>;
type UserCriterias = Record<string, UserCriteria>;

const processGroupsAsCriteria = async (
  sessionId: string,
  activities: Activities,
  activity: Activity,
  userCriteria: UserCriterias
): Promise<void> => {
  const previousGroupingActivities = _.pickBy(
    activities,
    (act) => act.index < activity.index
  );

  await Promise.all(
    Object.values(previousGroupingActivities).map(async (activity) => {
      if (
        activity.grouping.mode === 'Groups' &&
        (activity.grouping.settings.mode === 'algo' ||
          activity.grouping.settings.mode === 'custom_skema')
      ) {
        const groups = await getGroups(sessionId, activity.name);
        const criteria: Record<string, string> = {};
        const critName = `grouping_${activity.name}`;
        Object.values(groups || {}).forEach((group) => {
          const val = `${group.number}`;
          Object.keys(group.users || {}).forEach((userId) => {
            criteria[userId] = val;
          });
        });
        userCriteria[critName] = criteria;
      }
    })
  );
};

const processHasPostedAsCriteria = async (
  sessionId: string,
  users: Record<string, boolean>,
  userCriteria: UserCriterias
): Promise<void> => {
  const posts = await getPosts(sessionId);
  userCriteria['hasPosted'] = _.mapValues(
    users,
    (_true, userId) => `${!!posts[userId]}`
  );
};

export const createGroups = async (
  sessionId: string,
  activity: Activity,
  activities: Activities,
  productions: Record<string, Productions>
): Promise<void> => {
  const grouping = activity.grouping;

  if (grouping.mode !== 'Groups') {
    throw Error('unsupported mode');
  }

  const settings = grouping.settings as AlgoSettings;

  await setMatchingStatus(sessionId, activity.name, 0);

  const usersRef = `/sessionsNextData/${sessionId}/users/participants`;
  const users: Record<string, boolean> =
    (await db.ref(usersRef).once('value')).val() || {};

  const fullUsers: Record<string, User> = await mapValuesAsync(
    users,
    (_ignored, key) => fetchUser(key)
  );

  const fullUsersActivity: Record<string, UserActivity> = await mapValuesAsync(
    users,
    (_ignored, key) => fetchUserActivity(key)
  );

  console.log(fullUsers);

  // const criteriaPath = `/sessions/${communityId}/${sessionId}/admin/match/criteria`;

  // const useVideoPath = `/sessions/${communityId}/${sessionId}/useVideoInGroups`;
  // const useVideo =
  //   (await database.ref(useVideoPath).once('value')).val() || false;

  let votes: Record<string, Record<string, boolean>> = {};
  if (settings.votes) {
    const [ref] = dataPathToRef(
      sessionId,
      undefined,
      productions,
      settings.votes,
      activities
    ) as [string[]];

    const votesRef = ref.join('/');

    votes = (await db.ref(votesRef).once('value')).val() || {};
  }

  let topics: Record<string, string> = {};
  if (settings.topicsActivityName) {
    const postMeta = await fetchData<Record<string, ProductionMeta>>(
      `sessionsNextData/${sessionId}/activities/${settings.topicsActivityName}/productionsMeta/post`,
      {}
    );

    topics = _.mapValues(postMeta, (val) => val.topic || 'notopic');
  }

  const criteria: Record<string, Record<string, string>> = {};
  if (settings.criteria) {
    await Promise.all(
      Object.entries(settings.criteria).map(async ([name, info]) => {
        const [ref] = dataPathToRef(
          sessionId,
          undefined,
          productions,
          info.source,
          activities
        ) as [string[]];

        const criteriaRef = ref.join('/');

        const criteriaData: Record<string, string> = (
          await db.ref(criteriaRef).once('value')
        ).val();

        if (criteriaData) {
          criteria[name] = criteriaData;
        }
      })
    );
  }

  await processGroupsAsCriteria(sessionId, activities, activity, criteria);
  await processHasPostedAsCriteria(sessionId, users, criteria);

  const usersArray = Object.keys(users);

  const matchingTime =
    5 +
    Math.min((usersArray.length * usersArray.length * 300) / (400 * 400), 300);

  const userCriteria = (id: string): Record<string, string> =>
    Object.entries(criteria).reduce(
      (prec: Record<string, string>, [criteriaName, data]) => {
        const criteriaForUser = data[id];
        if (criteriaForUser) {
          prec[criteriaName] = criteriaForUser;
        }
        return prec;
      },
      {}
    );

  let activityUsers: null | TrueSet = null;
  if (grouping.settings.sourceActivity) {
    activityUsers = (
      await db
        .ref(
          `sessionsNextData/${sessionId}/activities/${grouping.settings.sourceActivity}/grouping/groupOfUser`
        )
        .get()
    ).val();
  }

  const criteriaDefinition = Object.entries(criteria).reduce(
    (
      prec: Record<string, { options: string[]; type: AugmentedCriteriaMode }>,
      [criteriaName, data]
    ) => {
      return {
        ...prec,
        [criteriaName]: {
          options: _.uniq(Object.values(data)),
          type: criteriaName.startsWith('grouping_')
            ? 'EXCLUDE_EACH_OTHERS'
            : settings.criteria?.[criteriaName]?.mode || 'ONE_OF_EACH',
          optionalArgument: settings.criteria?.[criteriaName]?.optionalArgument,
        },
      };
    },
    {}
  );

  if (settings.topicsActivityName) {
    criteriaDefinition['topics'] = {
      options: _.uniq(Object.values(topics)),
      type: 'HARD_SPLIT',
    };
  }

  const body = {
    peoples: usersArray
      .filter(
        (id) =>
          grouping.settings.matchedUsers === 'active'
            ? isUserActive(fullUsersActivity[id])
            : grouping.settings.matchedUsers === 'activity'
            ? activityUsers?.[id]
            : true //all
      )
      .map((id) => ({
        id: id,
        criteria: {
          ...userCriteria(id),
          ...(settings.topicsActivityName && topics[id]
            ? { topics: topics[id] }
            : {}),
        },
        votes: votes[id] || {},
      })),
    minPeopleByGroup: settings.minPeopleByGroup,
    maxPeopleByGroup: settings.maxPeopleByGroup,
    criteriaDefinition,
    matchingTime,
  };

  let step = 0;
  const interval = setInterval(() => {
    setMatchingStatus(
      sessionId,
      activity.name,
      Math.min((step / matchingTime) * 80, 80)
    );
    step += 1;
  }, 1000);

  console.log(body);

  try {
    const groups: MatcherReturn =
      grouping.settings.mode === 'algo'
        ? await makeComplexMatch(body)
        : grouping.settings.mode === 'custom_skema'
        ? makeCustomSkemaMatch(body)
        : grouping.settings.mode === 'empty'
        ? _.times(
            grouping.settings.numberOfGroups || 1,
            _.constant({ peoples: [], name: '' })
          )
        : grouping.settings.mode === 'same_as'
        ? await makeFromActivity(sessionId, grouping.settings.activity)
        : [];

    clearInterval(interval);

    const groupsBin = _.chunk(groups, NB_BATCH_WRITES);

    await groupsBin.reduce(async (prec: Promise<string[]>, groupBin, i) => {
      await prec;
      await setMatchingStatus(
        sessionId,
        activity.name,
        80 + ((i * NB_BATCH_WRITES) / groups.length) * 20
      );
      return Promise.all(
        groupBin.map((group, j) =>
          addGroup(
            sessionId,
            activities,
            activity.name,
            i * NB_BATCH_WRITES + j + 1,
            group.peoples,
            group.topic ||
              (group.peoples[0]?.id ? topics[group.peoples[0]?.id] : undefined)
          )
        )
      );
    }, Promise.all([]));

    if (await isActivityEnabled(sessionId, activity.name)) {
      const session = await fetchData<Session>(`sessionsNext/${sessionId}`);
      if (session) {
        await generateProductions(
          sessionId,
          session.communityId,
          activity,
          activities,
          session.groupPrefix
        );
      }
    }

    await setMatchingStatus(sessionId, activity.name, 'Done');
    console.log('Done');
  } catch (e) {
    setMatchingStatus(sessionId, activity.name, 'Error');
    throw e;
  } finally {
    clearInterval(interval);
  }
};
