import React, { useMemo } from 'react';
import { useField } from 'formik';
import _ from 'lodash';

import RangePicker from 'components/RangePicker';

export type RangePickerInputProps = {
  name: string;
  label?: string;
  type?: 'date' | 'datetime';
  readOnly?: boolean;
};

const RangePickerInput = ({
  name,
  label,
  type,
  readOnly,
}: RangePickerInputProps): JSX.Element => {
  const [field, , helpers] = useField<[Date, Date]>(name);

  const onChangeDebounced = useMemo(
    () => _.debounce((value) => helpers.setValue(value), 200),
    [helpers]
  );

  return (
    <div>
      {label ? (
        <label htmlFor={name} className="text-lg font-semibold">
          {label}
        </label>
      ) : null}
      <div className={`mt-2 w-full`}>
        <RangePicker
          value={field.value}
          onChange={onChangeDebounced}
          bounds={[undefined, undefined]}
          type={type}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default RangePickerInput;
