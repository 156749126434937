import { PostItem } from 'types/ui';
import i18next from 'i18next';
import i18n from 'services/i18n';

export const buildPost = (
  index: number | null = null,
  votes: Record<string, boolean> | null = {}
): PostItem => {
  const name =
    index !== null
      ? `${i18next.t('misc:participant')} ${index + 1}`
      : 'Jane Doe';
  let defaultPost = {
    author: {
      userId: `User${index}`,
      name,
    },
    content: i18n.t('misc:ShareExample', { name }),
    isLiked: votes ? !!votes[`User${index}`] : false,
  };

  return defaultPost;
};
