import React from 'react';
import { InformationCircle, XCircle, X } from 'assets/icons';
import _ from 'lodash';

export type AlertType = 'warning' | 'error' | 'info';
export type AlertProps = {
  message?: string | null | React.ReactNode;
  title?: string;
  type?: AlertType;
  className?: string;
  rounded?: boolean;
  onClose?: () => void;
  actionButton?: JSX.Element;
};

const Alert = ({
  title,
  message,
  type,
  className = '',
  rounded = true,
  onClose,
  actionButton,
}: AlertProps): JSX.Element => {
  let bgColor =
    type === 'error'
      ? 'bg-danger-soft'
      : type === 'warning'
      ? 'bg-warning-soft'
      : 'bg-primary-soft';
  let textColor =
    type === 'error'
      ? 'text-danger'
      : type === 'warning'
      ? 'text-warning'
      : 'text-primary';

  let hoverBgColor =
    type === 'error'
      ? 'hover:bg-danger'
      : type === 'warning'
      ? 'hover:bg-warning'
      : 'hover:bg-primary';

  function renderIcon() {
    const className = `shrink-0 w-6 h-6 mr-2 stroke-3/2 ${textColor}`;
    switch (type) {
      case 'error':
        return <XCircle className={className} />;
      case 'warning':
      case 'info':
        return <InformationCircle className={className} />;
    }
  }
  return (
    <div
      className={`flex items-start p-3 ${
        rounded ? 'rounded-md' : ''
      } ${bgColor} ${className}`}
    >
      {renderIcon()}
      <div className="flex flex-grow flex-row items-center justify-between">
        <div>
          {title ? (
            <h3 className={`text-base font-semibold ${textColor}`}>{title}</h3>
          ) : null}
          {message ? (
            _.isString(message) ? (
              <p className="mt-1 text-base">{message}</p>
            ) : (
              <div className="mt-1 text-base">{message}</div>
            )
          ) : null}
        </div>
        {actionButton ? (
          <div className="ml-5 shrink-0">{actionButton}</div>
        ) : null}
      </div>
      {onClose ? (
        <X
          className={`h-6 w-6 stroke-2 ${textColor} ml-5 cursor-pointer rounded-full ${hoverBgColor} hover:text-white`}
          onClick={onClose}
        />
      ) : null}
    </div>
  );
};

export default Alert;
