import React, { useMemo } from 'react';
import { useLocation, useHistory, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFirebaseConnect } from 'react-redux-firebase';
import _ from 'lodash';

import { Session } from 'types/types';
import { WithId } from 'types/typesUtils';
import { useAllUserCommunities } from 'model/communitiesManagement';
import { useUserAlertBanner } from 'model/userAlert';

import CommunitiesList from 'blocks/CommunitiesList';
import SessionsList from 'blocks/SessionsList';

import MainBar from 'components/MainBar';
import Tabs, { TabInfo } from 'components/Tabs';
import LoadingScreen from 'screens/LoadingScreen';
import Alert from 'components/Alert';

import Button from 'components/Button';

import { useGlobalModal } from 'contexts/GlobalModal';
import { useUserContextWithDefaults } from 'contexts/UserContext';
import WelcomeScreen from './screens/WelcomeScreen';
import HomeRibbon from './blocks/HomeRibbon';

const HomeScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const { userId, userType, userName, userEmail } =
    useUserContextWithDefaults();

  const { pathname } = useLocation();
  const history = useHistory();

  const { show: showCreateSession } = useGlobalModal('createSession');

  const parts = pathname.split('/');

  const [communities, communitiesLoaded] = useAllUserCommunities(userId);

  const location = useLocation<{ error?: string }>();

  useFirebaseConnect([
    {
      path: `sessionsOfUsers/${userId}`,
    },
    {
      path: `sessionsNext`,
    },
    {
      path: `communitiesOfAdmins/${userId}`,
    },
  ]);

  const allSessions: WithId<Session>[] = useSelector((state: any) =>
    Object.entries<Session>(state.firebase.data.sessionsNext || {}).map(
      ([sessionId, session]) => {
        return { ...session, id: sessionId };
      }
    )
  );

  const sessionsOfUser: Record<string, Record<string, true>> = useSelector(
    (state: any) => state.firebase.data.sessionsOfUsers?.[userId] || {}
  );

  const nbCommunitiesAsAdmin: number = useSelector((state: any) =>
    _.size(state.firebase.data.communitiesOfAdmins?.[userId] || {})
  );

  const userSessions = useMemo(
    () =>
      _.orderBy(
        allSessions.filter(
          (session) =>
            ((session.communityId && communities?.[session.communityId]) ||
              sessionsOfUser?.[session.id]) &&
            !session.hidden
        ),
        (session) => session.scheduledAt[0],
        ['desc']
      ),
    [allSessions, communities, sessionsOfUser]
  );

  const [nextSessions, pastSessions, onGoingSessions] = useMemo(() => {
    const sessions = userSessions.reduce<Array<WithId<Session>[]>>(
      ([next, past, onGoing], session) => {
        if (session.scheduledAt[1] < Date.now()) {
          past.push(session);
        } else if (session.scheduledAt[0] > Date.now()) {
          next.unshift(session);
        } else {
          onGoing.unshift(session);
        }
        return [next, past, onGoing];
      },
      [[], [], []]
    );

    const sanitize = (sessions: WithId<Session>[]) => {
      const filtered = sessions.filter((session) => sessionsOfUser[session.id]);
      return filtered.length > 0 ? filtered : sessions;
    };

    return sessions.map(sanitize);
  }, [userSessions, sessionsOfUser]);

  const showTabs =
    (userSessions && userSessions.length) > 1 ||
    (communitiesLoaded && _.size(communities) > 1) ||
    nbCommunitiesAsAdmin > 0;

  const tabs: { info: TabInfo; component: JSX.Element }[] = [
    {
      info: {
        key: 'home',
        label: t('common:menuHomePage'),
      },
      component: (
        <WelcomeScreen
          onGoingSessions={onGoingSessions}
          nextSessions={nextSessions}
          pastSessions={pastSessions}
          nbSessions={userSessions.length}
          userId={userId}
          communities={communities}
          showTabs={showTabs}
        />
      ),
    },
  ];

  if (userSessions && userSessions.length > 0) {
    tabs.push({
      info: {
        key: 'sessions',
        label: t('common:menuAllSessions'),
      },
      component: (
        <SessionsList
          userId={userId}
          sessions={userSessions}
          communities={communities}
        />
      ),
    });
  }

  if (communitiesLoaded && _.size(communities) > 0) {
    tabs.push({
      info: {
        key: 'communities',
        label: t('communities:MyCommunities'),
      },
      component: <CommunitiesList communities={communities} />,
    });
  }

  const selected: string | undefined = tabs.find(
    (tab) => tab.info.key === parts[1]
  )?.info.key;

  useUserAlertBanner(userId, userEmail);

  return communitiesLoaded ? (
    <div className="text-lef flex h-screen w-full flex-col">
      <MainBar
        userId={userId}
        userType={userType}
        userName={userName}
        customWidthClass="max-w-5xl"
        displayAlertBanner
      />
      <div className="flex flex-grow flex-col overflow-y-auto bg-white">
        <HomeRibbon />
        {location.state?.error && (
          <div className="mx-auto mb-8 max-w-2xl">
            <Alert title={location.state.error} type="error" />
          </div>
        )}
        <div className="sticky top-0 z-10 border-b border-surfaces-divider bg-white">
          <div className="mx-auto flex max-w-5xl flex-row items-center justify-between px-4">
            <div className="flex min-w-0 flex-grow">
              <Tabs
                tabs={tabs.map(({ info }: { info: TabInfo }) => info)}
                selected={selected}
                onChange={(key) => history.push(`/${key}`)}
                controlled
              />
            </div>

            <div className="mr-4 flex shrink-0 flex-row items-center space-x-3 py-1">
              <Button
                text={t('sessions:NewSession')}
                onClick={() => showCreateSession()}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-grow flex-col">
          <div className="mx-auto w-full max-w-5xl pt-6 pb-16">
            <Switch>
              {tabs.map((tab) => (
                <Route key={tab.info.key} exact path={`/${tab.info.key}`}>
                  {tab.component}
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default HomeScreen;
