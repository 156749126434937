import { database as db, NOW, increment } from 'services/firebase';
import { Community, User } from 'types/types';
import { WithId } from 'types/typesUtils';
import { sanitizeKeyContainingDot } from 'utils/utils';
import { hasMember } from './communitiesManagement';
import { getUserFromEmail } from './users';

export const inviteEmailInCommunity = async (
  communityId: string,
  community: Community,
  email: string,
  inviter: WithId<User>
): Promise<void> => {
  const userTuple = await getUserFromEmail(email);
  if (userTuple) {
    const isMember = await hasMember(communityId, userTuple[0]);
    if (isMember) {
      return;
    }
  }

  const emailKey = sanitizeKeyContainingDot(email);
  const alertId = db.ref(`userAlerts/${emailKey}`).push();
  await db.ref().update({
    [`invitesOfCommunities/${communityId}/${emailKey}`]: {
      createdAt: NOW,
      email,
      state: 'pending',
      inviter,
    },
    [`userAlerts/${emailKey}/${alertId.key}`]: {
      type: 'communityInvite',
      communityName: community.name,
      communityId,
      email,
      inviter,
    },
  });
};

export const acceptInvite = async (
  communityId: string,
  email: string,
  userId: string
): Promise<void> => {
  const isMember = await hasMember(communityId, userId);
  const emailKey = sanitizeKeyContainingDot(email);
  await db.ref().update({
    [`invitesOfCommunities/${communityId}/${emailKey}`]: null,
    ...(isMember
      ? {}
      : {
          [`/communitiesOfUsers/${userId}/${communityId}`]: true,
          [`/usersOfCommunities/${communityId}/${userId}`]: true,
          [`/communities/${communityId}/nbMembers`]: increment(1),
        }),
  });
};

export const declineInvite = async (
  communityId: string,
  email: string
): Promise<void> => {
  const emailKey = sanitizeKeyContainingDot(email);
  await db
    .ref(`invitesOfCommunities/${communityId}/${emailKey}/state`)
    .set('refused');
};

export const forceResent = async (
  communityId: string,
  community: Community,
  email: string
): Promise<void> => {
  const emailKey = sanitizeKeyContainingDot(email);
  const alertId = db.ref(`userAlerts/${emailKey}`).push();
  await db.ref().update({
    [`invitesOfCommunities/${communityId}/${emailKey}/state`]: 'pending',
    [`userAlerts/${emailKey}/${alertId.key}`]: {
      type: 'communityInvite',
      communityName: community.name,
      communityId,
      email,
    },
  });
};

export const removeInvitation = async (
  communityId: string,
  community: Community,
  email: string
): Promise<void> => {
  const emailKey = sanitizeKeyContainingDot(email);
  // TODO: remove all onGoing alerts
  await db.ref().update({
    [`invitesOfCommunities/${communityId}/${emailKey}`]: null,
  });
};
