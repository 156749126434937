import React, { useMemo } from 'react';
import MindMap from 'frameworks/mind-elixir/MindMap';
import { addRootNode, addNode } from 'frameworks/mind-elixir/utils';
import { colors } from 'frameworks/mind-elixir/colors';
import { ProductionMeta, RSet, Topic, Users } from 'types/types';
import { Nullable } from 'types/typesUtils';
import Spinner from 'components/Spinner';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export type DebriefMindmapProps = {
  isTopicsLoaded: boolean;
  topics: Nullable<RSet<Topic>>;
  question: Nullable<string>;
  participants: Nullable<Users>;
  posts: Nullable<RSet<string>>;
  postsMeta: Nullable<RSet<ProductionMeta>>;
};

const DebriefMindmap = ({
  isTopicsLoaded,
  topics,
  question,
  participants,
  posts,
  postsMeta,
}: DebriefMindmapProps) => {
  const { t } = useTranslation();

  const topicsPost = useMemo(() => {
    return Object.entries(postsMeta || {}).reduce<Record<string, string[]>>(
      (prev, [userId, postMeta]) => {
        const userName = participants?.[userId]?.name || t('users:UnknownUser');
        const post = `${posts?.[userId]} - ${userName}`;

        if (postMeta.topic && post) {
          if (prev[postMeta.topic]) {
            prev[postMeta.topic].push(post);
          } else {
            prev[postMeta.topic] = [post];
          }
        }
        return prev;
      },
      {}
    );
  }, [postsMeta, posts, participants, t]);

  const mindMapMemoized = useMemo(() => {
    if (!isTopicsLoaded) return <Spinner className="h-6 w-6 text-primary" />;
    const rootNode = addRootNode(question || '');
    Object.entries(topics || {}).forEach(([topicId, topic]) => {
      if (rootNode?.nodeData?.children) {
        const topicColor = colors[topic.color];
        const newtopicsChild = addNode({
          id: topicId,
          label: topic.description,
          style: topicColor
            ? {
                background: topicColor.default,
                color: 'white',
              }
            : {},
        });
        if (topicsPost[topicId]) {
          topicsPost[topicId].forEach((post) => {
            if (newtopicsChild?.children) {
              newtopicsChild.children.push(
                addNode({
                  label: post,
                })
              );
            }
          });
        }
        rootNode.nodeData.children.push(newtopicsChild);
      }
    });
    return <MindMap readOnly={true} data={rootNode} />;
  }, [question, topics, isTopicsLoaded, topicsPost]);

  return _.size(topics) ? (
    <div className="space-y-2">
      <h2 className="text-xl font-semibold">{t('sessions:Mindmap')}</h2>
      {mindMapMemoized}
    </div>
  ) : null;
};

export default DebriefMindmap;
