import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createSessionLite } from 'model/sessions';

import Layout from './Layout';
import Link from 'components/Link';
import Button from 'components/Button';
import ExtendedInput from 'frameworks/formik/ExtendedInput';
import Checkbox from 'frameworks/formik/CheckboxInput';
import Alert from 'components/Alert';

import { HistoryState } from 'utils/history';
import { gerErrorDescription } from 'services/firebase';
import { createUser } from 'model/users';
import { passwordSchema } from './utils';

type FormData = {
  email: string;
  password: string;
  displayName: string;
  consent: boolean;
};

const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const state = location.state;
  const intialEmail = state?.email ? state?.email : '';
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null
  );
  const [currentEmail, setCurrentEmail] = useState(intialEmail);
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const continueUrl =
    query.get('continueUrl') && encodeURIComponent(query.get('continueUrl')!);

  const isCreatingSession = !!state?.dataSession;

  const links = {
    signIn: {
      text: t('login:linkSignin'),
      to: {
        pathname: '/login',
        search: `${continueUrl ? `?continueUrl=${continueUrl}` : ''}`,
        state: {
          email: currentEmail,
          from: state?.from,
          message: state?.message,
          dataSession: state?.dataSession,
        },
      },
    },
  };

  const SubmitButton = (): JSX.Element => {
    const { isValid } = useFormikContext<FormData>();

    return (
      <Button
        disabled={!isValid}
        loading={loading}
        type="submit"
        text={t('login:signupButton')}
      />
    );
  };

  return (
    <Layout
      title={
        isCreatingSession
          ? t('login:signupTitleToCreateSession')
          : t('login:signupTitle')
      }
      subtitleText={t('login:signupDescription')}
      subtitleLink={links.signIn}
    >
      <div className="mt-6 md:mt-10">
        {globalErrorMessage ? (
          <Alert
            title={t('login:signupErrorTitle')}
            message={globalErrorMessage}
            type="error"
            className="mb-4"
          />
        ) : null}
        {state?.message ? (
          <Alert
            title={t('common:information')}
            message={state?.message}
            type="warning"
            className="mb-4"
          />
        ) : null}
        <Formik
          validateOnMount
          initialValues={{
            email: intialEmail,
            password: '',
            displayName: '',
            consent: false,
          }}
          validationSchema={Yup.object({
            displayName: Yup.string().required(t('form:fieldRequired')),
            password: passwordSchema(t),
            email: Yup.string()
              .email(t('form:invalideEmail'))
              .required(t('form:fieldRequired')),
            consent: Yup.boolean().equals(
              [true],
              t('form:termsAndConditionsRequired')
            ),
          })}
          onSubmit={async (values) => {
            setLoading(true);
            setGlobalErrorMessage(null);
            try {
              const { email, password, displayName } = values;

              const userId = await createUser(email, password, displayName);
              if (userId) {
                if (isCreatingSession) {
                  createSessionLite(
                    userId,
                    state.dataSession!.title,
                    state.dataSession!.theme,
                    state.dataSession!.peerProfile,
                    (accessCode) => {
                      if (accessCode) {
                        history.push(`/${accessCode}`);
                      }
                    }
                  );
                } else {
                  history.push({
                    pathname: continueUrl
                      ? decodeURIComponent(continueUrl)
                      : '/',
                    state: state,
                  });
                }
              }
            } catch (error) {
              console.error(error);
              setGlobalErrorMessage(gerErrorDescription((error as any).code));
            }
            setLoading(false);
          }}
        >
          <Form>
            <ExtendedInput
              label={t('login:emailAddressLabel')}
              name="email"
              inputType="email"
              autoComplete="email"
              onChange={setCurrentEmail}
            />
            <ExtendedInput
              className="mt-4"
              label={t('login:displayNameLabel')}
              name="displayName"
              inputType="text"
              autoComplete="name"
            />
            <ExtendedInput
              className="mt-4"
              label={t('login:passwordLabel')}
              name="password"
              inputType="password"
              autoComplete="new-password"
            />
            <Checkbox name="consent" className="mt-4">
              {
                <span className="text-base md:text-lg 3xl:text-xl">
                  {t('login:consentStart')}
                  <Link
                    external
                    to={{ pathname: 'https://www.wearepeers.com/cgu' }}
                    text={t('login:consentCgu')}
                    className="md:font-bold 3xl:text-xl"
                  />
                  {t('login:consentAnd')}
                  <Link
                    external
                    to={{
                      pathname:
                        'https://www.wearepeers.com/politique-de-confidentialite',
                    }}
                    text={t('login:consentPrivacyPolicy')}
                    className="md:font-bold 3xl:text-xl"
                  />
                </span>
              }
            </Checkbox>
            <div className="mt-6 flex w-full flex-row-reverse">
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

export default SignUp;
