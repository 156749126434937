import React from 'react';

import Colors from 'constants/Colors';

const Page = ({
  children,
  color,
  style,
  padding,
  paddingHalf,
  fullscreen,
  className,
  ...props
}: {
  children?: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
  padding?: boolean;
  paddingHalf?: boolean;
  fullscreen?: boolean;
  className?: string;
}) => {
  if (color) {
    document.body.style.backgroundColor = color;
  }
  const styleAfterProps: React.CSSProperties = {
    backgroundColor: color, // chose the background color in the props
    padding: paddingHalf ? 8 : padding ? 16 : 0, // 0 default
    maxWidth: fullscreen ? '100%' : '768px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    margin: 'auto',
    // overflowX: 'hidden',
  };
  return (
    <div
      {...props} // pass the other props to the div
      style={{
        ...styleAfterProps,
        ...style, // or apply your own style
      }}
    >
      {children}
    </div>
  );
};

Page.defaultProps = {
  color: Colors.colorWhite, // or let the default color be applied
  fullscreen: false,
};

export default Page;
