import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import css from './index.module.css';

import Modal from 'componentsOld/Modal';
import Colors from 'constants/Colors';

const randomHeaderColor = (index) => {
  const colors = [
    {
      color: Colors.colorRedHeaderText,
      backgroundColor: Colors.colorRedHeaderBackground,
    },
    {
      color: Colors.colorBlueHeaderText,
      backgroundColor: Colors.colorBlueHeaderBackground,
    },
    {
      color: Colors.colorGreenHeaderText,
      backgroundColor: Colors.colorGreenHeaderBackground,
    },
    {
      color: Colors.colorPurpleHeaderText,
      backgroundColor: Colors.colorPurpleHeaderBackground,
    },
    {
      color: Colors.colorGreyHeaderText,
      backgroundColor: Colors.colorGreyHeaderBackground,
    },
    {
      color: Colors.colorYellowHeaderText,
      backgroundColor: Colors.colorYellowHeaderBackground,
    },
  ];
  return colors[index % colors.length].color;
};

export const ChooseGroupModal = ({
  active,
  groups,
  groupPrefix,
  onExit = () => console.log('onExit'),
  onClickGroup = () => null,
  canAdd,
}) => {
  const { t } = useTranslation();
  if (active) {
    const sortedGroups = [
      ...(canAdd
        ? [
            {
              number: 0,
              name: t('facilitator:NewGroup'),
            },
          ]
        : []),
      ..._.sortBy(groups, (group) => group?.posts?.length || 0),
    ];
    return (
      <Modal className={css.modal} onExit={onExit}>
        <div className={css.modalContainer}>
          <span style={{ borderBottom: '0.5px solid #D1D1D6', padding: 5 }}>
            {t('facilitator:MoveTo')}
          </span>
          <div className={css.flexbox}>
            {sortedGroups.map((group, index) => (
              <div key={index} style={{ marginTop: '5px' }}>
                <span
                  style={{ color: randomHeaderColor(group.number) }}
                  className={[css.label, onClickGroup && css.clickable].join(
                    ' '
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClickGroup(group);
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      fontSize: '30px',
                      fontWeight: 'bold',
                    }}
                  >
                    {group.number
                      ? `${groupPrefix || ''}${group.number} `
                      : group.name}
                  </span>
                  {/* {group.number
                    ? `Group ${groupPrefix || ''}${group.number} `
                    : group.name} */}
                  {group.number ? (
                    <span style={{ marginLeft: '20px', color: 'black' }}>
                      {group?.posts?.length || 0} participants
                    </span>
                  ) : null}{' '}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};
