import { UserAlert, UserAlerts } from 'types/types';
import i18n from 'services/i18n';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { database as db } from 'services/firebase';
import { acceptInvite, declineInvite } from './communityInvites';
import {
  sanitizeKeyContainingDot,
  useMemoCompare,
  usePrevious,
} from 'utils/utils';
import { useEffect, useMemo } from 'react';
import { useAlert } from 'contexts/Alerts';
import React from 'react';
import Button from 'components/Button';

export type AlertAction = { description: string; action: () => Promise<void> };

export type AlertContent = {
  title: string;
  description: string;
  actions: AlertAction[];
};

export const useNextAlert = (
  userId: string,
  userEmail: string
): AlertContent | undefined => {
  const emailKey = sanitizeKeyContainingDot(userEmail);

  useFirebaseConnect([`userAlerts/${userId}`, `userAlerts/${emailKey}`]);

  const alerts: UserAlerts = useSelector((state: any) => ({
    ...(state.firebase.data.userAlerts?.[userId] || {}),
    ...(state.firebase.data.userAlerts?.[emailKey] || {}),
  }));

  const alert = useMemoCompare(Object.entries(alerts)[0], _.isEqual);

  const alertContent = useMemo(
    () => alert && buildAlertContent(...alert, userId),
    [alert, userId]
  );

  return alertContent;
};

const buildAlertContent = (
  alertId: string,
  alert: UserAlert,
  userId: string
): AlertContent => {
  switch (alert.type) {
    case 'communityInvite':
      const removeAlert = async () => {
        await db
          .ref(`userAlerts/${sanitizeKeyContainingDot(alert.email)}/${alertId}`)
          .remove();
      };
      return {
        title: i18n.t('communities:inviteTitle', {
          communityName: alert.communityName,
        }),
        description: i18n.t('communities:inviteDescription', {
          communityName: alert.communityName,
          inviterName: alert.inviter?.name,
        }),
        actions: [
          {
            description: i18n.t('communities:acceptInvite'),
            action: async () => {
              await acceptInvite(alert.communityId, alert.email, userId);
              await removeAlert();
            },
          },
          {
            description: i18n.t('communities:refuseInvite'),
            action: async () => {
              await declineInvite(alert.communityId, alert.email);
              await removeAlert();
            },
          },
        ],
      };
  }
};

export const useUserAlertBanner = (userId: string, userEmail: string): void => {
  const alertContent = useNextAlert(userId, userEmail);

  const previousAlertContent = usePrevious(alertContent);
  const [, , helpers] = useAlert();

  useEffect(() => {
    if (alertContent && !_.isEqual(previousAlertContent, alertContent)) {
      helpers.displayAlert({
        type: 'info',
        description: alertContent.description,
        title: alertContent.title,
        callToAction: (
          <div className="space-x-4">
            {alertContent.actions.map(({ description, action }, index) => (
              <Button
                key={index}
                text={description}
                onClick={() => {
                  action();
                }}
              />
            ))}
          </div>
        ),
      });
    } else if (!alertContent) {
      helpers.clearAlert();
    }
  });
};
