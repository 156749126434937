import React from 'react';

export type CheckboxProps = {
  id?: string;
  value: boolean;
  children?: JSX.Element;
  onChange?: (value: boolean) => void;
};

const Checkbox = ({
  id = 'checkbox',
  value,
  children,
  onChange,
}: CheckboxProps): JSX.Element => {
  return (
    <div className="inline-flex items-center">
      <input
        name={id}
        checked={!!value}
        type="checkbox"
        className="form-checkbox h-6 w-6 rounded-md text-primary focus:border-primary focus:ring-transparent"
        onChange={(e) => {
          onChange?.(e.target.checked);
        }}
      />
      <label htmlFor={id} className="ml-4 w-full">
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
