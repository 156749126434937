import React, { useState, MouseEvent, useEffect } from 'react';
import { isPromise } from 'utils/utils';
import Spinner from './Spinner';
import { Check } from 'assets/icons';
import { ColorNames, Colors } from 'types/ui';

export type ButtonProps = {
  size?: 'sm' | 'md' | 'base' | 'xl';
  design?: 'primary' | 'secondary';
  className?: string;
  text: string;
  doneText?: string;
  icon?: JSX.Element;
  iconPlacement?: 'left' | 'right';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean | undefined;
  enableDoneStatus?: boolean;
  onClick?: () => void | Promise<void>;
  color?: ColorNames;
  doneColor?: ColorNames;
  stopPropagation?: boolean;
  faded?: boolean;
  badge?: string;
};

type ColorType = {
  textColor: string;
  bgColor: string;
  borderColor: string;
  textPrimaryColor?: string;
  hoverColor: {
    primary: string;
    secondary: string;
  };
  badgeColor: {
    primary: string;
    secondary: string;
  };
};

const colors: Colors<ColorType> = {
  primary: {
    textColor: 'text-primary',
    bgColor: 'bg-primary',
    borderColor: 'border-primary',
    hoverColor: {
      primary: 'hover:bg-primary-strong hover:border-primary-strong',
      secondary: 'hover:bg-primary-soft',
    },
    badgeColor: {
      primary: 'bg-primary-soft text-primary',
      secondary: 'bg-primary text-primary-soft',
    },
  },
  success: {
    textColor: 'text-success',
    bgColor: 'bg-success',
    borderColor: 'border-success',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-success-soft',
    },
    badgeColor: {
      primary: 'bg-success-soft text-success',
      secondary: 'bg-success text-success-soft',
    },
  },
  warning: {
    textColor: 'text-warning',
    bgColor: 'bg-warning',
    borderColor: 'border-warning',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-warning-soft',
    },
    badgeColor: {
      primary: 'bg-warning-soft text-warning',
      secondary: 'bg-warning text-warning-soft',
    },
  },
  danger: {
    textColor: 'text-danger',
    bgColor: 'bg-danger',
    borderColor: 'border-danger',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-danger-soft',
    },
    badgeColor: {
      primary: 'bg-danger-soft text-danger',
      secondary: 'bg-danger text-danger-soft',
    },
  },
  gray: {
    textColor: 'text-black-soft',
    textPrimaryColor: 'text-black-soft',
    bgColor: 'bg-gray-soft',
    borderColor: 'border-gray-soft',
    hoverColor: {
      primary: 'hover:bg-surfaces-divider',
      secondary: '',
    },
    badgeColor: {
      primary: 'bg-black-soft text-surfaces-divider',
      secondary: 'bg-surfaces-divider text-black-soft',
    },
  },
  yellow: {
    textColor: 'text-yellow-600',
    textPrimaryColor: '',
    bgColor: 'bg-yellow-600',
    borderColor: 'border-yellow-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-yellow-200',
    },
    badgeColor: {
      primary: 'bg-yellow-200 text-yellow-600',
      secondary: 'bg-yellow-600 text-yellow-200',
    },
  },
  fuchsia: {
    textColor: 'text-fuchsia-600',
    textPrimaryColor: '',
    bgColor: 'bg-fuchsia-600',
    borderColor: 'border-fuchsia-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-fuchsia-200',
    },
    badgeColor: {
      primary: 'bg-fuchsia-200 text-fuchsia-600',
      secondary: 'bg-fuchsia-600 text-fuchsia-200',
    },
  },
  green: {
    textColor: 'text-green-600',
    textPrimaryColor: '',
    bgColor: 'bg-green-600',
    borderColor: 'border-green-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-green-200',
    },
    badgeColor: {
      primary: 'bg-green-200 text-green-600',
      secondary: 'bg-green-600 text-green-200',
    },
  },
  indigo: {
    textColor: 'text-indigo-600',
    textPrimaryColor: '',
    bgColor: 'bg-indigo-600',
    borderColor: 'border-indigo-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-indigo-200',
    },
    badgeColor: {
      primary: 'bg-indigo-200 text-indigo-600',
      secondary: 'bg-indigo-600 text-indigo-200',
    },
  },
  lime: {
    textColor: 'text-lime-600',
    textPrimaryColor: '',
    bgColor: 'bg-lime-600',
    borderColor: 'border-lime-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-lime-200',
    },
    badgeColor: {
      primary: 'bg-lime-200 text-lime-600',
      secondary: 'bg-lime-600 text-lime-200',
    },
  },
  sky: {
    textColor: 'text-sky-600',
    textPrimaryColor: '',
    bgColor: 'bg-sky-600',
    borderColor: 'border-sky-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-sky-200',
    },
    badgeColor: {
      primary: 'bg-sky-200 text-sky-600',
      secondary: 'bg-sky-600 text-sky-200',
    },
  },
  teal: {
    textColor: 'text-teal-600',
    textPrimaryColor: '',
    bgColor: 'bg-teal-600',
    borderColor: 'border-teal-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-teal-200',
    },
    badgeColor: {
      primary: 'bg-teal-200 text-teal-600',
      secondary: 'bg-teal-600 text-teal-200',
    },
  },
  violet: {
    textColor: 'text-violet-600',
    textPrimaryColor: '',
    bgColor: 'bg-violet-600',
    borderColor: 'border-violet-600',
    hoverColor: {
      primary: '',
      secondary: 'hover:bg-violet-200',
    },
    badgeColor: {
      primary: 'bg-violet-200 text-violet-600',
      secondary: 'bg-violet-600 text-violet-200',
    },
  },
};

export default function Button({
  text,
  doneText,
  className,
  type = 'button',
  disabled = false,
  loading,
  enableDoneStatus = false,
  onClick,
  icon,
  iconPlacement = 'left',
  size = 'base',
  design = 'primary',
  color = 'primary',
  doneColor,
  stopPropagation = false,
  faded = false,
  badge,
}: ButtonProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const handleClick = (event: MouseEvent) => {
    stopPropagation && event.stopPropagation();
    if (onClick) {
      const res = onClick();
      if (isPromise(res)) {
        setIsLoading(true);
        (res as Promise<void>)
          .then(() => {
            setIsLoading(false);
            enableDoneStatus && setDone(true);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      } else {
        enableDoneStatus && setDone(true);
      }
    }
  };

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setDone(false);
      }, 2000);
    }
  }, [done]);

  const {
    textColor,
    textPrimaryColor,
    bgColor,
    borderColor,
    hoverColor,
    badgeColor,
  } = colors[done ? doneColor || color : color];
  const buttonSizeClassName = `${
    size === 'sm'
      ? 'px-2 py-1 text-sm'
      : size === 'md'
      ? 'px-4 py-2'
      : size === 'xl'
      ? 'px-8 py-4 text-xl'
      : 'px-6 py-3 text-base'
  }`;
  const iconSizeClassName =
    iconPlacement === 'left'
      ? `${
          size === 'sm'
            ? 'w-4 h-4 mr-1'
            : size === 'md'
            ? 'w-5 h-5 mr-2'
            : size === 'xl'
            ? 'w-8 h-8 mr-4'
            : 'w-6 h-6 mr-3'
        }`
      : `${
          size === 'sm'
            ? 'w-4 h-4 ml-1'
            : size === 'md'
            ? 'w-5 h-5 ml-2'
            : size === 'xl'
            ? 'w-8 h-8 ml-4'
            : 'w-6 h-6 ml-3'
        }`;

  const badgeColorClassName =
    design === 'secondary' ? badgeColor.secondary : badgeColor.primary;

  const colorButtonClassName = `${
    faded ? 'opacity-40 hover:opacity-100' : hoverColor[design]
  } ${
    design === 'secondary'
      ? `bg-white ${textColor} border-2 ${borderColor}`
      : `${bgColor} ${textPrimaryColor || 'text-white'} border-2 ${borderColor}`
  }`;
  const enabledClassName = `${colorButtonClassName} ${
    (loading === undefined ? isLoading : loading) ? 'cursor-not-allowed' : ''
  }`;
  const disbaledClassname = 'bg-gray-200 text-gray-400 cursor-not-allowed';
  const computedClassName = `inline-flex items-center text-center rounded-full font-semibold  flex items-center justify-center ${className} ${
    disabled ? disbaledClassname : enabledClassName
  } ${buttonSizeClassName}`;

  const iconBlock = (loading === undefined ? isLoading : loading) ? (
    <Spinner className={iconSizeClassName} />
  ) : done ? (
    <Check className={`stroke-2 ${iconSizeClassName}`} />
  ) : icon ? (
    <span className={iconSizeClassName}>{icon}</span>
  ) : null;
  return (
    <button
      type={type}
      className={computedClassName}
      disabled={disabled || (loading === undefined ? isLoading : loading)}
      onClick={handleClick}
    >
      {iconPlacement === 'left' ? iconBlock : null}
      {done ? doneText || text : text}
      {iconPlacement === 'right' ? iconBlock : null}
      {badge ? (
        <span
          className={`ml-2 flex min-w-[25px] items-center justify-center rounded-full px-2 ${badgeColorClassName}`}
        >
          {badge}
        </span>
      ) : null}
    </button>
  );
}
