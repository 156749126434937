import React, { useState } from 'react';
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FieldInputProps,
  ErrorMessage,
} from 'formik';
import { useTranslation } from 'react-i18next';

import { Mode } from 'types/ui';

import LayoutFormPreview from 'blocks/LayoutFormPreview';

import FeedbackView from 'screens/SessionScreen/SessionDashboardScreen/views/FeedbackView';

import Button from 'components/Button';
import SmileyRating, { SmileyNoteType } from 'components/SmileyRating';
import RadioGroup, { RadioItem } from 'components/RadioGroup';
import Modal from 'components/Modal';
import { SuperThankYou } from 'assets/undraw';
import Link from 'components/Link';

type YesNoOptions = RadioItem<any>[];

type QuestionStyle = 'rating' | 'text' | 'yesNo';
type AnswerType = {
  value: any | null;
  set: (value: any) => void;
};

type QuestionType = {
  required: boolean;
  defaultValue: string;
  value: string;
  set: (value: string) => void;
  description: string;
  style: QuestionStyle;
  answer: AnswerType;
};

type FeedbackAnswerScreenViewProps = {
  questions: QuestionType[];
  mode: Mode;
  sessionId: string;
};

const FeedbackAnswerScreenView = ({
  questions,
  mode,
  sessionId,
}: FeedbackAnswerScreenViewProps): JSX.Element => {
  const { t } = useTranslation();
  const commonFieldClassName = 'w-full p-3 border rounded-md';
  const commonLabelClassName = 'text-xl font-semibold';

  const [submitted, setSubmitted] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);

  const yesNoOptions: YesNoOptions = [
    {
      value: true,
      description: t('common:yes'),
    },
    {
      value: false,
      description: t('common:no'),
    },
  ];

  const onCloseThanskModal = () => {
    setSubmitted(true);
    setShowThanksModal(false);
  };

  return (
    <>
      {!submitted ? (
        <div className="space-y-6">
          <Formik
            enableReinitialize
            initialValues={{
              answers: questions.map(
                (question) => question.answer.value || undefined
              ),
            }}
            onSubmit={async (values) => {
              await values.answers.reduce(async (promise, answer, index) => {
                await promise;
                return answer !== undefined
                  ? questions[index].answer.set(answer)
                  : Promise.resolve();
              }, Promise.resolve());
              setShowThanksModal(true);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className="space-y-4">
                  {questions && questions.length > 0 ? (
                    <FieldArray
                      name="answers"
                      render={() =>
                        questions.map((question, index) => {
                          return (
                            <div key={index} className="space-y-4">
                              <label
                                htmlFor={`answers.${index}`}
                                className={`${commonLabelClassName}`}
                              >
                                {question.value || question.defaultValue}
                              </label>
                              {question.style === 'rating' ? (
                                <div className="flex">
                                  <Field
                                    id={`answers.${index}`}
                                    name={`answers.${index}`}
                                  >
                                    {({
                                      field,
                                    }: {
                                      field: FieldInputProps<SmileyNoteType>;
                                    }) => {
                                      return (
                                        <SmileyRating
                                          value={field.value}
                                          setValue={(value) =>
                                            setFieldValue(
                                              `answers.${index}`,
                                              value
                                            )
                                          }
                                        />
                                      );
                                    }}
                                  </Field>
                                </div>
                              ) : question.style === 'yesNo' ? (
                                <Field
                                  id={`answers.${index}`}
                                  name={`answers.${index}`}
                                >
                                  {({
                                    field,
                                  }: {
                                    field: FieldInputProps<any>;
                                  }) => {
                                    return (
                                      <RadioGroup<any>
                                        options={yesNoOptions}
                                        value={field.value}
                                        setValue={(value) =>
                                          setFieldValue(
                                            `answers.${index}`,
                                            value
                                          )
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              ) : (
                                <Field
                                  id={`answers.${index}`}
                                  name={`answers.${index}`}
                                  className={`${commonFieldClassName}`}
                                />
                              )}
                            </div>
                          );
                        })
                      }
                    />
                  ) : null}
                  <Button
                    type="submit"
                    text={t('sessions:shareFeedback')}
                    disabled={
                      mode === 'editor' ||
                      mode === 'preview' ||
                      values.answers[0] === undefined
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <FeedbackView
          sessionId={sessionId}
          anonymous={true}
          showExportButton={false}
        />
      )}
      <Modal
        open={showThanksModal}
        onClose={onCloseThanskModal}
        body={
          <div className="flex max-w-lg flex-col items-center">
            <SuperThankYou className="h-44 fill-current text-primary" />
            <h1 className="mt-8 text-center text-3xl font-semibold">
              {t('sessions:ThanksFeedbackTitle')}
            </h1>
            <p className="mt-4  mb-4 text-center text-xl font-semibold text-gray-500">
              {t('sessions:ThanksFeedbackDescription')}
            </p>
            <Link
              className="mb-12"
              external
              text={t('sessions:postGoogleFeedback')}
              to={{ pathname: 'https://g.page/r/CYliDO9exbQgEB0/review' }}
            />
          </div>
        }
        footer={
          <Button text={t('common:Close')} onClick={onCloseThanskModal} />
        }
      />
    </>
  );
};

type FeedbackAnswerScreenFromProps = {
  questions: QuestionType[];
};

const FeedbackAnswerScreenForm = ({
  questions,
}: FeedbackAnswerScreenFromProps): JSX.Element => {
  const { t } = useTranslation();

  const commonFieldClassName = 'w-full p-3 border rounded-md mt-2';
  const commonLabelClassName = 'text-lg text-primary';

  return (
    <Formik
      initialValues={{
        questions: questions.map((question) => question.value),
      }}
      validate={(values) => {
        const errors: any = { questions: [] };
        values.questions.forEach((question, index) => {
          let initialQuestionValue = questions[index].value;
          let setter = questions[index].set;
          let isRequired = questions[index].required;

          if (setter && question !== initialQuestionValue) {
            setter(question);
          }
          if (isRequired && !question) {
            errors.questions.push(t('sessions:feedbackErrorDefaultQuestion'));
          } else {
            errors.questions.push(undefined);
          }
        });
        return errors;
      }}
      onSubmit={() => {
        // Do nothing
      }}
    >
      <Form className="space-y-4">
        {questions && questions.length > 0 ? (
          <FieldArray
            name="questions"
            render={() =>
              questions.map((question, index) => {
                return (
                  <div key={index}>
                    <label
                      htmlFor={`questions.${index}`}
                      className={`${commonLabelClassName}`}
                    >
                      {question.description}
                    </label>
                    <Field
                      id={`questions.${index}`}
                      name={`questions.${index}`}
                      className={`${commonFieldClassName}`}
                      placeholder={
                        question.defaultValue !== ''
                          ? question.defaultValue
                          : undefined
                      }
                    />
                    <ErrorMessage
                      name={`questions.${index}`}
                      render={(msg) => (
                        <div className="text-xs text-danger">{msg}</div>
                      )}
                    />
                  </div>
                );
              })
            }
          />
        ) : null}
      </Form>
    </Formik>
  );
};

type QuestionProps = {
  questionValuable: string;
  setQuestionValuable: (value: string) => void;
  customQuestionA: string;
  setCustomQuestionA: (value: string) => void;
  customQuestionB: string;
  setCustomQuestionB: (value: string) => void;
  customQuestionC: string;
  setCustomQuestionC: (value: string) => void;
  customQuestionD: string;
  setCustomQuestionD: (value: string) => void;
  valuable: string | null;
  setValuable: (value: string) => void;
  questionAAnswer: string | null;
  setQuestionAAnswer: (value: string) => void;
  questionBAnswer: string | null;
  setQuestionBAnswer: (value: string) => void;
  questionCAnswer: string | null;
  setQuestionCAnswer: (value: string) => void;
  future: boolean;
  setFuture: (value: boolean) => void;
  setValuableDescription: (value: string) => void;
  setQuestionAAnswerDescription: (value: string) => void;
  setQuestionBAnswerDescription: (value: string) => void;
  setQuestionCAnswerDescription: (value: string) => void;
  setFutureDescription: (value: string) => void;
};

type FeedbackAnswerScreenProps = FeedbackAnswerScreenViewProps &
  FeedbackAnswerScreenFromProps &
  QuestionProps & {
    mode: Mode;
  };

const FeedbackAnswerScreen = (
  props: FeedbackAnswerScreenProps
): JSX.Element => {
  const { t } = useTranslation();
  const questions: QuestionType[] = [];

  questions.push({
    required: true,
    defaultValue: t('sessions:feedbackDefaultValuableQuestion'),
    value: props.questionValuable || '',
    set: (value: string) => {
      props.setQuestionValuable(value);
      props.setValuableDescription(value);
    },
    description: t('sessions:feedbackMainQuestion'),
    style: 'rating',
    answer: {
      value: props.valuable,
      set: props.setValuable,
    },
  });

  questions.push({
    required: false,
    defaultValue: '',
    value: props.customQuestionA,
    set: (value: string) => {
      props.setCustomQuestionA(value);
      props.setQuestionAAnswerDescription(value);
    },
    style: 'text',
    description: t('sessions:feedbackComplementaryQuestion', { n: 1 }),
    answer: {
      value: props.questionAAnswer,
      set: props.setQuestionAAnswer,
    },
  });

  questions.push({
    required: false,
    defaultValue: '',
    value: props.customQuestionB,
    set: (value: string) => {
      props.setCustomQuestionB(value);
      props.setQuestionBAnswerDescription(value);
    },
    style: 'text',
    description: t('sessions:feedbackComplementaryQuestion', { n: 2 }),
    answer: {
      value: props.questionBAnswer,
      set: props.setQuestionBAnswer,
    },
  });

  questions.push({
    required: false,
    defaultValue: '',
    value: props.customQuestionC,
    set: (value: string) => {
      props.setCustomQuestionC(value);
      props.setQuestionCAnswerDescription(value);
    },
    style: 'text',
    description: t('sessions:feedbackComplementaryQuestion', { n: 3 }),
    answer: {
      value: props.questionCAnswer,
      set: props.setQuestionCAnswer,
    },
  });

  questions.push({
    required: true,
    defaultValue: t('sessions:feedbackDefaultFutureQuestion'),
    value: props.customQuestionD,
    set: (value: string) => {
      props.setCustomQuestionD(value);
      props.setFutureDescription(value);
    },
    style: 'yesNo',
    description: t('sessions:feedbackComplementaryQuestion', { n: 4 }),
    answer: {
      value: props.future,
      set: props.setFuture,
    },
  });

  return (
    <div className="mt-8">
      <LayoutFormPreview
        mode={props.mode}
        form={<FeedbackAnswerScreenForm questions={questions} />}
        view={
          <FeedbackAnswerScreenView
            questions={questions.filter(
              (question) => question.required || question.value !== ''
            )}
            mode={props.mode}
            sessionId={props.sessionId}
          />
        }
      />
    </div>
  );
};

export default FeedbackAnswerScreen;
