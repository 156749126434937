import React from 'react';

import GroupPresentation from 'componentsOld/Group/GroupPresentation';
import Page from 'componentsOld/Page';

const AdminMatchAllGroupsScreen = ({ groups, groupPrefix }) => (
  <Page style={{ marginTop: '50px' }}>
    {groups.map((group, i) => {
      return (
        <GroupPresentation
          {...group}
          number={group.number || i + 1}
          key={group.id}
          joined={false}
          leaderId={group.leaderId}
          disabled
          groupPrefix={groupPrefix}
        />
      );
    })}
  </Page>
);

export default AdminMatchAllGroupsScreen;
