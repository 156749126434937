import React from 'react';

export type StickyNoteProps = {
  children: JSX.Element;
  className?: string;
  onClick?: () => void;
};

const StickyNote = ({
  children,
  className,
  onClick,
}: StickyNoteProps): JSX.Element => {
  return (
    <div
      className={`flex items-center justify-center bg-yellow-100 p-2 shadow-md print:hidden ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default StickyNote;
