import {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng';

import {
  resetRequestedTurnOffVideo,
  resetRequestedMuteAudio,
  resetRequestedStopScreenSharing,
  startShareScreen,
  stopShareScreen,
  getUserIdSharingScreen,
} from 'model/meeting';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { usePrevious } from 'utils/utils';

import { MeetingDataUserRequest } from 'types/baseTypes';

import VideoButton from 'blocks/VideoButton';
import { VideoMode } from './types';

type LocalVideoCardProps = {
  localVideoTrack: ILocalVideoTrack | null;
  localAudioTrack: ILocalAudioTrack | null;
  localVideoTrackError: boolean;
  localAudioTrackError: boolean;
  client: IAgoraRTCClient | null;
  startLocalScreenTrack: () => Promise<ILocalVideoTrack | undefined>;
  setRequestedMode: (mode: VideoMode) => void;
  userId: string;
  meetingName: string;
  isFacilitator: boolean;
};

const LocalVideoCard = ({
  localVideoTrack,
  localAudioTrack,
  localVideoTrackError,
  localAudioTrackError,
  client,
  startLocalScreenTrack,
  setRequestedMode,
  userId,
  meetingName,
  isFacilitator,
}: LocalVideoCardProps): JSX.Element => {
  const { t } = useTranslation();

  const containerRef = useRef(null);
  const [hasVideo, setHasVideo] = useState(true);
  const [hasAudio, setHasAudio] = useState(true);
  const [screenTrack, setScreenTrack] = useState<ILocalVideoTrack | null>(null);
  const [hasVideoBeforeSharing, setHasVideoBeforeSharing] = useState<
    boolean | null
  >(null);

  useFirebaseConnect([
    `meetingsData/${meetingName}/sharingUserId`,
    `meetingsData/${meetingName}/users/${userId}`,
  ]);

  const dataUserRequest: MeetingDataUserRequest = useSelector(
    (state: any) =>
      state.firebase.data?.meetingsData?.[meetingName]?.users?.[userId] || {}
  );

  const {
    requestedMuteAudio,
    requestedTurnOffVideo,
    requestedStopScreenSharing,
  } = dataUserRequest;

  const sharingUserId: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.meetingsData?.[meetingName]?.sharingUserId || null
  );

  const previousSharingUserId = usePrevious(sharingUserId);

  useEffect(() => {
    if (!previousSharingUserId && sharingUserId && sharingUserId !== userId) {
      setRequestedMode('speakerFullScreen');
    }
    if (previousSharingUserId && !sharingUserId) {
      setRequestedMode(isFacilitator ? 'childrenFullScreen' : 'hybrid');
    }
  }, [
    sharingUserId,
    previousSharingUserId,
    userId,
    setRequestedMode,
    isFacilitator,
  ]);

  useEffect(() => {
    const videoTrack = localVideoTrack;
    if (localVideoTrack?.enabled && containerRef.current) {
      localVideoTrack.play(containerRef.current);
      setHasAudio(true);
      setHasVideo(true);
    }

    return () => videoTrack?.close();
  }, [localVideoTrack]);

  useEffect(() => {
    if (localAudioTrack?.enabled && requestedMuteAudio) {
      localAudioTrack.setEnabled(false);
      setHasAudio(false);
      resetRequestedMuteAudio(meetingName, userId);
    }
  }, [localAudioTrack, requestedMuteAudio, userId, meetingName]);

  useEffect(() => {
    if (localVideoTrack?.enabled && requestedTurnOffVideo) {
      localVideoTrack.setEnabled(false);
      setHasVideo(false);
      resetRequestedTurnOffVideo(meetingName, userId);
    }
  }, [localVideoTrack, requestedTurnOffVideo, userId, meetingName]);

  const prevChannelName = usePrevious(meetingName);

  useEffect(() => {
    const track = screenTrack;
    if (prevChannelName !== meetingName) {
      track?.close();
      setScreenTrack(null);
    }
  }, [prevChannelName, meetingName, screenTrack]);

  const setVideo = useCallback(
    async (enabled: boolean) => {
      if (localVideoTrack) {
        setHasVideo(enabled);
        await localVideoTrack.setEnabled(enabled);
      }
    },
    [localVideoTrack]
  );

  const setAudio = async (enabled: boolean) => {
    if (localAudioTrack) {
      setHasAudio(enabled);
      await localAudioTrack.setEnabled(enabled);
    }
  };

  const stopScreenSharing = useCallback(async () => {
    if (containerRef.current && client) {
      setScreenTrack(null);
      await stopShareScreen(meetingName);
      if (screenTrack) {
        await client.unpublish(screenTrack);
        await screenTrack.stop();
        await screenTrack.close();
      }
      if (localVideoTrack) {
        await localVideoTrack.play(containerRef.current);
        if (hasVideoBeforeSharing !== null) {
          await setVideo(hasVideoBeforeSharing);
        }
        await client.publish(localVideoTrack);
      }
    }
  }, [
    localVideoTrack,
    client,
    hasVideoBeforeSharing,
    setVideo,
    meetingName,
    screenTrack,
  ]);

  const startScreenSharing = async () => {
    if (containerRef?.current && client) {
      const hasVideoState = hasVideo;
      setHasVideoBeforeSharing(hasVideoState);

      try {
        const screenTrack = await startLocalScreenTrack();
        await client.unpublish(localVideoTrack || undefined);
        await localVideoTrack?.stop();
        await setVideo(false);
        setScreenTrack(screenTrack || null);
        if (containerRef.current && screenTrack) {
          await client.publish(screenTrack);
          screenTrack?.play(containerRef.current);
        }
        const ninjaId = await getUserIdSharingScreen(meetingName);
        if (ninjaId != null) {
          setScreenTrack(null);
          await client.unpublish(screenTrack);
          await screenTrack?.stop();
          await screenTrack?.close();
          throw new Error('Somebody sneaked and shared screen:(');
        }
        await startShareScreen(meetingName, userId);
      } catch (error) {
        console.error(error);
        if (localVideoTrack) {
          await localVideoTrack?.play(containerRef.current);
          await setVideo(hasVideoState);
          await client.publish(localVideoTrack);
        }
      }
    }
  };

  useEffect(() => {
    if (screenTrack?.enabled && requestedStopScreenSharing) {
      stopScreenSharing();
      resetRequestedStopScreenSharing(meetingName, userId);
    }
  }, [
    screenTrack,
    requestedStopScreenSharing,
    userId,
    meetingName,
    stopScreenSharing,
  ]);

  useEffect(() => {
    if (screenTrack) {
      screenTrack.on('track-ended', stopScreenSharing);
    }
    return () => {
      screenTrack?.off('track-ended', stopScreenSharing);
    };
  }, [screenTrack, stopScreenSharing]);

  const toggleScreenSharing = async () => {
    screenTrack?.enabled ? stopScreenSharing() : startScreenSharing();
  };

  const audioEnabled = hasAudio;
  const videoEnabled = hasVideo;
  return (
    <div className="relative h-full w-full bg-gray-300" ref={containerRef}>
      <div
        className={`absolute top-2 left-2 z-20 rounded-md bg-black-soft px-1 text-xs text-white`}
      >
        {t('users:You')}
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-20 flex items-center justify-center">
        <div className="flex flex-row space-x-2 py-1 px-2">
          <VideoButton.Microphone
            enabled={audioEnabled && !localAudioTrackError}
            disabled={localAudioTrackError}
            onClick={() => setAudio(!audioEnabled)}
          />
          {!screenTrack ? (
            <VideoButton.Camera
              enabled={videoEnabled && !localVideoTrackError}
              disabled={localVideoTrackError}
              onClick={() => setVideo(!videoEnabled)}
            />
          ) : null}

          <VideoButton.ShareScreen
            enabled={!!screenTrack}
            disabled={!!sharingUserId && sharingUserId !== userId}
            onClick={toggleScreenSharing}
          />
        </div>
      </div>
      {localAudioTrackError || localVideoTrackError ? (
        <div
          className={`absolute bottom-0 top-0 left-0 right-0 z-10 flex h-full w-full items-center justify-center bg-gray-300 ${
            !localVideoTrackError ? 'bg-opacity-50' : ''
          }`}
        >
          {localVideoTrackError ? (
            <p className="px-4 text-center text-lg text-black-soft">
              {t('video:LocalVideoTrackError')}
            </p>
          ) : null}
          {localAudioTrackError ? (
            <p className="px-4 text-center text-lg text-black-soft">
              {t('video:LocalAudioTrackError')}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LocalVideoCard;
