import React from 'react';

import { Typography } from 'antd';

const { Text } = Typography;

const FormLine = ({
  label,
  children,
  style,
  extra,
}: {
  label: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  extra?: React.ReactNode;
}): JSX.Element => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '8px',
      ...style,
    }}
  >
    <Text strong>{label}:</Text>
    {children && <div style={{ marginLeft: '8px' }}>{children}</div>}
    <div style={{ flex: 1 }} />
    {extra}
  </div>
);

export default FormLine;
