import React from 'react';
import CopyIcon from './CopyIcon';

export type CopyableProps = {
  textToCopy: string;
  children: React.ReactNode;
  top?: number;
  right?: number;
};

const Copyable = ({
  textToCopy,
  children,
  top = 4,
  right = 4,
}: CopyableProps) => {
  return (
    <div className="group relative">
      {children}
      <div
        className={`absolute top-${top} right-${right} hidden group-hover:block`}
      >
        <CopyIcon textToCopy={textToCopy} />
      </div>
    </div>
  );
};

export default Copyable;
