import { database as db, fetchData } from 'services/firebase';
import { Activities, Activity, Production, Productions } from 'types/types';
import _ from 'lodash';
import { makeKey } from 'utils/utils';
import { NB_MAX_CRITERIA } from 'constants/AppConfig';
import { HARDCODED_SCREENS } from 'constants/HardCodedScreens';
import { Criterium } from 'types/ui';

export const criteriaDeps = (
  criteriaName: string,
  activities: Activities
): Activities => {
  return _.pickBy(
    activities,
    (activity) =>
      activity.grouping.mode === 'Groups' &&
      activity.grouping.settings.mode === 'algo' &&
      _.some(activity.grouping.settings.criteria, ({ source }) =>
        source.includes(criteriaName)
      )
  );
};

export const removeCriteria = async (
  sessionId: string,
  activityName: string,
  criteriaName: string,
  activities: Activities,
  force: boolean = false
) => {
  const deps = criteriaDeps(criteriaName, activities);
  if (_.size(deps)) {
    if (!force) throw new Error("Can't remove a criteria with dependencies");
    else {
      await removeCriteriaFromGroupSettingsActivities(
        sessionId,
        deps,
        criteriaName
      );
    }
  }
  const activityRef = `sessionsNextTemplates/${sessionId}/activities/${activityName}`;
  const criteriaRef = `${activityRef}/productions/${criteriaName}`;
  const criteriaScreenBindings =
    activities[activityName]?.screens['create']?.dataBindings;
  if (criteriaScreenBindings) {
    const dataBindingsRef = `${activityRef}/screens/create/dataBindings`;
    const bindingsToRemove = _.pickBy(criteriaScreenBindings, (path) =>
      path.includes(criteriaName)
    );
    await Promise.all(
      Object.keys(bindingsToRemove).map(async (bindingName) => {
        await db.ref(`${dataBindingsRef}/${bindingName}`).remove();
        await db
          .ref(
            `${activityRef}/screens/create/content/template/editableContent/${bindingName}Instruction`
          )
          .remove();
      })
    );
  }
  return db.ref(criteriaRef).remove();
};

export const editCriteria = async (
  sessionId: string,
  activityName: string,
  criteriaName: string,
  criteriaData: {
    options: string[];
    description: string;
  }
) => {
  const activityRef = `sessionsNextTemplates/${sessionId}/activities/${activityName}`;
  const criteriaRef = `${activityRef}/productions/${criteriaName}`;
  return db.ref(criteriaRef).update(criteriaData);
};

export const getCriterias = (activity: Activity): Productions =>
  _.pickBy(activity.productions || {}, (_value, key) =>
    key.startsWith('crit_')
  );

export const addCriteria = async (
  sessionId: string,
  activity: Activity,
  criteriaData: {
    options: string[];
    description: string;
  }
) => {
  const activityRef = `sessionsNextTemplates/${sessionId}/activities/${activity.name}`;
  const criteriaName = `crit_${makeKey(criteriaData.description)}`;
  const criteriaRef = `${activityRef}/productions/${criteriaName}`;
  const criterias = getCriterias(activity);
  if (_.size(criterias) >= NB_MAX_CRITERIA) {
    throw Error('Already reached criteria limit !');
  }
  const production: Production = {
    name: criteriaName,
    type: 'enum',
    mode: 'ByUser',
    ...criteriaData,
  };

  await db.ref(criteriaRef).set(production);

  const criteriaScreenBindings = activity.screens['create']?.dataBindings;
  if (criteriaScreenBindings) {
    const availableBindings = HARDCODED_SCREENS.CreatePost.bindings.filter(
      (bindingName) =>
        bindingName.startsWith('criteria') &&
        !criteriaScreenBindings[bindingName]
    );
    if (availableBindings.length === 0) {
      throw new Error('Configuration got rotten at some point!');
    }
    const dataBindingsRef = `${activityRef}/screens/create/dataBindings`;
    await db
      .ref(`${dataBindingsRef}/${availableBindings[0]}`)
      .set(`productions.${activity.name}.${criteriaName}.`);
    await db
      .ref(
        `${activityRef}/screens/create/content/template/editableContent/${availableBindings[0]}Instruction`
      )
      .set(criteriaData.description);
  }
};

export const removeCriteriaFromGroupSettingsActivity = async (
  sessionId: string,
  activity: Activity,
  criteriaName: string
): Promise<void> => {
  if (
    activity.grouping.mode === 'Groups' &&
    activity.grouping.settings.mode === 'algo'
  ) {
    const criteria = _.pickBy(
      activity.grouping.settings.criteria,
      ({ source }) => !source.includes(criteriaName)
    );

    await db
      .ref(
        `sessionsNextTemplates/${sessionId}/activities/${activity.name}/grouping/settings/criteria/`
      )
      .set(criteria);
  }
};

export const removeCriteriaFromGroupSettingsActivities = async (
  sessionId: string,
  activities: Activities,
  criteriaName: string
): Promise<void> => {
  await Promise.all(
    Object.values(activities).map((activity) =>
      removeCriteriaFromGroupSettingsActivity(sessionId, activity, criteriaName)
    )
  );
};

export const addOrEditCriteriaActivity = async (
  sessionId: string,
  activityName: string,
  criterium: Criterium,
  criteria: Criterium[]
) => {
  const updates: Record<string, any> = {};
  updates[
    `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${criterium.name}`
  ] = {
    name: criterium.name,
    type: 'enum',
    mode: 'ByUser',
    description: criterium.description,
    options: criterium.options || [],
  };

  const cleanUpdates = await buildCleanCriteriaOperations(
    sessionId,
    activityName,
    criteria
  );

  return db.ref().update(_.merge(updates, cleanUpdates));
};

export const removeCriteriaFromActivity = async (
  sessionId: string,
  activityName: string,
  criteriaName: string,
  criteria: Criterium[]
) => {
  const updates: Record<string, any> = {};
  updates[
    `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${criteriaName}`
  ] = null;

  const cleanUpdates = await buildCleanCriteriaOperations(
    sessionId,
    activityName,
    criteria
  );

  return db.ref().update(_.merge(updates, cleanUpdates));
};

export const buildCleanCriteriaOperations = async (
  sessionId: string,
  activityName: string,
  criteria: Criterium[]
): Promise<Record<string, any>> => {
  const updates: Record<string, any> = {};
  const activities: Activities | null = await fetchData<Activities>(
    `sessionsNextTemplates/${sessionId}/activities`
  );

  let criteriaNames = criteria.map((criterium) => criterium.name);

  // Clean group settings for this criteria
  Object.values(activities || {}).forEach((activity) => {
    const activityCriteria =
      activity?.grouping.mode === 'Groups' &&
      activity?.grouping.settings.mode === 'algo'
        ? activity?.grouping.settings?.criteria
        : {};
    Object.keys(activityCriteria || {}).forEach((criteriaName) => {
      if (!criteriaNames.includes(criteriaName)) {
        updates[
          `sessionsNextTemplates/${sessionId}/activities/${activity.name}/grouping/settings/criteria/${criteriaName}`
        ] = null;
      }
    });
  });

  //Clean instructions into editable content
  const editableContent =
    activities?.[activityName].screens['create']?.content.template
      .editableContent;
  const newEditableContent: Record<string, any> = Object.entries(
    editableContent || {}
  ).reduce<Record<string, string>>((prev, [key, content]) => {
    if (!key.startsWith('criteria')) {
      prev[key] = content;
    }
    return prev;
  }, {});

  _.sortBy(Object.values(criteria || {}), 'name').forEach(
    (criterium, index) => {
      if (criterium.instruction) {
        newEditableContent[`criteria${index}Instruction`] =
          criterium.instruction;
      }
    }
  );

  updates[
    `sessionsNextTemplates/${sessionId}/activities/${activityName}/screens/create/content/template/editableContent`
  ] = newEditableContent;

  // Clean datapath bindings
  const postBindings =
    activities?.[activityName].screens['create'].dataBindings;

  const newBindings = Object.entries(postBindings || {}).reduce<
    Record<string, string>
  >((prev, [key, bond]) => {
    if (!key.startsWith('criteria')) {
      prev[key] = bond;
    }
    return prev;
  }, {});

  _.sortBy(criteriaNames).forEach((criteriaName, index) => {
    if (index < NB_MAX_CRITERIA) {
      newBindings[
        `criteria${index}`
      ] = `productions.${activityName}.${criteriaName}.`;
    }
  });

  updates[
    `sessionsNextTemplates/${sessionId}/activities/${activityName}/screens/create/dataBindings`
  ] = newBindings;

  return updates;
};

export const getCriteriaDeps = async (
  criteriaName: string,
  sessionId: string
): Promise<Activities> => {
  const activities: Activities | null = await fetchData<Activities>(
    `sessionsNextTemplates/${sessionId}/activities`
  );
  return _.pickBy(
    activities,
    (activity) =>
      activity.grouping.mode === 'Groups' &&
      activity.grouping.settings.mode === 'algo' &&
      _.some(activity.grouping.settings.criteria, ({ source }) =>
        source.includes(criteriaName)
      )
  );
};
