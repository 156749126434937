import React, { Fragment } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Activity } from 'types/types';
import {
  insertActivityNote,
  removeActivityNote,
} from 'model/activitiesManagement';

import Tag from 'components/Tag';
import Editor from 'frameworks/plate/RevisionedEditor';
import MDReactComponent from 'componentsOld/MDReactComponent';
import FormattedText from 'components/FormattedText';

import {
  ThumbUp,
  ThumbDown,
  EmojiHappy,
  EmojiSad,
  LightBulb,
  Pencil,
  UserGroup,
  User,
} from 'assets/icons';
import ActivityNote from './ActivityNote';

type Criterium = {
  description: string;
  options: string[];
};

type Question = {
  index: number;
  description: string;
  type: 'number' | 'boolean' | 'string';
};

export type ActivityDetailsProps = {
  sessionId: string | undefined;
  activity: Activity;
  index: number;
  readOnly?: boolean;
  activitiesNames: Record<string, string>;
  criteriaNames: Record<string, string>;
  showsAnnotation?: boolean;
};

const ActivityDetails = ({
  sessionId,
  activity,
  index,
  readOnly = true,
  activitiesNames,
  criteriaNames,
  showsAnnotation = true,
}: ActivityDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const screens = Object.values(activity?.screens || {});
  const grouping = activity.grouping;
  const screenName = (screens.length > 0 && screens[0].name) || '';
  const bindings = (screens.length > 0 && screens[0].dataBindings) || {};
  const params =
    (screens.length > 0 && screens[0].content?.template?.params) || {};

  const description =
    (screens.length > 0 &&
      screens[0].content?.template?.editableContent?.description) ||
    null;
  let instructions =
    (screens.length > 0 &&
      screens[0].content?.template?.editableContent?.instructions) ||
    null;
  const criteriaList: Criterium[] = [];
  const questionsList: Question[] = [];
  const paramList: string[] = [];

  let docName = null;
  let docMultiplicity = null;
  let docId = null;

  const insertNote = (note: string) => {
    sessionId && insertActivityNote(sessionId, activity, note);
  };

  const removeNote = () => {
    sessionId && removeActivityNote(sessionId, activity);
  };

  Object.entries(bindings).forEach(async ([type, bond]) => {
    const path = _.toPath(bond).slice(-2, -1);
    const activityInfos = _.get(activity, `productions.${path}`);
    if (['criteria0', 'criteria1', 'criteria2'].includes(type)) {
      criteriaList.push({
        description: activityInfos.description,
        options: activityInfos.options,
      });
    } else if (['docs'].includes(type)) {
      docId = activityInfos.template.docId;
      docName = activityInfos.docName;
      docMultiplicity = activityInfos.multiplicity || 'One';
    } else if (
      [
        'valuable',
        'questionAAnswer',
        'questionBAnswer',
        'questionCAnswer',
        'future',
      ].includes(type)
    ) {
      questionsList.push({
        index: activityInfos.index,
        description: activityInfos.description,
        type: activityInfos.type,
      });
    }
  });

  if (docMultiplicity) {
    paramList.push(
      docMultiplicity === 'One'
        ? t('sessions:docPerGroup')
        : t('sessions:docPerUser')
    );
  }

  switch (screenName) {
    case 'create':
      paramList.push(
        `${t('sessions:participantPost')} ${
          params.allowedNotToPost ? t('common:optional') : t('common:mandatory')
        }`
      );
      paramList.push(
        `${t('sessions:criteriaResponse')} ${
          params.criteriaRequired ? t('common:mandatory') : t('common:optional')
        }`
      );
      break;
    case 'like':
      paramList.push(
        params.noLimit ? t('sessions:noLimitVote') : t('sessions:limitTo3Votes')
      );
      break;
  }

  if (grouping?.mode === 'Groups') {
    const settings = grouping.settings;

    if (settings.mode === 'algo') {
      paramList.push(t('sessions:participantInNewGroup'));
      paramList.push(
        t('sessions:groupLength', {
          min: settings.minPeopleByGroup,
          max: settings.maxPeopleByGroup,
        })
      );

      Object.values(settings.criteria || {}).forEach((criterium) => {
        let description =
          criterium.mode === 'ONE_OF_EACH'
            ? t('sessions:criteriaGroupOneOfEach')
            : criterium.mode === 'EXCLUDE_EACH_OTHERS'
            ? t('sessions:criteriaGroupExcludeEachOthers')
            : criterium.mode === 'HARD_SPLIT'
            ? t('sessions:criteriaGroupHardSplit')
            : criterium.mode === 'JOIN_TOGETHER'
            ? t('sessions:criteriaGroupJoinTogether')
            : criterium.mode === 'ONE_IN_EACH'
            ? t('sessions:criteriaGroupOnInEachGroup')
            : t('sessions:criteriaGroupDefault');
        paramList.push(
          `${description} **${criteriaNames[criterium.name] || 'NA'}**${
            criterium.mode === 'ONE_IN_EACH'
              ? t('sessions:criteriaGroupOnInEachGroupOption', {
                  option: criterium.optionalArgument,
                })
              : ''
          }`
        );
      });
    } else if (settings.mode === 'same_as') {
      paramList.push(
        t('sessions:sameGroupAs', {
          step: activitiesNames[settings?.activity] || 'NA',
        })
      );
    } else if (settings.mode === 'custom_skema') {
      paramList.push(t('sessions:participantInNewGroup'));
    }

    paramList.push(
      settings?.matchedUsers === 'active'
        ? t('sessions:matchOnlyActiveParticipant')
        : settings?.matchedUsers === 'activity'
        ? t('sessions:matchOnlyActiveParticipant', {
            activity: settings?.sourceActivity,
          })
        : t('sessions:matchOnlyAllParticipant')
    );
  } else {
    paramList.push(t('sessions:individualActivity'));
  }

  return (
    <div
      key={activity.name}
      className="bi-avoid relative border-b border-surfaces-divider bg-white py-4 pb-16"
    >
      <div className="flex">
        <div className="flex w-full flex-col justify-center">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="m-1 mr-4 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary-soft text-xl font-semibold text-primary">
                {index + 1}
              </div>
              {activity.grouping?.mode !== 'All' ? (
                <UserGroup className="h-8 w-8 stroke-2" />
              ) : (
                <User className="h-8 w-8 stroke-2" />
              )}
              <label
                htmlFor={activity.name}
                className="ml-2 text-lg font-semibold"
              >
                {activity.humanName || activity.name}
              </label>
            </div>
            <p className="p-0 text-sm">
              {activity.duration
                ? t('common:Xminutes', {
                    duration: activity.duration,
                  })
                : t('common:NoTimeLimit')}
            </p>
          </div>
          {showsAnnotation ? (
            <div className="relative mt-2 xl:absolute xl:top-0 xl:bottom-0 xl:-right-56 xl:mt-0 xl:w-52">
              <div className="sticky top-0 flex justify-start">
                <ActivityNote
                  readOnly={readOnly}
                  insertNote={insertNote}
                  removeNote={removeNote}
                  defaultNote={activity.facilitationNote}
                />
              </div>
            </div>
          ) : null}

          <div className="mt-4 ml-2">
            {paramList.length > 0 ? (
              <ul className="my-2 list-inside list-disc rounded-md bg-gray-100 bg-opacity-50 p-4">
                <h3 className="font-semibold capitalize">
                  {t('common:settings')}
                </h3>
                {paramList.map((param, index) => (
                  <FormattedText key={index} as="li">
                    {param}
                  </FormattedText>
                ))}
              </ul>
            ) : null}

            {description ? (
              <>
                <h3 className="mt-4 font-semibold">
                  {t('sessions:postExampleLabel')}
                </h3>
                <p className="mt-2 whitespace-pre-line">{description}</p>
              </>
            ) : null}

            {criteriaList.length > 0 ? (
              <>
                <h3 className="mt-4 font-semibold">
                  {t('sessions:diversificationCriteria')}
                </h3>
                <div className="mt-2 grid grid-cols-4 gap-x-2 gap-y-8">
                  {criteriaList.map((criterium, index) => {
                    return (
                      <Fragment key={index}>
                        <p className="col-span-1">{criterium.description}</p>
                        <div className="col-span-3 ml-2 flex flex-row flex-wrap items-start space-x-2 border-l border-primary pl-2">
                          {criterium.options?.map((option, index) => {
                            return (
                              <Tag
                                key={index}
                                className="mt-2"
                                text={option}
                                color="primary"
                              />
                            );
                          })}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </>
            ) : null}

            {activity.grouping.mode !== 'All' &&
            activity.groupingModalContent ? (
              <div className="bi-avoid mt-4">
                <h2 className="font-semibold text-black">
                  {t('sessions:groupAndrole')}
                </h2>
                <div className="ml-2 border-l border-primary pl-2">
                  <MDReactComponent
                    text={activity.groupingModalContent}
                    editable={false}
                  />
                </div>
              </div>
            ) : null}

            {instructions ? (
              <div className="bi-avoid mt-4">
                <h2 className="font-semibold text-black">
                  {t('editor:goals')}
                </h2>
                <div className="ml-2 border-l border-primary pl-2">
                  <MDReactComponent text={instructions} editable={false} />
                </div>
              </div>
            ) : null}
            <div className="bi-avoid mt-4">
              {docName ? (
                <h3 className="mb-2 font-semibold">{docName}</h3>
              ) : null}
              {docId ? (
                <div className="ml-2 border-l border-primary pl-2">
                  <Editor
                    showBorder={false}
                    readOnly={true}
                    forceDisplayInstructions={true}
                    metaId={docId}
                    forceConnect
                  />
                </div>
              ) : null}
            </div>

            {questionsList.length > 0 ? (
              <>
                <h3 className="font-semibold capitalize">
                  {t('common:questions')}
                </h3>
                <div className="mt-2 space-y-3">
                  {questionsList
                    .sort((a, b) => a.index - b.index)
                    .map((question, index) => {
                      return (
                        <div key={index} className="flex items-center">
                          <div className="flex shrink-0 items-center">
                            {question.type === 'boolean' ? (
                              <>
                                <ThumbUp className="h-4 w-4 stroke-2" />
                                <ThumbDown className="h-4 w-4 stroke-2" />
                              </>
                            ) : question.type === 'number' ? (
                              <>
                                <EmojiSad className="h-4 w-4 stroke-2" />
                                <EmojiHappy className="h-4 w-4 stroke-2" />
                              </>
                            ) : (
                              <>
                                <Pencil className="h-4 w-4 stroke-2" />
                                <LightBulb className="h-4 w-4 stroke-2" />
                              </>
                            )}
                          </div>

                          <p className="ml-2">{question.description}</p>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetails;
