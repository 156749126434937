import { database } from 'services/firebase';

export const addKindOfPublic = (kind: string) => {
  const ref = database.ref(`kindsOfPublic`).push();
  return ref.set(kind);
};

export const editKindOfPublic = (kindId: string, kind: string) => {
  const ref = database.ref(`kindsOfPublic/${kindId}`);
  return ref.set(kind);
};

export const deleteKindOfPublic = (kindId: string) => {
  const ref = database.ref(`kindsOfPublic/${kindId}`);
  return ref.remove();
};
