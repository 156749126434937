import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bubble as Pattern } from 'assets/patterns/';

const HomeRibbon = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="relative h-48 w-full shrink-0 overflow-hidden bg-surfaces-soft">
      <Pattern className="absolute -right-7 top-9 h-96 w-96 fill-current text-white" />
      <div className="relative mx-auto max-w-5xl px-8">
        <h1 className="mt-10 text-2xl font-semibold text-primary">
          {'WE ARE PEERS'}
        </h1>
        <h2 className="max-w-2xl text-4xl font-semibold">
          {t('misc:WelcomeTitle')}
        </h2>
      </div>
    </div>
  );
};

export default HomeRibbon;
