import {
  getRangeBefore,
  getRangeFromBlockStart,
  getText,
  isCollapsed,
  isUrl,
  isImageUrl,
  insertImage,
  getPluginType,
  insertNodes,
  PlateEditor,
} from '@udecode/plate';
import { ELEMENT_EMBED_LINK } from '.';

export const insertLink = (editor: PlateEditor, url: string) => {
  if (isImageUrl(url)) {
    insertImage(editor, url);
  } else {
    insertNodes(editor, {
      type: getPluginType(editor, ELEMENT_EMBED_LINK),
      url,
      children: [{ text: '' }],
    });
  }
};

/**
 * Insert space after a url to wrap a link.
 * Lookup from the block start to the cursor to check if there is an url.
 * If not found, lookup before the cursor for a space character to check the url.
 */
export const withLink = (editor: PlateEditor) => {
  const { insertData, insertText } = editor;

  editor.insertText = (text) => {
    if (text === ' ' && isCollapsed(editor.selection)) {
      const selection = editor.selection;

      const rangeFromBlockStart = getRangeFromBlockStart(editor);
      const textFromBlockStart = getText(editor, rangeFromBlockStart);

      if (rangeFromBlockStart && isUrl(textFromBlockStart)) {
        console.log(textFromBlockStart);
        console.log(isUrl(textFromBlockStart));
        return insertLink(editor, textFromBlockStart);
      }

      const beforeWordRange = getRangeBefore(editor, selection!, {
        matchString: ' ',
        skipInvalid: true,
        afterMatch: true,
        multiPaths: true,
      } as any);

      if (beforeWordRange) {
        const beforeWordText = getText(editor, beforeWordRange);

        if (isUrl(beforeWordText)) {
          return insertLink(editor, textFromBlockStart);
        }
      }
    }

    insertText(text);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      insertLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
