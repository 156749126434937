import { createPluginFactory } from '@udecode/plate-core';
import { createInstructionsBlockElement } from './InstructionsBlockElement';

export type InstructionsPuglinOptions = {
  hidden?: boolean;
  editableInstructionBlocks?: boolean;
};

export const ELEMENT_INSTRUCTIONS = 'instructions_block';

export const createInstructionsPlugin =
  createPluginFactory<InstructionsPuglinOptions>({
    key: ELEMENT_INSTRUCTIONS,
    isElement: true,
    component: createInstructionsBlockElement(),
  });
