import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase';

import { DataProcessing } from 'assets/undraw';
import Link from 'components/Link';
import NumberMetric from 'components/NumericMetric';
import SmileyRating from 'components/SmileyRating';
import Spinner from 'components/Spinner';
import Table, { Column, Data } from 'components/Table';
import { TFunctionResult } from 'i18next';
import _ from 'lodash';
import moment from 'moment';

import { DashboardData } from 'types/types';
import Listbox, { Item } from 'components/Listbox';

export type CommunityMetricsProps = {
  communityId: string;
};

const LoadingScreen = () => (
  <div className="flex w-full justify-center">
    <div className="mt-12 flex flex-col items-center">
      <h2 className="flex flex-row items-center text-2xl font-semibold">
        Processing Data
        <Spinner className="ml-4 h-8 w-8 text-primary" />
      </h2>
      <DataProcessing className="mx-auto mt-10 w-72 fill-current text-primary md:w-96" />
    </div>
  </div>
);

const Dashboard = ({
  communities,
  nbSessions,
  nbFacilitators,
  nbParticipants,
  nbParticipants2MoreSessions,
  statisfaction,
  nbSatisfactionAnswered,
  wantsMore,
  nbDocs,
}: DashboardData): JSX.Element => {
  const { t } = useTranslation();

  type CommunityRow = {
    key: string;
    name: string;
    id: string;
    nbParticipants: number;
    nbSessions: number;
    meanSatisfaction: TFunctionResult | number;
    wantsMore: TFunctionResult | number;
  };

  const columns: Column<CommunityRow>[] = useMemo(
    () => [
      {
        title: t('communities:CommunityName'),
        dataIndex: 'name',
        render: (_dataIndex, community) => (
          <Link
            to={{ pathname: `/community/${community.id}` }}
            text={community.name}
          />
        ),
      },
      {
        title: t('sessions:NbParticipants'),
        dataIndex: 'nbParticipants',
      },
      {
        title: t('sessions:NbSessions'),
        dataIndex: 'nbSessions',
      },
      {
        title: t('communities:meanSatisfactionSimple'),
        dataIndex: 'meanSatisfaction',
      },
      {
        title: t('communities:wantsRenewExperience'),
        dataIndex: 'wantsMore',
      },
    ],
    [t]
  );

  const data: Data<CommunityRow> = useMemo(
    () =>
      _.sortBy(
        communities.map((community, index) => ({
          key: community.id,
          id: community.id,
          name: community.name,
          nbParticipants: community.nbParticipants,
          nbSessions: community.nbSessions,
          meanSatisfaction:
            community.statisfaction !== null &&
            community.statisfaction !== undefined
              ? _.round(community.statisfaction, 1)
              : t('misc:NA'),
          wantsMore:
            community.wantsMore !== null &&
            community.statisfaction !== undefined
              ? `${_.round(community.wantsMore * 100, 2)}%`
              : t('misc:NA'),
        })),
        (el) => -el.nbParticipants
      ),
    [communities, t]
  );

  return (
    <div className="mt-8 space-y-12">
      <div className="flex flex-row flex-wrap items-start gap-8">
        <NumberMetric
          label={t('common:createdSessions', { count: nbSessions })}
          value={nbSessions}
        />
        <NumberMetric
          label={t('common:createdCommunities', { count: communities.length })}
          value={communities.length}
        />
        <NumberMetric
          label={t('common:participants1', { count: nbParticipants })}
          value={nbParticipants}
        />
        <NumberMetric
          label={t('common:facilitatorsANdOrganizers', {
            count: nbFacilitators,
          })}
          value={nbFacilitators}
        />
        <NumberMetric
          percentage={!!wantsMore}
          label={t('common:wantsMore')}
          value={
            wantsMore === null || wantsMore === undefined
              ? t('misc:NA')
              : _.round(100 * wantsMore)
          }
        />
        <NumberMetric
          label={t('common:productionNumber', { count: nbDocs })}
          value={nbDocs}
        />
      </div>

      <div>
        <h2 className="mb-2 text-2xl font-semibold">
          {t('communities:meanSatisfaction', {
            count: nbSatisfactionAnswered,
          })}
        </h2>
        <div className="w-72">
          <SmileyRating value={statisfaction} showValue readOnly />
        </div>
      </div>
      <div>
        <h2 className="mb-2 text-2xl font-semibold">
          {t('communities:sessionData')}
        </h2>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

export const Metrics = (): JSX.Element => {
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const { t } = useTranslation();

  useFirebaseConnect('computations/globalMetrics');
  useFirebaseConnect('computations/annualMetrics');

  const data: DashboardData | null = useSelector(
    (state: any) => state.firebase.data.computations?.globalMetrics
  );

  const annualData: Record<string, DashboardData> | null = useSelector(
    (state: any) => state.firebase.data.computations?.annualMetrics
  );

  const displayedData = (selectedYear && annualData?.[selectedYear]) || data;

  const sinceDate = new Date(0);
  sinceDate.setFullYear(2018);
  sinceDate.setMonth(0);

  const items: Item<string | null>[] = Object.keys(annualData || {}).map(
    (year) => ({
      value: year,
      description: year,
    })
  );

  items.push({
    value: null,
    description: t('common:allPeriod'),
  });

  return !isLoaded(data) || isEmpty(data) ? (
    <LoadingScreen />
  ) : (
    <>
      <div className="flex flex-row items-center">
        <h2 className="text-2xl font-semibold flex-grow">
          {selectedYear
            ? t('common:OverPeriod', { year: selectedYear })
            : t('common:since', {
                formattedDate: moment(sinceDate).format('MMMM YYYY'),
              })}
        </h2>

        <Listbox
          className="w-32"
          items={items}
          defaultValue={selectedYear}
          setValue={(value) => setSelectedYear(value)}
        />
      </div>
      <Dashboard {...displayedData!} />
    </>
  );
};

export default Metrics;
