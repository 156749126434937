import React from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from 'services/firebase';

import { createSessionLite } from 'model/sessions';

import Layout from './Layout';

import ExtendedInput from 'frameworks/formik/ExtendedInput';
import Button from 'components/Button';

export type FastOnboardScreenProps = {};

const FastOnboardScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const SubmitButton = (): JSX.Element => {
    const { isValid } = useFormikContext<FormData>();
    return (
      <Button
        disabled={!isValid}
        text={t('login:createMySession')}
        type="submit"
      />
    );
  };

  return (
    <Layout
      title={t('login:createSessionTitle')}
      subtitleText={t('login:createSessionDescription')}
    >
      <div className="mt-6 md:mt-10">
        <Formik
          validateOnMount
          initialValues={{ title: '', theme: '', peerProfile: '' }}
          validationSchema={Yup.object({
            title: Yup.string().required(t('form:fieldRequired')),
            theme: Yup.string().required(t('form:fieldRequired')),
            peerProfile: Yup.string().required(t('form:fieldRequired')),
          })}
          onSubmit={(values) => {
            try {
              if (auth.currentUser?.uid) {
                createSessionLite(
                  auth.currentUser.uid,
                  values.title,
                  values.theme,
                  values.peerProfile,
                  (accessCode) => {
                    if (accessCode) {
                      history.push(`/${accessCode}`);
                    }
                  }
                );
              } else {
                history.push({
                  pathname: '/sign-up',
                  state: {
                    dataSession: values,
                  },
                });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <Form className="space-y-4">
            <ExtendedInput
              name="title"
              inputType="text"
              label={t('login:createSessionTitleLabel')}
            />
            <ExtendedInput
              name="theme"
              inputType="text"
              label={t('login:createSessionThemeLabel')}
            />
            <ExtendedInput
              name="peerProfile"
              inputType="text"
              label={t('login:createSessionPeerProfileLabel')}
            />
            <div className="flex justify-end pt-4">
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

export default FastOnboardScreen;
