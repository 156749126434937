import React from 'react';
import { Tag, Space, Input, Button, Tabs } from 'antd';
import { Communities } from 'types/types';
import CommunitiesSelector from 'componentsOld/ComunitiesSelector';
import _ from 'lodash';

const { Search } = Input;
const { TabPane } = Tabs;

export type SearchRequest = {
  query: string;
  selectedTag: string | undefined;
  communitiesIds?: Record<string, true>;
  page?: number;
};

const SearchForm = ({
  search,
  availableTags,
  communities,
  onSearchChange,
  onResetSearch,
  title,
  children,
}: {
  search: SearchRequest;
  onSearchChange: (searchRequest: SearchRequest) => void;
  onResetSearch: () => void;
  availableTags: Record<string, number>;
  communities: Communities;
  title?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const onQueryChange = (query: string) => {
    onSearchChange({
      ...search,
      query,
    });
  };

  const onCommunitiesChange = (communitiesIds: Record<string, true>) => {
    onSearchChange({
      ...search,
      communitiesIds,
    });
    return Promise.resolve();
  };

  const onSelectedTagsChange = (selected: string) => {
    onSearchChange({
      ...search,
      selectedTag: selected,
      page: 0,
    });
  };

  return (
    <div className="rounded-xl border border-surfaces-divider bg-white p-8">
      {title && <h3 className="mb-4 flex text-xl font-semibold">{title}</h3>}
      <div style={{ marginBottom: '24px' }}>
        <Tabs
          activeKey={search.selectedTag}
          style={{ marginBottom: '0px' }}
          onChange={onSelectedTagsChange}
        >
          {Object.entries(availableTags).map(([type, count]) => (
            <TabPane
              tab={
                <Space size="small">
                  {type}
                  <Tag>{count}</Tag>
                </Space>
              }
              key={type}
            />
          ))}
        </Tabs>
        <div style={{ width: '100%', display: 'flex' }}>
          <Search
            value={search.query}
            style={{ fontSize: '16px', marginRight: '8px' }}
            enterButton
            onChange={(event) => onQueryChange(event.target.value || '')}
          />
          <Button onClick={onResetSearch}>Reset</Button>
        </div>
      </div>
      {/* <HotTags
          selectedTags={search.tagsSelection}
          availableTags={availableTags}
          onChange={onSelectedTagsChange}
        /> */}

      {_.size(communities) > 1 && (
        <CommunitiesSelector
          selected={search.communitiesIds || {}}
          communities={communities}
          onChange={onCommunitiesChange}
        />
      )}

      {children}
    </div>
  );
};

export default SearchForm;
