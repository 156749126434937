import React from 'react';
import Pagination from 'components/Pagination';
import { connectPagination } from 'react-instantsearch-dom';

const PaginationComponent = ({ currentRefinement, nbPages, refine }: any) => (
  <Pagination
    currentPage={currentRefinement - 1}
    nbPages={nbPages}
    onPageChange={(newPage) => refine(newPage + 1)}
  />
);

export default connectPagination(PaginationComponent);
