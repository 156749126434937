import { useEffect, useRef, useState } from 'react';
import { SearchState } from 'react-instantsearch-core';
import qs from 'qs';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

const DEBOUNCE_TIME = 400;

export const useRefresh = () => {
  const [refresh, setRefresh] = useState<boolean>(false);

  const triggerRefresh = () => {
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    triggerRefresh();
  }, []);

  return refresh;
};

const createURL = (state: SearchState) => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState: SearchState) =>
  searchState ? createURL(searchState) : '';

const urlToSearchState = ({ search }: { search: string }) =>
  qs.parse(search.slice(1));

export const useUrlSync = (): [
  SearchState,
  (sate: SearchState) => void,
  (state: SearchState) => string
] => {
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [searchState, setSearchState] = useState<SearchState>(
    urlToSearchState(location)
  );
  const debouncedSetStateRef = useRef<NodeJS.Timeout | null>(null);

  function onSearchStateChange(updatedSearchState: SearchState) {
    if (debouncedSetStateRef.current) {
      clearTimeout(debouncedSetStateRef.current);
    }

    debouncedSetStateRef.current = setTimeout(() => {
      history.push(`${url}${searchStateToUrl(updatedSearchState)}`);
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return [searchState, onSearchStateChange, createURL];
};
