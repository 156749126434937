import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type ExpandableClampContainerProps = {
  children: JSX.Element;
  clamp: string;
};

const ExpandableClampContainer = ({
  children,
  clamp,
}: ExpandableClampContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const [shouldClamp, setShouldClamp] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);

  const isTruncatedRef = useCallback((node) => {
    let truncated = false;
    if (node !== null) {
      truncated = node.scrollHeight > node.clientHeight;
    }
    setIsTruncated(truncated);
  }, []);

  return (
    <div>
      {React.cloneElement(children, {
        ref: isTruncatedRef,
        className: `${children.props.className} ${shouldClamp ? clamp : ''}`,
      })}
      {isTruncated && shouldClamp ? (
        <button
          className="text-sm font-medium text-black underline"
          onClick={() => setShouldClamp(false)}
        >
          {t('common:seeMore')}
        </button>
      ) : null}
    </div>
  );
};

export default ExpandableClampContainer;
