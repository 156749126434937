import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Editor from 'frameworks/plate/RevisionedEditor';
import FakeEditor from 'frameworks/plate/FakeEditor';
import Disclosure from 'components/Disclosure';

import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';
import { WithId } from 'types/typesUtils';
import { DocMeta, FakeDoc, FakeDocs, User } from 'types/types';
import { ChevronDoubleUp } from 'assets/icons';
import Button from 'components/Button';
const { Text } = Typography;

export type CardEditorProps = {
  user: WithId<User>;
  metaId: string;
  collapsable?: boolean;
  noTitle?: boolean;
  editable?: boolean;
  docName: string;
  setDocName: (val: string) => void;
  isOpen?: boolean;
  setOpen?: (status: boolean) => void;
};

const CardEditor = ({
  user,
  metaId,
  collapsable,
  noTitle,
  editable,
  docName,
  setDocName,
  isOpen = false,
  setOpen,
}: CardEditorProps) => {
  const path = `/contentDocsMeta/${metaId}`;
  useFirebaseConnect([
    {
      path,
    },
  ]);

  const meta = useSelector(
    ({ firebase: { data } }: any) => data.contentDocsMeta?.[metaId]
  );

  const name = meta?.name || 'Document';
  const title = noTitle ? undefined : editable ? (
    <Text
      className="text-2xl font-semibold uppercase text-black"
      editable={editable ? { onChange: setDocName } : undefined}
    >
      {docName || name}
    </Text>
  ) : (
    <h3 className="text-2xl font-semibold uppercase text-black">{name}</h3>
  );
  return (
    <Disclosure
      defaultOpen={isOpen}
      status={isOpen}
      onStatusChange={setOpen}
      collapsable={collapsable}
      title={title}
      collapseOnTitle={!editable}
      type="notion"
      stickyPositionClassName={collapsable ? 'top-12' : 'top-0'}
    >
      {meta ? (
        <Editor
          metaId={meta.id}
          user={user}
          editableInstructionBlocks={editable}
          readOnly={false}
          toolbarSticky={false}
          toolbarOffsetClassName={collapsable ? 'top-20' : 'top-8'}
        />
      ) : null}
    </Disclosure>
  );
};

export type FakeCardEditorProps = {
  user: WithId<User>;
  doc: FakeDoc;
  collapsable?: boolean;
  noTitle?: boolean;
  metaId: string;
  isOpen?: boolean;
  setOpen?: (status: boolean) => void;
};

const FakeCardEditor = ({
  user,
  doc,
  collapsable,
  noTitle,
  metaId,
  isOpen = false,
  setOpen,
}: FakeCardEditorProps) => {
  const title = noTitle ? undefined : (
    <h3 className="text-2xl font-semibold uppercase text-black">{doc.name}</h3>
  );
  return (
    <Disclosure
      defaultOpen={false}
      status={isOpen}
      onStatusChange={setOpen}
      collapsable={collapsable}
      title={title}
      type="notion"
      stickyPositionClassName={collapsable ? 'top-12' : 'top-0'}
    >
      <FakeEditor
        initialValue={doc.content}
        // user={user}
        editableInstructionBlocks={false}
        readOnly={false}
        metaId={metaId}
        toolbarSticky={false}
        toolbarOffsetClassName={collapsable ? 'top-20' : 'top-8'}
      />
    </Disclosure>
  );
};

export type ContentCardsEditorProps = {
  docs: Record<string, true> | FakeDocs;
  user: WithId<User>;
  noTitle?: boolean;
  editable?: boolean;
  docName: string;
  setDocName: (val: string) => void;
};

const ContentCardsEditor = ({
  docs,
  user,
  noTitle,
  editable,
  docName,
  setDocName,
}: ContentCardsEditorProps) => {
  const { t } = useTranslation();
  const firebase = useFirebase();

  const [filteredDocs, setFilterDocs] = useState<
    (DocMeta & { fake?: boolean })[]
  >([]);

  const [openStatuses, setOpenStatuses] = useState<boolean[]>([]);

  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (docs) {
        const docIds = Object.entries(docs);
        const docsMeta = await Promise.all(
          docIds.map(async ([id, content]) =>
            content === true
              ? (
                  await firebase.ref(`/contentDocsMeta/${id}`).once('value')
                ).val()
              : content
          )
        );
        if (!cancelled) {
          const newFilteredDocs = docsMeta.filter((meta) => !!meta);
          setFilterDocs(newFilteredDocs);
          setOpenStatuses(newFilteredDocs?.map(() => false) || []);
        }
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [firebase, docs]);

  return (
    <div className="text-left">
      {Object.keys(docs).length > 1 ? (
        <div className="sticky top-0 z-70 flex flex-row items-center space-x-4 border-b border-white bg-white p-2">
          <Button
            size="sm"
            color="primary"
            design="secondary"
            icon={<ChevronDoubleUp className="rotate-180 stroke-2" />}
            text={t('common:ExpandAll')}
            onClick={() => setOpenStatuses(filteredDocs?.map(() => true) || [])}
          />
          <Button
            size="sm"
            color="primary"
            design="secondary"
            icon={<ChevronDoubleUp className="stroke-2" />}
            text={t('common:CollapseAll')}
            onClick={() =>
              setOpenStatuses(filteredDocs?.map(() => false) || [])
            }
          />
        </div>
      ) : null}
      {filteredDocs.map((doc, index) => {
        const setOpen = (status: boolean) => {
          setOpenStatuses((state) => {
            return state.map((oldStatus, statusIndex) =>
              index === statusIndex ? status : oldStatus
            );
          });
        };
        return !doc.fake ? (
          <CardEditor
            key={doc.id}
            user={user}
            metaId={doc.id}
            collapsable={Object.keys(docs).length > 1}
            noTitle={noTitle}
            editable={editable}
            docName={docName}
            setDocName={setDocName}
            isOpen={
              Object.keys(docs).length > 1 ? openStatuses[index] : undefined
            }
            setOpen={setOpen}
          />
        ) : (
          <FakeCardEditor
            key={doc.id}
            user={user}
            metaId={doc.id}
            doc={doc as unknown as FakeDoc}
            collapsable={Object.keys(docs).length > 1}
            noTitle={noTitle}
            isOpen={
              Object.keys(docs).length > 1 ? openStatuses[index] : undefined
            }
            setOpen={setOpen}
          />
        );
      })}
    </div>
  );
};

export default ContentCardsEditor;
