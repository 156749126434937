import React, { useState } from 'react';

import { Space, Button } from 'antd';
import { deleteSession } from 'model/sessions';

const DeleteSessionPane = ({
  sessionTitle,
  sessionId,
  confirm,
  close,
}: {
  sessionTitle: string;
  sessionId: string;
  confirm?: () => void;
  close: () => void;
}): JSX.Element => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  const handleDeletion = async () => {
    setError(false);
    try {
      setProcessing(true);
      await deleteSession(sessionId);
      confirm?.();
      close();
    } catch (e) {
      setError(true);
      setProcessing(false);
      console.error(e);
    }
  };

  return (
    <Space direction="vertical">
      {error
        ? 'An error occured. Retry ?'
        : `Are you sure you want to delete ${sessionTitle}?`}
      <Space>
        <Button disabled={processing} onClick={close}>
          Cancel
        </Button>
        <Button
          loading={processing}
          disabled={processing}
          onClick={handleDeletion}
        >
          {error ? 'Retry' : 'Yes'}
        </Button>
      </Space>
    </Space>
  );
};

export default DeleteSessionPane;
