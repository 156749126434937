import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const CopyButton = ({
  textToCopy,
  title,
  tooltipText,
  style,
  type,
  size,
}: {
  textToCopy: string;
  title: React.ReactNode;
  tooltipText?: string;
  style?: React.CSSProperties;
  type?: ButtonType;
  size?: SizeType;
}) => {
  const [copied, setCopied] = useState(false);

  const onClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <Tooltip visible={copied} title={tooltipText || 'Copié !'}>
      <Button size={size} type={type} style={style} onClick={onClick}>
        {title}
        {copied ? <CheckOutlined /> : <CopyOutlined />}
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
