import React from 'react';
import { Mode } from 'types/ui';
import { Lock, Play, Check } from 'assets/icons';

const Status = ({
  progress,
  state,
  onClick,
  mode,
  paused,
}: {
  progress: number | null;
  state: 'passed' | 'running' | 'locked';
  onClick: () => void;
  mode: Mode;
  paused: boolean;
}): JSX.Element => {
  const fullProgress = Math.min(Math.abs(progress || 0.5), 0.99) === 0.99;
  const circumference = 15 * 2 * Math.PI;

  return (
    <div
      className={`flex h-12 w-12 border-2 bg-white ${
        mode === 'facilitator'
          ? 'cursor-pointer hover:border-surfaces-strongest'
          : 'cursor-default'
      } rounded-full border-surfaces-strong `}
      onClick={onClick}
    >
      <div className="m-auto flex h-10 w-10 rounded-full bg-gray-soft">
        {state === 'running' && (
          <div className="m-auto">
            {progress !== null ? (
              <svg className="h-10 w-10 -rotate-90 rounded-full bg-gray-soft text-primary">
                <circle
                  className="text-gray-300"
                  strokeWidth="4"
                  stroke="currentColor"
                  fill="transparent"
                  r="15"
                  cx="20"
                  cy="20"
                />
                <circle
                  className="text-primary"
                  strokeWidth="4"
                  strokeDasharray={`${circumference}`}
                  strokeDashoffset={`${
                    fullProgress ? 0 : circumference - progress * circumference
                  }`}
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="15"
                  cx="20"
                  cy="20"
                />
                {paused ? (
                  <>
                    <rect
                      className="fill-current text-primary"
                      x="13"
                      y="14"
                      width="15"
                      height="4"
                    />
                    <rect
                      className="fill-current text-primary"
                      x="13"
                      y="21"
                      width="15"
                      height="4"
                    />
                  </>
                ) : null}
              </svg>
            ) : (
              <svg className="h-10 w-10 rounded-full bg-gray-soft">
                <rect
                  className="fill-current text-primary"
                  x="11"
                  y="17.5"
                  width="18"
                  height="4"
                />
              </svg>
            )}
          </div>
        )}
        {(state === 'passed' || (state === 'running' && fullProgress)) && (
          <Check className={`m-auto h-10 w-10 stroke-3/2 text-primary`} />
        )}
        {state === 'locked' &&
          (mode === 'facilitator' ? (
            <Play
              className={`m-auto h-6 w-6 stroke-2 ${
                state === 'locked' ? 'text-black' : 'text-primary'
              }`}
            />
          ) : (
            <Lock
              className={`m-auto h-6 w-6 stroke-3/2 ${
                state === 'locked' ? 'text-black' : 'text-primary'
              }`}
            />
          ))}
      </div>
    </div>
  );
};

export default Status;
