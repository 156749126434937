import { DataProcessing } from 'assets/undraw';
import Blockquote from 'components/Blockquote';
import Button from 'components/Button';
import Link from 'components/Link';
import NumberMetric from 'components/NumericMetric';
import SmileyRating from 'components/SmileyRating';
import Spinner from 'components/Spinner';
import Table, { Column, Data } from 'components/Table';
import { TFunctionResult } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCommunitySessionsParticipants } from 'services/export';
import { fetchData } from 'services/firebase';
import { searchDocs } from 'services/searching';
import { Sessions, TrueSet, TrueSetSet, UserType } from 'types/types';
import { mapValuesAsync } from 'utils/pureUtils';

export type CommunityMetricsProps = {
  communityId: string;
  userType: UserType;
};

const LoadingScreen = () => (
  <div className="flex w-full justify-center">
    <div className="mt-12 flex flex-col items-center">
      <h2 className="flex flex-row items-center text-2xl font-semibold">
        Processing Data
        <Spinner className="ml-4 h-8 w-8 text-primary" />
      </h2>
      <DataProcessing className="mx-auto mt-10 w-72 fill-current text-primary md:w-96" />
    </div>
  </div>
);

type DatabaseData = {
  sessions: Sessions;
  feedbacks: Record<string, any>;
  participantsSet: TrueSetSet;
  facilitatorsSet: TrueSetSet;
  organizersSet: TrueSetSet;
  nbDocs: number;
  startTime: number;
};

type DashboardSession = {
  title: string;
  accessCode: string;
  date: Moment;
  nbParticipants: number;
  statistfaction: number | null;
  wantsMore: number | null;
};

type DashboardData = {
  sessions: DashboardSession[];
  nbParticipants: number;
  nbFacilitators: number;
  nbParticipants2MoreSessions: number;
  statisfaction: number;
  nbSatisfactionAnswered: number;
  bestFeedbacks: string[];
  wantsMore: number | null;
  nbDocs: number;
  startTime: number;
};

const loadData = async (
  communityId: string,
  userType: UserType
): Promise<DatabaseData> => {
  const sessions: Sessions = await fetchData<Sessions>(
    'sessionsNext',
    {},
    (ref) => ref.orderByChild('communityId').equalTo(communityId)
  );

  const feedbacks = await mapValuesAsync(sessions, (_sess, sessionId) =>
    fetchData<any>(
      `sessionsNextData/${sessionId}/activities/feedback/productions`
    )
  );

  const participantsSet = await mapValuesAsync(sessions, (_sess, sessionId) =>
    fetchData<Record<string, true>>(
      `sessionsNextData/${sessionId}/users/participants`,
      {}
    )
  );

  const facilitatorsSet = await mapValuesAsync(sessions, (_sess, sessionId) =>
    fetchData<Record<string, true>>(
      `sessionsNextData/${sessionId}/users/facilitators`,
      {}
    )
  );

  const organizersSet = await mapValuesAsync(sessions, (_sess, sessionId) =>
    fetchData<Record<string, true>>(
      `sessionsNextData/${sessionId}/users/organizers`,
      {}
    )
  );

  const startingAt = await fetchData<number>(
    `communities/${communityId}/createdAt`,
    0
  );

  const results = await searchDocs('', undefined, userType, undefined, [
    communityId,
  ]);

  return {
    sessions,
    feedbacks,
    participantsSet,
    facilitatorsSet,
    organizersSet,
    nbDocs: results.nbHits,
    startTime: startingAt,
  };
};

const extractBestFeedbacks = (data: DatabaseData): string[] => {
  let feedbacks: string[] = _.flatten(
    Object.values(data.feedbacks).map((sessionFeedbacks) => {
      return Object.entries(sessionFeedbacks?.questionAAnswer || {})
        .filter(
          ([userId, feedback]) => sessionFeedbacks?.valuable?.[userId] >= 4
        )
        .map(([, val]) => val);
    })
  ) as string[];

  feedbacks = feedbacks.filter((str: string) => str.length > 75);

  return _.shuffle(feedbacks);
};

const processData = (data: DatabaseData): DashboardData => {
  const sessions = Object.entries(data.sessions).map(([sessionId, session]) => {
    const wantsMoreArray = Object.values(
      data.feedbacks[sessionId]?.future || {}
    );

    const facilitatorsSet = {
      ...data.facilitatorsSet[sessionId],
      ...data.organizersSet[sessionId],
    };

    return {
      title: session.title,
      accessCode: session.accessCode,
      date: moment(session.scheduledAt[0]),
      nbParticipants: _.size(data.participantsSet[sessionId]),
      facilitatorsSet,
      nbFacilitators: _.size(facilitatorsSet),
      statistfaction:
        _.mean(Object.values(data.feedbacks[sessionId]?.valuable || {})) ||
        null,
      nbSatisfactionAnswered: _.size(data.feedbacks[sessionId]?.valuable || {}),
      wantsMore:
        wantsMoreArray.filter(_.identity).length / wantsMoreArray.length ||
        null,
    };
  });

  const sessionsByParticipants: Record<string, number> = Object.values(
    data.participantsSet
  ).reduce((prec: Record<string, number>, userSet) => {
    const usersIds = Object.keys(userSet);
    usersIds.forEach((userId) => {
      prec[userId] = (prec[userId] || 0) + 1;
    });
    return prec;
  }, {});

  const filteredWantsMoreSessions = sessions
    .map((session) => session.wantsMore)
    .filter((status) => status !== null);

  return {
    sessions: _.orderBy(sessions, 'date', 'desc'),
    nbFacilitators: _.size(
      sessions.reduce(
        (prec: TrueSet, sess) => ({ ...prec, ...sess.facilitatorsSet }),
        {}
      )
    ),
    nbParticipants: _.size(sessionsByParticipants),
    nbParticipants2MoreSessions: Object.values(sessionsByParticipants).filter(
      (n) => n >= 2
    ).length,
    bestFeedbacks: extractBestFeedbacks(data),
    statisfaction: _.mean(
      sessions
        .map((session) => session.statistfaction)
        .filter((status) => status !== null)
    ),
    nbSatisfactionAnswered: _.sumBy(sessions, 'nbSatisfactionAnswered'),
    wantsMore:
      filteredWantsMoreSessions.length > 0
        ? _.mean(filteredWantsMoreSessions)
        : null,
    nbDocs: data.nbDocs,
    startTime: data.startTime,
  };
};

type FeedbacksBlockProps = {
  feedbacks: string[];
};

const FeedbacksBlock = ({ feedbacks }: FeedbacksBlockProps): JSX.Element => {
  const { t } = useTranslation();
  const [viewAll, setViewAll] = useState(false);

  const toDisplay = useMemo(() => {
    return viewAll ? feedbacks : _.take(feedbacks, 5);
  }, [viewAll, feedbacks]);

  return (
    <div>
      <div className="flex flex-row items-center space-x-4">
        <h2 className="mb-2 text-2xl font-semibold">
          {t('communities:bestQuotes')}
        </h2>
        <Button
          text={viewAll ? t('common:seeLess') : t('common:seeMore')}
          onClick={() => setViewAll(!viewAll)}
          size="sm"
        />
      </div>
      <div className="space-y-4">
        {toDisplay.map((feedback, index) => (
          <Blockquote key={index} quote={feedback} />
        ))}
      </div>
    </div>
  );
};
const Dashboard = ({
  sessions,
  nbFacilitators,
  nbParticipants,
  bestFeedbacks,
  statisfaction,
  nbSatisfactionAnswered,
  wantsMore,
  communityId,
  nbDocs,
  startTime,
}: DashboardData & {
  communityId: string;
}): JSX.Element => {
  const { t } = useTranslation();

  type SessionRow = {
    key: string;
    date: string;
    title: string;
    accessCode: string;
    nbParticipants: number;
    meanSatisfaction: TFunctionResult | number;
    wantsMore: TFunctionResult | number;
  };

  const columns: Column<SessionRow>[] = useMemo(
    () => [
      {
        title: t('sessions:SessionDate'),
        dataIndex: 'date',
      },
      {
        title: t('sessions:SessionTitle'),
        dataIndex: 'title',
        render: (_dataIndex, session) => (
          <Link
            to={{ pathname: `/${session.accessCode}` }}
            text={session.title}
          />
        ),
      },
      {
        title: t('sessions:NbParticipants'),
        dataIndex: 'nbParticipants',
      },
      {
        title: t('communities:meanSatisfactionSimple'),
        dataIndex: 'meanSatisfaction',
      },
      {
        title: t('communities:wantsRenewExperience'),
        dataIndex: 'wantsMore',
      },
    ],
    [t]
  );

  const data: Data<SessionRow> = useMemo(
    () =>
      sessions.map((session, index) => ({
        key: index.toString(),
        date: session.date.format(t('misc:dateFormatShort')),
        title: session.title,
        nbParticipants: session.nbParticipants,
        accessCode: session.accessCode,
        meanSatisfaction:
          session.statistfaction !== null
            ? _.round(session.statistfaction, 1)
            : t('misc:NA'),
        wantsMore:
          session.wantsMore !== null
            ? `${_.round(session.wantsMore * 100, 2)}%`
            : t('misc:NA'),
      })),
    [sessions, t]
  );

  return (
    <div className="space-y-12">
      <div>
        <Button
          text={t('common:ExtractParticipants')}
          onClick={() => exportCommunitySessionsParticipants(communityId)}
        />
      </div>
      <div className="flex flex-row items-start space-x-12">
        <NumberMetric
          label={t('common:createdSessions', { count: sessions.length })}
          value={sessions.length}
        />
        <NumberMetric
          label={t('common:participants1', { count: nbParticipants })}
          value={nbParticipants}
        />
        <NumberMetric
          label={t('common:facilitatorsANdOrganizers', {
            count: nbFacilitators,
          })}
          value={nbFacilitators}
        />
        <NumberMetric
          percentage={wantsMore !== null}
          label={t('common:wantsMore')}
          value={wantsMore === null ? t('misc:NA') : _.round(100 * wantsMore)}
        />
        <NumberMetric
          label={t('common:productionNumber', { count: nbDocs })}
          value={nbDocs}
        />
      </div>
      <div>
        <h2 className="mb-2 text-2xl font-semibold">
          {t('common:since', {
            formattedDate: moment(startTime).format('MMMM YYYY'),
          })}
        </h2>
      </div>
      <div>
        <h2 className="mb-2 text-2xl font-semibold">
          {t('communities:meanSatisfaction', { count: nbSatisfactionAnswered })}
        </h2>
        <div className="w-72">
          <SmileyRating value={statisfaction} showValue readOnly />
        </div>
      </div>
      <div>
        <h2 className="mb-2 text-2xl font-semibold">
          {t('communities:sessionData')}
        </h2>
        <Table columns={columns} data={data} />
      </div>
      <FeedbacksBlock feedbacks={bestFeedbacks} />
    </div>
  );
};

export const CommunityMetrics = ({
  communityId,
  userType,
}: CommunityMetricsProps): JSX.Element => {
  const [data, setData] = useState<DashboardData | null>(null);

  useEffect(() => {
    loadData(communityId, userType).then((data) => {
      const processedData = processData(data);
      setData(processedData);
    });
  }, [communityId, userType]);

  return !data ? (
    <LoadingScreen />
  ) : (
    <Dashboard {...data} communityId={communityId} />
  );
};
