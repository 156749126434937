import React from 'react';
import { Disclosure as DisclosureH } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import Tag from 'components/Tag';

import { Triangle, ChevronLeft } from 'assets/icons';

export type DisclosureProps = {
  type?: 'wap' | 'notion';
  title?: string | JSX.Element | ((open: boolean) => JSX.Element);
  children: JSX.Element | null;
  defaultOpen?: boolean;
  collapseOnTitle?: boolean;
  collapsable?: boolean;
  padding?: string;
  status?: boolean;
  onStatusChange?: (status: boolean) => void;
  stickyPositionClassName?: string;
};

export const DisclosureTitle = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center space-x-2">
      <h2 className="text-2xl font-semibold uppercase text-black">{text}</h2>
    </div>
  );
};

const Disclosure = ({
  type = 'wap',
  title,
  children,
  defaultOpen = false,
  collapseOnTitle = true,
  collapsable = true,
  padding = 'py-5 px-2',
  status,
  onStatusChange,
  stickyPositionClassName,
}: DisclosureProps): JSX.Element => {
  const { t } = useTranslation();
  const handleStatusChange =
    status !== undefined && onStatusChange !== undefined
      ? () => {
          onStatusChange(!status);
        }
      : undefined;
  return (
    <DisclosureH defaultOpen={defaultOpen}>
      {({ open }) => {
        const showPanel = !collapsable
          ? true
          : status !== undefined
          ? status
          : open;
        const toggleIconBlock =
          type === 'notion' ? (
            <Triangle
              className={`${
                showPanel ? '' : '-rotate-90 '
              } h-4 w-4 stroke-3/2 group-hover:stroke-5/2 group-hover:text-primary`}
            />
          ) : (
            <ChevronLeft
              className={`${
                open ? 'rotate-90' : '-rotate-90'
              } h-6 w-6 stroke-3/2 group-hover:stroke-5/2 group-hover:text-primary`}
            />
          );

        const titleBlock =
          typeof title === 'function' ? (
            title(showPanel)
          ) : (
            <span className="w-full">{title ? title : null}</span>
          );

        const helperBlock = (
          <span className="invisible group-hover:visible">
            <Tag
              text={showPanel ? t('common:Close') : t('common:Open')}
              color="primary"
            />
          </span>
        );

        const stickyClassName = `z-60 border-b border-white bg-white ${
          stickyPositionClassName ? `sticky ${stickyPositionClassName}` : ''
        } `;

        return (
          <div
            className={`w-full ${padding} ${
              collapsable ? 'border-b border-surfaces-divider' : ''
            } `}
          >
            {collapsable && collapseOnTitle ? (
              <div className={stickyClassName} onClick={handleStatusChange}>
                <DisclosureH.Button
                  className={`group flex w-full items-center space-x-4 text-left font-medium ${
                    type === 'wap' ? 'justify-between' : ''
                  }`}
                >
                  {type === 'notion' ? toggleIconBlock : null}
                  {titleBlock}
                  {type === 'notion' ? helperBlock : null}
                  {type === 'wap' ? toggleIconBlock : null}
                </DisclosureH.Button>
              </div>
            ) : collapsable ? (
              <div
                className={`flex w-full items-center space-x-4 ${stickyClassName} ${
                  type === 'wap' ? 'justify-between' : ''
                }`}
              >
                <div onClick={handleStatusChange}>
                  {type === 'notion' ? (
                    <DisclosureH.Button className="group">
                      {toggleIconBlock}
                    </DisclosureH.Button>
                  ) : null}
                </div>
                {titleBlock}
                {type === 'wap' ? (
                  <DisclosureH.Button className="group">
                    {toggleIconBlock}
                  </DisclosureH.Button>
                ) : null}
                {type === 'notion' ? helperBlock : null}
              </div>
            ) : (
              <div
                className={`flex w-full items-center space-x-4 ${stickyClassName}`}
              >
                {titleBlock}
              </div>
            )}

            {showPanel && (
              <DisclosureH.Panel static className={`mt-1 mb-4`}>
                {children}
              </DisclosureH.Panel>
            )}
          </div>
        );
      }}
    </DisclosureH>
  );
};

export default Disclosure;
