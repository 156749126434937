import React, { useEffect } from 'react';
import MindElixir from 'mind-elixir';
import './mindmap.css';

/* Les types ne sont pas encore disponibles car en cours de reflexion selon une issue  de gitlab
   https://github.com/ssshooter/mind-elixir-core/issues/68
   TODO : Convertir le fichier jsx en tsx une fois que cela sera défini
*/

const MindMap = ({ readOnly, data }) => {
  useEffect(() => {
    const mindElixir = new MindElixir({
      el: '#mindmap',
      direction: MindElixir.SIDE,
      draggable: !readOnly,
      contextMenu: !readOnly,
      toolBar: true,
      nodeMenu: !readOnly,
      keypress: !readOnly,
      editable: !readOnly,
      initialScale: 0.5,
    });
    mindElixir.init(data);
  }, [readOnly, data]);

  return (
    <div className="relative h-192 w-full">
      <div id="mindmap" className="h-full w-full" />
      <div className="pointer-events-none absolute top-0 left-0 bottom-0 right-0 border-8"></div>
    </div>
  );
};

export default MindMap;
