import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MDReactComponent from 'componentsOld/MDReactComponent';

import { ChevronRight, InformationCircle } from 'assets/icons';
import { TElement } from '@udecode/plate-core';

export type InstructionsBlockElementProps = {
  element: TElement;
  children: React.ReactNode;
};

export const createInstructionsBlockElement =
  (hidden?: boolean, editableInstructionBlocks?: boolean) =>
  ({ element, children }: InstructionsBlockElementProps) => {
    const { t } = useTranslation();
    const [collapsed, setCollpased] = useState(false);

    const toggleCollapsed = () => setCollpased(!collapsed);

    return hidden ? null : (
      <div
        contentEditable={editableInstructionBlocks || false}
        className="relative mb-5 overflow-hidden rounded-r-md border-l-2 border-primary"
      >
        <div
          // contentEditable={editableInstructionBlocks || false}
          className={`bg-surfaces-soft p-5 pr-14 text-black rounded-md${
            editableInstructionBlocks ? '' : 'cursor-pointer'
          }`}
        >
          {editableInstructionBlocks ? (
            <div>{children}</div>
          ) : (
            <>
              <div
                className="group mr-2 flex h-6 cursor-pointer items-center"
                onClick={toggleCollapsed}
              >
                <ChevronRight
                  className={`h-5 w-5 stroke-2 ${collapsed ? '' : 'rotate-90'}`}
                />
                {collapsed ? (
                  <p className="font-semibold">
                    {t('editor:viewInstructions')}
                  </p>
                ) : (
                  <p className="ml-2 hidden font-semibold group-hover:block">
                    {t('editor:collapseInstructions')}
                  </p>
                )}
              </div>
              {!collapsed && (
                <>
                  {element.source ? (
                    <MDReactComponent text={element.source} />
                  ) : null}
                  {children}
                </>
              )}
            </>
          )}
        </div>
        <div
          contentEditable={false}
          className="absolute -top-7 -right-7 h-20 w-20 select-none rounded-full bg-primary"
        >
          <InformationCircle className="absolute top-9 right-9 h-6 w-6 stroke-2 text-white" />
        </div>
      </div>
    );
  };
