import { Exclamation } from 'assets/icons';
import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { useConfidentialityList } from 'model/parameters';
import { useUserContextWithDefaults } from 'contexts/UserContext';

export type ConfidentialBarrierProps = {
  children?: JSX.Element;
};

const displayCondition = (email: string, list: string[]): boolean => {
  return _.some(list, (pattern) => email.includes(pattern));
};

const ConfidentialBarrier = ({
  children,
}: ConfidentialBarrierProps): JSX.Element => {
  const [hasBeenDisplayed, setHasBeenDisplayed] = useState(false);

  const { userId, userEmail, isUserLoaded } = useUserContextWithDefaults();
  const [list, listLoaded] = useConfidentialityList(userId);

  const mustDisplayModal =
    !hasBeenDisplayed &&
    isUserLoaded &&
    !!userEmail &&
    listLoaded &&
    displayCondition(userEmail, list);

  const { t } = useTranslation();

  const close = () => setHasBeenDisplayed(true);

  return (
    <>
      <Modal
        open={mustDisplayModal}
        onClose={close}
        body={
          <div className="flex h-full flex-col items-center justify-evenly">
            <div className="text-danger-medium">
              <Exclamation className="h-32 w-32 stroke-3/2" />
            </div>
            <p className="text-center text-3xl">
              {t('misc:ConfidentialityNotice')}
            </p>
          </div>
        }
        fullHeight
        footer={<Button onClick={close} text={t('common:Close')} />}
      />
      {children}
    </>
  );
};

export default ConfidentialBarrier;
