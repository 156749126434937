import React from 'react';

export type ThumbnailProps = {
  name: string;
  size?: 'normal' | 'tiny' | 'big' | 'large';
  colorClass?: string;
};

const sizes = {
  normal: 'w-10 h-10 text-lg rounded-lg',
  tiny: 'w-5 h-5 text-xs rounded',
  large: 'w-16 h-16 text-3xl rounded-xl',
  big: 'w-20 h-20 text-4xl rounded-2xl',
};

const Thumbnail = ({
  name,
  size = 'normal',
  colorClass = 'bg-black',
}: ThumbnailProps) => {
  return (
    <div
      className={`flex shrink-0 items-center justify-center font-semibold  text-white ${sizes[size]} ${colorClass}`}
    >
      <p>{name.charAt(0).toUpperCase()}</p>
    </div>
  );
};

export default Thumbnail;
