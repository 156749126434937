import React from 'react';

import { ProgressingStatus } from './Step';

export type ExtraStepProps = {
  onEnable?: () => void;
  onSelect?: () => void;
  label: string;
  done?: boolean;
  isSelected?: boolean;
  isEnabled?: boolean;
};

const ExtraStep = ({
  onSelect,
  onEnable,
  label,
  done = false,
  isSelected = false,
  isEnabled = false,
}: ExtraStepProps): JSX.Element => {
  const stepStateStyle = `${`cursor-pointer ${
    isEnabled || isSelected ? '' : 'hover:border-surfaces-strongest'
  }`} ${isEnabled ? 'bg-primary text-white ' : 'bg-white '} ${
    isEnabled || isSelected ? 'border-primary-strong' : 'border-surfaces-strong'
  }`;

  return (
    <div className="flex w-full flex-col justify-start px-4">
      <div className="flex w-full items-center">
        <ProgressingStatus
          mode={'facilitator'}
          durationMs={undefined}
          remainingTimeMs={undefined}
          paused={false}
          state={isEnabled ? 'running' : done ? 'passed' : 'locked'}
          onClick={() => {
            onEnable?.();
            onSelect?.();
          }}
        />

        <div
          className={`m-2 flex min-h-12 flex-grow flex-col rounded-2xl border-2 p-2 pl-4 text-base ${stepStateStyle}`}
        >
          <div
            className="flex flex-grow items-center"
            onClick={() => onSelect?.()}
          >
            <div className="ml-1 flex-grow text-left font-bold md:text-base">{`${label}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraStep;
