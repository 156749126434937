import React from 'react';
import { useField } from 'formik';

import { useOnChange } from './hooks';

import DurationPicker from 'components/DurationPicker';

type Duration = {
  hours: number;
  minutes: number;
};
export type DurationPickerInputProps = {
  name: string;
  label?: string;
  readOnly?: boolean;
  onChangeValue?: (value: Duration, hasError: boolean) => void;
};

const DurationPickerInput = ({
  name,
  label,
  readOnly,
  onChangeValue,
}: DurationPickerInputProps): JSX.Element => {
  const [field, , helpers] = useField<Duration>(name);

  useOnChange(name, onChangeValue);
  return (
    <div>
      {label ? (
        <label htmlFor={name} className="text-lg font-semibold">
          {label}
        </label>
      ) : null}
      <div className={`mt-2 w-full`}>
        <DurationPicker
          hours={field.value?.hours}
          minutes={field.value?.minutes}
          onChange={(hours, minutes) =>
            helpers.setValue({ hours: hours, minutes: minutes })
          }
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default DurationPickerInput;
