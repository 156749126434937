import List from 'components/List';
import Thumbnail from 'components/Thumbnail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRight from 'assets/icons/ChevronRight';
import { Communities } from 'types/types';
import { useHistory } from 'react-router-dom';

export type CommunitiesListProps = {
  communities: Communities;
};

const CommunitiesList = ({
  communities,
}: CommunitiesListProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <List
      items={Object.entries(communities)}
      title={''}
      showHeader={false}
      itemsPerPage={7}
      renderItem={([communityId, { name, nbMembers }]) => {
        return (
          <div
            key={communityId}
            className="flex h-20 w-full cursor-pointer flex-row items-center rounded-xl border border-surfaces-divider p-5 hover:bg-primary-soft"
            onClick={() => {
              history.push(`community/${communityId}`);
            }}
          >
            <div className="shrink-0">
              <Thumbnail name={name} />
            </div>
            <div className="ml-3 flex-grow">
              <p className="text-sm">
                {t('communities:Xmembers', { count: nbMembers })}
              </p>
              <p className="text-lg font-semibold">{name}</p>
            </div>
            <div className="shrink-0">
              <ChevronRight className="h-6 w-6 stroke-3/2 text-black" />
            </div>
          </div>
        );
      }}
    />
  );
};

export default CommunitiesList;
