import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export type TimeType = { hours: number; minutes: number };

const convertTimeToStringValue = (value?: TimeType) => {
  return value
    ? `${_.padStart(`${value.hours}`, 2, '0')}:${_.padStart(
        `${value.minutes}`,
        2,
        '0'
      )}`
    : undefined;
};

export type TimePickerProps = {
  name?: string;
  value: TimeType;
  onChange?: (time: TimeType) => void;
  min?: TimeType;
  max?: TimeType;
  suggestions?: TimeType[];
};

const TimePicker = ({
  name = '',
  value,
  onChange,
  min,
  max,
  suggestions,
}: TimePickerProps): JSX.Element => {
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <div className="relative w-min">
      <input
        required
        type="time"
        list={`popularHours ${name}`}
        className={`rounded-md border border-surfaces-divider p-3 text-lg invalid:border-danger`}
        value={convertTimeToStringValue(localValue)}
        min={convertTimeToStringValue(min)}
        max={convertTimeToStringValue(max)}
        onChange={(e) => {
          const [hours, minutes] = e.target.value.split(':');

          setLocalValue({
            minutes: parseInt(minutes),
            hours: parseInt(hours),
          });
          onChange?.({
            minutes: parseInt(minutes),
            hours: parseInt(hours),
          });
        }}
      />
      {suggestions ? (
        <datalist id={`popularHours ${name}`}>
          {suggestions.map((suggestion, index) => (
            <option
              key={index}
              value={convertTimeToStringValue(suggestion)}
            ></option>
          ))}
        </datalist>
      ) : null}
    </div>
  );
};

export default TimePicker;
