import React, { useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectSearchBox,
  Hits,
  connectCurrentRefinements,
  connectRange,
  Configure,
} from 'react-instantsearch-dom';
import { Hit, connectRefinementList } from 'react-instantsearch-core';
import moment from 'moment';

import { algoliaConf as conf, domainUrl } from 'config';

import { Link, useHistory } from 'react-router-dom';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import EditableSearchableMultiselect from 'componentsOld/EditableSearchableMultiselect';
import {
  addKindOfPublic,
  editKindOfPublic,
  deleteKindOfPublic,
} from 'model/kindsOfPublic';
import { updateKindsOfPublic, createTestSession } from 'model/sessions';
import { useSelector } from 'react-redux';
import { SessionSearchResultType, UserType } from 'types/types';
import { Button, Popover, Space, Input, Radio, Tag } from 'antd';
import CopyButton from 'componentsOld/Button/CopyButton';
import DeleteSessionPane from './DeleteSessionPane';
import LoadingScreen from 'screens/LoadingScreen';
import { intersection, overlaps } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import Pagination from 'frameworks/algoliasearch/Pagination';
import { useGlobalModal } from 'contexts/GlobalModal';
import { isGlobalAdmin } from 'model/users';

const { Search } = Input;

const client = algoliasearch(conf.appId, conf.key);

const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <Search
      className="flex-grow"
      value={currentRefinement}
      style={{ fontSize: '16px', marginRight: '8px' }}
      enterButton
      onChange={(event) => refine(event.target.value)}
    />
  );
});

const RangeSlider = ({ refine, value, setValue, min, max }: any) => {
  const { t } = useTranslation();
  const thisWeek = useMemo(
    () => ({
      min: moment().startOf('week').valueOf(),
      max: moment().endOf('week').valueOf(),
    }),
    []
  );

  const lastWeek = useMemo(
    () => ({
      min: moment().subtract(1, 'w').startOf('week').valueOf(),
      max: moment().subtract(1, 'w').endOf('week').valueOf(),
    }),
    []
  );

  const nextWeek = useMemo(
    () => ({
      min: moment().add(1, 'w').startOf('week').valueOf(),
      max: moment().add(1, 'w').endOf('week').valueOf(),
    }),
    []
  );

  const range = useMemo(() => ({ min, max }), [max, min]);

  useEffect(() => {
    let selectedWeek = null;
    switch (value) {
      case 'current':
        selectedWeek = thisWeek;
        break;
      case 'last':
        selectedWeek = lastWeek;
        break;
      case 'next':
        selectedWeek = nextWeek;
        break;
    }
    if (selectedWeek && (range.min !== undefined || range.max !== undefined)) {
      refine(intersection(selectedWeek, range));
    }
  }, [value, range, refine, thisWeek, nextWeek, lastWeek]);

  return (
    <Radio.Group
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      buttonStyle="solid"
    >
      <Radio.Button value="current" disabled={!overlaps(range, thisWeek)}>
        {t('time:ThisWeek')}
      </Radio.Button>
      <Radio.Button value="last" disabled={!overlaps(range, lastWeek)}>
        {t('time:LastWeek')}
      </Radio.Button>
      <Radio.Button value="next" disabled={!overlaps(range, nextWeek)}>
        {t('time:NextWeek')}
      </Radio.Button>
    </Radio.Group>
  );
};
const CustomRangeSlider = connectRange(RangeSlider);

const AppAdminSessionBrowseScreen = ({
  userId,
  userType,
}: {
  userId: string;
  userType: UserType;
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const { show: showCreateSession } = useGlobalModal('createSession');

  const [deleteVisible, setDeleteVisible] = useState<Record<string, boolean>>(
    {}
  );

  const [deletedSessions, setDeletedSessions] = useState<Set<string>>(
    new Set()
  );

  const [radioState, setRadioState] = useState<
    null | 'current' | 'next' | 'last'
  >('current');

  useFirebaseConnect([
    {
      path: `facilitations/${userId}`,
    },
    {
      path: `organizations/${userId}`,
    },
    {
      path: `kindsOfPublic/`,
    },
    {
      path: `communitiesOfAdmins/${userId}`,
    },
  ]);

  const facilitations: Record<string, true> = useSelector(
    (state: any) => state.firebase.data.facilitations?.[userId]
  );
  const organizations: Record<string, true> = useSelector(
    (state: any) => state.firebase.data.organizations?.[userId]
  );

  const kindsOfPublic: Record<string, string> = useSelector(
    (state: any) => state.firebase.data.kindsOfPublic
  );

  const communitiesOfAdmins: Record<string, string> = useSelector(
    (state: any) => state.firebase.data.communitiesOfAdmins?.[userId]
  );

  const handleDeleteVisibleChange = (key: string) => (visible: boolean) => {
    setDeleteVisible({
      ...deleteVisible,
      [key]: visible,
    });
  };

  const RefinementComponent = ({ refine }: any) => {
    const Component = useMemo(
      () =>
        ({ hit }: { hit: Hit<SessionSearchResultType> }) =>
          deletedSessions.has(hit.sessionId) ? null : (
            <SessionRow hit={hit} refine={refine} />
          ),
      [refine]
    );

    return <Hits hitComponent={Component} />;
  };

  const RefinementList = connectRefinementList(RefinementComponent);

  const SessionRow = ({
    hit,
    refine,
  }: {
    hit: Hit<SessionSearchResultType>;
    refine: (value: string[]) => any;
  }) => {
    return (
      <div className="my-4 flex flex-col items-start space-y-4 rounded-2xl border border-surfaces-divider bg-white py-4 px-8">
        <Link className="font-semibold underline" to={`/${hit.accessCode}`}>
          {hit.title}
        </Link>
        <div className="flex flex-row items-center space-x-2">
          <div>{moment(hit.start_timestamp).format('DD/MM/YYYY')}</div>
          <div>
            <EditableSearchableMultiselect
              options={kindsOfPublic || {}}
              selection={hit.kindsOfPublic || {}}
              addOption={addKindOfPublic}
              changeOption={editKindOfPublic}
              deleteOption={deleteKindOfPublic}
              onSelectionChange={(selection: Record<string, true>) =>
                updateKindsOfPublic(hit.sessionId, selection)
              }
            />
          </div>

          <Button
            type="link"
            onClick={(event) => {
              event.preventDefault();
              refine([hit.community]);
            }}
          >
            {hit.community}
          </Button>
          {/* <CommunityRefinement
            attribute="community"
            community={hit.community}
          /> */}
          {/* <ToggleRefinement
            attribute="community"
            label={hit.community}
            value={hit.community}
          /> */}
        </div>
        <Space>
          {/* <Link to={`/${record.code}/admin/details`} style={styles.link}>
              <Button>{`Details`}</Button>
            </Link> */}
          <Button
            onClick={() => {
              showCreateSession({
                modalTitle: t('sessions:duplicateSessionTitle'),
                title: t('common:duplicatedTag', { text: hit.title }),
                submitLabel: t('common:Duplicate'),
                duplicatedSessionId: hit.sessionId,
              });
            }}
          >
            {t('common:Duplicate')}
          </Button>
          <CopyButton
            textToCopy={`${domainUrl}/${hit.accessCode}`}
            title={t('common:CopyLink')}
          />
          {isGlobalAdmin(userType) && (
            <Popover
              content={
                <DeleteSessionPane
                  sessionId={hit.sessionId}
                  sessionTitle={hit.title}
                  confirm={() => {
                    setDeletedSessions(
                      new Set([hit.sessionId, ...deletedSessions])
                    );
                  }}
                  close={() => handleDeleteVisibleChange(hit.sessionId)(false)}
                />
              }
              title={`Remove ${hit.title}`}
              trigger="click"
              visible={deleteVisible[hit.sessionId]}
              onVisibleChange={handleDeleteVisibleChange(hit.sessionId)}
            >
              <Button>{t('common:Delete')}</Button>
            </Popover>
          )}
          {isGlobalAdmin(userType) && (
            <Button onClick={() => history.push(`/template/${hit.accessCode}`)}>
              Make template
            </Button>
          )}
          <Button
            onClick={async () => {
              try {
                const accessCode = await createTestSession(
                  hit.sessionId,
                  hit.accessCode,
                  hit.title,
                  userId
                );
                if (accessCode) {
                  history.push(`/${accessCode}`);
                }
              } catch (error) {
                console.error(error);
              }
            }}
          >
            Tester
          </Button>
        </Space>
      </div>
    );
  };

  const CurrentRefinements = connectCurrentRefinements(({ items, refine }) => (
    <>
      {items.length > 0 ? (
        items.map((item) =>
          item.items ? (
            item.items.map((nested) => (
              <Tag
                key={item.label + item.attribute}
                closable={true}
                onClose={() => {
                  refine(nested.value);
                }}
              >
                {item.label}
                {nested.label}
              </Tag>
            ))
          ) : (
            <Tag
              key={item.attribute}
              closable={true}
              onClose={() => {
                refine(item.value);
                if (item.attribute === 'start_timestamp') {
                  setRadioState(null);
                }
              }}
            >
              {item.label}
            </Tag>
          )
        )
      ) : (
        <p className="italic">{t('misc:noAppliedFilters')}</p>
      )}
    </>
  ));

  const ClearRefinements = useMemo(
    () =>
      connectCurrentRefinements(({ items, refine }: any) => {
        return (
          <Button
            onClick={() => {
              setRadioState(null);
              refine(items);
            }}
          >
            {t('common:Clear')}
          </Button>
        );
      }),
    [t]
  );

  const rightsFilters = [
    ...Object.keys(facilitations || {}).map(
      (sessionId) => `sessionId:${sessionId}`
    ),
    ...Object.keys(organizations || {}).map(
      (sessionId) => `sessionId:${sessionId}`
    ),
    ...Object.keys(communitiesOfAdmins || {}).map(
      (sessionId) => `communityId:${sessionId}`
    ),
  ].join(' OR ');

  const filters = `NOT isTemplate:true ${
    rightsFilters && !isGlobalAdmin(userType) ? `AND (${rightsFilters})` : ''
  }`;

  return isLoaded(
    facilitations,
    organizations,
    kindsOfPublic,
    communitiesOfAdmins
  ) ? (
    <div className="w-full">
      <div className="mb-4 flex flex-row">
        <div className="flex-grow"></div>
        <Button type="primary" onClick={() => showCreateSession()}>
          {t('sessions:CreateANewSession')}
        </Button>
      </div>
      <InstantSearch searchClient={client} indexName={conf.sessionsIndexName}>
        {<Configure filters={filters} />}
        <div className="w-full">
          <SearchBox />
        </div>
        <div className="mt-2 flex w-full flex-row text-left">
          <p className="mr-1">{t('common:Filters')}</p>
          <CurrentRefinements
            transformItems={(items) => {
              return items.map((item: any) =>
                item.attribute === 'start_timestamp'
                  ? {
                      ...item,
                      label: 'Date filter',
                    }
                  : item
              );
            }}
          />
        </div>
        <div className="flex-rpw mt-2 flex w-full">
          <CustomRangeSlider
            attribute="start_timestamp"
            value={radioState}
            setValue={setRadioState}
          />

          <div className="flex-grow" />
          <ClearRefinements clearsQuery />
        </div>
        <div className="mt-8">
          <Pagination />
        </div>
        <RefinementList attribute="community" />
        <div className="mt-4">
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default AppAdminSessionBrowseScreen;
