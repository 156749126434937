import React from 'react';

import Input from 'frameworks/formik/Input';
import InputCopy from 'frameworks/formik/InputCopy';
import { LanguageSelectorInput } from 'blocks/LanguageSelector';
import ListItemInput from 'frameworks/formik/ListItemInput';
import NumberInput from 'frameworks/formik/NumberInput';
import DatetimePickerInput from 'frameworks/formik/DatetimePickerInput';
import DurationPickerInput from 'frameworks/formik/DurationPickerInput';
import { SessionTypeInput } from 'blocks/SessionType';
import TextArea from 'frameworks/formik/TextArea';
import ActivityCriteria from 'blocks/ActivityCriteriaForm';
import UserSelector from 'blocks/UserSelector';

import { TaskViewType } from '../../tasks';
import { UserType } from 'types/types';

export type TaskInputProps = {
  name: string;
  viewType?: TaskViewType;
  readOnly: boolean;
  sessionId: string;
  userId: string;
  userType: UserType;
  onChange: (value: any, hasError: boolean) => void;
};

const TaskInput = ({
  name,
  viewType,
  readOnly,
  sessionId,
  userId,
  userType,
  onChange,
}: TaskInputProps): JSX.Element => {
  switch (viewType) {
    case 'textInput':
      return <Input name={name} readOnly={readOnly} onChangeValue={onChange} />;
    case 'textInputCopy':
      return (
        <InputCopy name={name} readOnly={readOnly} onChangeValue={onChange} />
      );
    case 'numberInput':
      return (
        <NumberInput name={name} readOnly={readOnly} onChangeValue={onChange} />
      );
    case 'textareaInput':
      return (
        <TextArea name={name} readOnly={readOnly} onChangeValue={onChange} />
      );
    case 'datetimeInput':
      return (
        <DatetimePickerInput
          name={name}
          readOnly={readOnly}
          wrap
          onChangeValue={onChange}
        />
      );
    case 'durationInput':
      return (
        <DurationPickerInput
          name={name}
          readOnly={readOnly}
          onChangeValue={onChange}
        />
      );
    case 'listItemInput':
      return (
        <ListItemInput
          name={name}
          readOnly={readOnly}
          cols={1}
          onChangeValue={onChange}
        />
      );
    case 'sessionTypeInput':
      return (
        <SessionTypeInput
          name={name}
          compact
          readOnly={readOnly}
          onChangeValue={onChange}
        />
      );
    case 'languageInput':
      return (
        <LanguageSelectorInput
          name={name}
          compact={false}
          allowUnknown
          readOnly={readOnly}
          onChangeValue={onChange}
        />
      );
    case 'criteria':
      return (
        <ActivityCriteria
          name={name}
          sessionId={sessionId}
          readOnly={readOnly}
          onChangeValue={onChange}
        />
      );
    case 'userSelector':
      return (
        <UserSelector
          name={name}
          readOnly={readOnly}
          sessionId={sessionId}
          userId={userId}
          userType={userType}
          onChangeValue={onChange}
        />
      );
    default:
      throw new Error('View not supported');
  }
};

export default TaskInput;
