import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { useLocalSearch } from 'services/localSearching';
import { CommunityInvite } from 'types/types';
import { Community, CommunityInviteState } from 'types/baseTypes';
import { useMemoCompare } from 'utils/utils';
import {
  inviteEmailInCommunity,
  forceResent,
  removeInvitation,
} from 'model/communityInvites';

import List from 'components/List';
import SearchInput from 'components/SearchInput';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'frameworks/formik/Input';
import RoundedButton from 'components/RoundedButton';
import Tag from 'components/Tag';

import { X, Plus, Trash, PaperPlane } from 'assets/icons';
import { Subscribe } from 'assets/undraw';

const splitStrategy = (value: string): Array<string> | null => {
  const splittedNewLine = value.split('\n');
  const splittedSpace = value.split(' ');
  return splittedNewLine?.length > 1
    ? splittedNewLine
    : splittedSpace?.length > 1
    ? splittedSpace
    : null;
};

type InvitesFormsProps = {
  close: () => void;
  inviteEmail: (email: string) => Promise<void>;
};

const InvitesForms = ({
  close,
  inviteEmail,
}: InvitesFormsProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        users: [{ email: '' }],
      }}
      validationSchema={Yup.object({
        users: Yup.array().of(
          Yup.object({
            email: Yup.string()
              .email(t('form:invalideEmail'))
              .required(t('form:fieldRequired')),
          })
        ),
      })}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          let promises = values.users.map(async (user) => {
            return await inviteEmail(user.email);
          });
          await Promise.all(promises);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
          close();
        }
      }}
      render={({ values, errors }) => {
        const nbErrors = Object.values(errors?.users || {}).filter(
          (user) => user !== undefined
        ).length;
        return (
          <Form className="relative mt-2 space-y-4 pb-16">
            <FieldArray
              name="users"
              render={(arrayHelpers) => (
                <>
                  <div className="flex flex-row items-center space-x-2">
                    <h4>{t('communities:emailAddresses')}</h4>
                    <RoundedButton
                      color="primary"
                      onClick={() =>
                        arrayHelpers.insert(values.users.length, { email: '' })
                      }
                    >
                      <Plus className="stroke-2" />
                    </RoundedButton>
                  </div>
                  <div className="max-h-144 overflow-y-auto">
                    {values.users.map((user, index) => {
                      const splittedEmail = splitStrategy(user.email);
                      const splitEmailFunction = splittedEmail
                        ? () => {
                            _.uniq(splittedEmail)
                              .map((el) => el.trim())
                              .forEach((email) =>
                                arrayHelpers.insert(values.users.length, {
                                  email: email,
                                })
                              );
                            arrayHelpers.remove(index);
                          }
                        : null;

                      return (
                        <div
                          className={`flex flex-row items-start justify-between`}
                        >
                          <span className="flex flex-grow">
                            <Input
                              placeholder={t(
                                'communities:emailAddressesPlaceholder'
                              )}
                              name={`users.${index}.email`}
                              action={
                                splitEmailFunction ? (
                                  <Button
                                    type="button"
                                    className="ml-2"
                                    onClick={splitEmailFunction}
                                    text={t('common:split')}
                                    size="sm"
                                    design="secondary"
                                    color="warning"
                                  />
                                ) : undefined
                              }
                            />
                          </span>
                          <RoundedButton
                            onClick={() => arrayHelpers.remove(index)}
                            className="mt-2 ml-2"
                          >
                            <X className="stroke-2" />
                          </RoundedButton>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`absolute bottom-0 flex w-full items-center ${
                      nbErrors ? 'justify-between' : 'justify-end'
                    }`}
                  >
                    {nbErrors ? (
                      <div className="flex flex-row items-center space-x-3 rounded-md bg-primary-soft py-2 px-4">
                        <Button
                          size="sm"
                          design="secondary"
                          text={t('communities:focusInvalidEmail')}
                          onClick={() => {
                            let nextIndex = 0;
                            Object.entries(errors?.users || {}).forEach(
                              ([key, value]) => {
                                const index = parseInt(key);
                                if (value !== undefined) {
                                  if (nextIndex !== index) {
                                    arrayHelpers.move(index, nextIndex);
                                  }
                                  nextIndex++;
                                }
                              }
                            );
                          }}
                        />
                        <p>
                          {t('communities:XInvlaidEmails', { count: nbErrors })}
                        </p>
                      </div>
                    ) : null}
                    <Button
                      disabled={!!nbErrors}
                      loading={loading}
                      type="submit"
                      text={t('communities:Invite')}
                    />
                  </div>
                </>
              )}
            />
          </Form>
        );
      }}
    />
  );
};

export type CommunityInvitesProps = {
  userId: string;
  userName: string;
  userEmail: string;
  communityId: string;
  community: Community;
};

const CommunityInvites = ({
  communityId,
  community,
  userName,
  userEmail,
  userId,
}: CommunityInvitesProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');
  const [showInviteModal, setShowInviteModal] = useState(false);

  useFirebaseConnect(`invitesOfCommunities/${communityId}`);

  const invitesOfCommunities: Record<string, CommunityInvite> | null =
    useSelector(
      (state: any) => state.firebase.data.invitesOfCommunities?.[communityId]
    );

  const searchKeys: (keyof CommunityInvite)[] = useMemo(
    () => ['email', 'state'],
    []
  );

  const memoizedInvites = useMemoCompare(invitesOfCommunities || {}, _.isEqual);
  const filteredInvites = useLocalSearch(
    memoizedInvites,
    searchKeys,
    searchString
  );

  const filteredUsersAsArray = useMemo(() => {
    return Object.entries(filteredInvites);
  }, [filteredInvites]);

  const renderTag = (state: CommunityInviteState) => {
    switch (state) {
      case 'pending':
        return <Tag text={t('communities:invitePending')} color="warning" />;
      case 'sent':
        return <Tag text={t('communities:inviteSent')} color="primary" />;
      case 'failed':
        return <Tag text={t('communities:inviteFailed')} color="danger" />;
      case 'accepted':
        return <Tag text={t('communities:inviteAccepted')} color="success" />;
      case 'refused':
        return <Tag text={t('communities:inviteRefused')} color="danger" />;
    }
  };

  return (
    <>
      <div className="flex w-full flex-row flex-wrap lg:flex-nowrap lg:space-x-16">
        <div className="flex-grow">
          <div className="mb-4 flex flex-row items-center">
            <h1 className="flex-grow text-3xl font-semibold">
              {t('communities:Invites')}
            </h1>
            <Button
              text={t('communities:InviteMembers')}
              onClick={() => setShowInviteModal(true)}
            />
          </div>
          <div className="mb-8 w-full">
            <SearchInput
              placeholder={t('common:Search')}
              value={searchString}
              onChange={(value) => setSearchString(value)}
            />
          </div>
          {isLoaded(invitesOfCommunities) ? (
            <List
              items={filteredUsersAsArray}
              itemsPerPage={20}
              renderItem={([userId, user]) => (
                <div className="flex flex-row items-center py-2">
                  <div className="flex flex-grow flex-col">
                    <div className="flex flex-row items-center space-x-4">
                      <h3 className="text-xl font-semibold">{user.email}</h3>
                      {renderTag(user.state)}
                    </div>
                    <p className="text-base text-black-soft">
                      {moment(user.createdAt).fromNow()}
                    </p>
                  </div>

                  <div className="flex items-center space-x-4">
                    <RoundedButton>
                      <PaperPlane
                        className="rotate-90 stroke-2 pb-1"
                        onClick={() =>
                          forceResent(communityId, community, user.email)
                        }
                      />
                    </RoundedButton>
                    <RoundedButton
                      color="danger"
                      onClick={() =>
                        removeInvitation(communityId, community, user.email)
                      }
                    >
                      <Trash className="stroke-2" />
                    </RoundedButton>
                  </div>
                </div>
              )}
              renderEmptyList={() => (
                <>
                  <h2 className="mt-10 px-5 text-center text-xl">
                    {invitesOfCommunities &&
                    Object.entries(invitesOfCommunities).length > 0
                      ? t('communities:updateSearchInvitation')
                      : t('communities:noPendingInvitation')}
                  </h2>
                  <Subscribe className="mx-auto mt-10 w-72 fill-current text-primary md:w-96" />
                </>
              )}
            />
          ) : (
            <div className="flex h-32 w-full items-center justify-center">
              <Spinner className="h-12 w-12 text-primary" />
            </div>
          )}
        </div>
      </div>
      <Modal
        onClose={() => setShowInviteModal(false)}
        open={showInviteModal}
        title={t('communities:InviteMembers')}
        titleCenter={false}
        body={
          <InvitesForms
            close={() => setShowInviteModal(false)}
            inviteEmail={(email) =>
              inviteEmailInCommunity(communityId, community, email, {
                name: userName,
                email: userEmail,
                id: userId,
              })
            }
          />
        }
      />
    </>
  );
};

export default CommunityInvites;
