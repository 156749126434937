import * as React from 'react';

function SvgArrow2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 253 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#arrow2_svg__clip0)">
        <path d="M12.715 32.99a9.24 9.24 0 01-.444-3.003 8.766 8.766 0 01.676-3.295 8.019 8.019 0 012.155-2.988 9.305 9.305 0 011.727-1.181 11.08 11.08 0 012.203-.846" />
        <path d="M7.055 15.815c.292.887.792 1.81 1.472 2.662.68.85 1.54 1.639 2.559 2.267 1.02.629 2.192 1.066 3.52 1.27a9.537 9.537 0 002.1.07c.738-.047 1.516-.17 2.33-.392m-7.764 2.235C7.574 25.106 3.912 26.41.36 27.98m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.832 6.558-4.433" />
        <path d="M30.916 9.574c.384 1.83 1.55 3.887 3.432 5.344 1.884 1.449 4.474 2.29 7.797 1.69m-7.94 1.497c-3.802.783-7.59 1.63-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.536 8.536 0 012.623-2.617c1.126-.705 2.514-1.218 4.19-1.432" />
        <path d="M54.792 5.397c.267 1.851 1.293 3.978 3.079 5.55a8.747 8.747 0 003.251 1.808c1.277.398 2.748.544 4.424.33m-8.014 1.032c-3.842.554-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.86.29-4.175 1.653-6.104a8.37 8.37 0 012.745-2.487c1.158-.653 2.567-1.118 4.252-1.24" />
        <path d="M78.724 2.545c.178 1.86 1.099 4.046 2.806 5.69a8.918 8.918 0 003.161 1.964c1.257.457 2.72.653 4.404.531m-8.053.66c-3.866.35-7.725.764-11.582 1.2m34.203 6.243c-.099-1.866.467-4.16 1.911-6.031a8.398 8.398 0 012.848-2.368c1.185-.605 2.612-1.008 4.301-1.06" />
        <path d="M102.711.774c.098 1.866.925 4.089 2.56 5.803a8.9 8.9 0 003.075 2.097c1.237.507 2.689.767 4.378.715m-8.074.32l-2.908.148-2.905.173c-1.938.107-3.874.25-5.81.379m33.912 7.682c-.02-1.868.641-4.136 2.162-5.945a8.368 8.368 0 012.945-2.246c1.209-.552 2.652-.898 4.341-.878" />
        <path d="M126.744.009c.02 1.868.753 4.124 2.315 5.906a8.917 8.917 0 002.985 2.223c1.214.561 2.654.88 4.343.9m-8.08-.02c-3.881.022-7.762.109-11.642.213m33.537 9.116c.063-1.867.823-4.103 2.423-5.84 1.598-1.744 4.042-2.961 7.419-2.791" />
        <path d="M150.811.274c-.063 1.867.57 4.155 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.451m-8.07-.386c-3.879-.151-7.76-.233-11.641-.298M173.369 19.7c.158-1.861 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 1.682c-.158 1.861.361 4.178 1.746 6.105 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.814c-3.866-.35-7.738-.623-11.612-.873m32.335 12.412c.274-1.848 1.275-3.983 3.06-5.527a8.213 8.213 0 013.262-1.743c1.281-.344 2.763-.455 4.424-.142" />
        <path d="M198.966 4.452c-.273 1.848.104 4.194 1.367 6.202a9.111 9.111 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.598-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.816 1.597-3.875 3.501-5.263a8.379 8.379 0 013.393-1.45 8.3 8.3 0 012.088-.125c.736.042 1.513.168 2.329.39" />
        <path d="M222.955 8.974c-.426 1.822-.236 4.18.857 6.29a8.916 8.916 0 002.356 2.9c.521.413 1.108.799 1.765 1.14a11.4 11.4 0 002.207.845m-7.813-2.058c-1.891-.438-3.778-.894-5.678-1.287-.95-.2-1.896-.418-2.848-.603l-2.856-.566m29.529 17.308c.643-1.754 2.037-3.65 4.098-4.803a8.146 8.146 0 013.537-.998 9.002 9.002 0 011.022.006c.345.03.699.08 1.06.153.722.145 1.475.38 2.25.714" />
        <path d="M246.699 15.965c-.643 1.754-.73 4.134.102 6.356a9.168 9.168 0 002.718 3.89c.265.232.547.457.85.67.606.428 1.29.814 2.066 1.148m-7.467-3.085l-2.73-1.01-2.754-.944c-.915-.324-1.843-.608-2.768-.903-.926-.289-1.846-.6-2.78-.865M12.715 53.779a9.24 9.24 0 01-.445-3.003 8.766 8.766 0 01.677-3.295 8.019 8.019 0 012.155-2.988 9.305 9.305 0 011.727-1.18c.654-.341 1.388-.624 2.203-.846" />
        <path d="M7.055 36.604c.292.887.792 1.81 1.472 2.663.68.85 1.54 1.638 2.559 2.267 1.02.628 2.192 1.065 3.52 1.268a9.537 9.537 0 002.1.072c.738-.048 1.516-.17 2.33-.393m-7.764 2.235C7.574 45.896 3.912 47.2.36 48.77m34.271-.707c-.383-1.827-.19-4.19.953-6.246 1.138-2.065 3.23-3.833 6.558-4.434" />
        <path d="M30.916 30.364c.384 1.83 1.55 3.886 3.432 5.344 1.884 1.448 4.474 2.29 7.797 1.69m-7.94 1.496c-3.802.784-7.59 1.631-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.176a8.535 8.535 0 012.623-2.617c1.126-.706 2.514-1.218 4.19-1.432" />
        <path d="M54.792 26.186c.267 1.851 1.293 3.979 3.079 5.55a8.749 8.749 0 003.251 1.809c1.277.397 2.748.543 4.424.329m-8.014 1.033c-3.842.553-7.681 1.124-11.506 1.784m34.418 4.645c-.178-1.86.29-4.175 1.653-6.105a8.37 8.37 0 012.745-2.487c1.158-.652 2.567-1.118 4.252-1.24" />
        <path d="M78.724 23.334c.178 1.86 1.099 4.046 2.806 5.691a8.919 8.919 0 003.161 1.964c1.257.456 2.72.653 4.404.53m-8.053.66c-3.866.35-7.725.765-11.582 1.201m34.203 6.242c-.099-1.865.467-4.16 1.911-6.031a8.398 8.398 0 012.848-2.368c1.185-.605 2.612-1.008 4.301-1.06" />
        <path d="M102.711 21.564c.098 1.865.925 4.088 2.56 5.803a8.899 8.899 0 003.075 2.097c1.237.506 2.689.767 4.378.714m-8.074.32l-2.908.148-2.905.173c-1.938.107-3.874.25-5.81.379m33.912 7.682c-.02-1.867.641-4.136 2.162-5.944a8.368 8.368 0 012.945-2.247c1.209-.552 2.652-.898 4.341-.878" />
        <path d="M126.744 20.798c.02 1.868.753 4.124 2.315 5.906a8.916 8.916 0 002.985 2.223c1.214.562 2.654.88 4.343.9m-8.08-.02c-3.881.022-7.762.11-11.642.213m33.537 9.116c.063-1.867.823-4.102 2.423-5.84 1.598-1.744 4.042-2.96 7.419-2.79" />
        <path d="M150.811 21.063c-.063 1.867.57 4.155 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.452m-8.07-.387c-3.879-.15-7.76-.233-11.641-.298m33.037 10.654c.158-1.862 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 22.47c-.158 1.862.361 4.18 1.746 6.106 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.814c-3.866-.349-7.738-.623-11.612-.873m32.335 12.413c.274-1.848 1.275-3.984 3.06-5.528a8.213 8.213 0 013.262-1.742c1.281-.345 2.763-.456 4.424-.143" />
        <path d="M198.966 25.241c-.273 1.848.104 4.194 1.367 6.202a9.111 9.111 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.612m-7.968-1.347c-3.835-.598-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.816 1.597-3.875 3.501-5.263a8.379 8.379 0 013.393-1.45 8.3 8.3 0 012.088-.125c.736.042 1.513.168 2.329.39" />
        <path d="M222.955 29.764c-.426 1.821-.236 4.18.857 6.29a8.916 8.916 0 002.356 2.9c.521.413 1.108.798 1.765 1.139.659.338 1.392.623 2.207.845m-7.813-2.058c-1.891-.437-3.778-.894-5.678-1.287-.95-.2-1.896-.417-2.848-.603l-2.856-.565m29.529 17.308c.643-1.754 2.037-3.651 4.098-4.803a8.146 8.146 0 013.537-.999 9.002 9.002 0 011.022.006c.345.03.699.08 1.06.153.722.146 1.475.38 2.25.714" />
        <path d="M246.699 36.754c-.643 1.754-.73 4.134.102 6.356a9.168 9.168 0 002.718 3.89c.265.233.547.457.85.67.606.428 1.29.814 2.066 1.149m-7.467-3.086l-2.73-1.01-2.754-.944c-.915-.324-1.843-.608-2.768-.903-.926-.289-1.846-.6-2.78-.865M12.715 74.568a9.24 9.24 0 01-.445-3.003 8.766 8.766 0 01.677-3.295 8.019 8.019 0 012.155-2.988 9.305 9.305 0 011.727-1.18 11.08 11.08 0 012.203-.846" />
        <path d="M7.055 57.393c.292.887.792 1.81 1.472 2.663.68.85 1.54 1.638 2.559 2.267 1.02.629 2.192 1.065 3.52 1.269a9.537 9.537 0 002.1.071c.738-.047 1.516-.17 2.33-.392m-7.764 2.234C7.574 66.686 3.912 67.99.36 69.56m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.833 6.558-4.434" />
        <path d="M30.916 51.153c.384 1.83 1.55 3.886 3.432 5.344 1.884 1.448 4.474 2.29 7.797 1.69m-7.94 1.496c-3.802.784-7.59 1.632-11.358 2.569m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.535 8.535 0 012.623-2.617c1.126-.705 2.514-1.218 4.19-1.432" />
        <path d="M54.792 46.976c.267 1.85 1.293 3.978 3.079 5.549a8.749 8.749 0 003.251 1.809c1.277.397 2.748.544 4.424.33m-8.014 1.032c-3.842.553-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.86.29-4.175 1.653-6.105a8.37 8.37 0 012.745-2.487c1.158-.652 2.567-1.118 4.252-1.24" />
        <path d="M78.724 44.123c.178 1.86 1.099 4.047 2.806 5.691a8.919 8.919 0 003.161 1.964c1.257.456 2.72.653 4.404.53m-8.053.66c-3.866.35-7.725.765-11.582 1.201m34.203 6.242c-.099-1.865.467-4.16 1.911-6.03a8.398 8.398 0 012.848-2.368c1.185-.606 2.612-1.009 4.301-1.061" />
        <path d="M102.711 42.353c.098 1.865.925 4.089 2.56 5.803a8.899 8.899 0 003.075 2.097c1.237.506 2.689.767 4.378.715m-8.074.32l-2.908.148-2.905.172c-1.938.108-3.874.25-5.81.38m33.912 7.682c-.02-1.868.641-4.136 2.162-5.945a8.368 8.368 0 012.945-2.247c1.209-.552 2.652-.898 4.341-.878" />
        <path d="M126.744 41.587c.02 1.868.753 4.124 2.315 5.906a8.917 8.917 0 002.985 2.224c1.214.561 2.654.879 4.343.899m-8.08-.02c-3.881.022-7.762.11-11.642.214m33.537 9.116c.063-1.867.823-4.103 2.423-5.84 1.598-1.745 4.042-2.962 7.419-2.792" />
        <path d="M150.811 41.852c-.063 1.868.57 4.155 2.052 6.007 1.484 1.844 3.808 3.281 7.18 3.451m-8.07-.387c-3.879-.15-7.76-.233-11.641-.298m33.037 10.654c.158-1.862 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 43.26c-.158 1.862.361 4.179 1.746 6.105 1.389 1.92 3.634 3.475 6.991 3.828m-8.038-.813c-3.866-.35-7.738-.623-11.612-.873m32.335 12.412c.274-1.848 1.275-3.984 3.06-5.528a8.212 8.212 0 013.262-1.742c1.281-.345 2.763-.456 4.424-.143" />
        <path d="M198.966 46.03c-.273 1.849.104 4.194 1.367 6.203a9.111 9.111 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.599-7.685-1.094-11.538-1.568m31.278 14.553c.425-1.817 1.597-3.876 3.501-5.263a8.379 8.379 0 013.393-1.45 8.296 8.296 0 012.088-.126c.736.043 1.513.168 2.329.39" />
        <path d="M222.955 50.553c-.426 1.822-.236 4.18.857 6.29a8.916 8.916 0 002.356 2.9c.521.413 1.108.798 1.765 1.14.659.337 1.392.622 2.207.845m-7.813-2.058c-1.891-.438-3.778-.894-5.678-1.288-.95-.2-1.896-.417-2.848-.602l-2.856-.566m29.529 17.308c.643-1.754 2.037-3.65 4.098-4.803a8.146 8.146 0 013.537-.998 8.978 8.978 0 011.022.005c.345.03.699.08 1.06.153.722.146 1.475.38 2.25.715" />
        <path d="M246.699 57.543c-.643 1.755-.73 4.134.102 6.357a9.168 9.168 0 002.718 3.89c.265.232.547.457.85.67.606.427 1.29.813 2.066 1.148m-7.467-3.086l-2.73-1.01-2.754-.944c-.915-.323-1.843-.608-2.768-.903-.926-.288-1.846-.6-2.78-.864M12.715 95.358a9.24 9.24 0 01-.445-3.003 8.766 8.766 0 01.677-3.295 8.019 8.019 0 012.155-2.988 9.305 9.305 0 011.727-1.181 11.08 11.08 0 012.203-.846" />
        <path d="M7.055 78.183c.292.887.792 1.809 1.472 2.662.68.85 1.54 1.639 2.559 2.267 1.02.629 2.192 1.066 3.52 1.269a9.53 9.53 0 002.1.071c.738-.047 1.516-.17 2.33-.392m-7.764 2.235C7.574 87.474 3.912 88.779.36 90.348m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.833 6.558-4.433" />
        <path d="M30.916 71.942c.384 1.83 1.55 3.887 3.432 5.344 1.884 1.449 4.474 2.29 7.797 1.69m-7.94 1.497c-3.802.783-7.59 1.63-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.537 8.537 0 012.623-2.617c1.126-.705 2.514-1.218 4.19-1.432" />
        <path d="M54.792 67.765c.267 1.851 1.293 3.978 3.079 5.549a8.749 8.749 0 003.251 1.81c1.277.397 2.748.543 4.424.329m-8.014 1.032c-3.842.554-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.86.29-4.175 1.653-6.104a8.37 8.37 0 012.745-2.488c1.158-.652 2.567-1.117 4.252-1.24" />
        <path d="M78.724 64.913c.178 1.86 1.099 4.046 2.806 5.69a8.919 8.919 0 003.161 1.964c1.257.457 2.72.653 4.404.531m-8.053.66c-3.866.35-7.725.764-11.582 1.2m34.203 6.243c-.099-1.866.467-4.16 1.911-6.032a8.398 8.398 0 012.848-2.367c1.185-.605 2.612-1.009 4.301-1.06" />
        <path d="M102.711 63.142c.098 1.866.925 4.089 2.56 5.803a8.9 8.9 0 003.075 2.097c1.237.506 2.689.767 4.378.715m-8.074.32l-2.908.148-2.905.173c-1.938.107-3.874.25-5.81.378m33.912 7.683c-.02-1.868.641-4.136 2.162-5.945a8.368 8.368 0 012.945-2.246c1.209-.553 2.652-.899 4.341-.879" />
        <path d="M126.744 62.377c.02 1.868.753 4.124 2.315 5.906a8.917 8.917 0 002.985 2.223c1.214.561 2.654.88 4.343.899m-8.08-.02c-3.881.022-7.762.11-11.642.214m33.537 9.116c.063-1.867.823-4.103 2.423-5.84 1.598-1.744 4.042-2.961 7.419-2.791" />
        <path d="M150.811 62.642c-.063 1.867.57 4.154 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.451m-8.07-.386c-3.879-.151-7.76-.233-11.641-.298m33.037 10.653c.158-1.861 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.747 7.552-2.394" />
        <path d="M174.896 64.05c-.158 1.86.361 4.178 1.746 6.105 1.389 1.919 3.634 3.474 6.991 3.828m-8.038-.814c-3.866-.35-7.738-.623-11.612-.873m32.335 12.412c.274-1.848 1.275-3.983 3.06-5.528a8.21 8.21 0 013.262-1.742c1.281-.344 2.763-.455 4.424-.143" />
        <path d="M198.966 66.82c-.273 1.848.104 4.194 1.367 6.202a9.111 9.111 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.599-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.817 1.597-3.876 3.501-5.263a8.379 8.379 0 013.393-1.45 8.3 8.3 0 012.088-.125c.736.042 1.513.168 2.329.39" />
        <path d="M222.955 71.342c-.426 1.822-.236 4.18.857 6.29a8.917 8.917 0 002.356 2.9c.521.413 1.108.798 1.765 1.14a11.42 11.42 0 002.207.845m-7.813-2.058c-1.891-.438-3.778-.894-5.678-1.288-.95-.2-1.896-.417-2.848-.602l-2.856-.566m29.529 17.308c.643-1.754 2.037-3.65 4.098-4.803a8.147 8.147 0 013.537-.998 8.978 8.978 0 011.022.006c.345.029.699.08 1.06.153.722.145 1.475.38 2.25.714" />
        <path d="M246.699 78.333c-.643 1.754-.73 4.134.102 6.356a9.168 9.168 0 002.718 3.89c.265.232.547.457.85.67.606.428 1.29.814 2.066 1.148m-7.467-3.085l-2.73-1.01-2.754-.944c-.915-.324-1.843-.608-2.768-.903-.926-.289-1.846-.6-2.78-.865M12.715 116.147a9.239 9.239 0 01-.445-3.003 8.761 8.761 0 01.677-3.295 8.013 8.013 0 012.155-2.988 9.296 9.296 0 011.727-1.181 11.093 11.093 0 012.203-.846" />
        <path d="M7.055 98.972c.292.887.792 1.809 1.472 2.662.68.85 1.54 1.639 2.559 2.267a9.328 9.328 0 003.52 1.269 9.548 9.548 0 002.1.072c.738-.048 1.516-.17 2.33-.393m-7.764 2.235c-3.698 1.179-7.36 2.484-10.912 4.053m34.271-.708c-.383-1.827-.19-4.19.953-6.245 1.138-2.065 3.23-3.833 6.558-4.434" />
        <path d="M30.916 92.732c.384 1.83 1.55 3.886 3.432 5.344 1.884 1.448 4.474 2.289 7.797 1.69m-7.94 1.496c-3.802.784-7.59 1.631-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.54 8.54 0 012.623-2.616c1.126-.706 2.514-1.218 4.19-1.433" />
        <path d="M54.792 88.554c.267 1.851 1.293 3.979 3.079 5.55a8.749 8.749 0 003.251 1.808c1.277.398 2.748.544 4.424.33m-8.014 1.032c-3.842.554-7.681 1.125-11.506 1.784m34.418 4.646c-.178-1.86.29-4.176 1.653-6.105a8.37 8.37 0 012.745-2.487c1.158-.653 2.567-1.118 4.252-1.24" />
        <path d="M78.724 85.702c.178 1.86 1.099 4.046 2.806 5.69a8.919 8.919 0 003.161 1.965c1.257.456 2.72.653 4.404.53m-8.053.66c-3.866.35-7.725.765-11.582 1.2m34.203 6.243c-.099-1.865.467-4.16 1.911-6.031a8.398 8.398 0 012.848-2.368c1.185-.605 2.612-1.008 4.301-1.06" />
        <path d="M102.711 83.931c.098 1.866.925 4.09 2.56 5.803a8.899 8.899 0 003.075 2.097c1.237.507 2.689.767 4.378.715m-8.074.32l-2.908.148-2.905.173c-1.938.107-3.874.25-5.81.379m33.912 7.682c-.02-1.867.641-4.136 2.162-5.944a8.368 8.368 0 012.945-2.247c1.209-.552 2.652-.898 4.341-.878" />
        <path d="M126.744 83.166c.02 1.868.753 4.124 2.315 5.906a8.917 8.917 0 002.985 2.223c1.214.562 2.654.88 4.343.9m-8.08-.02c-3.881.022-7.762.109-11.642.213m33.537 9.116c.063-1.867.823-4.102 2.423-5.84 1.598-1.744 4.042-2.961 7.419-2.79" />
        <path d="M150.811 83.431c-.063 1.867.57 4.155 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.452m-8.07-.387c-3.879-.15-7.76-.233-11.641-.298m33.037 10.654c.158-1.862 1.028-4.056 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 84.838c-.158 1.862.361 4.18 1.746 6.106 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.814a453.29 453.29 0 00-11.612-.873m32.335 12.413c.274-1.848 1.275-3.984 3.06-5.528a8.209 8.209 0 013.262-1.743c1.281-.344 2.763-.455 4.424-.142" />
        <path d="M198.966 87.61c-.273 1.847.104 4.193 1.367 6.201a9.111 9.111 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.598-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.816 1.597-3.875 3.501-5.263a8.382 8.382 0 013.393-1.449 8.291 8.291 0 012.088-.126 11.33 11.33 0 012.329.39" />
        <path d="M222.955 92.132c-.426 1.821-.236 4.18.857 6.29a8.912 8.912 0 002.356 2.899 10.91 10.91 0 001.765 1.14c.659.337 1.392.623 2.207.845m-7.813-2.058c-1.891-.437-3.778-.894-5.678-1.287-.95-.2-1.896-.417-2.848-.603l-2.856-.566m29.529 17.309c.643-1.755 2.037-3.651 4.098-4.804a8.16 8.16 0 013.537-.998 8.956 8.956 0 011.022.006c.345.029.699.08 1.06.153.722.145 1.475.38 2.25.714" />
        <path d="M246.699 99.122c-.643 1.754-.73 4.134.102 6.356a9.17 9.17 0 002.718 3.89c.265.233.547.457.85.671.606.427 1.29.813 2.066 1.148m-7.467-3.086l-2.73-1.01-2.754-.944c-.915-.324-1.843-.608-2.768-.903-.926-.289-1.846-.601-2.78-.865M12.715 136.936a9.239 9.239 0 01-.445-3.003 8.769 8.769 0 01.677-3.295c.455-1.075 1.15-2.127 2.155-2.988a9.296 9.296 0 011.727-1.181c.654-.34 1.388-.623 2.203-.845" />
        <path d="M7.055 119.761c.292.887.792 1.81 1.472 2.663.68.849 1.54 1.639 2.559 2.267 1.02.629 2.192 1.065 3.52 1.269a9.55 9.55 0 002.1.071c.738-.048 1.516-.17 2.33-.393m-7.764 2.235c-3.698 1.179-7.36 2.485-10.912 4.054m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.833 6.558-4.434" />
        <path d="M30.916 113.521c.384 1.83 1.55 3.886 3.432 5.344 1.884 1.448 4.474 2.289 7.797 1.69m-7.94 1.496c-3.802.784-7.59 1.631-11.358 2.569m34.53 2.621c-.267-1.847.083-4.193 1.354-6.176a8.532 8.532 0 012.623-2.617c1.126-.705 2.514-1.218 4.19-1.432" />
        <path d="M54.792 109.344c.267 1.851 1.293 3.978 3.079 5.549a8.751 8.751 0 003.251 1.809c1.277.397 2.748.544 4.424.329m-8.014 1.033c-3.842.553-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.86.29-4.175 1.653-6.105a8.367 8.367 0 012.745-2.487c1.158-.652 2.567-1.118 4.252-1.24" />
        <path d="M78.724 106.491c.178 1.86 1.099 4.047 2.806 5.691a8.932 8.932 0 003.161 1.964c1.257.456 2.72.653 4.404.531m-8.053.659c-3.866.351-7.725.765-11.582 1.201m34.203 6.242c-.099-1.865.467-4.16 1.911-6.031a8.387 8.387 0 012.848-2.367c1.185-.606 2.612-1.009 4.301-1.061" />
        <path d="M102.711 104.721c.098 1.865.925 4.088 2.56 5.803.818.854 1.838 1.591 3.075 2.097s2.689.767 4.378.714m-8.074.32l-2.908.149-2.905.172c-1.938.108-3.874.251-5.81.379m33.912 7.683c-.02-1.868.641-4.136 2.162-5.945a8.366 8.366 0 012.945-2.247c1.209-.552 2.652-.898 4.341-.878" />
        <path d="M126.744 103.955c.02 1.868.753 4.124 2.315 5.906a8.92 8.92 0 002.985 2.224c1.214.561 2.654.879 4.343.898m-8.08-.019c-3.881.022-7.762.109-11.642.214m33.537 9.116c.063-1.867.823-4.103 2.423-5.841 1.598-1.744 4.042-2.961 7.419-2.791" />
        <path d="M150.811 104.22c-.063 1.867.57 4.155 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.452m-8.07-.387c-3.879-.151-7.76-.233-11.641-.298m33.037 10.654c.158-1.862 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 105.628c-.158 1.861.361 4.179 1.746 6.105 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.813c-3.866-.35-7.738-.623-11.612-.873m32.335 12.412c.274-1.848 1.275-3.984 3.06-5.528.893-.775 1.981-1.397 3.262-1.742s2.763-.456 4.424-.143" />
        <path d="M198.966 108.398c-.273 1.848.104 4.194 1.367 6.203a9.113 9.113 0 002.593 2.677c1.107.756 2.475 1.299 4.136 1.612m-7.968-1.347c-3.835-.598-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.816 1.597-3.875 3.501-5.262a8.37 8.37 0 013.393-1.45 8.291 8.291 0 012.088-.126 11.33 11.33 0 012.329.39" />
        <path d="M222.955 112.921c-.426 1.821-.236 4.18.857 6.29a8.904 8.904 0 002.356 2.899 10.91 10.91 0 001.765 1.14c.659.337 1.392.623 2.207.845m-7.813-2.057c-1.891-.438-3.778-.894-5.678-1.288-.95-.2-1.896-.417-2.848-.603l-2.856-.565m29.529 17.308c.643-1.754 2.037-3.651 4.098-4.803a8.134 8.134 0 013.537-.998 8.952 8.952 0 011.022.005c.345.03.699.081 1.06.153.722.146 1.475.38 2.25.714" />
        <path d="M246.699 119.911c-.643 1.754-.73 4.134.102 6.357a9.175 9.175 0 002.718 3.89c.265.232.547.456.85.67.606.427 1.29.813 2.066 1.148m-7.467-3.086l-2.73-1.01-2.754-.944c-.915-.323-1.843-.608-2.768-.903-.926-.288-1.846-.6-2.78-.864M12.715 157.725a9.239 9.239 0 01-.445-3.003 8.769 8.769 0 01.677-3.295 8.029 8.029 0 012.155-2.988 9.341 9.341 0 011.727-1.18 11.032 11.032 0 012.203-.846" />
        <path d="M7.055 140.55c.292.888.792 1.81 1.472 2.663.68.85 1.54 1.639 2.559 2.267 1.02.629 2.192 1.065 3.52 1.269a9.489 9.489 0 002.1.071 11.592 11.592 0 002.33-.392m-7.764 2.235c-3.698 1.179-7.36 2.484-10.912 4.053m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.833 6.558-4.433" />
        <path d="M30.916 134.31c.384 1.83 1.55 3.886 3.432 5.344 1.884 1.449 4.474 2.29 7.797 1.69m-7.94 1.497c-3.802.783-7.59 1.631-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.53 8.53 0 012.623-2.616c1.126-.706 2.514-1.219 4.19-1.433" />
        <path d="M54.792 130.133c.267 1.851 1.293 3.978 3.079 5.549a8.751 8.751 0 003.251 1.809c1.277.398 2.748.544 4.424.329m-8.014 1.033c-3.842.553-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.859.29-4.175 1.653-6.105a8.378 8.378 0 012.745-2.487c1.158-.652 2.567-1.118 4.252-1.239" />
        <path d="M78.724 127.281c.178 1.859 1.099 4.046 2.806 5.69a8.904 8.904 0 003.161 1.964c1.257.456 2.72.653 4.404.531m-8.053.66c-3.866.35-7.725.764-11.582 1.2m34.203 6.242c-.099-1.865.467-4.159 1.911-6.031a8.398 8.398 0 012.848-2.367c1.185-.605 2.612-1.009 4.301-1.061" />
        <path d="M102.711 125.51c.098 1.866.925 4.089 2.56 5.803a8.896 8.896 0 003.075 2.097c1.237.506 2.689.767 4.378.715m-8.074.319l-2.908.149-2.905.172c-1.938.108-3.874.251-5.81.379m33.912 7.683c-.02-1.868.641-4.136 2.162-5.945a8.363 8.363 0 012.945-2.246c1.209-.553 2.652-.899 4.341-.879" />
        <path d="M126.744 124.745c.02 1.868.753 4.124 2.315 5.906a8.93 8.93 0 002.985 2.223c1.214.561 2.654.879 4.343.899m-8.08-.02c-3.881.022-7.762.11-11.642.214m33.537 9.116c.063-1.867.823-4.103 2.423-5.84 1.598-1.744 4.042-2.961 7.419-2.791" />
        <path d="M150.811 125.01c-.063 1.867.57 4.154 2.052 6.006 1.484 1.844 3.808 3.281 7.18 3.451m-8.07-.387c-3.879-.15-7.76-.232-11.641-.297m33.037 10.653c.158-1.862 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.748 7.552-2.394" />
        <path d="M174.896 126.417c-.158 1.862.361 4.179 1.746 6.105 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.813a455.11 455.11 0 00-11.612-.873m32.335 12.412c.274-1.848 1.275-3.983 3.06-5.528a8.209 8.209 0 013.262-1.742c1.281-.344 2.763-.456 4.424-.143" />
        <path d="M198.966 129.188c-.273 1.848.104 4.193 1.367 6.202a9.116 9.116 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.599-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.817 1.597-3.876 3.501-5.263a8.383 8.383 0 013.393-1.45c.656-.126 1.352-.171 2.088-.126.736.043 1.513.169 2.329.391" />
        <path d="M222.955 133.71c-.426 1.822-.236 4.18.857 6.291a8.924 8.924 0 002.356 2.899c.521.413 1.108.798 1.765 1.139.659.338 1.392.624 2.207.846m-7.813-2.058c-1.891-.438-3.778-.894-5.678-1.288-.95-.199-1.896-.417-2.848-.602l-2.856-.566m29.529 17.308c.643-1.754 2.037-3.651 4.098-4.803a8.134 8.134 0 013.537-.998 8.956 8.956 0 011.022.006c.345.029.699.08 1.06.153a11.27 11.27 0 012.25.714" />
        <path d="M246.699 140.701c-.643 1.754-.73 4.134.102 6.356a9.157 9.157 0 002.718 3.89c.265.232.547.457.85.67.606.428 1.29.814 2.066 1.148m-7.467-3.085l-2.73-1.01-2.754-.945c-.915-.323-1.843-.607-2.768-.903-.926-.288-1.846-.6-2.78-.864M12.715 178.515a9.239 9.239 0 01-.445-3.003 8.761 8.761 0 01.677-3.295 8.013 8.013 0 012.155-2.988 9.296 9.296 0 011.727-1.181 11.093 11.093 0 012.203-.846" />
        <path d="M7.055 161.34c.292.887.792 1.809 1.472 2.662.68.85 1.54 1.639 2.559 2.267a9.328 9.328 0 003.52 1.269 9.548 9.548 0 002.1.072c.738-.048 1.516-.17 2.33-.393m-7.764 2.235c-3.698 1.179-7.36 2.484-10.912 4.053m34.271-.708c-.383-1.827-.19-4.19.953-6.246 1.138-2.064 3.23-3.832 6.558-4.433" />
        <path d="M30.916 155.099c.384 1.83 1.55 3.887 3.432 5.344 1.884 1.449 4.474 2.29 7.797 1.69m-7.94 1.497c-3.802.783-7.59 1.631-11.358 2.568m34.53 2.622c-.267-1.847.083-4.194 1.354-6.177a8.53 8.53 0 012.623-2.616c1.126-.706 2.514-1.218 4.19-1.433" />
        <path d="M54.792 150.922c.267 1.851 1.293 3.978 3.079 5.549a8.737 8.737 0 003.251 1.809c1.277.398 2.748.544 4.424.33m-8.014 1.032c-3.842.554-7.681 1.125-11.506 1.784m34.418 4.645c-.178-1.859.29-4.175 1.653-6.104a8.367 8.367 0 012.745-2.487c1.158-.653 2.567-1.118 4.252-1.24" />
        <path d="M78.724 148.07c.178 1.86 1.099 4.046 2.806 5.691a8.916 8.916 0 003.161 1.963c1.257.457 2.72.653 4.404.531m-8.053.66c-3.866.35-7.725.764-11.582 1.201m34.203 6.242c-.099-1.866.467-4.16 1.911-6.032a8.41 8.41 0 012.848-2.367c1.185-.605 2.612-1.008 4.301-1.061" />
        <path d="M102.711 146.299c.098 1.866.925 4.089 2.56 5.803a8.896 8.896 0 003.075 2.097c1.237.507 2.689.767 4.378.715m-8.074.32l-2.908.148-2.905.173c-1.938.107-3.874.25-5.81.379m33.912 7.682c-.02-1.868.641-4.136 2.162-5.944a8.366 8.366 0 012.945-2.247c1.209-.552 2.652-.899 4.341-.878" />
        <path d="M126.744 145.534c.02 1.868.753 4.124 2.315 5.906a8.917 8.917 0 002.985 2.223c1.214.561 2.654.879 4.343.899m-8.08-.02c-3.881.023-7.762.11-11.642.214m33.537 9.116c.063-1.867.823-4.102 2.423-5.84 1.598-1.744 4.042-2.961 7.419-2.791" />
        <path d="M150.811 145.799c-.063 1.867.57 4.155 2.052 6.006 1.484 1.845 3.808 3.282 7.18 3.451m-8.07-.386c-3.879-.151-7.76-.233-11.641-.298m33.037 10.653c.158-1.861 1.028-4.055 2.714-5.707 1.684-1.66 4.189-2.747 7.552-2.394" />
        <path d="M174.896 147.206c-.158 1.862.361 4.179 1.746 6.106 1.389 1.919 3.634 3.475 6.991 3.828m-8.038-.814a455.11 455.11 0 00-11.612-.873m32.335 12.412c.274-1.848 1.275-3.983 3.06-5.527a8.21 8.21 0 013.262-1.743c1.281-.344 2.763-.455 4.424-.142" />
        <path d="M198.966 149.977c-.273 1.848.104 4.194 1.367 6.202a9.104 9.104 0 002.593 2.678c1.107.755 2.475 1.298 4.136 1.611m-7.968-1.346c-3.835-.598-7.685-1.093-11.538-1.567m31.278 14.552c.425-1.816 1.597-3.875 3.501-5.263a8.382 8.382 0 013.393-1.449 8.245 8.245 0 012.088-.126 11.33 11.33 0 012.329.39" />
        <path d="M222.955 154.5c-.426 1.821-.236 4.179.857 6.29a8.924 8.924 0 002.356 2.899 10.91 10.91 0 001.765 1.14c.659.337 1.392.623 2.207.845m-7.813-2.058c-1.891-.438-3.778-.894-5.678-1.287-.95-.2-1.896-.418-2.848-.603l-2.856-.566m29.529 17.308c.643-1.754 2.037-3.65 4.098-4.803a8.16 8.16 0 013.537-.998 8.956 8.956 0 011.022.006c.345.029.699.08 1.06.153a11.27 11.27 0 012.25.714" />
        <path d="M246.699 161.49c-.643 1.754-.73 4.134.102 6.356a9.17 9.17 0 002.718 3.89c.265.232.547.457.85.67.606.428 1.29.814 2.066 1.148m-7.467-3.085l-2.73-1.01-2.754-.944c-.915-.324-1.843-.608-2.768-.903-.926-.289-1.846-.601-2.78-.865" />
      </g>
    </svg>
  );
}

export default SvgArrow2;
