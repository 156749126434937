import invariant from 'tiny-invariant';
import * as Y from 'yjs';
import { SyncNode } from '../model';
import { toSlateDoc } from '../utils/convert';
const isTree = (node) => !!SyncNode.getChildren(node);
/**
 * Returns the SyncNode referenced by the path
 *
 * @param doc
 * @param path
 */
export function getTarget(doc, path) {
    function iterate(current, idx) {
        const children = SyncNode.getChildren(current);
        if (!isTree(current) || !(children === null || children === void 0 ? void 0 : children.get(idx))) {
            throw new TypeError(`path ${path.toString()} does not match doc ${JSON.stringify(toSlateDoc(doc))}`);
        }
        return children.get(idx);
    }
    return path.reduce(iterate, doc);
}
function getParentPath(path, level = 1) {
    if (level > path.length) {
        throw new TypeError('requested ancestor is higher than root');
    }
    return [path[path.length - level], path.slice(0, path.length - level)];
}
export function getParent(doc, path, level = 1) {
    const [idx, parentPath] = getParentPath(path, level);
    const parent = getTarget(doc, parentPath);
    invariant(parent, 'Parent node should exists');
    return [parent, idx];
}
/**
 * Returns the position of the sync item inside inside it's parent array.
 *
 * @param item
 */
export function getArrayPosition(item) {
    let i = 0;
    let c = item.parent._start;
    while (c !== item && c !== null) {
        if (!c.deleted) {
            i += 1;
        }
        c = c.right;
    }
    return i;
}
/**
 * Returns the document path of a sync item
 *
 * @param node
 */
export function getSyncNodePath(node) {
    if (!node) {
        return [];
    }
    const { parent } = node;
    if (!parent) {
        return [];
    }
    if (parent instanceof Y.Array) {
        invariant(node._item, 'Parent should be associated with a item');
        return [...getSyncNodePath(parent), getArrayPosition(node._item)];
    }
    if (parent instanceof Y.Map) {
        return getSyncNodePath(parent);
    }
    throw new Error(`Unknown parent type ${parent}`);
}
