import React, { useState, useRef } from 'react';
import { useField } from 'formik';

import { SecretEye } from 'frameworks/formik/Input';

export type ExtendedInputProps = {
  name: string;
  inputType: 'text' | 'password' | 'email';
  label?: string;
  className?: string;
  onChange?: (value: any) => void;
  autoComplete?: string;
};

const ExtendedInput = ({
  name,
  inputType,
  label = '',
  className = '',
  onChange,
  autoComplete,
}: ExtendedInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [field, meta, helpers] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const hasError = meta.touched && meta.error;
  const isSecret = inputType === 'password';

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  return (
    <>
      <div
        className={`flex w-full content-start items-center rounded-md border px-4 py-2 focus-within:border-primary md:px-4 md:py-2 ${className} ${
          hasError ? 'border-danger' : 'border-gray-300'
        }`}
      >
        <div
          className="w-full text-black-soft focus-within:text-primary"
          onClick={focusInput}
        >
          <label
            htmlFor={name}
            className={'w-full text-xs font-light md:text-sm 3xl:text-base'}
          >
            {label}
          </label>
          <input
            id={name}
            type={isSecret && showPassword ? 'text' : inputType}
            ref={inputRef}
            className="w-full rounded-none border-none text-base text-black outline-none md:text-xl 3xl:text-2xl"
            {...field}
            autoComplete={autoComplete}
            onChange={(e) => {
              helpers.setValue(e.target.value, true);
              if (onChange) onChange(e.target.value);
            }}
          />
        </div>
        {isSecret ? (
          <SecretEye
            eyeState={!showPassword}
            onClick={() => setShowPassword((state) => !state)}
          />
        ) : null}
      </div>
      {hasError ? (
        <div className="mt-2 font-medium text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

export default ExtendedInput;
