import React, { useEffect, useState, useMemo, ReactNode } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from './Layout';
import { domainUrl } from 'config';

import { verifyPasswordResetCode } from 'model/users';
import Alert from 'components/Alert';
import { gerErrorDescription } from 'services/firebase';
import Button from 'components/Button';

const Authentication = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [globalErrorMessage, setGlobalErrorMessage] = useState<
    string | null | ReactNode
  >(null);

  useEffect(() => {
    const redirect = async () => {
      const actionCode = params.get('oobCode');
      const mode = params.get('mode');
      const continueUrl = params.get('continueUrl');

      if (mode === 'resetPassword' && actionCode) {
        try {
          const userEmail = await verifyPasswordResetCode(actionCode);

          history.push({
            pathname: '/reset-password',
            search: `${
              continueUrl
                ? `?continueUrl=${encodeURIComponent(
                    continueUrl.replace(domainUrl, '')
                  )}`
                : ''
            }`,
            state: {
              email: userEmail,
              actionCode,
              from: {
                pathname: continueUrl?.replace(domainUrl, ''),
              },
            },
          });
        } catch (err) {
          console.log(err);
          const error = err as any;
          setGlobalErrorMessage(gerErrorDescription(error.code));
        }
      }
    };
    redirect();
  }, [params, history]);

  const links = {
    signIn: {
      text: t('login:linkSignin'),
      to: {
        pathname: '/login',
        state: {},
      },
    },
  };

  return (
    <Layout
      title={t('login:authenticationTitle')}
      subtitleText={t('login:linkAuthenticationDescription')}
      subtitleLink={links.signIn}
    >
      <div className="mt-6 md:mt-10">
        {globalErrorMessage ? (
          <Alert
            title={t('login:authenticationErrorOccured')}
            message={<div>{globalErrorMessage}</div>}
            type="error"
            className="mb-4"
            actionButton={
              <Button
                text={t('common:Retry')}
                onClick={() => history.push('/forget-password')}
              />
            }
          />
        ) : (
          <>
            <h3 className="text-lg font-semibold uppercase text-primary">
              {t('login:pleaseWait')}
            </h3>
            <p className="text-xl">{t('login:authenticationOnGoing')}</p>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Authentication;
