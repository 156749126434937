import Alert from 'components/Alert';
import Button from 'components/Button';
import Link from 'components/Link';
import UploadButton, { UploadedFailedReason } from 'components/UploadButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';

const SYNTHESIS_TEMPLATE_URL =
  'https://drive.google.com/drive/folders/1g7qFxGaBLg-X2q2mxnt_xWiLIc_tp9CR?usp=share_link';

export type DebriefFacilitatorInfoProps = {
  sessionId: string;
  synthesisUrl: Nullable<string>;
  setSynthesisUrl: (url: string | null) => void;
  marginClasses: string;
};

const DebriefFacilitatorInfo = ({
  sessionId,
  synthesisUrl,
  setSynthesisUrl,
  marginClasses,
}: DebriefFacilitatorInfoProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<UploadedFailedReason | null>(null);

  const errorToText = () => {
    switch (error) {
      case 'mimeTypeMismatch':
        return t('common:UploadedFileMustBeOfPDFType');
      default:
        return t('common:AnErrorOccurredUploadingFile');
    }
  };

  const uploadedFile = (fileUrl: string) => {
    setError(null);
    setSynthesisUrl(fileUrl);
  };

  return (
    <div className={`${marginClasses} space-y-2`}>
      {!synthesisUrl ? (
        <Alert
          className="p-4 mx-12"
          type={error ? 'error' : 'info'}
          title={error ? errorToText() : t('sessions:DebriefInfoTitle')}
          message={
            <>
              <p>{t('sessions:DebriefInfoMessage')}</p>
              <Link
                external={true}
                to={{
                  pathname: SYNTHESIS_TEMPLATE_URL,
                }}
                text={t('sessions:DebriefTemplatesLink')}
              />
            </>
          }
          actionButton={
            <UploadButton
              text={t('sessions:Upload')}
              urlPrefix={`sessions/${sessionId}/debrief`}
              mimeType="application/pdf"
              fileUploadedFailed={async (errorType) => setError(errorType)}
              fileUploaded={async (fileUrl) => uploadedFile(fileUrl)}
            />
          }
        />
      ) : (
        <Alert
          className="p-4 mx-12"
          title={t('sessions:DebriefInfoTitleSynthesisPresent')}
          message={
            <>
              <p>{t('sessions:DebriefInfoMessageSynthesisPresent')}</p>
            </>
          }
          actionButton={
            <Button
              text={t('sessions:DeleteSynthesis')}
              onClick={() => setSynthesisUrl(null)}
            />
          }
        />
      )}
    </div>
  );
};

export default DebriefFacilitatorInfo;
