import React, { useState, useMemo, useRef } from 'react';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useSelectorArray } from 'services/firebase';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FixedSizeList } from 'react-window';
import { Listbox } from '@headlessui/react';

import { User } from 'types/baseTypes';

import SearchInput from 'components/SearchInput';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import RoundedButton from 'components/RoundedButton';
import { Trash } from 'assets/icons';
import { useLocalSearch } from 'services/localSearching';
import { useDeepEqualMemo } from 'utils/utils';
import Link from 'components/Link';

import { useOnChange } from 'frameworks/formik/hooks';
import { isGlobalAdmin } from 'model/users';
import { UserType } from 'types/types';

type UserSelectorConditionnalProps =
  | {
      communityId?: undefined;
      sessionId?: string;
    }
  | {
      sessionId?: undefined;
      communityId?: string;
    };

export type UserSelectorProps = {
  userId: string;
  userType: UserType;
  name: string;
  readOnly?: boolean;
  onChangeValue?: (value: Record<string, boolean>, hasError: boolean) => void;
} & UserSelectorConditionnalProps;

const UserSelector = ({
  userId,
  userType,
  name,
  readOnly,
  sessionId,
  communityId,
  onChangeValue,
}: UserSelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const [field, , helpers] = useField(name);
  const [previousData, setPreviousData] = useState<{
    previous: any;
    computed: any;
  }>({ previous: undefined, computed: undefined });

  useOnChange(name, onChangeValue);

  const containerRef = useRef<HTMLDivElement>(null);

  useFirebaseConnect([{ path: `sessionsNext/${sessionId}/communityId` }]);

  const communityIdComputed: string = useSelector(
    (state: any) =>
      communityId ||
      (sessionId &&
        state.firebase.data.sessionsNext?.[sessionId]?.communityId) ||
      ''
  );

  const loadArray =
    communityIdComputed !== ''
      ? [
          'users',
          `usersOfCommunities/${communityIdComputed}`,
          `adminsOfCommunities/${communityIdComputed}`,
        ]
      : [];

  useFirebaseConnect(loadArray);

  const [isDataLoaded, userAvailable]: [boolean, Record<string, User>] =
    useSelectorArray(
      loadArray || [],
      (data: any) => {
        if (
          _.isEqual(
            _.omit(previousData.previous, 'users'),
            _.omit(data, 'users')
          ) &&
          _.size(previousData.previous?.users) === _.size(data?.users) &&
          previousData.computed
        ) {
          return previousData.computed;
        } else {
          const communityUserId = [
            ...Object.keys(
              data.usersOfCommunities?.[communityIdComputed] || {}
            ),
            ...Object.keys(
              data.adminsOfCommunities?.[communityIdComputed] || {}
            ),
          ];

          const newUsers = isGlobalAdmin(userType)
            ? Object.entries<User>(data.users || {}).reduce<
                Record<string, User>
              >((prev, [id, user]) => {
                if (!!user.email) {
                  prev[id] = user;
                }
                return prev;
              }, {})
            : _.uniq(communityUserId).reduce<Record<string, User>>(
                (prev, id) => {
                  if (data.users) {
                    prev[id] = data.users[id];
                  }
                  return prev;
                },
                {}
              );
          setPreviousData({ previous: data, computed: newUsers });
          return newUsers;
        }
      },
      (value) => value !== undefined
    );

  const searchKeys: (keyof User)[] = useMemo(() => ['email', 'name'], []);

  const userAvailableMemoize = useDeepEqualMemo(userAvailable) || {};

  const filteredUsers = useLocalSearch(
    userAvailableMemoize,
    searchKeys,
    filter
  );

  const [users, usersSize] = useMemo(() => {
    const usersTemp = Object.entries(filteredUsers || {});
    return [usersTemp, _.size(usersTemp)];
  }, [filteredUsers]);

  const fixedSizeList = useMemo(() => {
    return (
      <FixedSizeList
        height={usersSize < 6 ? usersSize * 40 : 240}
        itemCount={usersSize}
        itemSize={40}
        width={containerRef?.current?.clientWidth || 300}
        itemKey={(index) => {
          const [id] = users[index];
          return id;
        }}
      >
        {({ index, style }) => {
          const [id, user] = users[index];
          return (
            <div
              style={style}
              className="w-full cursor-pointer py-2 px-3 hover:bg-primary-soft hover:text-primary"
              onClick={() => {
                const newUsers = { ...field.value };
                newUsers[id] = true;
                helpers.setValue(newUsers);
                setFilter('');
              }}
            >
              {user.email}
            </div>
          );
        }}
      </FixedSizeList>
    );
  }, [field.value, users, usersSize, helpers]);

  return isDataLoaded ? (
    <Listbox key={JSON.stringify(field?.value)} value={''} onChange={() => {}}>
      {({ open }) => {
        return (
          <div ref={containerRef} className="relative w-full">
            {!readOnly ? (
              <>
                <Listbox.Button className="w-full">
                  {!open ? (
                    <SearchInput
                      ref={() => {
                        setFilter('');
                      }}
                      value={filter}
                      placeholder={t('common:searchMembers')}
                      onChange={setFilter}
                      hideCloseButton
                    />
                  ) : null}
                </Listbox.Button>

                <Listbox.Options className="absolute top-0 z-10 w-full bg-white">
                  <SearchInput
                    ref={(ref) => {
                      setTimeout(() => ref?.focus(), 0);
                    }}
                    value={filter}
                    placeholder={t('common:searchMembers')}
                    onChange={setFilter}
                    hideCloseButton
                  />
                  <div className="mt-1 rounded-md border shadow-lg">
                    {usersSize > 0 ? (
                      fixedSizeList
                    ) : (
                      <div className="space-y-2 py-2 px-3 text-lg">
                        <p className="">
                          {_.size(userAvailableMemoize) > 0
                            ? t('communities:NoMembersFound')
                            : t('communities:NoMembers')}
                        </p>
                        <Link
                          text={t('communities:InviteMembers')}
                          to={{
                            pathname: `/community/${communityIdComputed}/invites`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Listbox.Options>
              </>
            ) : null}

            {Object.keys(field.value || {}).map((id, index) => {
              const user = userAvailableMemoize[id];
              return user ? (
                <div key={index}>
                  <div className="flex flex-row items-center py-4 pr-5">
                    <div className="shrink-0">
                      <Avatar name={user.name} />
                    </div>
                    <div className="ml-3 flex flex-grow flex-col">
                      <h3 className="text-base font-semibold">{user.name}</h3>
                      <p className="text-sm font-light text-black-soft">
                        {user.email}
                      </p>
                    </div>
                    {!readOnly && id !== userId ? (
                      <RoundedButton
                        color="danger"
                        size="sm"
                        onClick={() => {
                          const newUsers = { ...field.value };
                          delete newUsers[id];
                          helpers.setValue(newUsers);
                        }}
                      >
                        <Trash className="stroke-2" />
                      </RoundedButton>
                    ) : null}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        );
      }}
    </Listbox>
  ) : (
    <Spinner className="h-8 w-8 text-primary" />
  );
};

export default UserSelector;
