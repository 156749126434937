import React from 'react';
import { Group, Users, User } from 'types/types';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { Typography } from 'antd';
import _ from 'lodash';
import { upperFirst } from 'utils/utils';

const { Paragraph } = Typography;

const GroupRenderer = ({ group }: { group: Group }) => {
  useFirebaseConnect(
    Object.keys(group.users).map((userId) => ({
      path: `/users/${userId}`,
    }))
  );

  const users: Users = useSelector((state: any) =>
    _.mapValues(
      group.users,
      (_val, key) =>
        state.firebase.data.users?.[key] || { name: 'Unknown user' }
    )
  );

  return (
    <Paragraph style={{ fontSize: '18px' }}>
      {Object.values(users || {})
        .map((user: User) => upperFirst(user?.name?.split(' ')[0] || 'Unknown'))
        .join(', ')}
    </Paragraph>
  );
};

export default GroupRenderer;
