import React from 'react';
import { Activity, Session } from 'types/types';
import SummaryBloc from './blocks/SummaryBloc';

export type DocumentActivityProps = {
  sessionId: string;
  session: Session;
  activity: Activity;
  summary: string | null;
  marginClasses: string;
};

const DocumentActivities = ({
  sessionId,
  session,
  activity,
  summary,
  marginClasses,
}: DocumentActivityProps): JSX.Element => {
  return (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">
        {activity.humanName || activity.name}
      </h2>
      <SummaryBloc
        sessionId={sessionId}
        session={session}
        activity={activity}
        activitySummary={summary}
      />
    </div>
  );
};

export default DocumentActivities;
