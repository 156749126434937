import moment from 'moment';
import _ from 'lodash';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import copy from 'copy-to-clipboard';

import { SessionStatusState } from 'types/baseTypes';
import { Criterium } from 'types/ui';

import {
  buildCallToPostEmail,
  buildConclusionEmail,
  buildReCallToPostEmail,
  buildRemindEventEmail,
} from 'services/mail';
import { buildCleanCriteriaOperations } from 'model/criteriaManagement';

import { URL_REGEXP } from 'utils/utils';
import {
  NB_MAX_CRITERIA,
  DEFAULT_SESSION_TYPE_VALUE,
} from 'constants/AppConfig';
import { To } from 'components/Link';
import { focus } from 'services/helpChat';
import { ButtonProps } from 'components/Button';

export type TaskViewType =
  | 'textInput'
  | 'textInputCopy'
  | 'numberInput'
  | 'textareaInput'
  | 'datetimeInput'
  | 'durationInput'
  | 'listItemInput'
  | 'sessionTypeInput'
  | 'languageInput'
  | 'editorInput'
  | 'criteria'
  | 'userSelector'
  | 'none';

export type Task = {
  name: string;
  title: string;
  layout: 'row' | 'col';
  description: string;
  viewType?: TaskViewType;
  readOnly?: boolean;
  shouldRender?: (values: any) => boolean;
  tag: SessionStatusState;
  dataBinding?: string | Record<string, string>;
  dataTransformer?: {
    get: (data: any) => any;
    set: (data: any, value: any, oldValue?: any) => any;
  };
  actions?: (
    | { type: 'redirection'; name: string; to: To; external?: boolean }
    | {
        type: 'function';
        name: string;
        execute: (context: { sessionId: string }) => Promise<void>;
        buttonProps?: Partial<ButtonProps>;
      }
  )[];
  dependencies?: { name: string; path: string }[];
  schema?: Yup.BaseSchema;
  cleanDataUpdates?: (sessionId: string, value: any) => void;
};

const CONTEXT_SESSION_ID = '@sessionId';
const CONTEXT_ACCESS_CODE = '@accessCode';

export const replaceSessionId = (path: string, sessionId: string): string => {
  return path.replace(CONTEXT_SESSION_ID, sessionId);
};

export const replaceAccessCode = (path: string, accessCode: string): string => {
  return path.replace(CONTEXT_ACCESS_CODE, accessCode);
};

export type Step = {
  name: string;
  nextStep?: SessionStatusState;
  tasks: Task[];
};

const getAllTasks = (t: TFunction): Task[] => {
  const copyButtonProps: Partial<ButtonProps> = {
    enableDoneStatus: true,
    doneText: t('sessions:copied'),
    doneColor: 'success',
  };

  return [
    {
      name: 'title',
      title: t('tasks:titleTitle'),
      layout: 'col',
      description: t('tasks:titleDescription'),
      viewType: 'textInput',
      tag: 'kickoff',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/title`,
      schema: Yup.string().required(),
    },
    {
      name: 'type',
      title: t('tasks:typeTitle'),
      layout: 'col',
      description: t('tasks:typeDescription'),
      viewType: 'sessionTypeInput',
      //isValid: (values) => values?.type !== null,
      tag: 'kickoff',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/type`,
      dataTransformer: {
        get: (value) => value || DEFAULT_SESSION_TYPE_VALUE,
        set: (data, value) => value,
      },
    },
    {
      name: 'language',
      title: t('tasks:languageTitle'),
      layout: 'col',
      description: t('tasks:languageDescription'),
      viewType: 'languageInput',
      /*isValid: (values) =>
        values?.language !== null && values.language !== 'xx',*/
      tag: 'kickoff',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/language`,
    },
    {
      name: 'participantsCount',
      title: t('tasks:participantsCountTitle'),
      layout: 'col',
      description: t('tasks:participantsCountDescription'),
      viewType: 'numberInput',
      /*isValid: (values) =>
        values?.participantsCount !== null && values.participantsCount > 0,*/
      tag: 'kickoff',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/estimatedParticipantCount`,
      schema: Yup.number()
        .typeError(t('form:MustBeANumber'))
        .min(2, t('form:NumberMinimum', { min: 2 })),
    },
    {
      name: 'successCriteria',
      title: t('tasks:successCriteriaTitle'),
      layout: 'row',
      description: t('tasks:successCriteriaDescription'),
      viewType: 'listItemInput',
      tag: 'kickoff',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/successCriteria`,
      dataTransformer: {
        get: (value) => value?.filter((value: string) => value !== '') || [],
        set: (data, values) =>
          values?.filter((value: string) => value !== '') || [],
      },
    },
    {
      name: 'scheduledAt',
      title: t('tasks:scheduledAtTitle'),
      layout: 'col',
      description: t('tasks:scheduledAtDescription'),
      viewType: 'datetimeInput',
      /*isValid: (values) => values?.scheduledAt !== null,*/
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/scheduledAt`,
      dataTransformer: {
        get: (data) => new Date(data[0]),
        set: (data, value) => {
          const duration = moment.duration(
            moment(data[1]).diff(moment(data[0]))
          );
          const startDate = value.getTime();
          const endDate = startDate + duration.asMilliseconds();
          return [startDate, endDate];
        },
      },
    },
    {
      name: 'duration',
      title: t('tasks:durationTitle'),
      layout: 'col',
      description: t('tasks:durationDescription'),
      viewType: 'durationInput',
      /*isValid: (values) =>
        values?.duration !== null &&
        (values.duration?.hours > 0 || values.duration?.minutes > 0),*/
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/scheduledAt`,
      dataTransformer: {
        get: (data) => {
          const duration = moment.duration(
            moment(data[1]).diff(moment(data[0]))
          );
          return {
            hours: duration.hours(),
            minutes: duration.minutes(),
          };
        },
        set: (data, value) => {
          const startDate = data[0];
          const endDate =
            startDate +
            value.hours * 60 * 60 * 1000 +
            value.minutes * 60 * 1000;
          return [startDate, endDate];
        },
      },
    },
    {
      name: 'location',
      title: t('tasks:locationTitle'),
      layout: 'col',
      description: t('tasks:locationDescription'),
      viewType: 'textareaInput',
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/location`,
      dependencies: [
        {
          name: 'sessionType',
          path: `sessionsNext/${CONTEXT_SESSION_ID}/type`,
        },
      ],
      shouldRender: (values) => values.sessionType !== 'digital',
    },
    {
      name: 'meetingUrl',
      title: t('tasks:meetingUrlTitle'),
      layout: 'col',
      description: t('tasks:meetingUrlDescription'),
      viewType: 'textInputCopy',
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/meetingUrl`,
      dependencies: [
        {
          name: 'sessionType',
          path: `sessionsNext/${CONTEXT_SESSION_ID}/type`,
        },
      ],
      shouldRender: (values) => {
        return values.sessionType && values.sessionType !== 'on_site';
      },
      schema: Yup.string()
        .ensure()
        .matches(URL_REGEXP, t('form:FieldMustBeValidURL')),
    },
    {
      name: 'description',
      title: t('tasks:DescriptionTitle'),
      layout: 'row',
      description: t('tasks:DescriptionDescription'),
      viewType: 'textareaInput',
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/description`,
    },
    {
      name: 'goals',
      title: t('tasks:goalsTitle'),
      layout: 'row',
      description: t('tasks:goalsDescription'),
      viewType: 'listItemInput',
      tag: 'pre_conception',
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/goals`,
      dataTransformer: {
        get: (value) => value?.filter((value: string) => value !== '') || [],
        set: (data, values) =>
          values?.filter((value: string) => value !== '') || [],
      },
    },
    {
      name: 'userResearch',
      title: t('tasks:userResearchTitle'),
      layout: 'row',
      description: t('tasks:userResearchDescription'),
      viewType: 'textareaInput',
      dataBinding: `sessionsNextData/${CONTEXT_SESSION_ID}/userResearch`,
      tag: 'pre_conception',
    },
    {
      name: 'mainQuestion',
      title: t('tasks:mainQuestionTitle'),
      layout: 'row',
      description: t('tasks:mainQuestionDescription'),
      viewType: 'textareaInput',
      tag: 'pre_conception',
      dataBinding: `sessionsNextTemplates/${CONTEXT_SESSION_ID}/activities/post/screens/create/content/template/editableContent/question`,
      actions: [
        {
          name: t('tasks:mainQuestionRedirection'),
          type: 'redirection',
          to: {
            pathname:
              'https://www.notion.so/wapwiki/Comment-faire-une-bonne-question-d-mergence-32e8178946084583b9d76900a62fed80',
          },
          external: true,
        },
      ],
    },
    {
      name: 'postExamples',
      title: t('tasks:postExamplesTitle'),
      layout: 'row',
      description: t('tasks:postExamplesDescription'),
      viewType: 'textareaInput',
      tag: 'pre_conception',
      dataBinding: `sessionsNextTemplates/${CONTEXT_SESSION_ID}/activities/post/screens/create/content/template/editableContent/description`,
    },
    {
      name: 'matchingCriteria',
      title: t('tasks:matchinCriteriaTitle'),
      layout: 'row',
      description: t('tasks:matchinCriteriaDescription'),
      viewType: 'criteria',
      tag: 'pre_conception',
      dataBinding: {
        criteria: `sessionsNextTemplates/${CONTEXT_SESSION_ID}/activities/post/productions`,
        editableContent: `sessionsNextTemplates/${CONTEXT_SESSION_ID}/activities/post/screens/create/content/template/editableContent`,
      },
      schema: Yup.array().max(
        NB_MAX_CRITERIA,
        t('sessions:limitTo3Criteria', { max: NB_MAX_CRITERIA })
      ),
      dataTransformer: {
        get: (data: {
          criteria: Record<string, Partial<Criterium>>;
          editableContent: Record<string, any>;
        }) => {
          return Object.entries(data.criteria || {}).reduce(
            (prev: Criterium[], [key, criterium]) => {
              if (key.startsWith('crit_')) {
                prev.push({
                  index: prev.length,
                  name: criterium.name || key,
                  description: criterium.description || '',
                  instruction:
                    data.editableContent[`criteria${prev.length}Instruction`],
                  options: criterium.options || [],
                });
              }
              return prev;
            },
            []
          );
        },
        set: (
          data: {
            criteria: Record<string, Partial<Criterium>>;
            editableContent: Record<string, any>;
          },
          value: Criterium[],
          oldValue: Criterium[]
        ) => {
          const addedCriteria = _.differenceBy(value, oldValue, 'name');
          const removedCriteria = _.differenceBy(oldValue, value, 'name');
          const updatedCriteria = _.intersectionBy(value, oldValue, 'name');
          updatedCriteria.forEach((criterium) => {
            _.set(
              data.criteria,
              `${criterium.name}.description`,
              criterium.description
            );
            _.set(
              data.criteria,
              `${criterium.name}.options`,
              criterium.options
            );
          });
          addedCriteria.forEach((criterium) => {
            if (criterium.name) {
              _.set(data.criteria, `${criterium.name}`, {
                type: 'enum',
                mode: 'ByUser',
                ...criterium,
              });
            }
          });

          removedCriteria.forEach((criterium) => {
            delete data.criteria?.[criterium.name];
          });

          const newEditableContent: Record<string, any> = Object.entries(
            data.editableContent || {}
          ).reduce<Record<string, string>>((prev, [key, content]) => {
            if (!key.startsWith('criteria')) {
              prev[key] = content;
            }
            return prev;
          }, {});

          _.sortBy(Object.values(value || {}), 'name').forEach(
            (criterium, index) => {
              if (criterium.instruction) {
                newEditableContent[`criteria${index}Instruction`] =
                  criterium.instruction;
              }
            }
          );
          data.editableContent = newEditableContent;
          return data;
        },
      },
      cleanDataUpdates: async (sessionId: string, value: Criterium[]) => {
        return buildCleanCriteriaOperations(sessionId, 'post', value);
      },
    },
    {
      name: 'organizers',
      title: t('tasks:organizersTitle'),
      layout: 'row',
      description: t('tasks:organizersDescription'),
      viewType: 'userSelector',
      dataBinding: `sessionsNextData/${CONTEXT_SESSION_ID}/users/organizers`,
      tag: 'assign_role',
      cleanDataUpdates: async (
        sessionId: string,
        value: Record<string, boolean>
      ) => {
        const updates: Record<string, any> = {};
        Object.keys(value || {}).forEach((userId) => {
          updates[`/sessionsOfUsers/${userId}/${sessionId}`] = true;
          updates[`/organizations/${userId}/${sessionId}`] = true;
        });
        return updates;
      },
    },
    {
      name: 'facilitators',
      title: t('tasks:facilitatorsTitle'),
      layout: 'row',
      description: t('tasks:facilitatorsDescription'),
      viewType: 'userSelector',
      dataBinding: `sessionsNextData/${CONTEXT_SESSION_ID}/users/facilitators`,
      tag: 'assign_role',
      cleanDataUpdates: async (
        sessionId: string,
        value: Record<string, boolean>
      ) => {
        const updates: Record<string, any> = {};
        Object.keys(value || {}).forEach((userId) => {
          updates[`/sessionsOfUsers/${userId}/${sessionId}`] = true;
          updates[`/facilitations/${userId}/${sessionId}`] = true;
        });
        return updates;
      },
    },
    {
      name: 'invitationEmail',
      title: t('tasks:participantMailTitle'),
      layout: 'col',
      description: t('tasks:invitationEmailDescription'),
      viewType: 'none',
      tag: 'not_started',
      actions: [
        {
          type: 'function',
          name: t('common:CopyEmailInvitation'),
          execute: async ({ sessionId }) => {
            const textToCopy = await buildCallToPostEmail(sessionId);
            copy(textToCopy, {
              format: 'text/html',
            });
          },
          buttonProps: copyButtonProps,
        },
        {
          name: t('tasks:mailRedirection'),
          type: 'redirection',
          to: {
            pathname:
              'https://drive.google.com/drive/folders/10S5KYTzWHzZSNcFe4TRqJtyFhMaILV8C?usp=sharing',
          },
          external: true,
        },
      ],
    },
    {
      name: 'designSession',
      title: t('tasks:designSessionTitle'),
      layout: 'col',
      description: t('tasks:designSessionDescription'),
      viewType: 'none',
      tag: 'not_started',
      actions: [
        {
          name: t('tasks:designSessionRedirection'),
          type: 'redirection',
          to: {
            pathname: `/${CONTEXT_ACCESS_CODE}/live`,
            search: 'modeRequested=editor',
          },
        },
        {
          name: t('tasks:peerLearningPlanRedirection'),
          type: 'redirection',
          to: { pathname: `/${CONTEXT_ACCESS_CODE}/dashboard/session_plan` },
        },
      ],
    },
    // {
    //   name: 'facilitationTraining',
    //   title: t('tasks:facilitationTrainingTitle'),
    //   layout: 'col',
    //   description: t('tasks:facilitationTrainingDescription'),
    //   viewType: 'none',
    //   tag: 'not_started',
    //   actions: [
    //     {
    //       name: t('tasks:facilitationTrainingRedirection'),
    //       type: 'redirection',
    //       to: {
    //         pathname: '',
    //       },
    //       external: true,
    //     },
    //     {
    //       name: t('tasks:technicalFacilitationTrainingRedirection'),
    //       type: 'redirection',
    //       to: {
    //         pathname: '',
    //       },
    //       external: true,
    //     },
    //   ],
    // },
    {
      name: 'recallEmail',
      title: t('tasks:recallEmailTitle'),
      layout: 'col',
      description: t('tasks:retroplanningDescription'),
      viewType: 'none',
      tag: 'not_started',
      actions: [
        {
          type: 'function',
          name: t('common:CopyEmailRecallInvitation'),
          execute: async ({ sessionId }) => {
            const textToCopy = await buildReCallToPostEmail(sessionId);
            copy(textToCopy, {
              format: 'text/html',
            });
          },
          buttonProps: copyButtonProps,
        },
        {
          name: t('tasks:mailRedirection'),
          type: 'redirection',
          to: {
            pathname:
              'https://drive.google.com/drive/folders/10S5KYTzWHzZSNcFe4TRqJtyFhMaILV8C?usp=sharing',
          },
          external: true,
        },
      ],
    },
    {
      name: 'briefFacilitators',
      title: t('tasks:briefFacilitatorsTitle'),
      layout: 'col',
      description: t('tasks:briefFacilitatorsDescription'),
      viewType: 'none',
      tag: 'not_started',
    },
    {
      name: 'prepareIntroSlides',
      title: t('tasks:prepareIntroSlidesTitle'),
      layout: 'row',
      description: t('tasks:prepareIntroSlidesDescription'),
      dataBinding: `sessionsNext/${CONTEXT_SESSION_ID}/presentationUrl`,
      viewType: 'textInputCopy',
      tag: 'not_started',
      schema: Yup.string()
        .ensure()
        .matches(URL_REGEXP, t('form:FieldMustBeValidURL')),
      actions: [
        {
          name: t('tasks:slideRedirection'),
          type: 'redirection',
          to: {
            pathname:
              'https://drive.google.com/drive/folders/10aotTT5W93SxOv9NQ2YiBVzbjHdMZFM-?usp=sharing',
          },
          external: true,
        },
      ],
    },
    {
      name: 'technicalReview',
      title: t('tasks:technicalReviewTitle'),
      layout: 'col',
      description: t('tasks:technicalReviewDescription'),
      viewType: 'none',
      tag: 'not_started',
      actions: [
        {
          name: t('tasks:technicalReviewRedirection'),
          type: 'function',
          execute: async () => {
            focus();
          },
        },
      ],
    },
    {
      name: 'remindEmail',
      title: t('tasks:remindEmailTitle'),
      layout: 'col',
      description: t('tasks:remindEmailDescription'),
      viewType: 'none',
      tag: 'not_started',
      actions: [
        {
          type: 'function',
          name: t('common:CopyEmailRemindEvent'),
          execute: async ({ sessionId }) => {
            const textToCopy = await buildRemindEventEmail(sessionId);
            copy(textToCopy, {
              format: 'text/html',
            });
          },
          buttonProps: copyButtonProps,
        },
        {
          name: t('tasks:mailRedirection'),
          type: 'redirection',
          to: {
            pathname:
              'https://drive.google.com/drive/folders/10S5KYTzWHzZSNcFe4TRqJtyFhMaILV8C?usp=sharing',
          },
          external: true,
        },
      ],
    },
    {
      name: 'waitForLive',
      title: t('tasks:waitForLiveTitle'),
      layout: 'row',
      description: t('tasks:waitForLiveDescription'),
      viewType: 'none',
      tag: 'not_started',
      readOnly: true,
      actions: [
        {
          name: t('tasks:facilitateRedirection'),
          type: 'redirection',
          to: {
            pathname: `/${CONTEXT_ACCESS_CODE}/live`,
            search: 'modeRequested=facilitator',
          },
        },
      ],
    },
    {
      name: 'animateSession',
      title: t('tasks:animateSessionTitle'),
      layout: 'col',
      description: t('tasks:animateSessionDescription'),
      viewType: 'none',
      tag: 'live',
      readOnly: true,
      actions: [
        {
          name: t('tasks:facilitateRedirection'),
          type: 'redirection',
          to: {
            pathname: `/${CONTEXT_ACCESS_CODE}/live`,
            search: 'modeRequested=facilitator',
          },
        },
      ],
    },
    {
      name: 'Congratulation',
      title: t('tasks:CongratulationTitle'),
      layout: 'col',
      description: t('tasks:CongratulationDescription'),
      viewType: 'none',
      tag: 'finished',
      readOnly: true,
    },
    {
      name: 'remindEmail',
      title: t('tasks:conclusionEmailTitle'),
      layout: 'col',
      description: t('tasks:conclusionEmailDescription'),
      viewType: 'none',
      tag: 'finished',
      actions: [
        {
          type: 'function',
          name: t('common:CopyConclusionEmail'),
          execute: async ({ sessionId }) => {
            const textToCopy = await buildConclusionEmail(sessionId);
            copy(textToCopy, {
              format: 'text/html',
            });
          },
          buttonProps: copyButtonProps,
        },
      ],
    },
    {
      name: 'exploreContent',
      title: t('tasks:exploreContentTitle'),
      layout: 'col',
      description: t('tasks:exploreContentDescription'),
      viewType: 'none',
      tag: 'finished',
      actions: [
        {
          name: t('tasks:exploreContentRedirection'),
          type: 'redirection',
          to: { pathname: `/${CONTEXT_ACCESS_CODE}/dashboard/explore` },
        },
      ],
    },
    {
      name: 'consultFeedbacks',
      title: t('tasks:consultFeedbacksTitle'),
      layout: 'col',
      description: t('tasks:consultFeedbacksDescription'),
      viewType: 'none',
      tag: 'finished',
      actions: [
        {
          name: t('tasks:consultFeedbacksRedirection'),
          type: 'redirection',
          to: { pathname: `/${CONTEXT_ACCESS_CODE}/dashboard/feedback` },
        },
      ],
    },
    {
      name: 'DebriefSession',
      title: t('tasks:DebriefSessionTitle'),
      layout: 'col',
      description: t('tasks:DebriefSessionDescription'),
      viewType: 'none',
      tag: 'finished',
    },
  ];
};

const getSteps = (t: TFunction): Record<SessionStatusState, Step> => {
  const i18nTasks = getAllTasks(t);
  return {
    kickoff: {
      name: t('sessions:SessionKickoff'),
      nextStep: 'pre_conception',
      tasks: i18nTasks.filter((task) => task.tag === 'kickoff'),
    },
    pre_conception: {
      name: t('sessions:SessionPreconception'),
      nextStep: 'assign_role',
      tasks: i18nTasks.filter((task) => task.tag === 'pre_conception'),
    },
    assign_role: {
      name: t('sessions:AssignRoles'),
      nextStep: 'not_started',
      tasks: i18nTasks.filter((task) => task.tag === 'assign_role'),
    },
    not_started: {
      name: t('sessions:CommunicationAndDesign'),
      tasks: i18nTasks.filter((task) => task.tag === 'not_started'),
    },
    live: {
      name: t('sessions:SessionLive'),
      tasks: i18nTasks.filter((task) => task.tag === 'live'),
    },
    finished: {
      name: t('sessions:SessionFinished'),
      tasks: i18nTasks.filter((task) => task.tag === 'finished'),
    },
  };
};

export const getTasks = (t: TFunction, tasksName: string[]): Task[] => {
  const i18nTasks = getAllTasks(t);
  return i18nTasks.filter((task) => tasksName.includes(task.name));
};

export default getSteps;
