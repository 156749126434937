import {
  Activity,
  SessionTemplate,
  Activities,
  AlgoSettings,
} from 'types/types';
import { database as db } from 'services/firebase';
import assert from 'assert';

export type Action =
  | {
      type: 'activity:delete';
      name: string;
    }
  | {
      type: 'activity:new';
      newActivity: Activity;
    }
  | {
      type: 'activity:move';
      name: string;
      to: number;
    };

export const validateAction = (
  sessiontTemplate: SessionTemplate,
  action: Action
): [boolean, string | undefined] => {
  return [true, undefined];
};

export const processAction = async (
  sessionTemplate: SessionTemplate,
  sessionTemplatePath: string,
  action: Action
): Promise<void> => {
  const [valid, reason] = validateAction(sessionTemplate, action);
  if (!valid) {
    throw new Error(`Action is not valid on this session: ${reason}`);
  }
  switch (action.type) {
    case 'activity:delete':
      return deleteActivity(
        sessionTemplate.activities,
        sessionTemplatePath,
        action.name
      );
    case 'activity:new':
      return addActivity(
        sessionTemplate.activities,
        sessionTemplatePath,
        action.newActivity
      );
    case 'activity:move':
      return moveActivity(
        sessionTemplate.activities,
        sessionTemplatePath,
        action.name,
        action.to
      );
    default:
      throw new Error('Unknown Operation');
  }
};

const addActivity = async (
  activities: Activities,
  sessionTemplatePath: string,
  activity: Activity
): Promise<any> => {
  const at = activity.index;
  const updates = Object.entries(activities).reduce(
    (prec: Promise<any>[], [key, activity]) => {
      if (activity.index >= at) {
        prec.push(
          db
            .ref(sessionTemplatePath)
            .child('activities')
            .child(key)
            .update({ index: activity.index + 1 })
        );
      }
      return prec;
    },
    []
  );
  await Promise.all(updates);

  await db
    .ref(sessionTemplatePath)
    .child('activities')
    .child(activity.name)
    .set(activity);
};

export const defaultGroupingSettings: AlgoSettings = {
  mode: 'algo',
  matchedUsers: 'active',
  votes: '',
  criteria: {},
  minPeopleByGroup: 4,
  maxPeopleByGroup: 5,
};

const deleteActivity = async (
  activities: Activities,
  sessionTemplatePath: string,
  name: string
): Promise<void> => {
  const activity = activities[name];
  const at = activity.index;
  const dbUpdates: Record<string, any> = {};
  Object.entries(activities).forEach(([key, activity]) => {
    if (activity.index > at) {
      if (
        activity.grouping.mode === 'Groups' &&
        activity.grouping.settings.mode === 'same_as' &&
        activity.grouping.settings.activity === name
      ) {
        dbUpdates[`${key}/grouping/settings`] = defaultGroupingSettings;
      }
      if (
        activity.grouping.mode === 'Groups' &&
        activity.grouping.settings.mode === 'algo' &&
        activity.grouping.settings.votes
      ) {
        const parts = activity.grouping.settings.votes.split('.');

        if (parts[1] === name) {
          dbUpdates[`${key}/grouping/settings/votes`] = '';
        }
      }

      dbUpdates[`${key}/index`] = activity.index - 1;
    }
  });

  dbUpdates[activity.name] = null;

  await db.ref(`${sessionTemplatePath}/activities`).update(dbUpdates);
};

const moveActivity = async (
  activities: Activities,
  sessionTemplatePath: string,
  name: string,
  to: number
): Promise<any> => {
  const activity = activities[name];
  const from = activity.index;
  assert(from !== to);
  const sign = from < to ? -1 : 1;
  const updates = Object.entries(activities).reduce(
    (prec: Promise<any>[], [key, activity]) => {
      if (
        (from < to && activity.index > from && activity.index < to) ||
        (from > to && activity.index > to && activity.index < from)
      ) {
        prec.push(
          db
            .ref(sessionTemplatePath)
            .child('activities')
            .child(key)
            .update({ index: activity.index + sign })
        );
      }
      return prec;
    },
    []
  );

  updates.push(
    db
      .ref(sessionTemplatePath)
      .child('activities')
      .child(activity.name)
      .update({ index: to })
  );

  return Promise.all(updates);
};
