import React from 'react';
import { useTranslation } from 'react-i18next';

import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import Spinner from 'components/Spinner';

import DebriefFacilitatorInfo from './DebriefFacilitatorInfo';
import { database as db } from 'services/firebase';
import DebriefDocument from './DebriefDocument';
import Button from 'components/Button';
import { newTab } from 'utils/utils';
import DebriefData from './DebriefData';
import MDReactComponent from 'componentsOld/MDReactComponent';
import { Nullable } from 'types/typesUtils';
import { ActionRecord } from 'types/types';
import DebriefWarningInfo from './DebriefWarningInfo';

export type DebriefViewProps = {
  sessionId: string;
  userId: string;
  readOnly?: boolean;
  editable?: boolean;
  margin?: string;
};

const DebriefView = ({
  sessionId,
  userId,
  readOnly = true,
  editable = false,
  margin,
}: DebriefViewProps): JSX.Element => {
  const { t } = useTranslation();

  useFirebaseConnect([
    `sessionsNextData/${sessionId}/debrief/synthesisUrl`,
    `sessionsNextData/${sessionId}/debrief/allowAutomatedSynthesis`,
    `sessionsNextData/${sessionId}/debrief/fastSynthesis`,
  ]);

  const debriefUrl: Nullable<string> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.synthesisUrl
  );

  const cardContent: Nullable<string> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.fastSynthesis
  );

  const allowAutomatedSynthesis: Nullable<ActionRecord> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief
        ?.allowAutomatedSynthesis
  );
  const setCardContent = async (content: string) => {
    await db
      .ref(`sessionsNextData/${sessionId}/debrief/fastSynthesis`)
      .set(content);
  };

  const marginClasses = margin ? `${margin} mx-auto` : '';

  return (
    <div>
      <div
        className={`${marginClasses} mb-10 flex max-w-5xl flex-row items-center`}
      >
        <h1 className="flex-grow text-3xl font-semibold">
          {t('common:debrief')}
        </h1>
        {debriefUrl && (
          <Button
            size="md"
            text={t('sessions:DownloadSynthesis')}
            onClick={() => {
              newTab(debriefUrl);
            }}
          />
        )}
      </div>
      <div className="w-full space-y-16 pb-10">
        {isLoaded(debriefUrl, allowAutomatedSynthesis) ? (
          <>
            {readOnly ? null : (
              <>
                <DebriefWarningInfo
                  sessionId={sessionId}
                  userId={userId}
                  allowAutomatedSynthesis={allowAutomatedSynthesis}
                  marginClasses={marginClasses}
                />
                <DebriefFacilitatorInfo
                  sessionId={sessionId}
                  synthesisUrl={debriefUrl}
                  setSynthesisUrl={(url: string | null) =>
                    db
                      .ref(`sessionsNextData/${sessionId}/debrief/synthesisUrl`)
                      .set(url)
                  }
                  marginClasses={marginClasses}
                />
              </>
            )}

            {debriefUrl ? (
              <DebriefDocument
                synthesisUrl={debriefUrl}
                marginClasses={marginClasses}
              />
            ) : readOnly ? (
              <div className={`${marginClasses} space-y-8`}>
                <MDReactComponent
                  text={cardContent || ''}
                  editable={editable}
                  setText={setCardContent}
                  forceBorder={editable}
                />
              </div>
            ) : null}

            {readOnly ? null : (
              <DebriefData
                sessionId={sessionId}
                allowAutomatedSynthesis={allowAutomatedSynthesis}
                marginClasses={marginClasses}
              />
            )}
          </>
        ) : (
          <Spinner className="mx-auto h-12 w-12 text-primary" />
        )}
      </div>
    </div>
  );
};

export default DebriefView;
