import { ELEMENT_LINK, insertImage, isImageUrl } from '@udecode/plate';
import {
  getPluginType,
  insertNodes,
  PlateEditor,
  TElement,
} from '@udecode/plate-core';

/**
 * Allows for pasting images from clipboard.
 * Not yet: dragging and dropping images, selecting them through a file system dialog.
 * @param img.type
 */

export const insertLink = (
  editor: PlateEditor,
  url: string | ArrayBuffer,
  name: string
) => {
  const text = { text: name };
  const image = {
    type: getPluginType(editor, ELEMENT_LINK),
    url,
    children: [text],
  };
  insertNodes<TElement>(editor, image);
};

export type FilePlugin = {
  upload: (prefix: string, file: File) => Promise<string | undefined>;
};

export const uploadFiles = (
  editor: PlateEditor<{}>,
  files: FileList | null,
  options: FilePlugin
) => {
  if (editor && files && files?.length > 0)
    for (const file of files) {
      options
        .upload(`filesOfDoc/${editor.docId}`, file)
        .then((url?: string) => {
          if (url) {
            if (isImageUrl(url)) {
              console.log(url);
              console.log('InsertingImage');
              insertImage(editor, url);
            } else {
              return insertLink(editor, url, `${file.name} 📎`);
            }
          }
        })
        .catch((e) => console.error(e));
    }
};

const withFileUpload =
  (options: {
    upload: (prefix: string, file: File) => Promise<string | undefined>;
  }) =>
  (editor: PlateEditor) => {
    const { insertData } = editor;

    editor.insertData = (data) => {
      const { files } = data;
      if (files?.length !== 0) {
        uploadFiles(editor, files, options);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

export default withFileUpload;
