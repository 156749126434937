import { Editor } from 'slate';
import invariant from 'tiny-invariant';
import { applyYjsEvents } from '../applyToSlate';
import applySlateOps from '../applyToYjs';
import { slateYjsOriginSymbol } from '../model';
import { toSlateDoc } from '../utils';
const IS_REMOTE = new WeakSet();
const LOCAL_OPERATIONS = new WeakMap();
const SHARED_TYPES = new WeakMap();
export const YjsEditor = {
    /**
     * Set the editor value to the content of the to the editor bound shared type.
     */
    synchronizeValue: (e) => {
        Editor.withoutNormalizing(e, () => {
            e.children = toSlateDoc(e.sharedType);
            e.onChange();
        });
    },
    /**
     * Returns whether the editor currently is applying remote changes.
     */
    sharedType: (editor) => {
        const sharedType = SHARED_TYPES.get(editor);
        invariant(sharedType, 'YjsEditor without attached shared type');
        return sharedType;
    },
    /**
     * Returns whether the editor currently is applying remote changes.
     */
    isRemote: (editor) => {
        return IS_REMOTE.has(editor);
    },
    /**
     * Performs an action as a remote operation.
     */
    asRemote: (editor, fn) => {
        const wasRemote = YjsEditor.isRemote(editor);
        IS_REMOTE.add(editor);
        fn();
        if (!wasRemote) {
            IS_REMOTE.delete(editor);
        }
    },
    /**
     * Unobserves the shared type.
     */
    destroy: (editor) => {
        editor.destroy();
    },
};
function localOperations(editor) {
    const operations = LOCAL_OPERATIONS.get(editor);
    invariant(operations, 'YjsEditor without attached local operations');
    return operations;
}
function trackLocalOperations(editor, operation) {
    if (!YjsEditor.isRemote(editor)) {
        localOperations(editor).add(operation);
    }
}
/**
 * Applies a slate operations to the bound shared type.
 */
function applyLocalOperations(editor) {
    const editorLocalOperations = localOperations(editor);
    applySlateOps(YjsEditor.sharedType(editor), Array.from(editorLocalOperations), slateYjsOriginSymbol);
    editorLocalOperations.clear();
}
/**
 * Apply Yjs events to slate
 */
function applyRemoteYjsEvents(editor, events) {
    Editor.withoutNormalizing(editor, () => YjsEditor.asRemote(editor, () => applyYjsEvents(editor, events.filter((event) => event.transaction.origin !== slateYjsOriginSymbol))));
}
export function withYjs(editor, sharedType, { synchronizeValue = true } = {}) {
    const e = editor;
    e.sharedType = sharedType;
    SHARED_TYPES.set(editor, sharedType);
    LOCAL_OPERATIONS.set(editor, new Set());
    if (synchronizeValue) {
        setTimeout(() => YjsEditor.synchronizeValue(e), 0);
    }
    const applyEvents = (events) => applyRemoteYjsEvents(e, events);
    sharedType.observeDeep(applyEvents);
    const { apply, onChange, destroy } = e;
    e.apply = (op) => {
        trackLocalOperations(e, op);
        apply(op);
    };
    e.onChange = () => {
        applyLocalOperations(e);
        onChange();
    };
    e.destroy = () => {
        sharedType.unobserveDeep(applyEvents);
        if (destroy) {
            destroy();
        }
    };
    return e;
}
