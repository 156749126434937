import Post from 'blocks/Post';
import List from 'components/List';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleBar, Users } from 'types/types';
import { PostItem } from 'types/ui';
import { ReactComponent as Idea } from 'assets/images/idea.svg';
import Button from 'components/Button';
import _ from 'lodash';
import LikeIcon from 'blocks/LikeIcon';
import { getGeneratedName } from 'services/nameGenerator';

export type PostListProps = {
  title?: string;
  users: Users;
  posts: Record<string, string>;
  votes: Record<string, true>;
  participate: () => void;
  toggleVote: (userId: string, current: boolean) => void;
  canVote: boolean;
  voteLimit?: number;
  role: RoleBar;
  obfuscate?: boolean;
  obfuscateAction?: () => void;
};

const PostList = ({
  users,
  posts,
  votes,
  participate,
  toggleVote,
  canVote,
  voteLimit,
  title,
  role,
  obfuscate,
  obfuscateAction,
}: PostListProps) => {
  const { t } = useTranslation();

  const postsArray = useMemo(
    () =>
      Object.entries(posts).map(([userId, postContent]) => {
        return {
          content: postContent,
          author: {
            userId,
            name:
              role === 'participant'
                ? getGeneratedName(userId)
                : users[userId]?.name || t('users:UnknownUser'),
          },
          isLiked: !!votes[userId],
        };
      }),
    [users, posts, votes, t, role]
  );

  const limitReached = !!voteLimit && _.size(votes) >= voteLimit;

  return (
    <List<PostItem>
      title={title || t('sessions:shares')}
      items={postsArray}
      itemsPerPage={20}
      showCount={true}
      showHeader={_.size(posts) > 0}
      renderItem={(post) => {
        const userName = post.author?.name || 'Utilisateur Inconnu';
        return (
          <Post
            obfuscate={obfuscate}
            obfuscateAction={obfuscateAction}
            content={post.content}
            userName={userName}
            actionIcon={
              canVote ? (
                <LikeIcon
                  disabled={limitReached}
                  liked={post.isLiked}
                  onClick={() => {
                    if (post.isLiked || !limitReached) {
                      toggleVote(post.author.userId, post.isLiked);
                    }
                  }}
                />
              ) : undefined
            }
          />
        );
      }}
      renderEmptyList={() => {
        return (
          <div className="flex w-full flex-col items-center space-y-8">
            <h1 className="text-3xl font-semibold">
              {role === 'participant'
                ? t('sessions:BeTheFirstToShare')
                : t('sessions:NoParticipantPosted')}
            </h1>
            <h2 className="text-xl font-semibold text-gray-400">
              {role === 'participant'
                ? t('sessions:YouAreAGoldMine')
                : t('sessions:InspirationShouldSoonCome')}
            </h2>
            <Idea className="h-32 w-32" />
            {role === 'participant' ? (
              <Button
                className="w-full"
                onClick={participate}
                text={t('sessions:Participate')}
              />
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default PostList;
