import ListItem from 'components/ListItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';

export type SessionObjectivesProps = {
  goals: Nullable<string[]>;
  marginClasses: string;
};

const SessionObjectives = ({
  goals,
  marginClasses,
}: SessionObjectivesProps) => {
  const { t } = useTranslation();

  return goals && goals.length > 0 ? (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:goalsTitle')}</h2>
      <ListItem items={goals || []} />
    </div>
  ) : null;
};

export default SessionObjectives;
