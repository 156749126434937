import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'react-router-tabs/styles/react-router-tabs.css';
import App from './App'; // load last to override lib styles (ex: tabs)

import store from './store';
// import registerServiceWorker from './registerServiceWorker'
import firebase, { auth } from 'services/firebase';
import ErrorView from './ErrorView';

import './index.css';
import 'frameworks/antd/style.css';
import 'frameworks/react-router-tabs/style.css';

import 'services/i18n';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import { getConsent } from 'services/axeptio';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { stage } from 'config';

import ErrorBoundary from './ErrorBondary';

if (getConsent('bugsnag')) {
  Bugsnag.start({
    apiKey: '09537e0c7f2c53de17a772eb21dc8e2f',
    plugins: [new BugsnagPluginReact()],
    releaseStage: stage,
    onError: function (event) {
      if (auth.currentUser) {
        event.setUser(auth.currentUser.uid);
      }
    },
  });
}

const CustomErrorBoundary = getConsent('bugsnag')
  ? Bugsnag.getPlugin('react')!.createErrorBoundary(React)
  : ErrorBoundary;

const rrfConfig = {
  userProfile: 'users',
  logErrors: true,
  // enableLogging: true,
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
};

ReactDOM.render(
  <CustomErrorBoundary FallbackComponent={ErrorView}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Provider store={store as any}>
        <App />
      </Provider>
    </ReactReduxFirebaseProvider>
  </CustomErrorBoundary>,
  document.getElementById('root')
);
