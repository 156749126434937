import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DayPickerInput from './DayPickerInput';
import TimePicker, { TimeType } from './TimePicker';
import Checkbox from './Checkbox';

const getNewTime = (
  first: Date,
  comparator: '<' | '>',
  compared: Date
): [number, number] => {
  let newHours = 0;
  let newMinutes = 0;
  const sameDay = moment(first).format('LL') === moment(compared).format('LL');
  if (comparator === '<') {
    newHours =
      !sameDay || first.getHours() < compared.getHours()
        ? first.getHours()
        : compared.getHours();
    newMinutes =
      !sameDay ||
      compared.getHours() !== newHours ||
      first.getMinutes() < compared.getMinutes()
        ? first.getMinutes()
        : compared.getMinutes();
  } else {
    newHours =
      !sameDay || first.getHours() > compared.getHours()
        ? first.getHours()
        : compared.getHours();
    newMinutes =
      !sameDay ||
      compared.getHours() !== newHours ||
      first.getMinutes() > compared.getMinutes()
        ? first.getMinutes()
        : compared.getMinutes();
  }
  return [newHours, newMinutes];
};
export type RangePickerProps = {
  type?: 'date' | 'datetime';
  value: [Date, Date];
  onChange: (range: [Date, Date]) => void;
  bounds: [Date | undefined, Date | undefined];
  readOnly?: boolean;
  forceRangeExtended?: boolean;
};

const RangePicker = ({
  value,
  onChange,
  bounds,
  type = 'date',
  readOnly = false,
  forceRangeExtended,
}: RangePickerProps): JSX.Element => {
  const { t } = useTranslation();
  const [from, to] = value;
  const sameDay = moment(from).format('LL') === moment(to).format('LL');
  const [rangeExtended, setRangeExtended] = useState(
    forceRangeExtended || !sameDay
  );

  const commonBounds = [
    ...(bounds?.[0]
      ? [
          {
            before: bounds[0],
          },
        ]
      : []),
    ...(bounds?.[1]
      ? [
          {
            after: bounds[1],
          },
        ]
      : []),
  ];

  const handleFromDateChange = (date: Date) => {
    const fromDate =
      type === 'date'
        ? moment(date).startOf('day').toDate()
        : moment(date)
            .hours(from.getHours())
            .minutes(from.getMinutes())
            .seconds(0)
            .toDate();

    const newEndDate =
      fromDate.getTime() > to.getTime() || !rangeExtended ? fromDate : to;
    const [newHours, newMinutes] = getNewTime(
      moment(newEndDate)
        .hours(to.getHours())
        .minutes(to.getMinutes())
        .seconds(0)
        .toDate(),
      '>',
      fromDate
    );

    const toDate =
      type === 'date'
        ? moment(newEndDate).startOf('day').toDate()
        : moment(newEndDate)
            .hours(newHours)
            .minutes(newMinutes)
            .seconds(0)
            .toDate();

    onChange([fromDate, toDate]);
  };

  const handleToDateChange = (date: Date) => {
    const endDate =
      type === 'date'
        ? moment(date).endOf('day').toDate()
        : moment(date)
            .hours(to.getHours())
            .minutes(to.getMinutes())
            .seconds(0)
            .toDate();

    const newFromDate = endDate.getTime() < from.getTime() ? endDate : from;
    const [newHours, newMinutes] = getNewTime(
      moment(newFromDate)
        .hours(from.getHours())
        .minutes(from.getMinutes())
        .seconds(0)
        .toDate(),
      '<',
      endDate
    );

    const fromDate =
      type === 'date'
        ? moment(newFromDate).startOf('day').toDate()
        : moment(newFromDate)
            .hours(newHours)
            .minutes(newMinutes)
            .seconds(0)
            .toDate();

    onChange([fromDate, endDate]);
  };

  const handleFromTimeChange = (time: TimeType) => {
    const fromDate = moment(from)
      .hours(time.hours)
      .minutes(time.minutes)
      .seconds(0)
      .toDate();

    const [newHours, newMinutes] = getNewTime(to, '>', fromDate);

    const toDate = moment(to)
      .hours(newHours)
      .minutes(newMinutes)
      .seconds(0)
      .toDate();

    onChange([fromDate, toDate]);
  };

  const handleToTimeChange = (time: TimeType) => {
    const toDate = moment(to)
      .hours(time.hours)
      .minutes(time.minutes)
      .seconds(0)
      .toDate();

    const [newHours, newMinutes] = getNewTime(from, '<', toDate);

    const fromDate = moment(from)
      .hours(newHours)
      .minutes(newMinutes)
      .seconds(0)
      .toDate();

    onChange([fromDate, toDate]);
  };

  const handleRangeExtendedChange = (extended: boolean) => {
    setRangeExtended(extended);

    const [newHours, newMinutes] = getNewTime(
      extended
        ? to
        : moment(from)
            .hours(to.getHours())
            .minutes(to.getMinutes())
            .seconds(0)
            .toDate(),
      '>',
      from
    );

    const toDate = extended
      ? to
      : type === 'date'
      ? moment(from).startOf('day').toDate()
      : moment(from).hours(newHours).minutes(newMinutes).seconds(0).toDate();

    onChange([from, toDate]);
  };

  return (
    <div className="flex w-full flex-row items-start">
      {readOnly ? (
        sameDay ? (
          <p className="text-lg font-light">
            {`${moment(from).format('LL')} `}
            <span className="font-normal">
              {`(${moment(from).format('LT')} - ${moment(to).format('LT')})`}
            </span>
          </p>
        ) : (
          <p className="text-lg font-light">
            {`${moment(from).format('LLL')} - ${moment(to).format('LLL')}`}
          </p>
        )
      ) : (
        <>
          <div className="flex flex-col space-y-2">
            <DayPickerInput
              value={from}
              onDayChange={handleFromDateChange}
              inputProps={{
                className:
                  'border p-3 text-lg w-full border-surfaces-divider rounded-md',
              }}
              dayPickerProps={{
                disabledDays: commonBounds,
              }}
            />

            {!forceRangeExtended ? (
              <Checkbox
                value={rangeExtended}
                onChange={handleRangeExtendedChange}
              >
                <p>{t('common:ExtendRangeOnSeveralDays')}</p>
              </Checkbox>
            ) : null}
          </div>
          <div className="flex flex-row items-center">
            {type === 'datetime' ? (
              <div className={`${rangeExtended ? 'ml-2' : 'ml-6'}`}>
                <TimePicker
                  name="from"
                  value={{ hours: from.getHours(), minutes: from.getMinutes() }}
                  onChange={handleFromTimeChange}
                  max={
                    sameDay
                      ? { hours: to.getHours(), minutes: to.getMinutes() }
                      : undefined
                  }
                  suggestions={[
                    { hours: 8, minutes: 0 },
                    { hours: 9, minutes: 0 },
                    { hours: 10, minutes: 0 },
                    { hours: 14, minutes: 0 },
                    { hours: 15, minutes: 0 },
                  ]}
                />
              </div>
            ) : null}
            <span className={`${rangeExtended ? 'mx-6' : 'ml-2'}`}>{'-'}</span>
            {rangeExtended ? (
              <DayPickerInput
                value={to}
                onDayChange={handleToDateChange}
                inputProps={{
                  className:
                    'border text-lg p-3 w-full border-surfaces-divider rounded-md',
                }}
                dayPickerProps={{
                  disabledDays: commonBounds,
                }}
              />
            ) : null}
            {type === 'datetime' ? (
              <div className="ml-2">
                <TimePicker
                  name="to"
                  value={{ hours: to.getHours(), minutes: to.getMinutes() }}
                  onChange={handleToTimeChange}
                  min={
                    sameDay
                      ? { hours: from.getHours(), minutes: from.getMinutes() }
                      : undefined
                  }
                  suggestions={[
                    { hours: 10, minutes: 0 },
                    { hours: 11, minutes: 0 },
                    { hours: 12, minutes: 0 },
                    { hours: 16, minutes: 0 },
                    { hours: 17, minutes: 0 },
                    { hours: 18, minutes: 0 },
                  ]}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default RangePicker;
