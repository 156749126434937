import React from 'react';
import { Wap as Logo } from 'assets';

const LoadingScreen = () => (
  <div className="flex h-screen w-full">
    <div className="m-auto">
      <Logo className="h-24 fill-current text-primary" />
      <p className="mt-4 text-2xl font-bold">{`Loading ...`}</p>
    </div>
  </div>
);

export default LoadingScreen;
