import React, { Key } from 'react';

export type Column<T extends Keyed> = {
  title: string;
  dataIndex: keyof T;
  render?: (dataIndex: keyof T, record: T) => React.ReactNode;
};

export type Data<T> = T[];

type Keyed = {
  key: Key;
};

export type TableProps<T extends Keyed> = {
  columns: Column<T>[];
  data: Data<T>;
};

const Table = <T extends Keyed>({
  data,
  columns,
}: TableProps<T>): JSX.Element => {
  return (
    <table className="w-full table-auto border-collapse border text-black">
      <thead className="bg-primary-soft">
        <tr>
          {columns.map((column) => {
            return (
              <th
                key={column.dataIndex.toString()}
                className="border border-surfaces-divider p-1 font-medium"
              >
                {column.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((datum) => {
          return (
            <tr key={datum.key}>
              {columns.map((column) => {
                return (
                  <td
                    key={column.dataIndex.toString()}
                    className="border-surface-divider whitespace-pre-wrap border px-2"
                  >
                    {column.render
                      ? column.render(column.dataIndex, datum)
                      : datum[column.dataIndex]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
