import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import { ActionRecord, Users } from 'types/types';
import { computeOrderedVotes } from 'model/votes';
import RevisionnedEditor from 'frameworks/plate/RevisionedEditor';
import PostBlock from './blocks/PostBlock';

export type FocusProps = {
  sessionId: string;
  postsSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  votes: Nullable<Record<string, string>>;
  participants: Users;
  groupOfUser: Nullable<Record<string, string>>;
  shareDocs: Nullable<Record<string, Record<string, true>>>;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const Focus = ({
  sessionId,
  postsSummaries,
  votes,
  posts,
  participants,
  groupOfUser,
  shareDocs,
  allowAutomatedSynthesis,
  marginClasses,
}: FocusProps) => {
  const { t } = useTranslation();

  const orderedVotes = computeOrderedVotes(votes);
  const selectableVotes = orderedVotes.filter(
    ([userId]) => !!groupOfUser?.[userId]
  );
  const userId = selectableVotes[0]?.[0];

  const groupId = groupOfUser?.[userId];

  const metaId =
    groupId && Object.keys(shareDocs?.[groupId]?.[userId] || {})[0];

  return userId && posts?.[userId] && metaId ? (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:Focus')}</h2>
      <div className="space-y-4">
        <PostBlock
          sessionId={sessionId}
          allowAutomatedSynthesis={allowAutomatedSynthesis}
          userId={userId}
          post={posts[userId]}
          postSummary={postsSummaries?.[userId] || null}
          user={participants[userId]}
        />
        <RevisionnedEditor metaId={metaId} readOnly={true} />
      </div>
    </div>
  ) : null;
};

export default Focus;
