import React from 'react';
import Fonts from 'constants/Fonts';
import { createStyleMap } from 'types/typesUtils';

const TitlePlan = ({
  style,
  children,
  ...props
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
}) => {
  return (
    <h1
      {...props}
      style={{
        ...styles.viewMain,
        ...(props.onClick ? { cursor: 'pointer' } : {}),
        ...style,
      }}
    >
      <b>{children}</b>
    </h1>
  );
};

const styles = createStyleMap({
  viewMain: {
    color: '#424242',
    fontSize: Fonts.SIZE.BIG,
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});

export default TitlePlan;
