import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './index.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const AdminGroupCard = ({
  title,
  onDelete,
  toggleRename,
  onRename,
  isEditing,
  children,
  color,
  number,
  groupPrefix,
  style,
  cardinality,
  activeMembers,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={['group-card-admin', css.container].join(' ')}
      style={style}
    >
      <div className={css.header}>
        <h3 className={css.title} style={{ color }}>
          <span className={css.number}>{`${groupPrefix}${number}`}</span>
          {isEditing ? (
            <input
              autoFocus
              className={css.input}
              value={title}
              onChange={(event) => {
                const { value } = event.target;
                onRename(value);
              }}
              onKeyDown={(event) => event.key === 'Enter' && toggleRename()}
            />
          ) : (
            <span>{title}</span>
          )}
        </h3>

        <div className={css.buttons}>
          {/* {toggleRename && (
          <img
            className={[css.image, css.clickable].join(' ')}
            src={isEditing ? Images.done : Images.pen}
            alt="edit button"
            onClick={toggleRename}
          />
        )} */}
          {onDelete && (
            <Tooltip title={t('facilitator:RemoveGroup')}>
              <DeleteOutlined onClick={onDelete} />
            </Tooltip>
          )}
        </div>
      </div>
      <h4 style={{ marginLeft: '12px' }}>
        {t('facilitator:ActiveMembersRatio', {
          active: activeMembers,
          cardinality: cardinality,
        })}
      </h4>
      <div className={css.body}>{children}</div>
    </div>
  );
};

export default AdminGroupCard;
