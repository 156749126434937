import { configureStore } from '@reduxjs/toolkit';
import { isLocalBuild } from 'config';

import reducer from './reducers';

export default configureStore({
  reducer,
  devTools: isLocalBuild(),
  middleware: [],
});
