import React, { useEffect, useMemo } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Mode, Selection } from 'types/ui';
import { usePrevious } from 'utils/utils';
import { getSelectableActivities } from 'model/dataHooks';

import Activity from './Activity';

import {
  Activity as ActivityType,
  Productions,
  Activities,
  RoleBar,
  ActivityMeta,
  Session,
  SessionStatusState,
} from 'types/types';
import SessionHomeFacilitationScreen from './screens/SessionHomeFacilitationScreen';
import DashboardFacilitationScreen from './screens/DashboardFacilitationScreen';
import { Nullable } from 'types/typesUtils';

type SessionRouterProps = {
  sessionId: string;
  session: Session;
  sessionState: Nullable<SessionStatusState>;
  userId: string;
  userName: string;
  userEmail: string;
  role: RoleBar;
  mode: Mode;
  productions: Record<string, Productions>;
  activities: Activities;
  enabledActivities: Record<string, boolean>;
  activitiesMeta: Record<string, ActivityMeta>;
  orderedActivities: ActivityType[];
  selection: Selection;
  groupModalVisible: boolean;
  showGroupModal: () => void;
  hideGroupModal: () => void;
  videoGroupId: string | null;
  selectVideoGroupId: (groupId: string) => void;
  resetVideoGroupId: () => void;
};

const SessionRouter = ({
  sessionId,
  session,
  sessionState,
  userId,
  userName,
  userEmail,
  role,
  mode,
  productions,
  activities,
  enabledActivities,
  activitiesMeta,
  orderedActivities,
  selection,
  groupModalVisible,
  showGroupModal,
  hideGroupModal,
  videoGroupId,
  selectVideoGroupId,
  resetVideoGroupId,
}: SessionRouterProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const lastEnabledActivities = usePrevious(enabledActivities);

  const { sessionPath: path, selectedActivity } = selection;
  const firstActivity = useMemo(() => {
    return (
      orderedActivities.find((activity) => enabledActivities[activity.name]) ||
      orderedActivities[0]
    );
  }, [orderedActivities, enabledActivities]);

  const redirectActivityPath =
    sessionState === 'live' || mode === 'editor' || mode === 'preview'
      ? `/${path}/${firstActivity.name}`
      : sessionState === 'finished'
      ? `/${path}/facilitate/dashboard`
      : `/${path}/facilitate/home`;

  const selectableActivity = useMemo(
    () =>
      getSelectableActivities(
        enabledActivities,
        orderedActivities,
        selectedActivity || ''
      ),
    [enabledActivities, orderedActivities, selectedActivity]
  );

  useEffect(() => {
    const toggleActivityView = () => {
      history.replace(redirectActivityPath);
    };

    if (
      lastEnabledActivities &&
      enabledActivities &&
      !_.isEqual(lastEnabledActivities, enabledActivities) &&
      selectedActivity !== firstActivity.name
    ) {
      toggleActivityView();
    }
  }, [
    lastEnabledActivities,
    enabledActivities,
    path,
    history,
    firstActivity.name,
    role,
    t,
    pathname,
    mode,
    orderedActivities,
    activities,
    selectedActivity,
    redirectActivityPath,
  ]);

  const renderActivity = (activity: ActivityType) => {
    const { name } = activity;

    return (
      <Route
        key={name}
        path={`/${path}/${name}`}
        render={(props) => {
          return (
            <Activity
              sessionId={sessionId}
              sessionDataId={sessionId}
              session={session}
              userId={userId}
              userName={userName}
              userEmail={userEmail}
              role={role}
              mode={mode}
              productions={productions}
              activity={activity}
              activityMeta={activitiesMeta[name]}
              activities={activities}
              selection={selection}
              lastActivity={
                activity.name ===
                orderedActivities[orderedActivities.length - 1].name
              }
              groupModalVisible={groupModalVisible}
              showGroupModal={showGroupModal}
              hideGroupModal={hideGroupModal}
              videoGroupId={videoGroupId}
              selectVideoGroupId={selectVideoGroupId}
              resetVideoGroupId={resetVideoGroupId}
              {...props}
            />
          );
        }}
      />
    );
  };

  return (
    <Switch>
      {orderedActivities
        .filter(
          (activity) => mode !== 'participant' || selectableActivity(activity)
        )
        .map((activity) => renderActivity(activity))}

      {mode === 'facilitator' ? (
        <Route
          path={`/${path}/facilitate/home`}
          render={() => (
            <SessionHomeFacilitationScreen
              sessionState={sessionState}
              sessionPath={`/${session.accessCode}`}
              selectedActivity={firstActivity}
            />
          )}
        />
      ) : undefined}
      {mode === 'facilitator' ? (
        <Route
          path={`/${path}/facilitate/dashboard`}
          render={() => (
            <DashboardFacilitationScreen
              sessionState={sessionState}
              sessionPath={`/${session.accessCode}`}
              selectedActivity={firstActivity}
            />
          )}
        />
      ) : undefined}
      <Route
        path={`/${path}/*`}
        render={() => {
          return <Redirect to={redirectActivityPath} />;
        }}
      />
      <Route
        path={`/${path}/`}
        render={() => {
          return <Redirect to={redirectActivityPath} />;
        }}
      />
    </Switch>
  );
};

export default SessionRouter;
