import * as React from 'react';

function SvgVideoCameraOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M14.502 6.682A2 2 0 0013 6H5a2 2 0 00-2 2v8a2 2 0 00.69 1.5zM15 11.848L8.852 18H13a2 2 0 002-2zm2.724-3.424c.134.125.413.148.634.068.352-.128.725-.332.725-.332.534-.32.995-.28.995-.28s.824.028.922.738v6.764a1 1 0 01-1.447.894L15 14" />
      <path
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.678 21.35l18.55-18.527"
      />
    </svg>
  );
}

export default SvgVideoCameraOff;
