import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/Button';
import MDReactComponent from 'componentsOld/MDReactComponent';
import GroupRenderer from 'componentsOld/Group/GroupRenderer';
import { Group } from 'types/types';

export type GroupModalProps = {
  open: boolean;
  onClose: () => void;
  content: string;
  setContent: (value: string) => Promise<void>;
  editable: boolean;
  group?: Group | null;
  groupPrefix: string | undefined;
};

const GroupModal = ({
  open,
  onClose,
  content,
  setContent,
  editable,
  group,
  groupPrefix,
}: GroupModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={'max-w-screen-xs sm:max-w-lg md:w-192'}
      title={
        <>
          <h2>
            {t('common:groupX', {
              number: group?.number || 1,
              groupPrefix: groupPrefix || '',
            })}
          </h2>
          {group && <GroupRenderer group={group} />}
        </>
      }
      body={
        <div className="px-8">
          <MDReactComponent
            text={content}
            editable={editable}
            setText={setContent}
            zIndex={1000}
          />
        </div>
      }
      footer={
        <div className="flex items-center space-x-4">
          <Button text={t('common:Ok')} onClick={onClose} />
        </div>
      }
    />
  );
};

export default GroupModal;
