import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import { Hit } from 'react-instantsearch-core';
import { WithId } from 'types/typesUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  TemplateSearchResultType,
  SessionTemplateFullDescription,
  UserType,
} from 'types/types';
import { useSessionTemplateDescriptions } from 'model/sessionsTemplates';
import { algoliaConf as conf, domainUrl } from 'config';
import { duplicateSession } from 'model/sessions';

import TemplateModal from 'blocks/TemplateModal';
import Pagination from 'frameworks/algoliasearch/Pagination';
import SearchBox from 'frameworks/algoliasearch/SearchBox';

import Button from 'components/Button';
import LoadingScreen from 'screens/LoadingScreen';
import Thumbnail from 'components/Thumbnail';

import { Clock, UserGroup } from 'assets/icons';

const client = algoliasearch(conf.appId, conf.key);

export type CommunityTemplatesProps = {
  userId: string;
  communityId: string;
  userType: UserType;
};

const CommunityTemplates = ({
  communityId,
  userId,
  userType,
}: CommunityTemplatesProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const [templates, templatesLoaded] = useSessionTemplateDescriptions(
    communityId || 'nonexistentid',
    userType
  );
  const [modalTemplate, setModalTemplate] =
    useState<WithId<SessionTemplateFullDescription> | null>(null);

  const createNewSession = async (
    title: string,
    duplicateSessionId: string
  ) => {
    const [accessCode] = await duplicateSession(
      duplicateSessionId,
      title,
      userId,
      false,
      {
        communityId,
        location: undefined,
      },
      {},
      false,
      true
    );
    if (accessCode) {
      history.push(`/${accessCode}/dashboard`);
    }
  };

  const TemplateRow = ({
    hit: { objectID },
  }: {
    hit: Hit<TemplateSearchResultType>;
  }) => {
    const template = templates[objectID];
    return template ? (
      <>
        <div
          className={`mt-4 w-full cursor-pointer rounded-md border border-surfaces-divider p-4 hover:border-primary`}
          onClick={() => setModalTemplate({ ...template, id: objectID })}
        >
          <div className="mb-2 flex flex-row items-center space-x-2">
            <Thumbnail name={template.name} colorClass="bg-primary" />
            <h3 className="text-xl font-semibold">{template.name}</h3>
          </div>
          <p className="text-base text-gray-500">{template.description}</p>
          <div className="mt-3 flex flex-row justify-between">
            <div className="flex flex-row items-center space-x-4 text-lg">
              <div className="flex flex-row items-center">
                <Clock className="mr-1 h-6 w-6 stroke-2" />
                {template.duration}min
              </div>
              <div className="flex flex-row items-center">
                <UserGroup className="mr-1 h-6 w-6 stroke-2" />
                {template.nbParticipants[0]} à {template.nbParticipants[1]}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Button
                design="secondary"
                stopPropagation
                size="md"
                text={t('sessions:automaticSessionCreationLink')}
                onClick={() => {
                  const params = {
                    templateId: objectID,
                    title: t('common:duplicatedTag', { text: template.name }),
                  };
                  const searchParams = new URLSearchParams(params);
                  navigator.clipboard.writeText(
                    `${domainUrl}/new/session?${searchParams}&communityId=[communityId]`
                  );
                }}
                enableDoneStatus
                doneText={t('sessions:copied')}
                doneColor="success"
              />
              <Button
                stopPropagation
                size="md"
                text={t('sessions:CreateANewSession')}
                onClick={() => {
                  createNewSession(
                    t('common:duplicatedTag', { text: template.name }),
                    objectID
                  );
                }}
              />
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  return templatesLoaded ? (
    <div className="w-full">
      <InstantSearch
        searchClient={client}
        indexName={`${conf.templatesIndexName}`}
      >
        {
          <Configure
            filters={`communitiesIds:${communityId} OR visibility:public`}
          />
        }
        <div className="flex w-full flex-row flex-wrap lg:flex-nowrap lg:space-x-16">
          <div className="flex-grow">
            <div className="mb-4 flex flex-row items-center">
              <h1 className="flex-grow text-3xl font-semibold">
                {t('communities:Templates')}
              </h1>
            </div>
            <div className="w-full">
              <SearchBox />
            </div>
            <div className="mt-8">
              <Pagination />
            </div>
            <Hits hitComponent={TemplateRow} />
            <div className="mt-4">
              <Pagination />
            </div>
          </div>
        </div>
        <TemplateModal
          template={modalTemplate}
          close={() => setModalTemplate(null)}
          footer={
            <div className="flex flex-row space-x-4">
              <Button
                onClick={() => setModalTemplate(null)}
                text={t('common:Close')}
              />
            </div>
          }
        />
      </InstantSearch>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default CommunityTemplates;
