import React, { useEffect, useState } from 'react';
import Editor, { theme } from 'rich-markdown-editor';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { uploadSimple } from 'services/storage';

import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import { Cloud, Refresh, Pencil } from 'assets/icons';

const MDReactComponent = ({
  text,
  editable,
  setText,
  zIndex,
  forceBorder,
}: {
  text: string;
  editable?: boolean;
  setText?: (x: string) => Promise<void>;
  zIndex?: number;
  forceBorder?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);

  const onEditStart = () => {
    setEditMode(true);
  };

  useEffect(() => {
    if (!editable) {
      setEditMode(false);
    }
  }, [editable]);

  const debouncedSetText =
    setText &&
    _.debounce(async (text) => {
      await setText(text);
      setSaving(false);
    }, 1000);

  const onChange = (getText: () => string) => {
    setSaving(true);
    const toSave = getText();
    // inspired by issue https://github.com/outline/rich-markdown-editor/issues/532
    const stripRegEx = new RegExp(/^\\\W*$/gm);
    const savedValue =
      toSave.length === 2 ? toSave.replace(stripRegEx, '') : toSave;
    // const toSave = text.replace(/<br>/g, '\n\n');
    debouncedSetText && debouncedSetText(savedValue);
  };

  return (
    <div
      className={`markdown-editor relative text-left ${
        editable ? 'min-h-12' : 'min-h-0'
      }`}
    >
      <div
        className={
          editMode || forceBorder
            ? `rounded-md border border-surfaces-divider p-2 pl-8 ${
                editMode ? 'pr-4' : ''
              }`
            : ''
        }
      >
        <div className={`flex w-full justify-end`}>
          {!editable || !setText ? null : editMode ? (
            <div className={`flex w-full justify-end`}>
              {saving ? (
                <div className="flex items-center justify-end">
                  <Refresh className="h-5 w-5 animate-spin" />
                  {t('common:saving')}
                </div>
              ) : (
                <div className="flex items-center justify-end">
                  <Cloud className="h-5 w-5" />
                  {t('common:saved')}
                </div>
              )}
            </div>
          ) : (
            <Tooltip content={t('common:Edit')}>
              <Pencil
                className="h6 w-6 cursor-pointer text-primary"
                onClick={onEditStart}
              />
            </Tooltip>
          )}
        </div>
        <Editor
          defaultValue={text}
          readOnly={!editMode}
          {...(!editMode ? { value: text } : {})}
          onChange={onChange}
          uploadImage={(file) => uploadSimple('filesOfInstructions', file)}
          theme={{
            ...theme,
            fontFamily: `Poppins, ${theme.fontFamily}`,
            noticeInfoBackground: 'var(--color-surfaces-soft)',
            noticeInfoText: 'var(--color-black-default)',
            noticeTipBackground: 'var(--color-primary-soft)',
            noticeTipText: 'var(--color-black-default)',
            noticeWarningBackground: 'var(--color-warning-soft)',
            noticeWarningText: 'var(--color-warning-default)',
            textHighlight: 'var(--color-primary-soft)',
            textHighlightForeground: 'var(--color-primary-default)',
            zIndex: theme.zIndex + (zIndex || 0),
          }}
        />
      </div>
      {editMode && (
        <>
          <div className="mt-2 flex justify-end">
            <Button
              onClick={() => setEditMode(false)}
              text={t('common:Close')}
              size="md"
              design="secondary"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MDReactComponent;
