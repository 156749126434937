// const createHistory = require('history').createBrowserHistory;
import { createBrowserHistory as createHistory, Location } from 'history';

export default createHistory({
  /* pass a configuration object here if needed */
});

export type FromLocationType = Location<HistoryState>;

export type HistoryState = {
  email: string;
  from: FromLocationType;
  error: string;
  message: string;
  actionCode: string;
  dataSession?: {
    title: string;
    theme: string;
    peerProfile: string;
  };
};
