import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'frameworks/formik/Input';
import { useEffect } from 'react';

export type YesNoModalProps = {
  open?: boolean;
  onClose?: () => void;
  onClickYes: () => void;
  onClickNo?: () => void;
  textYes?: string;
  textNo?: string;
  disabledYes?: boolean;
  disabledNo?: boolean;
  body?: React.ReactNode;
  children?: React.ReactNode;
  confirmationCode?: string;
  size?: 'base' | 'lg';
  critical?: boolean;
  title?: string;
};

const YesNoModal = ({
  open,
  onClose,
  onClickYes,
  onClickNo,
  textYes,
  textNo,
  body,
  children,
  confirmationCode,
  size = 'base',
  critical = false,
  disabledYes = false,
  disabledNo = false,
  title,
}: YesNoModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [confirmationCodeValue, setConfirmationValue] = useState('');
  const enableButtonYes =
    !confirmationCode ||
    confirmationCode.toUpperCase() === confirmationCodeValue;

  const modalSize =
    size === 'lg'
      ? 'max-w-screen-xs sm:max-w-md md:max-w-xl'
      : 'max-w-screen-xs sm:w-96';

  useEffect(() => {
    setConfirmationValue('');
  }, [open]);
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      body={
        <div>
          {body || children}
          {confirmationCode && (
            <div className="mt-4">
              <Formik
                initialValues={{ code: '' }}
                validate={(values) => {
                  setConfirmationValue(values.code);
                }}
                onSubmit={() => {
                  // Do nothing
                }}
              >
                <Form>
                  <Input
                    name="code"
                    label={`${t(
                      'common:confirmCodeMessage'
                    )} [[${confirmationCode}[[`}
                    placeholder={t('common:codeConfirmationPlaceholder')}
                  />
                </Form>
              </Formik>
            </div>
          )}
        </div>
      }
      className={modalSize}
      footer={
        <div className="flex items-center space-x-4">
          <Button
            disabled={disabledNo}
            text={textNo || t('common:no')}
            onClick={onClickNo || onClose}
            design={critical ? 'primary' : 'secondary'}
            size={critical ? 'base' : 'md'}
          />
          <Button
            disabled={disabledYes || !enableButtonYes}
            color={critical ? 'danger' : 'primary'}
            text={textYes || t('common:yes')}
            onClick={onClickYes}
          />
        </div>
      }
    />
  );
};

export default YesNoModal;
