import React from 'react';
import { useField } from 'formik';

import { useOnChange } from './hooks';

export type NumberInputProps = {
  name: string;
  label?: string;
  min?: number;
  max?: number;
  readOnly?: boolean;
  renderEmpty?: () => JSX.Element;
  onChangeValue?: (value: string, hasError: boolean) => void;
};

const NumberInput = ({
  name,
  label,
  min,
  max,
  readOnly = false,
  renderEmpty = () => <></>,
  onChangeValue,
}: NumberInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  useOnChange(name, onChangeValue);

  return (
    <div className="w-full">
      {label ? (
        <label htmlFor={name} className="text-lg font-semibold">
          {label}
        </label>
      ) : null}
      {readOnly ? (
        field.value && field.value.length > 0 ? (
          <p className="w-full flex-grow text-lg font-light">{field.value}</p>
        ) : (
          <div className="w-full">{renderEmpty()}</div>
        )
      ) : (
        <>
          <input
            type="number"
            className={`form-input mt-2 w-full rounded-lg border p-3 outline-none ${
              hasError ? 'border-danger' : 'border-surfaces-divider'
            }`}
            min={min}
            max={max}
            {...field}
            value={field.value || ''}
            onChange={(event) => helpers.setValue(event.target.value)}
          />
          {hasError ? (
            <div className="font-medium text-danger">{meta.error}</div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default NumberInput;
