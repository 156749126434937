import React, { cloneElement } from 'react';
import Tooltip from 'components/Tooltip';

export type RoundedButtonProps = {
  children: JSX.Element;
  color?: 'gray' | 'primary' | 'success' | 'warning' | 'danger';
  size?: 'sm' | 'base' | 'lg';
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  hoverText?: string;
  tooltipText?: string;
};

const RoundedButton = ({
  children,
  color = 'gray',
  size = 'base',
  onClick,
  className = '',
  disabled = false,
  hoverText,
  tooltipText,
}: RoundedButtonProps): JSX.Element => {
  const colorClasses =
    color === 'primary'
      ? 'text-primary-medium bg-primary-soft hover:text-primary'
      : color === 'success'
      ? 'text-success-medium bg-success-soft hover:text-success'
      : color === 'warning'
      ? 'text-warning-medium bg-warning-soft hover:text-warning'
      : color === 'danger'
      ? 'text-danger-medium bg-danger-soft hover:text-danger'
      : 'text-black-soft bg-gray-soft hover:bg-surfaces-divider';

  const [bgSize, iconSize] =
    size === 'sm'
      ? ['w-6 h-6', 'w-4 h-4']
      : size === 'lg'
      ? ['w-12 h-12', 'w-8 h-8']
      : ['w-9 h-9', 'w-6 h-6'];
  return (
    <Tooltip content={tooltipText}>
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={`group relative flex min-w-min items-center justify-center ${bgSize} rounded-full ${
          disabled
            ? 'cursor-not-allowed bg-gray-soft text-gray-300'
            : `${colorClasses} cursor-pointer`
        } ${className}`}
      >
        <span className={`absolute top-0 left-0 flex ${bgSize}`}>
          {cloneElement(children, {
            className: `m-auto ${children.props.className || ''} ${iconSize}`,
          })}
        </span>

        {!disabled && (
          <div className={`flex flex-row items-center`}>
            <span className={`hidden group-hover:block ${bgSize}`}></span>
            <span
              className={`invisible w-0 ${
                hoverText
                  ? 'pr-3 text-sm font-semibold group-hover:visible group-hover:w-max'
                  : ''
              }`}
            >
              {hoverText}
            </span>
          </div>
        )}
      </button>
    </Tooltip>
  );
};

export default RoundedButton;
