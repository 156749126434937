import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';

import { Check, Selector } from 'assets/icons';

import { usePrevious } from 'utils/utils';
import { useEffect } from 'react';
import { useCallback } from 'react';

export type Item<T> = {
  value: T;
  description: string;
};

export type ListboxProps<T> = {
  items: Item<T>[];
  defaultValue?: T;
  setValue?: (value: T) => void;
  placeholder?: string;
  className?: string;
  errorMode?: boolean;
  size?: 'sm' | 'base';
  maxHClass?: string;
  compact?: boolean;
};

const OurListbox = <T,>({
  items,
  defaultValue,
  setValue,
  placeholder,
  className,
  errorMode = false,
  size = 'base',
  maxHClass = 'max-h-72',
  compact = false,
}: ListboxProps<T>) => {
  const [selected, setSelected] = useState<T | undefined>(defaultValue);

  const previousDefaultValue = usePrevious(defaultValue);

  const handleSelection = useCallback(
    (value: T): void => {
      if (setValue) {
        setValue(value);
      }
      setSelected(value);
    },
    [setValue]
  );

  useEffect(() => {
    if (defaultValue === undefined && defaultValue !== previousDefaultValue) {
      setSelected(undefined);
    }
    if (defaultValue && defaultValue !== previousDefaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue, previousDefaultValue, handleSelection]);

  const getItemDescription = (value: T | undefined) => {
    const item = items.find((item) => item.value === value);
    return item?.description || value || placeholder;
  };
  return (
    <Listbox value={selected} onChange={handleSelection}>
      <div className={`relative w-full ${className}`}>
        <Listbox.Button
          className={`group relative w-full ${
            size === 'sm' ? 'p-2' : 'p-3'
          } pr-10 text-left ${
            selected ? 'text-black' : 'text-gray-400'
          } cursor-default rounded-lg border bg-white ${
            errorMode ? 'border-danger' : 'border-surfaces-divider'
          } cursor-pointer`}
        >
          <span className="block group-hover:font-medium">
            {getItemDescription(selected)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex content-end items-center pr-2 text-black group-hover:text-primary">
            <Selector
              className="h-6 w-6 stroke-3/2 group-hover:stroke-5/2"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options
          className={`absolute z-20 mt-1 w-full overflow-auto rounded-md border border-surfaces-divider bg-white py-1 text-base ${maxHClass}`}
        >
          {!defaultValue && (
            <Listbox.Option key={'null'} value={undefined} disabled={true} />
          )}
          {items.map((item, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) =>
                `${
                  active ? 'bg-primary-soft text-primary' : 'text-black'
                } relative cursor-pointer select-none py-1 ${
                  compact ? 'px-2' : 'pl-10 pr-4'
                }`
              }
              value={item.value}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`${
                      selected
                        ? `font-medium ${compact ? 'text-primary' : ''}`
                        : 'font-normal'
                    }  block`}
                  >
                    {item.description}
                  </span>
                  {selected && !compact ? (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 text-primary`}
                    >
                      <Check
                        className="h-6 w-6 stroke-3/2"
                        aria-hidden="true"
                      />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default OurListbox;
