import React, { Component } from 'react';
import _ from 'lodash';

import css from './index.module.css';

import Colors from 'constants/Colors';

import AdminGroupPost from 'componentsOld/Group/AdminGroupPost';
import AdminGroupCard from 'componentsOld/Group/AdminGroupCard';

const randomHeaderColor = (index) => {
  const colors = [
    {
      color: Colors.colorRedHeaderText,
      backgroundColor: Colors.colorRedHeaderBackground,
    },
    {
      color: Colors.colorBlueHeaderText,
      backgroundColor: Colors.colorBlueHeaderBackground,
    },
    {
      color: Colors.colorGreenHeaderText,
      backgroundColor: Colors.colorGreenHeaderBackground,
    },
    {
      color: Colors.colorPurpleHeaderText,
      backgroundColor: Colors.colorPurpleHeaderBackground,
    },
    {
      color: Colors.colorGreyHeaderText,
      backgroundColor: Colors.colorGreyHeaderBackground,
    },
    {
      color: Colors.colorYellowHeaderText,
      backgroundColor: Colors.colorYellowHeaderBackground,
    },
  ];
  return colors[index % colors.length].color;
};

class AdminGroupList extends Component {
  state = {
    isActiveAdd: false,
    isEditing: false,
  };

  handleAdd = (index) => () => {
    const { isActiveAdd } = this.state;
    if (typeof isActiveAdd === 'number' && isActiveAdd === index) {
      this.setState({ isActiveAdd: false });
    } else {
      this.setState({ isActiveAdd: index });
    }
  };

  renameGroup = (group) => (name) => {
    this.props.updateGroup({
      ...group,
      name,
    });
  };

  render() {
    const {
      groups,
      onRemove,
      onRemovePost = () => null,
      onMovePost,
      groupPrefix,
    } = this.props;
    const { isEditing } = this.state;
    return (
      <div className={css.container}>
        {groups &&
          groups.map(
            (group, index) =>
              group && (
                <AdminGroupCard
                  key={index}
                  number={group.number}
                  groupPrefix={groupPrefix}
                  title={group.topic}
                  cardinality={group.posts?.length || 0}
                  activeMembers={
                    _.countBy(group.posts || [], 'creatorActive')[true] || 0
                  }
                  color={randomHeaderColor(group.number)}
                  toggleRename={() =>
                    this.setState({
                      isEditing: this.state.isEditing === index ? false : index,
                    })
                  }
                  onDelete={onRemove(group.id)}
                  // onRename={this.renameGroup(group)}
                  isEditing={isEditing === index}
                >
                  {group.posts &&
                    group.posts.map(
                      (post, indexPost) =>
                        post && (
                          <AdminGroupPost
                            groups={groups}
                            groupPrefix={groupPrefix}
                            key={post.id}
                            {...(post || {})}
                            onDelete={() => onRemovePost({ group, post })}
                            onMove={(targetId, topicId) => {
                              onMovePost(post.id, group.id, targetId, topicId);
                            }}
                            highlighted={this.props.highlight(post)}
                          />
                        )
                    )}
                </AdminGroupCard>
              )
          )}
      </div>
    );
  }
}
export default AdminGroupList;
