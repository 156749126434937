import React from 'react';
import { useTranslation } from 'react-i18next';
import { Productions } from 'types/types';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import PieChart from 'frameworks/recharts/PieChart';

const processCriteria = (
  criteriaDef: Productions,
  criteriaData: Record<string, Record<string, string>>
): Record<
  string,
  { name: string; data: { name: string; value: number }[] }
> => {
  const criteriaDefReal = _.pickBy(
    criteriaDef,
    (_val, key) => key.startsWith('crit') && _.size(criteriaData[key]) > 0
  );
  return _.mapValues(criteriaDefReal, (criteriumDef, criteriaKey) => ({
    name: criteriumDef.description || criteriumDef.name,
    data: Object.entries(
      _.groupBy(
        Object.entries(criteriaData[criteriaKey]).map(([key, option]) => ({
          key,
          option,
        })),
        'option'
      )
    ).map(([option, ids]) => ({ name: option, value: ids.length })),
  }));
};

export type ParticipantAnalysisProps = {
  postProductions: Nullable<Productions>;
  criteriaData: Nullable<Record<string, Record<string, string>>>;
  marginClasses: string;
};

const ParticipantAnalysis = ({
  postProductions,
  criteriaData,
  marginClasses,
}: ParticipantAnalysisProps) => {
  const { t } = useTranslation();

  const groups = processCriteria(postProductions || {}, criteriaData || {});

  return _.size(groups) > 0 ? (
    <div className={`${marginClasses} flex flex-col items-start space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:Population')}</h2>
      <div className="flex flex-row flex-wrap justify-center">
        {Object.entries(groups).map(([key, data]) => (
          <div key={key} className="flex max-w-[190px] flex-col items-center">
            <PieChart data={data.data} />
            <p className="text-center">{data.name}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default ParticipantAnalysis;
