import { Communities, RoleBar, Sessions, TrueSet } from 'types/types';
import { database as db, NOW, increment, fetchData } from 'services/firebase';
import { diff } from 'utils/utils';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import _ from 'lodash';

export const changeCommunityName = async (
  communityId: string,
  name: string
): Promise<void> => {
  await db.ref(`communities/${communityId}/name`).set(name);
};

export const addCommunity = async (name: string): Promise<string> => {
  const communityRef = db.ref('communities').push();

  await (
    await communityRef
  ).set({
    name,
    createdAt: NOW,
    nbMembers: 0,
  });

  return communityRef.key!;
};

export const addUserToCommunity = async (
  userId: string,
  communityId: string
) => {
  const isMember = await hasMember(communityId, userId);

  if (!isMember) {
    await db.ref().update({
      [`/communitiesOfUsers/${userId}/${communityId}`]: true,
      [`/usersOfCommunities/${communityId}/${userId}`]: true,
      [`/communities/${communityId}/nbMembers`]: increment(1),
    });
  }
};

export const addAdminToCommunity = async (
  userId: string,
  communityId: string
) => {
  const isMember = await hasMember(communityId, userId);

  if (!isMember) {
    await db.ref().update({
      [`/communitiesOfAdmins/${userId}/${communityId}`]: true,
      [`/adminsOfCommunities/${communityId}/${userId}`]: true,
      [`/communities/${communityId}/nbMembers`]: increment(1),
    });
  }
};

export const removeUserFromCommunity = (
  userId: string,
  communityId: string
) => {
  return db.ref().update({
    [`/communitiesOfUsers/${userId}/${communityId}`]: null,
    [`/usersOfCommunities/${communityId}/${userId}`]: null,
    [`/communities/${communityId}/nbMembers`]: increment(-1),
  });
};

export const removeAdminFromCommunity = (
  userId: string,
  communityId: string
) => {
  return db.ref().update({
    [`/communitiesOfAdmins/${userId}/${communityId}`]: null,
    [`/adminsOfCommunities/${communityId}/${userId}`]: null,
    [`/communities/${communityId}/nbMembers`]: increment(-1),
  });
};

export const promoteUserAsAdmin = async (
  userId: string,
  communityId: string
): Promise<void> => {
  if (!(await isUser(communityId, userId))) {
    throw new Error('User is not user of community');
  }
  await Promise.all([
    removeUserFromCommunity(userId, communityId),
    addAdminToCommunity(userId, communityId),
  ]);
};

export const demoteAdminAsUser = async (
  userId: string,
  communityId: string
): Promise<void> => {
  if (!(await isAdmin(communityId, userId))) {
    throw new Error('User is not admin of community');
  }
  await Promise.all([
    removeAdminFromCommunity(userId, communityId),
    addUserToCommunity(userId, communityId),
  ]);
};

export const setCommunitiesOfUser = async (
  userId: string,
  communities: Record<string, true>,
  oldCommunities: Record<string, true>
) => {
  const { adds, dels } = diff(
    Object.keys(oldCommunities),
    Object.keys(communities)
  );

  await Promise.all([
    ...adds.map((communityId) => addUserToCommunity(userId, communityId)),
    ...dels.map((communityId) => removeUserFromCommunity(userId, communityId)),
  ]);
};

export const setUsersOfCommunity = async (
  communityId: string,
  users: Record<string, true>,
  oldUsers: Record<string, true>
) => {
  const { adds, dels } = diff(Object.keys(oldUsers), Object.keys(users));

  await Promise.all([
    ...adds.map((userId) => addUserToCommunity(userId, communityId)),
    ...dels.map((userId) => removeUserFromCommunity(userId, communityId)),
  ]);
};

export const setAdminsOfCommunity = async (
  communityId: string,
  users: Record<string, true>,
  oldUsers: Record<string, true>
) => {
  const { adds, dels } = diff(Object.keys(oldUsers), Object.keys(users));

  await Promise.all([
    ...adds.map((userId) => addAdminToCommunity(userId, communityId)),
    ...dels.map((userId) => removeAdminFromCommunity(userId, communityId)),
  ]);
};

const changeCommunityIdOfDocs = async (
  sessionId: string,
  newCommunityId: string
) => {
  const docs: Record<
    string,
    {
      communityId: string;
    }
  > = (
    await db
      .ref(`contentDocsMeta`)
      .orderByChild('sessionId')
      .equalTo(sessionId)
      .once('value')
  ).val();

  await Promise.all(
    Object.entries(docs || {}).map(([id, meta]) =>
      db.ref(`contentDocsMeta/${id}/communityId`).set(newCommunityId)
    )
  );
};

export const changeSessionCommunity = async (
  sessionId: string,
  newCommunityId: string
): Promise<void> => {
  db.ref(`sessionsNext/${sessionId}/communityId`).set(newCommunityId);
  await changeCommunityIdOfDocs(sessionId, newCommunityId);
};

export const changeUserCommunity = async (
  userId: string,
  oldCommunityId: string,
  newCommunityId: string
): Promise<void> => {
  await addUserToCommunity(userId, newCommunityId);
  await removeUserFromCommunity(userId, oldCommunityId);
};

// source community is merge into target
export const mergeCommunities = async (
  communitySourceId: string,
  communityTargetId: string
): Promise<void> => {
  // migrate sessions
  const sessions: Sessions =
    (
      await db
        .ref(`sessionsNext`)
        .orderByChild('communityId')
        .equalTo(communitySourceId)
        .once('value')
    ).val() || {};

  await Promise.all(
    Object.keys(sessions).map((sessionId) =>
      changeSessionCommunity(sessionId, communityTargetId)
    )
  );

  // migrate users
  const usersIds: Record<string, true> =
    (
      await db.ref(`usersOfCommunities/${communitySourceId}`).once('value')
    ).val() || {};

  await Promise.all(
    Object.keys(usersIds).map((userId) =>
      changeUserCommunity(userId, communitySourceId, communityTargetId)
    )
  );

  // remove community
  await db.ref(`/communities/${communitySourceId}`).remove();
};

export const useCommunities = (
  userId: string,
  role: RoleBar,
  sessionId?: string
): [Communities | undefined, boolean] => {
  const loadArray = [
    {
      path: 'communities',
    },
    {
      path: `communitiesOfAdmins/${userId}`,
    },
  ];

  if (sessionId) {
    loadArray.push({
      path: `sessionsNext/${sessionId}/communityId`,
    });
  }

  useFirebaseConnect(loadArray);

  const sessionCommunityId: string | undefined = useSelector((state: any) =>
    sessionId
      ? state.firebase.data.sessionsNext?.[sessionId]?.communityId
      : undefined
  );

  const allCommunities: Communities | undefined = useSelector(
    (state: any) => state.firebase.data.communities
  );

  const communitiesOfAdmin: Record<string, true> | undefined = useSelector(
    (state: any) => state.firebase.data.communitiesOfAdmins?.[userId]
  );

  if (!isLoaded(allCommunities) && !isLoaded(communitiesOfAdmin)) {
    return [undefined, true];
  }

  let communities = allCommunities || {};
  if (role !== 'superadmin') {
    communities = _.pickBy(
      communities,
      (_val, key) => communitiesOfAdmin?.[key]
    );
  }

  if (sessionCommunityId) {
    const communityOfSession = allCommunities?.[sessionCommunityId];
    if (communityOfSession) {
      communities[sessionCommunityId] = communityOfSession;
    }
  }

  return [communities, false];
};

export const useAllUserCommunitiesId = (userId: string): [TrueSet, boolean] => {
  useFirebaseConnect([
    {
      path: `communitiesOfAdmins/${userId}`,
    },
    {
      path: `communitiesOfUsers/${userId}`,
    },
  ]);

  const communitiesOfAdmin: Record<string, true> | undefined = useSelector(
    (state: any) => state.firebase.data.communitiesOfAdmins?.[userId]
  );

  const communitiesOfUsers: Record<string, true> | undefined = useSelector(
    (state: any) => state.firebase.data.communitiesOfUsers?.[userId]
  );

  const communitiesIds = {
    ...(communitiesOfAdmin || {}),
    ...(communitiesOfUsers || {}),
  };

  return [communitiesIds, isLoaded(communitiesOfAdmin, communitiesOfUsers)];
};

export const useAllUserCommunities = (
  userId: string
): [Communities, boolean] => {
  const [communitiesIdsToLoad] = useAllUserCommunitiesId(userId);

  useFirebaseConnect(
    Object.keys(communitiesIdsToLoad).map(
      (communityId) => `communities/${communityId}`
    )
  );

  const communities: Communities = useSelector((state: any) =>
    _.mapValues(
      communitiesIdsToLoad,
      (_val, communityId) => state.firebase.data.communities?.[communityId]
    )
  );

  return [
    communities,
    isLoaded(
      // communitiesOfAdmin,
      // communitiesOfUsers,
      ...Object.values(communities)
    ),
  ];
};

export const isAdmin = async (
  communityId: string,
  userId: string
): Promise<boolean> => {
  const isAdmin = await fetchData<true>(
    `adminsOfCommunities/${communityId}/${userId}`
  );

  return !!isAdmin;
};

export const isUser = async (
  communityId: string,
  userId: string
): Promise<boolean> => {
  const member = await fetchData<true>(
    `usersOfCommunities/${communityId}/${userId}`
  );

  return !!member;
};

export const hasMember = async (
  communityId: string,
  userId: string
): Promise<boolean> => {
  const member = await Promise.all([
    fetchData<true>(`usersOfCommunities/${communityId}/${userId}`),
    fetchData<true>(`adminsOfCommunities/${communityId}/${userId}`),
  ]);

  return _.some(member);
};

export const removeMemberFromCommunity = async (
  communityId: string,
  userId: string
): Promise<void> => {
  const [isU, isA] = await Promise.all([
    isUser(communityId, userId),
    isAdmin(communityId, userId),
  ]);
  if (isU) {
    await removeUserFromCommunity(userId, communityId);
  }
  if (isA) {
    await removeAdminFromCommunity(userId, communityId);
  }
};
