import React from 'react';
import { createStyleMap } from 'types/typesUtils';

import Colors from 'constants/Colors';

import ColorBanner from 'componentsOld/ColorBanner';

const Button = ({
  children,
  icon,
  style,
  active,
  center,
  onClick,
  limitWidth,
  ...props
}: {
  children?: React.ReactNode;
  icon?: {
    src: string;
    style: React.CSSProperties;
  };
  style?: React.CSSProperties;
  active?: boolean;
  center?: boolean;
  onClick?: () => void;
  limitWidth?: boolean;
}): JSX.Element => {
  const stylesText: React.CSSProperties = center
    ? { textAlign: 'center' }
    : { textAlign: 'left' };
  return (
    <ColorBanner
      {...props}
      onClick={active ? onClick : undefined}
      style={{
        ...styles.viewMain,
        ...{
          cursor: active ? 'pointer' : '',
          maxWidth: limitWidth ? 240 : undefined,
          width: limitWidth ? '100%' : undefined,
        },
        ...style,
      }}
      {...{ active, center }}
    >
      {icon ? (
        <div style={styles.viewTextWithIcon}>
          <img
            style={{ ...styles.img, ...icon.style }}
            src={icon.src}
            alt="next"
          />
          <span
            style={{
              ...styles.text,
              ...stylesText,
            }}
          >
            {children}
          </span>
        </div>
      ) : (
        <div
          style={{
            ...styles.text,
            ...stylesText,
          }}
        >
          {children}
        </div>
      )}
    </ColorBanner>
  );
};

// pass props to the button like onClick

Button.defaultProps = {
  active: true,
  center: true,
  color: Colors.colorAccent,
};

const styles = createStyleMap({
  viewMain: {},
  img: {
    padding: 8,
    height: 20,
    width: 20,
  },
  viewTextWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    padding: 8,
    color: Colors.colorTextDarkBackground,
    fontWeight: 'bold',
  },
});

export default Button;
