import { useUser, useUserType } from 'model/users';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'types/types';
import { Nullable } from 'types/typesUtils';

type UserContextType = {
  isUserLoaded: boolean;
  userEmail: Nullable<string>;
  userName: Nullable<string>;
  userId: Nullable<string>;
  userType: Nullable<UserType>;
};

const defaultUserContext: UserContextType = {
  isUserLoaded: false,
  userEmail: undefined,
  userName: undefined,
  userId: undefined,
  userType: undefined,
};

const UserContext = React.createContext<UserContextType>(defaultUserContext);

export type UserContextProviderProps = {
  userId?: string;
  children: React.ReactNode;
};

export const UserContextProvider = ({
  userId,
  children,
}: UserContextProviderProps) => {
  const [user, userLoaded] = useUser(userId || '');
  const [userType, userTypeLoaded] = useUserType(userId || '');

  const userContext = {
    isUserLoaded: userLoaded && userTypeLoaded,
    userEmail: user?.email,
    userName: user?.name,
    userId: userId,
    userType: userType,
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export const useUserContextWithDefaults = (): {
  isUserLoaded: boolean;
  userEmail: string;
  userName: string;
  userId: string;
  userType: UserType;
} => {
  const { t } = useTranslation();

  const { isUserLoaded, userEmail, userId, userType, userName } =
    useContext(UserContext);

  return {
    isUserLoaded,
    userName: userName || t('users:UnknownUser'),
    userEmail: userEmail || t('users:UnknownUser'),
    userId: userId || '',
    userType: userType || 'participant',
  };
};
