import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from 'types/types';
import { useOrganizersAsUsers } from 'model/dataHooks';

export type OrganizersDescriptionProps = { sessionId: string };

const OrganizersDescription = ({
  sessionId,
}: OrganizersDescriptionProps): JSX.Element => {
  const { t } = useTranslation();

  const [organizers, organizersLoaded] = useOrganizersAsUsers(
    sessionId || 'nowayyoufindme'
  );

  const organizersArray: User[] = Object.values(organizers || {});

  return organizersLoaded && organizersArray?.length > 0 ? (
    <>
      <p className="">{t('sessions:OrganizedBy')}</p>
      <p>
        <span className="font-semibold">{organizersArray[0].name}</span>{' '}
        {organizersArray.length > 1 && (
          <>
            {t('misc:and')}{' '}
            <span className="font-semibold">
              {organizersArray.length > 2
                ? t('common:XOtherPeople', {
                    count: organizersArray.length - 1,
                  })
                : organizersArray[1].name}
            </span>
          </>
        )}
      </p>
    </>
  ) : (
    <></>
  );
};

export default OrganizersDescription;
