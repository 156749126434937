import { remoteConfig } from './firebase';

export type RemoteConfig = {
  obfuscate_posts: boolean;
};

const defautConfig = {
  obfuscate_posts: true,
};

remoteConfig.defaultConfig = defautConfig;

export default remoteConfig;

// would we benefit to use a useEffect ?
export const useObfuscatePosts = (): boolean =>
  remoteConfig.getBoolean('obfuscate_posts');
