import React, { useState } from 'react';
import { Switch as HSwitch } from '@headlessui/react';
import { useMemo } from 'react';

export type SwitchProps = {
  enabled?: boolean;
  label?: string;
  onChange?: (value: boolean) => void;
};
const Switch = ({ enabled, onChange, label }: SwitchProps): JSX.Element => {
  const [state, setState] = useState(false);
  const handleChange = onChange ? onChange : setState;

  const computedState = useMemo(() => {
    return enabled !== undefined ? enabled : state;
  }, [state, enabled]);

  return (
    <HSwitch.Group>
      <div className="flex items-center">
        {label ? (
          <HSwitch.Label className="mr-4 text-base font-medium">
            {label}
          </HSwitch.Label>
        ) : null}
        <HSwitch
          checked={computedState}
          onChange={handleChange}
          className={`${
            computedState ? 'bg-primary' : 'bg-gray-300'
          }  relative inline-flex h-8 w-16 shrink-0 cursor-pointer rounded-full focus:outline-none`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${
              computedState
                ? 'translate-x-8 border-primary '
                : 'translate-x-0 border-gray-300'
            }
            pointer-events-none box-content inline-block h-6 w-6 rounded-full border-4 bg-white transition-transform duration-150 ease-in-out`}
          />
        </HSwitch>
      </div>
    </HSwitch.Group>
  );
};

export default Switch;
