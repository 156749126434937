import React, { useState } from 'react';
import Switch from 'components/Switch';
import { Mode } from 'types/ui';

import { useTranslation } from 'react-i18next';

type LayoutFormPreviewProps = {
  mode: Mode;
  form: JSX.Element;
  view: JSX.Element;
};

const LayoutFormPreview = ({
  mode,
  form,
  view,
}: LayoutFormPreviewProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState(false);
  const { t } = useTranslation();

  return mode === 'editor' ? (
    <div className="flex w-full flex-col 2xl:grid 2xl:grid-cols-2">
      <div className="mb-2 inline-flex w-full justify-end pr-4 2xl:hidden">
        <Switch
          enabled={showPreview}
          label={t('common:previewDesign')}
          onChange={setShowPreview}
        />
      </div>
      <div
        className={`${
          showPreview ? 'hidden' : 'flex-grow'
        } h-full p-4 2xl:flex 2xl:flex-grow`}
      >
        <div className="mx-auto">{form}</div>
      </div>
      <div
        className={`${
          showPreview ? 'flex-col' : 'hidden'
        }  px-8 py-12 2xl:flex 2xl:flex-col 2xl:bg-surfaces-soft`}
      >
        <div className="mb-4 hidden text-center text-xl font-light uppercase 2xl:block">
          {t('common:preview')}
        </div>
        <div className="rounded-lg bg-white p-8">{view}</div>
      </div>
    </div>
  ) : (
    <div className="mx-auto w-full bg-white px-4 pb-16 lg:px-8">{view}</div>
  );
};

export default LayoutFormPreview;
