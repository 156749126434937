import React, { useState } from 'react';
import { Select, Divider, Input, Button, Space, Typography, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { makeKey } from 'utils/utils';

const { Text } = Typography;

const { Option } = Select;

const EditableSearchableMultiselect = ({
  placeholder,
  selection,
  options,
  addOption,
  deleteOption,
  changeOption,
  onSelectionChange,
}: {
  placeholder?: string;
  selection: Record<string, true>;
  options: Record<string, string>;
  addOption?: (option: string) => void;
  deleteOption?: (optionId: string) => void;
  changeOption?: (optionId: string, newValue: string) => void;
  onSelectionChange: (selection: Record<string, true>) => void;
}) => {
  const [name, setName] = useState('');

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    addOption!(name);
    setName('');
  };

  const onChange = (value: string[]) => {
    onSelectionChange(
      value.reduce(
        (prec: Record<string, true>, key) => ({
          ...prec,
          [key]: true,
        }),
        {}
      )
    );
  };

  return (
    <Select
      style={{ width: 240 }}
      mode="multiple"
      showSearch
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={Object.keys(
        _.pickBy(selection || {}, (_val, key) => !!options[key])
      )}
      tagRender={({ value, onClose }) => {
        return (
          <Tag closable onClose={onClose}>
            {options[value as string]}
          </Tag>
        );
      }}
      filterOption={(input, option) => {
        return option?.data
          ? makeKey(option.data).includes(makeKey(input))
          : false;
      }}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          {addOption && (
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={name}
                onChange={onNameChange}
              />

              <Button type="link" onClick={addItem}>
                <PlusOutlined /> Add item
              </Button>
            </div>
          )}
        </div>
      )}
    >
      {Object.entries(options).map(([key, desc]) => (
        <Option key={key} value={key} data={desc}>
          <Space direction="horizontal" size="small">
            <Text
              editable={
                changeOption && {
                  onChange: (val: string) => changeOption(key, val),
                }
              }
            >
              {desc}
            </Text>
            {deleteOption && (
              <Button
                type="link"
                size="small"
                icon={<DeleteOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteOption(key);
                }}
              />
            )}
          </Space>
        </Option>
      ))}
    </Select>
  );
};

export default EditableSearchableMultiselect;
