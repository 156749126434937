import Button from 'components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectCurrentRefinements } from 'react-instantsearch-core';

const ClearRefinements = connectCurrentRefinements(({ items, refine }: any) => {
  const { t } = useTranslation();
  return (
    <Button
      design="secondary"
      size="md"
      onClick={() => {
        refine(items);
      }}
      text={t('common:Clear')}
    />
  );
});

export default ClearRefinements;
