import React, { ReactNode, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createSessionLite } from 'model/sessions';

import Layout from './Layout';
import Link from 'components/Link';
import Button from 'components/Button';
import ExtendedInput from 'frameworks/formik/ExtendedInput';
import Alert from 'components/Alert';

import { gerErrorDescription } from 'services/firebase';
import { authenticateUser } from 'model/users';
import { HistoryState } from 'utils/history';

type FormData = {
  email: string;
  password: string;
};

const SignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const state = location.state;
  const intialEmail = state?.email || '';
  const [globalErrorMessage, setGlobalErrorMessage] = useState<
    string | null | ReactNode
  >(null);
  const [currentEmail, setCurrentEmail] = useState(intialEmail);
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const continueUrl =
    query.get('continueUrl') && encodeURIComponent(query.get('continueUrl')!);
  const isCreatingSession = !!state?.dataSession;

  const links = {
    signUp: {
      text: t('login:linkSignup'),
      to: {
        pathname: '/sign-up',
        search: `${continueUrl ? `?continueUrl=${continueUrl}` : ''}`,
        state: {
          email: currentEmail,
          from: state?.from,
          message: state?.message,
          dataSession: state?.dataSession,
        },
      },
    },
    forgetPassword: {
      text: `${t('login:linkforgetPassword')}`,
      to: {
        pathname: '/forget-password',
        search: `${continueUrl ? `?continueUrl=${continueUrl}` : ''}`,
        state: { email: currentEmail, from: state?.from },
      },
    },
  };

  const SubmitButton = (): JSX.Element => {
    const { isValid } = useFormikContext<FormData>();

    return (
      <Button
        disabled={!isValid}
        loading={loading}
        type="submit"
        text={t('login:signinButton')}
      />
    );
  };

  return (
    <Layout
      title={
        isCreatingSession
          ? t('login:signinTitleToCreateSession')
          : t('login:signinTitle')
      }
      subtitleText={t('login:linkSignupDescription')}
      subtitleLink={links.signUp}
    >
      <div className="mt-6 md:mt-10">
        {globalErrorMessage ? (
          <Alert
            title={t('login:signinErrorTitle')}
            message={globalErrorMessage}
            type="error"
            className="mb-4"
          />
        ) : null}
        {state?.message ? (
          <Alert
            title={t('common:information')}
            message={state?.message}
            type="warning"
            className="mb-4"
          />
        ) : null}
        <Formik
          validateOnMount
          initialValues={{ email: intialEmail, password: '' }}
          validationSchema={Yup.object({
            password: Yup.string().required(t('form:passwordRequired')),
            email: Yup.string()
              .email(t('form:invalideEmail'))
              .required(t('form:fieldRequired')),
          })}
          onSubmit={async (values) => {
            setGlobalErrorMessage(null);
            setLoading(true);
            try {
              const user = await authenticateUser(
                values.email,
                values.password
              );
              if (user?.uid) {
                if (isCreatingSession) {
                  createSessionLite(
                    user.uid,
                    state.dataSession!.title,
                    state.dataSession!.theme,
                    state.dataSession!.peerProfile,
                    (accessCode) => {
                      if (accessCode) {
                        history.push(`/${accessCode}`);
                      }
                    }
                  );
                } else {
                  history.push({
                    pathname: continueUrl
                      ? decodeURIComponent(continueUrl)
                      : '/',
                    state: state,
                  });
                }
              }
            } catch (err) {
              console.error(err);
              const error = err as any;
              console.log(error.code);
              setGlobalErrorMessage(
                <>
                  {gerErrorDescription(error.code)}.{' '}
                  {error.code === 'auth/user-not-found' ? (
                    <>
                      {t('login:Didyou')}{' '}
                      <Link
                        to={links.signUp.to}
                        text={t('login:createAnAccount')}
                      />
                    </>
                  ) : null}
                </>
              );
            }
            setLoading(false);
          }}
        >
          <Form>
            <ExtendedInput
              label={t('login:emailAddressLabel')}
              name="email"
              inputType="email"
              onChange={setCurrentEmail}
              autoComplete="username"
            />
            <ExtendedInput
              className="mt-4"
              label={t('login:passwordLabel')}
              name="password"
              inputType="password"
              autoComplete="current-password"
            />
            <div className="mt-2 3xl:mt-4">
              <Link
                to={links.forgetPassword.to}
                text={links.forgetPassword.text}
              />
            </div>
            <div className="mt-6 flex w-full flex-row-reverse">
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

export default SignIn;
