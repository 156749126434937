import Button from 'components/Button';
import Copyable from 'components/Copyable';
import _ from 'lodash';
import { countVotes } from 'model/votes';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionRecord, ProductionMeta, RSet, Topic, Users } from 'types/types';
import { Nullable } from 'types/typesUtils';
import PostBlock from './blocks/PostBlock';

export type BestPostsByTopicsProps = {
  sessionId: string;
  postsSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  postsMeta: Nullable<RSet<ProductionMeta>>;
  votes: Nullable<Record<string, string>>;
  participants: Users;
  topics: Nullable<RSet<Topic>>;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const BestPostsByTopics = ({
  sessionId,
  postsSummaries,
  posts,
  postsMeta,
  votes,
  participants,
  topics,
  allowAutomatedSynthesis,
  marginClasses,
}: BestPostsByTopicsProps) => {
  const [viewAllTopics, setViewAllTopics] = useState<RSet<boolean>>({});
  const { t } = useTranslation();

  const toggleViewAllTopics = (topicKey: string) => {
    setViewAllTopics((record) => {
      const newRecord = { ...record };
      newRecord[topicKey] = !record[topicKey];
      return newRecord;
    });
  };

  const topicsPost = useMemo(() => {
    return Object.entries(postsMeta || {}).reduce<Record<string, string[]>>(
      (prev, [userId, postMeta]) => {
        const post = posts?.[userId];

        if (postMeta.topic && post) {
          if (prev[postMeta.topic]) {
            prev[postMeta.topic].push(userId);
          } else {
            prev[postMeta.topic] = [userId];
          }
        }
        return prev;
      },
      {}
    );
  }, [postsMeta, posts]);

  const countedVotes = countVotes(votes, posts || {});

  const bestPostsByTopics = _.mapValues(topicsPost, (posts, topicId) =>
    _.sortBy(
      posts,
      (p1, p2) => (countedVotes[p1] || 0) - (countedVotes[p2] || 0)
    ).slice(0, viewAllTopics[topicId] ? undefined : 2)
  );

  return topics && _.size(bestPostsByTopics) > 0 ? (
    <div className={`${marginClasses}`}>
      <h2 className="text-xl font-semibold">
        {t('sessions:BestPostsByTopics')}
      </h2>
      {Object.entries(bestPostsByTopics).map(([topicId, postsId]) => {
        return postsId.length > 0 && topics[topicId] ? (
          <div className="mt-8 space-y-2" key={topicId}>
            <Copyable top={0} textToCopy={topics[topicId].description}>
              <h3 className="text-xl font-semibold">
                {topics[topicId].description}
              </h3>
            </Copyable>
            <div className="space-y-4">
              {postsId.map((userId) => {
                return posts?.[userId] ? (
                  <PostBlock
                    key={userId}
                    allowAutomatedSynthesis={allowAutomatedSynthesis}
                    sessionId={sessionId}
                    userId={userId}
                    post={posts[userId]}
                    postSummary={postsSummaries?.[userId] || null}
                    user={participants[userId]}
                  />
                ) : null;
              })}
              <Button
                text={
                  viewAllTopics[topicId]
                    ? t('common:seeLess')
                    : t('common:seeMore')
                }
                onClick={() => toggleViewAllTopics(topicId)}
                size="sm"
              />
            </div>
          </div>
        ) : null;
      })}
    </div>
  ) : null;
};

export default BestPostsByTopics;
