import React, { useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { UserType } from 'types/types';
import DocView from './DocView';
import ExplorerView from './ExplorerView';

export type AllContentViewProps = {
  sessionId: string;
  userId: string;
  userType: UserType;
};

const AllContentView = ({
  sessionId,
  userId,
  userType,
}: AllContentViewProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const [fallbackUrl, setFallbackUrl] = useState<null | string>(null);

  return (
    <Switch>
      <Route path={`${url}/:docId`}>
        <DocView fallbackUrl={fallbackUrl || url} />
      </Route>
      <Route exact path={`${url}`}>
        <ExplorerView
          sessionId={sessionId}
          userId={userId}
          userType={userType}
          onDocSelect={() => {
            setFallbackUrl(`${pathname}${search}`);
          }}
        />
      </Route>
    </Switch>
  );
};

export default AllContentView;
