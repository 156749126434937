import * as React from 'react';

function SvgMindmap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={3.273} cy={5.617} r={2.5} stroke="currentColor" />
      <circle
        cx={12}
        cy={11}
        r={2.5}
        fill="currentColor"
        stroke="currentColor"
      />
      <circle cx={12.09} cy={20.975} r={2.5} stroke="currentColor" />
      <circle cx={20.924} cy={5.877} r={2.5} stroke="currentColor" />
      <path
        stroke="currentColor"
        d="M5.33 6.738l4.648 3.018m4.381.103l4.651-3.021m-6.869 6.657v4.85"
      />
    </svg>
  );
}

export default SvgMindmap;
