import React from 'react';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

type PostTypeProps = {
  compact?: boolean;
  content: string;
  userName: string;
  actionIcon?: JSX.Element;
  obfuscate?: boolean;
  obfuscateAction?: () => void;
};

const Post = ({
  compact = false,
  content,
  userName,
  actionIcon,
  obfuscate,
  obfuscateAction,
}: PostTypeProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="group relative flex w-full rounded-xl border border-surfaces-divider py-4 px-5">
      <div className="flex-grow pr-3">
        <p
          className={`${compact ? 'text-base' : 'text-lg'} ${
            content === '' ? 'italic text-black-soft' : ''
          } ${obfuscate ? 'select-none blur-sm' : ''}`}
        >
          {content || t('sessions:YouDidNotShare')}
        </p>

        <div className="mt-2 flex items-center">
          <Avatar name={userName} size="sm" />
          <p className="ml-3 flex-grow text-sm font-light text-black-soft">
            {userName}
          </p>
        </div>
      </div>
      {actionIcon ? <div className="flex h-auto">{actionIcon}</div> : null}
      {obfuscate ? (
        <div className="absolute inset-0 hidden flex-col items-center justify-center rounded-xl bg-white p-2 opacity-80 group-hover:flex">
          <p className="text-center opacity-100">
            {t('sessions:ToSeeThisPostYouNeedToParticipate')}
            {obfuscateAction ? (
              <Button
                size="sm"
                text={t('sessions:Participate')}
                onClick={obfuscateAction}
              />
            ) : (
              t('sessions:Participate')
            )}
          </p>
        </div>
      ) : undefined}
    </div>
  );
};

export default Post;
