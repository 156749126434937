import * as React from 'react';

function SvgMicrophoneOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M15 6.182V5a3 3 0 00-6 0v6a3 3 0 00.178 1.008zM12 22h4m-4 0H8m4-4v4m7-11a7 7 0 01-7 7" />
      <path
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.678 21.35l18.55-18.527"
      />
      <path d="M6.53 15.36C6.076 14.908 4.983 13.183 5 11m7.01 7.001c-1.493.032-2.92-.584-3.095-.748" />
    </svg>
  );
}

export default SvgMicrophoneOff;
