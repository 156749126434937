import NumberMetric, { NumberMetricProps } from 'components/NumericMetric';
import { getDocMeta } from 'model/docManagement';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityData, SessionTemplate } from 'types/types';
import { Nullable } from 'types/typesUtils';
import { flattenDocs, useList } from 'utils/utils';
import _ from 'lodash';
import Spinner from 'components/Spinner';

export type KeyNumbersProps = {
  sessionTemplate: Nullable<SessionTemplate>;
  activitiesData: Nullable<Record<string, ActivityData>>;
  marginClasses: string;
};

const KeyNumbers = ({
  sessionTemplate,
  activitiesData,
  marginClasses,
}: KeyNumbersProps) => {
  const { t } = useTranslation();

  const [metrics, metricsActions] = useList<NumberMetricProps>([]);

  useEffect(() => {
    let isSubscribed = true;
    if (sessionTemplate && activitiesData) {
      metricsActions.reset();
      Object.values(sessionTemplate.activities || {}).forEach((activity) => {
        Object.values(activity.productions || {}).forEach(
          async (production) => {
            if (production.type === 'document') {
              const documents =
                activitiesData[activity.name]?.productions?.[production.name] ||
                {};
              const docsIdsArray = Object.keys(flattenDocs(documents));

              const filteredDocs = (
                await Promise.all(
                  docsIdsArray.map((docId) => getDocMeta(docId))
                )
              ).filter((doc) => doc?.lastRevisionAt !== undefined);

              const docsCount = _.size(filteredDocs);

              if (isSubscribed) {
                metricsActions.push({
                  label: t('sessions:docsPerActivity', {
                    count: docsCount,
                    activityName: activity.humanName,
                  }),
                  value: docsCount,
                });
              }
            }
          }
        );
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [sessionTemplate, activitiesData, t, metricsActions]);

  return metrics.length > 0 ? (
    <div className={`${marginClasses} space-y-4`}>
      <h2 className="text-xl font-semibold">
        {t('sessions:productionKeyFigures')}
      </h2>
      <div className="flex flex-row flex-wrap items-center justify-center gap-8">
        {metrics.map((metricProps, index) => {
          return <NumberMetric key={index} {...metricProps} mitigate />;
        })}
      </div>
    </div>
  ) : (
    <div className={`${marginClasses} flex w-full justify-center`}>
      <Spinner className="h-8 text-primary" />
    </div>
  );
};

export default KeyNumbers;
