import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {
  Group,
  Activity,
  OrderedActivities,
  MatchingStatus,
  UserActivity,
  Users,
  Activities,
  Groups,
} from 'types/types';
import _ from 'lodash';
import { diff, useDeepEqualMemo } from 'utils/utils';
import {
  addFacilitatorToSession,
  addOrganizerToSession,
  removeFacilitatorFromSession,
  removeOrganizerFromSession,
} from 'model/sessions';
import { isUserActive } from './users';
import { useMemo } from 'react';
import i18n from 'services/i18n';
import { useTopics } from './productions';
import { useTranslation } from 'react-i18next';

export const useGroup = (
  sessionId: string,
  userId: string,
  activity?: Activity
): [
  Group | undefined | null,
  string | undefined | null,
  boolean,
  string | undefined | null
] => {
  const refs = [];
  const groupActivityName =
    activity?.grouping.mode === 'Groups' ? activity.name : undefined;
  if (groupActivityName) {
    refs.push({
      path: `/sessionsNextData/${sessionId}/activities/${groupActivityName}/grouping/groupOfUser/${userId}`,
    });
  }

  useFirebaseConnect(refs);

  const groupId: string | undefined = useSelector((state: any) =>
    groupActivityName
      ? state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
          groupActivityName
        ]?.grouping?.groupOfUser?.[userId]
      : null
  );

  const groupsRef = [];
  if (groupId) {
    groupsRef.push({
      path: `/sessionsNextData/${sessionId}/activities/${groupActivityName}/grouping/groups/${groupId}`,
      storeAs: `/sessionsNextData/${sessionId}/activities/${groupActivityName}/specificGroup/${groupId}`,
    });
  }

  useFirebaseConnect(groupsRef);

  const group: Group | undefined = useSelector((state: any) =>
    groupActivityName
      ? groupId
        ? state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
            groupActivityName
          ]?.specificGroup?.[groupId]
        : groupId
      : null
  );

  return [group, groupId, isLoaded(groupId, group), groupActivityName];
};

export const useGroups = (
  sessionId: string,
  activity?: Activity
): [Groups | undefined, boolean, string | undefined] => {
  const refs = [];
  const groupActivityName =
    activity?.grouping.mode === 'Groups' ? activity.name : undefined;
  if (groupActivityName) {
    refs.push({
      path: `/sessionsNextData/${sessionId}/activities/${groupActivityName}/grouping/groups`,
    });
  }
  useFirebaseConnect(refs);

  const groups: Groups | undefined = useSelector((state: any) =>
    _.get(
      state.firebase.data,
      `sessionsNextData.${sessionId}.activities.${groupActivityName}.grouping.groups`
    )
  );

  return [groups, isLoaded(groups), groupActivityName];
};

export const useFacilitators = (
  sessionId: string
): [
  Record<string, true>,
  boolean,
  (
    facilitators: Record<string, true>,
    oldFacilitators: Record<string, true>
  ) => Promise<void>
] => {
  const facilitatorsPath = `/sessionsNextData/${sessionId}/users/facilitators`;
  useFirebaseConnect([
    {
      path: facilitatorsPath,
    },
  ]);

  const facilitators: Record<string, true> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.facilitators ||
      {}
  );

  const setFacilitators = async (
    facilitators: Record<string, true>,
    oldFacilitators: Record<string, true>
  ) => {
    const { adds, dels } = diff(
      Object.keys(oldFacilitators),
      Object.keys(facilitators)
    );

    await Promise.all([
      ...adds.map((userId) => addFacilitatorToSession(userId, sessionId)),
      ...dels.map((userId) => removeFacilitatorFromSession(userId, sessionId)),
    ]);
  };

  return [facilitators, isLoaded(facilitators), setFacilitators];
};

export const useOrganizers = (
  sessionId: string
): [
  Record<string, true>,
  boolean,
  (
    organizers: Record<string, true>,
    oldOrganizers: Record<string, true>
  ) => Promise<void>
] => {
  const organizersPath = `/sessionsNextData/${sessionId}/users/organizers`;
  useFirebaseConnect([
    {
      path: organizersPath,
    },
  ]);

  const organizers: Record<string, true> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.organizers || {}
  );

  const setOrganizers = async (
    organizers: Record<string, true>,
    oldOrganizers: Record<string, true>
  ) => {
    const { adds, dels } = diff(
      Object.keys(oldOrganizers),
      Object.keys(organizers)
    );

    await Promise.all([
      ...adds.map((userId) => addOrganizerToSession(userId, sessionId)),
      ...dels.map((userId) => removeOrganizerFromSession(userId, sessionId)),
    ]);
  };

  return [organizers, isLoaded(organizers), setOrganizers];
};

export const useOrganizersAsUsers = (
  sessionId: string
): [
  Users,
  boolean,
  (
    organizers: Record<string, true>,
    oldOrganizers: Record<string, true>
  ) => Promise<void>
] => {
  const [organizers, loaded, setOrganizers] = useOrganizers(sessionId);
  const userIds = Object.keys(organizers);

  useFirebaseConnect(userIds.map((userId) => `users/${userId}`));

  const fullsOrganizers: Users = useSelector((state: any) =>
    _.mapValues(
      organizers,
      (_val, userId) =>
        state.firebase.data?.users?.[userId] || {
          name: 'Unknown User',
          email: 'unknown email',
        }
    )
  );

  return [
    fullsOrganizers,
    loaded && isLoaded(...Object.values(fullsOrganizers)),
    setOrganizers,
  ];
};

export const getSelectableActivities = (
  enabledActivities: Record<string, boolean>,
  orderedActivities: OrderedActivities,
  currentDisplayedActivityName: string
) => {
  const enabledActivityFiltered = _.pickBy(enabledActivities, (value) => value);
  const enabledActivityArray = Object.keys(enabledActivityFiltered);
  const enabledActivityName = enabledActivityArray[0];
  const enabledActivity =
    enabledActivityName &&
    orderedActivities.find((activity) => activity.name === enabledActivityName);

  return (activity: Activity) => {
    const isEnabledActivity = activity.name === enabledActivityName;
    const isPreviousActivity =
      enabledActivity && activity.index <= enabledActivity.index;
    const isCurrentlyDisplayed = currentDisplayedActivityName === activity.name;

    return isEnabledActivity || isPreviousActivity || isCurrentlyDisplayed;
  };
};

export const useMatchingStatus = (sessionId: string, activityName: string) => {
  const matchingStatusRef = `sessionsNextData/${sessionId}/activities/${activityName}/grouping/status`;

  useFirebaseConnect({
    path: matchingStatusRef,
  });

  const matchingStatus: MatchingStatus = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
        activityName
      ]?.grouping?.status
  );

  return [matchingStatus, !isLoaded(matchingStatus)];
};

export const useActiveUsers = (
  sessionId: string
): Record<string, UserActivity> => {
  const usersRef = `sessionsNextData/${sessionId}/users/participants`;
  useFirebaseConnect({ path: usersRef });

  const participants: Record<string, true> = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.users
        ?.participants || {}
  );

  useFirebaseConnect(
    Object.keys(participants).map((id) => ({ path: `/usersActivity/${id}` }))
  );

  const usersActivity: Record<string, UserActivity> = useSelector(
    (state: any) =>
      Object.keys(participants).reduce(
        (prec: Record<string, UserActivity>, key) => {
          const user = state.firebase.data.usersActivity?.[key];
          if (user) {
            prec[key] = user;
          }
          return prec;
        },
        {}
      )
  );

  const activeUsers: Record<string, UserActivity> = useMemo(() => {
    return _.pickBy(usersActivity, (activity) => isUserActive(activity));
  }, [usersActivity]);

  const activeUsersMemoize = useDeepEqualMemo(activeUsers);

  return activeUsersMemoize!;
};

export const useServerTimeOffset = (): number => {
  useFirebaseConnect({
    path: '.info/serverTimeOffset',
  });

  const offset: number = useSelector(
    (state: any) => state.firebase.data?.['']?.info?.serverTimeOffset || 0
  );

  return offset;
};

type ContextOptions = {
  userName: string;
  activities: Activities;
  groupPrefix: string;
};
export const useContext = (
  sessionId: string,
  userId: string,
  activity: Activity,
  options?: Partial<ContextOptions>
): Record<string, string> => {
  const { t } = useTranslation();

  useFirebaseConnect(
    options?.activities ? [] : `sessionsNextTemplates/${sessionId}/activities`
  );
  const activities: Activities = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextTemplates?.[sessionId]?.activities ||
      options?.activities ||
      {}
  );

  let prods = useMemo(
    () =>
      _.pickBy(activities['post']?.productions || {}, (production) =>
        production.name.startsWith('crit_')
      ),
    [activities]
  );

  const loadArray = [
    `sessionsNextData/${sessionId}/activities/post/productions/post/${userId}`,
  ];

  if (!options?.userName) {
    loadArray.push(`users/${userId}/name`);
  }

  if (!options?.groupPrefix) {
    loadArray.push(`sessionsNext/${sessionId}/groupPrefix`);
  }

  const [group] = useGroup(sessionId, userId, activity);

  useFirebaseConnect([
    ...loadArray,
    ...Object.values(prods).map(
      (prod) =>
        `sessionsNextData/${sessionId}/activities/post/productions/${prod.name}/${userId}`
    ),
  ]);

  const userName: string = useSelector(
    (state: any) =>
      state.firebase.data.users?.[userId]?.name || options?.userName || ``
  );

  const post = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
        ?.productions?.post?.[userId] || `${userName}`
  );

  const criteria = useSelector((state: any) =>
    _.mapValues(
      prods,
      (_prod, prodName) =>
        state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
          ?.productions?.[prodName]?.[userId] || i18n.t('common:NotProvided')
    )
  );

  const [topics] = useTopics(sessionId);

  const context: Record<string, string> = {
    group_number: `${options?.groupPrefix || ''}${group?.number || ''}`,
    post,
    name: userName,
    topic:
      (group?.topic && topics[group?.topic]?.description) ||
      t('misc:UnknownTopic'),
    ...criteria,
  };

  return context;
};
