import { database as db, fetchData } from 'services/firebase';

import {
  Group,
  Activities,
  MatchingStatus,
  Groups,
  Activity,
} from 'types/types';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import { flattenDocs } from 'utils/utils';
import { isActivityEnabled } from './sessions';
import {
  generateProductionsForUser,
  removeProductionsForUser,
  setPostMetaTopic,
} from './productions';

export const addGroup = async (
  sessionId: string,
  activities: Activities,
  activityName: string,
  number: number | undefined,
  peoples: { id: string }[],
  topicId?: string
): Promise<string> => {
  const groupsRef = `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/`;

  let numReq = db.ref(groupsRef).orderByChild('number');

  if (number) {
    numReq = numReq.equalTo(number);
  } else {
    numReq = numReq.limitToLast(1);
  }

  let numGroup: Record<string, Group> = (await numReq.once('value')).val();

  let realNumber = number;
  if (number) {
    if (numGroup) {
      throw Error('Trying to insert group with already existing number');
    }
  } else {
    realNumber = (Object.values(numGroup || {})[0]?.number || 0) + 1;
  }

  const groupId = db.ref(groupsRef).push();

  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId.key}`
    )
    .set({
      number: realNumber,
      topic: topicId || null,
      users:
        peoples.reduce((prec: Record<string, boolean>, { id }) => {
          prec[id] = true;
          return prec;
        }, {}) || {},
    });

  await Promise.all(
    peoples.map(({ id: userId }) => {
      return Promise.all([
        db
          .ref(
            `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groupOfUser/${userId}`
          )
          .set(groupId.key),
        updateUserTopic(sessionId, activities, activityName, userId, topicId),
      ]);
    }) || []
  );

  if (groupId.key === null) {
    throw Error('Oh no!');
  }

  return groupId.key;
};

export const getGroups = async (
  sessionId: string,
  activityName: string
): Promise<Groups> => {
  const groups: Groups | null = (
    await db
      .ref(
        `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/`
      )
      .once('value')
  ).val();

  return groups || {};
};

export const getGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string
): Promise<Group | null> => {
  return fetchData<Group>(
    `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}`
  );
};

export const setMatchingStatus = async (
  sessionId: string,
  activityName: string,
  status: MatchingStatus
) => {
  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/status`
    )
    .set(status);
};

export const removeUserFromGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userId: string
): Promise<void> => {
  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}/users/${userId}`
    )
    .remove();

  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groupOfUser/${userId}`
    )
    .remove();

  await removeProductionsForUser(sessionId, activityName, groupId, userId);
};

export const removeUsersFromGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userIds: string[]
): Promise<void[]> =>
  Promise.all(
    userIds.map((userId) =>
      removeUserFromGroup(sessionId, activityName, groupId, userId)
    )
  );

export const addUserToGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userId: string
): Promise<void> => {
  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}/users/${userId}`
    )
    .set(true);

  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groupOfUser/${userId}`
    )
    .set(groupId);

  if (await isActivityEnabled(sessionId, activityName)) {
    await generateProductionsForUser(sessionId, activityName, groupId, userId);
  }
};

export const addUsersToGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userIds: string[]
): Promise<void[]> =>
  Promise.all(
    userIds.map((userId) =>
      addUserToGroup(sessionId, activityName, groupId, userId)
    )
  );

export const deleteGroup = async (
  sessionId: string,
  activityName: string,
  groupId: string
) => {
  const groups: Groups = (
    await db
      .ref(
        `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups`
      )
      .once('value')
  ).val();

  const group = groups[groupId];

  // we renumber group so that they follow each others
  const updateGroups = _.mapValues(groups || {}, (g: Group) => ({
    ...g,
    number: g.number > group.number ? g.number - 1 : g.number,
  }));

  await Promise.all([
    removeUsersFromGroup(
      sessionId,
      activityName,
      groupId,
      Object.keys(group.users || {})
    ),
    db
      .ref(
        `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups`
      )
      .set(_.pickBy(updateGroups, (_group, id) => groupId !== id)),
  ]);
};

export const moveUserToGroup = async (
  sessionId: string,
  activities: Activities,
  activityName: string,
  sourceGroupId: string,
  targetGroupId: string | undefined,
  userId: string,
  topicId: string | undefined
) => {
  await removeUserFromGroup(sessionId, activityName, sourceGroupId, userId);
  let groupId = targetGroupId;
  if (!groupId) {
    groupId = await addGroup(
      sessionId,
      activities,
      activityName,
      undefined,
      []
    );
  }

  await addUserToGroup(sessionId, activityName, groupId, userId);

  // Change topic if necessary
  await updateUserTopic(sessionId, activities, activityName, userId, topicId);
};

export const updateUserTopic = async (
  sessionId: string,
  activities: Activities,
  activityName: string,
  userId: string,
  topicId: string | undefined
) => {
  const isTopicsEnabled = isActivityGroupingByTopic(activities[activityName]);
  if (isTopicsEnabled) {
    await setPostMetaTopic(sessionId, 'post', userId, topicId || null);
  }
};

export const isActivityGroupingByTopic = (activity: Activity): boolean => {
  const grouping = activity?.grouping;
  return !!(
    grouping.mode === 'Groups' &&
    (grouping.settings.mode === 'algo' ||
      grouping.settings.mode === 'custom_skema') &&
    grouping.settings.topicsActivityName
  );
};

export const resetGroups = async (
  sessionId: string,
  activityName: string
): Promise<void> => {
  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups`
    )
    .remove();

  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groupOfUser`
    )
    .remove();
};

const docsRefsToRemoveAtRef = async (ref: string): Promise<string[]> => {
  const docRecord = (await db.ref(ref).once('value')).val();
  if (docRecord) {
    const docs = flattenDocs(docRecord);
    const docIds = Object.keys(docs);
    return docIds.map((docId) => `/contentDocsMeta/${docId}`);
  } else {
    return [];
  }
};

const addToRemoveRefs = async (array: string[], prom: Promise<string[]>) => {
  const refs = await prom;
  array.push(...refs);
};

export const cleanGroupProductions = async (
  sessionId: string,
  activityName: string,
  activities: Activities,
  groupId: Nullable<string>,
  userId: Nullable<string>
) => {
  const impactedActivities = _.pickBy(
    activities,
    (activity) => activity.name === activityName
  );

  const refsToRemove: string[] = [];
  const promises: Promise<void>[] = [];

  Object.values(impactedActivities).forEach((activity) => {
    Object.values(activity.productions || {}).forEach((production) => {
      if (production.mode === 'ByGroup') {
        let ref = `/sessionsNextData/${sessionId}/activities/${activity.name}/productions/${production.name}`;
        if (groupId) {
          ref += `/${groupId}`;
          if (userId) {
            if (production.multiplicity === 'Each') {
              ref += `/${userId}`;
              if (production.type === 'document') {
                promises.push(
                  addToRemoveRefs(refsToRemove, docsRefsToRemoveAtRef(ref))
                );
              }
            } else {
              if (production.type === 'document') {
                promises.push(
                  (async () => {
                    const docRecord = (await db.ref(ref).once('value')).val();
                    if (docRecord) {
                      const docId = Object.keys(docRecord)[0];
                      refsToRemove.push(
                        `/contentDocsMeta/${docId}/authorsIds/${userId}`
                      );
                    }
                  })()
                );
                // in this case we don't want to put ref in array as the doc remains
                return;
              }
            }
          } else {
            if (production.type === 'document') {
              promises.push(
                addToRemoveRefs(refsToRemove, docsRefsToRemoveAtRef(ref))
              );
            }
          }
        } else {
          if (production.type === 'document') {
            promises.push(
              addToRemoveRefs(refsToRemove, docsRefsToRemoveAtRef(ref))
            );
          }
        }

        refsToRemove.push(ref);
      }
    });
  });

  await Promise.all(promises);

  await Promise.all(refsToRemove.map((ref) => db.ref(ref).remove()));
};

export const callFacilitator = async (
  sessionId: string,
  activityName: string,
  groupId: string
): Promise<void> => {
  await db
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}/assistance`
    )
    .update({ askedAt: new Date().getTime(), reslovedAt: null });
};

export const resolveAssistanceRequest = async (
  sessionId: string,
  activityName: string,
  groupId: string
): Promise<void> => {
  await db;
  db.ref(
    `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}/assistance/reslovedAt`
  ).set(new Date().getTime());
};
