import React from 'react';

import {
  someNode,
  TEditor,
  TElement,
  ToolbarButton,
  ToolbarButtonProps,
  unwrapNodes,
  wrapNodes,
} from '@udecode/plate';

import { usePlateEditorState } from '@udecode/plate-core';
export const toggleWrapNodes = (editor: TEditor, type: string) => {
  if (someNode(editor, { match: { type } })) {
    unwrapNodes(editor, { match: { type } });
  } else {
    wrapNodes(editor, {
      type,
      children: [],
    } as TElement);
  }
};

export const ToolbarToggleBlockButton = ({ ...props }: ToolbarButtonProps) => {
  const editor = usePlateEditorState()!;

  const res =
    !!editor?.selection && someNode(editor, { match: { type: props.type } });

  return (
    <ToolbarButton
      active={res}
      onMouseDown={() => {
        toggleWrapNodes(editor, props.type);
      }}
      {...props}
    />
  );
};
