import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Layout from './Layout';
import Button from 'components/Button';
import ExtendedInput from 'frameworks/formik/ExtendedInput';
import Alert from 'components/Alert';
import Link from 'components/Link';

import { HistoryState } from 'utils/history';
import { gerErrorDescription } from 'services/firebase';
import { confirmPasswordReset } from 'model/users';
import { passwordSchema } from './utils';

type FormData = {
  password: string;
};

const ResetPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation<HistoryState>();
  const state = location.state;

  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null
  );
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { email, actionCode, from } = state;

  const query = new URLSearchParams(useLocation().search);
  const continueUrl =
    query.get('continueUrl') && encodeURIComponent(query.get('continueUrl')!);

  const links = {
    signIn: {
      text: t('login:linkSignin'),
      to: {
        pathname: '/login',
        search: `${continueUrl ? `?continueUrl=${continueUrl}` : ''}`,
        state: {
          email,
          from,
        },
      },
    },
  };

  const SubmitButton = (): JSX.Element => {
    const { values } = useFormikContext<FormData>();

    return (
      <Button
        disabled={!values.password}
        loading={loading}
        type="submit"
        text={t('login:resetPasswordButton')}
      />
    );
  };

  return (
    <Layout
      title={t('login:resetPasswordTitle')}
      subtitleText={t('login:linkResetDescription')}
      subtitleLink={links.signIn}
    >
      <div className="mt-6 md:mt-10">
        {success ? (
          <p className="flex flex-col rounded-md bg-success-soft p-5 text-center text-3xl font-thin text-success">
            {t('login:resetPasswordSuccess')}
            <Link
              to={links.signIn.to}
              text={links.signIn.text}
              color="text-success"
              className="mt-4"
              size="lg"
            />
          </p>
        ) : (
          <>
            {globalErrorMessage ? (
              <Alert
                title={t('login:resetPasswordErrorTitle')}
                message={globalErrorMessage}
                type="error"
                className="mb-4"
              />
            ) : null}
            <Formik
              validateOnMount
              initialValues={{ password: '' }}
              validationSchema={Yup.object({
                password: passwordSchema(t),
              })}
              onSubmit={async (values) => {
                setLoading(true);
                setGlobalErrorMessage(null);
                try {
                  if (actionCode) {
                    await confirmPasswordReset(actionCode, values.password);
                    setSuccess(true);
                  } else {
                    setGlobalErrorMessage(t('login:errorNoCode'));
                  }
                } catch (error) {
                  console.error(error);
                  setGlobalErrorMessage(
                    gerErrorDescription((error as any).code)
                  );
                }
                setLoading(false);
              }}
            >
              <Form>
                <ExtendedInput
                  className="mt-4"
                  label={t('login:passwordLabel')}
                  name="password"
                  inputType="password"
                />
                <div className="mt-6 flex w-full flex-row-reverse">
                  <SubmitButton />
                </div>
              </Form>
            </Formik>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ResetPassword;
