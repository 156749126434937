import {
  Activities,
  DocMeta,
  DocTemplate,
  DocWithMeta,
  Group,
  Groups,
  ProductionVisibility,
  Users,
} from 'types/types';
import { database, fetchData, NOW } from 'services/firebase';
import {
  DocTree,
  extractTemplateHint,
  flattenDocs,
  intersects,
  mapValuesAsync,
  templateReplace,
} from 'utils/utils';
import _ from 'lodash';
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  TNode,
} from '@udecode/plate';
import { ELEMENT_INSTRUCTIONS } from 'frameworks/plate/plugins/instructions-block';
import { ELEMENT_EMBED_LINK } from 'frameworks/plate/plugins/embed-link';
import i18n from 'services/i18n';
import { getTextReplaceSession } from 'model/sessions';
import {
  cleanDocs as pureCleanDocs,
  cleanRevisions as pureCleanRevisions,
} from './pure/docManagement';
import { t } from 'i18next';

const buildDocFromTemplate = async (
  template: {
    items: {
      title: string;
      description: string;
      example: string;
    }[];
  },
  title: string = 'Untitled'
): Promise<TNode[]> => {
  let doc: TNode[] = title
    ? [
        {
          type: 'h1',
          children: [
            {
              text: title,
            },
          ],
        },
      ]
    : [];

  if (template?.items) {
    doc = template.items.reduce(
      (doc: TNode[], { title, description, example }) => {
        doc.push({
          type: 'h2',
          children: [
            {
              text: title,
            },
          ],
        });
        if (description) {
          doc.push({
            type: 'instructions_block',
            source: description,
            children: [
              {
                text: '',
              },
            ],
          });
        }
        if (example) {
          doc.push({
            type: 'p',
            children: [
              {
                text: example,
              },
            ],
          });
        }
        for (let i = 0; i < 5; i++) {
          doc.push({
            type: 'p',
            children: [
              {
                text: '',
              },
            ],
          });
        }
        return doc;
      },
      doc
    );
  }
  return doc;
};

const populateNode = (
  node: TNode,
  context: Record<string, string | TNode[]>,
  textReplaceSession: (text: string) => string
): TNode[] | undefined => {
  if (node.text) {
    const hints = extractTemplateHint(node.text as string);
    const hintOfDoc = hints.find((hint) => _.isArray(context[hint]));
    if (hintOfDoc) {
      return (context[hintOfDoc] as TNode[]) || undefined;
    }
    node.text = textReplaceSession(
      templateReplace(
        node.text as string,
        _.pickBy(context, (val) => _.isString(val)) as Record<string, string>
      )
    );
  }
  if (node.children) {
    const children: TNode[] = node.children as TNode[];
    for (let child of children) {
      const res = populateNode(child, context, textReplaceSession);
      if (res) {
        return res;
      }
    }
  }
  if (node.type === ELEMENT_EMBED_LINK) {
    node.url = textReplaceSession(node.url);
  }
};

const populateDoc = (
  doc: TNode[],
  context: Record<string, string | TNode[]>,
  textReplaceSession: (text: string) => string
): TNode[] => {
  const populatedDoc = doc.reduce((prec: TNode[], node) => {
    const res = populateNode(node, context, textReplaceSession);
    if (res) {
      prec = prec.concat(res!);
    } else {
      prec.push(node);
    }
    return prec;
  }, []);

  return populatedDoc;
};

export const extractContextHintsFromTemplate = (doc: TNode[]): string[] => {
  let hints: string[] = [];

  doc.forEach((node) => {
    if (node.text) {
      hints = hints.concat(extractTemplateHint(node.text as string));
    }
    if (node.children) {
      const children: TNode[] = node.children as TNode[];
      hints = hints.concat(extractContextHintsFromTemplate(children));
    }
  });

  return hints;
};

export type PreContext = Partial<{
  name: string;
  group_number: string;
  topic: string;
  post: string;
  group: Group;
  best: string;
  best_name: string;
  activities: Activities;
}>;

export const getContextFromHints = async (
  sessionId: string,
  activityId: string,
  userId: string,
  hints: string[],
  preContext: PreContext,
  dummyDeps: boolean
): Promise<Record<string, string | TNode[]>> => {
  const context: Record<string, string | TNode[]> = {};

  const activities =
    preContext['activities'] ||
    (await fetchData<Activities>(
      `sessionsNextTemplates/${sessionId}/activities`,
      {}
    ));

  const userName = intersects(hints, ['name', 'post', 'best_name'])
    ? preContext['name']
      ? preContext['name']
      : await fetchData<string>(`users/${userId}/name`, 'UnknownUser')
    : null;

  const post = intersects(hints, ['best', 'best_name', 'post'])
    ? preContext['post']
      ? preContext['post']
      : await fetchData<string>(
          `sessionsNextData/${sessionId}/activities/post/productions/post/${userId}`,
          userName!
        )
    : null;

  const activity = activities[activityId];

  let group: Group | null = null;
  const groupId = await fetchData<string>(
    `sessionsNextData/${sessionId}/activities/${activity.name}/grouping/groupOfUser/${userId}`
  );

  if (intersects(hints, ['best', 'best_name', 'group_number', 'topic'])) {
    if (preContext['group']) {
      group = preContext['group'];
    } else {
      group = await fetchData<Group>(
        `sessionsNextData/${sessionId}/activities/${activityId}/grouping/groups/${groupId}`
      );
    }
  }

  const topic =
    intersects(hints, ['topic']) && group?.topic
      ? preContext['topic']
        ? preContext['topic']
        : await fetchData<string>(
            `sessionsNextData/${sessionId}/topics/${group.topic}/description`,
            t('misc:UnknownTopic')
          )
      : t('misc:UnknownTopic');

  let [best, bestName]: [null | string, null | string] = [null, null];
  if (intersects(hints, ['best', 'best_name'])) {
    if (preContext['best'] && preContext['best_name']) {
      best = preContext['best'];
      bestName = preContext['best_name'];
    } else {
      const votes = await fetchData<Record<string, string>>(
        `sessionsNextData/${sessionId}/activities/choose/productions/vote/`,
        {}
      );
      if (group) {
        const relevantVotes = _.pickBy(
          votes,
          (_vote, key) => group!.users[key]
        );

        const voteCounts: Record<string, number> = _.mapValues(
          group.users || {},
          () => 1
        );
        Object.values(relevantVotes).forEach((userVotes) => {
          Object.keys(userVotes).forEach((key) => {
            if (group!.users[key]) {
              voteCounts[key] = (voteCounts[key] || 0) + 1;
            }
          });
        });

        const votesArray = Object.entries(voteCounts).map(
          ([userId, count]) => ({
            userId,
            count,
          })
        );

        const maxUser = _.maxBy(votesArray, 'count');
        if (maxUser) {
          const [bestPostCandidate, bestPostName]: [
            string | null,
            string | null
          ] = await Promise.all([
            fetchData<string>(
              `sessionsNextData/${sessionId}/activities/post/productions/post/${maxUser.userId}`
            ),
            fetchData<string>(`users/${maxUser.userId}/name`),
          ]);

          best = bestPostCandidate || post;
          bestName = bestPostName || userName;
        }
      }
    }
  }

  let criteria: Record<string, string> = {};
  if (hints.find((el) => el.startsWith('crit_'))) {
    let prods = _.pickBy(activities['post']?.productions || {}, (production) =>
      production.name.startsWith('crit_')
    );

    criteria = await mapValuesAsync(
      prods,
      async (prod) =>
        await fetchData<string>(
          `sessionsNextData/${sessionId}/activities/post/productions/${prod.name}/${userId}`,
          ''
        )
    );
  }

  let productions: Record<string, TNode[]> = {};
  const productionHints = hints.filter((el) => el.startsWith('productions'));
  if (productionHints.length > 0) {
    await Promise.all(
      productionHints.map(async (productionHint) => {
        const parts = productionHint.split('.');

        if (parts.length !== 4) {
          return;
        }
        const [, activityName, productionName, orientation] = parts;
        const sourceActivity = activities?.[activityName];
        const sourceProduction =
          activities?.[activityName]?.productions?.[productionName];
        const sourceGroupingActivity = sourceActivity;

        if (
          !sourceActivity ||
          !sourceProduction ||
          !sourceGroupingActivity ||
          sourceProduction.mode !== 'ByGroup' ||
          sourceProduction.multiplicity === 'Each'
        ) {
          console.log('Reason 1');
          return;
        }

        if (!dummyDeps) {
          const groups: Groups = await fetchData<Groups>(
            `sessionsNextData/${sessionId}/activities/${sourceGroupingActivity.name}/grouping/groups`,
            {}
          );

          group = await fetchData<Group>(
            `sessionsNextData/${sessionId}/activities/${activityId}/grouping/groups/${groupId}`
          );

          const orderedGroups = _.sortBy(
            Object.entries(groups),
            (entry) => entry[1].number
          );

          const nbGroups = orderedGroups.length;

          if (!group?.number || !nbGroups) {
            console.log('Reason 2');
            return;
          }

          let targetGroupId =
            orderedGroups[(1 + (group.number - 1)) % nbGroups];

          if (orientation === 'left') {
            targetGroupId =
              orderedGroups[(-1 + (group.number - 1) + nbGroups) % nbGroups];
          }

          const sourceDocIdRec = await fetchData<Record<string, true>>(
            `sessionsNextData/${sessionId}/activities/${sourceActivity.name}/productions/${sourceProduction.name}/${targetGroupId[0]}`
          );

          if (!sourceDocIdRec && _.size(sourceDocIdRec) !== 1) {
            return;
          }

          const sourceDocId = Object.keys(sourceDocIdRec!)[0];

          const doc = await getDoc(sourceDocId);

          if (doc?.content) {
            productions[productionHint] = doc.content as TNode[];
          }
        } else {
          if (sourceProduction.type === 'document') {
            const res = await makeDoc(
              sessionId,
              sourceActivity.name,
              userId,
              sourceProduction.template,
              sourceProduction.docName,
              sourceProduction.docTitle,
              preContext,
              dummyDeps
            );

            productions[productionHint] = res.doc;
          }
        }
      })
    );
  }

  const pC = preContext as Record<string, string>;

  await Promise.all(
    hints.map(async (hint) => {
      if (pC[hint]) {
        context[hint] = pC[hint];
      } else {
        if (hint === 'group_number') {
          const groupPrefix = await fetchData<string>(
            `sessionsNext/${sessionId}/groupPrefix`,
            ''
          );
          context[hint] = `${groupPrefix}${group?.number || ''}`;
          return;
        }
        if (hint === 'name') {
          context[hint] = userName!;
          return;
        }
        if (hint === 'post') {
          context[hint] = post!;
          return;
        }
        if (hint === 'best') {
          context[hint] = best!;
          return;
        }
        if (hint === 'best_name') {
          context[hint] = bestName!;
          return;
        }
        if (hint === 'topic') {
          context[hint] = topic || '';
          return;
        }
        if (hint.startsWith('crit_')) {
          context[hint] = criteria[hint] || '';
          return;
        }
        if (hint.startsWith('productions')) {
          context[hint] = productions[hint] || '';
          return;
        }
      }
    })
  );

  console.log(context);

  return context;
};

export const makeDoc = async (
  sessionId: string,
  activityId: string | null,
  userId: string,
  template: DocTemplate | null | TNode[],
  rawName: string,
  rawTitle: string,
  context: PreContext,
  dummyDeps: boolean = false
) => {
  const contextHints = [
    ...extractTemplateHint(rawName),
    ...extractTemplateHint(rawTitle),
  ];
  const simpleContext = activityId
    ? await getContextFromHints(
        sessionId,
        activityId,
        userId,
        contextHints,
        context,
        dummyDeps
      )
    : {};

  const name = templateReplace(
    rawName,
    simpleContext as Record<string, string>
  );
  const title = templateReplace(
    rawTitle,
    simpleContext as Record<string, string>
  );

  const textReplaceSession = await getTextReplaceSession(sessionId);

  let doc: TNode[] = [
    {
      type: 'p',
      children: [{ text: 'This is an empty document' }],
    },
  ];
  if (template) {
    if (_.isArray(template)) {
      doc = template;
    } else if (template.type === 'OldTemplate') {
      doc = await buildDocFromTemplate(template.body, title);
    } else {
      const templateMeta = (
        await database.ref(`contentDocsMeta/${template.docId}`).once('value')
      ).val();

      const templateDoc = (
        await database.ref(`contentDocs/${templateMeta.current}`).once('value')
      ).val();

      const contextHints = extractContextHintsFromTemplate(templateDoc);
      const computedContext = activityId
        ? await getContextFromHints(
            sessionId,
            activityId,
            userId,
            contextHints,
            context,
            dummyDeps
          )
        : {};

      doc = populateDoc(templateDoc, computedContext, textReplaceSession);
    }
  }
  return { name, title, doc };
};

export const addDoc = async (
  template: DocTemplate | null | TNode[],
  location: string,
  communityId: string | undefined,
  sessionId: string,
  activityId: string | null,
  groupId: string | undefined,
  rawName: string,
  rawTitle: string,
  authorId: string,
  context: PreContext,
  type: string,
  visibility: ProductionVisibility
) => {
  const { name, doc } = await makeDoc(
    sessionId,
    activityId,
    authorId,
    template,
    rawName,
    rawTitle,
    context
  );

  const ref = database.ref(location);

  const docRef = ref.push();
  const docId: string = docRef.key!;

  const docContentRef = database.ref(`contentDocs/${docId}`);
  await docContentRef.set(doc);

  const meta = {
    id: docId,
    sessionId,
    communityId: communityId || null,
    ...(activityId ? { activityId } : {}),
    ...(groupId ? { groupId } : {}),
    createdAt: NOW,
    // groupNumber,
    name,
    type,
    // fromTemplate: template,
    current: docId,
    revisions: {
      [docId]: NOW,
    },
    authorsIds: {
      [authorId]: true,
    },
    visibility,
  };

  // if (authors) {
  //   const authorsMap = authors.reduce((prec, author) => {
  //     prec[author.id] = author;
  //     return prec;
  //   }, {});
  //   meta.authors = authorsMap;
  // }
  const metaRef = database.ref(`contentDocsMeta/${docId}`);
  await metaRef.set(meta);

  await ref.transaction((raceDoc) => {
    if (!raceDoc) {
      return {
        [docId]: true,
      };
    }
  });

  const docRecord = (await ref.once('value')).val();
  const newDocId = Object.keys(docRecord)[0];
  if (!docRecord && !newDocId) {
    throw Error('This is really bad. Should not happen!');
  }

  // it is not our doc that will be used
  // we clean our mess before leaving
  if (docId !== newDocId) {
    await Promise.all([
      docContentRef.remove(),
      metaRef.remove(),
      await database
        .ref(`contentDocsMeta/${newDocId}/authorsIds/${authorId}`)
        .set(true),
    ]);

    return newDocId;
  }

  return docId;
};

export const addDocTemplate = async () => {
  const docRef = database.ref(`contentDocs`).push();
  const docId = docRef.key!;

  const doc = [
    {
      type: 'p',
      children: [{ text: 'This is an empty document template' }],
    },
  ];

  const docContentRef = database.ref(`contentDocs/${docId}`);
  await docContentRef.set(doc);

  const metaRef = database.ref(`contentDocsMeta/${docId}`);

  const meta = {
    id: docId,
    isTemplate: true,
    current: docId,
    name: 'Template',
    revisions: {
      [docId]: NOW,
    },
  };

  await metaRef.set(meta);

  return docId;
};

export const duplicateDoc = async (oldDocId: string) => {
  const docMeta = (
    await database.ref(`contentDocsMeta/${oldDocId}`).once('value')
  ).val();

  const docContent = (
    await database.ref(`contentDocs/${docMeta.current}`).once('value')
  ).val();

  const docRef = database.ref(`contentDocs`).push();
  const docId = docRef.key!;

  const docContentRef = database.ref(`contentDocs/${docId}`);
  await docContentRef.set(docContent);

  const metaRef = database.ref(`contentDocsMeta/${docId}`);

  const meta = {
    ...docMeta,
    id: docId,
    current: docId,
    revisions: {
      [docId]: NOW,
    },
  };

  await metaRef.set(meta);

  return docId;
};

export const renameDoc = async (
  sessionId: string,
  contentId: string,
  docId: string,
  name: string
) => {
  const ref = database.ref(
    `contentHeaders/${sessionId}/${contentId}/docs/${docId}/name`
  );
  const metaRef = database.ref(`contentDocsMeta/${docId}/name`);

  return Promise.all([metaRef.set(name), ref.set(name)]);
};

export const removeDoc = async (docId: string) => {
  const metaRef = database.ref(`contentDocsMeta/${docId}`);

  await cleanRevisions(docId);
  await metaRef.remove();
};

// export const addAuthorToDoc = async (docId: string, user: User) => {
//   const ref = database.ref(`contentDocsMeta/${docId}/authors/${user.id}`);

//   return ref.set(user);
// };

// export const removeAuthorFromDoc = async (docId, user) => {
//   const ref = database.ref(`contentDocsMeta/${docId}/authors/${user.id}`);

//   return ref.remove();
// };

export const removeDocMeta = async (docId: string) => {
  const metaRef = database.ref(`contentDocsMeta/${docId}`);

  return metaRef.remove();
};

export const removeDocs = async (sessionId: string) => {
  const contentHeadersPath = `contentHeaders/${sessionId}/`;
  const contentHeaders = (
    await database.ref(contentHeadersPath).once('value')
  ).val();

  if (contentHeaders) {
    await Promise.all(
      Object.entries(contentHeaders).map(([groupId]) => {
        return database
          .ref(`contentHeaders/${sessionId}/${groupId}/docs`)
          .remove();
      })
    );

    const docsMeta = (
      await database
        .ref('/contentDocsMeta')
        .orderByChild('sessionId')
        .equalTo(sessionId)
        .once('value')
    ).val();
    if (docsMeta) {
      return Promise.all(
        Object.entries(docsMeta).map(([docId]) =>
          Promise.all([
            database.ref(`/contentDocsMeta/${docId}`).remove(),
            database.ref(`/contentDocs/${docId}`).remove(),
          ])
        )
      );
    }
  }
};

export const regenerateDocs = async (
  communityId: string,
  sessionId: string
) => {
  // await removeDocs(sessionId);

  // const contentHeadersPath = `contentHeaders/${sessionId}/`;
  // const contentHeaders = (
  //   await database.ref(contentHeadersPath).once('value')
  // ).val();

  // const postsByUser = await getPostByUsers(sessionId);

  // await Promise.all(
  //   Object.keys(contentHeaders).map(groupId => {
  //     // return generateDocsForGroup(communityId, sessionId, groupId, postsByUser);
  //   })
  // );

  console.log('Done');
};

// export const generateDocsForGroup = async (
//   communityId,
//   sessionId,
//   groupId,
//   postsByUser
// ) => {
//   const contentHeader = (
//     await database.ref(`contentHeaders/${sessionId}/${groupId}`).once('value')
//   ).val();

//   const dirtyAuthors = await Promise.all(
//     contentHeader.usersId.map(async userId => {
//       const userRef = database.ref(`/users/${userId}`);
//       const user = (await userRef.once('value')).val();
//       return {
//         id: userId,
//         email: user?.email,
//         name: user?.name
//       };
//     })
//   );

//   const authors = dirtyAuthors.filter(
//     ({ email, name, id }) => email && name && id
//   );

//   await addDoc(
//     'synthesisTemplate',
//     communityId,
//     sessionId,
//     contentHeader.number,
//     groupId,
//     'Collaborate',
//     'Here the title of your work',
//     authors
//   );

//   if (contentHeader.usersId) {
//     await Promise.all(
//       contentHeader.usersId.map(async userId => {
//         const post = postsByUser[userId];

//         const author = authors.filter(author => author.id === userId);

//         await addDoc(
//           'contentTemplate',
//           communityId,
//           sessionId,
//           contentHeader.number,
//           groupId,
//           author[0]?.name || 'Unknown person',
//           post?.content || `${author[0]?.name || 'Unknown person'}`,
//           author
//         );
//       })
//     );
//   }
// };

export const getDocMeta = async (docId: string): Promise<DocMeta | null> => {
  const docMeta: DocMeta | null = (
    await database.ref(`contentDocsMeta/${docId}`).once('value')
  ).val();
  return docMeta || null;
};

export const getDoc = async (docId: string): Promise<DocWithMeta | null> => {
  const docMeta: DocMeta | null = await getDocMeta(docId);
  if (!docMeta) {
    return null;
  }
  const doc: TNode[] | null =
    docMeta &&
    (await database.ref(`contentDocs/${docMeta.current}`).once('value')).val();
  if (!doc) {
    return null;
  }
  return { ...docMeta, content: doc };
};

export const getCurrentDocId = async (
  docId: string
): Promise<string | null> => {
  const docMeta: DocMeta | null = (
    await database.ref(`contentDocsMeta/${docId}`).once('value')
  ).val();
  if (!docMeta) {
    return null;
  }
  return docMeta.current;
};

export const getDocs = async (
  sessionId: string,
  activityName: string,
  productionName: string,
  docsIds?: string[]
): Promise<DocWithMeta[]> => {
  const docsIdsTree = (
    await database
      .ref(
        `sessionsNextData/${sessionId}/activities/${activityName}/productions/${productionName}`
      )
      .once('value')
  ).val();

  const flattenedDocsId = flattenDocs(docsIdsTree || {});

  const docs = await Promise.all(
    Object.keys(flattenedDocsId)
      .filter((docId) => !docsIds || docsIds.includes(docId))
      .map((docId) => getDoc(docId))
  );

  return docs.filter(
    (doc) =>
      doc !== null &&
      // filter empty docs
      doc.lastRevisionAt !== undefined
  ) as DocWithMeta[];
};

const cleanRevisions = pureCleanRevisions(database);

export const cleanDocs = pureCleanDocs(database);

export const isDocUpdated = async (docId: string): Promise<boolean> => {
  const docMeta: DocMeta | null = await fetchData<DocMeta>(
    `contentDocsMeta/${docId}`
  );
  if (!docMeta) {
    return false;
  }
  return docMeta.lastRevisionAt !== undefined;
};

export const buildAuthorsLine = (authorsIds: string[], usersNames: Users) => {
  const names = authorsIds
    .map((userId) => usersNames[userId]?.name)
    .filter((userName) => {
      return !!userName;
    });

  if (names.length === 0) {
    return '';
  }
  const frontNames = names.slice(0, names.length - 1);
  const lastName = names[names.length - 1];

  return `${
    frontNames.length > 0
      ? `${frontNames.join(', ')} ${i18n.t('misc:and')} `
      : ''
  }${lastName}`;
};

export const buildCompilation = async (
  sessionId: string,
  activityName: string,
  productionName: string
): Promise<TNode[]> => {
  const docIdsSet = await fetchData<DocTree>(
    `sessionsNextData/${sessionId}/activities/${activityName}/productions/${productionName}`,
    {}
  );

  const docsIdsArray = Object.keys(flattenDocs(docIdsSet));

  const docs = await Promise.all(docsIdsArray.map((docId) => getDoc(docId)));

  // const usersIds = await fetchData<TrueSet>(
  //   `sessionsNextData/${sessionId}/users/participants`,
  //   {}
  // );

  // const users: Users = await mapValuesAsync(
  //   usersIds,
  //   async (_unknown, userId) => {
  //     const user = await fetchUser(userId);
  //     return user;
  //   }
  // );

  const docsParts = docs.reduce((prec: TNode[][], doc) => {
    if (
      doc?.visibility !== 'Private' &&
      // filter empty doc
      doc?.lastRevisionAt !== undefined
    ) {
      const content: TNode[] = (doc?.content || []) as TNode[];
      const part: TNode[] = [];

      const filteredContent: TNode[] = removeInstructions(content);
      const cleanContent: TNode[] = removeEmptyText(filteredContent);

      // if (doc?.authorsIds) {
      //   const authorLine = buildAuthorsLine(
      //     Object.keys(doc.authorsIds || {}),
      //     users
      //   );

      //   if (authorLine !== '') {
      //     part.push({
      //       type: ELEMENT_H1,
      //       children: [
      //         {
      //           text: authorLine,
      //         },
      //       ],
      //     });
      //   }
      // }

      cleanContent.forEach((node) => part.push(node));
      prec.push(part);
    }
    return prec;
  }, []);

  const lengthedParts = docsParts.map((docPart) => ({
    length: computeTextLength(docPart),
    docPart,
  }));

  const sortedPart = _.sortBy(lengthedParts, ({ length }) => -length);

  const empty: TNode[] = [];
  const res = _.concat(empty, ...sortedPart.map(({ docPart }) => docPart));

  return res;
};

export const removeInstructions = (doc: TNode[]): TNode[] => {
  let newNode: TNode[] = [];
  doc.forEach((node) => {
    if (node.type !== ELEMENT_INSTRUCTIONS) {
      newNode.push(node);
    }
  });
  return newNode;
};

export const removeEmptyText = (doc: TNode[]): TNode[] => {
  let newNodes: TNode[] = [];
  let previousBlankLine = true;
  const breakLineRegexp = /[\n]{2,}/gm;

  const isEmptyLine = (node: TNode) =>
    node.children.length === 1 && node.children[0].text.trim() === '';

  const removeBreakLines = (node: TNode): TNode => {
    const updatedNode = { ...node };
    if (updatedNode.children.length === 1 && updatedNode.children[0].text) {
      updatedNode.children[0].text = updatedNode.children[0].text
        .trim()
        .replaceAll(breakLineRegexp, '\n');
    }
    return updatedNode;
  };

  const insertNode = (node: TNode, emptyLine: boolean) => {
    if (!emptyLine || !previousBlankLine) {
      newNodes.push(node);
    }
    previousBlankLine = emptyLine;
  };

  doc.forEach((node) => {
    if (
      [ELEMENT_H1, ELEMENT_H2, ELEMENT_PARAGRAPH, 'paragraph'].includes(
        node.type
      )
    ) {
      insertNode(removeBreakLines(node), isEmptyLine(node));
    } else {
      insertNode(node, false);
    }
  });
  return newNodes;
};

export const computeTextLength = (doc: TNode[]): number => {
  const computeNodeLength = (node: TNode) => {
    if (node.text) {
      return node.text.length;
    } else {
      return computeTextLength(node.children);
    }
  };

  return _.sumBy(doc, (node) => computeNodeLength(node));
};

const serializeChildren = (nodes: TNode[]): string => {
  return nodes
    .map((node) => {
      if (node.text) {
        return node.text;
      } else {
        switch (node.type) {
          case ELEMENT_LINK:
            return `${serializeChildren(node.children)} (${node.url})`;
          default:
            return '';
        }
      }
    })
    .join('');
};

export const textuelizeElement = (node: any) => {
  switch (node.type) {
    case ELEMENT_IMAGE:
      return node.url as string;
    case ELEMENT_EMBED_LINK:
      return node.url;
    case ELEMENT_H1:
      return serializeChildren(node.children) + '\n\n';
    case ELEMENT_H2:
      return serializeChildren(node.children) + '\n\n';
    case ELEMENT_PARAGRAPH:
    case 'paragraph':
      return serializeChildren(node.children) + '\n';

    default:
      console.log('Not supported yet');
  }
};

export const textualizeDoc = (doc: TNode[]): string => {
  const elems = doc.map((node: TNode) => textuelizeElement(node));

  return elems.filter((res) => res !== undefined).join('\n');
};
