import {
  SessionTemplateDescription,
  SessionTemplateFullDescription,
  UserType,
} from 'types/types';
import { duplicateSession, deleteSession } from './sessions';
import { database as db, useSelectorArray } from 'services/firebase';
import _ from 'lodash';
import { diff } from 'utils/utils';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { isGlobalAdmin } from './users';

export const promoteSessionAsTemplate = async (
  sessionId: string,
  ownerId: string,
  communityId: string | undefined,
  description: SessionTemplateDescription
) => {
  const [, templateSessionId] = await duplicateSession(
    sessionId,
    description.name,
    ownerId,
    true,
    {},
    {},
    false,
    true
  );

  // duplicate data ?
  const promises = [
    db.ref(`sessionTemplateDescriptions/${templateSessionId}`).set(description),
  ];

  if (communityId) {
    promises.push(
      db
        .ref(`templatesOfCommunities/${communityId}/${templateSessionId}`)
        .set(true)
    );
    promises.push(
      db
        .ref(`communitiesOfTemplates/${templateSessionId}/${communityId}`)
        .set(true)
    );
  }
  promises.push(
    setTemplateVisibility(templateSessionId, description.visibility)
  );

  await Promise.all(promises);
  return templateSessionId;
};

export const addCommunityToTemplate = (
  templateId: string,
  communityId: string
) => {
  return db.ref().update({
    [`/communitiesOfTemplates/${templateId}/${communityId}`]: true,
    [`/templatesOfCommunities/${communityId}/${templateId}`]: true,
  });
};

export const removeCommunityFromTemplate = (
  templateId: string,
  communityId: string
) => {
  return db.ref().update({
    [`/communitiesOfTemplates/${templateId}/${communityId}`]: null,
    [`/templatesOfCommunities/${communityId}/${templateId}`]: null,
  });
};

export const setCommunitiesOfTemplate = async (
  templateId: string,
  communities: Record<string, true>,
  oldCommunities: Record<string, true>
) => {
  const { adds, dels } = diff(
    Object.keys(oldCommunities),
    Object.keys(communities)
  );

  await Promise.all([
    ...adds.map((communityId) =>
      addCommunityToTemplate(templateId, communityId)
    ),
    ...dels.map((communityId) =>
      removeCommunityFromTemplate(templateId, communityId)
    ),
  ]);
};

export const setTemplateVisibility = async (
  templateId: string,
  visibility: 'public' | 'private'
) => {
  await Promise.all([
    db
      .ref(`publicTemplates/${templateId}`)
      .set(visibility === 'public' ? true : null),
    db
      .ref(`sessionTemplateDescriptions/${templateId}/visibility`)
      .set(visibility),
  ]);
};

export const editTemplateDescription = async (
  templateId: string,
  description: SessionTemplateDescription
) => {
  await Promise.all([
    db.ref(`sessionTemplateDescriptions/${templateId}`).set(description),
    setTemplateVisibility(templateId, description.visibility),
  ]);
};

export const deleteTemplate = async (templateId: string) => {
  const communitiesIds: Record<string, true> =
    (
      await db.ref(`/communitiesOfTemplates/${templateId}`).once('value')
    ).val() || {};
  await Promise.all([
    deleteSession(templateId),
    db.ref(`sessionTemplateDescriptions/${templateId}`).remove(),
    db.ref(`publicTemplates/${templateId}`).remove(),
    db.ref(`communitiesOfTemplates/${templateId}`).remove(),
    ...Object.keys(communitiesIds).map((communityId) =>
      db.ref(`templatesOfCommunities/${communityId}/${templateId}`).remove()
    ),
  ]);
};

export const useSessionTemplateDescriptions = (
  communityId: string,
  userType: UserType
): [Record<string, SessionTemplateFullDescription>, boolean] => {
  useFirebaseConnect([
    `publicTemplates`,
    isGlobalAdmin(userType)
      ? `templatesOfCommunities`
      : `templatesOfCommunities/${communityId}`,
  ]);

  const publicTemplateIds: Record<string, true> = useSelector(
    (state: any) => state.firebase.data.publicTemplates
  );

  const communityTemplateIds: Record<string, true> = useSelector(
    (state: any) => {
      return isGlobalAdmin(userType)
        ? Object.values(
            state.firebase.data.templatesOfCommunities || {}
          ).reduce((current: Record<string, true>, template) => {
            Object.assign(current, template);
            return current;
          }, {})
        : state.firebase.data.templatesOfCommunities?.[communityId];
    }
  );

  const templateIds = {
    ...(publicTemplateIds || {}),
    ...(communityTemplateIds || {}),
  };

  const loadArray: string[] = [];
  Object.keys(templateIds).forEach((templateId) => {
    loadArray.push(`sessionTemplateDescriptions/${templateId}`);
    loadArray.push(`sessionsNext/${templateId}`);
  });

  useFirebaseConnect(loadArray);

  const [isDescriptionsLoaded, templateDescriptions]: [
    boolean,
    Record<string, SessionTemplateFullDescription>
  ] = useSelectorArray(loadArray, (data: any) => {
    return _.mapValues(templateIds, (_val, templateId) => ({
      ...data.sessionTemplateDescriptions?.[templateId],
      description: data.sessionsNext?.[templateId]?.description,
      goals: data.sessionsNext?.[templateId]?.goals,
    }));
  });

  const loaded = isLoaded(
    publicTemplateIds,
    communityTemplateIds,
    isDescriptionsLoaded
  );

  return [loaded ? templateDescriptions : {}, loaded];
};
