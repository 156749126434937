import { Node, Text } from 'slate';
import invariant from 'tiny-invariant';
import { toSlateNode, toSlatePath } from '../utils/convert';
/**
 * Translates a Yjs array event into a slate operations.
 *
 * @param event
 */
export default function translateArrayEvent(editor, event) {
    const targetPath = toSlatePath(event.path);
    const targetElement = Node.get(editor, targetPath);
    invariant(!Text.isText(targetElement), 'Cannot apply array event to text node');
    let offset = 0;
    const ops = [];
    const children = Array.from(targetElement.children);
    event.changes.delta.forEach((delta) => {
        var _a, _b;
        if ('retain' in delta) {
            offset += (_a = delta.retain) !== null && _a !== void 0 ? _a : 0;
        }
        if ('delete' in delta) {
            const path = [...targetPath, offset];
            children.splice(offset, (_b = delta.delete) !== null && _b !== void 0 ? _b : 0).forEach((node) => {
                ops.push({ type: 'remove_node', path, node });
            });
        }
        if ('insert' in delta) {
            invariant(Array.isArray(delta.insert), `Unexpected array insert content type: expected array, got ${JSON.stringify(delta.insert)}`);
            const toInsert = delta.insert.map(toSlateNode);
            toInsert.forEach((node, i) => {
                ops.push({
                    type: 'insert_node',
                    path: [...targetPath, offset + i],
                    node,
                });
            });
            children.splice(offset, 0, ...toInsert);
            offset += delta.insert.length;
        }
    });
    return ops;
}
