import React from 'react';

import Logo from 'components/Logo';
import {
  Arrow as PatternArrow,
  Bubble as PatternBubble,
} from 'assets/patterns/';
import loginImage from 'assets/images/login.jpeg';
import Link, { To } from 'components/Link';

type LayoutProps = {
  title: string;
  subtitleText?: string;
  subtitleLink?: {
    text: string;
    to: To;
  };
  children: React.ReactNode;
};

const Layout = ({
  title,
  subtitleText,
  subtitleLink,
  children,
}: LayoutProps): JSX.Element => {
  return (
    <div className="relative h-screen grid-cols-8 bg-white md:bg-surfaces-soft lg:grid">
      <div className="col-span-2 col-start-7 hidden bg-primary lg:block">
        <PatternBubble className="mx-auto mt-12 h-48 w-48 rotate-180 rounded-full fill-current text-white xl:hidden" />
      </div>
      <div className="absolute inset-0 flex xl:inset-x-16 xl:grid xl:grid-cols-2 2xl:inset-x-32">
        <div className="m-auto w-full sm:w-auto">
          <div className="m-auto h-auto w-full rounded-lg bg-white p-8 sm:w-128 sm:p-12 md:w-160 md:p-16 md:shadow-lg 3xl:w-192">
            <Logo className="h-12 md:h-16 3xl:h-20" />
            <h1
              className={
                'mt-8 text-2xl font-bold text-black md:text-4xl 3xl:text-5xl'
              }
            >
              {title}
            </h1>
            {subtitleText ? (
              <p className={' mt-3 w-full text-base md:text-lg 3xl:text-xl'}>
                {`${subtitleText} `}
                {subtitleLink ? (
                  <Link to={subtitleLink.to} text={subtitleLink.text} />
                ) : null}
              </p>
            ) : null}
            {children}
          </div>
        </div>
        <PatternArrow className="absolute bottom-8 left-16 hidden h-48 w-48 -rotate-45 rounded-full fill-current text-primary lg:block xl:hidden" />
        <div className="m-auto mr-12 hidden xl:block 2xl:m-auto">
          <div className="relative h-128 w-96 2xl:h-144 2xl:w-128 3xl:h-160 3xl:w-144">
            <img
              src={loginImage}
              alt="Description"
              className="h-full object-cover object-center"
            />
            <PatternBubble className="absolute -top-16 -right-16 h-32 w-32 rotate-180 rounded-full fill-current text-white 2xl:-top-28 2xl:-right-28 2xl:h-48 2xl:w-48" />
            <PatternArrow className="absolute -bottom-16 -left-16 h-32 w-32 -rotate-45 rounded-full fill-current text-primary 2xl:-right-14 2xl:-bottom-28 2xl:h-48 2xl:w-48" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
