import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Microphone as MicrophoneIcon,
  MicrophoneOff,
  VideoCamera as VideoCameraIcon,
  VideoCameraOff,
  ShareScreen as ShareScreenIcon,
} from 'assets/icons';

import Tooltip from 'components/Tooltip';

const withTooltip = (
  WrappedComponent: JSX.Element,
  text: string | undefined
) => {
  return (
    <Tooltip
      compact
      colorBgClassName="bg-black"
      content={
        text ? (
          <p className="text-center text-2xs tracking-wide">{text}</p>
        ) : undefined
      }
    >
      {WrappedComponent}
    </Tooltip>
  );
};

const baseClassName = 'h-6 w-6 stroke-3/2';
const normalStateClassName = `${baseClassName} text-white hover:text-opacity-80 cursor-pointer `;
const activatedStateClassName = `${baseClassName} text-secondary hover:text-white hover:text-opacity-50 cursor-pointer `;
const disactivatedStateClassName = `${baseClassName} text-black-soft hover:text-white hover:text-opacity-50 cursor-pointer `;
const disabledStateClassName = `${baseClassName} text-black-soft cursor-not-allowed`;

export type CameraButtonProps = {
  enabled: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const CameraButton = ({
  enabled,
  disabled,
  onClick,
}: CameraButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-full bg-gray-900 bg-opacity-20 p-1 hover:bg-opacity-30">
      {enabled
        ? withTooltip(
            <VideoCameraIcon
              className={
                disabled ? disabledStateClassName : normalStateClassName
              }
              onClick={disabled ? undefined : onClick}
            />,
            disabled ? undefined : t('video:DisableVideo')
          )
        : withTooltip(
            <VideoCameraOff
              className={
                disabled ? disabledStateClassName : disactivatedStateClassName
              }
              onClick={disabled ? undefined : onClick}
            />,
            disabled ? undefined : t('video:EnableVideo')
          )}
    </div>
  );
};

export type MicrophoneButtonProps = {
  enabled: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const MicrophoneButton = ({
  enabled,
  disabled,
  onClick,
}: MicrophoneButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-full bg-gray-900 bg-opacity-20 p-1 hover:bg-opacity-30">
      {enabled
        ? withTooltip(
            <MicrophoneIcon
              className={
                disabled ? disabledStateClassName : normalStateClassName
              }
              onClick={disabled ? undefined : onClick}
            />,
            disabled ? undefined : t('video:DisableMicro')
          )
        : withTooltip(
            <MicrophoneOff
              className={
                disabled ? disabledStateClassName : disactivatedStateClassName
              }
              onClick={disabled ? undefined : onClick}
            />,
            disabled ? undefined : t('video:EnableMicro')
          )}
    </div>
  );
};

export type ShareScreenButtonProps = {
  enabled: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const ShareScreenButton = ({
  enabled,
  disabled,
  onClick,
}: ShareScreenButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-full bg-gray-900 bg-opacity-20 p-1 hover:bg-opacity-30">
      {withTooltip(
        <ShareScreenIcon
          className={
            disabled
              ? disabledStateClassName
              : enabled
              ? activatedStateClassName
              : normalStateClassName
          }
          onClick={!disabled ? onClick : undefined}
        />,
        disabled
          ? t('video:ShareScreenDisabled')
          : enabled
          ? t('video:StopShareScreen')
          : t('video:StartShareScreen')
      )}
    </div>
  );
};

const VideoButtons = {
  Microphone: MicrophoneButton,
  Camera: CameraButton,
  ShareScreen: ShareScreenButton,
};

export default VideoButtons;
