import * as React from 'react';

function SvgShareScreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 11l5-5m0 0l5 5m-5-5v12"
      />
      <rect
        width={20.428}
        height={16.318}
        x={1.786}
        y={3.841}
        strokeLinecap="round"
        strokeLinejoin="round"
        ry={0}
      />
    </svg>
  );
}

export default SvgShareScreen;
