import _ from 'lodash';

const DELAY = 5000;

const activityEvents = ['mousemove', 'click', 'keydown', 'resize'];

export default class ActivityManager {
  _callback?: _.DebouncedFunc<() => void>;

  set heartCallback(cb: () => void) {
    this.destroy();
    this._callback = _.throttle(() => cb(), DELAY);
    this._init();
  }

  _init() {
    activityEvents.forEach((event) => {
      window.addEventListener(event, this._callback!);
    });
  }

  destroy() {
    if (this._callback) {
      this._callback.cancel();
      activityEvents.forEach((event) => {
        window.removeEventListener(event, this._callback!);
      });
    }
  }
}
