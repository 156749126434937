import React, { useMemo } from 'react';
import { AlertType } from 'components/Alert';
import AlertBanner from 'components/AlertBanner';

type AlertProviderProps = {
  children: React.ReactNode;
};

type AlertInfoType = {
  id?: string;
  type: AlertType;
  title: string;
  description: string;
  callToAction?: JSX.Element;
  closable?: boolean;
  duration?: number;
  onEnd?: () => void;
} | null;

type HelpersType = {
  displayAlert: (info: AlertInfoType) => void;
  clearAlert: () => void;
};

type ContextType = {
  alertInfo: AlertInfoType | null;
  setAlertInfo: (info: AlertInfoType) => void;
};

const AlertStateContext = React.createContext<ContextType | undefined>(
  undefined
);

const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const [alertInfo, setAlertInfo] = React.useState<AlertInfoType>(null);

  return (
    <AlertStateContext.Provider
      value={{
        alertInfo: alertInfo,
        setAlertInfo: setAlertInfo,
      }}
    >
      {children}
    </AlertStateContext.Provider>
  );
};

function useAlert(): [string | null, JSX.Element | null, HelpersType] {
  const context = React.useContext(AlertStateContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }
  const { alertInfo, setAlertInfo } = context;

  const helpers = useMemo(
    () => ({
      displayAlert: setAlertInfo,
      clearAlert: () => setAlertInfo(null),
    }),
    [setAlertInfo]
  );

  const [id, banner]: [string | null, JSX.Element | null] = useMemo(() => {
    const id = alertInfo?.id || null;
    const banner = alertInfo ? (
      <AlertBanner
        className="max-w-3xl"
        type={alertInfo.type}
        title={alertInfo.title}
        message={alertInfo.description}
        rounded={false}
        onClose={alertInfo.closable ? helpers.clearAlert : undefined}
        actionButton={alertInfo.callToAction}
        duration={alertInfo.duration}
        onEnd={alertInfo.onEnd}
      />
    ) : null;
    return [id, banner];
  }, [alertInfo, helpers]);

  return [id, banner, helpers];
}

export { AlertProvider, useAlert };
