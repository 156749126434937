import { currentEmail } from 'global';

export const LOGIN_PATH = '/login';

export const VOTES_PER_USER = 3;
export const SESSION_LOCATIONS_TYPES = ['online', 'local'];
export const NB_MAX_CRITERIA = 3;

export const isDevelopper = () => {
  return ['gaetan@wearepeers.com'].indexOf(currentEmail) !== -1;
};

export const DEFAULT_TEMPLATE_ID = '-N3Y6aLiiekZg3IjaWQE';
export const DEFAULT_SESSION_TYPE_VALUE = 'on_site';

export const THEME_MATCH_STRING_LIST = [
  'them',
  'thèm',
  'thém',
  'subject',
  'topic',
];
export const PEER_PROFILE_MATCH_STRING_LIST = ['pair', 'peer'];
export const POST_RESULT_DESCRIPTION_MATCH_STRING_LIST = [
  'result',
  'résult',
  'description',
];
export const MIN_BY_PERSON_STRING_LIST = [
  'xmin/person',
  'xmin/partici',
  'xmin/peopl',
  'x min/person',
  'x min/partici',
  'x min/peopl',
];
export const COMMUNITY_ID_MATCH_STRING_LIST = ['communityId'];

export const SESSION_TEST_SUFFIX = '_test';
