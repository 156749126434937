export const PADDING = 16;
export const PADDING_HALF = 8;
export const PADDING_QUARTER = 4;
export const ICON_SMALL = 16;
export const ICON_SMALL_WITH_PADDING_HALF = 16;
export const ICON_MEDIUM_SMALL = 20;
export const ICON_MEDIUM = 24;
export const ICON_MEDIUM_BIG = 32;
export const ICON_POWERED_BY = 30;
export const BORDER_RADIUS = 10;
export const BORDER_RADIUS_HALF = 5;
export const MOBILE_ADDRESS_BAR_HEIGHT = 60;
export const HEIGHT_STEP_BUTTON = 32;
export const BUTTON_DEFAULT_WIDTH = 90;
