import Copyable from 'components/Copyable';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFormattedDay } from 'services/datetimes';

export type SessionInfoProps = {
  title: string;
  day: Date;
  marginClasses: string;
};

const SessionInfo = ({
  title,
  day,
  marginClasses,
}: SessionInfoProps): JSX.Element => {
  const { t } = useTranslation();

  const formatted = getFormattedDay(moment(day));

  return (
    <div className={`${marginClasses} space-y-8`}>
      <h2 className="text-xl font-semibold">{t('sessions:SessionInfo')}</h2>
      <Copyable top={2} textToCopy={title}>
        <p className="text-4xl font-semibold">{title}</p>
      </Copyable>
      <Copyable top={1} textToCopy={title}>
        <p className="text-2xl ">{formatted}</p>
      </Copyable>
    </div>
  );
};

export default SessionInfo;
