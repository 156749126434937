import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Criterium } from 'types/ui';
import { makeKey } from 'utils/utils';

import { getCriteriaDeps } from 'model/criteriaManagement';

import Input from 'frameworks/formik/Input';
import MultiSelect from 'components/MultiSelect';
import RoundedButton from 'components/RoundedButton';
import { Plus, X, InformationCircle } from 'assets/icons';
import Tag from 'components/Tag';
import _ from 'lodash';
import Button from 'components/Button';
import { NB_MAX_CRITERIA } from 'constants/AppConfig';

import { useOnChange } from 'frameworks/formik/hooks';

import Tooltip from 'components/Tooltip';
import YesNoModal from 'components/YesNoModal';
import FormattedText from 'components/FormattedText';
import { Activity } from 'types/types';

export type ActivityCriteriaProps = {
  name: string;
  sessionId: string;
  readOnly?: boolean;
  onChangeValue?: (value: Criterium[], hasError: boolean) => void;
};

const ActivityCriteria = ({
  name,
  readOnly,
  onChangeValue,
  sessionId,
}: ActivityCriteriaProps): JSX.Element => {
  const [newCriteriaName, setNewCriteriaName] = useState<string | null>(null);
  const [confirmDeleteDataIndex, setConfirmDeleteDataIndex] = useState<
    number | null
  >(null);
  const [deps, setDeps] = useState<Activity[] | null>(null);
  const { t } = useTranslation();
  const { values, errors } = useFormikContext<Record<string, any>>();
  const criteria: Criterium[] = values?.[name];
  const error = errors?.[name];
  /* prevent crash when option is dropped, error => option:[undefined] ? */
  const errorMessage = _.isString(error)
    ? error
    : _.isArray(error) && error.length > 0 && _.isString(error[0])
    ? error[0]
    : undefined;
  const hasError = !!errorMessage;

  useOnChange(name, onChangeValue);

  useEffect(() => {
    const loadActivitiesDeps = async () => {
      const newDeps =
        confirmDeleteDataIndex !== null
          ? Object.values(
              await getCriteriaDeps(
                criteria[confirmDeleteDataIndex].name,
                sessionId
              )
            )
          : null;

      setDeps(newDeps);
    };
    loadActivitiesDeps();
  }, [confirmDeleteDataIndex, criteria, sessionId]);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className="w-full">
          {readOnly ? (
            <div className="space-y-4">
              {Object.values(criteria || {}).map((criteria) => (
                <div key={criteria.name} className="space-y-1">
                  <p>{criteria.instruction}</p>
                  <div>
                    {criteria.options.map((option, index) => {
                      return (
                        <Tag
                          className="mt-2 mr-2"
                          key={index}
                          text={option}
                          color="primary"
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="mt-2 space-y-4">
                {Object.values(criteria || {}).map((criteria, index) => (
                  <div key={criteria.name}>
                    <div className="flex flex-row items-center justify-between">
                      <label className={'text-lg text-primary'}>
                        {t('sessions:postCriteriaLabel', {
                          index: index + 1,
                        })}
                      </label>
                      <RoundedButton
                        color="danger"
                        size="sm"
                        onClick={() => setConfirmDeleteDataIndex(index)}
                      >
                        <X className="stroke-2" />
                      </RoundedButton>
                    </div>
                    <div className="space-y-2 border-l border-primary pl-2">
                      <div>
                        <label
                          htmlFor={`${name}.${index}.description`}
                          className={`text-base font-light`}
                        >
                          <span className="flex flex-row items-center space-x-1">
                            <p>{t('sessions:criteriaName') + ' :'}</p>
                            <Tooltip content={t('sessions:criteriaNameHelp')}>
                              <InformationCircle className="h-5 w-5 stroke-2" />
                            </Tooltip>
                          </span>
                        </label>
                        <Input
                          name={`${name}.${index}.description`}
                          placeholder={t(
                            'sessions:criteriaDescriptionPlaceholder'
                          )}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor={`${name}.${index}.instruction`}
                          className={`text-base font-light`}
                        >
                          <span className="flex flex-row items-center space-x-1">
                            <p>{t('sessions:postCriteriaDescriptionLabel')}</p>
                            <Tooltip
                              content={t('sessions:criteriaInstructionHelp')}
                            >
                              <InformationCircle className="h-5 w-5 stroke-2" />
                            </Tooltip>
                          </span>
                        </label>
                        <Input
                          name={`${name}.${index}.instruction`}
                          placeholder={t(
                            'sessions:criteriaInstructionPlaceholder'
                          )}
                        />
                      </div>
                      {criteria.name ? (
                        <MultiSelect
                          noBorder
                          name={`${name}.${index}.options`}
                          placeholder={t('sessions:criteriaOptionsPlaceholder')}
                          label={t('sessions:postCriteriaOptionsLabel')}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 mb-8 flex flex-row items-center space-x-2">
                {newCriteriaName === null ? (
                  <RoundedButton
                    disabled={
                      newCriteriaName !== null ||
                      _.size(criteria) >= NB_MAX_CRITERIA
                    }
                    color="success"
                    onClick={() => setNewCriteriaName('')}
                    hoverText={t('sessions:addCriterium')}
                    tooltipText={
                      _.size(criteria) >= NB_MAX_CRITERIA
                        ? t('sessions:limitTo3Criteria', {
                            max: NB_MAX_CRITERIA,
                          })
                        : undefined
                    }
                  >
                    <Plus className="stroke-2" />
                  </RoundedButton>
                ) : (
                  <Tooltip content={t('common:Cancel')}>
                    <RoundedButton
                      color="danger"
                      onClick={() => setNewCriteriaName(null)}
                    >
                      <X className="stroke-2" />
                    </RoundedButton>
                  </Tooltip>
                )}

                {newCriteriaName !== null ? (
                  <>
                    <div className="grow">
                      <input
                        className="w-full rounded-md border border-surfaces-divider p-3 text-lg outline-none"
                        placeholder={t('sessions:criteriaName')}
                        onChange={(event) =>
                          setNewCriteriaName(event.target.value)
                        }
                      />
                      <div className="relative h-0 w-full">
                        <div className="absolute inset-x-0 mt-1 flex flex-row space-x-2">
                          <InformationCircle className="h-5 w-5 shrink-0 stroke-2" />
                          <p className="text-sm italic text-black">
                            {t('sessions:criteriaNameHelp')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Button
                      disabled={newCriteriaName.length === 0}
                      text={t('common:Insert')}
                      color="primary"
                      size="md"
                      onClick={() => {
                        setNewCriteriaName(null);
                        arrayHelpers.push({
                          index: _.size(criteria),
                          name: `crit_${makeKey(newCriteriaName)}`,
                          description: newCriteriaName,
                          instruction: '',
                          options: [],
                        });
                      }}
                    />
                  </>
                ) : null}
              </div>
            </>
          )}
          {hasError ? (
            <div className="font-medium text-danger">{error}</div>
          ) : null}
          <YesNoModal
            title={t('misc:deleteCriteriaName', {
              name:
                confirmDeleteDataIndex !== null
                  ? criteria[confirmDeleteDataIndex].description
                  : undefined,
            })}
            size="lg"
            open={confirmDeleteDataIndex !== null}
            onClickNo={() => setConfirmDeleteDataIndex(null)}
            onClickYes={() => {
              if (confirmDeleteDataIndex !== null) {
                arrayHelpers.remove(confirmDeleteDataIndex);
                setConfirmDeleteDataIndex(null);
              }
            }}
            onClose={() => setConfirmDeleteDataIndex(null)}
            textYes={t('common:Delete')}
            textNo={t('common:Cancel')}
            critical
          >
            <FormattedText as="Fragment">
              {deps && deps.length > 0
                ? t('misc:deleteCriteriaWithDependency', {
                    name: deps
                      .map(({ name, humanName }) => humanName || name)
                      .join(', '),
                    count: deps.length,
                  })
                : t('misc:deleteCriteriaWithoutDependency')}
            </FormattedText>
          </YesNoModal>
        </div>
      )}
    />
  );
};

export default ActivityCriteria;
