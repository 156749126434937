import { database as db } from 'services/firebase';
import Copyable from 'components/Copyable';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { computeActivitySummary } from 'services/tln';
import { Activity, LoadableString, Session } from 'types/types';
import { Quote } from 'assets/icons';
import { buildCompilation, textualizeDoc } from 'model/docManagement';
import _ from 'lodash';
import Button from 'components/Button';

export type SummaryBlocProps = {
  sessionId: string;
  session: Session;
  activity: Activity;
  activitySummary: LoadableString;
};

const SummaryBloc = ({
  sessionId,
  session,
  activity,
  activitySummary,
}: SummaryBlocProps) => {
  const { t } = useTranslation();

  const setSummary = useCallback(
    async (state: LoadableString) => {
      await db
        .ref(
          `sessionsNextData/${sessionId}/debrief/summaries/${activity.name}/full`
        )
        .set(state);
    },
    [sessionId, activity.name]
  );

  const computeSummary = useCallback(async () => {
    if (_.size(activity.productions || {}) > 0) {
      try {
        setSummary('loading');
        const compilation = await buildCompilation(
          sessionId,
          activity.name,
          Object.values(activity.productions!)[0].name
        );
        const summary = await computeActivitySummary(
          session.title,
          session.language,
          activity.humanName || activity.name,
          textualizeDoc(compilation)
        );

        await setSummary(summary || null);
      } catch (e) {
        console.error(e);
        setSummary('error');
      }
    }
  }, [sessionId, activity, session.language, session.title, setSummary]);

  useEffect(() => {
    if (!activitySummary) {
      computeSummary();
    }
  }, [activitySummary, computeSummary]);

  return (
    <blockquote className="relative rounded-md bg-gray-50 px-8 pt-6 pb-2 text-lg italic space-y-4">
      <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
      <Copyable textToCopy={activitySummary || ''}>
        <p className="text-gray-600">
          {activitySummary === 'loading'
            ? t('sessions:BeeingGenerated')
            : activitySummary === 'error'
            ? t('sessions:SummaryError')
            : activitySummary === null
            ? t('sessions:NoSummaryYet')
            : activitySummary}
        </p>
      </Copyable>
      {!_.includes([null, 'loading'], activitySummary) ? (
        <Button
          color="gray"
          text={t('sessions:Regenerate')}
          onClick={() => setSummary(null)}
          size="sm"
        />
      ) : null}
    </blockquote>
  );
};

export default SummaryBloc;
