import * as React from 'react';

function SvgNeutral(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#FCEA2B"
        d="M36 13c-12.682 0-23 10.318-23 23s10.318 23 23 23 23-10.318 23-23-10.318-23-23-23z"
      />
      <circle
        cx={36}
        cy={36}
        r={23}
        fill="none"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M27 43h18"
      />
      <path d="M30 31a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3M48 31a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3" />
    </svg>
  );
}

export default SvgNeutral;
