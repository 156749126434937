import * as React from 'react';

function SvgMoveVertically(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round">
        <path d="M2 12.03l21.637.008" strokeDasharray="2, 4" />
        <path
          d="M18.64 16.63l-6.322 6.132-6.321-6.132M5.997 7.43l6.322-6.132L18.64 7.43"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgMoveVertically;
