import * as React from 'react';

function SvgAngry(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#FCEA2B"
        d="M36 13c-12.682 0-23 10.318-23 23s10.318 23 23 23 23-10.318 23-23-10.318-23-23-23z"
      />
      <circle
        cx={36}
        cy={36}
        r={23}
        fill="none"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path d="M30 32.925a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3M48 32.925a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3" />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M23 24l7 4M49 24l-7 4M29.5 43c1.284-.638 3.985-1.03 6.842-.998 2.624.03 4.99.414 6.158.998"
      />
    </svg>
  );
}

export default SvgAngry;
