import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Activity, Production, User } from 'types/types';
import { flattenDocs, templateReplace } from 'utils/utils';
import { WithId } from 'types/typesUtils';
import { useContext } from 'model/dataHooks';
import { useInstruction, useTextReplaceSession } from 'model/sessions';
import {
  resolveAssistanceRequest,
  isActivityGroupingByTopic,
} from 'model/groupManagement';
import { useSessionLiveContext } from 'screens/SessionScreen/SessionLiveScreen';

import LoadingScreen from 'screens/LoadingScreen';
import GroupHeader from 'blocks/GroupHeader';

import ContentCardsEditor from 'componentsOld/Content/ContentCardsEditor';
import MDReactComponent from 'componentsOld/MDReactComponent';
import { LinkWithIcon } from 'components/Link';
import Disclosure from 'components/Disclosure';
import Button from 'components/Button';
import SelectorInput from 'components/SelectorInput';

import {
  ChevronRight,
  CogLight,
  Eye,
  Hand,
  VideoCamera,
  Mindmap,
} from 'assets/icons';
import { Documents } from 'assets/undraw';
import { useActivityProductionData } from 'model/productions';

type ViewListItemProps = {
  title: string;
  description: string;
  onClick: () => void;
  selected: boolean;
  joinedVideo: boolean;
  higlighted: boolean;
  customAction?: JSX.Element;
};

const ViewListItem = ({
  title,
  description,
  onClick,
  selected,
  joinedVideo,
  higlighted,
  customAction,
}: ViewListItemProps): JSX.Element => {
  return (
    <div
      className={`flex cursor-pointer  border-b border-surfaces-divider hover:bg-primary-soft`}
      onClick={onClick}
    >
      <div
        className={`flex w-full flex-row items-center justify-between p-2 xl:p-4 ${
          selected ? 'border-r-2 border-primary' : ''
        }`}
      >
        <div className="flex flex-row items-center ">
          <div>
            <Eye
              className={`mr-2 h-4 w-4 rounded-full stroke-2 text-primary ${
                selected ? 'visible' : 'invisible'
              }`}
            />
            <VideoCamera
              className={`mr-2 h-4 w-4 rounded-full stroke-2 text-primary ${
                joinedVideo ? 'visible' : 'invisible'
              }`}
            />
          </div>

          <div className="flex  flex-col ">
            <p
              className={`text-sm font-semibold xl:text-base ${
                higlighted ? 'text-amber-500' : ''
              }`}
            >
              {title}
            </p>
            <p className="text-xs text-black-soft xl:text-sm">{description}</p>
          </div>
        </div>
        {customAction ? customAction : null}
        <div className="flex flex-row items-center">
          <ChevronRight className="h-6 w-6 stroke-2" />
        </div>
      </div>
    </div>
  );
};

export type DocumentsViewProps = {
  sessionId: string;
  activity: Activity;
  production: Production;
  groupPrefix: string | undefined;
  user: WithId<User>;
  manageGroup: (edit: boolean) => void;
  showMindmap: (showOverview: boolean) => void;
  hasVideo: boolean;
  videoGroupId: string | null;
  selectVideoGroupId: (groupId: string) => void;
  resetVideoGroupId: () => void;
};

const DocumentsView = ({
  sessionId,
  activity,
  production,
  groupPrefix,
  user,
  manageGroup,
  showMindmap,
  hasVideo,
  videoGroupId,
  selectVideoGroupId,
  resetVideoGroupId,
}: DocumentsViewProps) => {
  const { t } = useTranslation();

  const participantsInGroups = activity.grouping.mode === 'Groups';
  const participantsAsGroups = production.mode === 'ByUser';
  const isTopicsEnabled = isActivityGroupingByTopic(activity);

  const [prods, groups, users, productionsLodaed, groupsLoaded] =
    useActivityProductionData(sessionId, activity, production);
  const [searchedParticipant, setSearchedParticipant] = useState<string | null>(
    null
  );
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { selectedGroupId, setSelectedGroupId } = useSessionLiveContext();

  useEffect(() => {
    if (groupsLoaded && participantsInGroups) {
      if (_.size(groups) === 0) {
        manageGroup(true);
      } else if (!selectedGroupId || !groups[selectedGroupId]) {
        let groupId = Object.keys(groups || {})[0] || undefined;
        setSelectedGroupId(groupId);
      }
    }
  }, [
    groups,
    setSelectedGroupId,
    groupsLoaded,
    manageGroup,
    selectedGroupId,
    participantsInGroups,
  ]);

  useEffect(() => {
    if (participantsAsGroups) {
      if (_.size(users) !== 0 && !selectedUserId) {
        setSelectedUserId(Object.keys(users)[0]);
      }
    }
  }, [participantsAsGroups, users, selectedUserId]);

  useEffect(() => {
    if (searchedParticipant && participantsInGroups) {
      Object.entries(groups || {}).forEach(([groupId, group]) => {
        if (group?.users?.[searchedParticipant]) {
          setSelectedGroupId(groupId);
        }
      });
    }
  }, [searchedParticipant, groups, setSelectedGroupId, participantsInGroups]);

  const impersonatedUserId = useMemo(() => {
    const selectedGroupUsers = selectedGroupId
      ? groups?.[selectedGroupId]?.users || {}
      : {};
    return Object.keys(selectedGroupUsers)?.[0] || selectedUserId || user.id;
  }, [groups, selectedGroupId, user.id, selectedUserId]);

  const instruction = useInstruction(sessionId, activity);
  const context = useContext(sessionId, impersonatedUserId, activity, {
    groupPrefix: groupPrefix,
  });

  const textReplaceSession = useTextReplaceSession(
    sessionId,
    activity.name,
    impersonatedUserId
  );

  const flattenedDocs = useMemo(
    () =>
      flattenDocs(
        participantsInGroups
          ? prods[selectedGroupId || '']
          : participantsAsGroups
          ? prods[selectedUserId || '']
          : prods
      ),
    [
      prods,
      selectedGroupId,
      participantsInGroups,
      selectedUserId,
      participantsAsGroups,
    ]
  );

  if (!productionsLodaed || (participantsInGroups && !groupsLoaded)) {
    return <LoadingScreen />;
  }
  return (
    <div className="flex flex-col space-y-14 lg:flex-row lg:space-y-0">
      {participantsInGroups || participantsAsGroups ? (
        <div className="h-max w-full shrink-0 space-y-3 lg:sticky lg:top-6 lg:w-80 xl:w-96">
          <div className="flex w-full flex-col items-start xl:flex-row xl:items-center xl:justify-between">
            <div className="text-2xl font-semibold">
              {participantsInGroups
                ? t('facilitator:groupCount', { count: _.size(groups) })
                : t('facilitator:participantCount', {
                    count: _.size(users),
                  })}
            </div>
            {participantsInGroups ? (
              <LinkWithIcon
                text={t('facilitator:ManageGroup')}
                onClick={() => {
                  manageGroup(true);
                }}
              >
                <CogLight className="h-5 w-5 shrink-0 stroke-3/2 xl:h-6 xl:w-6" />
              </LinkWithIcon>
            ) : null}
          </div>
          {isTopicsEnabled ? (
            <LinkWithIcon
              text={t('facilitator:showGroupsOverview')}
              onClick={() => {
                showMindmap(true);
              }}
            >
              <Mindmap className="h-5 w-5 shrink-0 stroke-2 xl:h-6 xl:w-6" />
            </LinkWithIcon>
          ) : null}
          <div className="pr-2">
            <SelectorInput
              compact
              placeholder={t('facilitator:SearchGroupParticipant')}
              options={users}
              descriptionKey={'name'}
              value={searchedParticipant || ''}
              onChange={(value) => {
                setSearchedParticipant(value);
                if (!participantsInGroups && value) {
                  setSelectedUserId(value);
                }
              }}
              allowClear
            />
          </div>
          {hasVideo ? (
            <div className="flex flex-col items-center space-y-2">
              {videoGroupId === null ? (
                t('video:YourAreInPlenary')
              ) : (
                <>
                  <p>
                    {t('video:YouAreInGroup', {
                      groupNumber: groups?.[videoGroupId]?.number,
                    })}
                  </p>
                  <Button
                    text={t('video:BackToPlenary')}
                    design="secondary"
                    size="sm"
                    onClick={resetVideoGroupId}
                  />
                </>
              )}
            </div>
          ) : null}
          <div className="border-t border-surfaces-divider">
            {participantsInGroups
              ? Object.entries(groups || {}).map(([groupId, group]) => {
                  const isSearchedParticipantBelongsGroup =
                    searchedParticipant &&
                    !!group?.users?.[searchedParticipant];
                  return (
                    <ViewListItem
                      key={groupId}
                      title={t('common:groupX', {
                        number: group.number,
                        groupPrefix: groupPrefix || '',
                      })}
                      description={t('facilitator:participantCount', {
                        count: _.size(group.users),
                      })}
                      onClick={() => {
                        if (!isSearchedParticipantBelongsGroup) {
                          setSearchedParticipant(null);
                        }
                        setSelectedGroupId(groupId);
                      }}
                      selected={selectedGroupId === groupId}
                      joinedVideo={videoGroupId === groupId}
                      higlighted={!!isSearchedParticipantBelongsGroup}
                      customAction={
                        !!group?.assistance?.askedAt &&
                        !group?.assistance?.reslovedAt ? (
                          <div className="flex flex-col items-center">
                            <p className="text-sm">
                              {t('sessions:groupNeedAssistance')}
                            </p>
                            <Button
                              icon={<Hand className="stroke-2" />}
                              size="sm"
                              color="danger"
                              design="secondary"
                              onClick={() => {
                                hasVideo && selectVideoGroupId(groupId);
                                resolveAssistanceRequest(
                                  sessionId,
                                  activity.name,
                                  groupId
                                );
                              }}
                              text={t('sessions:ICare')}
                            />
                          </div>
                        ) : hasVideo ? (
                          <Button
                            icon={<VideoCamera className="stroke-2" />}
                            size="sm"
                            design="secondary"
                            disabled={videoGroupId === groupId}
                            onClick={() => selectVideoGroupId(groupId)}
                            text={t('video:JoinThisGroup')}
                          />
                        ) : undefined
                      }
                    />
                  );
                })
              : Object.entries(users || {}).map(([userId, user]) => {
                  return (
                    <ViewListItem
                      title={user.name}
                      description={user.email}
                      onClick={() => {
                        if (userId !== searchedParticipant) {
                          setSearchedParticipant(null);
                        }
                        setSelectedUserId(userId);
                      }}
                      selected={userId === selectedUserId}
                      joinedVideo={false}
                      higlighted={userId === searchedParticipant}
                    />
                  );
                })}
          </div>
        </div>
      ) : null}
      <div
        className={`grow p-4 ${
          participantsInGroups || participantsAsGroups
            ? 'lg:border-l lg:border-surfaces-divider'
            : ''
        }`}
      >
        <div className="bg-white">
          <>
            <GroupHeader
              sessionId={sessionId}
              userId={impersonatedUserId}
              activity={activity}
              groupPrefix={groupPrefix}
            />
            <Disclosure
              defaultOpen
              collapsable={false}
              title={
                <div className="flex items-center space-x-2">
                  <h2 className="text-2xl font-semibold uppercase text-black">
                    {t('editor:goals')}
                  </h2>
                </div>
              }
            >
              <MDReactComponent
                text={textReplaceSession(templateReplace(instruction, context))}
                editable={false}
                setText={async () => {}}
              />
            </Disclosure>

            {_.size(flattenedDocs) > 0 ? (
              <ContentCardsEditor
                editable={false}
                docs={flattenedDocs}
                user={user}
                setDocName={() => {}}
                docName=""
              />
            ) : (
              <div className="space-y-4">
                <h2 className="mt-10 px-5 text-center text-xl text-black-soft md:px-10 md:text-2xl xl:px-20 xl:text-3xl">
                  {t('facilitator:NoDocumentCreatedYet')}
                </h2>
                <Documents className="mx-auto h-48 fill-current text-primary" />
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default DocumentsView;
