import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const passwordSchema = (t: TFunction) =>
  Yup.string()
    .min(10, t('form:minPasswordChar'))
    .max(255, t('form:maxPasswordChar'))
    .matches(/.*[a-z].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[A-Z].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[0-9].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[^0-9a-zA-Z].*/, t('form:mustSatisfyComplexity'))
    .required(t('form:fieldRequired'));
