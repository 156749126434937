import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import _ from 'lodash';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export type DocumentViewerProps = {
  documentUrl: string;
};

const DocumentViewer = ({ documentUrl }: DocumentViewerProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, []);

  return (
    <div ref={ref}>
      {_.range(1, (numPages || 1) + 1).map((pageNumber) => (
        <Document
          loading={
            <p>
              {t('misc:DocumentLoading')}{' '}
              <Spinner className="w-8 h-8 text-primary" />
            </p>
          }
          key={pageNumber}
          file={documentUrl}
          onLoadSuccess={pageNumber === 1 ? onDocumentLoadSuccess : undefined}
        >
          <Page width={width} pageNumber={pageNumber} />
        </Document>
      ))}
    </div>
  );
};

export default DocumentViewer;
