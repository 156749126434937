import SearchInput from 'components/SearchInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectSearchBox } from 'react-instantsearch-dom';

const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  const { t } = useTranslation();

  return (
    <SearchInput
      placeholder={t('common:Search')}
      value={currentRefinement}
      onChange={(value) => refine(value)}
    />
  );
});

export default SearchBox;
