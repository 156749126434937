const fonts = {
  SIZE: {
    XSMALL: 12,
    SMALL: 14,
    MEDIUM: 16,
    MEDIUM_BIG: 18,
    BIG: 22,
    HUGE: 36,
    XHUGE: 60,
  },
};

export default fonts;
