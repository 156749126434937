import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { removeDocMeta } from 'model/docManagement';
import YesNoModal from 'components/YesNoModal';
import Editor from 'frameworks/plate/RevisionedEditor';
import FormattedText from 'components/FormattedText';

import { Card, Button, Space } from 'antd';

import {
  MailOutlined,
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { User, UserType } from 'types/types';
import { FullDocMeta } from './types';
import { isGlobalAdmin } from 'model/users';

const ContentPreview = ({
  doc,
  close,
  user,
  userType = 'participant',
  onDeleteDoc,
}: {
  user: User | undefined;
  doc: FullDocMeta;
  close: () => void;
  userType: UserType;
  onDeleteDoc: (docId: string) => void;
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [wantsDelete, setWantsDelete] = useState(false);

  const selectedDoc = doc;

  const remove = () => {
    wantsDelete && removeDocMeta(selectedDoc.id);
    onDeleteDoc(selectedDoc.id);
    setWantsDelete(false);
    close();
    setEditing(false);
  };

  const abortDeletion = () => {
    setWantsDelete(false);
  };

  const resetSelectedDoc = () => {
    close();
    setEditing(false);
  };

  return (
    <>
      <YesNoModal
        onClickYes={remove}
        onClickNo={abortDeletion}
        open={wantsDelete}
        onClose={abortDeletion}
        critical
        textYes={t('common:Delete')}
        textNo={t('common:Cancel')}
      >
        <FormattedText>
          {t('misc:deleteDocMessage', {
            title: selectedDoc?.title || selectedDoc?.name,
          })}
        </FormattedText>
      </YesNoModal>
      <Card
        bodyStyle={{
          paddingTop: '1px',
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        }}
        title={
          <Space>
            <Button icon={<LeftOutlined />} onClick={resetSelectedDoc}>
              {t('common:backToList')}
            </Button>
            {user &&
              (!editing ? (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setEditing(true)}
                >
                  {t('common:Edit')}
                </Button>
              ) : (
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => setEditing(false)}
                >
                  {t('common:Close')}
                </Button>
              ))}
            {isGlobalAdmin(userType) && (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => setWantsDelete(true)}
              >
                {t('common:Delete')}
              </Button>
            )}
          </Space>
        }
      >
        <div>
          <Editor readOnly={!editing} metaId={doc.id} user={user} />
        </div>
        <Space
          // size="large"
          style={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: '20px',
            paddingBottom: '5px',
            width: '100%',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          {selectedDoc.authors &&
            Object.values(selectedDoc.authors).map((user) => (
              <Button
                key={user.id}
                icon={<MailOutlined />}
                // size="small"
                href={`mailto:${user.email}`}
                style={{ marginBottom: '10px' }}
                onClick={(event) => event.stopPropagation()}
              >
                {user.name}
              </Button>
            ))}
        </Space>
      </Card>
    </>
  );
};

export default ContentPreview;
