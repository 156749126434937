import * as React from 'react';

function SvgTriangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path d="M579.5 879.8c-43.7 75.7-115.3 75.7-159 0L28.7 201.1c-43.7-75.7-8-137.7 79.5-137.7h783.7c87.5 0 123.2 62 79.5 137.7L579.5 879.8z" />
    </svg>
  );
}

export default SvgTriangle;
