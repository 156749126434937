import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Processing } from 'assets/undraw';
import Link from 'components/Link';
import { useHistory, useLocation } from 'react-router-dom';

export type ProcessingScreenProps<T> = {
  message?: string;
  execute?: (params?: T) => void;
  executionError?: boolean;
};

const ProcessingScreen = <T,>({
  message,
  execute,
  executionError,
}: ProcessingScreenProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();
  const { search } = location;

  const query = Object.fromEntries(new URLSearchParams(search)) as unknown as T;
  const [showStuckMessage, setShowStuckMessage] = useState(false);

  useEffect(() => {
    if (execute && query) {
      execute(query);
    }
  }, [execute, query]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowStuckMessage(true);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <div className="flex h-screen w-full bg-surfaces-soft px-4">
      <div className="m-auto space-y-4">
        <Processing className="w-full fill-current text-primary sm:w-128" />
        <div className="space-y-2 text-center">
          {message ? (
            <h3
              className={`text-xl ${
                executionError ? 'text-danger' : 'text-black-soft'
              }`}
            >
              {message}
            </h3>
          ) : null}
          {showStuckMessage || executionError ? (
            <p className="text-sm text-black-soft">
              {executionError
                ? t('common:stuckOnPageWithError')
                : t('common:stuckOnPage')}
              <Link
                size="sm"
                text={t('common:clickHere')}
                onClick={() => {
                  if (history.length > 1) {
                    history.goBack();
                  }
                  history.push('/home');
                }}
              />
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProcessingScreen;
