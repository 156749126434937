import * as React from 'react';

function SvgItemEcrou(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.117 0l4.031 1.08-1.803 6.73 5.934-3.425 2.087 3.614-5.76 3.326L24 13.038l-1.08 4.032-6.632-1.777 3.468 6.007-3.615 2.087-3.394-5.88L11.007 24l-4.032-1.08 1.73-6.456-5.888 3.4L.73 16.248l6.013-3.472a5.317 5.317 0 003.882 4.355 5.316 5.316 0 006.507-3.757 5.316 5.316 0 00-3.757-6.507 5.316 5.316 0 00-6.638 5.866L0 10.929l1.08-4.032 6.554 1.756-3.357-5.816L7.89.75l3.432 5.944L13.117 0z"
      />
    </svg>
  );
}

export default SvgItemEcrou;
