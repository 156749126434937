import React, { forwardRef, useState, useRef, MutableRefObject } from 'react';
import { useField, FieldArray } from 'formik';

import { useCombinedRefs } from 'utils/utils';
import { useOnChange } from './hooks';

import Input from 'frameworks/formik/Input';
import RoundedButton from 'components/RoundedButton';
import ListItem from 'components/ListItem';

import { X, Plus } from 'assets/icons';

export type ListItemInputProps = {
  name: string;
  label?: string;
  readOnly?: boolean;
  renderEmpty?: () => JSX.Element;
  cols?: 1 | 2;
  placeholder?: string;
  onChangeValue?: (value: string[], hasError: boolean) => void;
};

const ListItemInput = forwardRef<HTMLInputElement, ListItemInputProps>(
  (
    {
      label,
      name,
      readOnly = false,
      renderEmpty = () => <></>,
      cols = 2,
      placeholder,
      onChangeValue,
    },
    ref
  ): JSX.Element => {
    const [field] = useField<string[]>(name);
    const [focusIndex, setFocusIndex] = useState(0);
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs([
      ref as MutableRefObject<HTMLInputElement>,
      defaultRef,
    ]);

    useOnChange(name, onChangeValue);
    return (
      <div className="w-full">
        {readOnly ? (
          <>
            {label ? (
              <label className="text-lg font-semibold" htmlFor={name}>
                {label}
              </label>
            ) : null}
            <div className="mt-2">
              {field.value && field.value.length > 0 ? (
                <ListItem items={field.value} cols={cols} />
              ) : (
                renderEmpty()
              )}
            </div>
          </>
        ) : (
          <FieldArray
            name={name}
            render={(arraysHelper) => {
              return (
                <>
                  <div className="flex flex-row items-center space-x-3">
                    {label ? (
                      <label className="text-lg font-semibold" htmlFor={name}>
                        {label}
                      </label>
                    ) : null}
                  </div>
                  <div
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setFocusIndex(field.value.length);
                        arraysHelper.push('');
                        setTimeout(() => {
                          combinedRef.current?.focus();
                        }, 0);
                      }
                    }}
                  >
                    {field.value &&
                      field.value.map((item, index) => {
                        return (
                          <Input
                            ref={index === focusIndex ? combinedRef : undefined}
                            key={index}
                            name={`${name}.${index}`}
                            placeholder={placeholder}
                            action={
                              <RoundedButton
                                color="danger"
                                onClick={() => {
                                  arraysHelper.remove(index);
                                }}
                              >
                                <X className="h-6 w-6 stroke-2" />
                              </RoundedButton>
                            }
                          />
                        );
                      })}
                  </div>
                  <div className="mt-2">
                    <RoundedButton
                      color="success"
                      onClick={() => arraysHelper.push('')}
                    >
                      <Plus className="h-6 w-6 stroke-2" />
                    </RoundedButton>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    );
  }
);

export default ListItemInput;
