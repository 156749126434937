import { currentEmail } from 'global';
import { auth } from 'services/firebase';

let loaded = false;
let pendingNickname = null;
let pendingEmail = null;

window.$crisp?.push(['config', 'container:index', [100]]);

window.$crisp?.push([
  'on',
  'session:loaded',
  () => {
    loaded = true;
    try {
      if (pendingEmail) {
        window.$crisp?.push(['set', 'user:email', [pendingEmail]]);
      }
      if (pendingNickname) {
        window.$crisp?.push(['set', 'user:nickname', [pendingNickname]]);
      }
    } catch (error) {
      console.error(error);
    }
  },
]);

export const identify = () => {
  setTimeout(() => {
    if (auth.currentUser) {
      if (!loaded) {
        pendingEmail = currentEmail;
      } else {
        const email = currentEmail;
        try {
          window.$crisp?.push(['set', 'user:email', [email]]);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, 500);
};

export const reset = () => {
  pendingNickname = null;
  pendingNickname = null;
  window.$crisp?.push(['do', 'session:reset']);
};

export const focus = () => {
  window.$crisp?.push(['do', 'chat:open']);
};

export const sendMessage = (message) => {
  window.$crisp.push(['do', 'message:send', ['text', message]]);
};
