import { LOGIN_PATH, SESSION_TEST_SUFFIX } from 'constants/AppConfig';
import {
  getSessionIdFromAccessCode,
  subscribeUserToSession,
  useFullSessionFromAccessCode,
} from 'model/sessions';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import LoadingScreen from 'screens/LoadingScreen';
import { SessionTemplate, SessionStatusState } from 'types/types';
import { Nullable } from 'types/typesUtils';
import SessionLiveScreen from 'screens/SessionScreen/SessionLiveScreen';
import SessionHomeScreen from './SessionHomeScreen/SessionHomeScreen';
import SessionDashboardScreen from './SessionDashboardScreen/SessionDashboardScreen';
import { useValueFromPath } from 'services/firebase';
import { usePrevious } from 'utils/utils';
import Modal from 'components/Modal';
import GlobalSettings from 'screens/ParametersPane/GlobalSettings';
import Alert from 'components/Alert';
import Button from 'components/Button';
import { deleteSession } from 'model/sessions';
import { isGlobalAdmin } from 'model/users';
import { useUserContextWithDefaults } from 'contexts/UserContext';
import DocumentTitle from 'components/DocumentTitle';

const SessionGuard = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { userId, userType, userName, userEmail } =
    useUserContextWithDefaults();
  const [showGlobalSettings, setShowGlobalSettings] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ accessCode: string }>();

  const { accessCode } = useParams<{ accessCode: string }>();
  const { pathname } = location;

  useEffect(() => {
    if (!userId) {
      history.push(LOGIN_PATH, {
        from: pathname,
        message: t('common:messageLoginBeforeSession'),
      });
    }
    if (accessCode) {
      getSessionIdFromAccessCode(accessCode).then((sessionId) => {
        if (!sessionId) {
          history.push(`/home`, {
            error: t('sessions:sessionLinkDoesNotExist'),
          });
        }
      });
    }
  }, [accessCode, userId, history, pathname, t]);

  const [sessionId, session, community, , isSessionLoaded] =
    useFullSessionFromAccessCode(accessCode);

  const loadArray = sessionId
    ? [
        {
          path: `/sessionsNext/${sessionId}`,
        },
        {
          path: `/sessionsNextData/${sessionId}/state`,
        },
        {
          path: `/sessionsNextTemplates/${sessionId}`,
        },
        {
          path: `/sessionsNextData/${sessionId}/enabledActivities`,
        },
        {
          path: `/sessionsNextData/${sessionId}/enableFollowUp`,
        },
        {
          path: `/sessionsNextData/${sessionId}/enableExpertMode`,
        },
        {
          path: `/sessionsNextData/${sessionId}/inAssistance`,
        },
      ]
    : [];
  useFirebaseConnect(loadArray);

  const sessionState: Nullable<SessionStatusState> = useSelector(
    (state: any) =>
      (sessionId && state.firebase.data.sessionsNextData?.[sessionId]?.state) ||
      null
  );

  const sessionFollowUp: boolean = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextData?.[sessionId]?.enableFollowUp) ||
      false
  );

  const sessionExpertMode: boolean = useSelector((state: any) => {
    const expertModeState =
      sessionId &&
      state.firebase.data.sessionsNextData?.[sessionId]?.enableExpertMode;
    return expertModeState !== null ? expertModeState : true;
  });

  const sessionInAssistance: boolean = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextData?.[sessionId]?.inAssistance) ||
      false
  );

  const sessionTemplate: Nullable<SessionTemplate> = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const enabledActivities: Nullable<Record<string, boolean>> = useSelector(
    (state: any) =>
      sessionId &&
      state.firebase.data.sessionsNextData?.[sessionId]?.enabledActivities
  );

  const [isFacilitator, isFacilitatorLoaded] = useValueFromPath(
    `sessionsNextData/${sessionId}/users/facilitators/${userId}`,
    [!!sessionId]
  );
  const [isOrganizer, isOrganizerLoaded] = useValueFromPath(
    `sessionsNextData/${sessionId}/users/organizers/${userId}`,
    [!!sessionId]
  );

  // TODO: handle rights
  const role = isGlobalAdmin(userType)
    ? 'superadmin'
    : isOrganizer
    ? 'organizer'
    : isFacilitator
    ? 'facilitator'
    : 'participant';

  const switchToLive = useCallback(
    (mode?: 'editor' | 'facilitator') => {
      history.push(
        `/${accessCode}/live${mode ? `?modeRequested=${mode}` : ''}`
      );
    },
    [history, accessCode]
  );

  const switchToDashboard = useCallback(() => {
    history.push(`/${accessCode}/dashboard`);
  }, [history, accessCode]);

  const switchToHome = useCallback(() => {
    history.push(`/${accessCode}/home`);
  }, [history, accessCode]);

  const switchToDetails = () => {
    history.push(`/${accessCode}/dashboard/session_plan`);
  };

  const previousSessionState = usePrevious(sessionState);
  useEffect(() => {
    if (isFacilitatorLoaded && isOrganizerLoaded) {
      if (
        role === 'participant' &&
        previousSessionState !== 'live' &&
        sessionState === 'live'
      ) {
        switchToLive();
      } else if (
        role === 'participant' &&
        previousSessionState !== 'finished' &&
        sessionState === 'finished'
      ) {
        switchToDashboard();
      } else if (
        role === 'participant' &&
        previousSessionState !== 'not_started' &&
        sessionState === 'not_started'
      ) {
        switchToHome();
      }
    }
  }, [
    previousSessionState,
    sessionState,
    switchToLive,
    switchToDashboard,
    switchToHome,
    role,
    isFacilitatorLoaded,
    isOrganizerLoaded,
  ]);

  const selectedPage =
    sessionState === 'kickoff' ||
    sessionState === 'pre_conception' ||
    sessionState === 'assign_role' ||
    sessionState === 'not_started'
      ? 'home'
      : sessionState === 'finished'
      ? 'dashboard'
      : 'live';

  useEffect(() => {
    if (sessionId && isSessionLoaded && userId) {
      subscribeUserToSession(userId, sessionId, false);
    }
  }, [sessionId, userId, isSessionLoaded]);

  useEffect(() => {
    const globalLanguage = i18n.language;
    if (isSessionLoaded && session?.language !== globalLanguage) {
      i18n.changeLanguage(session?.language || 'fr');
    }
    return () => {
      i18n.changeLanguage(globalLanguage);
    };
  }, [i18n, session?.language, isSessionLoaded]);

  const sessionInTest = accessCode.endsWith(SESSION_TEST_SUFFIX);

  return session &&
    sessionId &&
    isLoaded(session, sessionTemplate, enabledActivities, isFacilitator) &&
    selectedPage &&
    isFacilitatorLoaded &&
    isOrganizerLoaded ? (
    <DocumentTitle title={session.title}>
      <div className="relative">
        {sessionInTest ? (
          <div className="absolute inset-x-0 bottom-4 z-1001">
            <Alert
              type="info"
              className="mx-auto w-3/4"
              title={t('sessions:sessionInTestTitle')}
              message={t('sessions:sessionInTestDescription')}
              actionButton={
                role !== 'participant' ? (
                  <Button
                    text={t('sessions:endSessionTest')}
                    onClick={async () => {
                      try {
                        history.push(
                          pathname.replace(
                            accessCode,
                            accessCode.replace(SESSION_TEST_SUFFIX, '')
                          )
                        );
                        await deleteSession(sessionId);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                ) : undefined
              }
            />
          </div>
        ) : null}
        <Switch>
          <Route exact path={match.path}>
            <Redirect
              to={`/${accessCode}/${
                role !== 'participant' ? 'dashboard' : selectedPage
              }`}
            />
          </Route>

          <Route path={`${match.path}/home`}>
            <SessionHomeScreen
              userId={userId}
              userType={userType}
              userName={userName}
              userEmail={userEmail}
              role={role}
              switchToSession={switchToLive}
              sessionState={sessionState || 'kickoff'}
            />
          </Route>

          <Route path={`${match.path}/dashboard`}>
            <SessionDashboardScreen
              userId={userId}
              userType={userType}
              userName={userName}
              sessionId={sessionId}
              session={session}
              role={role}
              switchToSession={switchToLive}
              sessionState={sessionState || null}
              accessCode={accessCode}
              sessionFollowUp={sessionFollowUp}
              communityName={community?.name}
              showGlobalSettings={() => setShowGlobalSettings(true)}
              sessionExpertMode={sessionExpertMode}
              sessionInAssistance={sessionInAssistance}
              sessionInTest={sessionInTest}
            />
          </Route>

          <Route exact path={`${match.path}/session_plan`}>
            <Redirect to={`/${accessCode}/dashboard/session_plan`} />
          </Route>

          <Route path={match.path}>
            <SessionLiveScreen
              userId={userId}
              userType={userType}
              userName={userName}
              userEmail={userEmail}
              role={role}
              switchToHome={switchToHome}
              switchToDetails={switchToDetails}
              switchToDashboard={switchToDashboard}
              showGlobalSettings={() => setShowGlobalSettings(true)}
              sessionInTest={sessionInTest}
            />
          </Route>
        </Switch>
        {sessionTemplate ? (
          <Modal
            title={t('sessions:SessionSettings')}
            open={showGlobalSettings}
            largeWidth
            fullHeight
            body={
              <GlobalSettings
                userId={userId}
                userType={userType}
                role={role}
                sessionId={sessionId}
                session={session}
                activities={sessionTemplate.activities}
                onClose={() => setShowGlobalSettings(false)}
              />
            }
            onClose={() => setShowGlobalSettings(false)}
          />
        ) : null}
      </div>
    </DocumentTitle>
  ) : (
    <LoadingScreen />
  );
};

export default SessionGuard;
