import React from 'react';
import IntroScreen from 'screens/IntroScreen';

import ContentDoneScreen from 'screens/ContentScreen/screens/ContentDone';
import SimpleCardScreen from 'screens/ContentScreen/screens/SimpleCardScreen';

import PostCreateScreen from 'screens/SessionScreen/SessionLiveScreen/screens/PostCreateScreen';
import PostListScreen from 'screens/SessionScreen/SessionLiveScreen/screens/PostListScreen';
import VoteScreen from 'screens/SessionScreen/SessionLiveScreen/screens/VoteScreen';
import FeedBackAnswerScreen from 'screens/SessionScreen/SessionLiveScreen/screens/FeedBackAnswerScreen';
import CreateContentScreen from 'screens/SessionScreen/SessionLiveScreen/screens/CreateContentScreen';
import DebriefView from 'screens/SessionScreen/SessionDashboardScreen/views/DebriefView/DebriefView';

export const HARDCODED_SCREENS: Record<
  string,
  {
    component: React.ComponentType<any>;
    bindings: string[];
    editableProps: string[];
    params?: string[];
  }
> = {
  Intro: {
    component: IntroScreen,
    bindings: ['title', 'scheduleDateTime'],
    editableProps: ['welcomeParticipants'],
  },
  CreatePost: {
    component: PostCreateScreen,
    bindings: ['post', 'criteria0', 'criteria1', 'criteria2', 'posts'],
    editableProps: [
      'question',
      'description',
      'answerPrompt',
      'postExample',
      'criteria0Instruction',
      'criteria1Instruction',
      'criteria2Instruction',
      'more',
      'forceNoPost',
    ],
    params: ['allowedNotToPost', 'criteriaRequired'],
  },
  StringList: {
    component: PostListScreen,
    bindings: ['contents', 'scheduledAt'],
    editableProps: ['thankyou'],
  },
  VoteScreen: {
    component: VoteScreen,
    bindings: ['vote', 'scheduledAt'],
    editableProps: [
      // 'yourTopic',
      'yourChoices',
      'moreTopics',
      // 'newRandom',
      'noTopic',
      // 'validate',
      // 'validateEmpty',
      // 'back',
      'thanks',
    ],
    params: ['noLimit'],
  },
  SimpleCardScreen: {
    component: SimpleCardScreen,
    bindings: [],
    editableProps: ['cardContent', 'facilitationInstruction'],
  },
  EditDocsScreen: {
    component: CreateContentScreen,
    bindings: ['docs'],
    editableProps: ['instructions', 'notTitle'],
  },
  ContentButtonScreen: {
    component: ContentDoneScreen,
    bindings: [],
    editableProps: ['content', 'buttonLink'],
  },
  FeedbackAnswer: {
    component: FeedBackAnswerScreen,
    bindings: [
      'questionAAnswer',
      'questionBAnswer',
      'questionCAnswer',
      'future',
      'valuable',
    ],
    editableProps: [
      'title',
      'questionValuable',
      'customQuestionA',
      'customQuestionB',
      'customQuestionC',
      'customQuestionD',
    ],
  },
  ExplorerScreen: {
    component: (props) => (
      <div className="mx-auto max-w-3xl">
        <DebriefView {...props} />
      </div>
    ),
    bindings: [],
    editableProps: [],
  },
};

export type HardcodedScreenType = keyof typeof HARDCODED_SCREENS;
