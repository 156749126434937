import React, { cloneElement, useEffect, useState } from 'react';

export type TooltipProps = {
  content: string | JSX.Element | undefined;
  children: JSX.Element;
  showOnClick?: boolean;
  colorBgClassName?: string;
  compact?: boolean;
};

const Tooltip = ({
  children,
  content,
  showOnClick = false,
  colorBgClassName = 'bg-gray-700',
  compact = false,
}: TooltipProps): JSX.Element => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), 1500);
    }
  }, [show, setShow]);

  return content ? (
    <div className="group/tooltip relative flex flex-col items-center">
      {cloneElement(children, {
        className: `${children.props.className || ''} ${
          showOnClick ? 'cursor-pointer' : ''
        }`,
        onClick: () => {
          setShow(true);
          children?.props.onClick?.();
        },
      })}
      <div
        className={`absolute bottom-full ${
          showOnClick && !!show ? 'flex' : 'hidden'
        } group-hover/tooltip:flex flex-col items-center pb-1`}
      >
        <span
          className={`relative z-100 rounded-lg ${colorBgClassName} ${
            compact ? 'p-1' : 'p-2'
          } text-sm leading-none text-white shadow-lg`}
        >
          {content}
        </span>
        <div className={`-mt-2 h-3 w-3 rotate-45 ${colorBgClassName}`}></div>
      </div>
    </div>
  ) : (
    children
  );
};

export default Tooltip;
