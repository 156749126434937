import React, { Fragment, useState, useEffect } from 'react';
import { FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import Tooltip from 'components/Tooltip';
import { X, InformationCircle, Plus, Duplicate, Check } from 'assets/icons';
import RoundedButton from './RoundedButton';

const Dropzone = ({ onDrop }: { onDrop: () => void }) => {
  const [highlighted, setHighlighted] = useState(false);

  return (
    <div
      onDrop={onDrop}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDragEnter={(event) => {
        event.preventDefault();
        setHighlighted(true);
      }}
      onDragLeave={() => {
        setHighlighted(false);
      }}
      className={`h-12 rounded-full ${
        highlighted
          ? 'w-2 border border-dashed border-primary bg-primary-soft'
          : 'w-2 bg-gray-soft'
      }`}
    ></div>
  );
};

export type DraggableTagsProps = {
  values: string[];
  onRemove: (index: number) => void;
  onMove: (idxSrc: number, idxDest: number) => void;
};

const DraggableTags = ({
  values,
  onRemove,
  onMove,
}: DraggableTagsProps): JSX.Element => {
  const [dragged, setDragged] = useState<number | null>(null);

  return (
    <>
      {values.map((item, index) => (
        <Fragment key={index}>
          {dragged !== null && dragged !== index && dragged !== index - 1 ? (
            <Dropzone
              onDrop={() => {
                if (dragged !== null) {
                  if (dragged < index) {
                    onMove(dragged, index - 1);
                  } else {
                    onMove(dragged, index);
                  }
                }
              }}
            />
          ) : (
            <div
              className="h-12
             w-2"
            ></div>
          )}
          <div
            draggable
            onDragStart={(event) => {
              event.dataTransfer.setData('text/html', item);
              setDragged(index);
            }}
            onDragEnd={() => {
              setDragged(null);
            }}
            className="my-1 mx-1 inline-flex h-8 cursor-move items-center rounded-full bg-primary-soft py-2 px-3 text-xs font-medium text-primary"
          >
            {item}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRemove(index);
              }}
              type="button"
              className="ml-1 inline-flex shrink-0 text-primary"
              aria-label="Remove small badge"
            >
              <X className="h-4 w-4 stroke-2" />
            </button>
          </div>
        </Fragment>
      ))}
      {dragged !== null && dragged !== values.length - 1 ? (
        <Dropzone
          onDrop={() => {
            if (dragged !== null) {
              onMove(dragged, values.length);
            }
          }}
        />
      ) : (
        <div className="h-12 w-2"></div>
      )}
    </>
  );
};

export type MultiSelectProps = {
  noBorder?: boolean;
  name: string;
  placeholder: string;
  className?: string;
  label?: string;
};

const MultiSelect = ({
  noBorder = false,
  name,
  className = '',
  label,
  placeholder,
}: MultiSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState('');
  const [field, meta, helpers] = useField({ name: name, multiple: true });
  const hasError = meta.touched && meta.error;
  const values: string[] = field.value;
  const containOptions = values && values.length !== 0;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className={`w-full ${className}`}>
      {label ? (
        <label
          htmlFor={name}
          className={`inline-flex w-full items-center space-x-1 text-base font-light`}
        >
          <span>{label}</span>
          <Tooltip
            content={
              <div className="w-40 space-y-2 text-xs">
                <div>
                  <span className="font-semibold">
                    {t('common:pressEnter')}
                  </span>
                  {`: ${t('misc:addCurrentItem')}`}
                </div>
                <div>
                  <span className="font-semibold">
                    {t('common:pressBackspace')}
                  </span>
                  {`: ${t('misc:deleteLastItem')}`}
                </div>
              </div>
            }
          >
            <InformationCircle className="h-5 w-5 stroke-2" />
          </Tooltip>
        </label>
      ) : null}

      <FieldArray
        {...field}
        name={name}
        render={(arraysHelper) => (
          <div
            className={`flex w-full space-x-2 ${
              noBorder ? '' : 'border'
            } rounded-md`}
          >
            <div className="my-2 flex max-h-14 w-full flex-row items-center rounded-md border px-3 ">
              <input
                value={currentValue}
                placeholder={placeholder}
                className=" grow py-3 text-lg outline-none"
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                  helpers.setTouched(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && currentValue !== '') {
                    e.stopPropagation();
                    arraysHelper.push(currentValue);
                    setCurrentValue('');
                  }
                }}
                onPaste={(e) => {
                  const pastValues = e.clipboardData.getData('Text');
                  const newValues = pastValues.split('\n');
                  if (newValues.length > 1) {
                    e.preventDefault();
                    newValues.forEach((value) => {
                      arraysHelper.push(value);
                    });
                  }
                }}
              />
              <RoundedButton
                size="base"
                color="success"
                disabled={currentValue === ''}
                onClick={() => {
                  arraysHelper.push(currentValue);
                  setCurrentValue('');
                }}
              >
                <Plus />
              </RoundedButton>
            </div>

            <div
              className={
                'relative  flex w-full flex-grow flex-wrap items-center justify-start py-1'
              }
            >
              {containOptions ? (
                <>
                  <div
                    className="absolute -top-5 -left-8 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full hover:text-primary"
                    onClick={() => {
                      copy(values.join('\n'));
                      setCopied(true);
                    }}
                  >
                    {copied ? (
                      <Check className="h-5 w-5 stroke-2 text-success" />
                    ) : (
                      <Tooltip content={t('sessions:copyOptions')}>
                        <Duplicate className="h-5 w-5 stroke-2" />
                      </Tooltip>
                    )}
                  </div>
                  <DraggableTags
                    values={values}
                    onMove={arraysHelper.move}
                    onRemove={(index) => {
                      arraysHelper.remove(index);
                      arraysHelper.form.validateField(name);
                    }}
                  />
                </>
              ) : (
                <span className="flex rounded-lg bg-warning-soft py-1 px-2 text-sm text-warning">
                  {t('common:noOptions')}
                </span>
              )}

              {hasError ? (
                <div className="mt-2 font-medium text-danger">{meta.error}</div>
              ) : null}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default MultiSelect;
