import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Activity } from 'types/types';
import { useGroup } from 'model/dataHooks';
import { removeUserFromGroup } from 'model/groupManagement';

import YesNoModal from 'components/YesNoModal';
import FormattedText from 'components/FormattedText';

export type GroupHeaderProps = {
  sessionId: string;
  userId: string;
  activity: Activity;
  groupPrefix: string | undefined;
};

const GroupHeader = ({
  sessionId,
  userId,
  activity,
  groupPrefix,
}: GroupHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const [group, groupId, , activityName] = useGroup(
    sessionId,
    userId,
    activity
  );
  const groupName = t('common:myGroupNumber', {
    groupPrefix: groupPrefix || '',
    number: group?.number || 1,
  });

  const groupSettings =
    activity?.grouping?.mode === 'Groups'
      ? activity?.grouping.settings
      : undefined;
  return group ? (
    <div className="flex">
      <div className="mx-auto flex flex-col items-center">
        <div className="rounded-lg bg-primary-soft py-2 px-6 text-xl font-semibold uppercase text-primary">
          {groupName}
        </div>
        {groupSettings?.membersCanChangeAndJoin ? (
          <button
            className="mt-1 text-sm text-danger hover:underline"
            onClick={() => setShowLeaveGroupModal(true)}
          >
            {t('sessions:leaveGroup')}
          </button>
        ) : null}
      </div>
      <YesNoModal
        critical
        open={showLeaveGroupModal}
        onClickYes={() => {
          removeUserFromGroup(sessionId, activityName!, groupId!, userId);
          setShowLeaveGroupModal(false);
        }}
        onClose={() => setShowLeaveGroupModal(false)}
        textYes={t('sessions:leaveGroup')}
        textNo={t('common:Cancel')}
      >
        <FormattedText className="whitespace-pre-wrap">
          {t('sessions:leaveGroupWarning')}
        </FormattedText>
      </YesNoModal>
    </div>
  ) : (
    <></>
  );
};

export default GroupHeader;
