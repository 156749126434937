import React, { useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Alert from 'components/Alert';
import SaveStatus from 'blocks/SaveStatus';

export type AutoSaveProps<T> = {
  debounceMs?: number;
  handleSubmit?: (values: T) => Promise<void>;
  ignoreErrors?: boolean;
};

const AutoSave = <T,>({
  debounceMs = 500,
  handleSubmit,
  ignoreErrors = false,
}: AutoSaveProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const { submitForm, values, initialValues, errors, isSubmitting } =
    useFormikContext<T>();
  const [isSaved, setIsSaved] = useState(true);

  const debouncedSubmitHandler = useMemo(
    () =>
      _.debounce(async () => {
        await (handleSubmit ? handleSubmit(values) : submitForm());
        setIsSaved(true);
      }, debounceMs),
    [submitForm, debounceMs, handleSubmit, values]
  );

  useEffect(() => {
    if (!_.isEqual(values, initialValues)) {
      setIsSaved(false);
      debouncedSubmitHandler();
    }
    return () => {
      debouncedSubmitHandler.cancel();
    };
  }, [debouncedSubmitHandler, values, initialValues]);

  const errorsCount = _.size(errors);
  return !ignoreErrors && errorsCount > 0 ? (
    <Alert
      type="error"
      title={t('common:ThereAreNErrorsinForm', {
        count: errorsCount,
      })}
    />
  ) : (
    <SaveStatus status={!isSubmitting && isSaved ? 'saved' : 'saving'} />
  );
};

export default AutoSave;
