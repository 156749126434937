import { useEffect, useState, useMemo } from 'react';
import Fuse from 'fuse.js';
import { WithId } from 'types/typesUtils';
import _ from 'lodash';

export const useLocalSearch = <T extends object>(
  collection: Record<string, T>,
  memoizedSearchKeys: (keyof T)[],
  searchPattern: string
): Record<string, T> => {
  const [fuse, setFuse] = useState<Fuse<WithId<T>> | null>(null);

  const collectionArray: WithId<T>[] = useMemo(() => {
    return Object.entries(collection).map(([key, obj]) => ({
      ...obj,
      id: key,
    }));
  }, [collection]);

  useEffect(() => {
    setFuse(
      new Fuse(collectionArray, {
        keys: memoizedSearchKeys as string[],
        threshold: 0.1,
      })
    );
  }, [collectionArray, memoizedSearchKeys]);

  const res = useMemo(() => {
    if (!fuse) {
      return null;
    }

    const resArray = fuse.search(searchPattern);

    if (!searchPattern) {
      return collection;
    }

    return _.omit(
      _.keyBy(
        resArray.map((elem) => elem.item),
        'id'
      ),
      'id'
    );
  }, [fuse, searchPattern, collection]);

  return (res || {}) as Record<string, T>;
};
