import * as React from 'react';

function SvgFurious(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M44.96 44.374v4.471"
      />
      <circle cx={44.926} cy={51.647} r={1} />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M28.147 44.081l-1.101 7.238M31.369 44.081l-1.101 7.238M25.788 46.097l7.321-.007M25.306 49.283l7.322-.007M40.218 45.719a1.532 1.532 0 00-1.418-.827 1.571 1.571 0 00-1.677 1.352s-.243 1.37 1.813 1.434c0 0 1.46.243 1.46 1.46 0 1.334-1.433 1.38-1.433 1.38s-1.37.104-1.752-.732M38.805 44.639v-1.005M38.805 51.778v-1.005"
      />
      <g>
        <path
          fill="#ea5a47"
          d="M36 13a23 23 0 1023 23 23.026 23.026 0 00-23-23z"
        />
        <rect
          x={19.518}
          y={41.076}
          rx={4.077}
          width={32.264}
          height={13.575}
          fill="#3f3f3f"
        />
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M44.96 44.374v4.471"
        />
        <circle cx={44.926} cy={51.647} r={1} fill="#fff" />
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M28.147 44.081l-1.101 7.238M31.369 44.081l-1.101 7.238M25.788 46.097l7.321-.007M25.306 49.283l7.322-.007M40.218 45.719a1.532 1.532 0 00-1.418-.827 1.571 1.571 0 00-1.677 1.352s-.243 1.37 1.813 1.434c0 0 1.46.243 1.46 1.46 0 1.334-1.433 1.38-1.433 1.38s-1.37.104-1.752-.732M38.805 44.639v-1.005M38.805 51.778v-1.005"
        />
      </g>
      <g>
        <circle
          cx={36}
          cy={36}
          r={23}
          fill="none"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path d="M30 33.925a3 3 0 11-3-3 3.001 3.001 0 013 3M48 33.925a3 3 0 11-3-3 3.001 3.001 0 013 3" />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M23 25.113l7 4M49 25.113l-7 4"
        />
        <rect
          x={19.868}
          y={41.076}
          rx={4.077}
          width={32.264}
          height={13.575}
          fill="none"
          stroke="#000"
          strokeMiterlimit={2}
          strokeWidth={2}
        />
      </g>
    </svg>
  );
}

export default SvgFurious;
