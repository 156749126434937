import { useUserContextWithDefaults } from 'contexts/UserContext';
import { Form, Formik } from 'formik';
import AutoSave from 'frameworks/formik/AutoSave';
import ListItemInput from 'frameworks/formik/ListItemInput';
import {
  saveConfidentialityList,
  useConfidentialityList,
} from 'model/parameters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from 'screens/LoadingScreen';

const AppAdminGlobalParameters = () => {
  const { t } = useTranslation();

  const { userId } = useUserContextWithDefaults();

  const [list, listLoaded] = useConfidentialityList(userId);

  return listLoaded ? (
    <Formik
      initialValues={{
        list,
      }}
      onSubmit={(values) =>
        saveConfidentialityList(
          values.list.filter((elem) => elem !== '')
        ).catch((err) => console.error(err))
      }
    >
      {({ values }) => {
        return (
          <Form>
            <AutoSave />
            <ListItemInput
              name="list"
              label={t('misc:ConfidentialityList')}
              placeholder={t('misc:ExDomain')}
            />
          </Form>
        );
      }}
    </Formik>
  ) : (
    <LoadingScreen />
  );
};

export default AppAdminGlobalParameters;
