import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/app-check';

import {
  appCheckKey,
  firebaseConfig,
  firebaseRemoteConfigFetchIntervalMs,
} from 'config';

import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export const config = firebaseConfig;
firebase.initializeApp(config);

const appCheck = firebase.appCheck();
appCheck.activate(
  new firebase.appCheck.ReCaptchaEnterpriseProvider(appCheckKey),
  true
);

export const auth = firebase.auth();
if (process.env.REACT_APP_ENV === 'local') {
  auth.useEmulator('http://localhost:9099');
}

export const storage = firebase.storage();
if (process.env.REACT_APP_ENV === 'local') {
  storage.useEmulator('localhost', 9199);
}
export const database = firebase.database();
export const functions = firebase.app().functions('europe-west1');
export const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis =
  firebaseRemoteConfigFetchIntervalMs;

declare global {
  interface Window {
    getDatabase: () => firebase.database.Database | undefined;
  }
}

// call local functions when developping localy
if (process.env.REACT_APP_ENV === 'local') {
  functions.useEmulator('localhost', 5001);
}

export const NOW = firebase.database.ServerValue.TIMESTAMP;
export const increment = firebase.database.ServerValue.increment;

export const gerErrorDescription = (code: string) => {
  switch (code) {
    case 'auth/invalid-email':
      return i18next.t('firebase:emailInvalid'); // Email address is not valid
    case 'auth/user-disabled':
      return i18next.t('firebase:userDisabled'); // 'Your account has been disabled'
    case 'auth/user-not-found':
      return i18next.t('firebase:userNotFound'); // 'Email does not exist'
    case 'auth/wrong-password':
      return i18next.t('firebase:wrongPassword'); // Password is invalid
    case 'auth/email-already-in-use':
      return i18next.t('firebase:emailAlreadyExists'); // Email already exists
    case 'auth/expired-action-code':
      return i18next.t('firebase:expiredLink'); //
    case 'auth/invalid-action-code':
      return i18next.t('firebase:linkNotValidOrAlreadyUsed');
    default:
      return i18next.t('firebase:unknownError'); // Unknown error occured
  }
};

export async function fetchData<T>(path: string): Promise<T | null>;
export async function fetchData<T>(path: string, defaultValue: T): Promise<T>;
export async function fetchData<T>(
  path: string,
  defaultValue: T,
  query: (ref: firebase.database.Reference) => firebase.database.Query
): Promise<T>;
export async function fetchData<T>(
  path: string,
  defaultValue?: T,
  query: (ref: firebase.database.Reference) => firebase.database.Query = (el) =>
    el
): Promise<T | null> {
  const data: T | null = (await query(database.ref(path)).once('value')).val();
  return data || defaultValue || null;
}

export function useValueFromPath<T>(
  path: string,
  depedenciesLoaded?: Array<boolean>
) {
  const [value, setValue] = useState<T | null | undefined>(undefined);
  const isDependenciesLoaded = (depedenciesLoaded || []).every((dep) => dep);

  useEffect(() => {
    const callback = (snap: firebase.database.DataSnapshot) => {
      if (isDependenciesLoaded) {
        setValue(snap.val());
      }
    };
    database.ref(path).on('value', callback);
    return () => {
      database.ref(path).off('value', callback);
    };
  }, [path, isDependenciesLoaded]);

  return [value, value !== undefined];
}

export function useSelectorArray<T>(
  querySettings: string[],
  selector: (state: any) => T,
  predicate?: (value: any) => boolean
): [boolean, T] {
  const virtualData = {};
  return useSelector((state: any) => {
    const dataLoaded = querySettings.reduce((prec, query) => {
      const path = query.replaceAll('/', '.');
      const value = _.get(state.firebase.data, path);
      _.set(virtualData, path, value);
      return prec && ((predicate && predicate(value)) || value);
    }, true);

    const result = selector(virtualData);
    return [dataLoaded, result];
  });
}

export default firebase;
