import { Productions, Production, Activities, DataPath } from 'types/types';

function dataPathToRefFunc(
  sessionId: string,
  userId: string | undefined,
  productions: Record<string, Productions>,
  dataPath: DataPath,
  activities: Activities,
  validation: true
): void;

function dataPathToRefFunc(
  sessionId: string,
  userId: string | undefined,
  productions: Record<string, Productions>,
  dataPath: DataPath,
  activities: Activities,
  validation?: false
):
  | [string[]]
  | [string[], Production]
  | [(groupId: string) => string[], Production, string[][]];

function dataPathToRefFunc(
  sessionId: string,
  userId: string | undefined,
  productions: Record<string, Productions>,
  dataPath: DataPath,
  activities: Activities,
  validation: boolean = false
):
  | void
  | [string[]]
  | [string[], Production]
  | [(groupId: string) => string[], Production, string[][]] {
  const parts = dataPath.split('.');

  if (!parts.length) {
    throw Error("DataPath can't be null");
  }

  switch (parts[0]) {
    case 'session':
      if (!parts[1]) {
        throw Error('When pointing to session, you must access a sub member');
      }
      // TODO: validate parts
      if (!validation) {
        return [['sessionsNext', sessionId, parts[1]]];
      }
      break;
    case 'productions':
      // console.log(parts);
      const activity = parts[1];
      const productionName = parts[2];
      const productionsRef = 'productions';
      // console.log(productions, activity);
      const targetedProduction = productions[activity]?.[productionName];
      // console.log(productions);
      // console.log(parts);
      if (!targetedProduction) {
        throw Error(
          `When pointing to productions, you must access valid production : ${dataPath}`
        );
      }

      if (parts[3] === '') {
        // console.log(activities[activity]);

        if (!validation) {
          if (userId === undefined) {
            throw Error('userId is mandatory to get byGroup or by byUser path');
          }

          if (
            targetedProduction.mode === 'ByGroup' &&
            activities[activity].grouping.mode !== 'All' // === Groups
          ) {
            return [
              (groupId: string) => [
                'sessionsNextData',
                sessionId,
                'activities',
                activity,
                productionsRef,
                productionName,
                groupId,
              ],
              targetedProduction,
              [
                [
                  'sessionsNextData',
                  sessionId,
                  'activities',
                  activity,
                  'grouping',
                  'groupOfUser',
                  userId,
                ],
              ],
            ];
          } else if (targetedProduction.mode === 'ByUser') {
            return [
              [
                'sessionsNextData',
                sessionId,
                'activities',
                activity,
                productionsRef,
                productionName,
                userId,
              ],
              targetedProduction,
            ];
          } else {
            return [
              [
                'sessionsNextData',
                sessionId,
                'activities',
                activity,
                productionsRef,
                productionName,
              ],
              targetedProduction,
            ];
          }
        }
        return;
      }

      if (parts[3] === '*') {
        if (!validation)
          return [
            [
              'sessionsNextData',
              sessionId,
              'activities',
              activity,
              productionsRef,
              productionName,
            ],
            targetedProduction,
          ];
        return;
      }

      throw Error('Unsupported production access type');
    default:
      throw Error('Not implemented yet');
  }
}

export const dataPathToRef = dataPathToRefFunc;
