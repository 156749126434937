import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { flattenDocs, mapValuesAsync, templateReplace } from 'utils/utils';

import MDReactComponent from 'componentsOld/MDReactComponent';
import ContentCardsEditor from 'componentsOld/Content/ContentCardsEditor';
import Disclosure from 'components/Disclosure';
import { Mode } from 'types/ui';
import { Activity, FakeDocs, Production } from 'types/types';
import { getDoc, makeDoc } from 'model/docManagement';
import _ from 'lodash';
import { useTextReplaceSession } from 'model/sessions';
import { useTopics } from 'model/productions';

type CreateContentScreenProps = {
  instructions: string;
  noTitle: boolean;
  docs: any;
  docsProduction: Production;
  sessionId: string;
  userId: string;
  userName: string;
  userEmail: string;
  editable: boolean;
  setInstructions: (value: string) => Promise<void>;
  docName: string;
  setDocName: (value: string) => void;
  context: Record<string, string>;
  mode: Mode;
  activity: Activity;
};

const CreateContentScreen = ({
  instructions,
  noTitle,
  docs,
  docsProduction,
  sessionId,
  userId,
  userName,
  userEmail,
  editable,
  setInstructions,
  docName,
  setDocName,
  context,
  mode,
  activity,
}: CreateContentScreenProps) => {
  const { t } = useTranslation();
  const [fakeDocs, setFakeDocs] = useState<FakeDocs>({});
  const textReplaceSession = useTextReplaceSession(
    sessionId,
    activity.name,
    userId
  );

  const [topics] = useTopics(sessionId);
  const topicsArray = Object.values(topics);
  const topicDescription =
    topicsArray?.[0]?.description || t('misc:TopicExample');

  const flattenedDocs = useMemo(() => flattenDocs(docs), [docs]);

  useEffect(() => {
    if (mode === 'preview' && docsProduction?.type === 'document') {
      let docs: FakeDocs = {};
      const toRun = async () => {
        if (_.size(flattenedDocs) > 1) {
          const someDocs = await mapValuesAsync(
            flattenedDocs,
            async (_ignore, key) => {
              const doc = await getDoc(key);

              return doc === null ? null : { ...doc, fake: true };
            }
          );

          docs = _.pickBy(someDocs, (val) => val !== null) as FakeDocs;
        } else {
          const generateDoc = async (userName: string, index: string) => {
            const { name, doc } = await makeDoc(
              sessionId,
              activity.name,
              userId,
              docsProduction.template,
              docsProduction.docName,
              docsProduction.docTitle,
              {
                ...context,
                group_number: `${1}`,
                topic: topicDescription,
                name: userName,
                post: t('misc:ShareExample', { name: userName }),
                best: t('misc:ShareExample', { name: userName }),
                best_name: userName,
              },
              true
            );
            docs[userName] = {
              id: index,
              name,
              content: doc,
              fake: true,
            };
          };

          await generateDoc(userName, `fakemain`);

          if (
            docsProduction.multiplicity === 'Each' &&
            ((activity.grouping?.mode === 'Groups' &&
              docsProduction.mode === 'ByGroup') ||
              (activity.grouping?.mode === 'All' &&
                docsProduction.mode === 'ByAll'))
          ) {
            await Promise.all(
              ['Natasha', 'Jérémy', 'Antoine', 'Mathilde'].map(
                (userName, index) => generateDoc(userName, `fake${index}`)
              )
            );
          }
        }
        setFakeDocs(docs);
      };
      toRun();
    }
  }, [
    mode,
    flattenedDocs,
    docsProduction,
    context,
    userName,
    sessionId,
    userId,
    activity.name,
    t,
    activity.grouping?.mode,
    topicDescription,
  ]);

  const helper = useMemo(() => {
    return editable
      ? instructions
      : textReplaceSession(
          templateReplace(instructions, {
            ...context,
            topic:
              !context.topic && mode === 'preview'
                ? topicDescription
                : context.topic,
          })
        );
  }, [
    context,
    mode,
    instructions,
    editable,
    textReplaceSession,
    topicDescription,
  ]);

  return (
    <div className="m-auto max-w-3xl flex-col">
      {(editable || helper) && (
        <Disclosure
          defaultOpen
          collapsable={mode === 'editor'}
          title={
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-semibold uppercase text-black">
                {t('editor:goals')}
              </h2>
            </div>
          }
        >
          <MDReactComponent
            text={helper}
            editable={editable}
            setText={setInstructions}
          />
        </Disclosure>
      )}
      <ContentCardsEditor
        docs={mode === 'preview' ? fakeDocs : flattenedDocs}
        noTitle={noTitle}
        editable={editable}
        docName={docName}
        setDocName={setDocName}
        user={{
          id: userId,
          name: userName,
          email: userEmail,
        }}
      />
    </div>
  );
};

CreateContentScreen.defaultProps = {
  baseUrl: 'create',
};

export default CreateContentScreen;
