import React from 'react';

import {
  MultiSelect,
  Paragraph,
  Select,
  stringConvertor,
} from 'componentsOld/LiterateForms';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { Criteria, Criterium } from 'types/types';

import _ from 'lodash';
import Spinner from 'components/Spinner';

type OneInEachPickerProps = {
  sessionId: string;
  oneInEachCriteria: Criteria;
  allCriteria: Criteria | undefined;
  convertor: {
    from: (val: string) => Criterium;
    to: (val: Criterium) => string;
  };
  productionsOptions: Record<string, { value: Criterium; label: string }>;
  onCriteriaChange: (criteria: Criteria) => void;
};

const OneInEachPicker = ({
  sessionId,
  oneInEachCriteria,
  convertor,
  productionsOptions,
  onCriteriaChange,
  allCriteria,
}: OneInEachPickerProps) => {
  const { t } = useTranslation();

  useFirebaseConnect(
    Object.values(productionsOptions).map(({ value }) => {
      const [, activityName, productionName] = value.source.split('.');
      return {
        path: `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${productionName}/options`,
        storeAs: `sessionCriteriaOptions/${sessionId}/${activityName}/${productionName}`,
      };
    })
  );

  const criteriaOptions: Record<string, string[] | null | undefined> =
    useSelector((state: any) =>
      _.mapValues(productionsOptions, ({ value }) => {
        const [, activityName, productionName] = value.source.split('.');
        return state.firebase?.data?.sessionCriteriaOptions?.[sessionId]?.[
          activityName
        ]?.[productionName];
      })
    );

  const loaded = _.every(
    Object.values(criteriaOptions),
    (val) => val !== undefined
  );

  const criterium = Object.values(oneInEachCriteria)[0];

  const onCriteriaOptionSelected = (value: string) => {
    if (criterium) {
      onCriteriaChange({
        [criterium.name]: { ...criterium, optionalArgument: value },
      });
    }
  };

  const onCriteriaSelected = (criteria: Criteria) => {
    const criterium = Object.values(criteria)[0];
    if (criterium) {
      const options = criteriaOptions[criterium.name];
      const option = options?.[0];
      if (option) {
        onCriteriaChange({
          [criterium.name]: { ...criterium, optionalArgument: option },
        });
      }
    } else {
      onCriteriaChange(criteria);
    }
  };

  return loaded ? (
    <Paragraph>
      {t('settings:ParticipantsMentors') + ' '}
      <MultiSelect
        maxSelectionSize={1}
        selection={oneInEachCriteria}
        c={convertor}
        options={Object.values(productionsOptions).filter(
          ({ value: criterium }) =>
            allCriteria?.[criterium.name]?.mode === 'ONE_IN_EACH' ||
            (allCriteria?.[criterium.name] === undefined &&
              criteriaOptions[criterium.name] &&
              criteriaOptions[criterium.name]!.length > 0)
        )}
        onChange={onCriteriaSelected}
      />
      {criterium ? (
        <>
          {', '}
          {t('settings:MentorOption')}
          <Select
            value={criterium.optionalArgument!}
            options={(criteriaOptions[criterium.name] || []).map((val) => ({
              value: val,
              label: val,
            }))}
            c={stringConvertor}
            onChange={onCriteriaOptionSelected}
          />
        </>
      ) : null}
      .
    </Paragraph>
  ) : (
    <Spinner className="w-8 h-8" />
  );
};

export default OneInEachPicker;
