import React, { useState, useEffect, useMemo, useRef } from 'react';
import { createEditor } from 'slate';
// import { withHistory } from 'slate-history';
// import { withReact } from 'slate-react';
import Alert from 'components/Alert';
import * as Y from 'yjs';

import { useTranslation } from 'react-i18next';

import { SyncElement, withYjs } from 'slate-yjs';
import { WebsocketProvider } from 'y-websocket';

import { collabUrl } from 'config';
import { useIntersection } from 'utils/utils';
import Editor, { CustomEditorProps } from 'frameworks/plate/Editor';
import { TNode } from '@udecode/plate';

export type CollaborativeEditorProps = {
  docId: string;
  metaId: string;
  forceConnect?: boolean;
};

const CollaborativeEditor = ({
  docId,
  metaId,
  forceConnect = false,
  ...props
}: CollaborativeEditorProps & CustomEditorProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<TNode[]>([]);
  const [isOnline, setOnlineState] = useState(false);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const [collabPair, setCollabPair] = useState<
    [Y.Array<SyncElement>, WebsocketProvider] | null
  >(null);
  const offLineTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // FIXME: docId change does not replace content of editor for some reason
  // It comes from frontend
  // we force remounting of component with key in revisionned editor as a fix
  useEffect(() => {
    const id = docId;
    const doc = new Y.Doc();
    const sharedType = doc.getArray<SyncElement>('content');

    const provider = new WebsocketProvider(collabUrl, `${metaId}/${id}`, doc, {
      connect: false,
    });

    const statusCb = ({ status }: { status: string }) => {
      console.log(status);
      if (status !== 'connected') {
        if (offLineTimeoutRef.current === null) {
          offLineTimeoutRef.current = setTimeout(() => {
            offLineTimeoutRef.current = null;
            setOnlineState(false);
          }, 1000);
        }
      } else {
        if (offLineTimeoutRef.current) {
          clearTimeout(offLineTimeoutRef.current);
          offLineTimeoutRef.current = null;
        }
        setOnlineState(true);
      }
    };

    provider.on('status', statusCb);

    // Super hacky way to provide a initial value from the client, if
    // you plan to use y-websocket in prod you probably should provide the
    // initial state from the server.

    const syncCb = (isSynced: boolean) => {
      // if (isSynced && sharedType.length === 0) {
      //   toSharedType(sharedType, defaultValue);
      // }
      console.log('Sync');
    };
    provider.on('sync', syncCb);

    if (forceConnect) {
      provider.connect();
    }

    setCollabPair([sharedType, provider]);

    return () => {
      provider.off('status', statusCb);
      provider.off('sync', syncCb);
      provider.disconnect();
    };
  }, [metaId, docId, forceConnect]);

  const editor = useMemo(() => {
    const ed = //withReact(
      //withHistory(
      createEditor() as any;
    // )as any
    //);
    setValue([]);
    const slateEditor = collabPair ? withYjs(ed, collabPair[0]) : ed;

    slateEditor.docId = docId;

    return slateEditor;
  }, [collabPair, docId]);

  const visible = useIntersection(editorRef.current || null);
  useEffect(() => {
    if (collabPair && !forceConnect) {
      const [, inEditor] = collabPair;
      if (visible) {
        inEditor.connect();
      } else {
        inEditor.disconnect();
      }
    }
  }, [collabPair, visible, forceConnect]);

  useEffect(() => {
    const id = setTimeout(() => setSaving(false), 500);
    setSaving(true);
    return () => clearTimeout(id);
  }, [value]);

  return (
    <div ref={editorRef} className="overflow-visible">
      {!isOnline && visible && (
        <Alert
          title={t('editor:beCareful')}
          message={t('editor:notConnectedMessage')}
          type="warning"
        />
      )}

      <Editor
        editor={editor}
        value={value}
        saving={saving}
        // // decorate={decorate}
        onChange={(newValue) => {
          if (value.length === 0 && newValue.length > 0 && editor?.history) {
            editor.history.undos = [];
          }
          setValue(newValue);
        }}
        metaId={metaId}
        {...props}
      />
    </div>
  );
};

export default CollaborativeEditor;
