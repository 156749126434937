import React from 'react';
import Logo from 'components/Logo';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import MenuList from 'blocks/MenuList';
import { UserType } from 'types/types';

import { useAlert } from 'contexts/Alerts';
import Button from './Button';

import { LOGIN_PATH } from 'constants/AppConfig';

export type MainBarProps = {
  title?: string;
  userId: string;
  userType: UserType;
  userName: string;
  showLogo?: boolean;
  actionBar?: JSX.Element;
  fullWidth?: boolean;
  customWidthClass?: string;
  displayAlertBanner?: boolean;
};

const MainBar = ({
  title,
  userId,
  userType,
  userName,
  showLogo = true,
  actionBar,
  fullWidth = false,
  customWidthClass = 'max-w-5xl',
  displayAlertBanner = false,
}: MainBarProps): JSX.Element => {
  const { t } = useTranslation();
  const [, alertBanner] = useAlert();
  const history = useHistory();
  const location = useLocation();
  return (
    <div className={`w-full border-b border-surfaces-divider bg-white`}>
      <div
        className={`flex flex-row  ${
          fullWidth ? 'ml-4 mr-4' : `${customWidthClass} mx-auto`
        }`}
      >
        {showLogo ? (
          <div className="flex h-16 items-center">
            <Logo className="mb-3 flex h-8" />
          </div>
        ) : null}

        {showLogo && title ? (
          <div className="flex h-16 items-center">
            <div className="mx-5 h-7 border-l border-gray-300" />
          </div>
        ) : null}

        <div className="flex min-w-0 flex-grow flex-wrap items-center justify-start space-x-4">
          {title && (
            <h1 className="flex h-16 items-center truncate text-base font-semibold">
              {title}
            </h1>
          )}

          <div className="mx-auto flex flex-grow flex-wrap items-center justify-center space-x-4">
            {actionBar}
          </div>
        </div>

        <div className="flex h-16 items-center print:hidden">
          {userId !== '' ? (
            <MenuList userName={userName} userId={userId} userType={userType} />
          ) : (
            <Button
              size="md"
              text={t('login:linkSignin')}
              onClick={() =>
                history.push(
                  `${LOGIN_PATH}?continueUrl=${encodeURIComponent(
                    `${location.pathname || ''}${location.search || ''}`
                  )}`
                )
              }
            />
          )}
        </div>
      </div>
      {displayAlertBanner ? alertBanner : undefined}
    </div>
  );
};

export default MainBar;
