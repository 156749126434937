import React from 'react';

import { ItemEcrou } from 'assets/icons';

export type ListItemProps = {
  items: string[];
  cols?: 1 | 2;
  textClassName?: string;
};

const ListItem = ({
  items,
  cols = 1,
  textClassName = 'text-base font-light',
}: ListItemProps): JSX.Element => {
  const gridClassNames = `${
    cols === 1 ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'
  }`;
  return (
    <div className={`grid ${gridClassNames} gap-2`}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`flex flex-row items-center ${textClassName}`}
        >
          <ItemEcrou className="mr-3 h-4 w-4 shrink-0 fill-current text-primary" />
          {item}
        </div>
      ))}
    </div>
  );
};

export default ListItem;
