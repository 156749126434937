import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { RSet } from 'types/types';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';

export const countVotes = (
  votes: Nullable<Record<string, string>>,
  postIds: RSet<unknown> = {}
): Record<string, number> => {
  const allPosts = _.mapValues(postIds, () => 0);

  const postLiked = Object.values(votes || {}).reduce<Record<string, number>>(
    (prev, vote) => {
      Object.keys(vote || {}).forEach((voteKey) => {
        if (prev[voteKey]) {
          prev[voteKey] = prev[voteKey] + 1;
        } else {
          prev[voteKey] = 1;
        }
      });
      return prev;
    },
    allPosts
  );

  return postLiked;
};

export const computeOrderedVotes = (
  votes: Nullable<Record<string, string>>,
  postsIds: RSet<unknown> = {}
) => {
  return Object.entries(countVotes(votes, postsIds)).sort(
    (a, b) => b[1] - a[1]
  );
};

export const useSessionVotes = (sessionId: string): Record<string, string> => {
  useFirebaseConnect([
    {
      path: `sessionsNextData/${sessionId}/activities/choose/productions/vote`,
    },
  ]);

  const sessionVotes: Record<string, string> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities?.choose
        ?.productions?.vote
  );

  return sessionVotes || {};
};
