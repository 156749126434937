import { createPluginFactory } from '@udecode/plate-core';
import { withLink } from './withLink';
import { EmbedLinkElement } from './EmbedLinkElement';

export const ELEMENT_EMBED_LINK = 'embed_link';

export const createEmbeddedLinkPlugin = createPluginFactory({
  key: ELEMENT_EMBED_LINK,
  isElement: true,
  isVoid: true,
  component: EmbedLinkElement,
  withOverrides: withLink,
});
