import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleBar } from 'types/types';
import {
  sectionsToDoc,
  saveDoc,
  buildDoc,
  activityToDoc,
  ActivityDataType,
} from 'services/docExport';

import SessionPlanView from './SessionPlanView';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { DocFragment } from 'frameworks/plate/plugins/docx-serializer';

export type UserContentViewProps = {
  sessionId: string;
  participantId: string;
  userId: string;
  username: string;
  role: RoleBar;
  sessionTitle: string;
  extraButtons?: JSX.Element[];
};

const UserContentView = ({
  sessionId,
  participantId,
  userId,
  username,
  role,
  sessionTitle,
  extraButtons,
}: UserContentViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [productions, setProductions] = useState<Record<string, any>>({});

  const exportDoc = async () => {
    const fragmentsPromised = Object.values(productions || {}).map(
      async (activityProduction: ActivityDataType) => {
        return await activityToDoc(activityProduction);
      },
      []
    );

    const fragmentsResult = await Promise.all(fragmentsPromised);
    const fragments = fragmentsResult.reduce<DocFragment[]>(
      (current, fragmentItems) => {
        fragmentItems.forEach((item) => {
          current.push(item);
        });
        return current;
      },
      []
    );
    const sections = buildDoc(sessionTitle, fragments);
    const doc = sectionsToDoc(sections);
    saveDoc(`WAP_${sessionTitle}${username ? `_${username}` : ''}`, doc);
  };

  return (
    <div>
      <div className="mb-4 flex flex-row items-center">
        <div className="flex flex-grow flex-row items-center">
          <Avatar size="lg" name={username} />
          <h1 className="ml-4 text-3xl font-semibold">{username}</h1>
        </div>
        <div className="space-x-4">
          {extraButtons ? extraButtons.map((button) => button) : null}
          <Button text={t('common:export')} onClick={exportDoc} />
        </div>
      </div>
      <SessionPlanView
        sessionId={sessionId}
        role={role}
        participantId={participantId}
        userId={userId}
        setProductions={setProductions}
      />
    </div>
  );
};

export default UserContentView;
