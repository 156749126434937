import { X, Expand, UserGroup } from 'assets/icons';

import { useMeeting, useMeetingUsers } from 'frameworks/agora/meetingManager';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { ScreenSize, useScreenSize } from 'utils/utils';
import Button from 'components/Button';
import RoundedButton from 'components/RoundedButton';
import _ from 'lodash';
import { VideoMode } from './types';
import VideoCard from './VideoCard';
import LocalVideoCard from './LocalVideoCard';
import LayoutContext from './LayoutContext';

export type VideoLayoutProps = {
  forceChildrenFullScreen?: boolean;
  childrenThumbnail?: JSX.Element;
  uid: string;
  meetingName: string;
  dataReady: boolean;
  isFacilitator: boolean;
};

const computeNbOthers = (screenSize: ScreenSize, mode: VideoMode) => {
  if (screenSize === 'xss' || screenSize === 'xs') {
    if (mode === 'visioFullScreen') {
      return 3;
    } else {
      return 0;
    }
  } else if (screenSize === 'sm' || screenSize === 'md') {
    if (mode === 'visioFullScreen') {
      return 5;
    } else {
      return 0;
    }
  } else {
    if (mode === 'visioFullScreen') {
      return 10;
    } else if (mode === 'hybrid') {
      return 5;
    } else {
      return 4;
    }
  }
};

const VideoLayout = ({
  forceChildrenFullScreen,
  childrenThumbnail,
  children,
  uid,
  meetingName,
  dataReady,
  isFacilitator,
}: VideoLayoutProps & {
  children: JSX.Element;
}): JSX.Element => {
  const [requestedMode, setRequestedMode] = useState<VideoMode>(
    isFacilitator ? 'childrenFullScreen' : 'hybrid'
  );
  const [selectedPage, setSelectedPage] = useState<number>(0);

  const { t } = useTranslation();

  const mode: VideoMode = forceChildrenFullScreen
    ? 'childrenFullScreen'
    : requestedMode;

  const scrollableDiv = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTo(0, 0);
    }
  }, []);

  const [
    client,
    localVideoTrack,
    localAudioTrack,
    localVideoTrackError,
    localAudioTrackError,
    startLocalScreenTrack,
  ] = useMeeting(
    !forceChildrenFullScreen && dataReady,
    meetingName,
    'group',
    'participant',
    uid
  );

  console.log('MEETING CHANNEL', client?.channelName);

  useFirebaseConnect([`meetingsData/${meetingName}/sharingUserId`]);

  const sharingUserId: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.meetingsData?.[meetingName]?.sharingUserId || null
  );

  const screenSize = useScreenSize();

  const nbOthers = computeNbOthers(screenSize, mode);

  const mustSort = useCallback((nbUsers) => nbUsers > nbOthers + 1, [nbOthers]);

  const meetingUsers = useMeetingUsers(client, sharingUserId, mustSort);

  const speaker = meetingUsers[0];
  const others = meetingUsers.slice(1);
  const lastPage = Math.max(Math.ceil(others.length / nbOthers) - 1, 0);

  useEffect(() => {
    if (selectedPage > lastPage) {
      setSelectedPage(lastPage);
    }
  }, [lastPage, selectedPage]);

  const goToNextPage = useCallback(() => {
    setSelectedPage((page) => Math.min(lastPage, page + 1));
  }, [lastPage]);

  const goToPreviousPage = useCallback(() => {
    setSelectedPage((page) => Math.max(0, page - 1));
  }, []);

  const canGoToNextPage = selectedPage < lastPage;
  const canGoToPreviousPage = selectedPage > 0;

  return (
    <div
      className={`grid h-full w-full grid-cols-12 grid-rows-12 ${
        mode !== 'childrenFullScreen' ? 'lg:gap-2 xs:gap-1 ' : 'lg:gap-1'
      }`}
    >
      {!forceChildrenFullScreen ? (
        <div
          className={`relative ${
            mode === 'speakerFullScreen'
              ? 'col-span-full col-start-1 row-span-full row-start-1'
              : mode === 'visioFullScreen'
              ? 'col-span-6 col-start-1 row-span-4 row-start-1 sm:row-span-3 lg:col-span-6 lg:col-start-1 lg:row-span-6 lg:row-start-1'
              : mode === 'childrenFullScreen'
              ? 'col-span-4 col-start-3 row-span-2 row-start-1 sm:col-span-6 sm:col-start-1   lg:col-span-2 lg:col-start-1'
              : 'col-span-6 col-start-1 row-span-3 row-start-1 lg:col-span-3'
          } overflow-hidden bg-surfaces-divider xs:rounded-md`}
        >
          <VideoCard
            user={speaker}
            client={client}
            remoteControlEnabled={isFacilitator}
            meetingName={meetingName}
          />
          {mode !== 'speakerFullScreen' ? (
            <div className="absolute bottom-1 right-1 z-40">
              <RoundedButton
                color="gray"
                size="sm"
                hoverText={t('video:Expand')}
                onClick={() =>
                  setRequestedMode((mode) =>
                    mode === 'visioFullScreen'
                      ? 'speakerFullScreen'
                      : mode === 'childrenFullScreen' && !isFacilitator
                      ? 'hybrid'
                      : 'speakerFullScreen'
                  )
                }
              >
                <Expand className="stroke-2" />
              </RoundedButton>
            </div>
          ) : (
            <div className="absolute right-1 top-1 left-1 z-10 flex flex-row space-x-2">
              <div className="mx-auto">
                <Button
                  icon={<X className="stroke-2" />}
                  color="gray"
                  size="sm"
                  text={t('video:ExitFullScreen')}
                  onClick={() =>
                    setRequestedMode(
                      !isFacilitator ? 'hybrid' : 'childrenFullScreen'
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : null}

      {!forceChildrenFullScreen ? (
        <div
          className={`relative ${
            mode === 'speakerFullScreen'
              ? 'col-span-6 col-start-1 row-span-3 row-start-10 sm:row-span-2 sm:row-start-11 lg:col-span-3 lg:col-start-1 xl:col-span-2 xl:col-start-1'
              : mode === 'visioFullScreen'
              ? 'col-span-6  row-span-4 row-start-9 sm:col-start-1  sm:row-span-3 sm:row-start-10 lg:col-span-3 xs:rounded-md'
              : mode === 'childrenFullScreen'
              ? 'col-span-4 col-start-7 row-span-2 row-start-1 sm:col-span-6 md:col-start-7 lg:col-span-2 lg:col-start-1 lg:row-start-11'
              : 'col-span-6 row-span-3 lg:col-span-3 lg:col-start-1 lg:row-start-10'
          } overflow-hidden bg-surfaces-divider xs:rounded-md`}
        >
          <LocalVideoCard
            localAudioTrack={localAudioTrack}
            localVideoTrack={localVideoTrack}
            localAudioTrackError={localAudioTrackError}
            localVideoTrackError={localVideoTrackError}
            startLocalScreenTrack={startLocalScreenTrack}
            userId={uid}
            client={client}
            meetingName={meetingName}
            setRequestedMode={setRequestedMode}
            isFacilitator={isFacilitator}
          />
        </div>
      ) : null}
      <div
        ref={scrollableDiv}
        className={`relative ${
          mode === 'speakerFullScreen' ? 'hidden' : '' /*
            see remark 1 at the bottom of the file
          */
        } ${
          mode === 'visioFullScreen'
            ? 'col-span-6 col-start-7 row-span-4 row-start-9 sm:row-span-3 sm:row-start-10 lg:col-span-3 lg:col-start-10'
            : mode === 'childrenFullScreen'
            ? forceChildrenFullScreen
              ? 'col-span-12 col-start-1 row-span-12 row-start-1'
              : 'col-span-12 col-start-1 row-span-10 row-start-3 lg:col-span-10 lg:col-start-3 lg:row-span-12 lg:row-start-1'
            : 'col-span-12 col-start-1 row-span-9 row-start-4 lg:col-span-9 lg:col-start-4'
        }`}
      >
        {mode === 'visioFullScreen' && childrenThumbnail
          ? childrenThumbnail
          : null}
        <div
          className={`${
            mode === 'visioFullScreen' && childrenThumbnail ? 'hidden' : '' /*
              see remark 1 at the bottom of the file
          */
          } h-full w-full overflow-y-auto`}
        >
          <LayoutContext.Provider value={{ scrollToTop }}>
            {children}
          </LayoutContext.Provider>
        </div>
        {!forceChildrenFullScreen ? (
          <div className="absolute top-0 left-0 z-10 flex flex-row space-x-2">
            {mode !== 'childrenFullScreen' ? (
              <Button
                icon={<Expand className="stroke-2" />}
                color="gray"
                size="sm"
                text={t('video:Expand')}
                onClick={() =>
                  setRequestedMode((mode) =>
                    mode === 'visioFullScreen' && !isFacilitator
                      ? 'hybrid'
                      : 'childrenFullScreen'
                  )
                }
              />
            ) : null}
          </div>
        ) : null}
      </div>

      {mode !== 'speakerFullScreen'
        ? _.take(others.slice(selectedPage * nbOthers), nbOthers).map(
            (user, index) => (
              <div
                key={user.user.uid}
                className={`relative col-span-6 row-span-4 bg-surfaces-divider sm:row-span-3 ${
                  mode === 'childrenFullScreen'
                    ? 'lg:col-span-2 lg:row-span-2'
                    : 'lg:col-span-3'
                } overflow-hidden xs:rounded-md`}
              >
                <VideoCard
                  user={user}
                  client={client}
                  remoteControlEnabled={isFacilitator}
                  meetingName={meetingName}
                />
                {canGoToPreviousPage && index === 0 ? (
                  <div className="absolute left-0 top-0 z-10 flex h-full items-center justify-center">
                    <div
                      className="rounded-md bg-black bg-opacity-0 p-1 text-white hover:cursor-pointer hover:text-opacity-80"
                      onClick={() => goToPreviousPage()}
                    >
                      <UserGroup className="h-6 w-6 stroke-3/2" />
                      <p>{selectedPage * nbOthers} +</p>
                    </div>
                  </div>
                ) : null}
                {canGoToNextPage && index === nbOthers - 1 ? (
                  <div className="absolute right-0 top-0 z-10 flex h-full items-center justify-center">
                    <div
                      className="rounded-md bg-black bg-opacity-0 p-1 text-center text-white hover:cursor-pointer hover:text-opacity-80"
                      onClick={() => goToNextPage()}
                    >
                      <UserGroup className="h-6 w-6 stroke-3/2" />
                      <p>{others.length - (selectedPage + 1) * nbOthers} +</p>
                    </div>
                  </div>
                ) : null}
              </div>
            )
          )
        : null}
    </div>
  );
};

export default VideoLayout;

/*
Remark 1:
Session router is passed in the children in SessionLiveScreen/index.tsx
We need router to autoswitch activities, if children is not monted even when visio is in visioFullScreen or speakerFullScreen modes.
We could argue we should put VideoLayout under SessionRouter but we would need a big refactor of all the component stack
Mainly due to right pannel in Layout and the management of the two mode (with nice banner and without)
A big refactor adding complexity in the router for a mode we don't know we want to keep might not be the best path now.
We keep this until the next big overhaul is done (UI or performance)
*/
