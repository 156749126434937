import React from 'react';
import { Menu } from '@headlessui/react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { auth } from 'services/firebase';
import { reset } from 'services/helpChat';
import { LOGIN_PATH } from 'constants/AppConfig';

import Avatar from 'components/Avatar';
import {
  Home,
  Table,
  UserCircle,
  //Library,
  Logout,
  ChevronRight,
} from 'assets/icons';
import { setCurrentEmail } from 'global';
import { isGlobalAdmin } from 'model/users';
import { UserType } from 'types/types';

export type MenuListProps = {
  userName: string;
  userId: string;
  userType: UserType;
};

type menuItemType = {
  description: string;
  icon: JSX.Element;
  to?: string;
  onClick?: () => void;
};

const MenuItem = ({
  icon,
  description,
  onClick,
}: {
  description: string;
  icon: JSX.Element;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`flex w-full min-w-max flex-row items-center py-1 px-4 text-black hover:text-primary ${
            active ? 'bg-primary-soft text-primary' : ''
          }`}
          onClick={onClick}
        >
          <span className="">{icon}</span>
          <span className="ml-4 text-lg">{description}</span>
        </button>
      )}
    </Menu.Item>
  );
};

const MenuList = ({
  userName,
  userId,
  userType,
}: MenuListProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const iconClassName = 'w-6 h-6 stroke-3/2';
  const appMenuItems: menuItemType[] = [
    {
      description: t('common:menuHomePage'),
      icon: <Home className={iconClassName} />,
      to: '/home',
    },
    // {
    //   description: t('common:menuAllContent'),
    //   icon: <Library className={iconClassName} />,
    //   to: '/content',
    // },
  ];

  if (isGlobalAdmin(userType)) {
    appMenuItems.unshift({
      description: t('common:menuAllSessions'),
      icon: <Table className={iconClassName} />,
      to: '/admin/all_sessions',
    });
  }

  const userMenuItems: menuItemType[] = [
    {
      description: t('common:menuMyProfile'),
      icon: <UserCircle className={iconClassName} />,
      to: `/profile/${userId}`,
    },
    {
      description: t('common:logout'),
      icon: <Logout className={iconClassName} />,
      onClick: async () => {
        try {
          await auth.signOut();
          setCurrentEmail(null);
          reset();
          history.push(LOGIN_PATH);
        } catch (error) {
          console.error(error);
        }
      },
    },
  ];

  const renderMenuItems = (linkList: menuItemType[]) => {
    return linkList.map((item, index) => {
      const menuItem = (
        <MenuItem
          key={index}
          description={item.description}
          icon={item.icon}
          onClick={item.onClick}
        />
      );

      return item.to ? (
        <Link key={index} to={item.to}>
          {menuItem}
        </Link>
      ) : (
        menuItem
      );
    });
  };

  return (
    <Menu as="div" className="relative z-100">
      <Menu.Button className="rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-1">
        <div className="flex flex-row items-center">
          <Avatar name={userName} />
          <ChevronRight className="ml-1 h-5 w-5 rotate-90 stroke-2" />
        </div>
      </Menu.Button>
      <Menu.Items className="absolute right-0 mt-2 min-w-max divide-y divide-surfaces-divider rounded-md border border-surfaces-divider bg-white shadow-lg focus:outline-none">
        <div className="py-2">{renderMenuItems(appMenuItems)}</div>
        <div className="py-2">{renderMenuItems(userMenuItems)}</div>
      </Menu.Items>
    </Menu>
  );
};

export default MenuList;
