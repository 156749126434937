import * as React from 'react';

function SvgGroupAssignment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 821.676 579.01"
      {...props}
    >
      <path
        d="M182.076 58h-52.4a19.017 19.017 0 00-19 19v56.81c-.67-.04-1.34-.1-2-.18a66.999 66.999 0 1173.1-77.63c.11.66.21 1.33.3 2z"
        fill="#f2f2f2"
      />
      <path
        d="M800.676 56h-671a21.023 21.023 0 00-21 21v354a21.023 21.023 0 0021 21h671a21.023 21.023 0 0021-21V77a21.023 21.023 0 00-21-21zm19 375a19.017 19.017 0 01-19 19h-671a19.017 19.017 0 01-19-19V77a19.017 19.017 0 0119-19h671a19.017 19.017 0 0119 19z"
        fill="#3f3d56"
      />
      <path
        d="M767.676 113.37h-605a1 1 0 010-2h605a1 1 0 010 2z"
        fill="#3f3d56"
      />
      <path
        d="M333.676 409a1 1 0 01-1-1V79a1 1 0 112 0v329a1 1 0 01-1 1zM484.676 409a1 1 0 01-1-1V79a1 1 0 112 0v329a1 1 0 01-1 1zM635.676 409a1 1 0 01-1-1V79a1 1 0 112 0v329a1 1 0 01-1 1z"
        fill="#3f3d56"
      />
      <path
        d="M236.592 216.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM288.592 339.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM406.592 242.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM559.592 195.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM700.592 195.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="current"
      />
      <path
        d="M765.592 207.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM736.592 284.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="#e6e6e6"
      />
      <path
        d="M612.592 268.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="#ff6584"
      />
      <path
        d="M541.592 284.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5zM388.592 352.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="#e6e6e6"
      />
      <path
        d="M203.592 295.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="#ff6584"
      />
      <path
        d="M306.592 216.587h-36.54a4.505 4.505 0 01-4.5-4.5v-43.12a4.505 4.505 0 014.5-4.5h36.54a4.505 4.505 0 014.5 4.5v43.12a4.505 4.505 0 01-4.5 4.5z"
        fill="#e6e6e6"
      />
      <path
        d="M276.676 97h-57a4 4 0 010-8h57a4 4 0 010 8zM437.676 97h-57a4 4 0 010-8h57a4 4 0 010 8zM588.676 97h-57a4 4 0 010-8h57a4 4 0 010 8zM729.676 97h-57a4 4 0 010-8h57a4 4 0 010 8z"
        fill="#ccc"
      />
      <path
        d="M149.01 389.891a10.056 10.056 0 01-8.7-12.73L114.675 353.3l13.553-3.438 25.206 20.443a10.11 10.11 0 01-4.426 19.586zM184.404 566.253h12.26l5.832-47.288h-18.094l.002 47.288z"
        fill="#ffb8b8"
      />
      <path
        d="M220.308 577.636l-38.53.001-.001-14.887h23.644a14.887 14.887 0 0114.887 14.886z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M100.345 557.401l11.848 3.154 17.801-44.196-17.485-4.654-12.164 45.696z"
      />
      <path
        d="M132.113 577.64l-37.234-9.911 3.829-14.386 22.848 6.081a14.887 14.887 0 0110.557 18.215zM199.961 557.3h-18.707a4.73 4.73 0 01-4.726-4.293L165.17 435.075a3.748 3.748 0 00-7.32-.715l-32.974 110.2a4.742 4.742 0 01-5.7 3.246l-17.403-4.352a4.747 4.747 0 01-3.448-5.787c.285-1.11 28.411-111.028 28.657-112.158 6.372-46.968 13.997-59.554 18.093-66.315.339-.56.65-1.074.93-1.56.352-.609 2.513-6.645 3.884-10.565a4.79 4.79 0 012.435-2.977c17.211-8.802 37.348-2.71 43.11-.632a4.682 4.682 0 012.338 1.815c17.284 26.072 8.802 177.492 6.926 207.581a4.751 4.751 0 01-4.738 4.444z"
        fill="#2f2e41"
      />
      <circle cx={151.684} cy={234.024} r={24.561} fill="#ffb8b8" />
      <path
        d="M149.082 351.838l-.177-.171c-.33-.32-33.055-32.38-28.82-62.024 1.811-12.68 12.07-20.782 29.667-23.431a27.135 27.135 0 0130 19.121l17.4 59.484z"
        fill="#ccc"
      />
      <path
        d="M132.333 374.64l-23.319-23.17a13.137 13.137 0 01-3.48-12.392l11.29-45.919a10.354 10.354 0 0113.18-7.4 10.41 10.41 0 016.808 12.794l-11.884 40.396 19.218 20.637z"
        fill="#ccc"
      />
      <path
        d="M257.404 295.562a10.056 10.056 0 01-14.883 4.03l-31.13 16.134 1.376-13.706 27.597-17.08a10.11 10.11 0 0117.04 10.622z"
        fill="#ffb8b8"
      />
      <path
        d="M239.121 305.765l-30.346 13.113a12.809 12.809 0 01-12.477-1.338l-38.589-27.65a10.355 10.355 0 01-1.974-14.985 10.41 10.41 0 0114.409-1.568l33.088 26.042 26.3-10.175z"
        fill="#ccc"
      />
      <path
        d="M155.756 241.895c3.03-3.797 8.69-4.001 12.853-6.505 5.452-3.28 7.706-10.611 5.949-16.726s-6.938-10.881-12.879-13.16-12.55-2.308-18.816-1.206c-9.33 1.64-18.516 5.995-24.206 13.569s-7.139 18.666-1.982 26.612c2.37 3.653 6.03 6.667 6.976 10.917 1.242 5.58-2.672 10.961-6.864 14.846-4.777 4.427-10.257 8.134-14.442 13.123s-6.976 11.806-5.066 18.032c1.6 5.217 6.256 9.06 11.361 10.984s10.666 2.203 16.121 2.31c5.298.103 10.924-.025 15.386-2.882 4.778-3.057 7.328-8.858 7.517-14.527s-1.706-11.222-4.245-16.294c-1.513-3.023-3.273-5.953-4.278-9.18s-1.18-6.89.43-9.863c1.693-3.125 5.117-5.01 8.604-5.694.182-.036.373-.068.574-.096a7.398 7.398 0 006.333-8.628c-.397-2.214-.363-4.334.674-5.632z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M483.229 567.3h-12.26l-5.832-47.288 18.094.001-.002 47.287z"
      />
      <path
        d="M462.212 563.797h23.644v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M572.184 553.413l-11.151 5.093-24.954-40.589 16.459-7.518 19.646 43.014z"
      />
      <path
        d="M551.612 558.958l21.506-9.823 6.185 13.541-35.047 16.009a14.887 14.887 0 017.356-19.727z"
        fill="#2f2e41"
      />
      <path
        d="M536.482 407.857a10.056 10.056 0 00.8-15.399l14.412-32.7-18.308 3.103-10.879 30.577a10.11 10.11 0 0013.975 14.419z"
        fill="#a0616a"
      />
      <path
        d="M482.448 554.831h-11.712a4.517 4.517 0 01-4.489-4.173l-6.387-170.56a4.5 4.5 0 014.926-4.804l67.735 7.194a4.501 4.501 0 014.062 4.393l1.42 75.236a3.492 3.492 0 00.233 1.193l27.785 72.053a4.499 4.499 0 01-2.643 5.84l-11.868 4.373a4.501 4.501 0 01-5.558-2.164l-34.132-66.369a4.475 4.475 0 01-.404-1.142l-5.831-28.094a3.5 3.5 0 00-6.904.312l-11.763 102.724a4.499 4.499 0 01-4.47 3.988z"
        fill="#2f2e41"
      />
      <circle cx={488.279} cy={222.178} r={24.561} fill="#a0616a" />
      <path
        d="M505.123 395.694a87.664 87.664 0 01-46.873-14.198l-.239-.165.025-.29 9.395-109.415a15.42 15.42 0 0114.599-14.062c12.176-.56 28.53.146 37.286 6.667 13.23 9.854 19.607 24.693 17.495 40.712-4.38 33.219.582 78.03 1.162 83.03l.042.36-.33.15a77.266 77.266 0 01-32.562 7.211z"
        fill="current"
      />
      <path
        d="M537.86 388.158a5.007 5.007 0 01-3.68-1.622l-5.373-5.862a5.026 5.026 0 01-1.11-4.795l12.251-41.463-18.64-39.144a8.832 8.832 0 01-.603-5.88 8.809 8.809 0 0116.313-2.083l25.295 41.042a11.95 11.95 0 01.72 11.198l-20.61 45.665a4.988 4.988 0 01-3.767 2.88 5.054 5.054 0 01-.796.064z"
        fill="current"
      />
      <path
        d="M364.738 308.953a10.056 10.056 0 0014.998-3.578l35.438 4.596-8.144-16.688-32.404-1.806a10.11 10.11 0 00-9.888 17.476z"
        fill="#a0616a"
      />
      <path
        d="M442.85 318.067a13.425 13.425 0 01-1.845-.128l-55.751-7.732a5.541 5.541 0 01-4.251-3.111 5.657 5.657 0 01-.318-.83 5.564 5.564 0 01.575-4.431l4.613-7.649a5.584 5.584 0 014.756-2.686h.008l48.596.056 36.294-32.517a9.868 9.868 0 016.107-2.5 9.835 9.835 0 017.372 16.816l-36.215 40.278a13.364 13.364 0 01-9.942 4.434z"
        fill="current"
      />
      <path
        d="M491.072 244.028c2.244-5.195 4.143-12.113-.162-15.785-2.497-2.13-6.093-2.135-9.365-2.4-9.217-.747-18.706-4.988-23.21-13.063s-1.99-20.085 6.51-23.728c5.729-2.456 12.297-.783 18.294.917l18.006 5.106c5.35 1.517 10.967 3.19 14.819 7.202 6.142 6.397 5.683 17.211.804 24.618s-12.226 15.13-20.637 17.942z"
        fill="#2f2e41"
      />
      <path
        d="M739.485 579.01H1.19a1.19 1.19 0 110-2.382h738.294a1.19 1.19 0 110 2.382z"
        fill="#3f3d56"
      />
    </svg>
  );
}

export default SvgGroupAssignment;
