import Banner from 'components/Banner';
import MainBar from 'components/MainBar';
import Tabs, { TabInfo } from 'components/Tabs';
import React from 'react';
import { Community, CommunityRole } from 'types/types';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
// import CommunityHome from './CommunityHome';
import CommunityUsers from './CommunityUsers';
import CommunitySessions from './CommunitySessions';
import CommunityInvites from './CommunityInvites';
import CommunityTemplates from './CommunityTemplates';
import Thumbnail from 'components/Thumbnail';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import LoadingScreen from 'screens/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUserAlertBanner } from 'model/userAlert';
import { CommunityMetrics } from './CommunityMetrics';
import { isGlobalAdmin, useUser } from 'model/users';
import { useUserContextWithDefaults } from 'contexts/UserContext';
import DocumentTitle from 'components/DocumentTitle';

const CommunityDashboard = (): JSX.Element => {
  const { pathname } = useLocation();
  const { userId, userName, userType, userEmail } =
    useUserContextWithDefaults();
  const { url, path, params } = useRouteMatch<{ communityId: string }>();
  const history = useHistory();

  const { t } = useTranslation();

  const parts = pathname.split('/');

  const communityId = params.communityId;

  const [user] = useUser(userId);

  useFirebaseConnect([
    `communities/${communityId}`,
    `adminsOfCommunities/${communityId}/${userId}`,
  ]);

  const community: Community | null = useSelector(
    (state: any) => state.firebase.data.communities?.[communityId]
  );

  const isAdminOfCommunity: true | null = useSelector(
    (state: any) =>
      state.firebase.data.adminsOfCommunities?.[communityId]?.[userId]
  );

  const role: CommunityRole =
    isGlobalAdmin(userType) || isAdminOfCommunity ? 'organizer' : 'guest';

  const tabs: TabInfo[] = [
    {
      key: 'sessions',
      label: t('communities:Sessions'),
    },
    {
      key: 'users',
      label: t('communities:Members'),
    },
  ];

  if (role === 'organizer') {
    if (isGlobalAdmin(userType) || !!user?.expertMode) {
      tabs.push({
        key: 'templates',
        label: t('communities:Templates'),
      });
    }
    tabs.push({
      key: 'invites',
      label: t('communities:Invites'),
    });
    tabs.push({
      key: 'metrics',
      label: t('communities:Metrics'),
    });
  }

  const selected: string | undefined = tabs.find(
    (tab) => tab.key === parts[3]
  )?.key;

  useUserAlertBanner(userId, userEmail);

  return isLoaded(community) ? (
    community ? (
      <DocumentTitle title={community.name}>
        <div className="min-h-screen w-full bg-white pb-12">
          <MainBar userId={userId} userType={userType} userName={userName} />
          <Banner colorClass="bg-gray-soft">
            <div className="w-full pt-8">
              <div className="mx-auto flex max-w-5xl flex-row items-center space-x-4 px-4">
                <Thumbnail name={community.name} size="big" />
                <div className="flex flex-col">
                  <p className="text-base">
                    {t('communities:Xmembers', { count: community.nbMembers })}
                  </p>
                  <h1 className="text-4xl font-semibold">{community.name}</h1>
                </div>
              </div>
            </div>
          </Banner>

          <div className="border-b border-surfaces-divider">
            <div className="mx-auto max-w-5xl px-4">
              <Tabs
                tabs={tabs}
                selected={selected}
                onChange={(key) => history.push(`${url}/${key}`)}
              />
            </div>
          </div>
          <div className="mx-auto max-w-5xl px-4 pt-6">
            <Switch>
              {/* <Route path={`${path}/home`}>
            <CommunityHome />
          </Route> */}
              <Route exact path={`${path}/`}>
                <Redirect to={`${url}/sessions`} />
              </Route>
              <Route path={`${path}/sessions`}>
                <CommunitySessions
                  communityId={communityId}
                  userId={userId}
                  role={role}
                />
              </Route>
              <Route path={`${path}/users`}>
                <CommunityUsers
                  communityId={communityId}
                  userId={userId}
                  userType={userType}
                />
              </Route>
              {role === 'organizer'
                ? [
                    // why not fragments ?
                    // https://stackoverflow.com/questions/66463284/react-router-warning-route-elements-should-not-change-from-controlled-to-unco
                    <Route key="community/templates" path={`${path}/templates`}>
                      <CommunityTemplates
                        communityId={communityId}
                        userId={userId}
                        userType={userType}
                      />
                    </Route>,
                    <Route key="community/invites" path={`${path}/invites`}>
                      <CommunityInvites
                        communityId={communityId}
                        community={community}
                        userId={userId}
                        userName={userName}
                        userEmail={userEmail}
                      />
                    </Route>,
                    <Route key="community/metrics" path={`${path}/metrics`}>
                      <CommunityMetrics
                        communityId={communityId}
                        userType={userType}
                      />
                    </Route>,
                  ]
                : null}
            </Switch>
          </div>
        </div>
      </DocumentTitle>
    ) : (
      <Redirect
        to={{
          pathname: '/home',
          state: {
            error: t('communities:CommunityDoesNotExistsOrNotAllowed'),
          },
        }}
      />
    )
  ) : (
    <LoadingScreen />
  );
};

export default CommunityDashboard;
