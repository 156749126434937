import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Activity, ActivityMeta, SessionLanguage, UserType } from 'types/types';
import { Mode } from 'types/ui';
import { msToMinutesSecondes } from 'services/datetimes';
import { usePrevious, formatTime } from 'utils/utils';

import { useAlert } from 'contexts/Alerts';
import Button from 'components/Button';

import Status from './Status';
import EditionButtonsBar from './EditionButtonsBar';
import withProgress, { useActivityProgress } from './withProgress';

import {
  Plus,
  History,
  PauseCircle,
  PlayCircle,
  Minus,
  ChevronUp,
} from 'assets/icons';
import { isGlobalAdmin } from 'model/users';

export const ProgressingStatus = withProgress(10000, Status);

const ActivityStep = ({
  userType,
  mode,
  activity,
  isEnabled,
  isSelectable,
  isSelected,
  activityMeta,
  toggleActivity,
  selectActivity,
  deleteActivity,
  inserActivity,
  resetActivityTimer,
  showActivitySettings,
  onActivityEnd,
  stopActivityTime,
  resumeActivityTime,
  updateActivityTime,
  toggleNextActivity,
  forceHideStatus,
  lng,
  smallMode = false,
}: {
  userType: UserType;
  mode: Mode;
  activity: Activity;
  isEnabled: boolean;
  isSelectable: boolean;
  isSelected: boolean;
  activityMeta: ActivityMeta;
  toggleActivity: () => void;
  selectActivity: () => void;
  deleteActivity?: () => Promise<void>;
  inserActivity?: (name: string, sourceActivityName: string) => Promise<void>;
  resetActivityTimer: () => Promise<void>;
  showActivitySettings: () => void;
  onActivityEnd: () => void;
  stopActivityTime: (remainingMs: number) => Promise<void>;
  resumeActivityTime: () => Promise<void>;
  updateActivityTime: (remainingMs: number) => Promise<void>;
  toggleNextActivity: () => void;
  forceHideStatus: boolean;
  lng: SessionLanguage;
  smallMode?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [alertId, , alertHelpers] = useAlert();
  const [, remainingMs] = useActivityProgress(
    1000,
    activity.duration ? activity.duration * 60000 : undefined,
    activityMeta
  );

  const [showExtraPanel, setShowExtraPanel] = useState(false);

  const shouldShowExtraPanel =
    isEnabled &&
    mode !== 'editor' &&
    !!activity.duration &&
    (isSelected || mode === 'facilitator') &&
    (!smallMode || showExtraPanel);

  const [remainingMinutes, remainingSecondes] = useMemo(
    () => msToMinutesSecondes(remainingMs),
    [remainingMs]
  );

  const previousRemainingMs = usePrevious(remainingMs);

  useEffect(() => {
    if (activity.duration && remainingMs === 0 && !!previousRemainingMs) {
      onActivityEnd();
    }
  }, [
    remainingMs,
    previousRemainingMs,
    activity.duration,
    activity.name,
    onActivityEnd,
  ]);

  useEffect(() => {
    if (isEnabled) {
      const facilitatorAlertTriggerMs = 60000;
      const participantAlertTriggerMs = 15000;
      if (remainingMs !== null) {
        if (remainingMs === 0 && mode === 'facilitator') {
          if (alertId !== 'timeEllapsed') {
            alertHelpers.displayAlert({
              id: 'timeEllapsed',
              type: 'warning',
              title: t('sessions:timeEllapsedTitle'),
              description: isGlobalAdmin(userType)
                ? t('sessions:timeEllapsedDescriptionAdmin')
                : t('sessions:timeEllapsedDescriptionFacilitator'),
              callToAction: isGlobalAdmin(userType) ? (
                <Button
                  color={'warning'}
                  size="md"
                  text={t('sessions:launch')}
                  onClick={() => {
                    toggleNextActivity();
                  }}
                />
              ) : undefined,
            });
          }
        } else if (
          remainingMs < facilitatorAlertTriggerMs &&
          mode === 'facilitator'
        ) {
          if (alertId !== 'timeNearlyEllapsed') {
            alertHelpers.displayAlert({
              id: 'timeNearlyEllapsed',
              type: 'info',
              title: t('sessions:timeNearlyEllapsedTitle'),
              description: isGlobalAdmin(userType)
                ? t('sessions:timeNearlyEllapsedDescriptionAdmin')
                : t('sessions:timeNearlyEllapsedDescriptionFacilitator'),
            });
          }
        } else if (
          remainingMs < participantAlertTriggerMs &&
          (mode === 'participant' || mode === 'preview')
        ) {
          if (alertId !== 'alertSwitchScreenParticipant') {
            alertHelpers.displayAlert({
              id: 'alertSwitchScreenParticipant',
              type: 'info',
              title: t('sessions:timeEllapsedTitle'),
              description: t(
                'sessions:timeNearlyEllapsedDescriptionParticipant'
              ),
            });
          }
        } else if (
          (remainingMs >= facilitatorAlertTriggerMs &&
            mode === 'facilitator') ||
          (remainingMs >= participantAlertTriggerMs &&
            (mode === 'participant' || mode === 'preview'))
        ) {
          if (
            alertId === 'timeNearlyEllapsed' ||
            alertId === 'timeEllapsed' ||
            alertId === 'alertSwitchScreenParticipant'
          ) {
            alertHelpers.clearAlert();
          }
        }
      } else {
        if (
          alertId === 'timeNearlyEllapsed' ||
          alertId === 'timeEllapsed' ||
          alertId === 'alertSwitchScreenParticipant'
        ) {
          alertHelpers.clearAlert();
        }
      }
    }
  }, [
    remainingMs,
    mode,
    alertHelpers,
    alertId,
    toggleNextActivity,
    t,
    isEnabled,
    userType,
  ]);

  const stepStateStyle = `${
    isSelectable
      ? `cursor-pointer ${
          isEnabled || isSelected ? '' : 'hover:border-surfaces-strongest'
        }`
      : 'text-gray-400'
  } ${isEnabled ? 'bg-primary text-white ' : 'bg-white '} ${
    isEnabled || isSelected ? 'border-primary-strong' : 'border-surfaces-strong'
  }`;

  const acticityDurationMs = activity.duration
    ? activity.duration * 60000
    : undefined;

  return (
    <div
      className="flex w-full items-center"
      onMouseMove={(event) => event.preventDefault()}
    >
      {mode !== 'editor' && !forceHideStatus && (
        <ProgressingStatus
          mode={mode}
          durationMs={acticityDurationMs}
          remainingTimeMs={remainingMs ? remainingMs : undefined}
          paused={activityMeta?.isPaused}
          state={
            isEnabled
              ? 'running'
              : activityMeta?.enabledAt
              ? 'passed'
              : 'locked'
          }
          onClick={toggleActivity}
        />
      )}

      <div
        className={`relative m-2 flex min-h-12 flex-grow ${
          !smallMode || shouldShowExtraPanel
            ? 'flex-col justify-center'
            : 'flex-row items-center justify-between'
        } rounded-2xl border-2 py-2 px-4 text-base ${stepStateStyle}`}
        onClick={selectActivity}
      >
        <div className="flex flex-grow items-center">
          <div className="ml-1 flex flex-grow flex-row text-left font-bold md:text-base">
            {`${activity.humanName || activity.name}`}
            {mode === 'facilitator' &&
            smallMode &&
            isEnabled &&
            activity.duration ? (
              <ChevronUp
                onClick={(event) => {
                  event.stopPropagation();
                  setShowExtraPanel((show) => !show);
                }}
                className={`ml-2 h-6 w-6 stroke-2 ${
                  showExtraPanel ? '' : 'rotate-180'
                }`}
              />
            ) : undefined}
          </div>

          {mode === 'editor' ? (
            <div className="flex flex-col items-end">
              {activity.duration ? formatTime(activity.duration) : ''}
              <EditionButtonsBar
                lng={lng}
                onClickDelete={deleteActivity}
                onClickInsert={inserActivity}
                onClickSettings={showActivitySettings}
              />
            </div>
          ) : !isEnabled ? (
            <div>
              {activity.duration
                ? `${Math.floor(activity.duration / 60)}h${`${Math.floor(
                    activity.duration % 60
                  )}`.padStart(2, '0')}`
                : ''}
            </div>
          ) : null}
        </div>
        {shouldShowExtraPanel ? (
          <>
            <div className="justify-left mx-auto mt-2 flex items-center space-x-2 sm:justify-between">
              <p
                className={`text-white xs:w-36 ${
                  mode === 'facilitator'
                    ? 'flex text-[46px] leading-none'
                    : 'w-full py-3 text-[46px] leading-none'
                }`}
              >
                {`${remainingMinutes
                  .toString()
                  .padStart(2, '0')}:${remainingSecondes
                  .toString()
                  .padStart(2, '0')}`}
              </p>
              {mode === 'facilitator' && (
                <div className="flex  shrink flex-col items-center justify-between space-y-2">
                  <button
                    className="flex h-6 w-6 items-center justify-center rounded-full bg-primary-soft text-xl font-semibold text-primary hover:bg-success-soft hover:text-success"
                    onClick={(event) => {
                      event.stopPropagation();
                      return (
                        remainingMs !== null &&
                        acticityDurationMs &&
                        updateActivityTime(
                          Math.floor((remainingMs + 60500) / 60000) * 60000
                        )
                      );
                    }}
                  >
                    <Plus className="h-5 w-5 stroke-2" />
                  </button>
                  <button
                    className="flex h-6 w-6 items-center justify-center rounded-full  bg-primary-soft font-mono text-3xl font-semibold text-primary hover:bg-warning-soft hover:text-warning"
                    onClick={(event) => {
                      event.stopPropagation();
                      return (
                        remainingMs !== null &&
                        updateActivityTime(
                          Math.floor((remainingMs - 500) / 60000) * 60000
                        )
                      );
                    }}
                  >
                    <Minus className="h-5 w-5 stroke-2" />
                  </button>
                </div>
              )}
            </div>
            {mode === 'facilitator' && (
              <div className="justify-left mx-auto mt-2 flex items-center sm:mx-0 sm:justify-between">
                <div
                  className="flex flex-row items-center rounded-md p-1 text-primary-soft hover:bg-danger-soft hover:text-danger"
                  onClick={(event) => {
                    event.stopPropagation();
                    resetActivityTimer();
                  }}
                >
                  <History className="h-5 w-5" />
                  <p className="ml-1 text-xs font-semibold uppercase">
                    {t('common:reset')}
                  </p>
                </div>

                <div
                  className={`flex flex-row items-center rounded-md p-1 text-primary-soft ${
                    activityMeta?.isPaused
                      ? 'hover:bg-success-soft hover:text-success'
                      : 'hover:bg-warning-soft hover:text-warning'
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    activityMeta?.isPaused
                      ? resumeActivityTime()
                      : remainingMs !== null && stopActivityTime(remainingMs);
                  }}
                >
                  {activityMeta?.isPaused ? (
                    <PlayCircle className="h-6 w-6 stroke-2" />
                  ) : (
                    <PauseCircle className="h-6 w-6 stroke-2" />
                  )}

                  <p className="ml-1 text-xs font-semibold uppercase">
                    {activityMeta?.isPaused
                      ? t('common:resume')
                      : t('common:pause')}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : isEnabled && activity.duration && mode !== 'editor' ? (
          <p
            className={`text-white ${
              mode === 'facilitator'
                ? 'flex  grow  text-[46px] leading-none'
                : 'w-full py-3 text-center text-[54px] leading-none'
            }`}
          >
            {`${remainingMinutes
              .toString()
              .padStart(2, '0')}:${remainingSecondes
              .toString()
              .padStart(2, '0')}`}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ActivityStep;
