import React from 'react';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Mode } from 'types/ui';
import { PostItem, Navigation } from 'types/ui';

import {
  getFormattedDateTime,
  getFormattedDateTimeEn,
} from 'services/datetimes';
import { templateReplace } from 'utils/utils';
import { buildPost } from 'utils/dataGenerator';
import { useUsers } from 'model/users';
import { useEstimatedParticipantsCount } from 'model/sessions';

import LayoutFormPreview from 'blocks/LayoutFormPreview';
import Tooltip from 'components/Tooltip';
import List from 'components/List';
import Post from 'blocks/Post';
import { Pencil } from 'assets/icons';
import { getGeneratedName } from 'services/nameGenerator';

type PostScreenListViewProps = {
  mode: Mode;
  thankyou: string;
  scheduledAt?: [number, number];
  posts: PostItem[];
  myPost: PostItem;
  navigation: Navigation;
};

const PostScreenListView = ({
  mode,
  thankyou,
  scheduledAt,
  posts,
  myPost,
  navigation,
}: PostScreenListViewProps & {
  simulatedParticipantsCount: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const now = Date.now();
  const context = {
    dateEn: getFormattedDateTimeEn(moment(scheduledAt?.[0])),
    dateFr: getFormattedDateTime(moment(scheduledAt?.[0])),
  };

  return (
    <div className="space-y-8">
      {scheduledAt?.[0] && scheduledAt?.[0] > now && thankyou ? (
        <Tooltip
          content={
            mode === 'editor'
              ? t('sessions:onlyVisibleBeforeSession')
              : undefined
          }
        >
          {
            <h2 className="whitespace-pre-line text-2xl">
              {templateReplace(thankyou || '', context)}
            </h2>
          }
        </Tooltip>
      ) : null}

      <div>
        <h3 className="mb-4 text-xl font-semibold">
          {t('sessions:myExperienceTitle')}
        </h3>
        {myPost ? (
          <Post
            content={myPost.content}
            userName={t('common:You')}
            actionIcon={
              <Pencil
                className="m-auto h-5 w-5 cursor-pointer stroke-3/2 hover:text-primary"
                onClick={() => {
                  mode !== 'editor' &&
                    navigation?.goToPrev &&
                    navigation.goToPrev(true);
                }}
              />
            }
          />
        ) : null}
      </div>
      <List<PostItem>
        title={t('sessions:postParticipantsTitle')}
        items={posts}
        itemsPerPage={20}
        renderItem={(item) => {
          const userName = item.author?.name || t('common:unknowUser');
          return <Post content={item.content} userName={userName} />;
        }}
        renderEmptyList={() => {
          return (
            <div className="text-succes rounded-lg bg-success-soft p-3">
              <h4 className="text-lg font-semibold">
                {t('sessions:Congratulations')}
              </h4>
              <p>{t('sessions:postFirstPostMessage')}</p>
            </div>
          );
        }}
      />
    </div>
  );
};

type PostScreenListFormProps = {
  thankyou: string;
  setThankyou?: (val: string) => void;
};

const PostScreenListForm = ({
  thankyou,
  setThankyou,
}: PostScreenListFormProps): JSX.Element => {
  const { t } = useTranslation();
  const commonFieldClassName = 'w-full p-3 border rounded-md mt-2';
  const commonLabelClassName = 'text-lg text-primary';
  return (
    <Formik
      initialValues={{
        thankyou: thankyou,
      }}
      validate={(values) => {
        if (setThankyou && values.thankyou !== thankyou) {
          setThankyou(values.thankyou);
        }
        return {};
      }}
      onSubmit={() => {
        // Do nothing
      }}
    >
      <Form className="">
        <div>
          <label htmlFor="thankyou" className={`${commonLabelClassName}`}>
            {t('sessions:postThankyouLabel')}
          </label>
          <Field
            id="thankyou"
            name="thankyou"
            component="textarea"
            className={`${commonFieldClassName} h-40`}
          />
        </div>
      </Form>
    </Formik>
  );
};

type PostScreenListProps = PostScreenListViewProps &
  PostScreenListFormProps & {
    contents: Record<string, string>;
    userId: string;
    sessionId: string;
  };

const PostScreenList = (props: PostScreenListProps): JSX.Element => {
  const posts = props.contents;

  const simulatedParticipantsCount = useEstimatedParticipantsCount(
    props.sessionId
  );
  const [users] = useUsers(posts || {});

  const [currentPostUserId, currentPostContent] = Object.entries(
    posts || {}
  ).find(([userId]) => props.userId === userId) || [props.userId, ''];
  const userPost =
    props.mode !== 'editor'
      ? {
          author: {
            userId: currentPostUserId,
            name: users[currentPostUserId]?.name,
          },
          content: currentPostContent,
          isLiked: false,
        }
      : buildPost();

  const postsArray =
    props.mode !== 'editor'
      ? Object.entries(posts || {})
          .filter(([userId]) => props.userId !== userId)
          .map(([userId, content]) => {
            return {
              author: {
                userId: userId,
                name:
                  props.mode === 'participant' || props.mode === 'preview'
                    ? getGeneratedName(userId)
                    : users[userId]?.name,
              },
              content: content,
              isLiked: false,
            };
          })
      : Array.from({ length: simulatedParticipantsCount }, (_, i) =>
          buildPost(i)
        );

  const viewProps: PostScreenListViewProps = {
    ..._.pick(props, ['mode', 'thankyou', 'scheduledAt', 'navigation']),
    myPost: userPost,
    posts: postsArray,
  };

  const formProps: PostScreenListFormProps = _.pick(props, [
    'thankyou',
    'setThankyou',
  ]);

  return (
    <LayoutFormPreview
      mode={props.mode}
      form={<PostScreenListForm {...formProps} />}
      view={
        <PostScreenListView
          {...viewProps}
          simulatedParticipantsCount={simulatedParticipantsCount}
        />
      }
    />
  );
};

export default PostScreenList;
