import React, { useState } from 'react';

import { Form, Popover, Button, Input, Space } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { Store } from 'antd/lib/form/interface';

const InputForm = ({
  close,
  onSubmit,
  placeholder,
}: {
  close: () => void;
  onSubmit: (input: string) => Promise<void>;
  placeholder?: string;
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Store) => {
    console.log('Success:', values);
    setLoading(true);

    const { input } = values;
    try {
      onSubmit(input);
      close();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name="input" rules={[{ required: true }]}>
        <Input placeholder={placeholder} />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button size="small" onClick={close}>
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {!loading ? 'Ok' : '   '}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const PopInput = ({
  title,
  placeholder,
  onSubmit,
  children,
}: {
  title?: React.ReactNode;
  placeholder?: string;
  onSubmit: (input: string) => Promise<void>;
  children: React.ReactElement;
}): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Popover
      content={
        <InputForm
          placeholder={placeholder}
          close={() => setVisible(false)}
          onSubmit={onSubmit}
        />
      }
      title={title}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      {children}
    </Popover>
  );
};

export default PopInput;
