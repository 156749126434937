import List from 'components/List';
import {
  addTopic,
  updateTopic,
  removeTopic,
  setPostMetaTopic,
  useActivityProductionData,
  usePostsMeta,
  useTopics,
  useSessionActivityCriteria,
  updateProductionForUser,
} from 'model/productions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from 'screens/LoadingScreen';
import { Activity, Topic } from 'types/types';
import { ColorNames } from 'types/ui';
import Button from 'components/Button';
import { WithId } from 'types/typesUtils';
import RoundedButton from 'components/RoundedButton';
import {
  Check,
  CogLight,
  Pencil,
  Plus,
  Trash,
  ArrowsRightLeft,
  ChevronRight,
  X,
} from 'assets/icons';
import _ from 'lodash';
import Modal from 'components/Modal';
import MenuButtons, { Action } from 'components/MenuButtons';
import { usePrevious } from 'utils/utils';

const orderedColors: ColorNames[] = [
  'sky',
  'fuchsia',
  'green',
  'yellow',
  'violet',
  'primary',
  'danger',
  'success',
  'warning',
  'lime',
  'indigo',
  'gray',
  'teal',
];

type TopicProps = {
  topicId: string;
  topic: Topic;
  updateTopic: (topicId: string, topic: Topic) => Promise<void>;
  removeTopic: (topicId: string) => Promise<void>;
  count: number;
};

const TopicRow = ({
  topicId,
  topic,
  updateTopic,
  removeTopic,
  count,
}: TopicProps) => {
  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(topic.description);

  const prevEditing = usePrevious(editing);

  useEffect(() => {
    if (editing && !prevEditing) {
      setEditingValue(topic.description);
    }
  }, [editing, prevEditing, topic.description]);

  return (
    <div className="flex flex-row justify-between space-x-4 border-t pt-4">
      {editing ? (
        <input
          value={editingValue}
          className=" grow rounded-lg border py-2 px-3 text-lg"
          onChange={(e) => {
            setEditingValue(e.target.value);
            updateTopic(topicId, {
              color: topic.color,
              description: e.target.value,
            });
          }}
        />
      ) : (
        <Button
          size="sm"
          text={topic.description}
          color={topic.color}
          badge={`${count}`}
        />
      )}

      <div className="flex shrink-0 flex-row items-center space-x-2">
        {editing ? (
          <RoundedButton
            size="sm"
            color="success"
            onClick={() => setEditing(false)}
          >
            <Check className="stroke-2" />
          </RoundedButton>
        ) : (
          <>
            <RoundedButton
              size="sm"
              color="primary"
              onClick={() => {
                setEditing(true);
              }}
            >
              <Pencil className="stroke-2" />
            </RoundedButton>
            <RoundedButton
              size="sm"
              color="danger"
              onClick={() => removeTopic(topicId)}
            >
              <Trash className="stroke-2" />
            </RoundedButton>
          </>
        )}
      </div>
    </div>
  );
};

const TopicInput = ({
  nextColor,
  insertTopic,
}: {
  nextColor: ColorNames;
  insertTopic: (topic: Topic) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  return (
    <div className="flex shrink-0 flex-row items-center space-x-3 overflow-hidden rounded-full border border-dashed border-surfaces-divider pl-2">
      <input
        className="flex min-w-0 grow text-xs placeholder-black-soft outline-none"
        placeholder={t('sessions:newTopics')}
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        onKeyPress={async (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            await insertTopic({
              description: description,
              color: nextColor,
            });
            setDescription('');
          }
        }}
      />

      <RoundedButton
        disabled={description === ''}
        className="shrink-0"
        onClick={async () => {
          await insertTopic({
            description: description,
            color: nextColor,
          });
          setDescription('');
        }}
        size="sm"
        color="success"
      >
        <Plus className="stroke-2" />
      </RoundedButton>
    </div>
  );
};

type PostItemProps = {
  id: string;
  post: string;
  name: string;
  criteria: Record<string, string>;
  criteriaSettings: Record<string, { options: string[]; name: string }>;
  updateProduction: (
    productionName: string,
    userId: string,
    value: string
  ) => Promise<void>;
  topics: Record<string, Topic>;
  topicsUserCount: Record<string, number>;
  topic?: Topic;
  insertTopic: (topic: Topic) => Promise<string | null>;
  updatePostMetaTopic: (
    productionId: string,
    topicId: string | null
  ) => Promise<void>;
};

const PostItem = ({
  id,
  post,
  criteria,
  criteriaSettings,
  updateProduction,
  topic,
  name,
  topics,
  topicsUserCount,
  insertTopic,
  updatePostMetaTopic,
}: PostItemProps) => {
  const [editing, setEditing] = useState(false);
  const [editingPost, setEditingPost] = useState(post);

  const topicsArray: WithId<Topic>[] = useMemo(
    () =>
      Object.entries(topics).map(([id, topic]) => ({
        id: id,
        ...topic,
      })),

    [topics]
  );

  const nextColor: ColorNames = useMemo(() => {
    return orderedColors[topicsArray.length % (orderedColors.length - 1)];
  }, [topicsArray]);

  return (
    <div className="flex flex-row space-x-4 border-b border-surfaces-divider pb-4">
      <div className="flex-grow">
        <p className="text-black-soft">{name}</p>
        {editing ? (
          <div className="flex flex-row items-center space-x-2">
            <textarea
              key={`post${id}`}
              value={editingPost}
              className="grow  rounded-lg border border-surfaces-divider p-1"
              rows={5}
              onChange={(e) => {
                setEditingPost(e.target.value);
              }}
            />
            <div className="flex flex-col items-center space-y-2">
              <RoundedButton
                color="success"
                onClick={() => {
                  updateProduction('post', id, editingPost);
                  setEditing(false);
                }}
              >
                <Check className="h-4 w-4 stroke-2" />
              </RoundedButton>
              <RoundedButton
                color="danger"
                onClick={() => {
                  setEditingPost(post);
                  setEditing(false);
                }}
              >
                <X className="h-4 w-4 stroke-2" />
              </RoundedButton>
            </div>
          </div>
        ) : (
          <p>
            {post}
            <span className="inline-block">
              {
                <span
                  className="ml-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full hover:bg-primary-soft"
                  onClick={() => setEditing(true)}
                >
                  <Pencil className=" h-4 w-4 stroke-2" />
                </span>
              }
            </span>
          </p>
        )}

        <div className="mt-1 flex flex-row items-center space-x-2">
          {Object.entries(criteriaSettings || {}).map(
            ([criteriumDescription, criteriumSettings]) => {
              const criteriaValue = criteria?.[criteriumDescription] || null;
              const criteriaActions = Object.values(
                criteriumSettings.options || {}
              ).reduce<Action[]>((prev, criteriumOption) => {
                if (criteriumOption !== criteriaValue) {
                  prev.push({
                    label: criteriumOption,
                    type: 'button',
                    onClick: () => {
                      updateProduction(
                        criteriumSettings.name,
                        id,
                        criteriumOption
                      );
                    },
                  });
                }
                return prev;
              }, []);
              return (
                <MenuButtons
                  actions={criteriaActions}
                  menuAnchor="left"
                  menuButton={
                    <Button
                      design={criteriaValue ? 'primary' : 'secondary'}
                      text={criteriaValue || `${criteriumDescription} ?`}
                      size="sm"
                      color={'gray'}
                      iconPlacement="right"
                      icon={
                        <ChevronRight className="rotate rotate-90 stroke-2" />
                      }
                    />
                  }
                />
              );
            }
          )}
        </div>
      </div>
      <div className="w-96 shrink-0">
        {topic ? (
          <Button
            size="sm"
            text={topic.description}
            color={topic.color}
            className=""
            onClick={() => {
              updatePostMetaTopic(id, null);
            }}
            icon={<ArrowsRightLeft className="stroke-2" />}
            iconPlacement="right"
          />
        ) : (
          <div className="flex flex-col space-y-2">
            {topicsArray.length ? (
              <div className="-ml-2 -mt-2 flex flex-wrap items-center">
                {topicsArray.map((topic) => {
                  return (
                    <Button
                      key={topic.id}
                      badge={`${topicsUserCount[topic.id] || 0}`}
                      size="sm"
                      design="secondary"
                      text={topic.description}
                      color={topic.color}
                      className="ml-2 mt-2"
                      faded
                      onClick={() => {
                        updatePostMetaTopic(id, topic.id);
                      }}
                    />
                  );
                })}
              </div>
            ) : null}
            <TopicInput
              nextColor={nextColor}
              insertTopic={async (topic: Topic) => {
                const topicId = await insertTopic(topic);
                if (topicId) {
                  await updatePostMetaTopic(id, topicId);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export type PostFacilitationDashboardProps = {
  sessionId: string;
  activity: Activity;
};

const PostFacilitationDashboard = ({
  sessionId,
  activity,
}: PostFacilitationDashboardProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModalTopics, setShowModalTopics] = useState(false);

  const [posts, , users, postsLoaded] = useActivityProductionData(
    sessionId,
    activity,
    activity.productions!['post']!
  );

  const criteria = useSessionActivityCriteria(sessionId, activity.name);
  const updateProduction = useCallback(
    (productionName: string, userId: string, value: string) =>
      updateProductionForUser(
        sessionId,
        activity.name,
        productionName,
        userId,
        value
      ),
    [sessionId, activity.name]
  );

  const criteriaSettings = useMemo(() => {
    return Object.values(activity?.productions || {}).reduce<
      Record<string, { options: string[]; name: string }>
    >((prev, production) => {
      if (production.name.startsWith('crit') && production.type === 'enum') {
        prev[production.description || production.name] = {
          options: production.options,
          name: production.name,
        };
      }
      return prev;
    }, {});
  }, [activity?.productions]);

  const [postsMeta, postsMetaLoaded] = usePostsMeta(sessionId, activity.name);

  const [topics, topicsLoaded] = useTopics(sessionId);

  const topicsUserCount = useMemo(() => {
    return _.mapValues(
      _.groupBy(postsMeta, (postMeta) => postMeta.topic),
      (list) => list.length
    );
  }, [postsMeta]);

  const postWithTopics = useMemo(
    () =>
      Object.entries(posts).map(([userId, post]) => {
        const meta = postsMeta[userId];
        const topic = (meta?.topic && topics[meta.topic]) || undefined;
        const user = users[userId];
        const userCriteria = Object.entries(criteria || {}).reduce<
          Record<string, string>
        >((prev, [criteriaName, usersCriteriaAnswers]) => {
          prev[criteriaName] = usersCriteriaAnswers[userId] || '';
          return prev;
        }, {});
        return {
          id: userId,
          post,
          criteria: userCriteria,
          criteriaSettings: criteriaSettings,
          updateProduction: updateProduction,
          topic,
          name: user?.name || t('users:UnknownUser'),
        };
      }),
    [
      posts,
      postsMeta,
      topics,
      users,
      t,
      criteria,
      updateProduction,
      criteriaSettings,
    ]
  );

  const insertSessionTopic = useCallback(
    (topic: Topic) => addTopic(sessionId, topic),
    [sessionId]
  );

  const updateSessionTopic = useCallback(
    (topicId: string, topic: Topic) => updateTopic(sessionId, topicId, topic),
    [sessionId]
  );

  const removeSessionTopic = useCallback(
    (topicId: string) => removeTopic(sessionId, topicId),
    [sessionId]
  );
  const updatePostMetaTopic = useCallback(
    (productionId: string, topicId: string | null) =>
      setPostMetaTopic(sessionId, activity.name, productionId, topicId),
    [sessionId, activity.name]
  );

  return postsLoaded && postsMetaLoaded && topicsLoaded ? (
    <div>
      <Modal
        largeWidth
        title={t('sessions:manageTopics')}
        open={showModalTopics}
        onClose={() => setShowModalTopics(false)}
        body={
          <List
            items={Object.entries(topics)}
            renderItem={([topicId, topic]) => (
              <TopicRow
                topicId={topicId}
                topic={topic}
                updateTopic={updateSessionTopic}
                removeTopic={removeSessionTopic}
                count={topicsUserCount[topicId] || 0}
              />
            )}
          />
        }
      />
      <div className="mb-4 flex flex-row items-center space-x-2">
        <h1 className="text-2xl font-semibold">
          {t('sessions:XPostsandYTopics', {
            postsCount: _.size(posts),
            topicsCount: _.size(topics),
          })}
        </h1>
        <Button
          disabled={!_.size(topics)}
          text={t('common:Edit')}
          size="sm"
          icon={<CogLight className="stroke-2" />}
          iconPlacement="right"
          onClick={() => setShowModalTopics(true)}
        />
      </div>
      <List
        items={postWithTopics}
        renderItem={(item) => (
          <PostItem
            {...item}
            topics={topics}
            topicsUserCount={topicsUserCount}
            updatePostMetaTopic={updatePostMetaTopic}
            insertTopic={insertSessionTopic}
          />
        )}
      />
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default PostFacilitationDashboard;
