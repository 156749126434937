import React from 'react';

import { Colors, ColorNames } from 'types/ui';

type sizeType = 'base' | 'lg';
type ColorType = {
  text: string;
  bg: string;
  badge: string;
};

const colors: Colors<ColorType> = {
  primary: {
    text: 'text-primary',
    bg: 'bg-primary-soft',
    badge: 'bg-primary text-white',
  },
  success: {
    text: 'text-success',
    bg: 'bg-success-soft',
    badge: 'bg-success text-white',
  },
  warning: {
    text: 'text-warning',
    bg: 'bg-warning-soft',
    badge: 'bg-warning text-white',
  },
  danger: {
    text: 'text-danger',
    bg: 'bg-danger-soft',
    badge: 'bg-danger text-white',
  },
  gray: {
    text: 'text-black-soft',
    bg: 'bg-gray-soft',
    badge: 'bg-black-soft text-white',
  },
  yellow: {
    text: 'text-yellow-600',
    bg: 'bg-yellow-200',
    badge: 'bg-yellow-700 text-white',
  },
  fuchsia: {
    text: 'text-fuchsia-600',
    bg: 'bg-fuchsia-200',
    badge: 'bg-fuchsia-700 text-white',
  },
  green: {
    text: 'text-green-600',
    bg: 'bg-green-200',
    badge: 'bg-green-700 text-white',
  },
  indigo: {
    text: 'text-indigo-600',
    bg: 'bg-indigo-200',
    badge: 'bg-indigo-700 text-white',
  },
  lime: {
    text: 'text-lime-600',
    bg: 'bg-lime-200',
    badge: 'bg-lime-700 text-white',
  },
  sky: {
    text: 'text-sky-600',
    bg: 'bg-sky-200',
    badge: 'bg-sky-700 text-white',
  },
  teal: {
    text: 'text-teal-600',
    bg: 'bg-teal-200',
    badge: 'bg-teal-700 text-white',
  },
  violet: {
    text: 'text-violet-600',
    bg: 'bg-violet-200',
    badge: 'bg-violet-700 text-white',
  },
};

export type TagProps = {
  text: string;
  color: ColorNames;
  className?: string;
  badge?: string;
  size?: sizeType;
};

const Tag = ({
  text,
  color = 'primary',
  className = '',
  badge,
  size = 'base',
}: TagProps): JSX.Element => {
  const {
    text: textColor,
    bg: bgColor,
    badge: badgeClassNames,
  } = colors[color];

  const divSizeClassName =
    size === 'lg' ? 'py-2 px-3 text-base' : 'py-1 px-2 text-xs';
  const iconSizeClassName = size === 'lg' ? 'h-6 w-6' : 'h-4 w-4';
  return (
    <div
      className={`flex shrink-0 items-center rounded-full ${divSizeClassName}  font-semibold ${textColor} ${bgColor} ${className}`}
    >
      {text}
      {badge ? (
        <span
          className={`ml-2 flex ${iconSizeClassName} items-center justify-center rounded-full ${badgeClassNames}`}
        >
          {badge}
        </span>
      ) : null}
    </div>
  );
};

export default Tag;
