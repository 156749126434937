import React from 'react';
import { RoutedTabs, NavTab } from 'react-router-tabs';
import { match as Match } from 'react-router-dom';

const Tabs = ({
  strings,
  match,
  tabs,
  style,
}: {
  strings: {
    screens: Record<string, { title: string }>;
  };
  match: Match;
  tabs: string[];
  style?: React.CSSProperties;
}) => {
  const { screens } = strings;
  const { url } = match;
  return (
    <div style={{ ...styles.viewMain, ...style }}>
      <RoutedTabs startPathWith={url} style={{ overflow: 'hidden' }}>
        {tabs.map((tab) => {
          const screen = screens[tab];
          const title = screen && screen.title;
          return (
            <NavTab key={title} to={`/${tab}`} style={{ textAlign: 'center' }}>
              {title}
            </NavTab>
          );
        })}
      </RoutedTabs>
    </div>
  );
};

const styles = {
  viewMain: {
    display: 'flex',
    // flex: 1,
    alignItems: 'flex-end',
  },
};

export default Tabs;
