import * as React from 'react';

function SvgDocuments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 844.46 651.814"
      {...props}
    >
      <path
        d="M612.046 424.273L502.71 367.902a7.007 7.007 0 01-3.014-9.43l6.764-13.118a7.008 7.008 0 019.43-3.014l109.336 56.372a7.007 7.007 0 013.014 9.429l-6.763 13.118a6.952 6.952 0 01-4.092 3.457 7.034 7.034 0 01-2.143.337 6.956 6.956 0 01-3.195-.78z"
        fill="current"
      />
      <path
        d="M578.712 371.54a6.966 6.966 0 015.394.14l35.831 19.813a17.47 17.47 0 017.253 22.951l-.448-.218.449.219a17.484 17.484 0 01-21.477 8.842l-38.859-13.582a7 7 0 01-3.573-9.237l11.554-25.231a7.037 7.037 0 01.743-1.294 6.958 6.958 0 013.133-2.403z"
        fill="#2f2e41"
      />
      <circle cx={661.155} cy={311.968} r={53.519} fill="current" />
      <path
        d="M654.588 644.814V521.8a7.008 7.008 0 017-7h14.759a7.008 7.008 0 017 7v123.014a7.008 7.008 0 01-7 7h-14.76a7.008 7.008 0 01-7-7zM622.588 644.814V521.8a7.008 7.008 0 017-7h14.759a7.008 7.008 0 017 7v123.014a7.008 7.008 0 01-7 7h-14.76a7.008 7.008 0 01-7-7z"
        fill="current"
      />
      <path
        d="M662.28 331.804l.055-2c-3.72-.104-7.001-.337-9.466-2.138a6.148 6.148 0 01-2.381-4.528 3.514 3.514 0 011.153-2.895c1.636-1.382 4.269-.934 6.188-.054l1.654.758-3.173-23.19-1.981.271 2.699 19.727c-2.608-.767-5.024-.437-6.678.961a5.471 5.471 0 00-1.86 4.492 8.133 8.133 0 003.2 6.073c3.166 2.315 7.282 2.43 10.59 2.523zM633.758 299.73h10.771v2h-10.771zM667.758 299.73h10.771v2h-10.771z"
        fill="#2f2e41"
      />
      <path
        d="M712.37 542.155l-18.511-121.613a7.007 7.007 0 015.867-7.973l14.59-2.221a7.008 7.008 0 017.974 5.867l18.51 121.613a7.008 7.008 0 01-5.866 7.973l-14.591 2.221a6.994 6.994 0 01-7.974-5.867z"
        fill="current"
      />
      <path
        d="M698.064 464.098a6.966 6.966 0 01-3.056-4.448l-5.019-40.635a17.47 17.47 0 0114.311-19.354l.087.492-.087-.492a17.484 17.484 0 0119.773 12.184l11.84 39.425a7 7 0 01-5.375 8.318l-27.205 5.474a7.037 7.037 0 01-1.484.16 6.958 6.958 0 01-3.785-1.124z"
        fill="#2f2e41"
      />
      <path
        d="M699.5 552H556.477a8.5 8.5 0 01-7.594-12.319l69.15-137.512A47.516 47.516 0 01708 423.516V543.5a8.51 8.51 0 01-8.5 8.5zM663.051 267.813c-10.956 2.447-20.197 9.587-31.212 11.808-5.02 1.011-10.548.97-15.159-1.501a15.8 15.8 0 01-7.334-8.56 23.62 23.62 0 013.767-22.34c6.184-7.57 16.043-11.058 25.502-12.041 18.93-1.968 38.375 4.27 53.783 15.137 15.909 11.22 28.113 28.067 32.207 47.241 2.189 10.25 1.973 21.153-1.764 31.023a39.528 39.528 0 01-19.768 21.544 38.796 38.796 0 01-6.766 2.52 1.501 1.501 0 00.797 2.893 42.73 42.73 0 0024.768-18.24c5.86-9.374 7.827-20.851 6.924-31.759-1.746-21.099-13.349-40.492-29.569-53.768-15.582-12.754-35.74-20.514-56.007-19.898-10.164.31-20.715 2.89-28.62 9.588a26.919 26.919 0 00-9.34 23.787 21.57 21.57 0 004.735 11.294 19.518 19.518 0 0012.528 6.47c11.984 1.56 22.376-5.233 32.966-9.6a51.263 51.263 0 018.36-2.705c1.883-.42 1.086-3.313-.798-2.893z"
        fill="#2f2e41"
      />
      <path
        d="M693.152 246.612c-4.302-16.108 6.927-32.872 22.184-37.902a43.585 43.585 0 0126.84.3 52.942 52.942 0 0122.119 14.44c13.374 14.228 19.299 33.432 22.07 52.366 2.863 19.549 1.248 40.96 11.251 58.725 4.147 7.365 10.303 13.814 18.257 16.986 7.42 2.958 16.31 2.972 23.419-.873a22.458 22.458 0 004.705-3.437c1.408-1.328-.717-3.447-2.12-2.122-5.954 5.617-14.826 6.677-22.477 4.473-8.346-2.405-14.769-8.818-19.002-16.204-4.895-8.54-6.933-18.303-8.045-27.989-1.213-10.573-1.68-21.204-3.301-31.735-2.923-18.993-9.238-38.058-22.636-52.311a56.363 56.363 0 00-22.275-14.821 47.224 47.224 0 00-27.278-1.377 37.203 37.203 0 00-20.506 13.79 34.68 34.68 0 00-7.073 22.535 30.616 30.616 0 00.976 5.953 1.501 1.501 0 002.892-.798z"
        fill="#2f2e41"
      />
      <path
        d="M104.26 383H17a17.02 17.02 0 01-17-17V17A17.02 17.02 0 0117 0h236.68L297 38.678V109h-2V39.574L252.918 2H17A15.017 15.017 0 002 17v349a15.017 15.017 0 0015 15h87.26z"
        fill="#ccc"
      />
      <path
        d="M296 40h-27a17.02 17.02 0 01-17-17V1a1 1 0 112 0v22a15.017 15.017 0 0015 15h27a1 1 0 010 2z"
        fill="#ccc"
      />
      <path
        d="M262.54 90.5h-228a8 8 0 000 16h228a8 8 0 000-16zM147.54 56.5h-113a8 8 0 000 16h113a8 8 0 000-16zM34.54 157.5a8 8 0 000 16H106v-16zM34.54 123.5a8 8 0 100 16H106v-16zM106.5 327H42.46a16.519 16.519 0 01-16.5-16.5v-84a16.519 16.519 0 0116.5-16.5h64.04z"
        fill="#e6e6e6"
      />
      <path
        d="M209.76 490.5H122.5a17.02 17.02 0 01-17-17v-349a17.02 17.02 0 0117-17h236.68l43.32 38.678V212.5h-2v-65.426L358.418 109.5H122.5a15.017 15.017 0 00-15 15v349a15.017 15.017 0 0015 15h87.26z"
        fill="#ccc"
      />
      <path
        d="M401.5 147.5h-27a17.02 17.02 0 01-17-17v-22a1 1 0 112 0v22a15.017 15.017 0 0015 15h27a1 1 0 010 2z"
        fill="#ccc"
      />
      <path
        d="M368.04 198h-228a8 8 0 000 16h228a8 8 0 000-16zM253.04 164h-113a8 8 0 000 16h113a8 8 0 000-16z"
        fill="#e6e6e6"
      />
      <path
        d="M368.04 198h-228a8 8 0 000 16h228a8 8 0 000-16zM253.04 164h-113a8 8 0 000 16h113a8 8 0 000-16zM140.04 265a8 8 0 000 16h71.46v-16zM140.04 231a8 8 0 000 16h71.46v-16z"
        fill="#e6e6e6"
      />
      <path
        d="M140.04 265a8 8 0 000 16h71.46v-16zM140.04 231a8 8 0 000 16h71.46v-16zM212 434.5h-64.04a16.519 16.519 0 01-16.5-16.5v-84a16.519 16.519 0 0116.5-16.5H212z"
        fill="#e6e6e6"
      />
      <path
        d="M217.64 216a16.994 16.994 0 00-8.14 14.5v2.5h2v-2.5a15.027 15.027 0 0111.15-14.5zm245.54-2.5H226.5a17.02 17.02 0 00-17 17v349a17.024 17.024 0 0017 17h263a17.024 17.024 0 0017-17V252.18zm41.32 366a15.018 15.018 0 01-15 15h-263a15.018 15.018 0 01-15-15v-349a15.027 15.027 0 0111.15-14.5 14.833 14.833 0 013.85-.5h235.92l42.08 37.57zM217.64 216a16.994 16.994 0 00-8.14 14.5v2.5h2v-2.5a15.027 15.027 0 0111.15-14.5z"
        fill="#3f3d56"
      />
      <path
        d="M505.5 253.5h-27a17.02 17.02 0 01-17-17v-22a1 1 0 012 0v22a15.017 15.017 0 0015 15h27a1 1 0 010 2z"
        fill="#3f3d56"
      />
      <path
        d="M472.041 320h-228a8 8 0 110-16h228a8 8 0 010 16zM357.041 286h-113a8 8 0 110-16h113a8 8 0 010 16zM472.041 387h-228a8 8 0 110-16h228a8 8 0 010 16zM357.041 353h-113a8 8 0 110-16h113a8 8 0 010 16z"
        fill="#ccc"
      />
      <path
        d="M463.959 540.5h-212a16.519 16.519 0 01-16.5-16.5v-84a16.519 16.519 0 0116.5-16.5h212a16.519 16.519 0 0116.5 16.5v84a16.519 16.519 0 01-16.5 16.5z"
        fill="current"
      />
    </svg>
  );
}

export default SvgDocuments;
