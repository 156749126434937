import React from 'react';

import Colors from 'constants/Colors';
import Fonts from 'constants/Fonts';
import { PADDING_HALF, BORDER_RADIUS_HALF } from 'constants/Dimensions';

import GroupCard from './GroupCard';
import PostInfos from 'componentsOld/PostInfos';
// import { TYPE_MATCH_FROM_TOPIC } from 'constants/Sequences';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-stretch',
  },
  connectPart: {
    borderTop: 'solid rgba(0, 0, 0, 0.1) 1px',
  },
  text: {
    fontSize: Fonts.SIZE.MEDIUM,
    height: 'fit-content',
    textAlign: 'left',
    paddingBottom: 16,
    paddingTop: 1,
    paddingLeft: 16,
    paddingRight: 16,
  },
  viewGroupCard: {
    flex: 1,
    height: 'fit-content',
    width: 'inherit',
    boxSizing: 'border-box',
    margin: PADDING_HALF,
  },
  viewGroupCardPosts: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: Colors.colorAccent,
    boxSizing: 'border-box',
    width: 'fit-content',
    margin: 5,
    padding: '5px 12px 5px 12px',
    fontWeight: 'bold',
    borderRadius: BORDER_RADIUS_HALF,
    color: Colors.colorTextDarkBackground,
    border: 'none',
    cursor: 'pointer',
  },
  post: {
    padding: 16,
    text: {
      color: '#000000',
      fontFamily: 'Helvetica',
      fontSize: Fonts.SIZE.SMALL,
      height: 'fit-content',
      textAlign: 'left',
    },
  },
};

const randomHeaderColor = (number) => {
  const colors = [
    {
      color: Colors.colorRedHeaderText,
      backgroundColor: Colors.colorRedHeaderBackground,
    },
    {
      color: Colors.colorBlueHeaderText,
      backgroundColor: Colors.colorBlueHeaderBackground,
    },
    {
      color: Colors.colorGreenHeaderText,
      backgroundColor: Colors.colorGreenHeaderBackground,
    },
    {
      color: Colors.colorPurpleHeaderText,
      backgroundColor: Colors.colorPurpleHeaderBackground,
    },
    {
      color: Colors.colorGreyHeaderText,
      backgroundColor: Colors.colorGreyHeaderBackground,
    },
    {
      color: Colors.colorYellowHeaderText,
      backgroundColor: Colors.colorYellowHeaderBackground,
    },
  ];
  return colors[number % colors.length];
};

const GroupPresentation = ({
  number,
  name,
  posts: realPosts,
  users,
  visioCode,
  leaderId,
  style,
  groupPrefix,
}) => {
  const posts =
    (users &&
      users.map(({ id, name }) => ({
        id,
        creator: name,
      }))) ||
    realPosts;

  return (
    <GroupCard
      name={name}
      number={number}
      groupPrefix={groupPrefix}
      visioCode={visioCode}
      style={{
        ...styles.viewGroupCard,
        ...style,
      }}
      color={randomHeaderColor(number)}
    >
      <div style={styles.viewGroupCardPosts}>
        {/* {createGroupType === TYPE_MATCH_FROM_TOPIC ? (
          posts.map(elem => elem && <PostInfos key={elem.id} {...elem} />)
        ) : ( */}
        <div style={styles.container}>
          {leaderId && (
            <PostInfos
              key={leaderId}
              {...posts.find((post) => post.id === leaderId)}
            />
          )}
          {leaderId && (
            <div style={styles.connectPart}>
              <p style={styles.text}>Joined</p>
            </div>
          )}
          {posts.map(
            (elem) =>
              elem &&
              elem.id !== leaderId && <PostInfos key={elem.id} {...elem} />
          )}
        </div>
        {/* )} */}
      </div>
    </GroupCard>
  );
};

GroupPresentation.defaultProps = {
  name: ' ',
  posts: [
    {
      text: 'Mettre l’emotion individuelle au service du groupe et inversement',
      tags: ['#a', '#b', '#c'],
    },
    {
      text: 'Partir de ses émotions pour apprendre',
      tags: ['#a', '#c'],
    },
  ],
};

export default GroupPresentation;
