import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import Blockquote from 'components/Blockquote';
import { Users } from 'types/types';
import Copyable from 'components/Copyable';

const extractBestFeedbacks = (
  feedbacks: Record<string, any>
): Record<string, string> => {
  const bestFeedbacks: Record<string, string> = _.pickBy(
    feedbacks?.questionAAnswer || {},
    (feedback, userId) =>
      feedbacks?.valuable?.[userId] >= 4 && feedback?.length > 75
  );

  return bestFeedbacks;
};

export type BestFeedbacksProps = {
  feedbacks: Nullable<Record<string, any>>;
  participants: Users;
  marginClasses: string;
};

const BestFeedbacks = ({
  feedbacks,
  participants,
  marginClasses,
}: BestFeedbacksProps) => {
  const { t } = useTranslation();

  const bestFeedbacks = extractBestFeedbacks(feedbacks || {});

  return _.size(bestFeedbacks) > 0 ? (
    <div className={`${marginClasses} space-y-2`}>
      <h2 className="text-xl font-semibold">{t('sessions:BestFeedbacks')}</h2>
      <div className="space-y-4">
        {Object.entries(bestFeedbacks)
          .slice(0, 3)
          .map(([userId, feedback]) => (
            <Copyable
              key={userId}
              textToCopy={`${
                (participants[userId]?.name || t('users:UnknownUser')).split(
                  ' '
                )[0]
              } - ${feedback}`}
            >
              <Blockquote
                quote={feedback}
                username={
                  (participants[userId]?.name || t('users:UnknownUser')).split(
                    ' '
                  )[0]
                }
              />
            </Copyable>
          ))}
      </div>
    </div>
  ) : null;
};

export default BestFeedbacks;
