import CommunitiesList from 'blocks/CommunitiesList';
import SessionsList from 'blocks/SessionsList';
import Link from 'components/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Communities, Session } from 'types/types';
import { WithId } from 'types/typesUtils';
import { focus } from 'services/helpChat';

import _ from 'lodash';

export type WelcomeScreenProps = {
  onGoingSessions: WithId<Session>[];
  nextSessions: WithId<Session>[];
  pastSessions: WithId<Session>[];
  nbSessions: number;
  userId: string;
  communities: Communities;
  showTabs: boolean;
};

const WelcomeScreen = ({
  onGoingSessions,
  nextSessions,
  pastSessions,
  nbSessions,
  userId,
  communities,
  showTabs,
}: WelcomeScreenProps) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex max-w-6xl flex-row flex-wrap px-8 lg:flex-nowrap lg:space-x-8">
      <div className="flex-grow space-y-8">
        {onGoingSessions.length > 0 ? (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">
              {t('sessions:onGoingSession', {
                count: onGoingSessions.length,
              })}
            </h2>
            <SessionsList
              userId={userId}
              sessions={onGoingSessions}
              communities={communities}
            />
          </div>
        ) : null}
        {nextSessions.length > 0 ? (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">
              {t('sessions:NextSession', {
                count: nextSessions.length,
              })}
            </h2>
            <SessionsList
              userId={userId}
              sessions={nextSessions}
              communities={communities}
            />
          </div>
        ) : null}

        {pastSessions.length > 0 ? (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">
              {t('sessions:LastSession', {
                count: pastSessions.length,
              })}
            </h2>
            <SessionsList
              userId={userId}
              sessions={pastSessions}
              communities={communities}
            />
          </div>
        ) : null}

        {nbSessions === 0 ? (
          <div className="mx-auto max-w-3xl">
            <h2 className="mb-10 text-center text-2xl font-semibold text-gray-500">
              {t('sessions:NoSessionFound')}
            </h2>
            <p className="mb-6 text-center text-xl font-semibold text-gray-300">
              {t('sessions:NoSessionFoundParticipantAdvice')}
            </p>
            <p className="mb-6 text-center text-xl font-semibold text-gray-300">
              {t('sessions:NoSessionFoundOrganizeAdvice')}
            </p>
            <div className="flex flex-row items-start justify-center space-x-1">
              <p className="text-center text-xl font-semibold text-gray-300">
                {t('sessions:NoSessionFoundContactSupport')}
              </p>

              <Link
                text={t('common:contactSupport')}
                onClick={(event) => {
                  event.preventDefault();
                  focus();
                }}
              />
            </div>
          </div>
        ) : null}
      </div>

      {!showTabs && _.size(communities) > 0 ? (
        <div className="mt-8 w-full lg:mt-0 lg:w-80">
          <h2 className="mb-4 text-2xl font-semibold">
            {t('communities:MyCommunities')}
          </h2>
          <CommunitiesList communities={communities} />
        </div>
      ) : null}
    </div>
  );
};

export default WelcomeScreen;
