import React from 'react';

import {
  isUrl,
  ToolbarButton,
  ToolbarButtonProps,
  usePlateEditorRef,
} from '@udecode/plate';
import { insertLink } from './withLink';

export const ToolbarLink = ({ ...props }: ToolbarButtonProps) => {
  const editor = usePlateEditorRef()!;
  return (
    <ToolbarButton
      onMouseDown={(event) => {
        event.preventDefault();

        const url = window.prompt('Enter the URL of the link:');
        if (!url || !isUrl(url)) return;
        insertLink(editor, url);
      }}
      {...props}
    />
  );
};
