export const getFormattedDate = (m) => m.format('DD MMMM YYYY');

export const getFormattedTime = (m) => m.format('HH:mm');

export const getFormattedPeriod = ([m1, m2]) =>
  `Le ${m1.format('DD MMMM YYYY')} de ${m1.format('HH:mm')} à ${m2.format(
    'HH:mm'
  )}`;

export const getFormattedDateTime = (m) =>
  `le ${m.format('DD MMMM YYYY')} à ${m.format('HH:mm')}`;

export const getFormattedDateTimeEn = (m) =>
  `on ${m.format('YYYY-MM-DD')} at ${m.format('HH:mm')}`;

export const msToMinutesSecondes = (ms) => {
  const secs = Math.round(ms / 1000);
  return [Math.floor(secs / 60), secs % 60];
};

export const msToFormattedMinutes = (ms) => {
  const [min, sec] = msToMinutesSecondes(ms);
  return `${min} : ${`${sec}`.padStart(2, '0')}`;
};

export const getFormattedDay = (m) => m.format('D/M/y');
