import React, { useState, useMemo, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelectorArray } from 'services/firebase';
import _ from 'lodash';
import * as Yup from 'yup';

import { TaskData, UserType } from 'types/types';
import { sequencialForEach, arrayToTrueSet } from 'utils/utils';

import { Task as TaskType, replaceSessionId } from '../../tasks';
import Task from '../blocks/Task';

import Spinner from 'components/Spinner';

export type TasksListFormProps = {
  sessionId: string;
  userId: string;
  userType: UserType;
  accessCode: string;
  tasks: TaskType[];
  tasksData: Record<string, TaskData>;
};

const TasksListForm = ({
  tasks,
  sessionId,
  userId,
  userType,
  accessCode,
  tasksData,
}: TasksListFormProps): JSX.Element => {
  const [isInitialised, setInitialised] = useState(false);

  const loadArray = useMemo(
    () =>
      tasks.reduce<string[]>((prev, task) => {
        if (task.dataBinding) {
          if (_.isObject(task.dataBinding)) {
            Object.values(task.dataBinding).forEach((bond) => {
              prev.push(replaceSessionId(bond, sessionId));
            });
          } else {
            prev.push(replaceSessionId(task.dataBinding, sessionId));
          }
        }
        if (task.dependencies) {
          task.dependencies.forEach((dep) => {
            prev.push(replaceSessionId(dep.path, sessionId));
          });
        }
        return prev;
      }, []),
    [tasks, sessionId]
  );

  useFirebaseConnect(loadArray);

  const [isDataLoaded, data]: [boolean, any] = useSelectorArray(
    loadArray || [],
    (data: any) => data,
    (value) => value !== undefined
  );

  useEffect(() => {
    if (isDataLoaded) {
      setInitialised(true);
    }
  }, [isDataLoaded]);

  return isInitialised ? (
    <Formik
      initialTouched={arrayToTrueSet(tasks.map((task) => task.name))}
      validateOnBlur={false}
      initialValues={tasks.reduce<{
        [key: string]: any;
      }>((prev, task) => {
        if (task.dataBinding) {
          const pickedData = _.isObject(task.dataBinding)
            ? _.mapValues(
                task.dataBinding,
                (bond) =>
                  _.get(
                    data,
                    replaceSessionId(bond, sessionId).split('/').join('.')
                  ) || null
              )
            : _.get(
                data,
                replaceSessionId(task.dataBinding, sessionId)
                  .split('/')
                  .join('.')
              ) || null;

          prev[task.name] = task.dataTransformer
            ? task.dataTransformer.get(pickedData)
            : pickedData;
        }

        if (task.dependencies) {
          task.dependencies.forEach((dep) => {
            if (!prev[dep.name]) {
              prev[dep.name] =
                _.get(
                  data,
                  replaceSessionId(dep.path, sessionId).split('/').join('.')
                ) || null;
            }
          });
        }
        return prev;
      }, {})}
      validate={async (values) => {
        const errors: Record<string, string[]> = {};
        await sequencialForEach(tasks, async (task) => {
          if (task.schema) {
            try {
              await task.schema.validate(values?.[task.name]);
            } catch (err) {
              if (err instanceof Yup.ValidationError) {
                errors[task.name] = err.errors;
              }
            }
          }
        });
        return errors;
      }}
      onSubmit={() => {
        /* do nothing */
      }}
    >
      {({ values }) => {
        const displayedTaskList = tasks.filter((task) =>
          task.shouldRender ? task.shouldRender(values) : true
        );

        return (
          <Form>
            <div className="mt-4 w-full space-y-3">
              {displayedTaskList.map((task) => {
                return (
                  <Task
                    key={task.name}
                    sessionId={sessionId}
                    userId={userId}
                    userType={userType}
                    task={task}
                    data={data}
                    taskData={tasksData?.[task.name]}
                    accessCode={accessCode}
                  />
                );
              })}
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Spinner className="mx-auto h-8 w-8 text-primary" />
  );
};

export default TasksListForm;
