import React from 'react';
import { Route, Redirect, useLocation, useParams } from 'react-router-dom';
import { auth } from 'services/firebase';
import { useTranslation } from 'react-i18next';
import { TrueSet } from 'types/types';
import { isGlobalAdmin } from 'model/users';
import LoadingScreen from 'screens/LoadingScreen';
import { LOGIN_PATH } from 'constants/AppConfig';
import { useAllUserCommunitiesId } from 'model/communitiesManagement';
import {
  useUserContext,
  useUserContextWithDefaults,
} from 'contexts/UserContext';

export type AuthorizeRouteProps = {
  children: React.ReactNode;
  communitiesIds: TrueSet;
  loginPath: string;
  path: string | string[];
  hasRight?: (context: {
    userId: string | undefined;
    params: Record<string, string>;
    communitiesIds: TrueSet;
  }) => boolean;
};

const AuthorizeRoute = ({
  children,
  communitiesIds,
  loginPath,
  path,
  hasRight,
}: AuthorizeRouteProps) => {
  const { t } = useTranslation();

  const params = useParams();
  const location = useLocation();

  const { userId, userType } = useUserContextWithDefaults();

  const allowAccess =
    auth.currentUser &&
    (isGlobalAdmin(userType) ||
      !hasRight ||
      (hasRight && hasRight({ userId, params, communitiesIds })));

  const message = auth.currentUser
    ? t('common:messageInsufficientRightPage')
    : t('common:messageLoginBeforePage');

  return !allowAccess ? (
    <Redirect
      to={{
        pathname: auth.currentUser ? '/' : `${loginPath}`,

        search: auth.currentUser
          ? ''
          : `?continueUrl=${encodeURIComponent(
              `${location.pathname || ''}${location.search || ''}`
            )}`,
        state:
          // we don't want a error message if the user goes to /
          path !== '/'
            ? {
                message,
                error: message,
              }
            : undefined,
      }}
    />
  ) : (
    <>{children}</>
  );
};

export type PrivateRouteProps = {
  children: React.ReactNode;
  requireRight?: string;
  loginPath?: string;
  path: string | string[];
  exact?: boolean;
  hasRight?: (context: {
    userId: string | undefined;
    params: Record<string, string>;
    communitiesIds: TrueSet;
  }) => boolean;
};
const PrivateRoute = ({
  children,
  requireRight,
  loginPath = LOGIN_PATH,
  path,
  exact,
  hasRight,
}: PrivateRouteProps): JSX.Element => {
  const [communitiesIds, communitiesIdsLoaded] = useAllUserCommunitiesId(
    auth.currentUser?.uid || ''
  );

  const { isUserLoaded } = useUserContext();

  return !auth.currentUser || (isUserLoaded && communitiesIdsLoaded) ? (
    <Route exact={exact} path={path}>
      <AuthorizeRoute
        communitiesIds={communitiesIds}
        hasRight={hasRight}
        loginPath={loginPath}
        path={path}
      >
        {children}
      </AuthorizeRoute>
    </Route>
  ) : (
    <LoadingScreen />
  );
};

export default PrivateRoute;
