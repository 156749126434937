import { database as db } from 'services/firebase';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';

export const useConfidentialityList = (userId: string): [string[], boolean] => {
  useFirebaseConnect(userId ? 'confidentialityList' : []);

  const list = useSelector(
    (state: any) => state.firebase.data.confidentialityList
  );

  return [list || [], isLoaded(list)];
};

export const saveConfidentialityList = async (
  confidentialityList: string[]
): Promise<void> => {
  await db.ref('confidentialityList').set(confidentialityList);
};
