import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  THEME_MATCH_STRING_LIST,
  PEER_PROFILE_MATCH_STRING_LIST,
  POST_RESULT_DESCRIPTION_MATCH_STRING_LIST,
} from 'constants/AppConfig';
import { isGlobalAdmin, useUser } from 'model/users';
import { MainGoalType, Session, DurationValues, UserType } from 'types/types';
import { URL_REGEXP, getClosest, usePrevious } from 'utils/utils';
import {
  generateActivities,
  updateSession,
  updateMainQuestionExamples,
} from 'model/sessions';

import RadioGroupInput from 'frameworks/formik/RadioGroupInput';
import DatetimePickerInput from 'frameworks/formik/DatetimePickerInput';
import { SessionTypeInput } from 'blocks/SessionType';
import TextArea from 'frameworks/formik/TextArea';
import InlineInput from 'frameworks/formik/InlineInput';
import AutoSave from 'frameworks/formik/AutoSave';
import Input from 'frameworks/formik/Input';

import Modal from 'components/Modal';
import Link from 'components/Link';
import Button from 'components/Button';
import FormattedText from 'components/FormattedText';
import LoadingScreen from 'screens/LoadingScreen';
import FillInSentence from 'blocks/FillInSentence';
import { focus, sendMessage } from 'services/helpChat';

type FormValues = Partial<
  Pick<
    Session,
    | 'mainGoal'
    | 'theme'
    | 'peerProfile'
    | 'postResultDescription'
    | 'type'
    | 'duration'
    | 'location'
    | 'meetingUrl'
  > & {
    sessionDate: Date;
    postExamples: string;
  }
>;

export type ConceptionViewProps = {
  enableExpertMode: () => void;
  userId: string;
  userType: UserType;
  sessionId: string;
  rebuildSessionPlanAllowed?: boolean;
};

const ConceptionView = ({
  enableExpertMode,
  userId,
  userType,
  sessionId,
  rebuildSessionPlanAllowed = true,
}: ConceptionViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [showEnableExpertModal, setShowEnableExpertModal] = useState(false);
  const [user] = useUser(userId);

  const loadArray = [
    `sessionsNext/${sessionId}/`,
    `sessionsNextTemplates/${sessionId}/activities/post/screens/create/content/template/editableContent`,
  ];

  useFirebaseConnect(loadArray);

  const session: Session | null = useSelector(
    (state: any) => sessionId && state.firebase.data.sessionsNext?.[sessionId]
  );

  const mainQuestion: string = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextTemplates?.[sessionId].activities?.post
          ?.screens?.create?.content?.template?.editableContent?.question) ||
      ''
  );

  const postExamples: string = useSelector(
    (state: any) =>
      (sessionId &&
        state.firebase.data.sessionsNextTemplates?.[sessionId].activities?.post
          ?.screens?.create?.content?.template?.editableContent?.description) ||
      ''
  );

  useEffect(() => {
    if (!isLoaded(session)) return;
    if (!session.duration) {
      const caculatedDuration = moment(session.scheduledAt[1]).diff(
        moment(session.scheduledAt[0]),
        'minutes'
      );
      const newDuration = getClosest<DurationValues>(
        caculatedDuration,
        [60, 90, 120],
        90
      );
      updateSession(sessionId, { duration: newDuration });
    }
  }, [session, sessionId]);

  const previousLanguage = usePrevious(session?.language || 'fr');
  useEffect(() => {
    if (
      previousLanguage &&
      (session?.language || 'fr') !== previousLanguage &&
      rebuildSessionPlanAllowed
    ) {
      generateActivities(
        sessionId,
        session?.mainGoal || 'action',
        session?.duration || 90,
        session?.language || 'fr'
      );
    }
  }, [
    sessionId,
    session?.mainGoal,
    session?.duration,
    session?.language,
    previousLanguage,
    rebuildSessionPlanAllowed,
  ]);

  const isUserExpert = !!user.expertMode || isGlobalAdmin(userType);
  return isLoaded(session) ? (
    <div className="w-full">
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          ..._.pick(session, [
            'mainGoal',
            'theme',
            'peerProfile',
            'postResultDescription',
            'type',
            'duration',
            'location',
            'meetingUrl',
          ]),
          ...{
            sessionDate: new Date(session.scheduledAt[0]),
            postExamples: postExamples,
          },
        }}
        validationSchema={Yup.object({
          meetingUrl: Yup.string().matches(
            URL_REGEXP,
            t('form:FieldMustBeValidURL')
          ),
        })}
        onSubmit={async (values, formikBag) => {
          try {
            if (
              rebuildSessionPlanAllowed &&
              values.mainGoal &&
              values.duration &&
              (session.mainGoal !== values.mainGoal ||
                session.duration !== values.duration)
            ) {
              await generateActivities(
                sessionId,
                values.mainGoal,
                values.duration,
                session.language || 'fr'
              );
            }

            const updatedSession: Partial<Session> = _.omit(
              values,
              'sessionDate'
            );
            if (values.sessionDate) {
              updatedSession.scheduledAt = [
                values.sessionDate.getTime(),
                values.sessionDate.getTime() +
                  (values?.duration || 120) * 60 * 1000,
              ];
            }

            await updateSession(sessionId, updatedSession);

            if (postExamples !== values.postExamples) {
              updateMainQuestionExamples(sessionId, values.postExamples || '');
            }

            if (
              session.mainGoal !== values.mainGoal &&
              values.mainGoal === 'other'
            ) {
              focus();
              sendMessage(t('sessions:mainGoalNotFoundMessage'));
            }
            formikBag.resetForm({ values });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <div className="flex w-full flex-row items-center justify-between">
              <div className="flex w-full flex-row items-center justify-between">
                <h1 className="text-4xl font-semibold">
                  {t('sessions:designLiteTitle')}
                </h1>
                <AutoSave />
              </div>
            </div>
            <div className="mt-8 w-full space-y-8">
              <RadioGroupInput<MainGoalType>
                disabled={!rebuildSessionPlanAllowed}
                disabledMessage={t('sessions:rebuildPlanImpossibleMessage')}
                name="mainGoal"
                design="wap"
                label={t('sessions:MainGoalLabel')}
                options={[
                  {
                    value: 'reveal_knowledge',
                    description: t(
                      'sessions:MainGoalRevealKnowledgeDescription'
                    ),
                  },
                  {
                    value: 'action',
                    description: t('sessions:MainGoalActionDescription'),
                  },
                  // {
                  //   value: 'share_internal_knowledge',
                  //   description:
                  //     "Diffuser l'expertise interne générée par vos collaborateurs",
                  // },
                  {
                    value: 'other',
                    description: t('sessions:MainGoalOtherDescription'),
                  },
                ]}
              />
              <div className={`flex w-full flex-col`}>
                <h3 className="mb-3 shrink-0 text-lg font-semibold">
                  {t('sessions:mainQuestionLabel')}
                </h3>

                <div className="relative mx-5 mt-6 flex border border-primary">
                  <div className="absolute -top-1 left-5 right-5 h-1 bg-white" />
                  <div className="absolute -bottom-1 left-5 right-5 h-1 bg-white" />
                  <div className="absolute top-5 bottom-5 -left-1 w-1 bg-white" />
                  <div className="absolute top-5 bottom-5 -right-1 w-1 bg-white" />
                  <div className="p-5 text-lg font-semibold">
                    <FillInSentence
                      root
                      text={mainQuestion}
                      alternatives={[
                        {
                          matches: THEME_MATCH_STRING_LIST,
                          item: <InlineInput name="theme" />,
                        },
                        {
                          matches: PEER_PROFILE_MATCH_STRING_LIST,
                          item: <InlineInput name="peerProfile" />,
                        },
                        {
                          matches: POST_RESULT_DESCRIPTION_MATCH_STRING_LIST,
                          item: <InlineInput name="postResultDescription" />,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="mx-5 mt-4">
                  <h3 className="text-lg">{t('sessions:answerExamples')}</h3>
                  <TextArea name="postExamples" />
                </div>
              </div>
              <SessionTypeInput
                name="type"
                label={t('sessions:sessionTypeLabel')}
              />
              {values.type === 'on_site' || values.type === 'hybrid' ? (
                <div className="mx-5 mt-4">
                  <h3 className="text-lg">{t('sessions:SessionLocation')}</h3>
                  <TextArea
                    name="location"
                    placeholder={t('sessions:ExLocation')}
                  />
                </div>
              ) : null}

              {values.type === 'digital' || values.type === 'hybrid' ? (
                <div className="mx-5 mt-4">
                  <h3 className="text-lg">{t('sessions:SessionMeetingUrl')}</h3>
                  <Input
                    name="meetingUrl"
                    placeholder={t('sessions:ExLocationUrl')}
                  />
                </div>
              ) : null}
              <RadioGroupInput<number>
                disabled={!rebuildSessionPlanAllowed}
                disabledMessage={t('sessions:rebuildPlanImpossibleMessage')}
                name="duration"
                design="wap"
                label={t('sessions:durationLabel')}
                options={[
                  { value: 60, description: '1h' },
                  { value: 90, description: '1h30' },
                  { value: 120, description: '2h' },
                ]}
              />

              <div
                className={`flex w-full flex-row items-center space-x-4 pb-60`}
              >
                <DatetimePickerInput
                  name="sessionDate"
                  label={t('sessions:sessionDateLabel')}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto flex w-max flex-row items-center space-x-2 pb-10 text-sm">
        {isUserExpert ? (
          <>
            <p>{t('sessions:NeedMoreControl')}</p>
            <Link
              text={t('sessions:EnableExpertMode')}
              color="text-danger"
              size="sm"
              onClick={() => setShowEnableExpertModal(true)}
            />
            <Modal
              open={showEnableExpertModal}
              title={t('sessions:EnableExpertModeTitle')}
              body={
                <FormattedText className="max-w-xs">
                  {t('sessions:enableExpertModeDescription')}
                </FormattedText>
              }
              footer={
                <div className="mb-4 flex items-center space-x-4">
                  <Button
                    text={t('common:Cancel')}
                    design="secondary"
                    onClick={() => setShowEnableExpertModal(false)}
                  />
                  <Button
                    color="danger"
                    text={t('sessions:EnableExpertMode')}
                    onClick={enableExpertMode}
                  />
                </div>
              }
            />
          </>
        ) : null}
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default ConceptionView;
