import React, { cloneElement, useState } from 'react';

import Logo from 'components/Logo';
import Banner from 'components/Banner';
import Surface from 'components/Surface';

import { Expand, Collapse } from 'assets/icons';
import { useAlert } from 'contexts/Alerts';
import { useIsKeyboardOpen, useScreenSize, SCREEN_SIZES } from 'utils/utils';
import VideoLayout, { VideoLayoutProps } from './VideoLayout/VideoLayout';

const Layout = ({
  actionBar,
  navBar,
  secondaryLeftPane,
  mainPane,
  showRightPane,
  rightPane,
  defaultFullScreen = false,
  forceFullScreen = false,
  headerContent,
  videoProps,
}: {
  actionBar?: JSX.Element;
  navBar: JSX.Element;
  maximizableLeftPane?: JSX.Element;
  secondaryLeftPane: (activitiesListProps?: {
    smallView: boolean;
  }) => JSX.Element;
  mainPane: JSX.Element;
  showRightPane: boolean;
  rightPane?: JSX.Element;
  defaultFullScreen?: boolean;
  forceFullScreen?: boolean;
  headerContent?: JSX.Element;
  showStepsInPriority: boolean;
  videoProps: VideoLayoutProps;
}): JSX.Element => {
  const [contentFullRequested, setContentFullRequested] = useState(
    forceFullScreen || defaultFullScreen
  );

  const contentFull = forceFullScreen || contentFullRequested;

  const [, alertBanner] = useAlert();

  const keyboardOpen = useIsKeyboardOpen();
  const screenSize = useScreenSize();
  const isScreenSmallerThanSmBreakpoint =
    SCREEN_SIZES.indexOf(screenSize) < SCREEN_SIZES.indexOf('sm');

  return (
    <div className="flex w-full">
      <div className="hidden h-screen w-full flex-col bg-surfaces-soft sm:flex  sm:w-auto">
        <div className="hidden h-16 w-full sm:flex">
          {
            <div className="my-auto pl-4">
              <Logo className="h-8" />
            </div>
          }
        </div>

        <div className="w-full sm:hidden">
          {cloneElement(navBar, { actionBar: actionBar })}
        </div>
        <div className="overflow-y-auto overflow-x-hidden">
          <div className="flex min-h-0 w-full flex-grow">
            <div className="mx-auto flex h-full w-full xs:w-80">
              <div className="w-full pt-8">
                {isScreenSmallerThanSmBreakpoint
                  ? null
                  : cloneElement(secondaryLeftPane())}
              </div>
            </div>
          </div>
        </div>
        <div className="h-24 w-full flex-shrink-0"></div>
      </div>
      <div className="relative flex h-screen w-full flex-grow flex-col sm:min-w-0">
        <div className="w- flex flex-col">
          {cloneElement(navBar, {
            actionBar: keyboardOpen ? undefined : actionBar,
          })}
          {!keyboardOpen ? (
            <div className="w-full border-b border-surfaces-divider bg-surfaces-soft py-2 sm:hidden">
              {isScreenSmallerThanSmBreakpoint
                ? cloneElement(secondaryLeftPane({ smallView: true }))
                : null}
            </div>
          ) : null}
          {alertBanner}
        </div>
        <div className="min-h-0 w-full flex-grow">
          <VideoLayout {...videoProps}>
            <div className={`relative`}>
              {!contentFull && <Banner className="hidden h-48 md:flex" />}
              <section
                className={`z-10 flex ${contentFull ? '' : 'md:-mt-20'}`}
              >
                <div className="flex w-full flex-grow flex-col">
                  <Surface
                    className={`relative mx-auto border-none px-2 py-6 md:p-6 lg:p-10 ${
                      contentFull
                        ? 'w-full'
                        : 'w-full lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl 3xl:max-w-6xl'
                    }`}
                  >
                    <div className="-mt-5 w-full p-2">{headerContent}</div>
                    <div
                      className="absolute top-5 right-5 hidden cursor-pointer flex-col items-end hover:text-primary md:flex"
                      onClick={() => setContentFullRequested((state) => !state)}
                    >
                      {forceFullScreen ? null : contentFull ? (
                        <Collapse className="h-6 w-6 stroke-3/2" />
                      ) : (
                        <Expand className="h-6 w-6 stroke-3/2" />
                      )}
                    </div>

                    {React.cloneElement(mainPane)}
                  </Surface>
                </div>
                {showRightPane ? (
                  <div className={`w-96 px-4`}>{rightPane}</div>
                ) : null}
              </section>
            </div>
          </VideoLayout>
        </div>
      </div>
    </div>
  );
};

export default Layout;
