import React, { useState, forwardRef } from 'react';
import { useField } from 'formik';
import { useOnChange } from './hooks';

import FormattedText from 'components/FormattedText';

import { Eye, EyeOff } from 'assets/icons';

export type InputProps = {
  name: string;
  label?: string;
  type?: 'text' | 'email' | 'password';
  action?: JSX.Element;
  placeholder?: string;
  readOnly?: boolean;
  renderEmpty?: () => JSX.Element;
  onChangeValue?: (value: string, hasError: boolean) => void;
  labelClassName?: string;
  disabled?: boolean;
  autoComplete?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      type = 'text',
      action,
      placeholder,
      readOnly = false,
      renderEmpty = () => <></>,
      onChangeValue,
      labelClassName = 'text-lg font-semibold',
      disabled = false,
      autoComplete,
    },
    ref
  ): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta] = useField(name);
    const hasError = !!(meta.touched && meta.error);

    useOnChange(name, onChangeValue);

    const isSecret = type === 'password';
    return (
      <div className={'w-full'}>
        {label ? (
          <label className={labelClassName} htmlFor={name}>
            <FormattedText as="Fragment">{label}</FormattedText>
          </label>
        ) : null}
        {readOnly ? (
          <div className="mt-2 inline-flex w-full items-center">
            {field.value && field.value.length > 0 ? (
              <p className="w-full flex-grow break-all text-lg font-light">
                {field.value}
              </p>
            ) : (
              <div className="w-full">{renderEmpty()}</div>
            )}
          </div>
        ) : (
          <>
            <div
              className={`mt-2 inline-flex w-full items-center border p-3
              ${
                hasError
                  ? 'border-danger'
                  : disabled
                  ? 'cursor-not-allowed border-gray-100 bg-gray-50'
                  : 'border-surfaces-divider'
              } rounded-md`}
            >
              <input
                ref={ref}
                className="w-full flex-grow text-lg outline-none disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300"
                type={isSecret && showPassword ? 'text' : type}
                placeholder={placeholder}
                {...field}
                value={field.value || ''}
                disabled={disabled}
                autoComplete={autoComplete}
              />
              {isSecret ? (
                <SecretEye
                  eyeState={!showPassword}
                  onClick={() => setShowPassword((state) => !state)}
                />
              ) : null}
              {action ? action : null}
            </div>
            {hasError ? (
              <div className="font-medium text-danger">{meta.error}</div>
            ) : null}
          </>
        )}
      </div>
    );
  }
);

export default Input;

type SecretEyeProps = {
  eyeState: boolean;
  onClick: () => void;
};

export const SecretEye = ({ eyeState, onClick }: SecretEyeProps) => {
  const className = 'w-6 h-6 cursor-pointer stroke-3/2 hover:text-primary';
  return eyeState ? (
    <Eye className={className} onClick={onClick} />
  ) : (
    <EyeOff className={className} onClick={onClick} />
  );
};
