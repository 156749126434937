import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useFirebaseConnect,
  useFirebase,
  isEmpty,
  isLoaded,
} from 'react-redux-firebase';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { User } from 'types/types';
import { NOW } from 'services/firebase';

import Editor from './CollaborativeEditor';
import Button from 'components/Button';
import Listbox, { Item } from 'components/Listbox';
import { History } from 'assets/icons';

type Revisions = Record<
  string,
  {
    createdAt: string;
  }
>;

const RevisionToolbar = ({
  current,
  onRevisionChange,
  onValidate,
  onCancel,
  revisions,
  loading,
  selectedRevision,
}: {
  current: string;
  onRevisionChange: (rev: string) => void;
  onValidate: () => void;
  onCancel: () => void;
  revisions: Revisions;
  loading: boolean;
  selectedRevision: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const items: Item<string>[] = useMemo(() => {
    const _items = _.sortBy(
      Object.entries(revisions),
      ([key, { createdAt }]) => -createdAt
    ).map(([revId, { createdAt }]) => ({
      value: revId,
      description: moment(createdAt).fromNow(),
    }));
    _items.unshift({
      value: current,
      description: t('editor:current'),
    });

    return _items;
  }, [revisions, current, t]);

  return (
    <div className="flex h-full w-full flex-wrap items-center justify-center space-x-4 px-4 pb-2">
      <div className="mx-auto mt-2 flex flex-grow items-center space-x-4">
        <label>{t('editor:revisions')}</label>
        <Listbox
          size="sm"
          defaultValue={current}
          items={items}
          setValue={onRevisionChange}
        />
      </div>
      <div className="mt-2 flex items-center space-x-4">
        <Button
          text={t('common:Cancel')}
          type="button"
          size="sm"
          design="secondary"
          disabled={loading}
          onClick={onCancel}
        />
        <Button
          text={t('editor:restoreVersion')}
          type="button"
          size="md"
          loading={loading}
          disabled={selectedRevision === current}
          onClick={onValidate}
        />
      </div>
    </div>
  );
};

export type RevisionnedEditorProps = {
  metaId: string;
  readOnly: boolean;
  forceDisplayInstructions?: boolean;
  editableInstructionBlocks?: boolean;
  showBorder?: boolean;
  user?: User;
  forceConnect?: boolean;
  toolbarSticky?: boolean;
  toolbarOffsetClassName?: string;
};

const RevisionnedEditor = ({
  metaId,
  user,
  showBorder = true,
  forceConnect,
  toolbarSticky,
  toolbarOffsetClassName,
  ...props
}: RevisionnedEditorProps): JSX.Element => {
  const [revisionMode, setRevisionMode] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const db = useFirebase();

  useFirebaseConnect([
    {
      path: `contentDocsRevisions/${metaId}`,
      queryParams: ['orderByChild=createdAt'],
    },
    `contentDocsMeta/${metaId}/current`,
  ]);

  const lastRevisions: Revisions = useSelector(
    (state: any) => state.firebase.data.contentDocsRevisions?.[metaId]
  );

  const current: string | null | undefined = useSelector(
    (state: any) => state.firebase.data.contentDocsMeta?.[metaId]?.current
  );

  const reset = useCallback(() => {
    if (current) {
      setRevisionMode(false);
      setSelectedRevision(current);
      setLoading(false);
    }
  }, [current]);

  useEffect(() => {
    reset();
  }, [reset]);

  const handleRevert = async (rev: string) => {
    setLoading(true);
    try {
      // making revision for current
      const oldDoc = (
        await db.ref(`contentDocs/${current}`).once('value')
      ).val();
      const ref = db.ref('contentDocs').push();
      await ref.set(oldDoc);
      await db.ref(`contentDocsRevisions/${metaId}/${ref.key}`).set({
        createdAt: NOW,
      });

      // creating current from revision
      const revDoc = (await db.ref(`contentDocs/${rev}`).once('value')).val();
      const newRef = db.ref('contentDocs').push();
      await newRef.set(revDoc);

      // updating current in meta
      await db.ref(`contentDocsMeta/${metaId}`).update({
        current: newRef.key,
        lastRevisionAt: NOW,
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <>
      {isLoaded(current) ? (
        <div
          className={`rounded-lg p-0 ${
            showBorder ? 'border border-surfaces-divider shadow-sm' : ''
          }`}
        >
          <Editor
            key={revisionMode ? selectedRevision! : current!}
            docId={revisionMode ? selectedRevision! : current!}
            metaId={metaId}
            // {...props}
            readOnly={revisionMode || props.readOnly}
            forceDisplayInstructions={
              props.forceDisplayInstructions || revisionMode || false
            }
            editableInstructionBlocks={
              props.editableInstructionBlocks && !revisionMode
            }
            additionalToolbarButtons={
              !revisionMode &&
              isLoaded(lastRevisions) &&
              !isEmpty(lastRevisions) &&
              Object.keys(lastRevisions).length > 0
                ? [
                    {
                      icon: (
                        <History className="fill-current text-black-soft hover:text-primary" />
                      ),
                      handler: () => setRevisionMode(true),
                    },
                  ]
                : []
            }
            alternateToolbar={
              revisionMode ? (
                <RevisionToolbar
                  current={current!}
                  revisions={lastRevisions}
                  onRevisionChange={(id) => setSelectedRevision(id)}
                  onValidate={() => handleRevert(selectedRevision!)}
                  onCancel={reset}
                  loading={loading}
                  selectedRevision={selectedRevision!}
                />
              ) : null
            }
            forceDisplayToolbar={revisionMode}
            // user={user}
            forceConnect={forceConnect}
            toolbarSticky={toolbarSticky}
            toolbarOffsetClassName={toolbarOffsetClassName}
          />
        </div>
      ) : null}
    </>
  );
};
export default RevisionnedEditor;
