import React, { useEffect, useRef, useState } from 'react';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { MicrophoneOff } from 'assets/icons';
import Avatar from 'components/Avatar';
import { MeetingUser } from 'frameworks/agora/meetingManager';
import {
  requestMuteAudio,
  requestTurnOffVideo,
  requestStopScreenSharing,
} from 'model/meeting';
import { useUser } from 'model/users';
import { useTranslation } from 'react-i18next';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import VideoButton from 'blocks/VideoButton';

type VideoCardProps = {
  client: IAgoraRTCClient | null;
  user?: MeetingUser;
  remoteControlEnabled: boolean;
  meetingName: string;
};

const VideoCard = ({
  user,
  client,
  remoteControlEnabled,
  meetingName,
}: VideoCardProps): JSX.Element => {
  const containerRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(0);
  console.log(videoLoaded);

  const { t } = useTranslation();

  const [userData] = useUser((user?.user.uid as string) || 'nowayyoufindme');

  useFirebaseConnect([`meetingsData/${meetingName}/sharingUserId`]);

  const sharingUserId: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.meetingsData?.[meetingName]?.sharingUserId || null
  );

  useEffect(() => {
    if (user?.user.hasVideo && client && user?.user.uid) {
      const cli = client;

      cli
        .subscribe(user.user, 'video')
        .then(() => setVideoLoaded((videoLoaded) => videoLoaded + 1))
        .catch((err: unknown) => console.error(err));

      return () => {
        cli.unsubscribe(user.user, 'video').catch((err) => console.error(err));
      };
    }
  }, [user?.user, user?.user.hasVideo, user?.user.uid, client]);

  useEffect(() => {
    if (containerRef.current && user?.user.videoTrack) {
      user.user.videoTrack?.play(containerRef.current);
    }
  }, [user?.user.videoTrack]);

  const audioEnabled = user?.user?.hasAudio;
  const videoEnabled = user?.user?.hasVideo;
  const sharingEnabled = user?.user?.uid === sharingUserId;

  const toggleAudio = () => {
    if (audioEnabled && remoteControlEnabled && user?.user?.uid) {
      requestMuteAudio(meetingName, user.user.uid as string);
    }
  };

  const toggleVideo = () => {
    if (videoEnabled && remoteControlEnabled && user?.user?.uid) {
      requestTurnOffVideo(meetingName, user.user.uid as string);
    }
  };

  const toggleScreenSharing = () => {
    if (sharingEnabled && remoteControlEnabled && user?.user?.uid) {
      requestStopScreenSharing(meetingName, user.user.uid as string);
    }
  };

  return (
    <div
      className={`relative h-full w-full ${
        user?.speaking ? 'border-4 border-warning-medium' : ''
      }`}
      ref={containerRef}
    >
      {userData ? (
        <div
          className={`absolute top-2 left-2 z-20  rounded-md bg-black-soft px-1 text-xs text-white`}
        >
          {userData.name || t('users:UnknownUser')}
        </div>
      ) : null}
      {user && !user?.user.hasAudio ? (
        <div className={`absolute top-2 right-2 z-20 h-5 w-5 rounded-full p-1`}>
          <MicrophoneOff className="h-full w-full text-white" />
        </div>
      ) : null}
      {user && remoteControlEnabled ? (
        <div className="absolute bottom-0 left-0 right-0 z-20 flex items-center justify-center">
          <div className="flex flex-row space-x-2 p-1">
            <VideoButton.Microphone
              enabled={!!audioEnabled}
              disabled={!audioEnabled}
              onClick={() => toggleAudio()}
            />
            {sharingEnabled ? (
              <VideoButton.ShareScreen
                enabled={true}
                onClick={() => toggleScreenSharing()}
              />
            ) : (
              <VideoButton.Camera
                enabled={!!videoEnabled}
                disabled={!videoEnabled}
                onClick={() => toggleVideo()}
              />
            )}
          </div>
        </div>
      ) : null}
      {!user?.user.hasVideo ? (
        <div className="flex h-full w-full items-center justify-center">
          {user ? (
            <Avatar
              size="lg"
              name={userData?.name || t('users:UnknownUser')}
              bgColorClass={
                user?.speaking ? 'border-warning-medium' : undefined
              }
            />
          ) : (
            <p className="px-4 text-center text-lg text-black-soft">
              {t('video:NotJoined')}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VideoCard;
