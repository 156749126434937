import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';

import {
  userIsParticipant,
  setUserActivityDraft,
  getUserActivityDraft,
  useTextReplaceSession,
} from 'model/sessions';
import React, { useCallback, useEffect, useState } from 'react';
import { useHydrate } from 'screens/SessionScreen/SessionLiveScreen/Screen';
import {
  convertPostPropsToCriteria,
  PostScreenView,
} from 'screens/SessionScreen/SessionLiveScreen/screens/PostCreateScreen';
import { SessionTemplate } from 'types/types';
import CongratulationsView from 'blocks/CongratulationView';
import Button from 'components/Button';

export type PostModalProps = {
  sessionTemplate: SessionTemplate;
  userId: string;
  sessionId: string;
  communityId: string | undefined;
  editingPost: boolean;
  close: () => void;
};

const PostModal = ({
  sessionId,
  userId,
  communityId,
  sessionTemplate,
  editingPost,
  close,
}: PostModalProps) => {
  const { t } = useTranslation();
  const [postDraft, setPostDraft] = useState<string | null>(null);
  const [showCongrats, setShowCongrats] = useState(false);
  const postScreenEditableContent =
    sessionTemplate.activities.post.screens.create.content.template
      .editableContent;

  const postScreenParams =
    sessionTemplate.activities.post.screens.create.content.template.params;

  const [data] = useHydrate(
    sessionId,
    sessionId,
    sessionTemplate.activities.post,
    userId,
    { post: sessionTemplate.activities.post.productions! },
    sessionTemplate.activities,
    sessionTemplate.activities.post.screens.create.dataBindings,
    false
  );

  const textReplaceSession = useTextReplaceSession(sessionId, 'post', userId);

  const criteria = convertPostPropsToCriteria({
    ...postScreenEditableContent,
    ...data,
  });

  const afterSave = async () => {
    const promises = [userIsParticipant(userId, sessionId)];
    await Promise.all(promises);
  };

  const savePost = (post: string) => {
    setUserActivityDraft(
      sessionId,
      sessionTemplate.activities.post.name,
      'post',
      userId,
      post
    );
  };

  const getpostDraft = useCallback(async () => {
    if (sessionTemplate?.activities?.post?.name) {
      const draft = await getUserActivityDraft(
        sessionId,
        sessionTemplate.activities.post.name,
        'post',
        userId
      );
      setPostDraft(draft);
    }
  }, [sessionId, sessionTemplate?.activities?.post?.name, userId]);

  useEffect(() => {
    getpostDraft();
  }, [getpostDraft]);

  const onClose = () => {
    close();
    getpostDraft();
    setTimeout(() => setShowCongrats(false), 200);
  };

  return (
    <Modal
      open={editingPost}
      onClose={onClose}
      body={
        showCongrats ? (
          <CongratulationsView />
        ) : (
          <PostScreenView
            {...(postScreenEditableContent as {
              question: string;
              description: string;
              answerPrompt: string;
              forceNoPost: string;
              more: string;
            })}
            {...(postScreenParams as {
              allowedNotToPost: boolean;
              criteriaRequired: boolean;
            })}
            post={data.post || postDraft || ''}
            setPost={data.setPost}
            criterias={criteria}
            afterSave={() => {
              setShowCongrats(true);
              afterSave();
            }}
            savePost={savePost}
            textReplaceSession={textReplaceSession}
          />
        )
      }
      footer={
        showCongrats ? (
          <Button text={t('common:Close')} onClick={onClose} />
        ) : undefined
      }
    />
  );
};

export default PostModal;
