import React, { useCallback, useEffect, useRef, useState } from 'react';
import { upload } from 'services/storage';
import Button from './Button';

export type UploadedFailedReason = 'mimeTypeMismatch' | 'unknownError';

export type UploadButtonProps = {
  text: string;
  urlPrefix: string;
  mimeType: string;
  fileUploaded?: (fileUrl: string) => Promise<void>;
  fileUploadedFailed?: (fileUrl: UploadedFailedReason) => Promise<void>;
};

const UploadButton = ({
  text,
  urlPrefix,
  mimeType,
  fileUploaded,
  fileUploadedFailed,
}: UploadButtonProps) => {
  const filePicker = useRef<HTMLInputElement>(null);
  const [loadingState, setLoadingState] = useState(false);

  const uploadFile = useCallback(
    (files: FileList | null) => {
      if (files?.length === 1) {
        const file = files[0];
        if (file.type === mimeType) {
          upload(
            urlPrefix,
            file,
            () => {
              setLoadingState(true);
            },
            (_fileName, url) => {
              setLoadingState(false);
              fileUploaded?.(url);
            },
            () => {
              setLoadingState(false);
              fileUploadedFailed?.('unknownError');
            }
          );
        } else {
          fileUploadedFailed?.('mimeTypeMismatch');
        }
      } else {
        fileUploadedFailed?.('unknownError');
      }
    },
    [fileUploaded, fileUploadedFailed, mimeType, urlPrefix]
  );

  useEffect(() => {
    if (filePicker.current) {
      const el = filePicker.current;
      const callback = () => {
        uploadFile(el.files);
      };

      el.addEventListener('input', callback);
      return () => el.removeEventListener('input', callback);
    }
  }, [filePicker, uploadFile]);

  return (
    <>
      <Button
        text={text}
        loading={loadingState}
        onClick={() => {
          if (filePicker.current) {
            filePicker.current.click();
          }
        }}
      />
      <input ref={filePicker} type="file" hidden={true} accept={mimeType} />
    </>
  );
};

export default UploadButton;
