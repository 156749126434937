import DocumentViewer from 'components/DocumentViewer';
import React from 'react';
import { Nullable } from 'types/typesUtils';

export type DebriefDocumentProps = {
  synthesisUrl: Nullable<string>;
  marginClasses: string;
};

const DebriefDocument = ({
  synthesisUrl,
  marginClasses,
}: DebriefDocumentProps) => {
  return synthesisUrl ? (
    <div className={`${marginClasses} space-y-8`}>
      <DocumentViewer documentUrl={synthesisUrl} />
    </div>
  ) : null;
};

export default DebriefDocument;
