import React from 'react';
import moment from 'moment';

import DayPickerInput from './DayPickerInput';

import 'react-day-picker/lib/style.css';
import DurationPicker from 'components/DurationPicker';

type DatetimePickerProps = {
  value: Date;
  onValueChange: (date: Date) => void;
  className?: string;
  errorMode?: boolean;
  readOnly?: boolean;
  wrap?: boolean;
};

const DatetimePicker = ({
  value,
  onValueChange,
  className,
  errorMode,
  readOnly = false,
  wrap = false,
}: DatetimePickerProps) => {
  return (
    <div
      className={`flex flex-row items-center ${
        wrap ? 'flex-wrap' : ''
      } -ml-4 ${className}`}
    >
      {readOnly ? (
        <p className="text-lg">
          {value
            ? `${moment(value).format('LL')} ${moment(value).format('LT')}`
            : ''}
        </p>
      ) : (
        <>
          <div className="mt-2 ml-4">
            <DayPickerInput
              placeholder={new Date().toLocaleDateString()}
              value={value}
              onDayChange={(date) => {
                let datetime = date;
                datetime.setHours(value?.getHours() || 0);
                datetime.setMinutes(value?.getMinutes() || 0);
                onValueChange(datetime);
              }}
              inputProps={{
                className: `border rounded-lg  p-3 shrink-0 ${
                  errorMode ? 'border-danger' : 'border-surfaces-divider'
                }`,
              }}
            />
          </div>
          <DurationPicker
            className="mt-2 ml-4"
            hours={value?.getHours() || 0}
            minutes={value?.getMinutes() || 0}
            onChange={(hours, minutes) => {
              let datetime = new Date(value) || new Date();
              datetime.setHours(hours);
              datetime.setMinutes(minutes);
              onValueChange(datetime);
            }}
            errorMode={errorMode}
          />
        </>
      )}
    </div>
  );
};

export default DatetimePicker;
