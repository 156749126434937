import Tooltip from './Tooltip';
import { Check, Duplicate } from 'assets/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CopyIconProps = {
  textToCopy: string;
};

const CopyIcon = ({ textToCopy }: CopyIconProps) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const onClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <Tooltip content={copied ? t('misc:copied') : t('misc:copy')}>
      <button className="stroke-3/2" onClick={onClick}>
        {copied ? (
          <Check className="h-6 w-6 text-success" />
        ) : (
          <Duplicate className="h-6 w-6" />
        )}
      </button>
    </Tooltip>
  );
};

export default CopyIcon;
