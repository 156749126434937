import React from 'react';
import { BORDER_RADIUS_HALF } from 'constants/Dimensions';

const GroupCard = ({
  number,
  name,
  visioCode,
  buttons,
  children,
  style,
  color,
  groupPrefix,
}) => {
  const componentStyle = { ...styles.viewMain, ...style };
  return (
    <div style={componentStyle}>
      <div style={{ ...styles.header, ...color }}>
        <p style={styles.header.text}>
          Group {`${groupPrefix || ''}${number}`}
        </p>
        {buttons}
      </div>
      <div>{children}</div>
    </div>
  );
};

const styles = {
  viewMain: {
    width: 300,
    // height: 'fit-content',
    borderRadius: BORDER_RADIUS_HALF,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.42)',
    // overflow: 'hidden',
  },
  header: {
    fontSize: '28px',
    borderRadius: BORDER_RADIUS_HALF,
    // minHeight: 50,
    // textAlign: 'center',
    // backgroundColor: '#F5F5F5',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px 0 20px',
    number: {
      fontWeight: 'bold',
      // borderRadius: '50%',
      // backgroundColor: 'white',
      // padding: BORDER_RADIUS_HALF
      // height: 30,
      // width: 30,
    },
    text: {
      marginBottom: '0',
    },
  },
};

export default GroupCard;
