import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { Nullable } from 'types/typesUtils';
import {
  Session,
  SessionTemplate,
  ActivitiesMeta,
  ActivityData,
  ActionRecord,
} from 'types/types';
import { useParticipants } from 'model/sessions';

import { useTopics, usePostsMeta, useSessionPosts } from 'model/productions';
import KeyNumbers from './KeyNumbers';
import DebriefMindmap from './DebriefMindMap';
import EmergingQuestion from './EmergingQuestion';
import ParticipantsList from './ParticipantsList';
import SessionObjectives from './SessionObjectives';
import SessionValuable from './SessionValuable';
import ParticipantAnalysis from './ParticipantAnalysis';
import BestFeedbacks from './BestFeedbacks';
import BestPosts from './BestPosts';
import { useSessionVotes } from 'model/votes';
import SessionInfo from './SessionInfo';
import BestPostsByTopics from './BestPostsByTopics';
import Focus from './Focus';
import Spinner from 'components/Spinner';
import AllDocumentActivities from './AllDocumentActivities';

export type DebriefDataProps = {
  sessionId: string;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const DebriefData = ({
  sessionId,
  allowAutomatedSynthesis,
  marginClasses,
}: DebriefDataProps) => {
  useFirebaseConnect([
    `sessionsNext/${sessionId}`,
    `sessionsNextTemplates/${sessionId}`,
    `sessionsNextData/${sessionId}/activities/`,
    `sessionsNextData/${sessionId}/activitiesMeta`,
    `sessionsNextData/${sessionId}/debrief/synthesisUrl`,
    `sessionsNextData/${sessionId}/debrief/summaries`,
  ]);

  const session: Nullable<Session> = useSelector(
    (state: any) => state.firebase.data.sessionsNext?.[sessionId]
  );

  const sessionTemplate: Nullable<SessionTemplate> = useSelector(
    (state: any) => state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const activitiesData: Nullable<Record<string, ActivityData>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities
  );

  const criteria: Nullable<Record<string, Record<string, string>>> =
    useSelector(
      (state: any) =>
        state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
          ?.productions || null
    );

  const activitiesMeta: Nullable<ActivitiesMeta> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activitiesMeta
  );

  const [participants, participantsLoaded] = useParticipants(sessionId);

  const feedbacks = activitiesData?.feedback?.productions;

  const [topics, isTopicsLoaded] = useTopics(sessionId);

  const [postsMeta] = usePostsMeta(sessionId, 'post');

  const posts = useSessionPosts(sessionId);

  const votes = useSessionVotes(sessionId);

  const summaries: Nullable<Record<string, any>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.summaries
  );

  const rephrasedPosts: Nullable<Record<string, string>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.summaries
        ?.post
  );

  return participantsLoaded &&
    isLoaded(
      session,
      sessionTemplate,
      activitiesData,
      criteria,
      activitiesMeta,
      summaries
    ) ? (
    <>
      <SessionInfo
        title={session!.title}
        day={new Date(session!.scheduledAt[0])}
        marginClasses={marginClasses}
      />

      <EmergingQuestion
        question={
          sessionTemplate?.activities?.post?.screens?.create?.content.template
            .editableContent.question
        }
        marginClasses={marginClasses}
      />

      <BestPostsByTopics
        sessionId={sessionId}
        allowAutomatedSynthesis={allowAutomatedSynthesis}
        postsSummaries={rephrasedPosts}
        posts={posts}
        postsMeta={postsMeta}
        votes={votes}
        participants={participants}
        topics={topics}
        marginClasses={marginClasses}
      />

      <Focus
        sessionId={sessionId}
        allowAutomatedSynthesis={allowAutomatedSynthesis}
        postsSummaries={rephrasedPosts}
        posts={posts}
        votes={votes}
        participants={participants}
        groupOfUser={activitiesData?.share?.grouping?.groupOfUser}
        shareDocs={activitiesData?.share?.productions?.experience}
        marginClasses={marginClasses}
      />

      <BestPosts
        sessionId={sessionId}
        allowAutomatedSynthesis={allowAutomatedSynthesis}
        postsSummaries={rephrasedPosts}
        posts={posts}
        votes={votes}
        participants={participants}
        marginClasses={marginClasses}
      />

      <DebriefMindmap
        isTopicsLoaded={isTopicsLoaded}
        topics={topics}
        question={
          sessionTemplate?.activities?.post?.screens?.create?.content.template
            .editableContent.question
        }
        participants={participants}
        posts={posts}
        postsMeta={postsMeta}
      />

      <KeyNumbers
        sessionTemplate={sessionTemplate}
        activitiesData={activitiesData}
        marginClasses={marginClasses}
      />

      <ParticipantsList
        participants={participants}
        marginClasses={marginClasses}
      />

      <SessionObjectives goals={session?.goals} marginClasses={marginClasses} />

      <SessionValuable
        valuable={feedbacks?.valuable}
        marginClasses={marginClasses}
      />

      <ParticipantAnalysis
        postProductions={sessionTemplate?.activities?.post?.productions}
        criteriaData={criteria}
        marginClasses={marginClasses}
      />

      <BestFeedbacks
        feedbacks={feedbacks}
        participants={participants}
        marginClasses={marginClasses}
      />

      {allowAutomatedSynthesis ? (
        <AllDocumentActivities
          sessionId={sessionId}
          session={session!}
          summaries={summaries}
          sessionTemplate={sessionTemplate!}
          marginClasses={marginClasses}
        />
      ) : null}
    </>
  ) : (
    <Spinner className="mx-auto h-12 w-12 text-primary" />
  );
};

export default DebriefData;
