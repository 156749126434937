import { useEffect, useState } from 'react';
import { DocSearchResults, UserType } from 'types/types';
import { FullDocMeta } from './types';
import _ from 'lodash';
import React from 'react';
import ContentList from './ContentList';
import { LoadingOutlined } from '@ant-design/icons';
import { searchDocs } from 'services/searching';
import { useFullDocs } from './hooks';

const LinkedDocs = ({
  sessionId,
  userId,
  userType,
  doc,
  selectDoc,
}: {
  sessionId: string;
  userId: string | undefined;
  userType: UserType;
  doc: FullDocMeta;
  selectDoc: (doc: FullDocMeta) => void;
}): JSX.Element => {
  const [searchResults, setSearchResults] = useState<DocSearchResults | null>(
    null
  );

  useEffect(() => {
    const authors = Object.keys(doc.authors || {});

    searchDocs('', userId, userType, [sessionId], undefined, undefined, authors)
      .then((results) => setSearchResults(results))
      .catch((e) => console.error(e));
  }, [doc, sessionId, userId, userType]);

  const mainDoc = doc;
  const fullFilter = (doc: FullDocMeta) =>
    doc.type &&
    !_.includes(
      ['La synthèse du groupe de John Doe', 'Expérience de John Doe'],
      doc.title
    ) &&
    mainDoc.id !== doc.id;

  const docs = useFullDocs(searchResults?.hits || []);

  return !searchResults ? (
    <LoadingOutlined style={{ fontSize: '32px' }} />
  ) : (
    <ContentList docs={docs.filter(fullFilter)} selectDoc={selectDoc} />
  );
};

export default LinkedDocs;
