import React from 'react';
import { Menu } from '@headlessui/react';

import RoundedButton from './RoundedButton';

import { X, DotsHorizontal } from 'assets/icons';

export type Action =
  | {
      type: 'button';
      label: string | null;
      onClick: () => void;
      icon?: JSX.Element;
      severity?: 'normal' | 'warning' | 'danger';
    }
  | {
      type: 'divider';
    };

export type MenuButtonsProps = {
  actions: Action[];
  size?: 'sm' | 'base' | 'lg';
  menuButton?: JSX.Element;
  verticalGap?: boolean;
  iconPlacement?: 'left' | 'right';
  menuAnchor?: 'left' | 'right';
};

const MenuButtons = ({
  actions,
  size = 'lg',
  menuButton,
  verticalGap = true,
  iconPlacement = 'right',
  menuAnchor = 'right',
}: MenuButtonsProps): JSX.Element => {
  return (
    <Menu as="div" className="relative select-none">
      {({ open }) => {
        return (
          <>
            <Menu.Button as="div">
              {menuButton ? (
                menuButton
              ) : (
                <RoundedButton size={size}>
                  {open ? <X /> : <DotsHorizontal />}
                </RoundedButton>
              )}
            </Menu.Button>
            <Menu.Items
              className={`absolute ${
                menuAnchor === 'left' ? 'left-0' : 'right-0'
              } z-40 ${
                verticalGap ? 'mt-3' : ''
              } max-h-80 min-w-max space-y-2 overflow-y-auto rounded-md border border-surfaces-divider bg-white py-2 shadow-lg focus:outline-none`}
            >
              {actions.map((action, index) => {
                return action.type === 'button' ? (
                  <Menu.Item key={index}>
                    <div
                      className={`flex cursor-pointer flex-row items-center justify-between space-x-4 py-2 px-3 ${
                        action.severity === 'danger'
                          ? 'text-danger hover:bg-danger-soft'
                          : action.severity === 'warning'
                          ? 'text-warning hover:bg-warning-soft'
                          : 'hover:bg-primary-soft'
                      }`}
                      onClick={action.onClick}
                    >
                      {action.icon && iconPlacement === 'left'
                        ? action.icon
                        : null}
                      <p className="text-lg">{action.label}</p>
                      {action.icon && iconPlacement === 'right'
                        ? action.icon
                        : null}
                    </div>
                  </Menu.Item>
                ) : action.type === 'divider' ? (
                  <div
                    key={index}
                    className="border-t border-surfaces-divider"
                  ></div>
                ) : null;
              })}
            </Menu.Items>
          </>
        );
      }}
    </Menu>
  );
};

export default MenuButtons;
