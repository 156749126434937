import React from 'react';

import Colors from 'constants/Colors';

const text = '&nbsp;';
const PostFull = ({ tags, content, creator, criteria, style }) => {
  // let criteriaNames = criteria && Object.keys(criteria);
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.header}>
          {creator && creator !== '' ? (
            <span>{creator}</span>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: text }} />
          )}
          {tags.map((tag) => (
            <span key={tag} style={styles.tags}>
              {tag}
            </span>
          ))}
        </div>
        {/* {criteria && criteriaNames.length > 0 && (
          <div>
            <p style={styles.criteria}>{`${criteriaNames[0]}: ${
              criteria[criteriaNames[0]]
            }`}</p>
          </div>
        )} */}
        {content && (
          <div>
            <p style={styles.content}>{content}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: 12,
  },
  img: {
    height: 48,
    width: 48,
  },
  imgContainer: {
    display: 'flex',
    marginRight: 12,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  tags: {
    color: Colors.colorGrayTextOnGrayBackground,
    margin: '0 5px 0 5px',
  },
  criteria: {
    fontStyle: 'italic',
    fontSize: 12,
    textAlign: 'left',
  },
  content: {
    color: Colors.colorGrayTextOnGrayBackground,
    height: 'fit-content',
    margin: 0,
    textAlign: 'left',
  },
};

PostFull.defaultProps = {
  creator: 'Unnamed',
  tags: [],
};

export default PostFull;
