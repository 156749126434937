import React from 'react';
import { useField } from 'formik';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'assets/icons';

import { useOnChange } from 'frameworks/formik/hooks';

import { Gb, Fr, Xx } from 'assets/flags';

export type LanguageSelectorProps = {
  value: string;
  readOnly?: boolean;
  compact?: boolean;
  onChange?: (language: string) => void;
  allowUnknown?: boolean;
};

type languageItemType = {
  id: string;
  description: string;
  icon: JSX.Element;
};

const LanguageItem = ({
  icon,
  description,
  onClick,
}: {
  description: string;
  icon: JSX.Element;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`flex w-full min-w-max flex-row items-center py-1 px-4 text-black hover:text-primary ${
            active ? 'bg-primary-soft text-primary' : ''
          }`}
          onClick={onClick}
        >
          <span className="">{icon}</span>
          <span className="ml-4 text-lg">{description}</span>
        </button>
      )}
    </Menu.Item>
  );
};

const LanguageSelector = ({
  onChange,
  value,
  readOnly = false,
  compact = true,
  allowUnknown = false,
}: LanguageSelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const iconClassName = 'h-5';
  const languages: languageItemType[] = [
    {
      id: 'fr',
      description: t('common:French'),
      icon: <Fr className={iconClassName} />,
    },
    {
      id: 'en',
      description: t('common:English'),
      icon: <Gb className={iconClassName} />,
    },
  ];

  if (allowUnknown) {
    languages.unshift({
      id: 'xx',
      description: t('common:Unknown', { context: 'female' }),
      icon: <Xx className={iconClassName} />,
    });
  }

  const selectedLanguage =
    languages.find((language) => language.id === value) || languages[0];

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <div className="flex flex-row items-center justify-start">
            {selectedLanguage?.icon}
            {!compact ? (
              <p className="ml-4 text-lg text-black">
                {selectedLanguage?.description}
              </p>
            ) : null}
            {!readOnly ? (
              <Menu.Button
                className={`ml-2 flex rounded-full p-0.5 hover:bg-primary-soft ${
                  open ? 'bg-primary-soft' : ''
                }`}
              >
                <ChevronRight className="h-5 w-5 rotate-90 stroke-2" />
              </Menu.Button>
            ) : null}
          </div>
          <Menu.Items className="absolute right-0 z-50 mt-2 min-w-max rounded-md border border-surfaces-divider bg-white shadow-lg focus:outline-none">
            <div className="py-2">
              {languages
                .filter((language) => language.id !== value)
                .map((language) => {
                  return (
                    <LanguageItem
                      key={language.id}
                      description={language.description}
                      icon={language.icon}
                      onClick={() => onChange && onChange(language.id)}
                    />
                  );
                })}
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default LanguageSelector;

export const LanguageSelectorInput = ({
  name,
  label,
  readOnly,
  compact,
  allowUnknown,
  onChangeValue,
}: {
  name: string;
  label?: string;
  readOnly?: boolean;
  compact?: boolean;
  allowUnknown?: boolean;
  onChangeValue?: (value: string, hasError: boolean) => void;
}): JSX.Element => {
  const [field, , helpers] = useField(name);

  useOnChange(name, onChangeValue);
  return (
    <div>
      {label && (
        <label className="text-lg font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="mt-1 flex">
        <LanguageSelector
          {...field}
          onChange={(value) => helpers.setValue(value)}
          readOnly={readOnly}
          compact={compact}
          allowUnknown={allowUnknown}
        />
      </div>
    </div>
  );
};
