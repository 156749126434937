import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DEFAULT_TEMPLATE_ID } from 'constants/AppConfig';

import { duplicateSession } from 'model/sessions';

import ProcessingScreen from 'screens/ProcessingScreen';
import { fetchData } from 'services/firebase';
import { Community, Session } from 'types/baseTypes';

export type NewSessionprocessingProps = { userId: string };

const NewSessionprocessing = ({
  userId,
}: NewSessionprocessingProps): JSX.Element => {
  const [executionError, setExecutionError] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ProcessingScreen<
      Partial<{
        templateId: string;
        communityId: string;
        title: string;
      }>
    >
      message={
        executionError
          ? t('sessions:creatingSessionErrorMessage')
          : t('sessions:creatingSessionForYou')
      }
      executionError={executionError}
      execute={async (params) => {
        try {
          const community = await fetchData<Community>(
            `communities/${params?.communityId}`
          );
          const template = await fetchData<Session>(
            `sessionsNext/${params?.templateId}`
          );
          if (community && template) {
            const [accessCode] = await duplicateSession(
              params?.templateId || DEFAULT_TEMPLATE_ID,
              params?.title || t('sessions:NewSession'),
              userId,
              false,
              {
                communityId: params?.communityId,
                location: undefined,
              },
              {},
              false,
              true
            );
            if (accessCode) {
              history.push(
                `/${accessCode}/live?modeRequested=editor&withSettings=true`
              );
            }
          } else {
            setExecutionError(true);
          }
        } catch (error) {
          console.error(error);
          setExecutionError(true);
        }
      }}
    />
  );
};

export default NewSessionprocessing;
