import { Colors } from 'types/ui';
import { tailwindTheme } from 'utils/utils';

type ColorValueType = {
  soft: string;
  default: string;
};

export const colors: Colors<ColorValueType> = {
  primary: {
    soft: 'var(--color-primary-soft)',
    default: 'var(--color-primary-default)',
  },
  success: {
    soft: 'var(--color-success-soft)',
    default: 'var(--color-success-default)',
  },
  warning: {
    soft: 'var(--color-warning-soft)',
    default: 'var(--color-warning-default)',
  },
  danger: {
    soft: 'var(--color-danger-soft)',
    default: 'var(--color-danger-default)',
  },
  gray: {
    soft: 'var(--color-surfaces-divider)',
    default: 'var(--color-black-soft)',
  },
  yellow: {
    soft: tailwindTheme.colors.yellow[200],
    default: tailwindTheme.colors.yellow[600],
  },
  fuchsia: {
    soft: tailwindTheme.colors.fuchsia[200],
    default: tailwindTheme.colors.fuchsia[600],
  },
  green: {
    soft: tailwindTheme.colors.green[200],
    default: tailwindTheme.colors.green[600],
  },
  indigo: {
    soft: tailwindTheme.colors.indigo[200],
    default: tailwindTheme.colors.indigo[600],
  },
  lime: {
    soft: tailwindTheme.colors.lime[200],
    default: tailwindTheme.colors.lime[600],
  },
  sky: {
    soft: tailwindTheme.colors.sky[200],
    default: tailwindTheme.colors.sky[600],
  },
  teal: {
    soft: tailwindTheme.colors.teal[200],
    default: tailwindTheme.colors.teal[600],
  },
  violet: {
    soft: tailwindTheme.colors.violet[200],
    default: tailwindTheme.colors.violet[600],
  },
};
