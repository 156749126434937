import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelectorArray } from 'services/firebase';
import _ from 'lodash';

import { SessionStatusState } from 'types/baseTypes';
import { TaskData, UserType } from 'types/types';
import { Nullable } from 'types/typesUtils';

import { Task as TaskType, Step, replaceSessionId } from '../../tasks';

import Disclosure from 'components/Disclosure';
import TasksListForm from '../blocks/TasksListForm';

export type FollowUpViewProps = {
  userId: string;
  userType: UserType;
  sessionId: string;
  sessionState: Nullable<SessionStatusState>;
  currentStep: Step;
  steps: Partial<Record<SessionStatusState, Step>>;
  accessCode: string;
};

const FollowUpView = ({
  userId,
  userType,
  sessionId,
  sessionState,
  currentStep,
  steps,
  accessCode,
}: FollowUpViewProps): JSX.Element => {
  const { t } = useTranslation();

  const loadedTasks = useMemo(() => {
    let isPreviousTask = true;
    return Object.values(steps || {}).reduce<TaskType[]>((tasks, step) => {
      const newTasks =
        isPreviousTask || step.name === currentStep.name ? step.tasks : [];
      if (step.name === currentStep.name) {
        isPreviousTask = false;
      }
      return [...tasks, ...newTasks];
    }, []);
  }, [steps, currentStep]);

  const loadArray = loadedTasks.reduce<string[]>(
    (prev, task) => {
      if (task.dataBinding) {
        if (_.isObject(task.dataBinding)) {
          Object.values(task.dataBinding).forEach((bond) => {
            prev.push(replaceSessionId(bond, sessionId));
          });
        } else {
          prev.push(replaceSessionId(task.dataBinding, sessionId));
        }
      }
      if (task.dependencies) {
        task.dependencies.forEach((dep) => {
          prev.push(replaceSessionId(dep.path, sessionId));
        });
      }
      return prev;
    },
    [`sessionsNextData/${sessionId}/tasks`]
  );

  useFirebaseConnect(loadArray);

  const [, data]: [boolean, any] = useSelectorArray(
    loadArray || [],
    (data: any) => data
  );

  const tasksData: Record<string, TaskData> = useMemo(
    () => _.get(data, `sessionsNextData.${sessionId}.tasks`),
    [data, sessionId]
  );

  const activeStepIndex =
    Object.keys(steps).findIndex((key) => key === sessionState) || 0;

  return (
    <>
      <div className="mb-2 flex flex-col items-start space-y-2 md:flex-row md:space-x-2">
        <div className="flex-grow">
          <div className="flex flex-row items-center space-x-4">
            <h1 className="text-3xl font-semibold">
              {t('sessions:stepForSuccessTitle')}
            </h1>
          </div>
          <p className="mt-2 text-lg text-gray-400">
            {t('sessions:stepForSuccessDescription')}
          </p>
        </div>
      </div>
      <div>
        {Object.values(steps).map((step, index) => {
          return (
            <Disclosure
              key={`${step.name} ${activeStepIndex}`}
              defaultOpen={false}
              collapsable
              title={
                <div className="flex w-full flex-row items-center space-x-2">
                  <span
                    className={`flex h-7 w-7  shrink-0 flex-row items-center justify-center rounded-full bg-primary-soft text-lg font-semibold text-primary group-hover:bg-primary group-hover:text-white`}
                  >
                    {index + 1}
                  </span>
                  <div className={`flex grow flex-row space-x-1`}>
                    <h2>{step.name}</h2>
                  </div>
                </div>
              }
            >
              <TasksListForm
                key={step.name}
                tasks={step.tasks}
                sessionId={sessionId}
                userId={userId}
                userType={userType}
                accessCode={accessCode}
                tasksData={tasksData}
              />
            </Disclosure>
          );
        })}
      </div>
    </>
  );
};

export default FollowUpView;
