import { cleanDocs } from './docManagement';
import { PureDatabase } from '../../types/typesUtils';

export const deleteSession =
  (database: PureDatabase) =>
  async (sessionId: string): Promise<void> => {
    const pathsToRemove = [
      `sessionsNext/${sessionId}`,
      `sessionsNextData/${sessionId}`,
      `sessionsNextTemplates/${sessionId}`,
    ];

    const users = (
      await database.ref(`sessionsNextData/${sessionId}/users`).once('value')
    ).val();

    // some sessionsOfUsers may not be cleaned (organizers, superadmins ...)
    Object.keys(users?.participants || {}).forEach((userId) => {
      pathsToRemove.push(`sessionsOfUsers/${userId}/${sessionId}`);
    });
    Object.keys(users?.facilitators || {}).forEach((userId) => {
      pathsToRemove.push(
        `facilitations/${userId}/${sessionId}`,
        `sessionsOfUsers/${userId}/${sessionId}`
      );
    });

    Object.keys(users?.organizers || {}).forEach((userId) => {
      pathsToRemove.push(`organizations/${userId}/${sessionId}`);
    });

    const updates = pathsToRemove.reduce<Record<string, null>>((prev, path) => {
      prev[path] = null;
      return prev;
    }, {});
    await database.ref().update(updates);

    await cleanDocs(database)(sessionId);
  };
