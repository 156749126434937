import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import MenuButtons, { Action } from 'components/MenuButtons';
import Button from 'components/Button';

import {
  exportAllUsersProduction,
  exportUsers,
  exportFeedbacks,
} from 'services/export';
import { exportSessionDocs } from 'services/docExport';
import { useParticipants } from 'model/sessions';
import { useTopics } from 'model/productions';

import { ChevronUp, DocumentText, Table } from 'assets/icons';

export type ExportsButtonProps = {
  sessionId: string;
  showParticipantsExport?: boolean;
  showFeedbackExport?: boolean;
  showDocsExport?: boolean;
};

const ExportsButton = ({
  sessionId,
  showParticipantsExport,
  showFeedbackExport,
  showDocsExport,
}: ExportsButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [participants, isParticipantLoaded] = useParticipants(sessionId);
  const [topics] = useTopics(sessionId);
  const actions: Action[] = [];

  if (showDocsExport && isParticipantLoaded) {
    actions.push({
      type: 'button',
      onClick: () => {
        exportSessionDocs(sessionId, participants, true);
      },
      label: t('sessions:exportAllDocuments'),
      icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
    });

    if (_.size(topics) > 0) {
      actions.push({
        type: 'button',
        onClick: () => {
          exportSessionDocs(
            sessionId,
            participants,
            true,
            undefined,
            'byTopics'
          );
        },
        label: t('sessions:exportThematicSynthsesis'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });
    }
  }

  if (showFeedbackExport) {
    if (showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        exportFeedbacks(sessionId);
      },
      label: t('sessions:exportParticipantsFeedbacks'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  if (showParticipantsExport) {
    if (showFeedbackExport || showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        exportAllUsersProduction(sessionId);
      },
      label: t('sessions:exportRawData'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });

    actions.push({
      type: 'button',
      onClick: () => {
        exportUsers(sessionId);
      },
      label: t('sessions:exportTimesheet'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  return (
    <MenuButtons
      menuButton={
        <Button
          size="md"
          disabled={actions.length === 0}
          text={t('sessions:exportData')}
          icon={<ChevronUp className="rotate-180 stroke-2" />}
          iconPlacement="right"
        />
      }
      size="sm"
      verticalGap={false}
      iconPlacement="left"
      actions={actions}
    />
  );
};

export default ExportsButton;
