import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';

import { algoliaConf as conf } from 'config';
import { DocSearchResults, UserType } from 'types/types';
import { isGlobalAdmin } from 'model/users';

export const client = algoliasearch(conf.appId, conf.key);
const docsIndex = client.initIndex(conf.docsIndexName);

export const searchDocs = async (
  query: string,
  userId: string | undefined,
  userType: UserType,
  sessionsIds?: string[],
  communitiesIds?: string[],
  types?: Record<string, boolean>,
  authors?: string[],
  page?: number,
  hitsPerPage?: number
): Promise<DocSearchResults> => {
  let filters = [
    ...(sessionsIds || []).map((sessionId) => ({
      id: sessionId,
      facet: 'sessionId',
    })),
    ...(communitiesIds || []).map((communityId) => ({
      id: communityId,
      facet: 'communityId',
    })),
  ]
    .map(({ id, facet }) => `${facet}:${id}`)
    .join(' OR ');

  if (types) {
    const tagsParts = Object.keys(_.pickBy(types, (val) => val));

    if (tagsParts.length > 0) {
      const tagsFilter = tagsParts.map((tag) => `type:"${tag}"`).join(' OR ');

      filters += ` ${filters === '' ? '' : 'AND '}(${tagsFilter})`;
    }
  }

  if (authors && authors.length > 0) {
    const authorsFilter = `(${authors
      .map((authorId) => `authorsIds:${authorId}`)
      .join(' OR ')})`;

    filters += `${filters.length > 0 ? ' AND ' : ''}${authorsFilter}`;
  }

  if (!isGlobalAdmin(userType)) {
    filters += filters
      ? ` AND ((NOT visibility:Private) OR authorizedIds:${userId})`
      : `(NOT visibility:Private) OR authorizedIds:${userId}`;
  }

  const response: DocSearchResults = await docsIndex.search(query, {
    // attributesToRetrieve: ['objectID'],
    attributesToHighlight: [],
    hitsPerPage: hitsPerPage || 20,
    ...(filters ? { filters } : undefined),
    page,
  });

  return response;
};

export const countsByType = async (
  userId: string | undefined,
  userType: UserType,
  options?: {
    sessionsIds?: string[];
    communitiesIds?: string[];
  },
  query: string = ''
): Promise<Record<string, number>> => {
  let filters = [
    ...(options?.sessionsIds || []).map((sessionId) => ({
      id: sessionId,
      facet: 'sessionId',
    })),
    ...(options?.communitiesIds || []).map((communityId) => ({
      id: communityId,
      facet: 'sessionId',
    })),
  ]
    .map(({ id, facet }) => `${facet}:${id}`)
    .join(' OR ');

  if (!isGlobalAdmin(userType)) {
    filters += filters
      ? ` AND ((NOT visibility:Private) OR authorizedIds:${userId})`
      : `(NOT visibility:Private) OR authorizedIds:${userId}`;
  }

  const resp = await docsIndex.search(query, {
    facets: ['type'],
    hitsPerPage: 0,
    filters,
  });

  return resp.facets?.type || {};
};
