import React, { useMemo } from 'react';
import {
  Activity,
  Activities,
  Productions,
  Criterium,
  GroupingMode,
  Production,
  ProductionMultiplicity,
  Criteria,
  GroupSettings,
  ActivityProp,
  AugmentedCriteriaMode,
  MatchingMode,
  ProductionVisibility,
  PRODUCTION_VISIBILITIES,
  MatchedUsers,
  UserType,
} from 'types/types';
import { useFirebase } from 'react-redux-firebase';
import {
  GroupingParametersGeneric,
  GroupingRendererProps,
} from 'screens/ParametersPane/GroupingParameters';
import { getCriterias } from 'model/criteriaManagement';
import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';
import {
  Form,
  Select,
  MultiSelect,
  stringConvertor,
  Paragraph,
  RangedNumberSelect,
  booleanConvertor,
  OptionalInput,
  optionalIntegerConvertor,
  Input,
  Convertor,
} from 'componentsOld/LiterateForms';

import _ from 'lodash';
import {
  setCanNotPost,
  setNoChooseLimit,
  unsetCanNotPost,
  unsetNoChooseLimit,
  setCriteriaRequired,
  unsetCriteriaRequired,
} from 'model/activitiesManagement';
import { TFunction } from 'i18next';
import { isGlobalAdmin } from 'model/users';
import OneInEachPicker from './OneInEachPicker';
const { Text } = Typography;

const groupingModeConvertor = {
  from: (val: string): GroupingMode => {
    if (_.includes(['All', 'Groups'], val)) {
      return val as GroupingMode;
    } else {
      throw new Error("Can't convert string to group mode");
    }
  },
  to: (val: GroupingMode): string => val,
};

const matchingModeConvertor = {
  from: (val: string): MatchingMode => {
    if (_.includes(['algo', 'empty', 'custom_skema', 'same_as'], val)) {
      return val as MatchingMode;
    } else {
      throw new Error("Can't convert string to matching mode");
    }
  },
  to: (val: MatchingMode): string => val,
};

const matchedUsersConvertor = {
  from: (val: string): MatchedUsers => {
    if (_.includes(['active', 'all', 'activity'], val)) {
      return val as MatchedUsers;
    } else {
      throw new Error("Can't convert string to matching mode");
    }
  },
  to: (val: MatchedUsers): string => val,
};

const NewGroupForm = ({
  sessionId,
  userType,
  grouping,
  onMinPeopleByGroupChange,
  onMaxPeopleByGroupChange,
  onCriteriaChange,
  onChangeMatchedUsers,
  onChangeSourceActivity,
  productionsOptions,
  onChangeMatchingMode,
  onChangeNumberOfGroups,
  onMembersCanChangeAndJoin,
  availableActivities,
  availableTopicsActivities,
  onSameGroupActivityChange,
  onTopicsActivityChange,
}: {
  sessionId: string;
  grouping: {
    mode: 'Groups';
    settings: GroupSettings;
  };
  availableActivities: Activities;
  availableTopicsActivities: Activities;
  onMinPeopleByGroupChange: (val: number) => Promise<void>;
  onMaxPeopleByGroupChange: (val: number) => Promise<void>;
  onCriteriaChange: (criteria: Criteria) => Promise<void>;
  onChangeMatchedUsers: (val: MatchedUsers) => Promise<void>;
  onChangeSourceActivity: (val: string) => Promise<void>;
  productionsOptions: Record<string, { value: Criterium; label: string }>;
  onChangeMatchingMode: (mode: MatchingMode) => Promise<void>;
  onChangeNumberOfGroups: (mode: number) => Promise<void>;
  onMembersCanChangeAndJoin: (val: boolean) => Promise<void>;
  onSameGroupActivityChange: (activityName: string) => Promise<void>;
  onTopicsActivityChange: (topicsActivity: string | undefined) => Promise<void>;
  userType: UserType;
}) => {
  type Crit = { criteria?: Criteria };

  const { t } = useTranslation();
  const criteriaConvertor = (criteriaMode: AugmentedCriteriaMode) => ({
    from: (val: string): Criterium => {
      const res = productionsOptions[val];
      if (!res) {
        throw new Error('no criteria with this name');
      }
      return { ...res.value, mode: criteriaMode };
    },
    to: (val: Criterium): string => val.name,
  });

  const crit = (grouping.settings as Crit).criteria || {};

  const oneOfEachCriteria = _.pickBy(
    crit,
    (val) =>
      val.mode !== 'JOIN_TOGETHER' &&
      val.mode !== 'HARD_SPLIT' &&
      val.mode !== 'ONE_IN_EACH'
  );

  const joinedTogetherCriteria = _.pickBy(
    _.pickBy(crit, (val) => val.mode === 'JOIN_TOGETHER')
  );

  const hardSplitCriteria = _.pickBy(
    _.pickBy(crit, (val) => val.mode === 'HARD_SPLIT')
  );

  const oneInEachCriteria = _.pickBy(
    _.pickBy(crit, (val) => val.mode === 'ONE_IN_EACH')
  );

  const onCriteriaPartialChange =
    (criteriaMode: AugmentedCriteriaMode) => (criteria: Criteria) => {
      const newCriteria = {
        ..._.omit(
          crit,
          Object.keys(
            criteriaMode === 'HARD_SPLIT'
              ? hardSplitCriteria
              : criteriaMode === 'JOIN_TOGETHER'
              ? joinedTogetherCriteria
              : criteriaMode === 'ONE_IN_EACH'
              ? oneInEachCriteria
              : oneOfEachCriteria
          )
        ),
        ...criteria,
      };

      onCriteriaChange(newCriteria);
    };

  const settings = grouping.settings;

  const changeTopicMatching = (val: boolean): Promise<void> => {
    if (!val) {
      return onTopicsActivityChange(undefined);
    }
    const activity = Object.keys(availableTopicsActivities)[0];

    console.log(availableTopicsActivities);

    if (activity) {
      return onTopicsActivityChange(activity);
    }

    return Promise.resolve();
  };

  return (
    <>
      <Paragraph>
        {t('settings:GroupAreCreated') + ' '}
        <Select
          value={settings.mode}
          c={matchingModeConvertor}
          options={[
            {
              value: 'algo',
              label: t('settings:automatically'),
            },
            ...(_.size(availableActivities) > 0
              ? [
                  {
                    value: 'same_as' as MatchingMode,
                    label: t('settings:duplicatedFromActivity'),
                  },
                ]
              : []),
            {
              value: 'empty',
              label: t('settings:empty'),
            },
            ...(grouping.settings.mode === 'custom_skema' ||
            isGlobalAdmin(userType)
              ? [
                  {
                    value: 'custom_skema' as MatchingMode,
                    label: t('settings:CustomSkema'),
                  },
                ]
              : []),
          ]}
          onChange={onChangeMatchingMode}
        />
        {grouping.settings.mode === 'same_as' ? (
          <>
            {t('settings:baseOnThe') + ' '}
            <Select
              value={grouping.settings.activity!}
              options={Object.values(availableActivities).map((act) => ({
                value: act.name,
                label: act.humanName || act.name,
              }))}
              c={stringConvertor}
              onChange={onSameGroupActivityChange}
            />{' '}
            {t('settings:step')}
          </>
        ) : null}
        .
      </Paragraph>

      {settings.mode === 'algo' || settings.mode === 'custom_skema' ? (
        <>
          <Paragraph>
            {t('settings:GroupSizeIs') + ' '}
            <RangedNumberSelect
              start={2}
              end={13}
              value={settings.minPeopleByGroup}
              onChange={(value: number) => {
                onMinPeopleByGroupChange(value);
                if (value > settings.maxPeopleByGroup) {
                  onMaxPeopleByGroupChange(value);
                }
              }}
            />
            {' ' + t('settings:to') + ' '}
            <RangedNumberSelect
              start={2}
              end={13}
              value={settings.maxPeopleByGroup}
              onChange={(value: number) => {
                onMaxPeopleByGroupChange(value);
                if (value < settings.minPeopleByGroup) {
                  onMinPeopleByGroupChange(value);
                }
              }}
            />
            .
          </Paragraph>

          <Paragraph>
            {t('settings:ParticipantsAreMixedIntoGroup') + ' '}
            <MultiSelect
              selection={oneOfEachCriteria}
              c={criteriaConvertor('ONE_OF_EACH')}
              options={Object.values(productionsOptions).filter(
                ({ value: criterium }) =>
                  !_.includes(
                    ['JOIN_TOGETHER', 'HARD_SPLIT', 'ONE_IN_EACH'],
                    settings.criteria?.[criterium.name]?.mode
                  )
              )}
              onChange={onCriteriaPartialChange('ONE_OF_EACH')}
            />
            .
          </Paragraph>
          <Paragraph>
            {t('settings:ParticipantsAreMatchedIntoGroup') + ' '}
            <MultiSelect
              selection={joinedTogetherCriteria}
              c={criteriaConvertor('JOIN_TOGETHER')}
              options={Object.values(productionsOptions).filter(
                ({ value: criterium }) =>
                  settings.criteria?.[criterium.name]?.mode ===
                    'JOIN_TOGETHER' ||
                  settings.criteria?.[criterium.name] === undefined
              )}
              onChange={onCriteriaPartialChange('JOIN_TOGETHER')}
            />
            .
          </Paragraph>
          <OneInEachPicker
            sessionId={sessionId}
            oneInEachCriteria={oneInEachCriteria}
            allCriteria={settings.criteria}
            convertor={criteriaConvertor('ONE_IN_EACH')}
            productionsOptions={productionsOptions}
            onCriteriaChange={onCriteriaPartialChange('ONE_IN_EACH')}
          />
          <Paragraph>
            {t('settings:ParticipantsAreForceSplittedIntoGroup') + ' '}
            <MultiSelect
              maxSelectionSize={1}
              selection={hardSplitCriteria}
              c={criteriaConvertor('HARD_SPLIT')}
              options={Object.values(productionsOptions).filter(
                ({ value: criterium }) =>
                  settings.criteria?.[criterium.name]?.mode === 'HARD_SPLIT' ||
                  settings.criteria?.[criterium.name] === undefined
              )}
              onChange={onCriteriaPartialChange('HARD_SPLIT')}
            />
            .
          </Paragraph>
          <Paragraph>
            {t('settings:Groups')}
            <Select
              value={!!settings.topicsActivityName}
              c={booleanConvertor}
              options={[
                {
                  value: false,
                  label: t('settings:areNot'),
                },
                {
                  value: true,
                  label: t('settings:are'),
                },
              ]}
              onChange={changeTopicMatching}
            />
            {t('settings:dividedByTopics')}.
          </Paragraph>
          <Paragraph>
            <Select
              value={grouping.settings.matchedUsers || 'all'}
              c={matchedUsersConvertor}
              options={[
                {
                  value: 'active',
                  label: t('settings:onlyActive'),
                },
                {
                  value: 'all',
                  label: t('settings:All'),
                },
                ...(_.size(availableActivities) > 0
                  ? [
                      {
                        value: 'activity' as MatchedUsers,
                        label: t('settings:onlyMatchedAtActivity'),
                      },
                    ]
                  : []),
              ]}
              onChange={onChangeMatchedUsers}
            />
            {grouping.settings.matchedUsers === 'activity' &&
            grouping.settings.sourceActivity ? (
              <>
                {' '}
                <Select
                  value={grouping.settings.sourceActivity}
                  options={Object.values(availableActivities).map((act) => ({
                    value: act.name,
                    label: act.humanName || act.name,
                  }))}
                  c={stringConvertor}
                  onChange={onChangeSourceActivity}
                />
              </>
            ) : null}
            {' ' + t('settings:participantsAreMatched')}.
          </Paragraph>
        </>
      ) : grouping.settings.mode === 'empty' ? (
        <Paragraph>
          <RangedNumberSelect
            start={1}
            end={500}
            value={grouping.settings.numberOfGroups || 0}
            onChange={onChangeNumberOfGroups}
          />
          {' ' + t('settings:emptyGroupCreated')}.
        </Paragraph>
      ) : null}
      <Paragraph>
        {t('settings:participantsAreJoinGroup') + ' '}
        <Select
          value={!!grouping.settings.membersCanChangeAndJoin}
          c={booleanConvertor}
          options={[
            {
              value: true,
              label: t('settings:allowed'),
            },
            {
              value: false,
              label: t('settings:notAllowed'),
            },
          ]}
          onChange={onMembersCanChangeAndJoin}
        />
        {' ' + t('settings:toJoinGroups')}.
      </Paragraph>
    </>
  );
};

const GroupRenderer =
  (
    productionsOptions: Record<string, { value: Criterium; label: string }>,
    t: TFunction
  ) =>
  ({
    sessionId,
    grouping,
    availableModes,
    availableActivities,
    availableTopicsActivities,
    onChangeGroupMode,
    onMinPeopleByGroupChange,
    onMaxPeopleByGroupChange,
    onSameGroupActivityChange,
    onCriteriaChange,
    onChangeSourceActivity,
    onChangeMatchedUsers,
    onChangeMatchingMode,
    onChangeNumberOfGroups,
    onMembersCanChangeAndJoin,
    onTopicsActivityChange,
    userType,
  }: GroupingRendererProps): JSX.Element => {
    const groupingModeDescriptions: { [x in GroupingMode]: string } = {
      All: t('settings:notInGroup'),
      Groups: t('settings:inGroup'),
    };

    const availableModeOptions = availableModes.map((el) => ({
      value: el,
      label: groupingModeDescriptions[el],
    }));

    return (
      <Form>
        <Paragraph>
          {t('settings:participantsAre') + ' '}
          <Select
            value={grouping.mode}
            options={availableModeOptions}
            c={groupingModeConvertor}
            onChange={onChangeGroupMode}
          />
          .
        </Paragraph>
        {grouping.mode === 'Groups' && (
          <NewGroupForm
            sessionId={sessionId}
            userType={userType}
            grouping={grouping}
            onMinPeopleByGroupChange={onMinPeopleByGroupChange}
            onMaxPeopleByGroupChange={onMaxPeopleByGroupChange}
            onCriteriaChange={onCriteriaChange}
            onChangeSourceActivity={onChangeSourceActivity}
            onChangeMatchedUsers={onChangeMatchedUsers}
            productionsOptions={productionsOptions}
            onChangeMatchingMode={onChangeMatchingMode}
            onChangeNumberOfGroups={onChangeNumberOfGroups}
            onMembersCanChangeAndJoin={onMembersCanChangeAndJoin}
            availableActivities={availableActivities}
            availableTopicsActivities={availableTopicsActivities}
            onSameGroupActivityChange={onSameGroupActivityChange}
            onTopicsActivityChange={onTopicsActivityChange}
          />
        )}
      </Form>
    );
  };

const productionMultiplicityConvertor = {
  from: (val: string): ProductionMultiplicity => {
    if (_.includes(['One', 'Each'], val)) {
      return val as ProductionMultiplicity;
    } else {
      throw new Error("Can't convert string to production multiplicity");
    }
  },
  to: (val: ProductionMultiplicity): string => val,
};

type PlenaryDocConf =
  | 'one_doc_per_participant_visible'
  | 'one_doc_per_participant_not_visible'
  | 'one_doc_for_all_participants';

const plenaryDocConfConvertorConvertor = {
  from: (val: string): PlenaryDocConf => {
    if (
      _.includes(
        [
          'one_doc_per_participant_not_visible',
          'one_doc_per_participant_visible',
          'one_doc_for_all_participants',
        ],
        val
      )
    ) {
      return val as PlenaryDocConf;
    } else {
      throw new Error("Can't convert string to production multiplicity");
    }
  },
  to: (val: PlenaryDocConf): string => val,
};

const productionVisibilityConvertor = {
  from: (val: string): ProductionVisibility => {
    if (_.includes(PRODUCTION_VISIBILITIES, val)) {
      return val as ProductionVisibility;
    } else {
      throw new Error("Can't convert string to production multiplicity");
    }
  },
  to: (val: ProductionVisibility): string => val,
};

const DocumentForm = ({
  production,
  activityRef,
}: {
  production: Production;
  activityRef: string;
}): JSX.Element => {
  const { t } = useTranslation();
  if (production.type !== 'document') {
    throw new Error('This should only be used with doc production');
  }
  const db = useFirebase();

  const baseRef = `${activityRef}/productions/${production.name}`;

  const changePlenaryDocConf = (conf: PlenaryDocConf) => {
    let dbUpdate: Pick<Production, 'multiplicity' | 'mode'> = {
      multiplicity: 'Each',
      mode: 'ByUser',
    };
    switch (conf) {
      case 'one_doc_per_participant_not_visible':
        break;
      case 'one_doc_per_participant_visible':
        dbUpdate = {
          multiplicity: 'Each',
          mode: 'ByAll',
        };
        break;
      case 'one_doc_for_all_participants':
        dbUpdate = {
          multiplicity: 'One',
          mode: 'ByAll',
        };
        break;
    }

    return db.ref(baseRef).update(dbUpdate);
  };

  const productionElemsToPlenaryDocConf = (
    production: Pick<Production, 'multiplicity' | 'mode'>
  ): PlenaryDocConf => {
    if (production.mode === 'ByUser') {
      return 'one_doc_per_participant_not_visible';
    } else if (
      production.mode === 'ByAll' &&
      production.multiplicity === 'Each'
    ) {
      return 'one_doc_per_participant_visible';
    } else if (
      production.mode === 'ByAll' &&
      production.multiplicity === 'One'
    ) {
      return 'one_doc_for_all_participants';
    } else {
      throw Error('bad things happen, not a valid doc conf');
    }
  };

  const changeMultiplicity = (val: string) => {
    const mode = val as ProductionMultiplicity;
    return db.ref(`${baseRef}/multiplicity`).set(mode);
  };

  const changeVisibility = (val: string) => {
    const mode = val as ProductionVisibility;
    console.log('Here');
    return db.ref(`${baseRef}/visibility`).set(mode);
  };

  return (
    <>
      {/* <FormEditableOption
        label="Mode"
        value={production.multiplicity || 'One'}
        editable={true}
        options={options}
        onChange={changeMultiplicity}
      /> */}
      <Form>
        <Paragraph>
          {t('settings:ThereIsOneDocument') + ' '}
          {production.mode === 'ByGroup' ? (
            <Select
              value={production.multiplicity || 'One'}
              c={productionMultiplicityConvertor as Convertor<string, string>}
              onChange={changeMultiplicity}
              options={[
                { value: 'One', label: t('settings:byGroup') },
                { value: 'Each', label: t('settings:byParticipant') },
              ]}
            />
          ) : (
            <Select
              value={productionElemsToPlenaryDocConf(production)}
              c={plenaryDocConfConvertorConvertor}
              onChange={changePlenaryDocConf}
              options={[
                {
                  value: 'one_doc_per_participant_not_visible',
                  label: t('settings:byParticipantNotVisible'),
                },
                {
                  value: 'one_doc_per_participant_visible',
                  label: t('settings:byParticipantVisble'),
                },
                {
                  value: 'one_doc_for_all_participants',
                  label: t('settings:forAllParticipants'),
                },
              ]}
            />
          )}
          .
        </Paragraph>
        <Paragraph>
          {t('settings:GroupDocumentAre') + ' '}
          <Select
            value={production.visibility || 'Public'}
            c={productionVisibilityConvertor as Convertor<string, string>}
            onChange={changeVisibility}
            options={[
              { value: 'Public', label: t('settings:public') },
              { value: 'Private', label: t('settings:private') },
            ]}
          />
          .
        </Paragraph>
      </Form>
    </>
  );
};

const GeneralForm = ({
  activity,
  activityRef,
}: {
  activity: Activity;
  activityRef: string;
}) => {
  const { t } = useTranslation();
  const db = useFirebase();
  const baseRef = activityRef;
  const setter =
    (prop: ActivityProp) => (val: string | boolean | number | null) => {
      return db.ref(`${baseRef}/${prop}`).set(val);
    };

  return (
    <Form>
      <Paragraph>
        {t('settings:activityNameIs') + ' '}
        <Input
          c={stringConvertor}
          value={
            activity.humanName === null || activity.humanName === undefined
              ? activity.name
              : activity.humanName
          }
          onChange={setter('humanName')}
        />
        .
      </Paragraph>

      <Paragraph>
        {t('settings:activityDurationIs') + ' '}
        <OptionalInput
          c={optionalIntegerConvertor}
          value={activity.duration === undefined ? null : activity.duration}
          onChange={setter('duration')}
        />
        {_.isNumber(activity.duration) && t('settings:mins')}.
      </Paragraph>
    </Form>
  );
};

const PostForm = ({
  activity,
  sessionId,
}: {
  activity: Activity;
  sessionId: string;
}) => {
  const { t } = useTranslation();
  const onNotPostChange = (canNotPost: boolean) => {
    if (canNotPost) {
      setCanNotPost(sessionId, activity);
    } else {
      unsetCanNotPost(sessionId, activity);
    }
  };

  const onCriteriaRequiredChange = (forceCriteriaPost: boolean) => {
    if (forceCriteriaPost) {
      setCriteriaRequired(sessionId, activity);
    } else {
      unsetCriteriaRequired(sessionId, activity);
    }
  };

  return (
    <Form>
      <Paragraph>
        {t('settings:participantsAre') + ' '}
        <Select
          options={[
            {
              value: false,
              label: t('settings:requiredShare'),
            },
            {
              value: true,
              label: t('settings:allowedNotShare'),
            },
          ]}
          c={booleanConvertor}
          value={
            activity.screens.create?.content.template.params?.allowedNotToPost
          }
          onChange={onNotPostChange}
        />
        {' ' + t('settings:toPostShare')}
      </Paragraph>

      <Paragraph>
        {t('settings:criteriaAre') + ' '}
        <Select
          options={[
            {
              value: true,
              label: t('settings:requiredCriteria'),
            },
            {
              value: false,
              label: t('settings:optionalCriteria'),
            },
          ]}
          c={booleanConvertor}
          value={
            activity.screens.create?.content.template.params?.criteriaRequired
          }
          onChange={onCriteriaRequiredChange}
        />
        {' ' + t('settings:toPostCriteria')}
      </Paragraph>
    </Form>
  );
};

const ChooseForm = ({
  activity,
  sessionId,
}: {
  activity: Activity;
  sessionId: string;
}) => {
  const { t } = useTranslation();
  const onChange = (canNotPost: boolean) => {
    if (canNotPost) {
      setNoChooseLimit(sessionId, activity);
    } else {
      unsetNoChooseLimit(sessionId, activity);
    }
  };

  return (
    <Form>
      <Paragraph>
        {t('settings:participantsCanHave') + ' '}
        <Select
          options={[
            {
              value: false,
              label: '3',
            },
            {
              value: true,
              label: t('settings:unlimited'),
            },
          ]}
          c={booleanConvertor}
          value={activity.screens.like?.content.template.params?.noLimit}
          onChange={onChange}
        />
        {' ' + t('settings:votes')}
      </Paragraph>
    </Form>
  );
};

const AdminActivitySettings = ({
  sessionId,
  userType,
  activity,
  activities,
  productions,
}: {
  sessionId: string;
  userType: UserType;
  activity: Activity;
  activities: Activities;
  productions: Record<string, Productions>;
}): JSX.Element => {
  const { t } = useTranslation();
  const baseRef = `sessionsNextTemplates/${sessionId}/activities/${activity.name}`;

  const documentProduction: Production | undefined = Object.values(
    _.pickBy(
      activity.productions || {},
      (production) => production.type === 'document'
    )
  )[0];

  const GroupRendererClass = useMemo(() => {
    const critOptionArray = Object.values(activities)
      .filter((act) => activity.index > act.index)
      .map((activity) => {
        const criteriaActivityName = activity.name;
        const criteriaOptions = _.mapValues(
          getCriterias(activity),
          (production, key) => ({
            value: {
              name: production.name,
              source: `productions.${criteriaActivityName}.${key}.*`,
            },
            label: production.description || production.name,
          })
        );
        return criteriaOptions;
      });

    const criteriaOptions = _.merge({}, ...critOptionArray);

    return GroupRenderer(criteriaOptions, t);
  }, [activities, activity.index, t]);

  return (
    <>
      <Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
        {t('settings:General')}
      </Text>
      <GeneralForm activity={activity} activityRef={baseRef} />
      {'post' in (activity.productions || {}) ? (
        <PostForm sessionId={sessionId} activity={activity} />
      ) : null}
      {activity.name === 'choose' ? (
        <ChooseForm sessionId={sessionId} activity={activity} />
      ) : null}

      {!['post', 'choose', 'feedback'].includes(activity.name) &&
        !('post' in (activity.productions || {})) &&
        activity.productions && (
          <>
            <Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {t('settings:Group')}
            </Text>
            <GroupingParametersGeneric
              sessionId={sessionId}
              userType={userType}
              activity={activity}
              activities={activities}
              productions={productions}
              activityRef={baseRef}
              GroupRenderer={GroupRendererClass}
            />
          </>
        )}

      {documentProduction && (
        <>
          <Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {t('settings:Document')}
          </Text>
          <DocumentForm production={documentProduction} activityRef={baseRef} />
        </>
      )}
    </>
  );
};

export default AdminActivitySettings;
