type ConsentServices = {
  crisp: boolean;
  bugsnag: boolean;
  fullstory: boolean;
};
type ConsentServicesType = keyof ConsentServices;

declare global {
  interface Window {
    $consents: {
      $$completed: boolean;
      $$date: string;
      $$token: string;
    } & ConsentServices;
    openAxeptioCookies: () => void;
  }
}

export const getConsent = (service: ConsentServicesType): boolean => {
  return window.$consents?.[service];
};
