import { Trash } from 'assets/icons';
import RoundedButton from 'components/RoundedButton';
import YesNoModal from 'components/YesNoModal';
import { deleteUser } from 'model/users';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DeleteUserButtonProps = {
  userId: string;
  userName: string;
  userEmail: string;
};

const DeleteUserButton = ({
  userId,
  userName,
  userEmail,
}: DeleteUserButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const [displayModal, setDisplayModal] = useState(false);

  const onDeleteButtonClick = () => {
    deleteUser(userId).catch((err) => console.error(err));
    setDisplayModal(false);
  };

  return (
    <>
      <YesNoModal
        onClickYes={onDeleteButtonClick}
        onClickNo={() => {
          setDisplayModal(false);
        }}
        open={displayModal}
        textYes={t('users:DeleteUser', { name: userName })}
        textNo={t('common:Cancel')}
        critical
      >
        <h1 className="font-semibold mb-2">
          {t('users:DeleteConfirmationTitle', {
            name: userName,
            email: userEmail,
          })}
        </h1>
        <p>
          {t('users:DeleteConfirmation', {
            name: userName,
            email: userEmail,
          })}
        </p>
      </YesNoModal>
      {/* <Modal
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        body={
          <div>
            <p>
              {t('users:DeleteConfirmation', {
                name: userName,
                email: userEmail,
              })}
            </p>
            <div className="space-x-8">
              <Button
                text={t('common:Cancel')}
                onClick={() => {
                  setDisplayModal(false);
                }}
              />
              <Button
                color="danger"
                text={t('users:DeleteUser', { name: userName })}
                onClick={onDeleteButtonClick}
              />
            </div>
          </div>
        }
      /> */}
      <RoundedButton
        color="danger"
        tooltipText={`Delete ${userName}`}
        onClick={() => setDisplayModal(true)}
      >
        <Trash />
      </RoundedButton>
    </>
  );
};

export default DeleteUserButton;
