import * as React from 'react';

function SvgQuote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" {...props}>
      <g fill="currentColor">
        <circle cx={18.5} cy={31.5} r={5.5} />
        <path d="M18.5 38c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z" />
      </g>
      <g fill="currentColor">
        <circle cx={35.5} cy={31.5} r={5.5} />
        <path d="M35.5 38c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z" />
      </g>
      <path
        fill="currentColor"
        d="M13 32a1 1 0 01-1-1c0-7.72 6.28-14 14-14a1 1 0 110 2c-6.617 0-12 5.383-12 12a1 1 0 01-1 1zm17 0a1 1 0 01-1-1c0-7.72 6.28-14 14-14a1 1 0 110 2c-6.617 0-12 5.383-12 12a1 1 0 01-1 1z"
      />
    </svg>
  );
}

export default SvgQuote;
