import { functions } from './firebase';
import i18n from 'services/i18n';

const getCustomToken = functions.httpsCallable('auth-getCustomToken');
const createCreds = functions.httpsCallable('auth-createCredentials');
const sendPasswordRstEmail = functions.httpsCallable(
  'auth-sendPasswordResetEmail'
);
const verifyPasswordRstCode = functions.httpsCallable(
  'auth-verifyPasswordResetCode'
);
const confirmPasswordRst = functions.httpsCallable('auth-confirmPasswordReset');
const regenerateCustomTkn = functions.httpsCallable(
  'auth-regenerateCustomToken'
);
const updateMail = functions.httpsCallable('auth-updateEmail');
const updatePwd = functions.httpsCallable('auth-updatePassword');

export type AuthErrorCode =
  | 'auth/invalid-email'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/user-mismatch'
  | 'auth/wrong-password'
  | 'auth/email-already-in-use'
  | 'auth/unknown-error'
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/weak-password'
  | 'auth/requires-recent-login';

const handleError = (e: Error) => {
  let code: AuthErrorCode = 'auth/unknown-error';
  switch (e.message) {
    case 'auth/wrong-password':
      code = 'auth/wrong-password';
      break;
    case 'auth/user-not-found':
      code = 'auth/user-not-found';
      break;
    case 'auth/user-mismatch':
      code = 'auth/user-mismatch';
      break;
    case 'auth/email-already-in-use':
      code = 'auth/email-already-in-use';
      break;
    case 'auth/expired-action-code':
      code = 'auth/expired-action-code';
      break;
    case 'auth/invalid-action-code':
      code = 'auth/invalid-action-code';
      break;
    case 'auth/weak-password':
      code = 'auth/weak-password';
      break;
    case 'auth/requires-recent-login':
      code = 'auth/requires-recent-login';
      break;
    default:
      code = 'auth/unknown-error';
  }
  return new AuthError(code, 'An error occured.');
};

export class AuthError extends Error {
  code: AuthErrorCode;

  constructor(code: AuthErrorCode, message: string) {
    super(message);
    this.name = 'AuthError';
    this.code = code;
  }
}

export const getAuthToken = async (
  email: string,
  password: string
): Promise<string> => {
  try {
    const result = await getCustomToken({ email, password });
    return result.data;
  } catch (e: any) {
    throw handleError(e);
  }
};

export const createCredentials = async (
  email: string,
  password: string,
  name: string
): Promise<string> => {
  try {
    const result = await createCreds({ email, password, name });
    return result.data;
  } catch (e: any) {
    throw handleError(e);
  }
};

export const sendPasswordResetEmail = async (
  email: string,
  settings: {
    url: string;
  }
): Promise<void> => {
  try {
    await sendPasswordRstEmail({
      email,
      language: i18n.language,
      continueUrl: settings.url,
    });
  } catch (e: any) {
    throw handleError(e);
  }
};

export const verifyPasswordResetCode = async (
  code: string
): Promise<string> => {
  try {
    const result = await verifyPasswordRstCode({
      code,
    });
    return result.data;
  } catch (e: any) {
    throw handleError(e);
  }
};

export const confirmPasswordReset = async (
  code: string,
  newPassword: string
): Promise<string> => {
  try {
    const result = await confirmPasswordRst({
      code,
      newPassword,
    });
    return result.data;
  } catch (e: any) {
    throw handleError(e);
  }
};

export const regenerateCustomToken = async (
  token: string,
  email: string,
  password: string
): Promise<string> => {
  try {
    const result = await regenerateCustomTkn({
      currentToken: token,
      email,
      password,
    });
    return result.data;
  } catch (e: any) {
    throw handleError(e);
  }
};

export const updateEmail = async (
  token: string,
  currentEmail: string,
  newEmail: string
): Promise<void> => {
  try {
    await updateMail({
      currentToken: token,
      currentEmail,
      newEmail,
    });
  } catch (e: any) {
    throw handleError(e);
  }
};

export const updatePassword = async (
  token: string,
  currentEmail: string,
  newPassword: string
): Promise<void> => {
  try {
    await updatePwd({
      currentToken: token,
      currentEmail,
      newPassword,
    });
  } catch (e: any) {
    throw handleError(e);
  }
};
