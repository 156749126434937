import { PlateProps, TNode } from '@udecode/plate';
import Editor, { CustomEditorProps } from 'frameworks/plate/Editor';
import React, { useState, useMemo, useRef } from 'react';
import { createEditor } from 'slate';
// import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import './slate.css';

const defaultValue = [
  {
    type: 'p',
    text: 'Loading ...',
  },
];

export type FakeEditorProps = PlateProps &
  CustomEditorProps & {
    initialValue: TNode[];
  };

const Client = ({ initialValue, ...props }: FakeEditorProps) => {
  const [value, setValue] = useState(initialValue || defaultValue);
  const editorRef = useRef(null);

  const editor = useMemo(() => {
    const slateEditor = withReact(
      // withHistory(
      createEditor() as any
      //)
    );

    setValue(initialValue);

    return slateEditor;
  }, [initialValue]);

  return (
    <div ref={editorRef} className="overflow-visible">
      <Editor
        editor={editor}
        value={value}
        saving={false}
        // decorate={decorate}
        onChange={(value: TNode[]) => setValue(value)}
        {...props}
      />
    </div>
  );
};

export default Client;
