import React from 'react';

export type ProgressBarProps = {
  progress: number;
  color?: 'primary' | 'danger' | 'warning';
};

const ProgressBar = ({
  progress,
  color = 'primary',
}: ProgressBarProps): JSX.Element => {
  const [mainColor, softColor] =
    color === 'danger'
      ? ['bg-danger', 'bg-danger-soft']
      : color === 'warning'
      ? ['bg-warning', 'bg-warning-soft']
      : ['bg-primary', 'bg-primary-soft'];
  return (
    <div className="relative pt-1">
      <div className={`flex h-2 overflow-hidden ${softColor}`}>
        <div
          style={{ width: `${progress * 100}%` }}
          className={`flex bg-primary shadow-none ${mainColor}`}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
