import { storage } from './firebase';
import { generateRandomString } from 'utils/utils';
import _ from 'lodash';

export const getTemporaryUrl = (fileRef: string) =>
  storage.ref(fileRef).getDownloadURL();

export const upload = async (
  prefix: string,
  file: File,
  startUploading: (filename: string) => void,
  successedUploading: (filename: string, url: string) => void,
  failedUploading: (filename: string) => void
) => {
  const ref = storage.ref(`${prefix}/${generateRandomString(8)}-${file.name}`);
  try {
    startUploading(file.name);
    const uploadTask = ref.put(file);

    await uploadTask;

    const url = await ref.getDownloadURL();
    successedUploading(file.name, url);

    return url;
  } catch (e) {
    console.error(e);
    failedUploading(file.name);
  }
};

const encodeUrlToBlob = async (url: string) => {
  const res = await fetch(url);

  return res.blob();
};

export const uploadShort = async (
  prefix: string,
  file: string | ArrayBuffer
) => {
  console.log('Congrés');
  console.log(file);

  const ref = storage.ref(`${prefix}/${generateRandomString(16)}`);

  const blob = _.isString(file) ? await encodeUrlToBlob(file) : file;

  const uploadTask = ref.put(blob);

  await uploadTask;

  const url: string = await ref.getDownloadURL();

  console.log(url);

  return url;
};

export const uploadSimple = async (prefix: string, file: File) => {
  const ref = storage.ref(`${prefix}/${generateRandomString(16)}-${file.name}`);
  const uploadTask = ref.put(file);

  await uploadTask;

  const url = await ref.getDownloadURL();
  return url;
};

export const deleteFile = (fileRef: string) =>
  storage.ref(fileRef).delete().catch(console.error);
