import * as React from 'react';

function SvgSkeptical(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#FCEA2B"
        d="M36 13c-12.682 0-23 10.318-23 23s10.318 23 23 23 23-10.318 23-23-10.318-23-23-23z"
      />
      <circle
        cx={36}
        cy={36}
        r={23}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M23.07 27.743c.414-.344 2.246-1.791 4.91-1.636 2.16.126 3.61 1.233 4.09 1.636M48.93 27.095c-.414.344-2.246 1.792-4.91 1.637-2.16-.126-3.61-1.234-4.09-1.637"
      />
      <path d="M30 35a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3M48 35a3.001 3.001 0 01-6 0c0-1.655 1.345-3 3-3s3 1.345 3 3" />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M29.132 47.139c4.6-1.417 9.829-1.47 13.736 0"
      />
    </svg>
  );
}

export default SvgSkeptical;
