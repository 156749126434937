import Logo from 'components/Logo';
import React from 'react';
import { focus } from 'services/helpChat';

const ErrorView = () => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="relative flex min-h-screen w-full flex-col items-center bg-surfaces-soft p-4">
      <div className="absolute top-4 left-4">
        <Logo className="h-8 fill-current text-primary" />
      </div>
      <div className="flex-grow" />
      <p className="mb-4 max-w-xs rotate-90 text-center text-7xl">:(</p>
      <div className="max-w-3xl space-y-8 text-xl font-medium text-black-soft">
        <div>
          <p>Une erreur est survenue et nous en sommes désolés.</p>
          <p>An error occured and we are really sorry.</p>
        </div>
        <div>
          <p>
            S'il vous plaît, essayez de{' '}
            <button
              className="cursor-pointer font-medium text-primary underline hover:no-underline"
              onClick={(event) => {
                event.preventDefault();
                reload();
              }}
            >
              recharger la page
            </button>
            .
          </p>

          <p>
            Please, try{' '}
            <button
              className="cursor-pointer font-medium text-primary underline hover:no-underline"
              onClick={(event) => {
                event.preventDefault();
                reload();
              }}
            >
              reload the page
            </button>
            .
          </p>
        </div>
        <div>
          <p>
            Si le problème persiste,{' '}
            <button
              className="cursor-pointer font-medium text-primary underline hover:no-underline"
              onClick={(event) => {
                event.preventDefault();
                focus();
              }}
            >
              contactez le support
            </button>
            .
          </p>
          <p>
            If the problem persists,{' '}
            <button
              className="cursor-pointer font-medium text-primary underline hover:no-underline"
              onClick={(event) => {
                event.preventDefault();
                focus();
              }}
            >
              contact support
            </button>
            .
          </p>
        </div>
      </div>
      <div className="flex-grow" />
      <div className="flex-grow" />
    </div>
  );
};

export default ErrorView;
