import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import css from './index.module.css';
import { ChooseGroupModal } from 'componentsOld/Group/AdminGroupActions';
import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { usePrevious } from 'utils/utils';

const AdminGroupPost = ({
  tags,
  votes,
  content,
  criteria,
  active,
  isTemporaryUser,
  onDelete,
  onSelectPost,
  onSelectTag,
  creator,
  creatorEmail,
  creatorActive,
  onMove,
  groups,
  groupPrefix,
  highlighted,
}) => {
  const { t } = useTranslation();
  const criteriaArray = criteria && Object.entries(criteria);
  const [viewGroupsModal, setViewGroupsModal] = useState(false);
  const ref = useRef();
  const prevHighlighted = usePrevious(highlighted);

  const votesArray = Object.values(votes);

  useEffect(() => {
    if (ref.current && highlighted && !prevHighlighted) {
      ref.current.scrollIntoView();
    }
  }, [prevHighlighted, highlighted, ref]);

  return (
    <div>
      <div
        className={`m-2 max-w-[300px] cursor-pointer rounded-md p-2  ${
          active && highlighted
            ? 'bg-amber-200 ring-4 ring-inset ring-violet-400 '
            : active
            ? 'bg-violet-400'
            : highlighted
            ? 'bg-amber-200'
            : 'bg-gray-200'
        }`}
        onClick={onSelectPost}
        ref={ref}
      >
        <div
          className={
            Array.isArray(tags) && tags.length > 0
              ? css.header
              : css.headerdelete
          }
        >
          {/* <TagList tags={tags} onSelectTag={onSelectTag} /> */}
          <div style={{ width: '100%', display: 'flex', margin: '8px' }}>
            {onMove && (
              <>
                <Tooltip title={t('facilitator:MoveToAnotherGroup')}>
                  <SendOutlined
                    style={{ fontSize: '16px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setViewGroupsModal(true);
                    }}
                  />
                </Tooltip>
                {viewGroupsModal && (
                  <ChooseGroupModal
                    active
                    groups={groups}
                    groupPrefix={groupPrefix}
                    onExit={() => setViewGroupsModal(false)}
                    onClickGroup={(group) => onMove(group.id, group.topicId)}
                    canAdd
                  />
                )}
              </>
            )}
            <div style={{ flex: 2 }}></div>
            {onDelete && (
              <Tooltip title={t('facilitator:RemoveFromGroup')}>
                <DeleteOutlined
                  style={{ fontSize: '16px' }}
                  onClick={onDelete}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className={css.body}>
          <p className={css.content}>{content.slice(0, 30)}...</p>
          {isTemporaryUser && (
            <p className={css.temporary}>
              <i>{`Does not have access to a computer`}</i>
            </p>
          )}
        </div>
        <div>
          {criteria && criteriaArray.length > 0 && (
            <div className={css.criteria}>
              {criteriaArray.map(([key, val]) => (
                <div key={key}>{`${key}: ${val}`}</div>
              ))}
            </div>
          )}
        </div>
        <div>
          {votes && (
            <p className={css.votes}>
              {votesArray.length > 0
                ? `${votesArray.join(', ')} ${t(
                    'facilitator:participantLikeThis',
                    { count: votesArray.length }
                  )}`
                : t('facilitator:nobodyLikeThis')}
            </p>
          )}
        </div>
        <div>
          <div className="flex items-center space-x-2">
            {creator && <p className="font-bold">{'By ' + creator}</p>}
            <div
              className={`h-3 w-3 rounded-full ${
                creatorActive ? 'bg-[#01da1c]' : 'bg-[#ff6131]'
              }`}
            />
          </div>
          <p className="truncate text-sm">{creatorEmail}</p>
        </div>
      </div>
    </div>
  );
};

export default AdminGroupPost;
