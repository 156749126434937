import React from 'react';

import Listbox from 'components/Listbox';
import _ from 'lodash';

type DurationPickerProps = {
  hours: number;
  minutes: number;
  onChange: (hours: number, minutes: number) => void;
  className?: string;
  errorMode?: boolean;
  maxHours?: number;
  maxMinutes?: number;
  stepHours?: number;
  stepMinutes?: number;
  readOnly?: boolean;
};

const DurationPicker = ({
  hours,
  minutes,
  onChange,
  errorMode,
  className,
  maxHours = 24,
  maxMinutes = 60,
  stepHours = 1,
  stepMinutes = 5,
  readOnly = false,
}: DurationPickerProps) => {
  const hoursOptions = _.range(0, maxHours, stepHours).map((val) => ({
    value: val,
    description: `${val}h`,
  }));

  const minutesOptions = _.range(0, maxMinutes, stepMinutes).map((val) => ({
    value: val,
    description: `${val}min`,
  }));
  return (
    <div
      className={`flex w-full flex-row flex-wrap items-center space-x-2 ${className} `}
    >
      {readOnly ? (
        <p className="text-lg">{`${hours || 0}h ${minutes || 0}min`}</p>
      ) : (
        <>
          <Listbox
            className="max-w-min"
            items={hoursOptions}
            defaultValue={hours || 0}
            setValue={(value) => onChange(value, minutes)}
            errorMode={errorMode}
            compact
          />
          <Listbox
            className="max-w-min"
            items={minutesOptions}
            defaultValue={minutes || 0}
            setValue={(value) => onChange(hours, value)}
            errorMode={errorMode}
            compact
          />
        </>
      )}
    </div>
  );
};

export default DurationPicker;
