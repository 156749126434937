import React, { useState, useEffect, useCallback, useMemo } from 'react';

import ContentList from './ContentList';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import ContentPreview from './ContentPreview';
import SearchForm, { SearchRequest } from './SearchForm';

import { countsByType, searchDocs } from 'services/searching';
import { Communities, DocSearchResults, User, UserType } from 'types/types';
import { WithId } from 'types/typesUtils';
import { FullDocMeta } from './types';
import { usePrevious } from 'utils/utils';
import LinkedDocs from './LinkedDocs';

import { Typography, Divider, Pagination } from 'antd';
import { useFullDocs } from './hooks';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { isGlobalAdmin } from 'model/users';

const { Title } = Typography;

const SEARCH_DELAY = 200;

const debouncedSearch = _.debounce(
  async (
    query: SearchRequest,
    userId: string | undefined,
    userType: UserType,
    cb: (results: DocSearchResults, count: Record<string, number>) => void,
    sessionsIds?: string[],
    communitiesIds?: string[]
  ) => {
    let commIds: string[] | undefined = Object.keys(query.communitiesIds || {});
    if (commIds.length === 0) {
      commIds = communitiesIds;
    }

    const [results, count] = await Promise.all([
      searchDocs(
        query.query,
        userId,
        userType,
        sessionsIds,
        commIds,
        {
          [query.selectedTag || '']: true,
        },
        undefined,
        query.page
      ),
      countsByType(
        userId,
        userType,
        { sessionsIds, communitiesIds: commIds },
        query.query
      ),
    ]);
    cb(results, count);
  },
  SEARCH_DELAY
);

const ContentExplorer = ({
  user,
  sessionsIds,
  communitiesIds,
  userType = 'participant',
  title,
}: // filter
{
  sessionsIds?: string[];
  communitiesIds?: string[];
  user: WithId<User> | undefined;
  userType: UserType;
  title?: string;
  // filter?: (doc: WithId<DocMeta>) => boolean;
}) => {
  const [selectedDoc, setSelectedDoc] = useState<FullDocMeta | null>(null);
  const [searchResults, setSearchResults] = useState<DocSearchResults | null>(
    null
  );
  const [search, setSearch] = useState<SearchRequest>({
    query: '',
    selectedTag: undefined,
  });
  const [availableTags, setAvailableTags] = useState<Record<
    string,
    number
  > | null>(null);

  const [deletedDocs, setDeletedDocs] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const tags = await countsByType(user?.id, userType, {
          sessionsIds,
          communitiesIds,
        });
        setAvailableTags(tags);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [sessionsIds, communitiesIds, user?.id, userType]);

  const closePreview = () => {
    setSelectedDoc(null);
  };

  const docs = useFullDocs(searchResults?.hits || []);

  useFirebaseConnect([
    {
      path: `communities`,
    },
  ]);

  const communities: Communities | null = useSelector(
    ({ firebase: { data } }: any) => data.communities
  );

  const availableCommunities: Communities = useMemo(
    () =>
      communities
        ? _.pickBy(
            communities,
            (_val, key) =>
              isGlobalAdmin(userType) || _.includes(communitiesIds, key)
          )
        : {},
    [communities, communitiesIds, userType]
  );

  const fullFilter = (doc: FullDocMeta) =>
    doc.type &&
    !_.includes(
      ['La synthèse du groupe de John Doe', 'Expérience de John Doe'],
      doc.title
    ) &&
    !_.includes(deletedDocs, doc.objectID);
  // (!filter || (filter && filter(doc)));

  const makeSearch = useCallback(
    (query: SearchRequest) => {
      debouncedSearch(
        query,
        user?.id,
        userType,
        (results, counts) => {
          setSearchResults(results);
          setAvailableTags(counts);
        },
        sessionsIds,
        communitiesIds
      );
    },
    [sessionsIds, communitiesIds, user?.id, userType]
  );

  const onSearchChange = useCallback(
    (query: SearchRequest) => {
      setSearch(query);
      makeSearch(query);
    },
    [makeSearch]
  );

  const resetSearch = () => {
    const query = {
      query: '',
      selectedTag: search.selectedTag,
    };
    onSearchChange(query);
  };

  const addDelectedDoc = (docId: string) => {
    setDeletedDocs([...deletedDocs, docId]);
    if (availableTags && search.selectedTag) {
      setAvailableTags({
        ...availableTags,
        [search.selectedTag]: availableTags[search.selectedTag] - 1,
      });
    }
  };
  const oldAvailableTags = usePrevious(availableTags);

  useEffect(() => {
    if (!_.isEqual(oldAvailableTags, availableTags) && availableTags) {
      const defaultKey: string | undefined = Object.keys(availableTags)[0];

      if (defaultKey) {
        const query = {
          ...search,
          selectedTag: defaultKey,
          page: 0,
        };
        onSearchChange(query);
      }
    }
  }, [availableTags, oldAvailableTags, search, onSearchChange]);

  // return !isLoaded(users) ||
  // !isLoaded(...Object.values(users || [])) ||
  return !availableTags ? (
    <LoadingOutlined style={{ fontSize: '32px' }} />
  ) : selectedDoc ? (
    <>
      <ContentPreview
        doc={selectedDoc}
        close={closePreview}
        user={user}
        userType={userType}
        onDeleteDoc={addDelectedDoc}
      />
      <Divider />
      <Title level={3}>Linked documents</Title>
      <LinkedDocs
        sessionId={selectedDoc.sessionId}
        userId={user?.id}
        userType={userType}
        doc={selectedDoc}
        selectDoc={setSelectedDoc}
      />
    </>
  ) : (
    <>
      <SearchForm
        onSearchChange={onSearchChange}
        onResetSearch={resetSearch}
        availableTags={availableTags!}
        search={search}
        communities={availableCommunities}
        title={title}
      >
        <ContentList
          docs={docs.filter(fullFilter)}
          selectDoc={setSelectedDoc}
          user={user}
        />
        {searchResults && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              simple
              onChange={(val) => onSearchChange({ ...search, page: val - 1 })}
              defaultCurrent={(search.page || 0) + 1}
              total={searchResults.hitsPerPage * searchResults.nbPages}
              pageSize={searchResults.hitsPerPage}
            />
          </div>
        )}
      </SearchForm>
    </>
  );
};

export default ContentExplorer;
