import * as React from 'react';

function SvgHeartEyes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={36} cy={36} r={23} fill="#FCEA2B" />
      <path
        fill="#D22F27"
        d="M26.5 27.438c-1.266-3.354-6.442-3.568-6.118 1.318.043.649.281 1.273.642 1.814l5.318 6.422 5.22-6.266c.58-.696.923-1.578.906-2.485-.086-4.352-5.051-4.035-6.128-.804M45.801 27.438c-1.255-3.354-6.387-3.568-6.066 1.318.043.649.28 1.273.636 1.814l5.274 6.422 5.178-6.266c.575-.696.915-1.578.897-2.485-.085-4.352-5.009-4.035-6.076-.804"
      />
      <path
        fill="#FFF"
        d="M48.586 42.673c0 5.63-4.178 10.105-12.554 10.105-8.374 0-12.607-4.489-12.607-10.105 0 0 13.025 3.032 25.16 0z"
      />
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <circle cx={36} cy={36} r={23} />
        <path d="M48.586 42.673c0 5.63-4.178 10.105-12.554 10.105-8.374 0-12.607-4.489-12.607-10.105 0 0 13.025 3.032 25.16 0zM26.5 27.438c-1.266-3.354-6.442-3.568-6.118 1.318.043.649.281 1.273.642 1.814l5.318 6.422h0l5.22-6.266c.58-.696.923-1.578.906-2.485-.086-4.352-5.051-4.035-6.128-.804M45.801 27.438c-1.255-3.354-6.387-3.568-6.066 1.318.043.649.28 1.273.636 1.814l5.274 6.422h0l5.178-6.266c.575-.696.915-1.578.897-2.485-.085-4.352-5.009-4.035-6.076-.804" />
      </g>
    </svg>
  );
}

export default SvgHeartEyes;
