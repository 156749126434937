import Alert from 'components/Alert';
import Button from 'components/Button';
import { recordAllowSynthesis } from 'model/sessions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionRecord } from 'types/types';
import { Nullable } from 'types/typesUtils';

export type DebriefWarningInfoProps = {
  sessionId: string;
  userId: string;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const DebriefWarningInfo = ({
  sessionId,
  userId,
  allowAutomatedSynthesis,
  marginClasses,
}: DebriefWarningInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${marginClasses} space-y-2`}>
      {!allowAutomatedSynthesis ? (
        <Alert
          className="p-4 mx-12"
          type="warning"
          title={t('sessions:DebriefWarningTitle')}
          message={<p>{t('sessions:DebriefWarningMessage')}</p>}
          actionButton={
            <Button
              onClick={() => {
                recordAllowSynthesis(sessionId, userId);
              }}
              text={t('sessions:ItsDoneAndLaunch')}
            />
          }
        />
      ) : null}
    </div>
  );
};

export default DebriefWarningInfo;
