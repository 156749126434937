import React from 'react';
import { Select } from 'antd';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { User, UserType } from 'types/types';

const { Option } = Select;

const UsersSelector = ({
  selected,
  onChange,
  filter,
}: {
  selected: Record<string, true>;
  onChange: (
    val: Record<string, true>,
    old: Record<string, true>
  ) => Promise<void>;
  filter?: (id: string, user: User, type: UserType) => boolean;
}): JSX.Element => {
  useFirebaseConnect([{ path: '/users' }, { path: '/typeForUser' }]);

  const users: Record<string, User> | null = useSelector(
    (state: any) => state.firebase.data.users
  );
  const typeOfUsers: Record<string, UserType> = useSelector(
    (state: any) => state.firebase.data.typeForUser || {}
  );

  const availableUsers = _.pickBy(users || {}, (user, key) => {
    const type = typeOfUsers?.[key];
    return !filter || filter(key, user, type);
  });

  const selectedUsers = Object.keys(selected || {});

  selectedUsers.forEach((key) => {
    const currentUser = users?.[key];
    if (selected && currentUser) {
      availableUsers[key] = currentUser;
    }
  });

  const availableUsersArray = Object.entries(availableUsers).map(
    ([key, { email }]) => ({
      value: key,
      email,
    })
  );

  return isLoaded(users) ? (
    <Select
      style={{ minWidth: '160px' }}
      showSearch
      value={selectedUsers}
      mode="multiple"
      onChange={(users) => {
        const usersRecord = users.reduce(
          (prec: Record<string, true>, userId) => {
            prec[userId] = true;
            return prec;
          },
          {}
        );
        return onChange(usersRecord, selected);
      }}
      filterOption={(input, option) => {
        return option?.data?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {availableUsersArray.map(({ value, email }) => (
        <Option key={value} value={value} data={email}>
          {email}
        </Option>
      ))}
    </Select>
  ) : (
    <></>
  );
};

export default UsersSelector;
