import { Node } from 'slate';
import { toSlatePath } from '../utils/convert';
/**
 * Translates a Yjs map event into a slate operations.
 *
 * @param event
 */
export default function translateMapEvent(editor, event) {
    const targetPath = toSlatePath(event.path);
    const targetSyncElement = event.target;
    const targetElement = Node.get(editor, targetPath);
    const keyChanges = Array.from(event.changes.keys.entries());
    const newProperties = Object.fromEntries(keyChanges.map(([key, info]) => [
        key,
        info.action === 'delete' ? null : targetSyncElement.get(key),
    ]));
    const properties = Object.fromEntries(keyChanges.map(([key]) => [key, targetElement[key]]));
    return [{ type: 'set_node', newProperties, properties, path: targetPath }];
}
