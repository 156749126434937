import * as React from 'react';

function SvgWap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 353 148" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M188.569 61.895L160.471 148h-20.16l-17.262-52.494L105.786 148h-20.16L57.528 61.895h22.554l16.002 53.123 18.144-53.123h17.641l18.144 53.123 16.002-53.123h22.554zm128.143 0c7.391 0 13.818 1.28 19.279 3.84 5.46 2.56 9.66 6.189 12.6 10.889 2.94 4.7 4.409 10.238 4.409 16.616 0 6.63-1.533 12.379-4.599 17.246-3.066 4.868-7.453 8.624-13.166 11.267-5.713 2.644-12.432 3.965-20.159 3.965h-11.594v21.778H282.19v-85.6h34.522zm-72.072 70.873h-36.289l-5.67 14.728h-22.68l36.414-85.6h20.413l36.288 85.6h-22.68l-5.796-14.728zm-6.93-17.75l-11.214-28.827-11.214 28.827h22.428zM18.103 104.95l6.942 7.688 25.952-23.39-6.942-7.688-25.952 23.39zm298.231 2.138c4.703 0 8.463-1.259 11.278-3.777 2.814-2.518 4.221-5.874 4.221-10.07 0-3.861-1.407-6.945-4.221-9.253-2.815-2.308-6.575-3.462-11.278-3.462h-12.852v26.562h12.852zM1.719 49.555L0 59.635l39.122 6.659 1.719-10.08-39.122-6.66zM36.582 0l-8.935 5.335 23.495 39.276 8.935-5.335L36.582 0zm62.156 10.325l-9.434-3.966L75.75 38.544l9.434 3.966 13.555-32.185z" />
    </svg>
  );
}

export default SvgWap;
