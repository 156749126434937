import React from 'react';

import { Collapse, Typography, Switch } from 'antd';

import {
  Session,
  SessionTemplate,
  SessionProp,
  Activity,
  Activities,
  ActivityProp,
  Screen,
  ScreenProp,
  DataPath,
  Productions,
  RoleBar,
  UserType,
} from 'types/types';

import { Selection } from 'types/ui';

import { useFirebase } from 'react-redux-firebase';
import DateTimeInterval from 'componentsOld/DateTimeInterval';
import moment, { Moment } from 'moment';
import EditableTable from 'componentsOld/EditableTable';
import { HARDCODED_SCREENS } from 'constants/HardCodedScreens';
import { dataPathToRef } from 'model/dataPathUtils';
import FormLine from './FormLine';
import GroupingParameters from './GroupingParameters';
import ProductionsForm from './ProductionsForm';
import UsersSelector from 'componentsOld/UsersSelector';
import { useFacilitators, useOrganizers } from 'model/dataHooks';
import CommunitySelector from 'componentsOld/CommunitySelector';
import _ from 'lodash';

const { Panel } = Collapse;
const { Text } = Typography;

const SessionParameters = ({
  sessionId,
  session,
  userId,
  role,
}: {
  sessionId: string;
  session: Session;
  userId: string;
  role: RoleBar;
}): JSX.Element => {
  const db = useFirebase();

  const baseRef = `/sessionsNext/${sessionId}`;
  const setter = (prop: SessionProp) => (val: string | number[] | boolean) => {
    return db.ref(`${baseRef}/${prop}`).set(val);
  };

  const [facilitators, facilitatorsLoaded, setFacilitators] =
    useFacilitators(sessionId);

  const [organizers, organizersLoaded, setOrganizers] =
    useOrganizers(sessionId);

  return (
    <>
      <FormLine label="Session Title">
        <Text editable={{ onChange: setter('title') }}>{session.title}</Text>
      </FormLine>
      <FormLine label="ScheduledAt">
        <DateTimeInterval
          editable
          scheduleDateTime={session.scheduledAt.map((timestamp) =>
            moment(timestamp)
          )}
          submitValue={(mDate: [Moment, Moment]) =>
            setter('scheduledAt')(mDate.map((val) => val.valueOf()))
          }
        />
      </FormLine>
      <FormLine label="Location">
        <Text editable={{ onChange: setter('location') }}>
          {session.location}
        </Text>
      </FormLine>
      {(role === 'superadmin' || role === 'organizer') && (
        <>
          <FormLine label="Community">
            <CommunitySelector
              userId={userId}
              role={role}
              selected={session.communityId}
              onChange={setter('communityId')}
            />
          </FormLine>
          {organizersLoaded && (
            <FormLine label="Organizers">
              <UsersSelector selected={organizers} onChange={setOrganizers} />
            </FormLine>
          )}
          {facilitatorsLoaded && (
            <FormLine label="Facilitators">
              <UsersSelector
                selected={facilitators}
                onChange={setFacilitators}
              />
            </FormLine>
          )}
        </>
      )}
      <FormLine label="Mail automation">
        <Switch
          onChange={setter('usesMailAutomation')}
          checked={session.usesMailAutomation}
          // size="small"
        />
      </FormLine>
      <FormLine label="Enable steps automatically">
        <Switch
          onChange={setter('enableStepsAutomatically')}
          checked={!!session.enableStepsAutomatically}
          // size="small"
        />
      </FormLine>
      <FormLine label="Group prefix">
        <Text editable={{ onChange: setter('groupPrefix') }}>
          {session.groupPrefix}
        </Text>
      </FormLine>
      <FormLine label="Internal visio">
        <Switch
          onChange={setter('internalVisio')}
          checked={session.internalVisio}
          // size="small"
        />
      </FormLine>
    </>
  );
};

const ActivityParameters = ({
  sessionId,
  userType,
  activity,
  activities,
  productions,
}: {
  sessionId: string;
  userType: UserType;
  activity: Activity;
  activities: Activities;
  productions: Record<string, Productions>;
}) => {
  const db = useFirebase();

  const baseRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}`;
  const setter =
    (prop: ActivityProp) => (val: string | boolean | number | null) => {
      return db.ref(`${baseRef}/${prop}`).set(val);
    };

  return (
    <Collapse>
      <Panel key="1" header="Informations">
        <FormLine label="Name">
          <Text editable={{ onChange: setter('humanName') }}>
            {activity.humanName || activity.name}
          </Text>
        </FormLine>
        <FormLine label="Show stepper">
          <Switch
            onChange={setter('stepper')}
            checked={activity.stepper}
            // size="small"
          />
        </FormLine>
        <FormLine label="Duration">
          <Text
            editable={{
              onChange: (val: string) => {
                if (val) {
                  const num = parseInt(val, 10);
                  if (!_.isNaN(num)) {
                    setter('duration')(num);
                  } else {
                    setter('duration')(null);
                  }
                } else {
                  setter('duration')(null);
                }
              },
            }}
          >
            {`${activity.duration}`}
          </Text>
        </FormLine>
      </Panel>
      <Panel key="2" header="Grouping">
        <GroupingParameters
          sessionId={sessionId}
          userType={userType}
          productions={productions}
          activities={activities}
          activity={activity}
          activityRef={baseRef}
        />
      </Panel>
      <Panel key="3" header="Productions">
        <ProductionsForm
          activities={activities}
          activity={activity}
          activityRef={baseRef}
        />
      </Panel>
    </Collapse>
  );
};

const ScreenParameters = ({
  sessionId,
  activity,
  productions,
  activities,
  screen,
}: {
  sessionId: string;
  activity: Activity;
  productions: Record<string, Productions>;
  activities: Activities;
  screen: Screen;
}) => {
  const db = useFirebase();
  type BindingRecord = { key: string; name: string; dataPath: DataPath };

  const baseRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/${screen.name}`;
  const setter = (prop: ScreenProp) => (val: string) => {
    return db.ref(`${baseRef}/${prop}`).set(val);
  };

  const bindingsRef = `${baseRef}/dataBindings`;
  const updateBindings = ({ key, dataPath }: BindingRecord) => {
    if (dataPath === '') {
      return db.ref(bindingsRef).child(key).remove();
    } else {
      return db.ref(bindingsRef).child(key).set(dataPath);
    }
  };

  const dataBindings = screen.dataBindings;
  const bindings: BindingRecord[] = [];
  if (screen.content.template.type === 'HardcodedScreenTemplate') {
    const names = HARDCODED_SCREENS[screen.content.template.name].bindings;
    names.forEach((name: string) =>
      bindings.push({ key: name, name, dataPath: dataBindings[name] || '' })
    );
  }

  const bindingValidator = async (ignored: any, dataPath: DataPath) => {
    console.log('Validating', dataPath);
    if (dataPath !== '') {
      try {
        dataPathToRef(
          sessionId,
          undefined,
          productions,
          dataPath,
          activities,
          true
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
    return dataPath;
  };

  return (
    <Collapse>
      <Panel key="1" header="Informations">
        <FormLine label="Name">
          <Text editable={{ onChange: setter('humanName') }}>
            {screen.humanName || screen.name}
          </Text>
        </FormLine>
      </Panel>
      <Panel key="3" header="Bindings">
        <EditableTable
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
            },
            {
              title: 'Data Path',
              dataIndex: 'dataPath',
              editable: true,
              rules: [
                {
                  validator: bindingValidator,
                  message: 'This data path is not valid',
                },
              ],
            },
          ]}
          handleSave={updateBindings}
          dataSource={bindings}
        />
      </Panel>
    </Collapse>
  );
};

const ParametersPane = ({
  userId,
  userType,
  role,
  sessionId,
  session,
  productions,
  activities,
  sessionTemplate,
  selection,
  rightPaneHidden,
}: {
  userId: string;
  userType: UserType;
  role: RoleBar;
  sessionId: string;
  session: Session;
  productions: Record<string, Productions>;
  activities: Activities;
  sessionTemplate: SessionTemplate;
  selection: Selection;
  rightPaneHidden?: boolean;
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        textAlign: 'left',
      }}
    >
      {!rightPaneHidden && (
        <Collapse>
          <Panel key="1" header="Session">
            <SessionParameters
              userId={userId}
              sessionId={sessionId}
              session={session}
              role={role}
            />
          </Panel>
          {selection.selectedActivity && (
            <Panel key="2" header="Activity">
              <ActivityParameters
                sessionId={sessionId}
                userType={userType}
                productions={productions}
                activity={
                  sessionTemplate.activities[selection.selectedActivity]
                }
                activities={sessionTemplate.activities}
              />
            </Panel>
          )}
          {selection.selectedActivity && selection.selectedScreen && (
            <Panel key="3" header="Screen">
              <ScreenParameters
                sessionId={sessionId}
                activity={
                  sessionTemplate.activities[selection.selectedActivity]
                }
                productions={productions}
                activities={activities}
                screen={
                  sessionTemplate.activities[selection.selectedActivity]
                    .screens[selection.selectedScreen]
                }
              />
            </Panel>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default ParametersPane;
