import React, { useCallback, useMemo, useState } from 'react';
import {
  BlockToolbarButton,
  createBasicMarksPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createParagraphPlugin,
  createListPlugin,
  createPlateUI,
  createPlugins,
  createResetNodePlugin,
  createSoftBreakPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  ListToolbarButton,
  MarkToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  Plate,
  PlateProps,
  ToolbarButton,
  usePlateEditorRef,
  createImagePlugin,
  createLinkPlugin,
  StyledElement,
  withProps,
} from '@udecode/plate';
import Tooltip from 'components/Tooltip';
import { createInstructionsPlugin } from 'frameworks/plate/plugins/instructions-block';
import {
  Bold,
  FileAlt,
  Italic,
  ListOl,
  ListUl,
  Paperclip,
  Refresh,
  Underline,
  Image,
  Link,
  FileDownload,
  XCircle,
} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { upload, uploadShort } from 'services/storage';
import { ToolbarUpload } from 'frameworks/plate/plugins/file-upload/ToolbarUpload';
import { exportSingleDoc } from 'services/docExport';
import { ELEMENT_INSTRUCTIONS } from 'frameworks/plate/plugins/instructions-block';
import { createInstructionsBlockElement } from 'frameworks/plate/plugins/instructions-block/InstructionsBlockElement';
import { ToolbarToggleBlockButton } from 'frameworks/plate/plugins/instructions-block/ToolbarToggleBlockButton';
import { createEmbeddedLinkPlugin } from './plugins/embed-link';
import { ToolbarLink } from './plugins/embed-link/ToolbarLink';
import SaveStatus from 'blocks/SaveStatus';

import './slate.css';

export type CustomEditorProps = {
  readOnly?: boolean;
  forceDisplayToolbar?: boolean;
  alternateToolbar?: React.ReactNode;
  editableInstructionBlocks?: boolean;
  additionalToolbarButtons?: { icon: React.ReactNode; handler: () => void }[];
  saving?: boolean;
  metaId: string;
  forceDisplayInstructions?: boolean;
  toolbarSticky?: boolean;
  toolbarOffsetClassName?: string;
};

export type EditorProps = PlateProps & CustomEditorProps;

const components = createPlateUI({
  [ELEMENT_H1]: withProps(StyledElement, {
    styles: {
      root: {
        margin: '1em 0 0.5em',
        fontSize: '1.875em',
        fontWeight: '500',
        lineHeight: '1.3',
      },
    },
  }),
});

const Editor = ({
  readOnly,
  forceDisplayToolbar,
  alternateToolbar,
  editableInstructionBlocks,
  saving,
  additionalToolbarButtons,
  forceDisplayInstructions,
  metaId,
  toolbarSticky = true,
  toolbarOffsetClassName = 'top-0',
  ...props
}: EditorProps) => {
  const { t } = useTranslation();

  const editor = usePlateEditorRef()!;

  const toolBarIconClassName =
    'fill-current text-black-soft hover:text-primary';

  const [uploadInfoVisible, setUploadInfoVisible] = useState(false);
  const [uploadState, setUploadState] = useState<{
    state: 'loading' | 'succeeded' | 'failed';
    fileName: string;
  } | null>(null);

  const startUploading = useCallback((file) => {
    setUploadState({
      state: 'loading',
      fileName: file,
    });
    setUploadInfoVisible(true);
  }, []);

  const successedUploading = useCallback((file) => {
    setUploadState({
      state: 'succeeded',
      fileName: file,
    });
    setUploadInfoVisible(false);
  }, []);

  const failedUploading = useCallback((file) => {
    setUploadState({
      state: 'failed',
      fileName: file,
    });
  }, []);

  const uploadCallback = useCallback(
    (prefix, file) => {
      return upload(
        prefix,
        file,
        startUploading,
        successedUploading,
        failedUploading
      );
    },
    [startUploading, successedUploading, failedUploading]
  );

  const plugins = useMemo(
    () =>
      createPlugins(
        [
          createParagraphPlugin(),
          createHeadingPlugin(),

          createBasicMarksPlugin(),

          createResetNodePlugin(),
          createSoftBreakPlugin(),
          createExitBreakPlugin(),
          createListPlugin(),

          createImagePlugin({
            options: {
              uploadImage: (buf: string | ArrayBuffer) => uploadShort('', buf),
            },
          }),
          createLinkPlugin(),
          createInstructionsPlugin({
            component: createInstructionsBlockElement(
              readOnly && !forceDisplayInstructions,
              editableInstructionBlocks
            ),
          }),
          createEmbeddedLinkPlugin(),
        ],
        { components }
      ),
    [editableInstructionBlocks, forceDisplayInstructions, readOnly]
  );

  return (
    <div style={{ minHeight: '300px' }} className="h-full rounded-lg bg-white">
      {(!readOnly || forceDisplayToolbar) && (
        <div
          className={`notranslate ${
            toolbarSticky ? 'sticky' : ''
          } ${toolbarOffsetClassName} z-50 mb-4 rounded-t-md border-b border-surfaces-divider bg-gray-soft`}
        >
          {alternateToolbar || (
            <div className="flex min-h-12 flex-row flex-wrap items-center space-x-1 px-4">
              {editableInstructionBlocks && (
                <Tooltip content={t('editor:makeInstruction')}>
                  <ToolbarToggleBlockButton
                    type={getPluginType(editor, ELEMENT_INSTRUCTIONS)}
                    icon={<FileAlt className={toolBarIconClassName} />}
                  />
                </Tooltip>
              )}
              <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H1)}
                icon={
                  <p className="fill-current font-inter text-xl font-light tracking-tighter text-black-soft hover:text-primary">
                    {t('editor:h1')}
                  </p>
                }
              />
              <BlockToolbarButton
                type={getPluginType(editor, ELEMENT_H2)}
                icon={
                  <p className="fill-current font-inter text-xl font-light tracking-tighter text-black-soft hover:text-primary">
                    {t('editor:h2')}
                  </p>
                }
              />
              <ListToolbarButton
                type={getPluginType(editor, ELEMENT_UL)}
                icon={<ListUl className={toolBarIconClassName} />}
              />
              <ListToolbarButton
                type={getPluginType(editor, ELEMENT_OL)}
                icon={<ListOl className={toolBarIconClassName} />}
              />
              <MarkToolbarButton
                type={getPluginType(editor, MARK_BOLD)}
                icon={<Bold className={toolBarIconClassName} />}
              />
              <MarkToolbarButton
                type={getPluginType(editor, MARK_ITALIC)}
                icon={<Italic className={toolBarIconClassName} />}
              />
              <MarkToolbarButton
                type={getPluginType(editor, MARK_UNDERLINE)}
                icon={<Underline className={toolBarIconClassName} />}
              />
              <ToolbarUpload
                icon={<Image className={toolBarIconClassName} />}
                options={{ upload: uploadCallback }}
                accept="image/*"
              />
              <ToolbarUpload
                icon={<Paperclip className={toolBarIconClassName} />}
                options={{ upload: uploadCallback }}
              />
              <ToolbarLink icon={<Link className={toolBarIconClassName} />} />
              <Tooltip content={t('editor:export')}>
                <ToolbarButton
                  onMouseDown={() => {
                    exportSingleDoc(metaId, props.value);
                  }}
                  icon={<FileDownload className={toolBarIconClassName} />}
                />
              </Tooltip>
              {additionalToolbarButtons?.map(({ icon, handler }, index) => (
                <ToolbarButton onMouseDown={handler} icon={icon} key={index} />
              ))}
              <div className="mr-1 w-16 flex-1 text-right">
                <SaveStatus status={saving ? 'saving' : 'saved'} />
              </div>
            </div>
          )}

          {uploadInfoVisible ? (
            <div className="border-t border-b border-surfaces-divider bg-white py-1 px-2">
              {uploadState?.state === 'loading' ? (
                <div className="flex items-center">
                  {t('editor:uploadingFile', {
                    fileName: uploadState?.fileName,
                  })}
                  <Refresh className="ml-1 h-5 w-5 animate-spin" />
                </div>
              ) : uploadState?.state === 'succeeded' ? (
                t('editor:uploadedFile', { fileName: uploadState?.fileName })
              ) : uploadState?.state === 'failed' ? (
                <div className="flex items-center">
                  {t('editor:uploadingFileFailed', {
                    fileName: uploadState?.fileName,
                  })}
                  <XCircle
                    className="ml-1 h-5 w-5 cursor-pointer stroke-2"
                    onClick={() => setUploadInfoVisible(false)}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}

      <div className="px-5 text-gray-600">
        <Plate
          id={metaId}
          {...props}
          plugins={plugins}
          editableProps={{ readOnly }}
        />
      </div>
    </div>
  );
};

export default Editor;
