import React from 'react';

import Microlink from '@microlink/react';
import { TElement } from '@udecode/plate';

export type EmbbedLinkElementProps = {
  element: TElement;
  children: React.ReactNode;
};

export const EmbedLinkElement = ({
  element,
  children,
}: EmbbedLinkElementProps) => (
  <div contentEditable={false}>
    <Microlink url={element.url} media="logo" />
    {children}
  </div>
);
