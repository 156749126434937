import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import { useCommunities } from 'model/communitiesManagement';

import Button from 'components/Button';
import Input from 'frameworks/formik/Input';
import CommunitySelector from 'blocks/CommunitySelector';
import RangePickerInput from 'frameworks/formik/RangePickerInput';
import UserSelector from 'blocks/UserSelector';
import CheckboxInput from 'frameworks/formik/CheckboxInput';
import Checkbox from 'components/Checkbox';
import { SessionLanguage } from 'types/baseTypes';
import { LanguageSelectorInput } from 'blocks/LanguageSelector';
import Spinner from 'components/Spinner';
import { UserType } from 'types/types';
import { isDevelopper } from 'constants/AppConfig';
import { isGlobalAdmin } from 'model/users';

type FormValues = {
  title: string;
  communityId: string | undefined;
  scheduledAt: [Date, Date];
  organizers: Record<string, boolean>;
  hidden?: boolean;
  customAccessCode?: string;
  language: SessionLanguage;
};
export type CreateSessionFormProps = {
  userId: string;
  userType: UserType;
  sessionId?: string;
  title: string;
  communityId?: string;
  close: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  submitLabel?: string;
};

const CreateSessionForm = ({
  userId,
  userType,
  title,
  communityId,
  close,
  onSubmit,
  submitLabel,
}: CreateSessionFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [communities, communitiesLoading] = useCommunities(
    userId,
    isGlobalAdmin(userType) ? 'superadmin' : 'facilitator'
  );

  return !communitiesLoading ? (
    <Formik<FormValues>
      initialValues={{
        title: title,
        communityId: communityId,
        scheduledAt: [
          new Date(moment().add(7, 'day').utc().format()),
          new Date(moment().add(7, 'day').add(2, 'hours').utc().format()),
        ],
        organizers: {},
        language: 'fr',
      }}
      validationSchema={Yup.object({
        title: Yup.string().required(t('form:fieldRequired')),
        hidden: Yup.boolean(),
        customAccessCode: Yup.string().min(10),
      })}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          await onSubmit(values);
        } catch (e) {
          console.error(e);
          setLoading(false);
        } finally {
          close();
        }
      }}
    >
      {({ values }) => (
        <Form className="relative flex h-full flex-col">
          <div className="mb-14 grow space-y-4 overflow-y-auto overflow-x-hidden">
            <Input name="title" label={t('sessions:enterNewTitle')} />
            <LanguageSelectorInput
              label={t('sessions:LanguageLabel')}
              name="language"
              compact={false}
            />
            <RangePickerInput
              name="scheduledAt"
              label={t('sessions:SessionDateTime')}
              type="datetime"
            />
            {_.size(communities) ? (
              <CommunitySelector
                name="communityId"
                label={t('sessions:ChooseACommunity')}
                communitiesAvailable={communities || {}}
              />
            ) : null}
            {values.communityId && (
              <div>
                <label className="text-lg font-semibold" htmlFor={'organizers'}>
                  {t('sessions:ChooseOrganizers')}
                </label>
                <UserSelector
                  userId={userId}
                  userType={userType}
                  name="organizers"
                  communityId={values.communityId}
                  sessionId={undefined}
                />
              </div>
            )}
            {isDevelopper() ? (
              <>
                {!showAdvancedSettings ? (
                  <Checkbox
                    value={showAdvancedSettings}
                    onChange={setShowAdvancedSettings}
                  >
                    {t('sessions:showDevelopperOptions')}
                  </Checkbox>
                ) : (
                  <h2 className="text-xl font-semibold">
                    {t('sessions:DevelopperOptions')}
                  </h2>
                )}
                {showAdvancedSettings ? (
                  <>
                    <CheckboxInput name="hidden">
                      {t('sessions:isHidden')}
                    </CheckboxInput>
                    <Input
                      name="customAccessCode"
                      label={t('sessions:enterCustomAccessCode')}
                    />
                  </>
                ) : undefined}
              </>
            ) : undefined}
          </div>
          <div className="absolute inset-x-0 bottom-0 flex items-center justify-end space-x-4">
            <Button
              text={t('common:Cancel')}
              size="sm"
              design="secondary"
              onClick={close}
            />
            <Button
              text={submitLabel || t('common:Create')}
              type="submit"
              size="md"
              loading={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <Spinner className="h-8 w-8 text-primary" />
  );
};

export default CreateSessionForm;
