import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import Tabs from 'componentsOld/Tabs';
import { PADDING } from 'constants/Dimensions';
import { TABS } from './tabs';
import { isGlobalAdmin } from 'model/users';

import MainBar from 'components/MainBar';

class AppAdminScreen extends Component {
  getUrl = () => {
    const {
      match: { url },
    } = this.props;
    return url.endsWith('/') ? url.slice(0, -1) : url;
  };

  getTabsFiltered = () => {
    const { userType } = this.props;
    return TABS.filter(() => isGlobalAdmin(userType));
  };

  getTabsUrl = () => {
    const tabsFiltered = this.getTabsFiltered();
    return tabsFiltered.map((tab) => tab.url);
  };

  getDefaultRedirect = () => {
    const url = this.getUrl();
    const tabsUrl = this.getTabsUrl();
    return tabsUrl && tabsUrl.length > 0 && `${url}/${tabsUrl[0]}`;
  };

  renderTab = ({ renderTab, url }) => {
    return (
      <Route
        key={url}
        path={`${this.props.match.path}/${url}`}
        render={(props) =>
          renderTab({
            ...this.props,
            ...props,
          })
        }
      />
    );
  };

  renderTabs = () => this.getTabsFiltered().map(this.renderTab);

  renderRedirect = () => <Redirect to={this.getDefaultRedirect()} />;

  render() {
    return (
      <div className="min-h-screen bg-surfaces-soft">
        <MainBar
          userId={this.props.userId}
          userType={this.props.userType}
          userName={this.props.userName}
        />
        <div className="absolute inset-0 top-16 overflow-y-auto">
          <div className="mx-auto mt-0.5 w-fit bg-white p-10">
            <Tabs
              {...this.props}
              strings={{
                screens: TABS.reduce((prec, tab) => {
                  prec[tab.url] = {
                    title: tab.title,
                  };
                  return prec;
                }, {}),
              }}
              style={styles.tabs}
              tabs={this.getTabsUrl()}
            />
            <div style={styles.content} className="w-full">
              <Switch>
                {this.renderTabs()}
                {this.getDefaultRedirect() && (
                  <Route
                    path={`${this.props.match.path}/`}
                    render={this.renderRedirect}
                  />
                )}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  tabs: {
    flex: undefined,
    margin: PADDING,
  },
  content: {
    flex: 1,
  },
};

export default withRouter(AppAdminScreen);
