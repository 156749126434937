import * as React from 'react';

function SvgSuperThankYou(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 685.515 739.815"
      {...props}
    >
      <defs>
        <pattern
          id="super_thank_you_svg__a"
          data-name="New Pattern Swatch 2"
          width={40.062}
          height={88.607}
          patternTransform="translate(-33.635 45.497)"
          patternUnits="userSpaceOnUse"
        >
          <path fill="none" d="M0 0h40.062v88.607H0z" />
          <path
            d="M8.421 12.839l-.35 9.45c-.019.482.731.481.75 0l.35-9.45c.018-.483-.732-.482-.75 0zM14.472 14.729l-.355 10.583c-.017.483.733.483.75 0l.355-10.583c.016-.483-.734-.482-.75 0zM22.493 9.062v10.205a.375.375 0 00.75 0V9.062a.375.375 0 00-.75 0zM21.36 32.496l-.756 6.047c-.06.478.69.473.75 0l.756-6.047c.06-.478-.691-.474-.75 0zM12.34 31.745l-1.128 10.206c-.053.479.697.475.75 0l1.127-10.206c.053-.479-.697-.475-.75 0zM6.241 32.873v8.316a.375.375 0 00.75 0v-8.316a.375.375 0 00-.75 0zM25.517 14.354v9.449a.375.375 0 00.75 0v-9.45a.375.375 0 00-.75 0zM25.517 34.385v8.693a.375.375 0 00.75 0v-8.693a.375.375 0 00-.75 0zM17.265 24.462l.269 8.504c.015.482.765.484.75 0l-.27-8.504c-.014-.481-.764-.483-.75 0zM17.769 46.007l.283 4.252c.032.48.783.483.75 0l-.283-4.252c-.032-.479-.782-.482-.75 0zM12.87 50.82l-.549 7.088c-.037.481.713.479.75 0l.549-7.088c.037-.481-.713-.479-.75 0zM9.047 27.955l.838 4.255c.093.473.816.272.723-.2l-.838-4.254c-.093-.473-.816-.273-.723.2zM8.131 46.858l-.283 5.953c-.023.482.727.48.75 0l.283-5.953c.023-.483-.727-.482-.75 0zM3.05 36.934l1.418 14.174c.048.475.798.48.75 0L3.801 36.934c-.048-.475-.798-.48-.75 0z"
            fill="#e4e4e4"
          />
          <path
            d="M20.654 32.684l.284 16.157a.375.375 0 00.75 0l-.284-16.157a.375.375 0 00-.75 0zM29.236 29.573l-.85 17.291c-.024.483.726.481.75 0l.85-17.291c.024-.483-.726-.481-.75 0zM23.442 11.142L21.74 27.016c-.051.479.699.475.75 0l1.7-15.874c.052-.48-.698-.476-.75 0zM17.638.36L16.52 16.52c-.033.482.717.48.75 0L18.39.361c.033-.482-.717-.48-.75 0zM14.11 2.924l-3.024 25.24c-.058.478.693.474.75 0l3.024-25.24c.058-.478-.693-.474-.75 0zM6.765 2.068l.922 24.092c.018.481.768.484.75 0L7.515 2.068c-.019-.48-.769-.483-.75 0z"
            fill="#e4e4e4"
          />
          <path
            d="M11.894 1.864l7.922 22.966c.156.454.881.259.723-.2L12.617 1.666c-.156-.453-.881-.259-.723.2zM36.712 3.203l-.283 13.89a.375.375 0 00.75 0l.283-13.89a.375.375 0 00-.75 0zM26.648 26.728l.865 19.842c.02.48.77.483.75 0l-.865-19.842c-.021-.481-.771-.484-.75 0zM26.556 55.362l-.567 9.07c-.03.483.72.48.75 0l.567-9.07c.03-.482-.72-.48-.75 0zM18.336 60.464v6.803a.375.375 0 00.75 0v-6.803a.375.375 0 00-.75 0zM10.904 37.519L9.53 57.081c-.033.482.717.48.75 0l1.374-19.562c.034-.482-.716-.48-.75 0zM1.853 38.073L3.88 70.669c.03.48.78.483.75 0L2.603 38.073c-.03-.48-.78-.483-.75 0z"
            fill="#e4e4e4"
          />
          <path
            d="M.009 39.287L7.11 74.15c.096.472.82.272.723-.2L.732 39.087c-.096-.472-.82-.272-.723.2zM12.864 67.303l2.176 6.268c.157.453.882.258.723-.2l-2.176-6.267c-.157-.453-.882-.259-.723.2zM27.974 43.74v22.393a.375.375 0 00.75 0V43.74a.375.375 0 00-.75 0zM32.765 29.956l3.124 20.408c.073.476.796.274.723-.199l-3.124-20.409c-.073-.475-.795-.273-.723.2zM31.942 11.992v13.606a.375.375 0 00.75 0V11.992a.375.375 0 00-.75 0zM17.26 52.24l1.162 19.841c.028.48.778.483.75 0l-1.163-19.84c-.028-.48-.778-.484-.75 0z"
            fill="#e4e4e4"
          />
          <path
            d="M9.65 55.518l5.312 18.164a.375.375 0 00.723-.2L10.374 55.32a.375.375 0 00-.723.2zM26.556 57.63v24.377a.375.375 0 00.75 0V57.63a.375.375 0 00-.75 0zM33.878 60.468l1.693 20.41c.04.478.79.482.75 0l-1.693-20.41c-.04-.478-.79-.482-.75 0zM22.351 74.64l-.85 13.606c-.03.483.72.48.75 0l.85-13.606c.03-.482-.72-.48-.75 0z"
            fill="#e4e4e4"
          />
          <path
            d="M14.215 59.907l-1.702 22.11c-.037.482.713.48.75 0l1.702-22.11c.037-.481-.713-.479-.75 0zM5.854 56.883l4.529 22.679c.094.473.817.272.723-.2l-4.53-22.678c-.094-.473-.817-.273-.722.2zM24.57 42.142l6.797 26.648a.375.375 0 00.723-.2l-6.797-26.647a.375.375 0 00-.723.2zM39.312 25.314v15.308a.375.375 0 00.75 0V25.314a.375.375 0 00-.75 0zM32.205 12.556l-2.548 22.11c-.055.48.696.475.75 0l2.548-22.11c.055-.479-.696-.475-.75 0z"
            fill="#e4e4e4"
          />
        </pattern>
      </defs>
      <path
        d="M296.63 237.364a34.124 34.124 0 00-45.198-28.821l2.474.252a45.211 45.211 0 00-27.63 17.652 39.146 39.146 0 00-6.204 31.925c2.804 10.834 11.08 20.233 21.674 23.839 5.89 2.005 12.214 2.25 18.434 2.462 4.037.137 8.148.26 12.04-.822a25.293 25.293 0 0012.595-8.78 51.676 51.676 0 007.45-13.642c3.017-7.658 5.183-15.875 4.364-24.065z"
        fill="#2f2e41"
      />
      <path
        d="M384.589 736.501c-8.796-33.08 10.737-65.323 33.56-87.644 12.164-11.896 25.71-22.237 38.92-32.918a415.653 415.653 0 0036.15-32.557c22.022-22.54 41.232-48.3 53.953-77.255 12.097-27.534 17.815-57.529 14.983-87.572-2.686-28.494-12.9-56.523-31.688-78.384q-3.511-4.086-7.388-7.833c-1.386-1.346-3.51.773-2.121 2.121 20.98 20.372 33.191 47.979 37.324 76.7 4.262 29.618-.13 59.814-11.205 87.505-11.799 29.499-30.56 55.821-52.318 78.791a385.924 385.924 0 01-34.903 32.36c-13.124 10.81-26.761 21.025-39.258 32.578-23.032 21.293-43.732 50.582-40.888 83.551a66.356 66.356 0 001.986 11.355 1.501 1.501 0 002.893-.798z"
        fill="#f0f0f0"
      />
      <path
        d="M476.698 548.176a306.697 306.697 0 01-31.516-75.87 312.816 312.816 0 01-10.528-81.492c.1-27.507 3.44-55.703 11.968-81.935 8.094-24.895 22.594-48.637 45.616-62.223a74.516 74.516 0 0138.754-10.234c14.376.136 28.552 3.92 41.505 10.047 12.685 6 24.285 14.087 35 23.102a257.321 257.321 0 0128.516 28.001c17.366 19.97 31.554 43.093 39.478 68.447 8.259 26.422 9.706 55.323 2.042 82.064-7.362 25.685-23.49 47.578-43.288 65.163-21.396 19.005-46.742 32.816-72.299 45.35-6.397 3.138-12.828 6.204-19.26 9.269-1.742.83-.221 3.417 1.514 2.59 26.95-12.844 54.165-25.8 78.188-43.732 21.197-15.822 40.091-35.685 51.332-59.88 11.887-25.585 14.323-54.677 9.396-82.282-4.815-26.978-16.894-52.228-33.06-74.219a242.877 242.877 0 00-26.692-30.462 220.722 220.722 0 00-32.55-26.53c-23.919-15.633-53.83-25.074-82.176-17.021-25.2 7.16-44.595 27.35-56.136 50.233-6.46 12.808-10.813 26.582-13.91 40.558a295.572 295.572 0 00-6.08 41.81 314.791 314.791 0 0031.853 162.742q4.563 9.173 9.742 18.019a1.501 1.501 0 002.59-1.515z"
        fill="#f0f0f0"
      />
      <path
        d="M457.698 519.176a306.697 306.697 0 01-31.516-75.87 312.816 312.816 0 01-10.528-81.492c.1-27.507 3.44-55.703 11.968-81.935 8.094-24.895 22.594-48.637 45.616-62.223a74.516 74.516 0 0138.754-10.234c14.376.136 28.552 3.92 41.505 10.047 12.685 6 24.285 14.087 35 23.102a257.321 257.321 0 0128.516 28.001c17.366 19.97 31.554 43.093 39.478 68.447 8.259 26.422 9.706 55.323 2.042 82.064-7.362 25.685-23.49 47.578-43.288 65.163-21.396 19.005-46.742 32.816-72.299 45.35-6.397 3.138-12.828 6.204-19.26 9.269-1.742.83-.221 3.417 1.514 2.59 26.95-12.844 54.165-25.8 78.188-43.732 21.197-15.822 40.091-35.685 51.332-59.88 11.887-25.585 14.323-54.677 9.396-82.282-4.815-26.978-16.894-52.228-33.06-74.219a242.877 242.877 0 00-26.692-30.462 220.722 220.722 0 00-32.55-26.53c-23.919-15.633-53.83-25.074-82.176-17.021-25.2 7.16-44.595 27.35-56.136 50.233-6.46 12.808-10.813 26.582-13.91 40.558a295.572 295.572 0 00-6.08 41.81 314.791 314.791 0 0031.853 162.742q4.563 9.173 9.742 18.019a1.501 1.501 0 002.59-1.515z"
        fill="#f0f0f0"
      />
      <path
        d="M184.595 462.956a12.514 12.514 0 014.875-18.56l34.676-108.575 24.15 12.546-41.679 102.78a12.582 12.582 0 01-22.022 11.809z"
        fill="#ffb6b6"
      />
      <path
        d="M212.924 350.836a5.605 5.605 0 01.026-4.673l11.877-25.535a15.583 15.583 0 0129.908 8.766l-3.97 27.973a5.611 5.611 0 01-7.149 4.592l-27.171-8.05a5.605 5.605 0 01-3.52-3.073z"
        fill="#2f2e41"
      />
      <path
        d="M313.102 355.145c-8.252 21.61-6.144 44.275 4.02 67.762h-72c2.894-16.71 1.72-32.241-9-45z"
        fill="#ffb6b6"
      />
      <path
        d="M228.836 315.433l21.309-15.497 34.869-9.686 29.057 5.812 1.708 16.057a193.849 193.849 0 01-2.677 58.523l-75.548 17.434-7.749-13.56s-17.434-17.434-3.874-36.805z"
        fill="#2f2e41"
      />
      <path
        d="M413.912 207.036a12.514 12.514 0 01-11.003 15.72l-70.145 89.838-18.309-20.135 74.708-81.976a12.582 12.582 0 0124.75-3.447z"
        fill="#ffb6b6"
      />
      <path
        d="M348.493 302.397a5.605 5.605 0 01-1.644 4.374l-19.988 19.839a15.583 15.583 0 01-25.019-18.585l13.416-24.865a5.611 5.611 0 018.297-1.83l22.7 16.965a5.605 5.605 0 012.238 4.102z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb6b6"
        d="M228.931 716.837h11.033l5.249-42.557h-16.284l.002 42.557z"
      />
      <path
        d="M225.194 711.395l17.442-1.041v7.472l16.582 11.452a4.668 4.668 0 01-2.652 8.51H235.8l-3.58-7.392-1.397 7.391h-7.829z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb6b6"
        d="M306.931 716.837h11.033l5.249-42.557h-16.284l.002 42.557z"
      />
      <path
        d="M303.194 711.395l17.442-1.041v7.472l16.582 11.452a4.668 4.668 0 01-2.652 8.51H313.8l-3.58-7.392-1.397 7.391h-7.829zM336.242 478.397v21.7l-2.12 26.51s-.67 23.15-1.48 41.41c-.49 11.1-1.04 20.39-1.52 21.59-.59 1.47-.45 5.48-.11 9.69.4 4.87 1.06 10.01 1.17 11.73.21 3.21-1.49 17-1.49 17s-1.9 34.58-4.66 37.34c-2.76 2.76 0 10.61 0 10.61-12.94 9.54-29.28-2.12-29.28-2.12 3.61-5.75-2.12-45-2.12-45s-.84-13.4-2.54-17c-1.7-3.6-2.13-26.09-2.13-26.09v-33.31s-4.46-6.82-5.09-10 0-14.42 0-14.42l-4.66-15.7-3.19 12.3s-8.26 34.37-9.75 38.61c-1.49 4.24.84 8.49.84 8.49s2.13 6.54 0 9.54a21.455 21.455 0 00-3 6.16s-1.09 12.52-3 17-3.6 10-3.6 10 .45 8.46-1.46 10.37c-1.91 1.91-2.54 11.46-2.54 11.46s.42 10.81-2.13 14.63c-2.55 3.82-2.55 25.46-2.55 25.46a29.678 29.678 0 01-29.88 1.27s2.52-2.36.61-13.36c-1.91-11 1.37-40.95 1.37-40.95s-.42-7.6 1.7-11c2.12-3.4-1.07-9.97-.22-12.94s3.4-19.1 3.4-19.1-1.06-39.67-1.06-41.79a27.7 27.7 0 00-3-10.82c-1.91-3.39 0-28 0-28s4.5-12.72 3-15.48 1-7.64 1-7.64-2.33-5.73 0-6.79 2.76-5.3 2.76-5.3l1.62-20.58s.61-9.34 2.55-9.76c1.94-.42 1.09-4.02 3-6.57a17.93 17.93 0 001.9-5.52c.27-1.18.52-2.41.73-3.59.6-3.2.97-5.96.97-5.96 12.52 10.4 78.35 3.11 78.42 3.11 4.18.91 9.15 15.42 12.74 28.07a127.592 127.592 0 014.8 34.74z"
        fill="#2f2e41"
      />
      <circle
        cx={517.022}
        cy={334.349}
        r={29.872}
        transform="rotate(-73.091 334.371 467.835)"
        fill="#ffb6b6"
      />
      <path
        d="M226.016 259.462l11.567-2.407 2.13-15.35 3.993 14.076 5.529-1.15 1.242-8.955 2.33 8.211 41.08-8.547a31.622 31.622 0 00-37.4-24.517l-5.955 1.239a31.622 31.622 0 00-24.516 37.4z"
        fill="#2f2e41"
      />
      <path
        d="M344.351 34.85l-.076.145a64.684 64.684 0 00-59.72 114.586l-.076.146 114.878 59.872 59.872-114.877a64.772 64.772 0 10-114.878-59.873z"
        fill="current"
      />
      <path
        d="M446.7 694.054l-.02.037a16.349 16.349 0 00-15.093 28.961l-.02.037 29.036 15.133 15.132-29.035a16.371 16.371 0 10-29.035-15.133z"
        fill="#ff6584"
      />
      <path
        d="M61.533 325.9v.164a64.684 64.684 0 000 129.215v.164h129.544V325.899a64.772 64.772 0 10-129.544 0z"
        fill="current"
      />
      <path
        d="M665.257 739.815H17.963a1.19 1.19 0 110-2.382h647.294a1.19 1.19 0 110 2.382z"
        fill="#cacaca"
      />
      <path
        d="M562.199 448.281a57.17 57.17 0 0036.198-13.214 55.475 55.475 0 0019.165-34.288 56.616 56.616 0 00-1.108-22.71 1.501 1.501 0 00-2.893.798 53.954 53.954 0 01-4.074 37.533 53.354 53.354 0 01-47.288 28.881 1.5 1.5 0 000 3z"
        fill="#f0f0f0"
      />
      <path
        d="M330.55 58.622c-8.535-8.103-22.013-8.081-31.87-2.44-11.063 6.33-16.078 19.409-15.825 31.69.286 13.822 7.044 26.337 15.77 36.703a146.218 146.218 0 0032.126 27.77 161.414 161.414 0 0038.515 18.354q2.51.809 5.045 1.533a1.501 1.501 0 00.798-2.892 157.494 157.494 0 01-68.9-40.88c-9.072-9.293-17.135-20.46-19.615-33.46-2.19-11.48.23-24.808 9.128-32.985 8.65-7.95 23.787-9.74 32.707-1.272 1.4 1.33 3.525-.788 2.121-2.12zM59.315 357.456c-11.53-1.434-22.357 6.676-26.848 16.919-5.036 11.486-1.64 24.727 5.664 34.435 8.045 10.692 20.23 16.924 32.968 20.232 13.673 3.552 28.188 4.51 42.262 3.855a161.669 161.669 0 0048.677-9.895c1.794-.666 1.018-3.567-.798-2.893-26 9.654-54.945 12.7-82.287 7.603-12.222-2.278-24.669-6.625-33.776-15.418-8.252-7.967-14.09-20.034-11.607-31.686 2.468-11.58 13.477-21.677 25.745-20.152 1.912.238 1.894-2.764 0-3z"
        fill="#fff"
      />
      <path
        d="M251.134 210.506c2.789-11.147-2.613-23.105-10.976-30.985s-19.276-12.41-30.078-16.332c-8.613-3.127-18.703-5.847-26.499-1.03-6.264 3.87-8.981 11.388-12.562 17.822a58.412 58.412 0 01-71.786 26.195c4.623 13.422 20.548 19.986 34.714 19.075s27.25-7.436 40.447-12.667 27.876-9.273 41.439-5.084c5.724 1.768 10.93 4.924 16.598 6.866s12.301 2.52 17.353-.701"
        fill="#2f2e41"
      />
      <path
        d="M449.547 618.794l-9.657-16.997a1.501 1.501 0 00-2.59 1.514l9.656 16.997a1.501 1.501 0 002.59-1.514z"
        fill="#f0f0f0"
      />
      <path
        d="M501.124 488.18l-.71-.88a5.562 5.562 0 01.71.88z"
        transform="translate(-257.242 -80.093)"
        fill="url(#super_thank_you_svg__a)"
      />
    </svg>
  );
}

export default SvgSuperThankYou;
