import React, { useState } from 'react';
import { Form, Select, Button } from 'antd';
import { mergeCommunities } from 'model/communitiesManagement';
import { Store } from 'rc-field-form/lib/interface';
import { Communities } from 'types/types';

const { Option } = Select;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const MergeCommunityForm = ({
  sourceCommunityId,
  communities,
  close,
}: {
  sourceCommunityId: string;
  communities: Communities;
  close: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Store) => {
    const { targetId } = values;
    console.log('Success:', targetId);
    setLoading(true);

    await mergeCommunities(sourceCommunityId, targetId);

    setLoading(false);

    close();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ noMatching: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        // label="Target community"
        name="targetId"
        rules={[
          {
            required: true,
            message: 'Please input a name for this new community!',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            return (
              option?.data?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        >
          {Object.entries(communities).map(([communityId, community]) => (
            <Option value={communityId} data={community.name}>
              {community.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button htmlType="submit" loading={loading}>
          {!loading ? 'Merge' : 'Processing...'}
        </Button>
        <Button type="link" onClick={close}>
          Annuler
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MergeCommunityForm;
