import React, { useEffect } from 'react';

import { useProgressTimer } from 'utils/utils';

import Alert, { AlertProps } from 'components/Alert';
import ProgressBar from 'components/ProgressBar';

type AlertBannerProps = AlertProps & {
  duration?: number;
  onEnd?: () => void;
};
const AlertBanner = (props: AlertBannerProps): JSX.Element => {
  const { className, duration, onEnd, ...restProps } = props;

  const progress = useProgressTimer(duration);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (duration && onEnd) {
      timeoutId = setTimeout(onEnd, duration);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [duration, onEnd]);

  const [bgColor, progressColor]: [
    string,
    'primary' | 'danger' | 'warning' | undefined
  ] =
    restProps.type === 'info'
      ? ['bg-primary-soft', 'primary']
      : restProps.type === 'warning'
      ? ['bg-warning-soft', 'warning']
      : restProps.type === 'error'
      ? ['bg-danger-soft', 'danger']
      : ['', undefined];
  return (
    <div className={`w-full ${bgColor}`}>
      <Alert className={`mx-auto rounded-none ${className}`} {...restProps} />
      {progress ? (
        <ProgressBar progress={progress} color={progressColor} />
      ) : null}
    </div>
  );
};

export default AlertBanner;
