import React from 'react';
import { Session, SessionTemplate } from 'types/types';
import _ from 'lodash';
import DocumentActivity from './DocumentActivity';
import { Nullable } from 'types/typesUtils';

export type AllDocumentActivitiesProps = {
  sessionId: string;
  session: Session;
  summaries: Nullable<Record<string, any>>;
  sessionTemplate: SessionTemplate;
  marginClasses: string;
};

const AllDocumentActivities = ({
  sessionId,
  session,
  summaries,
  sessionTemplate,
  marginClasses,
}: AllDocumentActivitiesProps): JSX.Element => {
  const displayedActivities = _.pickBy(
    sessionTemplate.activities,
    (activity) => {
      return (
        Object.values(activity.productions || {})[0]?.type === 'document' &&
        activity.name !== 'share'
      );
    }
  );

  const orderedActivities = _.sortBy(
    Object.values(displayedActivities),
    'index'
  );

  return (
    <>
      {orderedActivities.map((activity) => (
        <DocumentActivity
          key={activity.name}
          sessionId={sessionId}
          session={session}
          activity={activity}
          summary={summaries?.[activity.name]?.full || null}
          marginClasses={marginClasses}
        />
      ))}
    </>
  );
};

export default AllDocumentActivities;
