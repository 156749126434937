import { DocMeta } from '../../types/baseTypes';
import { PureDatabase } from '../../types/typesUtils';

export const cleanRevisions =
  (database: PureDatabase) => async (docId: string) => {
    const revisions: Record<string, unknown> | null = (
      await database.ref(`contentDocsRevisions/${docId}`).once('value')
    ).val();

    await Promise.all(
      Object.keys(revisions || {}).map((revId) => {
        return database.ref(`contentDocs/${revId}`).remove();
      })
    );
    await database.ref(`contentDocsRevisions/${docId}`).remove();
  };

export const cleanDocs =
  (database: PureDatabase) =>
  async (sessionId: string): Promise<void> => {
    const docMetas: Record<string, DocMeta> | null = (
      await database
        .ref('contentDocsMeta')
        .orderByChild('sessionId')
        .equalTo(sessionId)
        .once('value')
    ).val();

    await Promise.all(
      Object.entries(docMetas || {}).map(async ([docId]) =>
        cleanRevisions(database)(docId)
      )
    );

    await Promise.all(
      Object.keys(docMetas || {}).map((docId) => {
        return database.ref(`contentDocsMeta/${docId}`).remove();
      })
    );
  };
