import { matchingUrl } from 'config';
import _ from 'lodash';
import { getGroups } from 'model/groupManagement';

import { AugmentedCriteriaMode, CriteriaMode, TrueSet } from 'types/types';

export type MatcherUser = {
  id: string;
  criteria: Record<string, string>;
  votes: Record<string, boolean>;
};

export type CriteriaDefinition<T> = Record<
  string,
  { options: string[]; type: T; optionalArgument?: string }
>;

export type MatcherBody<T> = {
  peoples: MatcherUser[];
  minPeopleByGroup: number;
  maxPeopleByGroup: number;
  criteriaDefinition: CriteriaDefinition<T>;
  // in seconds
  matchingTime: number;
};

export type MatchResult = {};

type MatcherRequestReturn = {
  groups: Record<
    string,
    {
      name: string;
      peoples: {
        id: string;
      }[];
    }
  >;
};

export type MatcherReturn = {
  // name: string;
  topic?: string;
  peoples: {
    id: string;
  }[];
}[];

export const makeMatch = async (
  body: MatcherBody<CriteriaMode>
): Promise<MatcherReturn> => {
  const result = await fetch(matchingUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const matching: MatcherRequestReturn = await result.json();
  return Object.values(matching.groups);
};

const findBestCohortToAddNewComer = (
  cohorts: Record<string, MatcherUser[]>,
  minGroupSize: number
): string | null => {
  let tooSmallCohortId: string | null = null;
  let smallestCohortIdCount: number = Number.MAX_SAFE_INTEGER;
  let bestCohortId: string | null = null;
  let lowestCohortScore: number = Number.MAX_SAFE_INTEGER;
  Object.entries(cohorts).forEach(([id, cohort]) => {
    if (cohort.length < minGroupSize && cohort.length < smallestCohortIdCount) {
      tooSmallCohortId = id;
      smallestCohortIdCount = cohort.length;
      return;
    }
    const [userWithCriteriaCount, userWithoutCriteriaCount] = cohort.reduce(
      (prev, user) => {
        if (user.criteria.hasPosted === 'true') {
          prev[0]++;
        } else {
          prev[1]++;
        }
        return prev;
      },
      [0, 0]
    );
    const cohortScore = userWithCriteriaCount
      ? userWithoutCriteriaCount / userWithCriteriaCount
      : Number.MAX_SAFE_INTEGER;
    if (cohortScore < lowestCohortScore) {
      lowestCohortScore = cohortScore;
      bestCohortId = id;
    }
  });

  return tooSmallCohortId || bestCohortId;
};

export const makeComplexMatch = async (
  body: MatcherBody<AugmentedCriteriaMode>
): Promise<MatcherReturn> => {
  const criteria = body.criteriaDefinition;

  const splitCriteria = _.pickBy(criteria, (def) => def.type === 'HARD_SPLIT');
  const nbSplitCriteria = _.size(splitCriteria);

  if (nbSplitCriteria > 1) {
    throw new Error("There can't be more than one hard split criteria");
  } else if (nbSplitCriteria === 0) {
    return makeMatch(body as MatcherBody<CriteriaMode>);
  }

  const splitCriteriaName = Object.keys(splitCriteria)[0]!;

  const usersBin = _.groupBy(
    body.peoples,
    (people) => people.criteria?.[splitCriteriaName]
  );

  if (splitCriteriaName === 'topics') {
    const peopleWithoutHardsplitCriteria = usersBin['undefined'];
    delete usersBin['undefined'];

    peopleWithoutHardsplitCriteria?.forEach((user) => {
      const cohortId = findBestCohortToAddNewComer(
        usersBin,
        body.minPeopleByGroup
      );
      if (cohortId && usersBin[cohortId]) {
        usersBin[cohortId].push(user);
      }
    });
  }

  const criteriaDef = _.pickBy(
    criteria,
    (crit) => crit.type !== 'HARD_SPLIT'
  ) as CriteriaDefinition<CriteriaMode>;

  const results = await Promise.all(
    Object.values(usersBin).map((users) =>
      makeMatch({ ...body, peoples: users, criteriaDefinition: criteriaDef })
    )
  );

  return _.flatten(results);
};

export const makeCustomSkemaMatch = (
  body: MatcherBody<AugmentedCriteriaMode>
): MatcherReturn => {
  console.log(body.criteriaDefinition);

  const peoples = body.peoples;

  const [tutors, participants] = _.partition(peoples, (user) =>
    user?.criteria['crit_role']?.includes('M2 Msc IHRPM')
  );

  const nbGroups = Math.min(
    Math.floor(peoples.length / 4),
    participants.length
  );

  type Group = { id: string; type: 'tutor' | 'participant' }[];

  const groups: {
    peoples: Group;
  }[] = _.times(nbGroups, () => ({
    peoples: [],
  }));

  const [finance, marketing] = _.partition(
    participants,
    (part) => part?.criteria?.['crit_offer'] === 'Finance'
  );

  const nbGrFinance = Math.round(
    (groups.length * finance.length) / participants.length
  );

  const nbGrMarketing = nbGroups - nbGrFinance;

  _.shuffle(finance).forEach((part, index) => {
    groups[index % nbGrFinance].peoples.push({
      id: part.id,
      type: 'participant',
    });
  });

  _.shuffle(marketing).forEach((part, index) => {
    groups[nbGrFinance + (index % nbGrMarketing)].peoples.push({
      id: part.id,
      type: 'participant',
    });
  });

  _.shuffle(tutors).forEach((part, index) => {
    if (index < nbGroups) {
      console.log(index);
      groups[index % nbGroups].peoples.push({ id: part.id, type: 'tutor' });
    } else {
      const group = groups.reduce(
        (
          prec:
            | undefined
            | {
                peoples: Group;
              },
          group
        ) => {
          if (prec === undefined) {
            return group;
          } else {
            if (prec.peoples.length === group.peoples.length) {
              if (
                prec.peoples.filter(({ type }) => type === 'tutor').length <=
                group.peoples.filter(({ type }) => type === 'tutor').length
              ) {
                return prec;
              } else {
                return group;
              }
            } else if (prec.peoples.length > group.peoples.length) {
              return group;
            } else {
              return prec;
            }
          }
        },
        undefined
      );
      console.log(group?.peoples.length);
      group?.peoples.push({ id: part.id, type: 'tutor' });
    }
  });

  return groups.map((group) => {
    return {
      peoples: group.peoples.map(({ id }) => ({ id })),
    };
  });
};

export const makeFromActivity = async (
  sessionId: string,
  activityName: string
): Promise<MatcherReturn> => {
  const groups = await getGroups(sessionId, activityName);

  return Object.values(groups).map((group: any) => {
    const members: TrueSet = group.users || {};
    const peoples = Object.keys(members).map((id) => ({
      id,
    }));
    return {
      peoples,
      name: '',
      topic: group.topic,
    };
  });
};
