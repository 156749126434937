import * as React from 'react';

function SvgArrow3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={320}
      height={200}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#arrow3_svg__clip0)"
        stroke="#fff"
        strokeWidth={2.378}
        opacity={0.2}
      >
        <path d="M39.476 189.307a12.406 12.406 0 01-2.693-3.161c-.752-1.256-1.296-2.689-1.485-4.219-.188-1.528-.057-3.184.6-4.821.324-.822.767-1.65 1.343-2.47a15 15 0 012.183-2.393" />
        <path d="M20.136 173.242c.999.83 2.285 1.566 3.75 2.107 1.461.536 3.107.887 4.84.951 1.735.063 3.524-.191 5.346-.815.907-.316 1.81-.735 2.702-1.273.9-.531 1.795-1.173 2.666-1.955M31.22 179.834c-3.832 3.74-7.53 7.602-10.902 11.695M63.095 168.6c-1.781-1.85-3.213-4.688-3.23-7.786-.029-3.104 1.357-6.485 5.134-9.322" />
        <path d="M45.84 150.679c1.783 1.853 4.716 3.461 8.127 3.92 3.408.447 7.276-.259 11.046-3.091M56.048 158.35a405.33 405.33 0 00-12.52 10.278M88.996 149.354c-1.648-1.948-2.871-4.869-2.675-7.965.097-1.55.544-3.148 1.455-4.697.922-1.537 2.31-3.021 4.275-4.349" />
        <path d="M73.026 130.475c1.652 1.952 4.458 3.732 7.828 4.383 1.684.323 3.505.359 5.39-.021 1.895-.368 3.857-1.149 5.82-2.477M82.678 138.717c-4.46 3.115-8.903 6.249-13.265 9.474M116.176 131.312c-1.544-2.02-2.598-4.98-2.247-8.075.174-1.55.703-3.129 1.701-4.627 1-1.496 2.448-2.94 4.49-4.167" />
        <path d="M101.225 111.755c1.544 2.021 4.26 3.937 7.583 4.723 1.66.391 3.475.503 5.386.214 1.912-.287 3.897-1.005 5.938-2.232M110.431 120.414c-4.633 2.897-9.213 5.863-13.774 8.853M144.281 114.36c-1.448-2.078-2.362-5.077-1.867-8.158.245-1.543.851-3.094 1.916-4.555 1.066-1.46 2.583-2.844 4.678-3.994" />
        <path d="M130.26 94.243c1.448 2.078 4.07 4.097 7.352 5.01a14.13 14.13 0 005.372.423c1.921-.217 3.941-.855 6.036-2.005M139.051 103.248l-3.566 2.047-3.547 2.073c-2.371 1.374-4.714 2.788-7.068 4.185M173.151 98.487c-1.352-2.13-2.127-5.162-1.49-8.22.317-1.532.99-3.064 2.124-4.48 1.134-1.414 2.711-2.742 4.859-3.81" />
        <path d="M160.067 77.854c1.351 2.131 3.879 4.248 7.117 5.286 1.617.516 3.416.767 5.347.626 1.932-.139 3.976-.703 6.123-1.77M168.437 87.187c-4.886 2.53-9.725 5.135-14.551 7.759M202.71 83.768c-1.246-2.184-1.873-5.241-1.086-8.268.78-3.034 3.003-6.008 7.388-7.993" />
        <path d="M190.649 62.626c1.246 2.184 3.67 4.402 6.855 5.571 3.184 1.16 7.139 1.31 11.518-.671M198.555 72.29c-5.005 2.33-9.964 4.741-14.912 7.17M232.928 70.37c-1.123-2.238-1.581-5.318-.625-8.302.948-2.993 3.34-5.859 7.838-7.623" />
        <path d="M222.064 48.699c1.123 2.239 3.422 4.565 6.54 5.882 3.116 1.307 7.055 1.644 11.546-.117M229.421 58.718c-5.131 2.093-10.215 4.278-15.284 6.491M263.783 58.591c-.966-2.298-1.218-5.396-.059-8.32a10.738 10.738 0 012.882-4.106c1.373-1.222 3.166-2.306 5.486-3.019" />
        <path d="M254.429 36.346c.966 2.298 3.107 4.748 6.128 6.239 1.51.741 3.242 1.249 5.176 1.4 1.934.153 4.047-.107 6.367-.819M261.082 46.762c-5.268 1.788-10.482 3.705-15.683 5.647M295.229 48.93c-.753-2.36-.735-5.479.686-8.301.712-1.4 1.775-2.729 3.255-3.854a12.227 12.227 0 012.547-1.492c.96-.426 2.031-.783 3.218-1.055" />
        <path d="M287.934 26.057c.755 2.366 2.669 4.95 5.547 6.668a13.69 13.69 0 005.034 1.808c.952.139 1.966.202 3.039.17 1.071-.038 2.2-.183 3.387-.455M293.612 36.928c-2.698.718-5.405 1.412-8.084 2.186-1.341.384-2.69.745-4.025 1.147l-4.008 1.193M327.07 30.184l-4.165.603-4.148.693c-1.386.219-2.759.491-4.136.75-1.376.266-2.758.501-4.125.8M54.234 213.174a12.383 12.383 0 01-2.692-3.16c-.753-1.257-1.297-2.69-1.485-4.22-.189-1.528-.058-3.184.6-4.821a11.86 11.86 0 011.342-2.47 15.009 15.009 0 012.183-2.393" />
        <path d="M34.895 197.109c.998.83 2.284 1.566 3.749 2.107 1.461.537 3.107.887 4.84.951 1.735.063 3.525-.191 5.346-.815a14.31 14.31 0 002.702-1.273 16.64 16.64 0 002.666-1.955M77.853 192.467c-1.781-1.85-3.213-4.688-3.23-7.786-.029-3.104 1.357-6.484 5.134-9.322" />
        <path d="M60.597 174.546c1.785 1.853 4.717 3.462 8.129 3.92 3.408.447 7.275-.259 11.045-3.091M70.806 182.217a405.33 405.33 0 00-12.52 10.278M103.754 173.222c-1.648-1.949-2.871-4.869-2.675-7.965a10.49 10.49 0 011.455-4.697c.922-1.537 2.311-3.021 4.276-4.349" />
        <path d="M87.784 154.342c1.652 1.953 4.458 3.733 7.828 4.384 1.684.323 3.505.359 5.39-.021 1.895-.368 3.857-1.15 5.821-2.477M97.436 162.585c-4.46 3.115-8.902 6.248-13.265 9.474M130.934 155.179c-1.544-2.02-2.598-4.98-2.246-8.075a10.345 10.345 0 011.701-4.626c.999-1.497 2.447-2.94 4.489-4.168" />
        <path d="M115.983 135.623c1.545 2.02 4.26 3.936 7.583 4.722 1.66.391 3.476.503 5.386.215 1.912-.288 3.898-1.006 5.939-2.233M125.189 144.281c-4.633 2.897-9.213 5.863-13.774 8.854M159.04 138.228c-1.449-2.078-2.363-5.078-1.867-8.158.244-1.543.85-3.095 1.915-4.556 1.066-1.459 2.583-2.843 4.678-3.993" />
        <path d="M145.018 118.11c1.448 2.078 4.071 4.097 7.352 5.01 1.639.453 3.452.641 5.372.423 1.922-.217 3.941-.855 6.036-2.005M153.809 127.116l-3.566 2.047-3.547 2.073c-2.371 1.374-4.714 2.787-7.068 4.184M187.909 122.355c-1.352-2.132-2.127-5.162-1.49-8.22.317-1.532.99-3.065 2.124-4.48 1.134-1.414 2.711-2.743 4.859-3.81" />
        <path d="M174.825 101.721c1.352 2.131 3.879 4.249 7.117 5.286 1.617.516 3.416.768 5.347.627 1.932-.14 3.976-.704 6.124-1.771M183.195 111.054c-4.886 2.531-9.725 5.136-14.551 7.759M217.468 107.635c-1.246-2.184-1.873-5.241-1.086-8.268.78-3.034 3.003-6.008 7.388-7.992" />
        <path d="M205.407 86.493c1.246 2.184 3.67 4.402 6.855 5.571 3.184 1.16 7.139 1.31 11.518-.67M213.313 96.158c-5.005 2.33-9.964 4.74-14.912 7.17M247.686 94.239c-1.123-2.24-1.581-5.32-.624-8.304.948-2.992 3.339-5.858 7.837-7.622" />
        <path d="M236.823 72.566c1.122 2.239 3.421 4.565 6.539 5.882 3.116 1.307 7.055 1.645 11.546-.117M244.179 82.585c-5.131 2.094-10.215 4.279-15.284 6.492M278.541 82.46c-.966-2.3-1.218-5.397-.059-8.322a10.738 10.738 0 012.882-4.105c1.373-1.222 3.166-2.307 5.486-3.02" />
        <path d="M269.187 60.213c.967 2.298 3.107 4.748 6.128 6.239 1.51.742 3.242 1.249 5.176 1.4 1.935.153 4.048-.107 6.367-.819M275.841 70.63c-5.269 1.788-10.482 3.705-15.684 5.646M309.987 72.797c-.753-2.359-.735-5.479.686-8.3.712-1.402 1.775-2.73 3.255-3.855a12.227 12.227 0 012.547-1.492 17.53 17.53 0 013.219-1.054" />
        <path d="M302.692 49.924c.755 2.366 2.669 4.95 5.548 6.669a13.678 13.678 0 005.033 1.807c.952.139 1.966.203 3.039.17 1.071-.038 2.2-.183 3.387-.454M308.371 60.795c-2.699.718-5.406 1.412-8.085 2.186-1.341.384-2.69.745-4.025 1.147l-4.008 1.193M92.611 216.334c-1.781-1.85-3.213-4.688-3.23-7.786-.029-3.104 1.357-6.484 5.134-9.322" />
        <path d="M75.356 198.414c1.784 1.853 4.716 3.461 8.128 3.92 3.408.447 7.276-.259 11.046-3.091M118.512 197.089c-1.648-1.948-2.871-4.869-2.674-7.965.096-1.55.543-3.148 1.455-4.697.921-1.537 2.31-3.021 4.275-4.349" />
        <path d="M102.542 178.209c1.652 1.953 4.458 3.733 7.828 4.384 1.684.323 3.505.359 5.39-.021 1.896-.368 3.857-1.149 5.821-2.477M112.194 186.452c-4.459 3.115-8.902 6.249-13.265 9.474M145.693 179.046c-1.545-2.02-2.599-4.98-2.247-8.074.173-1.55.702-3.13 1.701-4.627.999-1.497 2.447-2.94 4.489-4.168" />
        <path d="M130.741 159.49c1.545 2.021 4.26 3.936 7.583 4.723a14.17 14.17 0 005.387.214c1.911-.287 3.897-1.005 5.938-2.232M139.948 168.149c-4.634 2.897-9.214 5.863-13.775 8.853M173.798 162.095c-1.448-2.078-2.363-5.077-1.867-8.158.244-1.543.85-3.094 1.915-4.556 1.066-1.459 2.583-2.843 4.679-3.993" />
        <path d="M159.776 141.977c1.449 2.078 4.071 4.097 7.352 5.01 1.64.453 3.452.641 5.373.423 1.921-.217 3.94-.855 6.036-2.005M168.567 150.983l-3.566 2.047-3.547 2.073c-2.371 1.374-4.714 2.788-7.068 4.185M202.667 146.222c-1.352-2.131-2.127-5.162-1.49-8.22.317-1.532.99-3.064 2.124-4.48 1.135-1.414 2.711-2.742 4.859-3.81" />
        <path d="M189.583 125.589c1.352 2.131 3.879 4.248 7.117 5.286 1.617.516 3.416.767 5.347.626 1.932-.139 3.977-.703 6.124-1.771M197.953 134.922c-4.886 2.53-9.725 5.135-14.551 7.759M232.227 131.503c-1.247-2.184-1.873-5.241-1.087-8.268.78-3.034 3.003-6.009 7.388-7.993" />
        <path d="M220.165 110.361c1.247 2.184 3.67 4.402 6.855 5.571 3.184 1.16 7.139 1.31 11.518-.671M228.071 120.025c-5.005 2.33-9.964 4.74-14.912 7.17M262.444 118.106c-1.123-2.239-1.581-5.319-.624-8.304.948-2.992 3.339-5.858 7.837-7.622" />
        <path d="M251.581 96.434c1.122 2.238 3.422 4.565 6.539 5.882 3.116 1.307 7.055 1.644 11.546-.117M258.937 106.453c-5.13 2.093-10.214 4.278-15.283 6.491M293.3 106.326c-.967-2.298-1.219-5.396-.06-8.32a10.738 10.738 0 012.882-4.106c1.373-1.222 3.167-2.306 5.486-3.019" />
        <path d="M283.945 84.08c.967 2.299 3.108 4.748 6.128 6.24 1.51.741 3.242 1.249 5.177 1.4 1.934.153 4.047-.107 6.366-.819M290.599 94.497c-5.268 1.788-10.482 3.705-15.684 5.647M324.745 96.665c-.752-2.36-.735-5.48.686-8.301.712-1.401 1.775-2.73 3.256-3.854a12.219 12.219 0 012.546-1.492 17.53 17.53 0 013.219-1.055" />
        <path d="M317.45 73.791c.755 2.366 2.669 4.95 5.548 6.669a13.685 13.685 0 005.033 1.808c.952.138 1.966.202 3.039.169 1.072-.038 2.2-.183 3.387-.454M323.129 84.663c-2.699.718-5.406 1.412-8.085 2.186-1.341.384-2.69.745-4.024 1.146l-4.009 1.194M117.3 202.077c1.652 1.952 4.458 3.732 7.828 4.383 1.684.323 3.505.359 5.39-.021 1.896-.368 3.857-1.149 5.821-2.477M160.451 202.914c-1.545-2.02-2.599-4.98-2.247-8.075a10.36 10.36 0 011.701-4.627c.999-1.496 2.448-2.94 4.49-4.167" />
        <path d="M145.499 183.357c1.545 2.021 4.26 3.937 7.584 4.723 1.66.391 3.475.503 5.386.214 1.911-.287 3.897-1.005 5.938-2.232M154.706 192.016c-4.634 2.897-9.214 5.863-13.775 8.853M188.556 185.962c-1.448-2.078-2.362-5.077-1.867-8.158.245-1.543.85-3.095 1.916-4.555 1.066-1.46 2.582-2.844 4.678-3.994" />
        <path d="M174.534 165.845c1.449 2.078 4.071 4.097 7.353 5.01 1.639.452 3.451.641 5.372.423 1.921-.217 3.94-.855 6.036-2.005M183.325 174.851l-3.566 2.046-3.547 2.074c-2.371 1.374-4.714 2.787-7.068 4.184M217.425 170.089c-1.351-2.131-2.127-5.162-1.49-8.22.317-1.532.99-3.064 2.124-4.479 1.135-1.415 2.711-2.743 4.859-3.81" />
        <path d="M204.341 149.456c1.352 2.131 3.879 4.248 7.117 5.286a14.14 14.14 0 005.347.627c1.933-.14 3.977-.704 6.124-1.772M212.711 158.789c-4.886 2.53-9.725 5.135-14.551 7.759M246.985 155.37c-1.246-2.184-1.873-5.241-1.087-8.268.781-3.034 3.003-6.008 7.389-7.992" />
        <path d="M234.924 134.228c1.246 2.184 3.669 4.402 6.854 5.571 3.184 1.16 7.139 1.31 11.518-.671M242.83 143.893c-5.006 2.33-9.965 4.74-14.912 7.17M277.202 141.973c-1.123-2.239-1.58-5.319-.624-8.303.948-2.993 3.339-5.858 7.838-7.622" />
        <path d="M266.339 120.301c1.122 2.239 3.422 4.565 6.539 5.882 3.116 1.307 7.056 1.644 11.546-.117M273.695 130.32c-5.13 2.094-10.214 4.279-15.283 6.492M308.058 130.194c-.967-2.298-1.218-5.396-.06-8.321a10.736 10.736 0 012.882-4.106c1.373-1.221 3.167-2.306 5.486-3.018" />
        <path d="M298.703 107.948c.967 2.298 3.108 4.748 6.129 6.239a14.188 14.188 0 005.176 1.4c1.934.153 4.047-.107 6.366-.819M305.357 118.364c-5.268 1.788-10.482 3.705-15.684 5.647M203.314 209.83c-1.448-2.078-2.362-5.077-1.867-8.158.245-1.543.85-3.095 1.916-4.556 1.066-1.459 2.582-2.843 4.678-3.993" />
        <path d="M189.293 189.712c1.448 2.078 4.07 4.097 7.352 5.01 1.639.453 3.451.641 5.372.423 1.921-.217 3.941-.855 6.036-2.005M198.083 198.718l-3.566 2.047-3.547 2.073c-2.371 1.374-4.713 2.787-7.068 4.184M232.183 193.957c-1.351-2.132-2.127-5.162-1.49-8.22.317-1.532.99-3.065 2.124-4.48 1.135-1.414 2.711-2.743 4.859-3.81" />
        <path d="M219.1 173.324c1.351 2.131 3.878 4.248 7.116 5.286a14.14 14.14 0 005.347.626c1.933-.139 3.977-.704 6.124-1.771M227.469 182.657c-4.885 2.53-9.725 5.135-14.551 7.759M261.743 179.238c-1.246-2.184-1.873-5.241-1.087-8.268.781-3.034 3.003-6.009 7.389-7.993" />
        <path d="M249.682 158.096c1.246 2.184 3.669 4.402 6.854 5.571 3.184 1.16 7.139 1.31 11.518-.671M257.588 167.76c-5.006 2.33-9.965 4.74-14.912 7.17M291.96 165.841c-1.123-2.24-1.58-5.319-.624-8.304.948-2.992 3.34-5.858 7.838-7.622" />
        <path d="M281.097 144.168c1.122 2.239 3.422 4.565 6.539 5.882 3.116 1.307 7.056 1.645 11.546-.116M288.453 154.187c-5.13 2.094-10.214 4.279-15.283 6.492M322.816 154.061c-.967-2.298-1.218-5.396-.06-8.321a10.733 10.733 0 012.882-4.105c1.373-1.222 3.167-2.307 5.486-3.019" />
        <path d="M313.461 131.815c.967 2.298 3.108 4.748 6.129 6.239a14.168 14.168 0 005.176 1.4c1.934.153 4.047-.107 6.366-.819M320.115 142.231c-5.268 1.789-10.482 3.706-15.683 5.647M233.858 197.191c1.351 2.131 3.879 4.248 7.116 5.286 1.618.516 3.417.767 5.348.626 1.932-.139 3.976-.703 6.123-1.771M276.501 203.105c-1.246-2.184-1.873-5.241-1.087-8.268.781-3.034 3.003-6.009 7.389-7.993" />
        <path d="M264.44 181.963c1.246 2.184 3.669 4.402 6.855 5.571 3.183 1.16 7.138 1.31 11.518-.671M272.346 191.627c-5.006 2.331-9.965 4.741-14.912 7.171M306.718 189.708c-1.122-2.239-1.58-5.319-.624-8.304.948-2.992 3.34-5.858 7.838-7.622" />
        <path d="M295.855 168.035c1.123 2.239 3.422 4.565 6.54 5.883 3.115 1.307 7.055 1.644 11.546-.117M303.211 178.055c-5.13 2.093-10.214 4.278-15.283 6.491M334.873 166.099c-5.268 1.788-10.482 3.705-15.683 5.647M279.198 205.831c1.246 2.184 3.67 4.401 6.855 5.571 3.183 1.16 7.138 1.31 11.518-.671M321.476 213.575c-1.122-2.239-1.58-5.318-.624-8.303.948-2.993 3.34-5.858 7.838-7.622" />
        <path d="M310.613 191.903c1.123 2.239 3.422 4.565 6.54 5.882 3.115 1.307 7.055 1.645 11.546-.117M317.969 201.922c-5.13 2.094-10.214 4.279-15.283 6.492" />
      </g>
      <defs>
        <clipPath id="arrow3_svg__clip0">
          <path
            fill="#fff"
            transform="matrix(.89047 -.45505 .52592 .85054 0 159.639)"
            d="M0 0h358.807v241.318H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgArrow3;
