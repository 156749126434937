import React from 'react';

export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const { onExit } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && onExit) {
      onExit();
    }
  };

  render() {
    const { className, children, style } = this.props;
    return (
      <div ref={this.setWrapperRef} className={className} style={style}>
        {children}
      </div>
    );
  }
}
