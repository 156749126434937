const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      fontSize: {
        '2xs': '0.625rem',
      },
      gridTemplateRows: {
        8: 'repeat(8, minmax(0, 1fr))',
        12: 'repeat(12, minmax(0, 1fr))',
      },
      gridRowStart: {
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
      },
      gridRow: {
        'span-7': 'span 7 / span 7',
        'span-8': 'span 8 / span 8',
        'span-9': 'span 9 / span 9',
        'span-10': 'span 10 / span 10',
        'span-11': 'span 11 / span 11',
        'span-12': 'span 12 / span 12',
      },
      screens: {
        xs: '420px',
        '3xl': '2048px',
      },
      spacing: {
        128: '32rem',
        144: '36rem',
        160: '40rem',
        176: '44rem',
        192: '48rem',
        208: '52rem',
        240: '60rem',
        280: '70rem',
        320: '80rem',
      },
      minHeight: {
        12: '3rem',
        44: '11rem',
      },
      zIndex: {
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100',
        1001: '1001',
      },
      fill: {
        none: 'none',
      },
      colors: {
        gray: {
          soft: 'var(--color-gray-soft)',
        },
        black: {
          soft: 'var(--color-black-soft)',
          DEFAULT: 'var(--color-black-default)',
          strong: 'var(--color-black-strong)',
        },
        surfaces: {
          soft: 'var(--color-surfaces-soft)',
          DEFAULT: 'var(--color-surfaces-default)',
          strong: 'var(--color-surfaces-strong)',
          strongest: 'var(--color-surfaces-strongest)',
          divider: 'var(--color-surfaces-divider)',
        },
        primary: {
          soft: 'var(--color-primary-soft)',
          medium: 'var(--color-primary-medium)',
          DEFAULT: 'var(--color-primary-default)',
          strong: 'var(--color-primary-strong)',
        },
        secondary: {
          soft: 'var(--color-secondary-soft)',
          DEFAULT: 'var(--color-secondary-default)',
        },
        success: {
          soft: 'var(--color-success-soft)',
          medium: 'var(--color-success-medium)',
          DEFAULT: 'var(--color-success-default)',
        },
        warning: {
          soft: 'var(--color-warning-soft)',
          medium: 'var(--color-warning-medium)',
          DEFAULT: 'var(--color-warning-default)',
        },
        danger: {
          soft: 'var(--color-danger-soft)',
          medium: 'var(--color-danger-medium)',
          DEFAULT: 'var(--color-danger-default)',
        },
      },
      strokeWidth: {
        '3/2': '1.5',
        '5/2': '2.5',
      },
      rotate: {
        150: '150deg',
        '-27': '-27deg',
      },
      fontFamily: {
        sans: ["'Poppins', sans-serif", ...defaultTheme.fontFamily.sans],
        inter: "'Inter', sans-serif",
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({
      strategy: 'class',
    }),
    require('@tailwindcss/line-clamp'),
  ],
};
