import React from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/Tooltip';
import { Heart } from 'assets/icons';

export type LikeIconProps = {
  disabled: boolean;
  liked: boolean;
  onClick: () => void;
};

const LikeIcon = ({ disabled, liked, onClick }: LikeIconProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Tooltip
      content={
        disabled && !liked
          ? t('sessions:YouHaveReachedTheLimitOfAllowedVotes')
          : undefined
      }
    >
      <div
        className={`m-auto flex h-11 w-11 items-center justify-center rounded-full ${
          disabled && !liked
            ? 'cursor-not-allowed'
            : 'cursor-pointer hover:bg-gray-100'
        }`}
        onClick={onClick}
      >
        <Heart
          className={`h-7 w-7 stroke-1 ${
            liked
              ? 'fill-current text-red-500'
              : disabled
              ? 'text-black-soft'
              : 'fill-none text-black'
          }`}
        />
      </div>
    </Tooltip>
  );
};

export default LikeIcon;
