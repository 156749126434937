import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFirebaseConnect } from 'react-redux-firebase';

import { Communities, Community, Session } from 'types/types';
import { WithId } from 'types/typesUtils';
import { useSelectorArray } from 'services/firebase';

import Thumbnail from 'components/Thumbnail';
import List from 'components/List';
import Tag from 'components/Tag';

export type SessionListItemProps = {
  accessCode: string;
  title: string;
  community: Community | null;
  scheduledAt: [number, number];
  rolesLoaded: boolean;
  isFacilitator: boolean;
  isOrganizer: boolean;
};

const SessionListItem = ({
  accessCode,
  title,
  community,
  scheduledAt,
  rolesLoaded,
  isFacilitator,
  isOrganizer,
}: SessionListItemProps) => {
  const { t } = useTranslation();

  return (
    <div
      key={accessCode}
      className="relative flex w-full flex-row items-center rounded-xl border border-surfaces-divider p-6 hover:bg-primary-soft"
    >
      {rolesLoaded ? (
        <div className="absolute top-4 right-4">
          {isOrganizer ? (
            <Tag text={t('common:Organizer')} color="success" />
          ) : isFacilitator ? (
            <Tag text={t('common:Facilitator')} color="primary" />
          ) : (
            <Tag text={t('common:Participant')} color="gray" />
          )}
        </div>
      ) : null}
      <div className="flex flex-grow flex-col">
        <Link to={`/${accessCode}`}>
          <div className="">
            <p className="text-sm font-semibold text-primary">
              {moment(scheduledAt[0])
                .format(t('misc:dateTimeFormat'))
                .toUpperCase()}
            </p>

            <h3 className="mt-1 text-xl font-semibold">{title}</h3>

            {community ? (
              <div className="mt-4 flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <Thumbnail name={community.name} size="tiny" />
                  <p className="ml-1 text-base font-semibold">
                    {community.name}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </Link>
      </div>
    </div>
  );
};

export type SessionsListProps = {
  userId: string;
  sessions: WithId<Session>[];
  communities: Communities;
};

const SessionsList = ({
  userId,
  sessions,
  communities,
}: SessionsListProps): JSX.Element => {
  const loadArray: string[] = [
    `organizations/${userId}`,
    `facilitations/${userId}`,
  ];

  useFirebaseConnect(loadArray);

  const [isRolesLoaded, roles] = useSelectorArray(
    loadArray || [],
    (data: any) => {
      return {
        facilitations: data.facilitations?.[userId],
        organizations: data.organizations?.[userId],
      };
    },
    (value) => value !== undefined
  );

  return (
    <List
      showHeader={false}
      title={''}
      items={sessions}
      itemsPerPage={5}
      renderItem={({ id, accessCode, scheduledAt, title, communityId }) => {
        const community = communityId ? communities[communityId] : null;
        return (
          <SessionListItem
            accessCode={accessCode}
            title={title}
            community={community}
            scheduledAt={scheduledAt}
            rolesLoaded={isRolesLoaded}
            isFacilitator={!!roles?.facilitations?.[id]}
            isOrganizer={!!roles?.organizations?.[id]}
          />
        );
      }}
    />
  );
};

export default SessionsList;
