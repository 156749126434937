import React from 'react';

import Page from 'componentsOld/Page';
import { Typography } from 'antd';
import { getFormattedDateTimeEn, getFormattedTime } from 'services/datetimes';
import moment from 'moment';

const { Title } = Typography;

const IntroScreen = ({
  welcomeParticipants,
  setWelcomeParticipants,
  title,
  scheduleDateTime,
  editable,
}) => {
  return (
    <Page>
      <Title
        editable={editable && { onChange: setWelcomeParticipants }}
        level={3}
        style={{ marginBottom: '50px', marginTop: '100px' }}
      >
        {welcomeParticipants}
      </Title>
      <Title level={3} style={{ marginBottom: '20px' }}>
        Your session:
      </Title>
      <Title level={2} style={{ marginTop: '0px', marginBottom: '20px' }}>
        {title}
      </Title>
      {scheduleDateTime &&
      moment().isBefore(moment(scheduleDateTime[0]).clone().startOf('day')) ? (
        <Title level={3} style={{ marginTop: '0px' }}>
          will start {getFormattedDateTimeEn(moment(scheduleDateTime[0]))}
        </Title>
      ) : scheduleDateTime && moment().isBefore(moment(scheduleDateTime[0])) ? (
        <Title level={3} style={{ marginTop: '0px' }}>
          will start today at {getFormattedTime(moment(scheduleDateTime[0]))}
        </Title>
      ) : (
        <Title level={3} style={{ marginTop: '0px' }}>
          will start soon
        </Title>
      )}
    </Page>
  );
};

export default IntroScreen;
