import React, { createElement } from 'react';

type TagType = {
  text: string;
  className?: string;
};

const delimiters = [
  {
    delimiter: '**',
    className: 'font-semibold',
  },
  {
    delimiter: '__',
    className: 'underline',
  },
  {
    delimiter: '!!',
    className: 'text-danger',
  },
  {
    delimiter: '[[',
    className: 'bg-primary-soft text-primary px-2 rounded-md',
  },
];

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export type FormattedTextProps = {
  children: string;
  className?: string;
  as?: string;
};

const MAGIC_SPLITTER_TOKEN = '&&&';

const FormattedText = ({
  children,
  as = 'p',
  className = '',
}: FormattedTextProps): JSX.Element => {
  const regexpString = delimiters.reduce((current, { delimiter }, index) => {
    const escapedDelimiter = escapeRegExp(delimiter);
    return (
      current +
      `${index > 0 ? '|' : ''}${escapedDelimiter}(.*?)${escapedDelimiter}`
    );
  }, '');

  const re = new RegExp(regexpString, 'g');
  const found = children.match(re);

  let tags: TagType[] | null = null;
  if (found && found.length > 0) {
    let currentString = children;
    tags = found.reduce<TagType[]>((current, match) => {
      const splitted = currentString
        .replace(match, MAGIC_SPLITTER_TOKEN)
        .split(MAGIC_SPLITTER_TOKEN);
      if (splitted.length > 1) {
        currentString = splitted[1];
        current.push({
          text: splitted[0] || '',
        });

        const { delimiter, className } = delimiters.find(({ delimiter }) =>
          match.startsWith(delimiter)
        ) || { delimiter: null };
        if (delimiter && className) {
          current.push({
            text: match.replaceAll(delimiter, ''),
            className: className,
          });
        }
      }

      return current;
    }, []);
    tags.push({
      text: currentString,
    });
  }

  const block =
    tags && tags.length > 0
      ? tags.map((tag, index) =>
          tag.className ? (
            <span key={index} className={tag.className}>
              {tag.text}
            </span>
          ) : (
            tag.text
          )
        )
      : children;
  return as === 'Fragment' ? (
    <>{block}</>
  ) : (
    createElement(as, { className: className }, block)
  );
};

export default FormattedText;
