import React, { useMemo } from 'react';
import {
  isLoaded,
  useFirebaseConnect,
  useFirebase,
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { URL_REGEXP } from 'utils/utils';

import { Session } from 'types/types';
import LoadingScreen from 'screens/LoadingScreen';
import Input from 'frameworks/formik/Input';
import RangePickerInput from 'frameworks/formik/RangePickerInput';
import TextArea from 'frameworks/formik/TextArea';
import ListItemInput from 'frameworks/formik/ListItemInput';
import AutoSave from 'frameworks/formik/AutoSave';
import { LanguageSelectorInput } from 'blocks/LanguageSelector';

import { SessionTypeInput } from 'blocks/SessionType';

import { DEFAULT_SESSION_TYPE_VALUE } from 'constants/AppConfig';

export type FormData = {
  title: string;
  sessionDate: [Date, Date];
  location: string;
};

export type SettingsViewProps = { sessionId: string; userId: string };

const SettingsView = ({ sessionId }: SettingsViewProps): JSX.Element => {
  const { t } = useTranslation();
  const db = useFirebase();
  const baseRef = sessionId ? `sessionsNext/${sessionId}/` : null;
  const refsToFetch: string[] = useMemo(() => {
    const refs: string[] = [];
    if (baseRef) {
      refs.push(baseRef);
    }
    return refs;
  }, [baseRef]);

  useFirebaseConnect(refsToFetch);

  const session: Session | null = useSelector(
    (state: any) => sessionId && state.firebase.data.sessionsNext?.[sessionId]
  );

  return isLoaded(session) ? (
    <div className="mb-16">
      <Formik
        enableReinitialize
        initialValues={{
          title: session.title,
          sessionDate: [
            new Date(session.scheduledAt[0]),
            new Date(session.scheduledAt[1]),
          ],
          location: session.location || '',
          meetingUrl: session.meetingUrl || '',
          description: session.description || '',
          goals: session.goals || [],
          language: session.language || 'fr',
          type: session.type || DEFAULT_SESSION_TYPE_VALUE,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(t('form:fieldRequired')),
          goals: Yup.array().of(
            Yup.string().min(10, t('templates:GoalsMustBe10CharLong'))
          ),
          meetingUrl: Yup.string().matches(
            URL_REGEXP,
            t('form:FieldMustBeValidURL')
          ),
        })}
        onSubmit={(values, formikBag) => {
          if (baseRef) {
            db.ref(baseRef).update({
              title: values.title,
              type: values.type,
              scheduledAt: [
                values.sessionDate[0].getTime(),
                values.sessionDate[1].getTime(),
              ],
              location: values.location,
              meetingUrl: values.meetingUrl,
              description: values.description,
              goals: values.goals.filter((goal) => goal !== ''),
              language: values.language,
            });
            formikBag.resetForm({ values });
          }
        }}
      >
        {({ values }) => (
          <Form>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-4">
                <h1 className="text-3xl font-semibold">
                  {t('sessions:configureEvent')}
                </h1>
                <LanguageSelectorInput name="language" />
              </div>
              <AutoSave />
            </div>

            <div className="mt-6 flex flex-col space-y-8">
              <Input
                name="title"
                label={t('sessions:SessionTitle')}
                placeholder={t('sessions:ExNewSession')}
              />
              {!session.isTemplate ? (
                <>
                  <RangePickerInput
                    name="sessionDate"
                    label={t('sessions:SessionDateTime')}
                    type={'datetime'}
                  />

                  <SessionTypeInput
                    name="type"
                    label={t('sessions:SessionType')}
                  />
                  {values.type === 'on_site' || values.type === 'hybrid' ? (
                    <TextArea
                      name="location"
                      label={t('sessions:SessionLocation')}
                      placeholder={t('sessions:ExLocation')}
                    />
                  ) : null}

                  {values.type === 'digital' || values.type === 'hybrid' ? (
                    <Input
                      name="meetingUrl"
                      label={t('sessions:SessionMeetingUrl')}
                      placeholder={t('sessions:ExLocationUrl')}
                    />
                  ) : null}
                </>
              ) : null}

              <TextArea
                name="description"
                label={t('common:Description')}
                placeholder={t('templates:ExThisTemplate')}
              />
              <ListItemInput
                name="goals"
                cols={2}
                label={t('sessions:goalsTitle')}
                placeholder={t('templates:ExAllowToShareOnFormation')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default SettingsView;
