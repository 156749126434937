import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { Communities, Hit, User, Users } from 'types/types';
import { FullDocMeta } from './types';
import _ from 'lodash';
import { WithId } from 'types/typesUtils';

export const useFullDocs = (searchResults: Hit[] | null): FullDocMeta[] => {
  const usersRef: string[] = [];
  if (searchResults) {
    searchResults.forEach((doc) => {
      // FIXME: in some case doc is null and application crashes
      // It is weird #1034
      if (doc.authorsIds) {
        (_.isPlainObject(doc.authorsIds)
          ? Object.keys(doc.authorsIds)
          : (doc.authorsIds as string[])
        ).forEach((userId) => {
          usersRef.push(`users/${userId}`);
        });
      }
    });
  }

  useFirebaseConnect([
    ...usersRef.map((userRef) => ({
      path: userRef,
    })),
    {
      path: `communities`,
    },
  ]);

  const users: Users = useSelector(({ firebase: { data } }: any) => data.users);
  const communities: Communities = useSelector(
    ({ firebase: { data } }: any) => data.communities
  );

  const docs: FullDocMeta[] = useMemo(
    () =>
      (searchResults || []).map((doc) => ({
        ...doc,
        id: doc.objectID,
        authors: _.keyBy(
          (!doc.authorsIds
            ? []
            : _.isPlainObject(doc.authorsIds)
            ? Object.keys(doc.authorsIds)
            : (doc.authorsIds as string[]) || []
          ).reduce(
            (
              {
                authors,
                unknownPresent,
              }: { authors: WithId<User>[]; unknownPresent: boolean },
              userId
            ) => {
              const author = users?.[userId];
              const auth = {
                id: userId,
                name: author?.name || 'Unknown author',
                email: author?.email || 'unknown@email.com',
              };

              let newUnknown = unknownPresent;
              if ((author?.name && author?.email) || !unknownPresent) {
                authors.push(auth);
                if (!author?.name || !author?.email) {
                  newUnknown = true;
                }
              }

              return { authors, unknownPresent: newUnknown };
            },
            { authors: [], unknownPresent: false }
          ).authors,
          'id'
        ),
        community: communities?.[doc.communityId]?.name || 'Unknown community',
      })),
    [searchResults, users, communities]
  );

  return docs;
};
