import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import copy from 'copy-to-clipboard';

import Input from './Input';
import RoundedButton from 'components/RoundedButton';
import { ClipboardCopy, Check } from 'assets/icons';

export type InputCopyProps = {
  name: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChangeValue?: (value: string, hasError: boolean) => void;
};

const InputCopy = ({
  name,
  label,
  readOnly,
  onChangeValue,
}: InputCopyProps): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const { values } = useFormikContext<{ [name: string]: any }>();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <div className="flex w-full flex-row items-center space-x-2">
      <Input
        name={name}
        readOnly={readOnly}
        onChangeValue={onChangeValue}
        label={label}
      />
      <RoundedButton
        onClick={() => {
          copy(values[name]);
          setCopied(true);
        }}
        color={copied ? 'success' : 'primary'}
        className="mt-2"
      >
        {copied ? <Check className="stroke-2" /> : <ClipboardCopy />}
      </RoundedButton>
    </div>
  );
};

export default InputCopy;
