import { SyncElement } from '../../model';
import { getTarget } from '../../path';
/**
 * Applies a remove text operation to a SharedType.
 *
 * @param doc
 * @param op
 */
export default function removeText(doc, op) {
    const node = getTarget(doc, op.path);
    const nodeText = SyncElement.getText(node);
    nodeText.delete(op.offset, op.text.length);
    return doc;
}
