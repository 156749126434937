import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { updateMyEmail, updateMyName, updateMyPassword } from 'model/users';

import MainBar from 'components/MainBar';
import Avatar from 'components/Avatar';
import Loading from 'screens/LoadingScreen';
import Button from 'components/Button';
import ModalReauthenticateUser from 'blocks/ModalReauthenticateUser';
import Input from 'frameworks/formik/Input';
import { passwordSchema } from './login/utils';
import { useUserContextWithDefaults } from 'contexts/UserContext';

type ProfileInformationProps = {
  label: string;
  value: string;
  onSubmit?: (value: string) => Promise<void>;
  secure?: boolean;
  secret?: boolean;
  fieldValidation: Yup.StringSchema;
  autoComplete?: string;
};

const ProfileInformation = ({
  label,
  value,
  onSubmit,
  secure = false,
  secret = false,
  fieldValidation,
  autoComplete,
}: ProfileInformationProps): JSX.Element => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [popAuth, setPopAuth] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (saved) {
      setTimeout(() => setSaved(false), 2000);
    }
  }, [saved]);
  return (
    <>
      <Formik
        initialValues={{ value }}
        validationSchema={Yup.object({
          value: fieldValidation,
        })}
        onSubmit={async ({ value }) => {
          setSaving(true);
          try {
            await onSubmit?.(value);
            setSaved(true);
          } catch (e) {
            console.error(e);
          } finally {
            setSaving(false);
            setEditing(false);
          }
        }}
      >
        <Form>
          <div className="flex w-full flex-row border-b border-surfaces-divider pb-5">
            <div className="mr-5 flex-grow">
              <label htmlFor="value" className="text-lg font-semibold">
                {label}
              </label>
              <div>
                {editing ? (
                  <Input
                    name="value"
                    type={secret ? 'password' : 'text'}
                    autoComplete={autoComplete}
                  />
                ) : (
                  <p>{secret ? '******' : value}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <button
                type="button"
                className={`cursor-pointer text-base font-semibold ${
                  saved ? 'text-success' : 'text-primary'
                }`}
                onClick={() =>
                  editing
                    ? setEditing(false)
                    : secure
                    ? setPopAuth(true)
                    : setEditing(true)
                }
              >
                {editing
                  ? t('common:Cancel')
                  : saved
                  ? t('common:updated')
                  : t('common:Update')}
              </button>
              {editing ? (
                <Button
                  type="submit"
                  text="Valider"
                  className="mt-3"
                  size="md"
                  loading={saving}
                />
              ) : null}
            </div>
          </div>
        </Form>
      </Formik>
      <ModalReauthenticateUser
        open={popAuth}
        onClose={() => {
          setPopAuth(false);
        }}
        onResult={(result) => {
          if (result) {
            setEditing(true);
            setPopAuth(false);
          }
        }}
      />
    </>
  );
};

const ProfileScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const { userId, userType, userName, userEmail } =
    useUserContextWithDefaults();

  const cardClassNames =
    'bg-white sm:border sm:border-surfaces-strong sm:rounded-xl p-8';

  return userId ? (
    <div className="relative min-h-screen w-full bg-white pb-12 text-left sm:bg-surfaces-soft">
      <MainBar userId={userId} userType={userType} userName={userName} />
      <div className="absolute inset-0 top-16 overflow-y-auto">
        <div
          className={`mx-auto mt-10 flex max-w-lg flex-col space-y-5 sm:space-y-10 md:max-w-4xl md:flex-row md:space-x-10 md:space-y-0 lg:max-w-5xl`}
        >
          <div className={`${cardClassNames} h-full w-full md:w-96`}>
            <div className="inline-flex items-center">
              <Avatar name={userName} size="xl" />
              <div className="ml-6 flex flex-grow flex-col">
                <h3 className="text-xl font-semibold">{userName}</h3>
              </div>
            </div>
          </div>
          <div className={`${cardClassNames} h-full flex-grow`}>
            <h2 className="mb-10 text-2xl font-semibold">
              {t('profile:myInformations')}
            </h2>
            <div className="w-full space-y-6">
              <ProfileInformation
                value={userName}
                label={t('login:displayNameLabel')}
                onSubmit={updateMyName}
                fieldValidation={Yup.string().required(t('form:fieldRequired'))}
              />
              <>
                <input hidden name="username" autoComplete="username" />
                <ProfileInformation
                  value={''}
                  label={t('login:passwordLabel')}
                  onSubmit={updateMyPassword}
                  fieldValidation={passwordSchema(t)}
                  secure={true}
                  secret={true}
                  autoComplete="new-password"
                />
              </>
              <ProfileInformation
                value={userEmail}
                label={t('login:emailAddressLabel')}
                onSubmit={updateMyEmail}
                fieldValidation={Yup.string()
                  .email(t('form:invalideEmail'))
                  .required(t('form:fieldRequired'))}
                secure={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default ProfileScreen;
