import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { domainUrl } from 'config';

import Layout from './Layout';
import Button from 'components/Button';
import ExtendedInput from 'frameworks/formik/ExtendedInput';
import Alert from 'components/Alert';

import { HistoryState } from 'utils/history';
import { gerErrorDescription } from 'services/firebase';
import { sendResetPasswordEmail } from 'model/users';

type FormData = {
  email: string;
};

const ForgetPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation<HistoryState>();
  const state = location.state;

  const intialEmail = state?.email ? state?.email : '';
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string | null>(
    null
  );
  const [success, setSuccess] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(intialEmail);
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const continueUrl =
    query.get('continueUrl') && encodeURIComponent(query.get('continueUrl')!);

  const links = {
    signIn: {
      text: t('login:linkSignin'),
      to: {
        pathname: '/login',
        search: `${continueUrl ? `?continueUrl=${continueUrl}` : ''}`,
        state: { email: currentEmail, from: state?.from },
      },
    },
  };

  const SubmitButton = (): JSX.Element => {
    const { isValid } = useFormikContext<FormData>();

    return (
      <Button
        disabled={!isValid}
        loading={loading}
        type="submit"
        text={t('login:forgetPasswordButton')}
      />
    );
  };

  return (
    <Layout
      title={t('login:forgetPasswordTitle')}
      subtitleText={t('login:linkSigninDescription')}
      subtitleLink={links.signIn}
    >
      <div className="mt-6 md:mt-10">
        {success ? (
          <p className="rounded-md bg-success-soft p-5 text-center text-3xl font-thin text-success">
            {t('login:forgetPasswordSuccess')}
          </p>
        ) : (
          <>
            {globalErrorMessage ? (
              <Alert
                title={t('login:forgetPasswordErrorTitle')}
                message={globalErrorMessage}
                type="error"
                className="mb-4"
              />
            ) : null}
            <Formik
              validateOnMount
              initialValues={{ email: intialEmail }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email(t('form:invalideEmail'))
                  .required(t('form:fieldRequired')),
              })}
              onSubmit={async (values) => {
                setLoading(true);
                try {
                  setGlobalErrorMessage(null);
                  await sendResetPasswordEmail(
                    values.email,
                    `${domainUrl}${
                      state?.from?.pathname ||
                      (continueUrl && decodeURIComponent(continueUrl)) ||
                      ''
                    }`
                  );
                  setSuccess(true);
                } catch (error) {
                  console.error(error);
                  setGlobalErrorMessage(
                    gerErrorDescription((error as any).code)
                  );
                }
                setLoading(false);
              }}
            >
              <Form>
                <ExtendedInput
                  label={t('login:emailAddressLabel')}
                  name="email"
                  inputType="email"
                  onChange={setCurrentEmail}
                />
                <div className="mt-6 flex w-full flex-row-reverse">
                  <SubmitButton />
                </div>
              </Form>
            </Formik>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ForgetPassword;
