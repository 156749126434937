import React, { useState } from 'react';

import { Typography, Select } from 'antd';

const { Option } = Select;
const { Text } = Typography;

export type EditableOptionDataType = Record<
  string,
  { desc: string; disabled?: boolean }
>;

export type EditableOptionProps = {
  value: string;
  editable: boolean;
  onChange: (val: string) => void;
  options: EditableOptionDataType;
};

const EditableOption = ({
  value,
  editable,
  onChange,
  options,
  forceEditing,
  size,
}: EditableOptionProps & {
  forceEditing?: boolean;
  size?: 'large' | 'small';
}) => {
  const [editing, setEditing] = useState(false);

  const onEditStart = () => {
    setEditing(true);
  };

  const valueChanged = (val: string) => {
    setEditing(false);
    onChange && onChange(val);
  };

  return editing || forceEditing ? (
    <Select
      defaultValue={value}
      size={size}
      onChange={valueChanged}
      onBlur={() => setEditing(false)}
    >
      {Object.entries(options).map(([key, { desc, disabled }]) => (
        <Option key={key} value={key} disabled={disabled}>
          {desc}
        </Option>
      ))}
    </Select>
  ) : (
    <Text editable={editable && { editing: false, onStart: onEditStart }}>
      {options[value].desc}
    </Text>
  );
};

export default EditableOption;
