import { database as db } from 'services/firebase';
import Copyable from 'components/Copyable';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { computePostSummary } from 'services/tln';
import { ActionRecord, LoadableString, User } from 'types/types';
import { Quote } from 'assets/icons';
import _ from 'lodash';
import Button from 'components/Button';
import { Nullable } from 'types/typesUtils';

export type PostBlockProps = {
  sessionId: string;
  userId: string;
  post: string;
  postSummary: LoadableString;
  user: User | undefined;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
};

const PostBlock = ({
  sessionId,
  userId,
  post,
  postSummary,
  user,
  allowAutomatedSynthesis,
}: PostBlockProps) => {
  const { t } = useTranslation();

  const userName = user?.name || t('users:UnknownUser');
  const textToCopy = `${userName} - ${post}`;

  const setSummary = useCallback(
    async (state: LoadableString) => {
      await db
        .ref(`sessionsNextData/${sessionId}/debrief/summaries/post/${userId}`)
        .set(state);
    },
    [sessionId, userId]
  );

  const computeSummary = useCallback(async () => {
    try {
      setSummary('loading');
      const summary = await computePostSummary(post, userName!);
      await setSummary(summary);
    } catch (e) {
      console.error(e);
      setSummary('error');
    }
  }, [post, userName, setSummary]);

  useEffect(() => {
    if (!postSummary && allowAutomatedSynthesis) {
      computeSummary();
    }
  }, [postSummary, computeSummary, allowAutomatedSynthesis]);

  return (
    <blockquote className="relative rounded-md bg-gray-50 px-8 pt-6 pb-2 text-lg italic">
      <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
      <Copyable textToCopy={textToCopy}>
        <p className="text-gray-600">{post}</p>
      </Copyable>
      <p className="text-sm capitalize text-primary">{userName}</p>

      {postSummary ? (
        <>
          <div className="w-full my-4">
            <div className=" border-b-2 border-surfaces-divider max-w-lg mx-auto" />
          </div>
          <div className="space-y-4">
            <Copyable textToCopy={postSummary!}>
              <p className="text-gray-600">
                {postSummary === 'error'
                  ? t('sessions:SummaryError')
                  : postSummary === 'loading'
                  ? t('sessions:BeeingGenerated')
                  : postSummary}
              </p>
            </Copyable>

            {!_.includes([null, 'loading'], postSummary) ? (
              <Button
                color="gray"
                text={t('sessions:Regenerate')}
                onClick={() => setSummary(null)}
                size="sm"
              />
            ) : null}
          </div>
        </>
      ) : null}
    </blockquote>
  );
};

export default PostBlock;
