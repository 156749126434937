import { Node, Text } from 'slate';
import invariant from 'tiny-invariant';
import { toSlatePath } from '../utils/convert';
/**
 * Translates a Yjs text event into a slate operations.
 *
 * @param event
 */
export default function translateTextEvent(editor, event) {
    const targetPath = toSlatePath(event.path);
    const targetText = Node.get(editor, targetPath);
    invariant(Text.isText(targetText), 'Cannot apply text event to non-text node');
    let offset = 0;
    let { text } = targetText;
    const ops = [];
    event.changes.delta.forEach((delta) => {
        var _a, _b;
        if ('retain' in delta) {
            offset += (_a = delta.retain) !== null && _a !== void 0 ? _a : 0;
        }
        if ('delete' in delta) {
            const endOffset = offset + ((_b = delta.delete) !== null && _b !== void 0 ? _b : 0);
            ops.push({
                type: 'remove_text',
                offset,
                path: targetPath,
                text: text.slice(offset, endOffset),
            });
            text = text.slice(0, offset) + text.slice(endOffset);
        }
        if ('insert' in delta) {
            invariant(typeof delta.insert === 'string', `Unexpected text insert content type: expected string, got ${typeof delta.insert}`);
            ops.push({
                type: 'insert_text',
                offset,
                text: delta.insert,
                path: targetPath,
            });
            offset += delta.insert.length;
            text = text.slice(0, offset) + delta.insert + text.slice(offset);
        }
    });
    return ops;
}
