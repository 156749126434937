import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { addCommunity } from 'model/communitiesManagement';
import { Store } from 'rc-field-form/lib/interface';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 16 },
};

const CreateCommunityForm = ({ close }: { close: () => void }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Store) => {
    const { communityName } = values;
    console.log('Success:', communityName);
    setLoading(true);

    await addCommunity(communityName);

    setLoading(false);

    close();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ noMatching: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Community Name"
        name="communityName"
        rules={[
          {
            required: true,
            message: 'Please input a name for this new community!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button htmlType="submit" loading={loading}>
          {!loading ? 'Create' : '   '}
        </Button>
        <Button type="link" onClick={close}>
          Annuler
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateCommunityForm;
