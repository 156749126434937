import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from 'assets/icons';

export type ModalProps = {
  open?: boolean;
  onClose?: () => void;
  description?: string;
  body?: React.ReactNode;
  title?: React.ReactNode;
  titleCenter?: boolean;
  footer?: React.ReactNode;
  className?: string;
  compact?: boolean;
  fullHeight?: boolean;
  largeWidth?: boolean;
};

const Modal = ({
  open,
  onClose,
  description,
  body,
  title,
  footer,
  className = '',
  compact = false,
  titleCenter = true,
  fullHeight = false,
  largeWidth = false,
}: ModalProps): JSX.Element => {
  // ref used to avoid Focus trap
  let refDiv = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        onClose={onClose || (() => {})}
        className="fixed inset-0 z-1001"
        initialFocus={refDiv}
      >
        <div className="flex h-full w-full px-4">
          <Transition.Child
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-black opacity-30"
              onClick={onClose}
            />
          </Transition.Child>
          <div className="z-100 mx-auto mt-12">
            <Transition.Child
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                ref={refDiv}
                className={`z-50 flex ${
                  fullHeight ? 'h-[90vh]' : ''
                } max-h-full flex-col bg-white ${
                  compact ? 'p-4' : 'p-8'
                } mx-auto ${
                  largeWidth ? 'w-[75vw]' : ''
                } max-w-4xl overflow-y-auto rounded-3xl ${className}`}
              >
                <div className="flex flex-row items-start justify-end">
                  {title && (
                    <div className="flex flex-grow items-center">
                      <Dialog.Title
                        className={`w-full pb-2 text-3xl font-semibold ${
                          titleCenter ? 'text-center' : ''
                        } `}
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  )}
                  {onClose ? (
                    <button className="flex" onClick={onClose}>
                      <X className="h-6 w-6 stroke-3/2" />
                    </button>
                  ) : null}
                </div>
                {description && (
                  <Dialog.Description>{description}</Dialog.Description>
                )}
                <div
                  className={`mt-4 flex-shrink flex-grow overflow-y-auto text-lg`}
                >
                  {body}
                </div>
                {footer ? (
                  <div className="mt-10 flex flex-col items-end">{footer}</div>
                ) : null}
                {/* <div className={`bottom-0 bg-white py-6  z-50 ${className}`}>
              <Button onClick={onClose} text={t('common:Close')} />
            </div> */}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
