import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import _ from 'lodash';

import {
  Activities,
  RoleBar,
  Session,
  TrueSet,
  SessionLanguage,
  Communities,
  UserType,
} from 'types/types';
import { useFacilitators, useOrganizers } from 'model/dataHooks';
import { useCommunities } from 'model/communitiesManagement';

import AutoSave from 'frameworks/formik/AutoSave';
import Input from 'frameworks/formik/Input';
import CommunitySelector from 'blocks/CommunitySelector';
import RangePickerInput from 'frameworks/formik/RangePickerInput';
import UserSelector from 'blocks/UserSelector';
import Button from 'components/Button';
import { LanguageSelectorInput } from 'blocks/LanguageSelector';
import Spinner from 'components/Spinner';
import { isGlobalAdmin } from 'model/users';

type FormValues = {
  title: string;
  communityId: string | undefined;
  scheduledAt: [Date, Date];
  organizers: TrueSet;
  facilitators: TrueSet;
  language: SessionLanguage;
};

const FormSettings = ({
  userId,
  userType,
  communities,
  values,
  formButton,
}: {
  userId: string;
  userType: UserType;
  communities: Communities | undefined;
  values: FormValues;
  formButton: JSX.Element;
}) => {
  const { t } = useTranslation();
  const organizersSelector = useMemo(() => {
    return (
      <UserSelector
        userId={userId}
        userType={userType}
        name="organizers"
        communityId={values.communityId}
        sessionId={undefined}
      />
    );
  }, [userId, userType, values.communityId]);

  const facilitatorsSelector = useMemo(() => {
    return (
      <UserSelector
        userId={userId}
        userType={userType}
        name="facilitators"
        communityId={values.communityId}
        sessionId={undefined}
      />
    );
  }, [userId, userType, values.communityId]);

  return (
    <Form className="relative flex h-full flex-col">
      <div className="mb-14 grow space-y-6 overflow-y-auto overflow-x-hidden">
        <Input name="title" label={t('sessions:TitleLabel')} />
        <LanguageSelectorInput
          label={t('sessions:LanguageLabel')}
          name="language"
          compact={false}
        />
        <RangePickerInput
          name="scheduledAt"
          label={t('sessions:SessionDateTime')}
          type="datetime"
        />
        {_.size(communities) > 0 ? (
          <CommunitySelector
            name="communityId"
            label={t('sessions:CommunityLabel')}
            communitiesAvailable={communities || {}}
          />
        ) : null}
        {values.communityId && (
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <div>
              <label className="text-lg font-semibold" htmlFor={'organizers'}>
                {t('sessions:OrganizersLabel')}
              </label>
              {organizersSelector}
            </div>
            <div>
              <label className="text-lg font-semibold" htmlFor={'facilitators'}>
                {t('sessions:facilitatorsLabel')}
              </label>
              {facilitatorsSelector}
            </div>
          </div>
        )}
      </div>

      <div className="absolute inset-x-0 bottom-0 flex items-center justify-end space-x-4">
        <AutoSave />
        {formButton}
      </div>
    </Form>
  );
};

const GlobalSettings = ({
  userId,
  userType,
  session,
  sessionId,
  onClose,
}: {
  userId: string;
  userType: UserType;
  role: RoleBar;
  sessionId: string;
  session: Session;
  activities: Activities;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const db = useFirebase();
  const baseRef = sessionId ? `sessionsNext/${sessionId}/` : null;

  const [facilitators, , setFacilitators] = useFacilitators(sessionId);
  const [organizers, , setOrganizers] = useOrganizers(sessionId);
  const [communities, communitiesLoading] = useCommunities(
    userId,
    isGlobalAdmin(userType) ? 'superadmin' : 'facilitator',
    sessionId
  );

  return !communitiesLoading ? (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        title: session.title,
        communityId: session.communityId,
        scheduledAt: [
          new Date(session.scheduledAt[0]),
          new Date(session.scheduledAt[1]),
        ],
        organizers: organizers,
        facilitators: facilitators,
        language: session.language || 'fr',
      }}
      validationSchema={Yup.object({
        title: Yup.string().required(t('form:fieldRequired')),
      })}
      onSubmit={(values, formikBag) => {
        try {
          let updated = false;
          if (baseRef) {
            db.ref(baseRef).update({
              title: values.title,
              communityId: values.communityId,
              scheduledAt: [
                values.scheduledAt[0].getTime(),
                values.scheduledAt[1].getTime(),
              ],
              language: values.language,
            });
            updated = true;
          }
          if (!_.isEqual(organizers, values.organizers)) {
            setOrganizers(values.organizers, organizers);
            updated = true;
          }
          if (!_.isEqual(facilitators, values.facilitators)) {
            setFacilitators(values.facilitators, facilitators);
            updated = true;
          }

          if (updated) {
            formikBag.resetForm({ values });
          }
        } catch (e) {
          console.error(e);
        } finally {
        }
      }}
    >
      {({ values }) => (
        <FormSettings
          userId={userId}
          userType={userType}
          communities={communities}
          values={values}
          formButton={<Button text={t('common:Ok')} onClick={onClose} />}
        />
      )}
    </Formik>
  ) : (
    <Spinner className="h-8 w-8 text-primary" />
  );
};

export default GlobalSettings;
