import React, { useMemo } from 'react';
import { database as db } from 'services/firebase';
import _ from 'lodash';

import {
  Task as TaskType,
  replaceAccessCode,
  replaceSessionId,
} from '../../tasks';
import { TaskData, UserType } from 'types/types';

import Link from 'components/Link';
import Button from 'components/Button';
import ExpandableClampContainer from 'components/ExpandableClampContainer';
import TaskInput from './TaskInput';

import { Check } from 'assets/icons';

export type TaskProps = {
  sessionId: string;
  userId: string;
  userType: UserType;
  accessCode: string;
  task: TaskType;
  data: any;
  taskData: TaskData;
};

const onChangeDebounced = (sessionId: string, task: TaskType, data: any) => {
  return _.debounce(async (value: any, hasError: boolean) => {
    if (task.dataBinding && !hasError) {
      const pickedData = _.isObject(task.dataBinding)
        ? _.mapValues(
            task.dataBinding,
            (bond) =>
              _.get(
                data,
                replaceSessionId(bond, sessionId).split('/').join('.')
              ) || null
          )
        : _.get(
            data,
            replaceSessionId(task.dataBinding, sessionId).split('/').join('.')
          ) || null;

      const oldValue =
        pickedData && task.dataTransformer
          ? task.dataTransformer.get(pickedData)
          : pickedData;

      const currentValue = task.dataTransformer
        ? task.dataTransformer.set(pickedData, value, oldValue)
        : value;

      const updates: Record<string, any> = {};
      if (_.isObject(task.dataBinding)) {
        Object.entries(task.dataBinding).forEach(([key, bond]) => {
          updates[replaceSessionId(bond, sessionId)] = currentValue[key];
        });
      } else {
        updates[replaceSessionId(task.dataBinding, sessionId)] = currentValue;
      }

      const cleanUpdates = task.cleanDataUpdates
        ? await task.cleanDataUpdates(sessionId, value)
        : [];

      db.ref().update(_.merge(updates, cleanUpdates));
    }
  }, 200);
};

const Task = ({
  task,
  data,
  taskData,
  sessionId,
  userId,
  userType,
  accessCode,
}: TaskProps): JSX.Element => {
  const isValid = !!taskData?.completedAt;
  //const readOnly = !task.readOnly && isValid;

  const onChange = useMemo(() => {
    return onChangeDebounced(sessionId, task, data);
  }, [sessionId, task, data]);

  return (
    <div key={task.name}>
      <div className="relative rounded-lg border border-surfaces-divider p-4">
        <div className="flex flex-row space-x-6">
          <div
            className={`group flex h-6 w-6 flex-shrink-0 cursor-pointer rounded-md border  ${
              isValid
                ? 'border-success hover:bg-success-soft'
                : 'border-black hover:bg-gray-soft'
            }`}
            onClick={() => {
              db.ref(`sessionsNextData/${sessionId}/tasks/${task.name}`).update(
                {
                  authorId: userId,
                  completedAt: isValid ? null : new Date().getTime(),
                }
              );
            }}
          >
            <Check
              className={`m-auto ${
                isValid ? 'visible text-success' : 'invisible text-black '
              }  h-5 w-5 stroke-2`}
            />
          </div>
          <div
            className={`flex min-w-0 flex-grow ${
              task.layout === 'col'
                ? 'flex-col space-y-4 md:flex-row md:space-x-4'
                : 'flex-col space-y-4'
            }`}
          >
            <div
              className={`flex flex-grow flex-col ${
                isValid ? 'text-gray-400 ' : 'text-black'
              }`}
            >
              <h3
                className={`font-semibold ${
                  isValid ? 'text-gray-400 line-through' : 'text-black'
                }`}
              >
                {task.title}
              </h3>
              <ExpandableClampContainer clamp="line-clamp-2">
                <p className="text-sm">{task.description}</p>
              </ExpandableClampContainer>
            </div>
            {task.viewType !== 'none' ? (
              <div
                className={`flex flex-shrink-0  ${
                  task.layout === 'col' ? 'w-96' : 'w-full'
                }`}
              >
                <TaskInput
                  name={task.name}
                  viewType={task.viewType}
                  sessionId={sessionId}
                  userId={userId}
                  userType={userType}
                  readOnly={false /* readOnly */}
                  onChange={onChange}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between">
          <div className="ml-12 flex flex-row items-center divide-x divide-surfaces-divider">
            {task.actions
              ? task.actions.map((action, index) => {
                  return action.type === 'redirection' ? (
                    <Link
                      key={index}
                      className="px-2"
                      external={action.external}
                      to={{
                        pathname: replaceAccessCode(
                          action.to.pathname,
                          accessCode
                        ),
                        search: action.to.search,
                      }}
                      text={action.name}
                    />
                  ) : (
                    <Button
                      key={index}
                      className="mx-2"
                      size="sm"
                      text={action.name}
                      {...(action.buttonProps || {})}
                      onClick={async () => {
                        await action.execute({ sessionId: sessionId });
                      }}
                      disabled={isValid}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
