import React, { useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import _ from 'lodash';

import {
  OrderedActivities,
  SessionTemplate,
  SessionTemplateFullDescription,
} from 'types/types';
import { Clock, UserGroup, ItemEcrou } from 'assets/icons';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { WithId } from 'types/typesUtils';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Thumbnail from 'components/Thumbnail';
import ActivityDetails from 'screens/SessionScreen/SessionDashboardScreen/blocks/ActivityDetails';
import { getCriterias } from 'model/criteriaManagement';
import Switch from 'components/Switch';

export type TemplateModalProps = {
  template: WithId<SessionTemplateFullDescription> | null;
  close: () => void;
  footer?: React.ReactNode;
};

const TemplateModal = ({
  template,
  close,
  footer,
}: TemplateModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  const templateId = template?.id;

  useFirebaseConnect(`sessionsNextTemplates/${templateId}`);

  const sessionTemplate: SessionTemplate | null = useSelector(
    (state: any) =>
      templateId && state.firebase.data.sessionsNextTemplates?.[templateId]
  );

  const activities = sessionTemplate?.activities;

  const orderedActivities: OrderedActivities = useMemo(() => {
    return _.sortBy(Object.values(activities || {}), 'index');
  }, [activities]);

  const activitiesNames = orderedActivities.reduce<Record<string, string>>(
    (current, activity) => {
      current[activity.name] = activity.humanName;
      return current;
    },
    {}
  );

  const criteriaNames = orderedActivities.reduce<Record<string, string>>(
    (current, activity) => {
      const criteria = getCriterias(activity);
      Object.values(criteria).forEach((production) => {
        if (production.name && production.description) {
          current[production.name] = production.description;
        }
      });
      return current;
    },
    {}
  );

  return (
    <Modal
      onClose={close}
      open={!!template}
      footer={footer}
      body={
        <>
          <div className="flex flex-row items-center">
            <div className="shrink-0">
              <Thumbnail
                name={template?.name || 'W'}
                colorClass="bg-primary"
                size="large"
              />
            </div>
            <div className="ml-4 flex-grow">
              <Dialog.Title className="text-4xl font-semibold">
                {template?.name}
              </Dialog.Title>
              <div className="mt-1 flex flex-row space-x-4 text-lg">
                <div className="flex flex-row items-center">
                  <Clock className="mr-1 h-6 w-6 stroke-2" />
                  {template?.duration}min
                </div>
                <div className="flex flex-row items-center">
                  <UserGroup className="mr-1 h-6 w-6 stroke-2" />
                  {t('common:AToB', {
                    min: template?.nbParticipants[0],
                    max: template?.nbParticipants[1],
                  })}
                </div>
              </div>
            </div>
          </div>

          <Dialog.Description className="mt-4 text-lg">
            {template?.description}
          </Dialog.Description>

          <div className="pr-36">
            <h3 className="mt-6 text-2xl font-semibold">
              {t('sessions:SessionsGoal')}
            </h3>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {template?.goals?.map((goal, index) => (
                <div key={index} className="flex flex-row text-base">
                  <ItemEcrou className="mr-3 h-6 w-6 shrink-0 fill-current text-primary" />
                  {goal}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex flex-row justify-between pr-2">
              <h3 className="mt-6 mb-4 text-2xl font-semibold">
                {t('sessions:Steps')}
              </h3>
              <Switch
                label={t('common:DetailedView')}
                enabled={showDetails}
                onChange={() => setShowDetails((state) => !state)}
              />
            </div>
            {orderedActivities.map((activity, index) => {
              return showDetails ? (
                <ActivityDetails
                  key={index}
                  sessionId={templateId}
                  activity={activity}
                  index={index}
                  activitiesNames={activitiesNames}
                  criteriaNames={criteriaNames}
                  showsAnnotation={false}
                />
              ) : (
                <div key={activity.name} className="mb-12 flex flex-row">
                  <div className="m-1 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary-soft text-xl font-semibold text-primary">
                    {index + 1}
                  </div>
                  <div className="ml-3 flex flex-grow flex-col">
                    <p className="p-0 text-base">
                      {activity.duration
                        ? t('common:Xminutes', { duration: activity.duration })
                        : t('common:NoTimeLimit')}
                    </p>
                    <label
                      htmlFor={activity.name}
                      className="text-lg font-semibold"
                    >
                      {activity.humanName || activity.name}
                    </label>
                    <p className="text-lg text-gray-600">
                      {template?.activitiesDescription[activity.name]}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      }
    />
  );
};

export default TemplateModal;
