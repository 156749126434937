import React, { useState } from 'react';
import { Button, Space, Collapse, Popconfirm, Typography } from 'antd';
import {
  Activity,
  Activities,
  Production,
  PRODUCTION_TYPES,
  ProductionTypeType,
  PRODUCTION_MODES,
  PRODUCTION_MULTIPLICITIES,
  PRODUCTION_VISIBILITIES,
} from 'types/types';
import { DeleteOutlined } from '@ant-design/icons';
import FormLine from './FormLine';
import EditableOption, {
  EditableOptionDataType,
} from 'componentsOld/EditableOption';
import EditableTable from 'componentsOld/EditableTable';
import { useFirebase } from 'react-redux-firebase';
import _ from 'lodash';
import PopInput from 'componentsOld/PopInput';
import { addDocTemplate } from 'model/docManagement';

const { Panel } = Collapse;
const { Text } = Typography;

const EnumEditor = ({
  options,
  save,
}: {
  options: string[];
  save: (options: string[]) => void;
}) => {
  type Record = {
    key: string;
    name: string;
  };

  const [addingRow, setAddingRow] = useState<Record | null>(null);

  const dataOptions = (options || []).map((key) => ({
    key,
    name: key,
  }));

  if (addingRow) {
    dataOptions.push(addingRow);
  }
  const updateOptions = ({ key, name }: Record) => {
    if (key === addingRow?.key) {
      if (name === '') {
        setAddingRow({ key, name });
      } else {
        setAddingRow(null);
      }
    }
    if (name !== '') {
      const newOptions = _.keyBy(dataOptions, 'key');
      newOptions[name] = { key, name };
      const newOptionsArray = Object.values(newOptions)
        .map((rec) => rec.name)
        .filter((name) => name !== '');
      return save(newOptionsArray);
    }
  };

  const handleDelete = (deletedKey: string) => {
    console.log(deletedKey);
    if (deletedKey === 'trolollkjiuhiuhlo') {
      setAddingRow(null);
    } else {
      const remainingOptions = Object.values(
        _.pickBy(_.keyBy(dataOptions, 'key'), (_, key) => key !== deletedKey)
      ).map((rec) => rec.name);

      return save(remainingOptions);
    }
  };

  const handleAdd = () => setAddingRow({ key: 'trolollkjiuhiuhlo', name: '' });

  return (
    <EditableTable
      columns={[
        {
          title: 'Option',
          dataIndex: 'name',
          editable: true,
        },
      ]}
      handleSave={updateOptions}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      dataSource={dataOptions}
    />
  );
};

const ProductionsForm = ({
  activity,
  activities,
  activityRef,
}: {
  activity: Activity;
  activities: Activities;
  activityRef: string;
}): JSX.Element => {
  const db = useFirebase();
  const baseRef = `${activityRef}/productions`;
  type Setter = (prop: any) => (val: any) => Promise<void>;

  const renderForm = (
    production: Production,
    setter: Setter,
    remove: () => Promise<void>,
    add: (name: string, production?: Production) => Promise<void>
  ) => {
    const { name } = production;

    const makeOptionsForEditable = (
      options: string[]
    ): EditableOptionDataType =>
      options.reduce((prec: EditableOptionDataType, type) => {
        prec[type] = { desc: type };
        return prec;
      }, {});

    let details = null;
    switch (production.type) {
      case 'document':
        details = (
          <div key={name}>
            <FormLine label="Name">
              <Text editable={{ onChange: setter('docName') }}>
                {production.docName}
              </Text>
            </FormLine>
            <FormLine label="Title">
              <Text editable={{ onChange: setter('docTitle') }}>
                {production.docTitle}
              </Text>
            </FormLine>
            <FormLine label="Visibility">
              <EditableOption
                value={production.visibility || 'Public'}
                options={makeOptionsForEditable(PRODUCTION_VISIBILITIES)}
                onChange={setter('visibility')}
                editable
              />
            </FormLine>
          </div>
        );
        break;
      case 'enum':
        details = (
          <>
            <FormLine label="Options" />
            <EnumEditor options={production.options} save={setter('options')} />
          </>
        );
        break;
      default:
        details = null;
    }

    const changeProductionType = async (val: string) => {
      const newType = val as ProductionTypeType;
      if (newType === 'document') {
        await setter('docName')('A name');
        await setter('docTitle')('A title');
        await setter('template')({
          type: 'document',
          docId: await addDocTemplate(),
        });
      }

      return setter('type')(newType);
    };

    // const changeProductionName = async (newName: string) => {
    //   if (newName !== production.name) {
    //     await add(newName, production);
    //     return remove();
    //   }
    // };

    return (
      <Panel
        key={name}
        header={
          <FormLine
            label="Name"
            style={{ marginBottom: '0px' }}
            extra={
              <Popconfirm title="Sure to delete?" onConfirm={() => remove()}>
                <DeleteOutlined onClick={(e) => e.stopPropagation()} />
              </Popconfirm>
            }
          >
            <Text
            // editable={{ onChange: changeProductionName }}
            >
              {name}
            </Text>
          </FormLine>
        }
      >
        <FormLine label="Description">
          <Text editable={{ onChange: setter('description') }}>
            {production.description}
          </Text>
        </FormLine>
        <FormLine label="Type">
          <EditableOption
            value={production.type}
            options={makeOptionsForEditable(PRODUCTION_TYPES)}
            onChange={changeProductionType}
            editable
          />
        </FormLine>
        <FormLine label="Mode">
          <EditableOption
            value={production.mode}
            options={makeOptionsForEditable(PRODUCTION_MODES)}
            onChange={setter('mode')}
            editable
          />
        </FormLine>
        <FormLine label="Multiplicity">
          <EditableOption
            value={production.multiplicity || 'One'}
            options={makeOptionsForEditable(PRODUCTION_MULTIPLICITIES)}
            onChange={setter('multiplicity')}
            editable
          />
        </FormLine>
        {details}
      </Panel>
    );
  };

  const add = (name: string, production?: Production) => {
    const newProduction: Omit<Production, 'name'> = production || {
      type: 'string',
      mode: 'ByUser',
    };

    return db.ref(`${baseRef}/${name}`).set({ ...newProduction, name });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Collapse>
        {Object.values(activity.productions || {}).map((production) => {
          const prodRef = `${baseRef}/${production.name}`;
          const setter: Setter = (prop) => (val) => {
            return db.ref(`${prodRef}/${prop}`).set(val);
          };

          // TODO: clean deps before removing production
          const remove = () => db.ref(prodRef).remove();

          return renderForm(production, setter, remove, add);
        })}
      </Collapse>
      <PopInput
        placeholder={'Production name'}
        onSubmit={(input) => add(input)}
      >
        <Button>Add</Button>
      </PopInput>
    </Space>
  );
};

export default ProductionsForm;
