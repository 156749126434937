import i18n from 'services/i18n';
import { hashCode } from 'utils/utils';

let innerNames: undefined | string[];

const getNames = (): string[] => {
  if (!innerNames) {
    innerNames = [
      i18n.t('names:Admirable_Waper_male'),
      i18n.t('names:Admirable_Waper_female'),
      i18n.t('names:Happy_Waper_male'),
      i18n.t('names:Happy_Waper_female'),
      i18n.t('names:Joyful_Waper_male'),
      i18n.t('names:Joyful_Waper_female'),
      i18n.t('names:Curious_Waper_male'),
      i18n.t('names:Curious_Waper_female'),
      i18n.t('names:Smart_Waper_male'),
      i18n.t('names:Smart_Waper_female'),
      i18n.t('names:Clever_Waper_male'),
      i18n.t('names:Clever_Waper_female'),
      i18n.t('names:Marvelous_Waper_male'),
      i18n.t('names:Marvelous_Waper_female'),
      i18n.t('names:Astonishing_Waper_male'),
      i18n.t('names:Astonishing_Waper_female'),
      i18n.t('names:Wise_Waper_male'),
      i18n.t('names:Wise_Waper_female'),
      i18n.t('names:Friendly_Waper_male'),
      i18n.t('names:Friendly_Waper_female'),
      i18n.t('names:Affable_Waper_male'),
      i18n.t('names:Affable_Waper_female'),
      i18n.t('names:Genial_Waper_male'),
      i18n.t('names:Genial_Waper_female'),
      i18n.t('names:Courteous_Waper_male'),
      i18n.t('names:Courteous_Waper_female'),
      i18n.t('names:Gracious_Waper_male'),
      i18n.t('names:Gracious_Waper_female'),
      i18n.t('names:Kind_Waper_male'),
      i18n.t('names:Kind_Waper_female'),
      i18n.t('names:Sympathetic_Waper_male'),
      i18n.t('names:Sympathetic_Waper_female'),
      i18n.t('names:Compassionate_Waper_male'),
      i18n.t('names:Compassionate_Waper_female'),
      i18n.t('names:Charitable_Waper_male'),
      i18n.t('names:Charitable_Waper_female'),
      i18n.t('names:Generous_Waper_male'),
      i18n.t('names:Generous_Waper_female'),
      i18n.t('names:Magnanimous_Waper_male'),
      i18n.t('names:Magnanimous_Waper_female'),
      i18n.t('names:Merciful_Waper_male'),
      i18n.t('names:Merciful_Waper_female'),
      i18n.t('names:Sound_Waper_male'),
      i18n.t('names:Sound_Waper_female'),
      i18n.t('names:Insightful_Waper_male'),
      i18n.t('names:Insightful_Waper_female'),
      i18n.t('names:Ingenious_Waper_male'),
      i18n.t('names:Ingenious_Waper_female'),
      i18n.t('names:Resourceful_Waper_male'),
      i18n.t('names:Resourceful_Waper_female'),
      i18n.t('names:Creative_Waper_male'),
      i18n.t('names:Creative_Waper_female'),
      i18n.t('names:Inventive_Waper_male'),
      i18n.t('names:Inventive_Waper_female'),
      i18n.t('names:Crafty_Waper_male'),
      i18n.t('names:Crafty_Waper_female'),
      i18n.t('names:Artful_Waper_male'),
      i18n.t('names:Artful_Waper_female'),
      i18n.t('names:Astute_Waper_male'),
      i18n.t('names:Astute_Waper_female'),
      i18n.t('names:Savvy_Waper_male'),
      i18n.t('names:Savvy_Waper_female'),
      i18n.t('names:Subtle_Waper_male'),
      i18n.t('names:Subtle_Waper_female'),
      i18n.t('names:Canny_Waper_male'),
      i18n.t('names:Canny_Waper_female'),
      i18n.t('names:Shrewd_Waper_male'),
      i18n.t('names:Shrewd_Waper_female'),
      i18n.t('names:Pleasant_Waper_male'),
      i18n.t('names:Pleasant_Waper_female'),
      i18n.t('names:Comely_Waper_male'),
      i18n.t('names:Comely_Waper_female'),
      i18n.t('names:Rational_Waper_male'),
      i18n.t('names:Rational_Waper_female'),
      i18n.t('names:Careful_Waper_male'),
      i18n.t('names:Careful_Waper_female'),
      i18n.t('names:Meticulous_Waper_male'),
      i18n.t('names:Meticulous_Waper_female'),
      i18n.t('names:Prepared_Waper_male'),
      i18n.t('names:Prepared_Waper_female'),
      i18n.t('names:Shining_Waper_male'),
      i18n.t('names:Shining_Waper_female'),
    ];
  }
  return innerNames;
};

export const getGeneratedName = (key: string): string => {
  const names = getNames();
  const hash = hashCode(key);
  const index = ((hash % names.length) + names.length) % names.length;

  return names[index];
};
