import RangePicker from 'components/RangePicker';
import React from 'react';
import { connectRange } from 'react-instantsearch-dom';

const RangeSlider = ({ currentRefinement, refine, min, max }: any) => {
  const changeInterval = (newMin: number, newMax: number) => {
    const newInterval = {
      min: Math.max(newMin, min || 0),
      max: Math.min(newMax, max || Date.now()),
    };

    refine(newInterval);
  };

  return (
    <RangePicker
      forceRangeExtended
      value={[
        new Date(Math.max(currentRefinement.min || 0, min || 0)),
        new Date(
          Math.min(currentRefinement.max || Date.now(), max || Date.now())
        ),
      ]}
      bounds={[new Date(min), new Date(max)]}
      onChange={([from, to]) => {
        if (from && to) {
          changeInterval(from.getTime(), to.getTime());
        }
      }}
    />
  );
};

export default connectRange(RangeSlider);
