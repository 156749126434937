import React from 'react';
import { Arrow2 as PatternArrow2 } from 'assets/patterns';

type BannerProps = {
  className?: string;
  colorClass?: string;
  children?: React.ReactNode;
};

const Banner = ({
  className = '',
  colorClass = 'bg-primary selection:bg-white selection:text-primary',
  children,
}: BannerProps): JSX.Element => {
  return (
    <div className={`relative w-full ${colorClass} ${className}`}>
      <div className="absolute inset-0 overflow-hidden">
        <PatternArrow2 className="invisible absolute -left-20 -top-28 h-52 rotate-150 stroke-current stroke-3/2 text-white xl:visible" />
        <PatternArrow2 className="invisible absolute -right-20 -bottom-28 h-52 -rotate-27 stroke-current stroke-3/2 text-white lg:visible" />
      </div>
      <div className="py-4">{children}</div>
    </div>
  );
};

export default Banner;
