import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { useField } from 'formik';

import { SessionTypeType } from 'types/types';
import { useOnChange } from 'frameworks/formik/hooks';

import { VideoCamera, LocationMarker } from 'assets/icons';

export type SessionTypeProps = {
  value: SessionTypeType;
  onChange?: (value: SessionTypeType) => void;
  readOnly?: boolean;
  compact?: boolean;
};

const SessionType = ({
  value,
  onChange,
  readOnly = false,
  compact = false,
}: SessionTypeProps): JSX.Element => {
  const { t } = useTranslation();
  const iconClassName = 'w-6 h-6 stroke-3/2';
  const sessionTypeItems: Array<{
    value: SessionTypeType;
    description: string;
    icon: JSX.Element;
  }> = [
    {
      value: 'on_site',
      description: compact
        ? t('sessions:onSite')
        : t('sessions:onSiteDescription'),
      icon: <LocationMarker className={iconClassName} />,
    },
    {
      value: 'digital',
      description: compact
        ? t('sessions:digital')
        : t('sessions:digitalDescription'),
      icon: <VideoCamera className={iconClassName} />,
    },
    // {
    //   value: 'hybrid',
    //   description: compact
    //     ? t('sessions:hybrid')
    //     : t('sessions:hybridDescription'),
    //   icon: <Hybrid className={iconClassName} />,
    // },
  ];

  const selected =
    sessionTypeItems.find((item) => item.value === value) ||
    sessionTypeItems[0];

  return readOnly ? (
    <div className="mt-4 flex flex-row items-center space-x-3 text-base">
      {selected.icon}
      <p>{selected.description}</p>
    </div>
  ) : (
    <RadioGroup value={value} onChange={(value) => onChange && onChange(value)}>
      <div className="-mt-2 -ml-2 flex flex-row flex-wrap items-center">
        {sessionTypeItems.map((item, index) => (
          <RadioGroup.Option key={index} value={item.value}>
            {({ checked }) => (
              <div
                className={`ml-2 mt-2 flex cursor-pointer flex-row items-center space-x-3 rounded-lg p-3 font-semibold ${
                  checked
                    ? 'bg-primary-soft text-primary'
                    : 'bg-gray-soft text-black-soft'
                }`}
              >
                <div>{item.icon}</div>
                <div>{item.description}</div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default SessionType;

export const SessionTypeInput = ({
  name,
  label,
  readOnly,
  compact,
  onChangeValue,
}: {
  name: string;
  label?: string;
  readOnly?: boolean;
  compact?: boolean;
  onChangeValue?: (value: SessionTypeType, hasError: boolean) => void;
}): JSX.Element => {
  const [field, , helpers] = useField(name);

  useOnChange(name, onChangeValue);

  return (
    <div>
      {label ? (
        <label htmlFor={name} className="text-lg font-semibold">
          {label}
        </label>
      ) : null}
      <div className={`mt-2 w-full`}>
        <SessionType
          {...field}
          onChange={(value) => helpers.setValue(value)}
          readOnly={readOnly}
          compact={compact}
        />
      </div>
    </div>
  );
};
