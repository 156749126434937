import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatchingStatus } from 'model/dataHooks';
import _ from 'lodash';
import { resetGroups } from 'model/groupManagement';
import { createGroups } from 'model/matching';
import { Activities, Productions } from 'types/types';

import YesNoModal from 'components/YesNoModal';
import FormattedText from 'components/FormattedText';
import ProgressBar from 'components/ProgressBar';

const MatchGroupModal = ({
  sessionId,
  activities,
  activityName,
  productions,
  visible,
  showGroups,
  editGroups,
  autoShowGroups,
}: {
  sessionId: string;
  activityName: string;
  activities: Activities;
  productions: Record<string, Productions>;
  visible: boolean;
  showGroups: () => void;
  editGroups: () => void;
  autoShowGroups: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [stay, setStay] = useState(false);
  const [running, setRunning] = useState(false);

  const activity = activities[activityName];

  const [matchingStatus, loading] = useMatchingStatus(sessionId, activity.name);

  const isSameGroup =
    activity.grouping.mode === 'Groups' &&
    activity.grouping.settings.mode === 'same_as';

  useEffect(() => {
    if (matchingStatus === 'Error') {
      editGroups();
      return;
    }
    if (!stay && matchingStatus === 'Done') {
      showGroups();
      return;
    }
    if (
      !running &&
      visible &&
      (matchingStatus === null || matchingStatus === 'NotRun')
    ) {
      setStay(true);
      setRunning(true);
      resetGroups(sessionId, activity.name)
        .then(() => createGroups(sessionId, activity, activities, productions))
        .then(() => {
          setRunning(false);
          (autoShowGroups || isSameGroup) && setStay(false);
        })
        .catch((e) => {
          setRunning(false);
          console.error(e);
        });
    }
  }, [
    visible,
    matchingStatus,
    activity,
    activities,
    productions,
    sessionId,
    stay,
    showGroups,
    editGroups,
    running,
    autoShowGroups,
    isSameGroup,
  ]);

  return (
    <YesNoModal
      onClickYes={() => {
        setStay(false);
      }}
      onClickNo={() => {
        editGroups();
        setStay(false);
      }}
      open={!loading && visible && (matchingStatus !== 'Done' || stay)}
      textYes={t('facilitator:showGroups')}
      textNo={t('facilitator:editGroups')}
      disabledYes={matchingStatus !== 'Done'}
      disabledNo={matchingStatus !== 'Done'}
      critical
    >
      {matchingStatus === 'Done' && (
        <FormattedText>{t('facilitator:showGroupsMessage')}</FormattedText>
      )}
      {_.isNumber(matchingStatus) && (
        <>
          <p className="mb-4">
            {isSameGroup
              ? t('facilitator:copyingGroups')
              : t('facilitator:creatingGroups')}
          </p>
          <ProgressBar progress={matchingStatus / 100} />
        </>
      )}
    </YesNoModal>
  );
};

export default MatchGroupModal;
