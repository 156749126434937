import React from 'react';
import { useField } from 'formik';
import Checkbox from 'components/Checkbox';

export type CheckboxInputProps = {
  name: string;
  className?: string;
  children?: JSX.Element;
  setValue?: (value: boolean) => void;
};

const CheckboxInput = ({
  name,
  className = '',
  children,
  setValue,
}: CheckboxInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;
  return (
    <div className={`${className}`}>
      <div className="inline-flex items-center">
        <Checkbox
          id={name}
          value={!!field.value}
          onChange={(checked) => {
            helpers.setTouched(true);
            helpers.setValue(checked, true);
            if (setValue) {
              setValue(checked);
            }
          }}
        >
          {children}
        </Checkbox>
      </div>
      {hasError ? (
        <div className="mt-2 font-medium text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CheckboxInput;
