import React from 'react';

import { Quote } from 'assets/icons';

export type BlockquoteProps = {
  quote: string;
  username?: string;
};

const Blockquote = ({ quote, username }: BlockquoteProps): JSX.Element => {
  return (
    <blockquote className="relative rounded-md bg-gray-50 px-4 pt-6 pb-2 text-xl italic">
      <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
      <p className="text-gray-600">{quote}</p>
      {username ? (
        <p className="text-sm capitalize text-primary">{username}</p>
      ) : null}
    </blockquote>
  );
};

export default Blockquote;
