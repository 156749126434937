import * as React from 'react';

function SvgStarEyes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="12 12 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={36} cy={36} r={24} fill="#FCEA2B" />
      <path
        fill="#F1B31C"
        d="M25.042 21.237l7.986-1.2-3.904 7.069 3.61 7.224-7.93-1.53-5.755 5.666-.997-8.014-7.166-3.723 7.314-3.424 1.326-7.966zM46.882 21.237l-7.986-1.2 3.905 7.069-3.61 7.224 7.93-1.53 5.755 5.666.996-8.014 7.167-3.723-7.314-3.424-1.326-7.966z"
      />
      <path
        fill="#EA5A47"
        d="M49.737 46.356c-1.79 4.27-6.35 7.23-13.7 7.23-7.41 0-12.03-3.03-13.8-7.36 2.03.46 14.98 3.17 27.5.13z"
      />
      <path
        fill="#FFF"
        d="M50.607 41.866c0 1.6-.29 3.11-.87 4.49-12.52 3.04-25.47.33-27.5-.13-.55-1.35-.83-2.82-.83-4.36h.11s14.81 3.59 28.89.07l.2-.07z"
      />
      <g
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          d="M50.607 41.866c0 1.6-.29 3.11-.87 4.49-12.52 3.04-25.47.33-27.5-.13-.55-1.35-.83-2.82-.83-4.36h.11s14.81 3.59 28.89.07l.2-.07z"
        />
        <path d="M49.737 46.356c-1.79 4.27-6.35 7.23-13.7 7.23-7.41 0-12.03-3.03-13.8-7.36 2.03.46 14.98 3.17 27.5.13zM50.227 46.236l-.49.12M22.237 46.226c-.19-.05-.28-.07-.28-.07" />
        <path
          strokeLinecap="round"
          d="M46.554 15.56A22.902 22.902 0 0036 13a22.9 22.9 0 00-11.076 2.837M13.194 32.995A23.215 23.215 0 0013 36c0 12.702 10.297 23 23 23 12.702 0 23-10.298 23-23 0-1.007-.065-2-.19-2.973"
        />
        <path
          strokeLinecap="round"
          d="M25.118 21.237l7.986-1.2-3.905 7.069 3.61 7.224-7.93-1.53-5.755 5.666-.996-8.014-7.167-3.723 7.314-3.424 1.326-7.966zM46.882 21.237l-7.986-1.2 3.905 7.069-3.61 7.224 7.93-1.53 5.755 5.666.996-8.014 7.167-3.723-7.314-3.424-1.326-7.966z"
        />
      </g>
    </svg>
  );
}

export default SvgStarEyes;
