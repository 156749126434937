import * as React from 'react';

function SvgConfetti(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 366.636 366.636"
      {...props}
    >
      <path
        fill="#ffb819"
        d="M7.261 366.636l223.535-104.164-121.483-120.343z"
      />
      <circle cx={139.46} cy={232.5} r={27.121} fill="#ffd26c" />
      <path
        d="M64.791 240.073c7.507.439 15.158-2.219 20.866-7.982 10.454-10.552 10.455-27.525.076-38.087l-20.942 46.069zM34.985 337.966c-5.319 5.371-7.93 12.403-7.847 19.408l44.797-20.876c-10.697-9.221-26.859-8.718-36.95 1.468zM142.845 283.129c-6.434 6.495-8.903 15.423-7.436 23.792l47.484-22.127a27.648 27.648 0 00-1.693-1.846c-10.641-10.541-27.813-10.46-38.355.181z"
        fill="#ffd26c"
      />
      <circle cx={77.177} cy={286.451} r={27.121} fill="#ffd26c" />
      <path
        fill="#004d7a"
        d="M96.306 170.743l105.999 105.005 28.491-13.276-121.483-120.343z"
      />
      <circle cx={135} cy={86.679} r={18.497} fill="#00bcb4" />
      <circle cx={276.53} cy={235.558} r={18.497} fill="#00bcb4" />
      <circle cx={316.74} cy={153.038} r={18.497} fill="#ffb819" />
      <circle cx={176.102} cy={18.497} r={18.497} fill="#ffb819" />
      <circle cx={228.315} cy={181.419} r={18.497} fill="#d85c72" />
      <circle cx={239.536} cy={74.687} r={18.497} fill="#d85c72" />
      <circle cx={334.385} cy={83.179} r={18.497} fill="#d85c72" />
      <path
        d="M133.624 143.693a6.818 6.818 0 01-6.819-6.819 6.818 6.818 0 016.819-6.819c25.377 0 46.024-20.646 46.024-46.024 0-32.898 26.764-59.662 59.662-59.662 32.897 0 59.661 26.764 59.661 59.662a6.818 6.818 0 11-13.636 0c0-25.378-20.647-46.024-46.024-46.024-25.378 0-46.024 20.646-46.024 46.024-.002 32.898-26.767 59.662-59.663 59.662z"
        fill="#00bcb4"
      />
      <path
        d="M312.259 210.037c-25.978 0-47.115-21.136-47.115-47.115 0-18.459-15.019-33.479-33.478-33.479-18.46 0-33.479 15.019-33.479 33.479a6.818 6.818 0 11-13.636 0c0-25.979 21.136-47.115 47.115-47.115 25.979 0 47.115 21.136 47.115 47.115 0 18.46 15.018 33.478 33.478 33.478s33.478-15.018 33.478-33.478a6.819 6.819 0 0113.637 0c.001 25.979-21.135 47.115-47.115 47.115z"
        fill="#ffb819"
      />
    </svg>
  );
}

export default SvgConfetti;
