import React from 'react';
import { useTranslation } from 'react-i18next';

import MDReactComponent from 'componentsOld/MDReactComponent';
import Disclosure, { DisclosureTitle } from 'components/Disclosure';

import { Mode } from 'types/ui';

export type SimpleCardScreenProps = {
  cardContent: string;
  setCardContent?: (value: string) => Promise<void>;
  facilitationInstruction: string;
  setFacilitationInstruction?: (value: string) => Promise<void>;
  editable: boolean;
  mode: Mode;
} & (
  | {
      editable: true;
      setCardContent: (value: string) => Promise<void>;
      setFacilitationInstruction: (value: string) => Promise<void>;
    }
  | { editable: false }
);

const SimpleCardScreen = ({
  cardContent,
  setCardContent,
  facilitationInstruction,
  setFacilitationInstruction,
  editable,
  mode,
}: SimpleCardScreenProps): JSX.Element => {
  const { t } = useTranslation();

  const showFacilitationInstructions =
    (mode === 'editor' || mode === 'facilitator') &&
    (facilitationInstruction || editable);

  return (
    <div className="mx-auto flex h-full w-full max-w-3xl flex-col p-4">
      <Disclosure
        collapsable={false}
        title={
          showFacilitationInstructions ? (
            <DisclosureTitle text={t('sessions:contentForParticipants')} />
          ) : undefined
        }
      >
        <MDReactComponent
          text={cardContent}
          editable={editable}
          setText={setCardContent}
          forceBorder={editable}
        />
      </Disclosure>
      {showFacilitationInstructions ? (
        <Disclosure
          collapsable={false}
          title={
            <DisclosureTitle text={t('sessions:facilitationInstructions')} />
          }
        >
          <MDReactComponent
            text={facilitationInstruction}
            editable={editable}
            setText={setFacilitationInstruction}
            forceBorder={editable}
          />
        </Disclosure>
      ) : null}
    </div>
  );
};

export default SimpleCardScreen;
