import {
  Field,
  FieldArray,
  ArrayHelpers,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';

import { X } from 'assets/icons';
import {
  Communities,
  SessionTemplateFullDescription,
  SessionTemplatePrivacy,
} from 'types/types';
import { useTranslation } from 'react-i18next';
import Listbox, { Item } from 'components/Listbox';
import Button from 'components/Button';

export type BaseTemplateParams = Omit<
  SessionTemplateFullDescription,
  'activitiesDescription' | 'sourceSessionId' | 'nbParticipants' | 'duration'
> & {
  nbParticipants: [number | undefined, number | undefined];
  duration: number | undefined;
};

type BaseTemplateFormData = BaseTemplateParams & {
  communities: Record<string, true>;
};

export type BaseTemplateFormProps = {
  templateBaseDescription: null | BaseTemplateParams;
  communitiesOfTemplate: Record<string, true>;
  communities: Communities;
  onCancel: () => void;
  onSubmit: (
    params: BaseTemplateParams,
    communitiesOfTemplate: Record<string, true>
  ) => void;
};

const BaseTemplateForm = ({
  templateBaseDescription,
  communitiesOfTemplate,
  communities,
  onCancel,
  onSubmit,
}: BaseTemplateFormProps): JSX.Element => {
  const { t } = useTranslation();

  const baseTemplateSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t('form:NameRequired')),
        nbParticipants: Yup.array()
          .of(
            Yup.number()
              .positive(t('form:NumberMustBePositive')) //'Le nombre doit être positif'
              .integer(t('form:NumberMustBeAnInteger')) //'Le nombre doit être entier'
              .required(t('form:NumberIsRequired')) //'Ce champ est requis !'
          )
          .length(2)
          .required(t('form:fieldRequired')),
        duration: Yup.number()
          .positive(t('form:NumberMustBePositive'))
          .integer(t('form:NumberMustBeAnInteger'))
          .required(t('form:NumberIsRequired')),
        description: Yup.string().required(t('form:fieldRequired')),
        goals: Yup.array()
          .of(
            Yup.string().min(10, t('templates:GoalsMustBe10CharLong')) //'vous ne pouvez pas sécifier des objectifs vide')
          )
          .min(3, t('templates:MustSpecify3Goals')) //'Vous devez spécifier au moins 4 objectifs'),
          .required(t('templates:MustSpecify3Goals')),
        visibility: Yup.string()
          .required(t('form:fieldRequired'))
          .oneOf(['private', 'public']),
        communities: Yup.object().required(t('form:fieldRequired')),
      }),
    [t]
  );

  const renderGoalsForm =
    (values: Partial<BaseTemplateParams>, errorMode: boolean) =>
    (arrayHelpers: ArrayHelpers) => {
      const goals: string[] = values.goals || [];
      return (
        <div className="mt-2 flex flex-col items-start">
          {goals.length > 0 ? (
            goals.map((goal, index) => (
              <div
                key={index}
                className="flex-raw mb-4 flex w-full items-center"
              >
                <Field
                  name={`goals.${index}`}
                  id={`goals.${index}`}
                  className={`flex-grow border  p-3 ${
                    errorMode ? 'border-danger' : 'border-surfaces-divider'
                  }`}
                  placeholder={t('templates:ExAllowToShareOnFormation')} // "Ex: Permettre à chacun d'échanger sur le formation"
                />
                <button
                  type="button"
                  className="p-2"
                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                >
                  <X className="text-black-medium h-8 w-8 stroke-1" />
                </button>
              </div>
            ))
          ) : (
            <p className="my-3 h-6 text-black-soft">
              {t('templates:NoGoalsAreSet')}
            </p>
          )}
          <button
            className="bg-black-light h-8 rounded-2xl border border-surfaces-divider py-2 px-12 text-sm font-semibold text-black"
            type="button"
            onClick={() => arrayHelpers.push('')}
          >
            {t('templates:AddAGoal')}
          </button>
        </div>
      );
    };

  const privacyOptions: Item<SessionTemplatePrivacy>[] = useMemo(
    () => [
      {
        value: 'private',
        description: t('templates:private'),
      },
      {
        value: 'public',
        description: t('templates:public'),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-5xl text-left">
      <h2 className="mt-8 text-lg font-semibold text-primary">
        {t('common:StepXOverY', { step: 1, length: 2 })}
      </h2>
      <h1 className="pb-8 text-3xl font-semibold">
        {t('templates:FillInParametersForYourTemplate')}
      </h1>

      <Formik
        initialValues={{
          ...(templateBaseDescription || {}),
          communities: communitiesOfTemplate,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          onSubmit?.(
            _.pickBy(
              values,
              (_val, key) => key !== 'communities'
            ) as BaseTemplateParams,
            values.communities!
          );
        }}
        validationSchema={baseTemplateSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          submitCount,
        }: FormikProps<Partial<BaseTemplateFormData>>) => {
          const hasError = (name: keyof BaseTemplateFormData): boolean =>
            !!(errors[name] && (submitCount || touched[name]));

          return (
            <Form>
              <div className="flex w-full flex-row space-x-10">
                <div className="flex-grow">
                  <div className="mb-8">
                    <label htmlFor="name" className="text-lg">
                      {t('templates:NameOfTemplate')}
                    </label>
                    <Field
                      className={`mt-2 w-full border p-3 ${
                        hasError('name')
                          ? 'border-danger'
                          : 'border-surface-divider'
                      }`}
                      id="name"
                      name="name"
                      placeholder={t('templates:ExTemplateName')} //"Ex: Meilleur template de la vie"
                    />
                    {hasError('name') && (
                      <div className="mt-2 font-medium text-danger">
                        {errors.name}
                      </div>
                    )}
                  </div>

                  <div className="mb-8">
                    <label htmlFor="participants" className="text-lg">
                      {t('templates:NumberOfParticipants')}
                    </label>
                    <div id="participants" className="flex">
                      <Field
                        className={`mt-2 mr-4 w-full border p-3 ${
                          hasError('nbParticipants')
                            ? 'border-danger'
                            : 'border-surface-divider'
                        }`}
                        id="nbParticipants.0"
                        name="nbParticipants.0"
                        type="number"
                        placeholder="Ex: 20"
                      />
                      <Field
                        className={`mt-2 w-full border p-3 ${
                          hasError('nbParticipants')
                            ? 'border-danger'
                            : 'border-surface-divider'
                        }`}
                        id="nbParticipants.1"
                        name="nbParticipants.1"
                        type="number"
                        placeholder="Ex: 500"
                      />
                    </div>
                    {hasError('nbParticipants') && (
                      <div className="mt-2 font-medium text-danger">
                        {errors.nbParticipants}
                      </div>
                    )}
                  </div>

                  <div className="mb-8">
                    <label htmlFor="duration" className="text-lg">
                      {t('templates:DurationInMinutes')}
                    </label>
                    <Field
                      className={`mt-2 w-full border border-surfaces-divider p-3 ${
                        hasError('duration')
                          ? 'border-danger'
                          : 'border-surface-divider'
                      }`}
                      id="duration"
                      name="duration"
                      type="number"
                      placeholder={t('templates:Ex120')} // "Ex: 120"
                    />
                    {errors.duration && (submitCount || touched.duration) && (
                      <div className="mt-2 font-medium text-danger">
                        {errors.duration}
                      </div>
                    )}
                  </div>

                  <div className="mb-8">
                    <label htmlFor="description" className="text-lg">
                      {t('common:Description')}
                    </label>
                    <Field
                      as="textarea"
                      className={`mt-2 h-48 w-full resize-none border border-surfaces-divider p-3 ${
                        hasError('description')
                          ? 'border-danger'
                          : 'border-surface-divider'
                      }`}
                      id="description"
                      name="description"
                      placeholder={t('templates:ExThisTemplate')} //"Ex: Ce template ..."
                    />
                    {errors.description &&
                      (submitCount || touched.description) && (
                        <div className="mt-2 font-medium text-danger">
                          {errors.description}
                        </div>
                      )}
                  </div>

                  <div className="mb-8">
                    <label htmlFor="goals" className="text-lg">
                      {t('templates:GoalsOfTemplate')}
                    </label>
                    <FieldArray
                      name="goals"
                      render={renderGoalsForm(values, hasError('goals'))}
                    />
                    {errors.goals && (submitCount || touched.goals) && (
                      <div className="mt-2 font-medium text-danger">
                        {errors.goals}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="mb-8">
                    <label htmlFor="goals" className="text-lg">
                      {t('templates:Visibility')}
                    </label>
                    <Listbox
                      className="mt-2"
                      defaultValue={values.visibility}
                      items={privacyOptions}
                      errorMode={hasError('visibility')}
                      placeholder={t('templates:ChoosePrivacySettings')}
                      setValue={(val) => setFieldValue('visibility', val)}
                    />
                    {errors.visibility &&
                      (submitCount || touched.visibility) && (
                        <div className="mt-2 font-medium text-danger">
                          {errors.visibility}
                        </div>
                      )}
                  </div>
                  {values.visibility === 'private' && (
                    <CommunitiesSelector
                      communities={communities}
                      communitiesOfTemplate={communitiesOfTemplate}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <button
                  type="button"
                  className="py-3 px-11 text-base font-semibold text-primary"
                  onClick={onCancel}
                >
                  {t('common:Cancel')}
                </button>
                <div className="flex-grow" />
                <Button text={t('common:Next')} type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BaseTemplateForm;

const CommunitiesSelector = ({
  communities,
  communitiesOfTemplate,
}: {
  communities: Communities;
  communitiesOfTemplate: Record<string, true>;
}): JSX.Element => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<{
    communities: Record<string, true>;
  }>();

  return (
    <div className="mb-8">
      <label htmlFor="goals" className="text-lg">
        {t('communities:CommunitiesThatHaveAccess')}
      </label>
      <Listbox
        items={Object.entries(communities).map(
          ([communityId, community]) => ({
            value: communityId,
            description: community.name,
          }),
          []
        )}
        placeholder={t('communities:SelectACommunity')}
        setValue={(val: string) =>
          setFieldValue('communities', { ...values.communities, [val]: true })
        }
      />
      <div>
        {Object.keys(values.communities).map((communityId) => {
          const community = communities[communityId];
          return (
            <div className="flex flex-row items-center">
              <div className="text-lg font-semibold">{community.name}</div>
              <div className="flex-grow" />
              <button
                className="text-base font-semibold"
                onClick={() => {
                  setFieldValue(
                    'communities',
                    _.pickBy(
                      values.communities,
                      (_val, key) => key !== communityId
                    )
                  );
                }}
              >
                {t('templates:RemoveCommunity')}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
