import { SessionLanguage } from 'types/baseTypes';
import { Nullable } from 'types/typesUtils';
import { functions } from './firebase';

const computePostSummaryFunction = functions.httpsCallable(
  'automation-rephrasePost'
);

const promptAI = functions.httpsCallable('automation-promptAI');

export const computePostSummary = async (
  post: string,
  name: string
): Promise<string> => {
  const result = await computePostSummaryFunction({
    post,
    name,
  });

  if (!result.data) {
    throw new Error('returned data is empty');
  }

  return result.data;
};

export const computeActivitySummary = async (
  sessionTitle: string,
  sessionLanguage: SessionLanguage | undefined,
  activityName: string,
  content: string
): Promise<Nullable<string>> => {
  const promptEn = `
    Peoples shared their thought on the following topic: ${sessionTitle} throught several activities.

    During activity named "${activityName}", these notes where taken :
    "
    ${content}
    "

    Summarize these notes in 50 lines.
  `;

  const promptFr = `
  Les pairs ont partagé sur le sujet "${sessionTitle}" au cours de différentes activités.

  Pendant l'activité intitulée "${activityName}", ces notes ont été prises :
  "
  ${content}
  "

  Résume ces notes en 50 lignes.
`;

  const result = await promptAI({
    prompt: sessionLanguage === 'en' ? promptEn : promptFr,
  });

  return result.data;
};
