import React from 'react';
import { useTranslation } from 'react-i18next';

import { Refresh, Cloud } from 'assets/icons';

export type SaveStatusProps = {
  status: 'saved' | 'saving';
};

const SaveStatus = ({ status }: SaveStatusProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-end space-x-1">
      {status === 'saving' ? (
        <>
          <Refresh className="h-5 w-5 animate-spin" />
          <p>{t('common:saving')}</p>
        </>
      ) : status === 'saved' ? (
        <>
          <Cloud className="h-5 w-5" />
          <p>{t('common:saved')}</p>
        </>
      ) : null}
    </div>
  );
};

export default SaveStatus;
