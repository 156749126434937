import * as React from 'react';

function SvgReviews(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 801.953 537"
      {...props}
    >
      <path
        d="M658.25 321.503a10.527 10.527 0 011.62-.346l21.899-44.497-6.686-10.023 13.808-12.067 17.357 21.24-35.35 51.449a10.496 10.496 0 11-12.649-5.756z"
        fill="#a0616a"
      />
      <path
        d="M695.632 260.718a4.514 4.514 0 01-1.016.801l-22.406 12.989a4.5 4.5 0 01-6.324-1.968l-10.897-23.023a4.476 4.476 0 011.537-5.645l13.89-9.448a4.502 4.502 0 015.717.544l19.413 19.481a4.504 4.504 0 01.086 6.27z"
        fill="#ccc"
      />
      <path
        fill="#a0616a"
        d="M727.092 515.689l-11.83 3.219-18.045-44.097 17.46-4.751 12.415 45.629z"
      />
      <path
        d="M705.892 517.827l22.815-6.207 3.908 14.364-37.179 10.116a14.887 14.887 0 0110.456-18.273z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M625.961 524.716l-12.259-.001-5.833-47.288 18.094.001-.002 47.288z"
      />
      <path
        d="M604.944 521.212h23.644V536.1h-38.53a14.887 14.887 0 0114.886-14.887z"
        fill="#2f2e41"
      />
      <circle cx={638.878} cy={194.165} r={24.561} fill="#a0616a" />
      <path
        d="M625.21 491h-16.753a4.513 4.513 0 01-4.496-4.223c-4.992-79.361-2.072-136.289 9.19-179.153a4.678 4.678 0 01.182-.552l.898-2.243a4.479 4.479 0 014.178-2.829h34.452a4.493 4.493 0 013.417 1.571l4.68 5.46c.09.107.176.215.258.33 21.101 29.425 40.011 86.953 61.913 164.278a4.485 4.485 0 01-2.959 5.516l-18.005 5.762a4.524 4.524 0 01-5.425-2.332l-46.722-96.907a3.5 3.5 0 00-6.62 1.046l-13.729 100.385a4.517 4.517 0 01-4.458 3.891z"
        fill="#2f2e41"
      />
      <path
        d="M659.068 309.43l-.14-.001-41.85-1.322a4.485 4.485 0 01-4.328-3.954c-5.305-42.941 9.244-54.445 15.746-57.397a3.46 3.46 0 002.012-2.743l.562-4.501a4.484 4.484 0 01.89-2.186c10.193-13.205 24.112-17.433 31.502-18.778a4.493 4.493 0 015.252 3.85l.842 6.462a3.56 3.56 0 00.714 1.692c21.259 27.792-2.425 68.827-7.389 76.77a4.508 4.508 0 01-3.813 2.109z"
        fill="#ccc"
      />
      <path
        d="M688.431 196.93a97.753 97.753 0 00-19.505-20.42c-6.198-4.841-13.098-8.978-20.71-10.956-7.612-1.979-16.018-1.642-22.963 2.049-6.945 3.69-14.125 11.024-14.17 18.89-.013 2.526.63 5.944 3.118 5.507l.72.096 18.006-3.637 7.67 26.615c.539 1.87 1.262 3.961 3.036 4.758 1.672.751 3.605.044 5.296-.662.279-.117 2.669-5.482 2.669-5.482a1 1 0 011.722-.288l1.42 1.774a2 2 0 002.332.596l29.366-12.258c1.353-.565 2.865-1.302 3.257-2.715.376-1.353-.451-2.721-1.264-3.867z"
        fill="#2f2e41"
      />
      <path d="M800.953 537h-301a1 1 0 010-2h301a1 1 0 010 2z" fill="#3f3d56" />
      <path
        d="M637.77 381.164a3.203 3.203 0 01-1.89-.625l-4.551-3.308a2.206 2.206 0 00-2.602 0l-4.552 3.308a3.213 3.213 0 01-4.945-3.593l1.738-5.35a2.207 2.207 0 00-.804-2.475l-4.552-3.308a3.213 3.213 0 011.89-5.813h5.626a2.208 2.208 0 002.105-1.53l1.738-5.35a3.213 3.213 0 016.113 0l1.739 5.35a2.208 2.208 0 002.105 1.53h5.626a3.213 3.213 0 011.889 5.813l-4.552 3.307a2.208 2.208 0 00-.804 2.475l1.739 5.351a3.165 3.165 0 01-1.168 3.593 3.203 3.203 0 01-1.888.625z"
        fill="current"
      />
      <path
        d="M655.165 370.466a10.743 10.743 0 001.407-16.412l7.773-93.872-23.144 3.184-.44 91.205a10.8 10.8 0 0014.404 15.895z"
        fill="#a0616a"
      />
      <path
        d="M665.857 273.7a4.514 4.514 0 01-1.28-.186l-24.846-7.308a4.5 4.5 0 01-2.927-5.941l9.195-23.754a4.476 4.476 0 015.152-2.772l16.415 3.569a4.502 4.502 0 013.542 4.521l-.763 27.492a4.504 4.504 0 01-4.488 4.378z"
        fill="#ccc"
      />
      <path
        d="M432 391H7a7.008 7.008 0 01-7-7V257a7.008 7.008 0 017-7h425a7.008 7.008 0 017 7v127a7.008 7.008 0 01-7 7zM7 252a5.006 5.006 0 00-5 5v127a5.006 5.006 0 005 5h425a5.006 5.006 0 005-5V257a5.006 5.006 0 00-5-5z"
        fill="#3f3d56"
      />
      <path
        d="M192.975 444.475a3.925 3.925 0 01-2.314-.766l-5.576-4.051a2.702 2.702 0 00-3.188 0l-5.576 4.051a3.937 3.937 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.985-3.032l-5.576-4.052a3.936 3.936 0 012.313-7.12h6.893a2.705 2.705 0 002.58-1.874l2.129-6.556a3.937 3.937 0 017.488 0l2.13 6.556a2.705 2.705 0 002.579 1.873h6.893a3.937 3.937 0 012.313 7.121l-5.576 4.052a2.705 2.705 0 00-.985 3.032l2.13 6.555a3.877 3.877 0 01-1.43 4.401 3.924 3.924 0 01-2.314.766zM249.484 444.475a3.925 3.925 0 01-2.314-.766l-5.577-4.051a2.702 2.702 0 00-3.187 0l-5.577 4.051a3.937 3.937 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.984-3.032l-5.577-4.052a3.937 3.937 0 012.314-7.12h6.893a2.705 2.705 0 002.579-1.874l2.13-6.556a3.936 3.936 0 017.487 0l2.13 6.556a2.705 2.705 0 002.58 1.873h6.892a3.937 3.937 0 012.314 7.121l-5.576 4.052a2.705 2.705 0 00-.986 3.032l2.13 6.555a3.877 3.877 0 01-1.43 4.401 3.924 3.924 0 01-2.313.766zM305.992 444.475a3.924 3.924 0 01-2.314-.766l-5.576-4.051a2.708 2.708 0 00-3.188 0l-5.576 4.051a3.936 3.936 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.985-3.032l-5.576-4.052a3.937 3.937 0 012.314-7.12h6.892a2.705 2.705 0 002.579-1.874l2.13-6.556a3.936 3.936 0 017.488 0l2.13 6.556a2.705 2.705 0 002.579 1.873h6.892a3.937 3.937 0 012.314 7.121l-5.576 4.052a2.705 2.705 0 00-.985 3.032l2.13 6.555a3.878 3.878 0 01-1.43 4.401 3.925 3.925 0 01-2.314.766zM362.5 444.475a3.925 3.925 0 01-2.313-.766l-5.577-4.051a2.702 2.702 0 00-3.187 0l-5.577 4.051a3.937 3.937 0 01-6.058-4.4l2.13-6.556a2.704 2.704 0 00-.985-3.032l-5.576-4.052a3.937 3.937 0 012.314-7.12h6.893a2.705 2.705 0 002.579-1.874l2.13-6.556a3.936 3.936 0 017.487 0l2.13 6.556a2.705 2.705 0 002.579 1.873h6.893a3.937 3.937 0 012.314 7.121l-5.577 4.052a2.705 2.705 0 00-.985 3.032l2.13 6.555a3.877 3.877 0 01-1.43 4.401 3.924 3.924 0 01-2.313.766z"
        fill="current"
      />
      <path
        d="M400.04 444.864a4.313 4.313 0 01-2.54-.841 4.26 4.26 0 01-1.572-4.835l2.13-6.555a2.319 2.319 0 00-.844-2.598l-5.576-4.052a4.324 4.324 0 012.542-7.822h6.892a2.318 2.318 0 002.21-1.606l2.13-6.556a4.324 4.324 0 018.226 0l2.13 6.556a2.318 2.318 0 002.21 1.606h6.893a4.324 4.324 0 012.541 7.822l-5.576 4.052a2.318 2.318 0 00-.845 2.598l2.13 6.555a4.324 4.324 0 01-6.654 4.834l-5.576-4.05a2.318 2.318 0 00-2.732 0l-5.577 4.05a4.31 4.31 0 01-2.541.842zm9.485-7.34a4.315 4.315 0 012.541.829l5.577 4.051a2.324 2.324 0 003.576-2.598l-2.13-6.555a4.313 4.313 0 011.571-4.834l5.576-4.052a2.324 2.324 0 00-1.366-4.204h-6.892a4.313 4.313 0 01-4.113-2.988l-2.13-6.555a2.324 2.324 0 00-4.42 0l-2.13 6.555a4.312 4.312 0 01-4.113 2.988h-6.893a2.324 2.324 0 00-1.366 4.204l5.577 4.052a4.314 4.314 0 011.57 4.834l-2.13 6.555a2.324 2.324 0 003.577 2.598l5.576-4.051a4.317 4.317 0 012.542-.828z"
        fill="#3f3d56"
      />
      <path
        d="M55 280a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13zM55 314a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13zM55 348a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13z"
        fill="#ccc"
      />
      <path
        d="M55 280a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13zM55 314a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13zM55 348a6.5 6.5 0 000 13h329a6.5 6.5 0 100-13z"
        fill="#ccc"
      />
      <path
        d="M432 141H7a7.008 7.008 0 01-7-7V7a7.008 7.008 0 017-7h425a7.008 7.008 0 017 7v127a7.008 7.008 0 01-7 7zM7 2a5.006 5.006 0 00-5 5v127a5.006 5.006 0 005 5h425a5.006 5.006 0 005-5V7a5.006 5.006 0 00-5-5z"
        fill="#3f3d56"
      />
      <path
        d="M192.975 194.475a3.925 3.925 0 01-2.314-.766l-5.576-4.051a2.702 2.702 0 00-3.188 0l-5.576 4.051a3.937 3.937 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.985-3.032l-5.576-4.052a3.936 3.936 0 012.313-7.12h6.893a2.705 2.705 0 002.58-1.874l2.129-6.556a3.937 3.937 0 017.488 0l2.13 6.556a2.705 2.705 0 002.579 1.873h6.893a3.937 3.937 0 012.313 7.121l-5.576 4.052a2.705 2.705 0 00-.985 3.032l2.13 6.555a3.877 3.877 0 01-1.43 4.401 3.924 3.924 0 01-2.314.766zM249.484 194.475a3.925 3.925 0 01-2.314-.766l-5.577-4.051a2.702 2.702 0 00-3.187 0l-5.577 4.051a3.937 3.937 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.984-3.032l-5.577-4.052a3.937 3.937 0 012.314-7.12h6.893a2.705 2.705 0 002.579-1.874l2.13-6.556a3.936 3.936 0 017.487 0l2.13 6.556a2.705 2.705 0 002.58 1.873h6.892a3.937 3.937 0 012.314 7.121l-5.576 4.052a2.705 2.705 0 00-.986 3.032l2.13 6.555a3.877 3.877 0 01-1.43 4.401 3.924 3.924 0 01-2.313.766zM305.992 194.475a3.924 3.924 0 01-2.314-.766l-5.576-4.051a2.708 2.708 0 00-3.188 0l-5.576 4.051a3.936 3.936 0 01-6.058-4.4l2.13-6.556a2.705 2.705 0 00-.985-3.032l-5.576-4.052a3.937 3.937 0 012.314-7.12h6.892a2.705 2.705 0 002.579-1.874l2.13-6.556a3.936 3.936 0 017.488 0l2.13 6.556a2.705 2.705 0 002.579 1.873h6.892a3.937 3.937 0 012.314 7.121l-5.576 4.052a2.705 2.705 0 00-.985 3.032l2.13 6.555a3.878 3.878 0 01-1.43 4.401 3.925 3.925 0 01-2.314.766z"
        fill="current"
      />
      <path
        d="M400.04 194.864a4.313 4.313 0 01-2.54-.841 4.26 4.26 0 01-1.572-4.835l2.13-6.555a2.319 2.319 0 00-.844-2.598l-5.576-4.052a4.324 4.324 0 012.542-7.822h6.892a2.318 2.318 0 002.21-1.606l2.13-6.556a4.324 4.324 0 018.226 0l2.13 6.556a2.318 2.318 0 002.21 1.606h6.893a4.324 4.324 0 012.541 7.822l-5.576 4.052a2.318 2.318 0 00-.845 2.598l2.13 6.555a4.324 4.324 0 01-6.654 4.834l-5.576-4.05a2.318 2.318 0 00-2.732 0l-5.577 4.05a4.31 4.31 0 01-2.541.842zm9.485-7.34a4.315 4.315 0 012.541.829l5.577 4.051a2.324 2.324 0 003.576-2.598l-2.13-6.555a4.313 4.313 0 011.571-4.835l5.576-4.051a2.324 2.324 0 00-1.366-4.204h-6.892a4.313 4.313 0 01-4.113-2.988l-2.13-6.555a2.324 2.324 0 00-4.42 0l-2.13 6.555a4.312 4.312 0 01-4.113 2.988h-6.893a2.324 2.324 0 00-1.366 4.204l5.577 4.051a4.314 4.314 0 011.57 4.835l-2.13 6.555a2.324 2.324 0 003.577 2.598l5.576-4.051a4.317 4.317 0 012.542-.828zM343.04 194.703a4.313 4.313 0 01-2.54-.84 4.26 4.26 0 01-1.572-4.836l2.13-6.555a2.319 2.319 0 00-.844-2.598l-5.576-4.052A4.324 4.324 0 01337.18 168h6.892a2.318 2.318 0 002.21-1.606l2.13-6.555a4.324 4.324 0 018.226 0l2.13 6.555a2.318 2.318 0 002.21 1.606h6.893a4.324 4.324 0 012.541 7.822l-5.576 4.052a2.318 2.318 0 00-.845 2.598l2.13 6.555a4.324 4.324 0 01-6.654 4.835l-5.576-4.051a2.318 2.318 0 00-2.732 0l-5.577 4.05a4.31 4.31 0 01-2.541.842zm9.485-7.339a4.315 4.315 0 012.541.828l5.577 4.052a2.324 2.324 0 003.576-2.598l-2.13-6.556a4.313 4.313 0 011.571-4.834l5.576-4.052A2.324 2.324 0 00367.87 170h-6.892a4.313 4.313 0 01-4.113-2.988l-2.13-6.555a2.324 2.324 0 00-4.42 0l-2.13 6.555a4.312 4.312 0 01-4.113 2.988h-6.892a2.324 2.324 0 00-1.367 4.204l5.577 4.052a4.314 4.314 0 011.57 4.834l-2.13 6.556a2.324 2.324 0 003.577 2.598l5.577-4.052a4.317 4.317 0 012.541-.828z"
        fill="#3f3d56"
      />
      <path
        d="M55 30a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13zM55 64a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13zM55 98a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13z"
        fill="#ccc"
      />
      <path
        d="M55 30a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13zM55 64a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13zM55 98a6.5 6.5 0 000 13h329a6.5 6.5 0 000-13z"
        fill="#ccc"
      />
    </svg>
  );
}

export default SvgReviews;
