import * as React from 'react';

function SvgHeart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.696 1C16.871 1 19 3.98 19 6.755 19 12.388 10.161 17 10 17c-.161 0-9-4.612-9-10.245C1 3.98 3.129 1 6.304 1 8.119 1 9.311 1.905 10 2.711 10.689 1.905 11.881 1 13.696 1z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHeart;
