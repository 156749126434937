import { database as db } from 'services/firebase';

export const requestMuteAudio = async (meetingName: string, userId: string) => {
  await db
    .ref(`meetingsData/${meetingName}/users/${userId}/requestedMuteAudio`)
    .set(true);
};

export const resetRequestedMuteAudio = async (
  meetingName: string,
  userId: string
) => {
  await db
    .ref(`meetingsData/${meetingName}/users/${userId}/requestedMuteAudio`)
    .remove();
};

export const requestTurnOffVideo = async (
  meetingName: string,
  userId: string
) => {
  await db
    .ref(`meetingsData/${meetingName}/users/${userId}/requestedTurnOffVideo`)
    .set(true);
};

export const resetRequestedTurnOffVideo = async (
  meetingName: string,
  userId: string
) => {
  await db
    .ref(`meetingsData/${meetingName}/users/${userId}/requestedTurnOffVideo`)
    .remove();
};

export const requestStopScreenSharing = async (
  meetingName: string,
  userId: string
) => {
  await db
    .ref(
      `meetingsData/${meetingName}/users/${userId}/requestedStopScreenSharing`
    )
    .set(true);
};

export const resetRequestedStopScreenSharing = async (
  meetingName: string,
  userId: string
) => {
  await db
    .ref(
      `meetingsData/${meetingName}/users/${userId}/requestedStopScreenSharing`
    )
    .remove();
};

export const startShareScreen = async (meetingName: string, userId: string) => {
  await db.ref(`meetingsData/${meetingName}/sharingUserId`).set(userId);
};

export const stopShareScreen = async (meetingName: string) => {
  await db.ref(`meetingsData/${meetingName}/sharingUserId`).remove();
};

export const getUserIdSharingScreen = async (meetingName: string) => {
  const userId = (
    await db.ref(`meetingsData/${meetingName}/sharingUserId`).get()
  ).val();
  return userId;
};
