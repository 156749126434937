import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { generateRandomString } from 'utils/utils';
import { Activities, Activity, Productions, MatchingStatus } from 'types/types';

import { createGroups } from 'model/matching';
import { resetGroups, setMatchingStatus } from 'model/groupManagement';

import YesNoModal from 'components/YesNoModal';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import FormattedText from 'components/FormattedText';
import Alert from 'components/Alert';

const AdminMatchMakeGroupsScreen = ({
  sessionId,
  activity,
  activities,
  productions,
  matchingStatus,
  participantsCount,
  activeParticipantsCount,
}: {
  sessionId: string;
  activity: Activity;
  activities: Activities;
  productions: Record<string, Productions>;
  matchingStatus: MatchingStatus;
  participantsCount: number;
  activeParticipantsCount: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const [showForceModal, setShowForceModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleClick = async () => {
    try {
      setProcessing(true);
      await resetGroups(sessionId, activity.name);
      await createGroups(sessionId, activity, activities, productions);
    } catch (e) {
      setProcessing(false);
      console.log((e as any).stack);
    }
  };
  const forceRelaunch = async () => {
    setShowForceModal(true);
  };

  const renderProgress = () => {
    if (processing || _.isNumber(matchingStatus)) {
      return (
        <div className="space-y-4">
          <h4 className="text-center text-xl font-semibold">
            {t('facilitator:creatingGroups')}
          </h4>
          <ProgressBar
            progress={_.isNumber(matchingStatus) ? matchingStatus / 100 : 0}
          />
          <Button
            onClick={forceRelaunch}
            text={t('facilitator:forceRelaunch')}
          />
        </div>
      );
    }
  };

  const confirmationCode = useMemo(
    () => showForceModal && generateRandomString(6, true),
    [showForceModal]
  );

  return (
    <div className="mt-24 flex h-full w-full flex-col items-center justify-center space-y-4">
      <YesNoModal
        critical
        size="lg"
        onClickYes={() => setMatchingStatus(sessionId, activity.name, 'NotRun')}
        onClickNo={() => setShowForceModal(false)}
        onClose={() => setShowForceModal(false)}
        open={showForceModal}
        confirmationCode={confirmationCode || undefined}
        textYes={t('sessions:launch')}
        textNo={t('common:Cancel')}
      >
        <div className="">
          <FormattedText className="whitespace-pre-line">
            {t('facilitator:restartMachingWarning')}
          </FormattedText>
        </div>
      </YesNoModal>
      {matchingStatus === 'Error' && (
        <Alert title={t('facilitator:matchingError')} type="error" />
      )}
      {renderProgress()}
      {_.includes(['Error', 'NotRun', -1], matchingStatus) && !processing && (
        <>
          <h2 className="text-lg font-semibold">
            {t('sessions:activeParticipants', {
              active: activeParticipantsCount,
              total: participantsCount,
            })}
          </h2>
          <Button onClick={handleClick} text={t('facilitator:startMatching')} />
        </>
      )}
    </div>
  );
};

export default AdminMatchMakeGroupsScreen;
