import React, { useMemo, useState } from 'react';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import { Hit, connectRefinementList } from 'react-instantsearch-core';
import moment from 'moment';

import { algoliaConf as conf } from 'config';
import { Link } from 'react-router-dom';
import { CommunityRole, SessionSearchResultType } from 'types/types';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

import Pagination from 'frameworks/algoliasearch/Pagination';
import DateRangePicker from 'frameworks/algoliasearch/DateRangePicker';
import SortAscending from 'assets/icons/SortAscending';

import SortBy from 'frameworks/algoliasearch/SortBy';
import SearchBox from 'frameworks/algoliasearch/SearchBox';
import ClearRefinements from 'frameworks/algoliasearch/ClearRefinements';

import { client } from 'services/searching';
import { useRefresh } from 'frameworks/algoliasearch/hooks';

import MenuButtons from 'components/MenuButtons';
import {
  Filter as FilterIcon,
  Duplicate /*, Template*/,
  Trash,
} from 'assets/icons';
import { deleteSession } from 'model/sessions';
import { useGlobalModal } from 'contexts/GlobalModal';

// const RangeSlider = ({ refine, value, setValue, min, max }: any) => {
//   const { t } = useTranslation();
//   const thisWeek = {
//     min: moment()
//       .startOf('week')
//       .valueOf(),
//     max: moment()
//       .endOf('week')
//       .valueOf()
//   };

//   const lastWeek = {
//     min: moment()
//       .subtract(1, 'w')
//       .startOf('week')
//       .valueOf(),
//     max: moment()
//       .subtract(1, 'w')
//       .endOf('week')
//       .valueOf()
//   };

//   const nextWeek = {
//     min: moment()
//       .add(1, 'w')
//       .startOf('week')
//       .valueOf(),
//     max: moment()
//       .add(1, 'w')
//       .endOf('week')
//       .valueOf()
//   };

//   const range = { min, max };

//   return (
//     <Radio.Group
//       value={value}
//       onChange={event => {
//         try {
//           setValue(event.target.value);
//           let selectedWeek = thisWeek;
//           switch (event.target.value) {
//             case 'current':
//               selectedWeek = thisWeek;
//               break;
//             case 'last':
//               selectedWeek = lastWeek;
//               break;
//             case 'next':
//               selectedWeek = nextWeek;
//               break;
//           }
//           refine(intersection(selectedWeek, range));
//         } catch (e) {
//           setValue(null);
//         }
//       }}
//       buttonStyle="solid"
//     >
//       <Radio.Button value="current" disabled={!overlaps(range, thisWeek)}>
//         {t('time:ThisWeek')}
//       </Radio.Button>
//       <Radio.Button value="last" disabled={!overlaps(range, lastWeek)}>
//         {t('time:LastWeek')}
//       </Radio.Button>
//       <Radio.Button value="next" disabled={!overlaps(range, nextWeek)}>
//         {t('time:NextWeek')}
//       </Radio.Button>
//     </Radio.Group>
//   );
// };
// const CustomRangeSlider = connectRange(RangeSlider);

export type CommunitySessionsProps = {
  userId: string;
  communityId: string;
  role: CommunityRole;
};

const CommunitySessions = ({
  userId,
  communityId,
  role,
}: CommunitySessionsProps): JSX.Element => {
  const { t } = useTranslation();

  const { show: showCreateSession } = useGlobalModal('createSession');

  const refresh = useRefresh();
  const [deletedSessions, setDeletedSessions] = useState<Set<string>>(
    new Set()
  );

  const RefinementComponent = ({ refine }: any) => {
    const Component = useMemo(
      () =>
        ({ hit }: { hit: Hit<SessionSearchResultType> }) =>
          deletedSessions.has(hit.sessionId) ? null : (
            <SessionRow hit={hit} refine={refine} />
          ),
      [refine]
    );

    return <Hits hitComponent={Component} />;
  };

  const RefinementList = connectRefinementList(RefinementComponent);

  const SessionRow = ({
    hit: { sessionId, accessCode, title, scheduledAt },
    refine,
  }: {
    hit: Hit<SessionSearchResultType>;
    refine: (value: string[]) => any;
  }) => {
    return (
      <div
        key={sessionId}
        className="mt-4 flex w-full flex-row items-center justify-between rounded-lg border border-surfaces-divider p-6"
      >
        <Link to={`/${accessCode}`}>
          <p className="text-sm font-semibold text-primary">
            {moment(scheduledAt[0])
              .format(t('misc:dateTimeFormat'))
              .toUpperCase()}
          </p>
          <h3 className="mt-1 text-xl font-semibold">{title}</h3>
        </Link>
        {role === 'organizer' ? (
          <MenuButtons
            actions={[
              {
                type: 'button',
                label: t('sessions:duplicate'),
                icon: <Duplicate className="h-6 w-6 stroke-3/2" />,
                onClick: async () => {
                  showCreateSession({
                    modalTitle: t('sessions:duplicateSessionTitle'),
                    title: t('common:duplicatedTag', { text: title }),
                    submitLabel: t('common:Duplicate'),
                    duplicatedSessionId: sessionId,
                    communityId: communityId,
                  });
                },
              },
              // {
              //   type: 'button',
              //   label: t('sessions:makeTemplate'),
              //   icon: <Template className="h-6 w-6 stroke-3/2" />,
              //   onClick: () => history.push(`/template/${accessCode}`),
              // },
              {
                type: 'button',
                label: t('common:Delete'),
                icon: <Trash className="h-6 w-6 stroke-3/2" />,
                onClick: async () => {
                  await deleteSession(sessionId);
                  setDeletedSessions(new Set([sessionId, ...deletedSessions]));
                },
                severity: 'danger',
              },
            ]}
            size="base"
          />
        ) : null}
      </div>
    );
  };

  // const CurrentRefinements = connectCurrentRefinements(({ items, refine }) => (
  //   <>
  //     {items.length > 0 ? (
  //       items.map(item =>
  //         item.items ? (
  //           item.items.map(nested => (
  //             <Tag
  //               key={item.label + item.attribute}
  //               closable={true}
  //               onClose={() => {
  //                 refine(nested.value);
  //               }}
  //             >
  //               {item.label}
  //               {nested.label}
  //             </Tag>
  //           ))
  //         ) : (
  //           <Tag
  //             key={item.attribute}
  //             closable={true}
  //             onClose={() => {
  //               refine(item.value);
  //               if (item.attribute === 'start_timestamp') {
  //                 setRadioState(null);
  //               }
  //             }}
  //           >
  //             {item.label}
  //           </Tag>
  //         )
  //       )
  //     ) : (
  //       <p className="italic">{t('misc:noAppliedFilters')}</p>
  //     )}
  //   </>
  // ));

  const filters = `NOT isTemplate:true AND communityId:${communityId}`;

  return (
    <div className="w-full">
      <InstantSearch
        searchClient={client}
        indexName={`${conf.sessionsIndexName}`}
        refresh={refresh}
      >
        <Configure filters={filters} />

        {/* <div className="flex flex-row mt-2 w-full text-left">
          <p className="mr-1">{t('common:Filters')}</p>
          <CurrentRefinements
            transformItems={items => {
              return items.map((item: any) =>
                item.attribute === 'start_timestamp'
                  ? {
                      ...item,
                      label: 'Date filter'
                    }
                  : item
              );
            }}
          />
        </div> */}
        {/* <div className="flex mt-2 w-full flex-rpw">
          <CustomRangeSlider
            attribute="start_timestamp"
            value={radioState}
            setValue={setRadioState}
          /> */}

        {/* <div className="flex-grow" />
          <ClearRefinements clearsQuery />
        </div> */}
        <div className="flex w-full flex-row flex-wrap lg:flex-nowrap lg:space-x-16">
          <div className="flex-grow">
            <div className="mb-4 flex flex-row items-center">
              <h1 className="flex-grow text-3xl font-semibold">
                {t('sessions:Sessions')}
              </h1>
              {role === 'organizer' ? (
                <Button
                  text={t('sessions:NewSession')}
                  onClick={() =>
                    showCreateSession({ communityId: communityId })
                  }
                />
              ) : null}
            </div>
            <div className="w-full">
              <SearchBox />
            </div>
            <div className="mt-8">
              <Pagination />
            </div>
            <RefinementList attribute="community" />
            <div className="mt-4">
              <Pagination />
            </div>
          </div>
          <div className="mt-8 w-full shrink-0 lg:mt-4 lg:w-80">
            <div className="mb-6 flex flex-row items-center space-x-4">
              <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-soft">
                <FilterIcon className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold">
                {t('common:FilterResults')}
              </h3>
            </div>

            <DateRangePicker attribute="start_timestamp" />
            <div className="mt-8 mb-6 flex flex-row items-center space-x-4">
              <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-soft">
                <SortAscending className="h-6 w-6 stroke-3/2" />
              </div>
              <h3 className="text-xl font-semibold">
                {t('common:SortResults')}
              </h3>
            </div>
            <SortBy
              items={[
                {
                  value: `${conf.sessionsIndexName}_start_timestamp_asc`,
                  label: t('common:chronologicalOrder'),
                },
                {
                  value: `${conf.sessionsIndexName}`,
                  label: t('common:antichronologicalOrder'),
                },
              ]}
              defaultRefinement={`${conf.sessionsIndexName}`}
            />
            <div className="mt-8">
              <ClearRefinements />
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default CommunitySessions;
