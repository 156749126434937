import { generateUUID } from 'utils/utils';

type NodeDataType = {
  id: string;
  topic: string;
  root?: boolean;
  expanded?: boolean;
  children?: NodeDataType[];
  style?: Object;
};
type NodeType = {
  nodeData: NodeDataType;
  linkData: any;
};

export const addRootNode = (label: string): NodeType => {
  return {
    nodeData: {
      id: generateUUID(),
      topic: label,
      root: true,
      children: [],
      expanded: true,
    },
    linkData: {},
  };
};

export const addNode = ({
  id,
  label,
  style,
}: {
  id?: string;
  label: string;
  style?: Object;
}): NodeDataType => {
  return {
    id: id || generateUUID(),
    topic: label,
    children: [],
    style: style,
  };
};
