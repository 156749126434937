import React from 'react';
import { useField } from 'formik';

export type InlineInputProps = {
  name: string;
  placeholder?: string;
};

const InlineInput = ({
  name,
  placeholder = '',
}: InlineInputProps): JSX.Element => {
  const [field] = useField(name);
  return (
    <span className="mx-2 inline-block w-min min-w-[60px]">
      <div className="invisible h-0 w-max px-1" aria-hidden="true">
        {field.value || placeholder}
      </div>
      <input
        type="text"
        className="my-1 inline w-full border-b border-primary p-1"
        {...field}
        placeholder={placeholder}
        value={field.value || ''}
      />
    </span>
  );
};

export default InlineInput;
