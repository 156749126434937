import React, { useState } from 'react';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';

import { DEFAULT_TEMPLATE_ID } from 'constants/AppConfig';

import CreateSessionForm from 'blocks/CreateSessionForm';
import { duplicateSession } from 'model/sessions';
import { useHistory } from 'react-router-dom';
import { useUserType } from 'model/users';

type ModalType = 'createSession';
type InitialDataCreateSession = Partial<{
  modalTitle: string;
  duplicatedSessionId: string;
  title: string;
  communityId: string;
  submitLabel: string;
}>;

type ModalProviderProps = {
  children: React.ReactNode;
  userId: string;
};

type ModalInfoType = {
  show: (initialData?: InitialDataCreateSession) => void;
};

const GlobalModalStateContext = React.createContext<
  Record<ModalType, ModalInfoType> | undefined
>(undefined);

const ModalProvider = ({
  children,
  userId,
}: ModalProviderProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [createSessionModalOpen, setCreateSessionModalOpen] = useState(false);
  const [initialCreateSessionData, setInitialCreateSessionData] =
    useState<InitialDataCreateSession | null>(null);

  const [userType] = useUserType(userId);

  return (
    <GlobalModalStateContext.Provider
      value={{
        createSession: {
          show: (initialData) => {
            setInitialCreateSessionData(initialData || null);
            setCreateSessionModalOpen(true);
          },
        },
      }}
    >
      {children}
      <Modal
        title={
          initialCreateSessionData?.modalTitle ||
          t('sessions:CreateANewSession')
        }
        fullHeight
        largeWidth
        open={createSessionModalOpen}
        body={
          <CreateSessionForm
            userId={userId}
            userType={userType || 'participant'}
            title={initialCreateSessionData?.title || ''}
            communityId={initialCreateSessionData?.communityId || ''}
            close={() => setCreateSessionModalOpen(false)}
            submitLabel={initialCreateSessionData?.submitLabel}
            onSubmit={async ({
              title,
              communityId,
              scheduledAt: [from, to],
              organizers,
              hidden,
              customAccessCode,
              language,
            }) => {
              const [accessCode] = await duplicateSession(
                initialCreateSessionData?.duplicatedSessionId ||
                  DEFAULT_TEMPLATE_ID,
                title,
                userId,
                false,
                {
                  communityId: communityId,
                  scheduledAt: [from.getTime(), to.getTime()],
                  language: language,
                },
                organizers,
                hidden,
                true,
                customAccessCode
              );
              if (accessCode) {
                history.push(`/${accessCode}`);
              }
            }}
          />
        }
        onClose={() => setCreateSessionModalOpen(false)}
      />
    </GlobalModalStateContext.Provider>
  );
};

function useGlobalModal(type: ModalType): ModalInfoType {
  const context = React.useContext(GlobalModalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalModal must be used within a ModalProvider');
  }

  return context[type];
}

export { ModalProvider, useGlobalModal };
