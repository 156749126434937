import { getSessionIdFromAccessCode } from 'model/sessions';
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  OrderedActivities,
  SessionTemplate,
  SessionTemplateDescription,
  Session,
} from 'types/types';
import MainBar from 'components/MainBar';
import BaseTemplateForm, { BaseTemplateParams } from './BaseTemplateForm';
import TemplateDetailsForm from './TemplateDetailsForm';
import { useFirebaseConnect, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  editTemplateDescription,
  promoteSessionAsTemplate,
  setCommunitiesOfTemplate,
} from 'model/sessionsTemplates';
import { useCommunities } from 'model/communitiesManagement';
import { sleep } from 'utils/pureUtils';
import { isGlobalAdmin } from 'model/users';
import { useUserContextWithDefaults } from 'contexts/UserContext';

const NewTemplateScreen = (): JSX.Element => {
  const { accessCode } = useParams<{ accessCode: string }>();
  const { userId, userType, userName } = useUserContextWithDefaults();
  const history = useHistory();
  const { t } = useTranslation();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [page, setPage] = useState<'base' | 'details'>('base');
  const [baseParams, setBaseParams] = useState<null | BaseTemplateParams>(null);
  const [templateCommunities, setTemplateCommunities] = useState<
    Record<string, true>
  >({});

  const db = useFirebase();
  const updateSessionDescription = (
    sessionId: string,
    values: {
      description: string;
      goals: string[];
    }
  ) => {
    if (sessionId) {
      db.ref(`sessionsNext/${sessionId}`).update(values);
    }
  };

  useEffect(() => {
    getSessionIdFromAccessCode(accessCode).then((sessionId) => {
      if (!sessionId) {
        history.push(`/home`, {
          error: t('templates:noSuchSession'),
        });
      } else {
        setSessionId(sessionId);
      }
    });
  }, [accessCode, history, t]);

  const refsToFetch: string[] = useMemo(() => {
    const refs: string[] = [];
    if (sessionId) {
      refs.push(
        `sessionsNext/${sessionId}`,
        `sessionsNextTemplates/${sessionId}`,
        `sessionTemplateDescriptions/${sessionId}`,
        `communitiesOfTemplates/${sessionId}`
      );
    }
    return refs;
  }, [sessionId]);

  useFirebaseConnect(refsToFetch);

  const sessionTemplate: SessionTemplate | null = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const session: Session | null = useSelector(
    (state: any) => sessionId && state.firebase.data.sessionsNext?.[sessionId]
  );

  const template: SessionTemplateDescription | null = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionTemplateDescriptions?.[sessionId]
  );

  const communitiesOfTemplate: Record<string, true> | null = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.communitiesOfTemplates?.[sessionId]
  );

  useEffect(() => {
    if (template && session) {
      setBaseParams({
        ...template,
        description: session?.description || '',
        goals: session?.goals || [],
      });
    } else if (session) {
      setBaseParams({
        duration: undefined,
        name: '',
        nbParticipants: [undefined, undefined],
        visibility: 'public',
        description: session?.description || '',
        goals: session?.goals || [],
      });
    }
  }, [template, session]);

  const activities = sessionTemplate?.activities;

  const orderedActivities: OrderedActivities = useMemo(() => {
    return _.sortBy(Object.values(activities || {}), 'index');
  }, [activities]);

  const [communities] = useCommunities(
    userId,
    isGlobalAdmin(userType) ? 'superadmin' : 'participant'
  );

  useEffect(() => {
    if (communitiesOfTemplate) {
      const filteredTemplatesOfCommunities = _.pickBy(
        communitiesOfTemplate,
        (_val, communityId) => !!communities?.[communityId]
      );
      setTemplateCommunities(filteredTemplatesOfCommunities);
    }
  }, [communitiesOfTemplate, communities]);

  return (
    <div className="relative min-h-screen w-full bg-white pb-12">
      <MainBar
        title={t('templates:NewTemplate')}
        userId={userId}
        userType={userType}
        userName={userName}
      />

      {page === 'base' || baseParams === null ? (
        <BaseTemplateForm
          templateBaseDescription={baseParams}
          communitiesOfTemplate={templateCommunities}
          communities={communities || {}}
          onSubmit={(
            values: BaseTemplateParams,
            communitiesOfTemplate: Record<string, true>
          ) => {
            setBaseParams(values);
            setTemplateCommunities(communitiesOfTemplate);
            setPage('details');
          }}
          onCancel={() => {
            history.goBack();
          }}
        />
      ) : (
        <TemplateDetailsForm
          activities={orderedActivities}
          activitiesDescription={template?.activitiesDescription || {}}
          onBack={() => setPage('base')}
          onSubmit={async (activities) => {
            const defaultNumber: [number, number] = [
              baseParams.nbParticipants[0] || 0,
              baseParams.nbParticipants[1] || 0,
            ];
            const description: SessionTemplateDescription = {
              name: baseParams.name,
              nbParticipants: defaultNumber,
              duration: baseParams.duration || 0,
              visibility: baseParams.visibility,
              activitiesDescription: activities,
              sourceSessionId: template?.sourceSessionId || sessionId!,
            };

            const oldCommunities = _.pickBy(
              communitiesOfTemplate || {},
              (_val, communityId) => !!communities?.[communityId]
            );

            if (!template) {
              const templateId = await promoteSessionAsTemplate(
                sessionId!,
                userId,
                session!.communityId,
                description
              );
              await updateSessionDescription(templateId, {
                description: baseParams.description,
                goals: baseParams.goals,
              });
              await setCommunitiesOfTemplate(
                templateId,
                templateCommunities,
                oldCommunities
              );
            } else {
              await Promise.all([
                editTemplateDescription(sessionId!, description),
                updateSessionDescription(sessionId!, {
                  description: baseParams.description,
                  goals: baseParams.goals,
                }),
                setCommunitiesOfTemplate(
                  sessionId!,
                  templateCommunities,
                  oldCommunities
                ),
              ]);
            }

            await sleep(5000);

            history.push(`/admin/all_templates`);
          }}
        />
      )}
    </div>
  );
};

export default NewTemplateScreen;
