import React from 'react';

const LayoutContext = React.createContext<{
  scrollToTop: () => void;
}>({
  scrollToTop: () => {},
});

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error(
      'useLayoutContext must be used within the Main Page of a Layout'
    );
  }
  return context;
};

export default LayoutContext;
