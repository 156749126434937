import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/utils';

export type NumberMetricProps = {
  value: number;
  label: string;
  percentage?: boolean;
  mitigate?: boolean;
};

const NumberMetric = ({
  value,
  label,
  percentage,
  mitigate = false,
}: NumberMetricProps) => {
  const { i18n } = useTranslation();
  return (
    <div className="flex flex-col flex-wrap items-center space-y-2">
      <p
        className={`mx-auto ${
          mitigate ? 'text-3xl' : 'text-5xl'
        } font-semibold text-primary-strong`}
      >
        {_.isNumber(value) ? formatNumber(value, i18n.language) : value}
        {percentage && <span className="text-2xl">%</span>}
      </p>
      <h3 className={`text-center ${mitigate ? '' : 'text-lg font-semibold'}`}>
        {label}
      </h3>
    </div>
  );
};

export default NumberMetric;
