import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import RevisionnedEditor from 'frameworks/plate/RevisionedEditor';
import { useUsers } from 'model/users';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { exportSingleDoc } from 'services/docExport';
import { DocMeta, User } from 'types/types';
import _ from 'lodash';
import { LinkedDocs } from './DocList';
import { Eye, Pencil } from 'assets/icons';

export type AuthorsListProps = {
  authors: Record<string, User>;
};

const AuthorsList = ({ authors }: AuthorsListProps) => {
  const { t } = useTranslation();

  const authorsArray = Object.entries(authors);

  return (
    <div>
      {authorsArray.length > 0 ? (
        <h3 className="text-xl font-semibold">{t('misc:By')}</h3>
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2">
        {authorsArray.map(([authorId, user]) => {
          return user ? (
            <div key={authorId} className="flex flex-row items-center py-2">
              <Avatar name={user.name} />
              <div className="ml-3 flex flex-grow flex-col">
                <div className="flex flex-row items-center space-x-4">
                  <h3 className="text-base font-semibold">{user.name}</h3>
                </div>
                <p className="text-sm text-black-soft">{user.email}</p>
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export type DocViewProps = {
  fallbackUrl: string;
};

const DocView = ({ fallbackUrl }: DocViewProps) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  const { params } = useRouteMatch<{ docId: string }>();
  const history = useHistory();

  const metaId = params?.docId;

  useFirebaseConnect(`contentDocsMeta/${metaId}`);

  const meta: DocMeta | undefined | null = useSelector(
    (state: any) => state.firebase.data.contentDocsMeta?.[metaId]
  );

  const [authors, usersLoaded] = useUsers(
    meta?.authorsIds || _.mapValues(meta?.authors, (_) => true) || {}
  );

  return (
    <div className="flex w-full flex-col space-y-4">
      {fallbackUrl && (
        <div className="flexrow flex space-x-4">
          <Button
            design="secondary"
            text={t('common:backToList')}
            onClick={() => {
              history.push(fallbackUrl);
            }}
          />
          {meta && (
            <>
              <Button
                text={t('common:export')}
                onClick={() => exportSingleDoc(meta.id)}
              />
              <div className="flex-grow" />
              <Button
                design="secondary"
                icon={editMode ? <Eye className="stroke-2" /> : <Pencil />}
                text={editMode ? t('common:Preview') : t('common:Edit')}
                onClick={() => setEditMode(!editMode)}
              />
            </>
          )}
        </div>
      )}

      {metaId ? (
        isLoaded(meta) && usersLoaded ? (
          meta ? (
            <div className="flex flex-col space-y-16">
              <RevisionnedEditor
                metaId={meta.id}
                readOnly={!editMode}
                showBorder={false}
              />
              <AuthorsList authors={authors} />
              <LinkedDocs meta={meta} />
            </div>
          ) : (
            <Redirect to={fallbackUrl} />
          )
        ) : (
          <Spinner />
        )
      ) : (
        <Redirect to={fallbackUrl} />
      )}
    </div>
  );
};

export default DocView;
