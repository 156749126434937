import React, { cloneElement } from 'react';

export type ActionsButtonProps = {
  icon: JSX.Element;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  label?: string;
  enabled?: boolean;
  disabled?: boolean;
  className?: string;
  colorClassNames?: {
    default?: string;
    enabled?: string;
  };
};
const ActionsButton = ({
  onClick = () => {},
  icon,
  size = 'md',
  label,
  enabled,
  disabled,
  className = '',
  colorClassNames,
}: ActionsButtonProps): JSX.Element => {
  const sizeClass =
    size === 'sm' ? 'w-6  h-6' : size === 'lg' ? 'w-10 h-10' : 'w-8 h-8';
  const colorText = `${
    disabled
      ? 'text-gray-400'
      : enabled !== undefined && enabled
      ? colorClassNames?.enabled || 'text-secondary'
      : colorClassNames?.default || 'text-primary'
  }`;

  return (
    <button
      className={`flex flex-col items-center rounded-md p-2 hover:bg-primary-soft ${
        disabled ? 'cursor-not-allowed' : ''
      }${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {cloneElement(icon, {
        className: `m-auto text-xl ${colorText} stroke-3/2  ${sizeClass} ${
          icon.props.className || ''
        }`,
      })}

      {label ? (
        <p className={`mt-1 text-xs uppercase ${colorText}`}>{label}</p>
      ) : null}
    </button>
  );
};

export default ActionsButton;
