import React, { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import TitlePlan from 'componentsOld/ContentPlan/TitlePlan';
import { changeUserType, setUserExpertMode } from 'model/users';
import { Table, Space, Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { Communities, ROLES, Users, UserType } from 'types/types';
import CommunitiesSelector from 'componentsOld/ComunitiesSelector';
import { setCommunitiesOfUser } from 'model/communitiesManagement';
import Listbox from 'components/Listbox';
import DeleteUserButton from '../blocks/DeleteUserButton';

const AppAdminEditUserTypeScreen = (): JSX.Element => {
  type ArrayRecord = {
    email: string;
    name: string;
    key: string;
    id: string;
    type: UserType;
    communities: Record<string, true>;
    expertMode?: boolean;
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useFirebaseConnect([
    {
      path: `/users`,
      storeAs: 'allUsers',
    },
    {
      path: `/typeForUser`,
      storeAs: 'allTypeForUser',
    },
    {
      path: `/communitiesOfUsers`,
      storeAs: 'allCommunitiesOfUsers',
    },
    {
      path: `/communities`,
      storeAs: 'allCommunities',
    },
  ]);

  const users: Users = useSelector(
    (state: any) => state.firebase.data.allUsers
  );
  const typesForUsers: Record<string, UserType> = useSelector(
    (state: any) => state.firebase.data.allTypeForUser
  );
  const communitiesOfUsers: Record<string, Record<string, true>> = useSelector(
    (state: any) => state.firebase.data.allCommunitiesOfUsers
  );

  const communities: Communities = useSelector(
    (state: any) => state.firebase.data.allCommunities
  );

  const searchInput = useRef<null | Input>(null);

  const usersArray: ArrayRecord[] = Object.entries(users || {})
    .filter(([, user]) => !!user?.email && !user.isTemporaryUser)
    .map(([key, user]) => ({
      ...user,
      id: key,
      key,
      type: typesForUsers?.[key],
      communities: communitiesOfUsers?.[key] || {},
    }));

  const changeCommunities =
    (userId: string) =>
    (
      oldCommunities: Record<string, true>,
      newCommunities: Record<string, true>
    ) =>
      setCommunitiesOfUser(userId, oldCommunities, newCommunities);

  const onChangeValueType = (userId: string) => (userType: UserType) => {
    changeUserType(userId, userType);
  };

  const onChangeExpertMode = (userId: string) => (exportMode: boolean) => {
    setUserExpertMode(userId, exportMode);
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text?: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const userTypeOptions: any = ROLES.map((role: UserType) => ({
    value: role,
    description: role,
  }));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: ArrayRecord, b: ArrayRecord) =>
        (a.name + '').localeCompare(b.name),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'email',
      dataIndex: 'email',
      sorter: (a: ArrayRecord, b: ArrayRecord) =>
        (a.email + '').localeCompare(b.email),
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Community',
      dataIndex: 'communityId',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <Space
          direction="vertical"
          style={{ padding: '8px', maxWidth: '200px' }}
        >
          <CommunitiesSelector
            selected={selectedKeys[0]}
            communities={communities}
            onChange={async (val) => setSelectedKeys([val])}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      ),
      // filters: [
      //   { text: 'Participant', value: 'participant' },
      //   { text: 'Facilitator', value: 'facilitator' },
      //   { text: 'Admin', value: 'admin' }
      // ],
      onFilter: (value: any, record: ArrayRecord) => {
        const selected = Object.keys(value);
        return (
          selected.length === 0 ||
          selected.some(
            (selectedCommunityId) =>
              communitiesOfUsers?.[record.id]?.[selectedCommunityId]
          )
        );
      },

      render: (_ignored: unknown, record: ArrayRecord) => {
        return (
          <CommunitiesSelector
            communities={communities || {}}
            selected={record.communities}
            onChange={changeCommunities(record.id)}
          />
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      filters: [
        { text: 'Participant', value: 'participant' },
        { text: 'Admin', value: 'admin' },
      ],
      onFilter: (value: any, record: ArrayRecord) =>
        typesForUsers?.[record.id] === value,
      render: (_ignored: unknown, record: ArrayRecord) => {
        return (
          <div className="min-w-[110px]">
            <Listbox<UserType>
              defaultValue={record.type}
              setValue={onChangeValueType(record.id)}
              items={userTypeOptions}
            />
          </div>
        );
      },
    },
    {
      title: 'Mode',
      dataIndex: 'expertMode',
      filters: [
        { text: 'Expert', value: true },
        { text: 'Normal', value: false },
      ],
      onFilter: (value: any, record: ArrayRecord) =>
        !!record.expertMode === value,
      render: (_ignored: unknown, record: ArrayRecord) => {
        return (
          <div className="min-w-[90px]">
            <Listbox<boolean>
              defaultValue={!!record.expertMode}
              setValue={onChangeExpertMode(record.id)}
              items={[
                {
                  value: true,
                  description: 'Expert',
                },
                {
                  value: false,
                  description: 'Normal',
                },
              ]}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (_ignored: unknown, record: ArrayRecord) => {
        return (
          <DeleteUserButton
            userId={record.id}
            userName={record.name}
            userEmail={record.email}
          />
        );
      },
    },
  ];
  return (
    <div>
      {isLoaded(users, typesForUsers, communitiesOfUsers, communities) ? (
        <Table
          style={{ width: '100%' }}
          columns={columns}
          dataSource={usersArray}
          rowKey={(record) => record.id}
        />
      ) : (
        <TitlePlan>{`Loading...`}</TitlePlan>
      )}
    </div>
  );
};

export default AppAdminEditUserTypeScreen;
