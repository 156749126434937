import React from 'react';
import { Select } from 'antd';
import { isLoaded } from 'react-redux-firebase';

import { Communities, User } from 'types/types';

const { Option } = Select;

const CommunitiesSelector = ({
  communities,
  selected,
  onChange,
}: {
  communities: Communities;
  selected: Record<string, true>;
  onChange: (
    val: Record<string, true>,
    old: Record<string, true>
  ) => Promise<void>;
  filter?: (id: string, user: User) => boolean;
}): JSX.Element => {
  const selectedCommunities = Object.keys(selected || {});

  const communitiesArray = Object.entries(communities || {}).map(
    ([key, { name }]) => ({
      value: key,
      name,
    })
  );

  const isLoading = !isLoaded(communitiesArray);

  return (
    <Select
      style={{ minWidth: '160px' }}
      showSearch
      value={selectedCommunities}
      mode="multiple"
      disabled={isLoading}
      placeholder="Select communities"
      onChange={(users) => {
        const usersRecord = users.reduce(
          (prec: Record<string, true>, userId) => {
            prec[userId] = true;
            return prec;
          },
          {}
        );
        return onChange(usersRecord, selected);
      }}
      filterOption={(input, option) => {
        return option?.data?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {communitiesArray.map(({ value, name }) => (
        <Option key={value} value={value} data={name}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default CommunitiesSelector;
