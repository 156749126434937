import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { database, fetchData } from 'services/firebase';
import {
  Activities,
  Activity,
  FeedbackType,
  Group,
  Production,
  ProductionMeta,
  Productions,
  Session,
  Topic,
  User,
} from 'types/types';
import { mapValuesAsync, sequencialForEach } from 'utils/utils';
import {
  addDoc,
  addDocTemplate,
  duplicateDoc,
  PreContext,
  removeDoc,
} from './docManagement';

import _ from 'lodash';
import { getGroups } from './groupManagement';
import { getParticipants } from './sessions';

export const sanitizeActivityProductions = async (
  activity: Activity
): Promise<Activity> => {
  const replaceProduction = async (
    production: Production
  ): Promise<Production> => {
    if (production.type === 'document') {
      if (production.template.type === 'OldTemplate') {
        return {
          ...production,
          template: {
            type: 'document',
            docId: await addDocTemplate(),
          },
        };
      } else if (production.template.type === 'document') {
        return {
          ...production,
          template: {
            type: 'document',
            docId: await duplicateDoc(production.template.docId),
          },
        };
      }
    }
    return production;
  };

  const replaceProductions = (productions: Productions): Promise<Productions> =>
    mapValuesAsync(productions, replaceProduction);

  if (activity.productions) {
    return {
      ...activity,
      productions: await replaceProductions(activity.productions),
    };
  }
  return activity;
};

export const sanitizeProductions = async (
  activities: Activities
): Promise<Activities> => {
  return await mapValuesAsync(activities, sanitizeActivityProductions);
};

export const removeProductionForUser = async (
  sessionId: string,
  activity: Activity,
  production: Production,
  groupId: string,
  userId: string
) => {
  if (production.type === 'document') {
    if (
      (production.mode === 'ByGroup' && groupId) ||
      production.mode === 'ByAll' ||
      production.mode === 'ByUser'
    ) {
      let ref: string = `sessionsNextData/${sessionId}/activities/${activity.name}/productions/${production.name}/`;
      if (production.mode === 'ByGroup') {
        ref += `${groupId}/`;
      }
      if (production.multiplicity === 'Each' || production.mode === 'ByUser') {
        ref += `${userId}/`;
      }

      const docIdObj: Record<string, boolean> = (
        await database.ref(ref).once('value')
      ).val();

      if (docIdObj && (production.multiplicity || 'One') !== 'One') {
        /// remove Doc
        await removeDoc(Object.keys(docIdObj)[0]);
        await database.ref(ref).remove();
      }
      // else remove author ?
    }
  }
};

export const removeProductionsForUser = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userId: string
) => {
  const activity = await fetchData<Activity>(
    `sessionsNextTemplates/${sessionId}/activities/${activityName}`
  );

  if (activity) {
    await Promise.all(
      Object.values(activity.productions || {}).map((production) =>
        removeProductionForUser(
          sessionId,
          activity,
          production,
          groupId,
          userId
        )
      )
    );
  }
};

export const useActivityProductionData = (
  sessionId: string,
  activity: Activity,
  production: Production
): [
  Record<string, any>,
  Record<string, Group>,
  Record<string, User>,
  boolean,
  boolean
] => {
  const productionRef = `/sessionsNextData/${sessionId}/activities/${activity.name}/productions/${production.name}`;

  let groupRef: string | null = null;
  if (activity.grouping.mode === 'Groups') {
    groupRef = `/sessionsNextData/${sessionId}/activities/${activity.name}/grouping/groups`;
  }

  const refs = [productionRef];
  if (groupRef) {
    refs.push(groupRef);
  }

  useFirebaseConnect(refs.map((ref) => ({ path: ref })));

  const prods: Record<string, any> = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
        activity.name
      ]?.productions?.[production.name] || {}
  );

  const groups: Record<string, Group> = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
        activity.name
      ]?.grouping?.groups
  );

  const usersKeys: string[] =
    production.mode === 'ByUser' ||
    (production.mode === 'ByAll' && production.multiplicity === 'Each')
      ? Object.keys(prods || {})
      : production.mode === 'ByGroup'
      ? Object.values(groups || {}).reduce((prec: string[], group) => {
          return [...prec, ...Object.keys(group.users || {})];
        }, [])
      : [];

  useFirebaseConnect(
    usersKeys.map((userKey) => ({ path: `users/${userKey}` }))
  );

  const users: Record<string, User> = useSelector((state: any) =>
    usersKeys.reduce((prec: Record<string, User>, userKey) => {
      const user = state.firebase.data?.users?.[userKey];
      if (user) {
        prec[userKey] = user;
      }
      return prec;
    }, {})
  );

  const res: [
    Record<string, any>,
    Record<string, Group>,
    Record<string, User>,
    boolean,
    boolean
  ] = [prods, groups || {}, users, isLoaded(prods), isLoaded(groups)];
  return res;
};

export const getPosts = async (
  sessionId: string
): Promise<Record<string, string>> => {
  const groups: Record<string, string> | null = (
    await database
      .ref(`sessionsNextData/${sessionId}/activities/post/productions/post`)
      .once('value')
  ).val();

  return groups || {};
};

export const useSessionPosts = (sessionId: string): Record<string, string> => {
  useFirebaseConnect([
    {
      path: `sessionsNextData/${sessionId}/activities/post/productions/post`,
    },
  ]);

  const sessionPosts: Record<string, string> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
        ?.productions?.post
  );

  return sessionPosts || {};
};

export const getPostsMeta = async (
  sessionId: string,
  activityName: string
): Promise<Record<string, ProductionMeta>> => {
  const postsMeta = await fetchData<Record<string, ProductionMeta>>(
    `sessionsNextData/${sessionId}/activities/${activityName}/productionsMeta/post`
  );
  return postsMeta || {};
};

export const usePostsMeta = (
  sessionId: string,
  activityName: string
): [Record<string, ProductionMeta>, boolean] => {
  useFirebaseConnect([
    {
      path: `sessionsNextData/${sessionId}/activities/${activityName}/productionsMeta/post`,
    },
  ]);

  const sessionPostsMeta: Record<string, ProductionMeta> | undefined =
    useSelector(
      (state: any) =>
        state.firebase.data.sessionsNextData?.[sessionId]?.activities?.[
          activityName
        ]?.productionsMeta?.post
    );

  return [sessionPostsMeta || {}, isLoaded(sessionPostsMeta)];
};

export const getTopics = async (
  sessionId: string
): Promise<Record<string, Topic>> => {
  const topics = await fetchData<Record<string, Topic>>(
    `sessionsNextData/${sessionId}/topics`
  );
  return topics || {};
};

export const useTopics = (
  sessionId: string
): [Record<string, Topic>, boolean] => {
  useFirebaseConnect([
    {
      path: `sessionsNextData/${sessionId}/topics`,
    },
  ]);

  const topics: Record<string, Topic> | undefined = useSelector(
    (state: any) => state.firebase.data.sessionsNextData?.[sessionId]?.topics
  );

  return [topics || {}, isLoaded(topics)];
};

export const addTopic = async (
  sessionId: string,
  topic: Topic
): Promise<string | null> => {
  const reference = await database
    .ref(`sessionsNextData/${sessionId}/topics`)
    .push(topic);
  return reference.key;
};

export const updateTopic = async (
  sessionId: string,
  topicId: string,
  topic: Topic
): Promise<void> => {
  return await database
    .ref(`sessionsNextData/${sessionId}/topics/${topicId}`)
    .set(topic);
};

export const removeTopic = async (sessionId: string, topicId: string) => {
  const updates: Record<string, any> = {};
  updates[`sessionsNextData/${sessionId}/topics/${topicId}`] = null;

  const activities = await fetchData<Activities>(
    `sessionsNextTemplates/${sessionId}/activities`
  );

  await Promise.all(
    Object.values(activities || {}).map(async (activity) => {
      const postProductionMeta = await fetchData<
        Record<string, ProductionMeta>
      >(
        `sessionsNextData/${sessionId}/activities/${activity.name}/productionsMeta/post`
      );

      Object.entries(postProductionMeta || {}).forEach(
        ([productionId, meta]) => {
          if (meta?.topic === topicId) {
            updates[
              `sessionsNextData/${sessionId}/activities/${activity.name}/productionsMeta/post/${productionId}/topic`
            ] = null;
          }
        }
      );

      const groupActivityName =
        activity?.grouping.mode === 'Groups' ? activity.name : undefined;
      if (groupActivityName) {
        const groups = await fetchData<Record<string, Group>>(
          `/sessionsNextData/${sessionId}/activities/${groupActivityName}/grouping/groups`
        );
        Object.entries(groups || {}).forEach(([groupId, group]) => {
          if (group.topic === topicId) {
            updates[
              `/sessionsNextData/${sessionId}/activities/${groupActivityName}/grouping/groups/${groupId}/topic`
            ] = null;
          }
        });
      }
    })
  );

  await database.ref().update(updates);
};

export const setPostMetaTopic = async (
  sessionId: string,
  activityName: string,
  productionId: string,
  topicId: string | null
) => {
  await database
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/productionsMeta/post/${productionId}/topic`
    )
    .set(topicId);
};

export const useSessionParticipantActivityProductions = (
  sessionId: string,
  activityName: string,
  userId: string
): Record<string, any> | undefined => {
  useFirebaseConnect([
    {
      path: `sessionsNextTemplates/${sessionId}/activities/${activityName}`,
    },
  ]);

  const activityTemplate = useSelector((state: any) =>
    _.get(
      state.firebase.data,
      `sessionsNextTemplates.${sessionId}.activities.${activityName}`
    )
  );

  const loadArray: string[] = [];
  const screens: any[] = Object.values(activityTemplate?.screens || {});
  const dataBindings: Record<string, string> =
    screens.length > 0 ? screens[0].dataBindings || {} : {};

  const productions: Record<string, any> = activityTemplate?.productions || {};

  const computedBindings = Object.entries(dataBindings).reduce<
    Record<string, any>
  >((current, [key, bond]) => {
    const parts = bond.split('.');
    if (parts[0] === 'productions') {
      if (parts.length > 3) {
        const bondActivityName = parts[1];
        const productionName = parts[2];

        const groupingActivityName =
          activityTemplate?.grouping?.mode === 'Groups'
            ? activityName
            : activityTemplate?.grouping?.mode === 'SameGroups' &&
              activityTemplate?.grouping?.activity
            ? activityTemplate.grouping.activity
            : null;

        if (groupingActivityName) {
          const groupPath = `sessionsNextData/${sessionId}/activities/${groupingActivityName}/grouping/groupOfUser/${userId}`;
          loadArray.push(groupPath);
          current['groupId'] = groupPath.replaceAll('/', '.');

          const groups = `sessionsNextData/${sessionId}/activities/${groupingActivityName}/grouping/groups`;
          loadArray.push(groups);
          current['groups'] = groups.replaceAll('/', '.');
        }

        const visibilityPath = `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${productionName}/visibility`;
        loadArray.push(visibilityPath);
        current['visibility'] = visibilityPath.replaceAll('/', '.');

        const multiplicityPath = `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${productionName}/multiplicity`;
        loadArray.push(multiplicityPath);
        current['multiplicity'] = multiplicityPath.replaceAll('/', '.');

        const modePath = `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions/${productionName}/mode`;
        loadArray.push(modePath);
        current['mode'] = modePath.replaceAll('/', '.');

        const path = `sessionsNextData/${sessionId}/activities/${bondActivityName}/productions/${productionName}`;
        loadArray.push(path);
        current[key] = path.replaceAll('/', '.');
      }
    }
    return current;
  }, {});

  useFirebaseConnect(loadArray);

  const values: Record<string, any> = useSelector((state: any) =>
    _.mapValues(computedBindings, (path) => {
      return _.get(state.firebase.data, path);
    })
  );

  let dataActivitySession: Record<string, any> = {};

  if (values.post) {
    dataActivitySession.post = values.post[userId] || undefined;
  }

  dataActivitySession.criteria = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key.startsWith('crit_') && value) {
      const criterium = productions[key];
      dataActivitySession.criteria.push({
        value: value[userId],
        description: criterium.description,
      });
    }
  });

  dataActivitySession.votes = [];
  if (values.votes && values.posts) {
    const votes: Record<string, { post: string; userId: string }> =
      values.votes || {};
    Object.entries(votes).forEach(([key, vote]) => {
      if (key === userId) {
        Object.keys(vote || {}).forEach((userId) => {
          dataActivitySession.votes.push({
            post: values.posts[userId],
            userId: userId,
          });
        });
      }
    });
  }

  if (values.docs) {
    let searchPath = '';
    if (values.groupId && values.mode === 'ByGroup') {
      searchPath += `.${values.groupId}`;
    }
    if (values.mode === 'ByUser' || values.multiplicity === 'Each') {
      searchPath += `.${userId}`;
    }

    if (searchPath.startsWith('.')) {
      searchPath = searchPath.slice(1);
    }

    dataActivitySession.docs =
      searchPath.length > 0 ? _.get(values.docs, searchPath) : values.docs;
    if (values.visibility === 'Private' && values.mode !== 'ByAll') {
      dataActivitySession.docsAuthorizedUsersId =
        values.groups?.[values.groupId]?.users;
    }
  }

  dataActivitySession.questions = [];
  [
    'valuable',
    'questionAAnswer',
    'questionBAnswer',
    'questionCAnswer',
    'future',
  ].forEach((key) => {
    if (values[key] && values[key][userId]) {
      const question = productions[key];
      dataActivitySession.questions.push({
        value: values[key][userId],
        description: question.description,
        type: question.type,
      });
    }
  });

  return dataActivitySession;
};

export const useSessionParticipantsPost = (
  sessionId: string
): [Record<string, string> | undefined, boolean] => {
  useFirebaseConnect([
    {
      path: `sessionsNextData/${sessionId}/users/participants`,
    },
    {
      path: `sessionsNextData/${sessionId}/activities/post/productions/post`,
    },
  ]);

  const sessionParticipants: Record<string, boolean> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.participants
  );

  const sessionPosts: Record<string, string> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
        ?.productions?.post
  );

  if (!isLoaded(sessionParticipants) && !isLoaded(sessionPosts)) {
    return [undefined, false];
  }

  return [
    !!sessionParticipants
      ? Object.keys(sessionParticipants).reduce<Record<string, string>>(
          (current, participantId) => {
            current[participantId] = sessionPosts?.[participantId] || '';
            return current;
          },
          {}
        )
      : undefined,
    true,
  ];
};

export const useSessionFeedbacks = (
  sessionId: string
): FeedbackType[] | undefined => {
  useFirebaseConnect([
    {
      path: `sessionsNextTemplates/${sessionId}/activities/feedback/productions`,
    },
    {
      path: `/sessionsNextData/${sessionId}/activities/feedback/productions/`,
    },
  ]);

  const feedbacksTemplateProduction = useSelector((state: any) =>
    _.get(
      state.firebase.data,
      `sessionsNextTemplates.${sessionId}.activities.feedback.productions`
    )
  );

  const feedbacks: Record<string, FeedbackType> = useSelector((state: any) =>
    _.get(
      state.firebase.data,
      `sessionsNextData.${sessionId}.activities.feedback.productions`
    )
  );

  const computedFeedbacks = Object.entries<any>(
    feedbacksTemplateProduction || {}
  ).map(([key, template]: [string, FeedbackType]) => {
    const meta: any = {};
    if (template.type === 'number') {
      meta.sum = 0;
      meta.moyenne = 0;
    }

    const answers = Object.entries(feedbacks?.[key] || {}).map(
      ([userId, value]) => {
        if (template.type === 'number' && typeof value === 'number') {
          meta.sum += value;
        }
        return {
          value,
          userId,
        };
      }
    );

    if (template.type === 'number' && answers.length > 0) {
      meta.moyenne = meta.sum / answers.length;
    }

    return {
      index: template.index,
      type: template.type,
      description: template.description,
      answers: answers,
      meta: meta,
    };
  });

  return computedFeedbacks.sort((a, b) => a.index - b.index);
};

export const useSessionActivityCriteria = (
  sessionId: string,
  activityName: string
): Record<string, any> => {
  useFirebaseConnect([
    {
      path: `sessionsNextTemplates/${sessionId}/activities/${activityName}/productions`,
    },
    {
      path: `sessionsNextData/${sessionId}/activities/${activityName}/productions`,
    },
  ]);

  const templateProductions: Record<string, any> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextTemplates?.[sessionId]?.activities?.[
        activityName
      ]?.productions
  );

  const criteriaData: Record<string, any> | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities?.[
        activityName
      ]?.productions
  );

  const criteria: Record<string, any> = Object.entries(
    criteriaData || {}
  ).reduce((current: { [key: string]: any }, [key, productions]) => {
    if (key.startsWith('crit_') && templateProductions) {
      const criteriumDescription = templateProductions[key]?.description;
      current[criteriumDescription || key] = productions;
    }
    return current;
  }, {});

  return criteria;
};

export const updateProductionForUser = async (
  sessionId: string,
  activityName: string,
  productionName: string,
  userId: string,
  value: string
): Promise<void> => {
  await database
    .ref(
      `sessionsNextData/${sessionId}/activities/${activityName}/productions/${productionName}/${userId}`
    )
    .set(value);
};

export const generateProductionForUser = async (
  sessionId: string,
  communityId: string | undefined,
  activity: Activity,
  production: Production,
  groupId: string | undefined,
  userId: string,
  context: PreContext = {}
) => {
  if (production.type === 'document') {
    if (
      (production.mode === 'ByGroup' && groupId) ||
      production.mode === 'ByAll' ||
      production.mode === 'ByUser'
    ) {
      let ref: string = `sessionsNextData/${sessionId}/activities/${activity.name}/productions/${production.name}/`;
      if (production.mode === 'ByGroup') {
        ref += `${groupId}/`;
      }
      if (production.multiplicity === 'Each' || production.mode === 'ByUser') {
        ref += `${userId}/`;
      }

      const docIdObj: Record<string, boolean> = (
        await database.ref(ref).once('value')
      ).val();

      if (!docIdObj) {
        /// make doc

        const template = production.template;
        await addDoc(
          template,
          ref,
          communityId,
          sessionId,
          activity.name,
          groupId,
          production.docName,
          production.docTitle,
          userId,
          context,
          activity.humanName || activity.name,
          production.visibility || 'Public'
        );
      } else {
        if (production.multiplicity !== 'Each') {
          await database
            .ref(
              `contentDocsMeta/${Object.keys(docIdObj)[0]}/authorsIds/${userId}`
            )
            .set(true);
        }
      }
    }
  }
};

export const generateProductions = async (
  sessionId: string,
  communityId: string | undefined,
  activity: Activity,
  activities: Activities,
  groupPrefix: string | undefined
) => {
  const productions = activity.productions;
  const groupingMode = activity.grouping.mode;

  if (groupingMode === 'Groups') {
    const groups = await getGroups(sessionId, activity.name);
    await Promise.all(
      Object.entries(productions || {}).map(async ([key, production]) => {
        if (production.type === 'document') {
          await Promise.all(
            Object.entries(groups || {}).map(([groupId, group]) =>
              sequencialForEach(
                Object.keys(group.users || {}),
                (userId: string) =>
                  generateProductionForUser(
                    sessionId,
                    communityId,
                    activity,
                    production,
                    groupId,
                    userId,
                    {
                      group,
                      group_number: `${groupPrefix || ''}${
                        group?.number || ''
                      }`,
                      activities: activities,
                    }
                  )
              )
            )
          );
        }
      })
    );
  } else {
    const participants: Record<string, true> =
      (await getParticipants(sessionId)) || {};
    await Promise.all(
      Object.entries(productions || {}).map(async ([key, production]) => {
        if (production.type === 'document') {
          await sequencialForEach(Object.keys(participants), (userId: string) =>
            generateProductionForUser(
              sessionId,
              communityId,
              activity,
              production,
              undefined,
              userId,
              {
                activities: activities,
              }
            )
          );
        }
      })
    );
  }
};

export const generateProductionsForUser = async (
  sessionId: string,
  activityName: string,
  groupId: string,
  userId: string
) => {
  const activities = await fetchData<Activities>(
    `sessionsNextTemplates/${sessionId}/activities`
  );
  const activity = activities?.[activityName];
  const session = await fetchData<Session>(`sessionsNext/${sessionId}`);

  const group =
    (await fetchData<Group>(
      `sessionsNextData/${sessionId}/activities/${activityName}/grouping/groups/${groupId}`
    )) || undefined;

  if (activity && session) {
    await Promise.all(
      Object.values(activity.productions || {}).map((production) =>
        generateProductionForUser(
          sessionId,
          session.communityId,
          activity,
          production,
          groupId,
          userId,
          {
            group,
            group_number: `${session?.groupPrefix || ''}${group?.number || ''}`,
            activities: activities,
          }
        )
      )
    );
  }
};
