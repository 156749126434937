import invariant from 'tiny-invariant';
import { SyncElement } from '../../model';
import { getTarget } from '../../path';
/**
 * Applies a insert text operation to a SharedType.
 *
 * @param doc
 * @param op
 */
export default function insertText(doc, op) {
    const node = getTarget(doc, op.path);
    const nodeText = SyncElement.getText(node);
    invariant(nodeText, 'Apply text operation to non text node');
    nodeText.insert(op.offset, op.text);
    return doc;
}
