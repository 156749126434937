import React, { useMemo } from 'react';
import { upperFirst } from 'utils/utils';
import {
  Productions,
  Production,
  Activities,
  DataBindings,
  Activity,
  Screen,
  ScreenTemplate,
  Users,
} from 'types/types';

import { Mode, Navigation } from 'types/ui';

import {
  useFirebase,
  useFirebaseConnect,
  isLoaded,
  isEmpty,
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { dataPathToRef } from 'model/dataPathUtils';

import LoadingScreen from 'screens/LoadingScreen';

import { HARDCODED_SCREENS } from 'constants/HardCodedScreens';
import { useEffect } from 'react';
import { useLayoutContext } from './VideoLayout/LayoutContext';

export const useHydrate = (
  sessionId: string,
  sessionTemplateId: string,
  activity: Activity,
  userId: string,
  productions: Record<string, Productions>,
  activities: Activities,
  dataBindings?: DataBindings,
  editable?: boolean
) => {
  const firebase = useFirebase();

  const bindings = dataBindings || {};
  const entries = Object.entries(bindings);

  // console.log(entries);

  const refs: any = entries.map(([name, dataPath]) => [
    name,
    ...dataPathToRef(sessionId, userId, productions, dataPath, activities),
  ]);

  // console.log(refs);

  // TODO: perf: dedup identical deps
  const deps = refs.reduce((deps: string[], [, , , depsArr]: any) => {
    if (depsArr) {
      depsArr.forEach((depRef: string[]) => {
        deps.push(depRef.join('/'));
      });
    }
    return deps;
  }, []);
  useFirebaseConnect(deps);

  const depsData: Record<string, any> = useSelector(
    (state: any) =>
      refs.reduce((prec: Record<string, any>, [name, , , depsArr]: any) => {
        if (depsArr) {
          prec[name] = depsArr.map((ref: string[]) =>
            _.get(state.firebase.data, ref.join('.'))
          );
        }
        return prec;
      }, {}),
    _.isEqual
  );

  //console.log(depsData);

  // console.log(
  // refs.reduce((prec, [name, ref, production, depsArr]) => {
  //   if (depsArr) {
  //     if (isLoaded(...depsData[name])) {
  //       prec.push({ path: ref(...depsData[name]).join('/') });
  //     }
  //   } else {
  //     prec.push({ path: ref.join('/') });
  //   }
  //   return prec;
  // }, []);
  // );

  useFirebaseConnect(
    refs.reduce((prec: { path: string }[], [name, ref, , depsArr]: any) => {
      if (depsArr) {
        if (isLoaded(...depsData[name])) {
          prec.push({ path: ref(...depsData[name]).join('/') });
        }
      } else {
        prec.push({ path: ref.join('/') });
      }
      return prec;
    }, [])
  );

  const data: Record<string, any> = useSelector(
    (state: any) =>
      refs.reduce(
        (prec: Record<string, any>, [name, ref, production, depsArr]: any) => {
          const prod = production as Production | undefined;

          if (depsArr) {
            if (isLoaded(...depsData[name])) {
              prec[name] = _.get(
                state.firebase.data,
                ref(...depsData[name]).join('.')
              );
            }
          } else {
            prec[name] = _.get(state.firebase.data, ref.join('.'));
          }
          if (
            editable &&
            prod?.type === 'document' &&
            prod?.template.type === 'document'
          ) {
            prec[name] = {
              [prod.template.docId]: true,
            };
          }

          return prec;
        },
        {}
      ),
    _.isEqual
  );

  const setters = useMemo(() => {
    return refs.reduce(
      (
        prec: Record<string, (val: any) => Promise<any>>,
        [name, ref, production, depsArr]: any
      ) => {
        if (!depsArr) {
          prec[`set${upperFirst(name)}`] = (val) =>
            firebase.ref(ref.join('/')).set(val);
          if (production?.type === 'set') {
            prec[`set${upperFirst(name)}`] = (val) =>
              firebase.ref(`${ref.join('/')}/${val}`).set(true);

            prec[`unset${upperFirst(name)}`] = (val) =>
              firebase.ref(`${ref.join('/')}/${val}`).remove();
          }
        }
        return prec;
      },
      {}
    );
  }, [refs, firebase]);

  // console.log(data);

  const usersRefs = useMemo(() => {
    return refs.reduce((prec: string[], [name, , production]: any) => {
      if (production?.mode === 'ByUsers' && data[name]) {
        return Object.keys(data[name]).reduce((prec, userId) => {
          prec.push(`/users/${userId}`);
          return prec;
        }, prec);
      } else {
        return prec;
      }
    }, []);
  }, [refs, data]);

  const usersKeys: string[] = _.uniq(usersRefs);

  useFirebaseConnect(
    usersKeys.map((userKey) => ({ path: `users/${userKey}` }))
  );

  const users: Users = useSelector((state: any) =>
    usersKeys.reduce((prec: Users, userKey: string) => {
      const user = state.firebase.data?.users?.[userKey];
      if (user) {
        prec[userKey] = user;
      }
      return prec;
    }, {})
  );

  // console.log(users);

  const additionalProps = useMemo(() => {
    return refs.reduce(
      (prec: Record<string, any>, [name, , production]: any) => {
        if (production?.type === 'enum') {
          prec[`${name}Options`] = production.options;
          prec[`set${upperFirst(name)}Options`] = (val: string[]) =>
            firebase
              .ref(
                `sessionsNextTemplates/${sessionTemplateId}/activities/${activity.name}/productions/${production.name}/options`
              )
              .set(val);
        }
        if (editable && production?.type === 'document') {
          prec['docName'] = production.docName;
          prec['setDocName'] = (val: string) =>
            firebase
              .ref(
                `sessionsNextTemplates/${sessionTemplateId}/activities/${activity.name}/productions/${production.name}/docName`
              )
              .set(val);
        }
        if (production?.type === 'document') {
          prec[`${name}Production`] = production;
        }

        if (
          production?.type === 'string' ||
          production?.type === 'boolean' ||
          production?.type === 'number'
        ) {
          prec[`set${upperFirst(name)}Description`] = (val: string[]) =>
            firebase
              .ref(
                `/sessionsNextTemplates/${sessionTemplateId}/activities/${activity.name}/productions/${name}/description`
              )
              .set(val);
        }
        return prec;
      },
      {}
    );
  }, [refs, activity.name, sessionTemplateId, firebase, editable]);

  const loaded = isLoaded(
    ...[
      ...Object.values(data),
      ..._.flatten(Object.values(depsData)),
      ...Object.values(users),
    ].filter((dat) => {
      return !isEmpty(dat);
    })
  );

  return [
    {
      ...data,
      ...setters,
      ...additionalProps,
      userId,
      sessionId,
      users,
      activity,
    },
    loaded,
  ];
};

const HardcodedScreenTemplate = ({
  communityId,
  userName,
  template,
  data,
  dbRef,
  editable,
  navigation,
  mode,
  context,
}: {
  communityId: string | undefined;
  userName: string;
  template: ScreenTemplate;
  data: Record<string, any>;
  dbRef: string;
  editable?: boolean;
  navigation: Navigation;
  mode: Mode;
  context: Record<string, string>;
}): JSX.Element => {
  const { name, editableContent, params } = template;

  const baseRef = `${dbRef}/content/template/editableContent`;

  const firebase = useFirebase();

  const hardCodedScreen = HARDCODED_SCREENS[name];

  if (!hardCodedScreen) {
    throw Error(`This type of screen is non existent: ${name}`);
  }

  const editableContentSetters = hardCodedScreen.editableProps.reduce(
    (prec: Record<string, (prop: string) => Promise<void>>, key: string) => {
      prec[`set${upperFirst(key)}`] = async (val) => {
        console.log(`${baseRef}/${key}`, val);
        await firebase.ref(`${baseRef}/${key}`).set(val);
      };

      return prec;
    },
    {}
  );

  const Component: React.ComponentType<any> = hardCodedScreen.component;

  return (
    <Component
      {...editableContent}
      {...params}
      {...editableContentSetters}
      {...data}
      userName={userName}
      editable={editable}
      navigation={navigation}
      mode={mode}
      context={context}
      communityId={communityId}
    />
  );
};

const ScreenElement = ({
  sessionId,
  communityId,
  sessionDataId,
  userId,
  userName,
  productions,
  screen,
  activities,
  activity,
  editable,
  navigation,
  mode,
  context,
}: {
  sessionId: string;
  communityId: string | undefined;
  sessionDataId: string;
  userId: string;
  productions: Record<string, Productions>;
  screen: Screen;
  userName: string;
  activities: Activities;
  activity: Activity;
  editable?: boolean;
  navigation: Navigation;
  mode: Mode;
  context: Record<string, string>;
}): JSX.Element => {
  const content = screen.content;
  const dataBindings = screen.dataBindings;

  const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/${screen.name}`;

  const { scrollToTop } = useLayoutContext();

  useEffect(() => {
    console.log('Done');
    scrollToTop();
  }, [screen.name, scrollToTop]);

  const [data, loaded] = useHydrate(
    sessionDataId,
    sessionId,
    activity,
    userId,
    productions,
    activities,
    dataBindings,
    editable
  );

  if (!loaded) {
    return <LoadingScreen />;
  }

  switch (content.template.type) {
    case 'HardcodedScreenTemplate':
      return (
        <HardcodedScreenTemplate
          communityId={communityId}
          userName={userName}
          template={content.template}
          data={data}
          dbRef={dbRef}
          editable={editable}
          navigation={navigation}
          mode={mode}
          context={context}
        />
      );
    default:
      throw Error('Unknown template type');
  }
};

export default ScreenElement;
