// this was optimized so that unrelevant config is not built in

import _ from 'lodash';

// in prod js file
export const firebaseConfig =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      {
        apiKey: 'AIzaSyC551j76WlZ-dJ8YlywJ-B1Hh0zdPRKZls',
        authDomain: 'wap-live-eur.firebaseapp.com',
        databaseURL:
          'https://wap-live-eur-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'wap-live-eur',
        storageBucket: 'wap-live-eur.appspot.com',
        messagingSenderId: '368989295939',
        appId: '1:368989295939:web:cca4fc4fcd45a69eaf8bf6',
      }
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      {
        apiKey: 'AIzaSyAobtuzvYWFRBQySgRq9YE1u4x98u3giTM',
        authDomain: 'wap-dev-eur.firebaseapp.com',
        databaseURL:
          'https://wap-dev-eur-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'wap-dev-eur',
        storageBucket: 'wap-dev-eur.appspot.com',
        messagingSenderId: '1075893434753',
        appId: '1:1075893434753:web:e8af22e3e01477c866d080',
      }
    : process.env.REACT_APP_ENV === 'exp'
    ? {
        apiKey: 'AIzaSyD9bv2epVmpyD6Di_1aOCHH2UF27HubKnY',
        authDomain: 'wap-experimental.firebaseapp.com',
        databaseURL:
          'https://wap-experimental-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'wap-experimental',
        storageBucket: 'wap-experimental.appspot.com',
        messagingSenderId: '154025279456',
        appId: '1:154025279456:web:259790afd1a8e0301a3585',
        measurementId: 'G-FLKV86FQSR',
      }
    : //local
      {
        apiKey: 'AIzaSyAobtuzvYWFRBQySgRq9YE1u4x98u3giTM',
        authDomain: 'wap-experimental.firebaseapp.com',
        databaseURL: 'http://localhost:9000/?ns=wap-dev-eur-default-rtdb',
        projectId: 'wap-dev-eur',
        storageBucket: 'wap-dev-eur.appspot.com',
        messagingSenderId: '1075893434753',
        appId: '1:1075893434753:web:e8af22e3e01477c866d080',
      };

export const appCheckKey =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      '6LepeWAiAAAAADYiLxMwgHQiKPBduO0da9fwIbba'
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      '6LdDWmAiAAAAANga8QK5xAsfW1hItwJEhi9q902M'
    : process.env.REACT_APP_ENV === 'exp'
    ? '6LeacWAiAAAAAPod06zN_-u1mta08gl2sZLnXFCg'
    : //local
      '6LdDWmAiAAAAANga8QK5xAsfW1hItwJEhi9q902M';

export const matchingUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      'https://europe-west1-wap-live-eur.cloudfunctions.net/wap-match'
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      'https://europe-west1-wap-dev-eur.cloudfunctions.net/wap-match'
    : process.env.REACT_APP_ENV === 'exp'
    ? //wap-experimental
      'https://europe-west1-wap-experimental.cloudfunctions.net/wap-match'
    : //local
      'http://localhost:8080/';

export const domainUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      'https://wap.live'
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      'https://wap-dev-eur.firebaseapp.com'
    : process.env.REACT_APP_ENV === 'exp'
    ? //wap-experimental
      'https://next.wap.live'
    : //local
      'http://localhost:3000';

export const collabUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      'wss://ycollab.services.wap.live'
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      'wss://ycollab-dev.services.wap.live'
    : process.env.REACT_APP_ENV === 'exp'
    ? //wap-experimental
      'wss://ycollab-exp.services.wap.live'
    : //local
      'ws://localhost:1789';

export const algoliaConf = {
  appId: '7V4AIMFFC8',
  key: 'd01bf0dffe87b679c4b440a1581d5c75',
  docsIndexName:
    process.env.REACT_APP_ENV === 'prod'
      ? 'live_DOCS'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'dev_DOCS'
      : process.env.REACT_APP_ENV === 'exp'
      ? 'exp_DOCS'
      : //local
        'local_DOCS',
  sessionsIndexName:
    process.env.REACT_APP_ENV === 'prod'
      ? 'live_SESSIONS'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'dev_SESSIONS'
      : process.env.REACT_APP_ENV === 'exp'
      ? 'exp_SESSIONS'
      : //local
        'local_SESSIONS',
  templatesIndexName:
    process.env.REACT_APP_ENV === 'prod'
      ? 'live_TEMPLATES'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'dev_TEMPLATES'
      : process.env.REACT_APP_ENV === 'exp'
      ? 'exp_TEMPLATES'
      : //local
        'local_TEMPLATES',
};

export const themeClassName =
  process.env.REACT_APP_ENV === 'dev'
    ? 'env-dev'
    : process.env.REACT_APP_ENV === 'exp'
    ? 'env-exp'
    : ''; // no class needed for the production

export const stage =
  process.env.REACT_APP_ENV === 'prod'
    ? //wap-live
      'production'
    : process.env.REACT_APP_ENV === 'dev'
    ? //wap-dev
      'development'
    : process.env.REACT_APP_ENV === 'exp'
    ? //wap-experimental
      'experimental'
    : //local
      'local';

export const firebaseRemoteConfigFetchIntervalMs =
  process.env.REACT_APP_ENV === 'local' ? 10000 : 12 * 3600 * 1000;

export const isLocalBuild = () =>
  !_.includes(['exp', 'dev', 'prod'], process.env.REACT_APP_ENV);
