import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Session,
  SessionTemplate,
  OrderedActivities,
  RoleBar,
} from 'types/types';
import { Nullable } from 'types/typesUtils';
import { getCriterias } from 'model/criteriaManagement';
import { formatTime } from 'utils/utils';

import ActivityDetails from '../blocks/ActivityDetails';
import ActivityUserDetails from '../blocks/ActivityUserDetails';

import ListItem from 'components/ListItem';
import Button from 'components/Button';

import { Clock, Puzzle } from 'assets/icons';

export type SessionPlanViewProps = {
  sessionId: string;
  role: RoleBar;
  participantId?: string;
  userId?: string;
  title?: string;
  setProductions?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

const SessionPlanView = ({
  sessionId,
  role,
  participantId,
  userId,
  title,
  setProductions,
}: SessionPlanViewProps): JSX.Element => {
  const { t } = useTranslation();

  const readOnly = !(role === 'organizer' || role === 'superadmin');

  const loadArray = sessionId
    ? [
        {
          path: `/sessionsNext/${sessionId}`,
        },
        {
          path: `/sessionsNextTemplates/${sessionId}`,
        },
      ]
    : [];
  useFirebaseConnect(loadArray);

  const sessionTemplate: Nullable<SessionTemplate> = useSelector(
    (state: any) =>
      sessionId && state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const activities = sessionTemplate?.activities;

  const orderedActivities: OrderedActivities = useMemo(() => {
    return _.sortBy(Object.values(activities || {}), 'index').filter(
      (activity) => activity.productions
    );
  }, [activities]);

  const activitiesNames = orderedActivities.reduce<Record<string, string>>(
    (current, activity) => {
      current[activity.name] = activity.humanName;
      return current;
    },
    {}
  );

  const criteriaNames = orderedActivities.reduce<Record<string, string>>(
    (current, activity) => {
      const criteria = getCriterias(activity);
      Object.values(criteria).forEach((production) => {
        if (production.name && production.description) {
          current[production.name] = production.description;
        }
      });
      return current;
    },
    {}
  );

  const session: Session | null = useSelector(
    (state: any) => sessionId && state.firebase.data.sessionsNext?.[sessionId]
  );
  return (
    <>
      {title ? (
        <div className="mb-4 flex flex-row items-center">
          <h1 className="flex-grow text-3xl font-semibold">{title}</h1>
          <Button
            className="print:hidden"
            size="md"
            text={t('common:print')}
            onClick={() => {
              setTimeout(window.print, 100);
            }}
          />
        </div>
      ) : null}
      <div className="space-y-8">
        {!participantId && session?.description ? (
          <div className="space-y-2">
            <h2 className="text-xl font-semibold capitalize">
              {t('common:Description')}
            </h2>
            <p className="whitespace-pre-line text-base font-light">
              {session?.description}
            </p>
          </div>
        ) : null}

        {!participantId && session?.goals && session.goals.length > 0 ? (
          <div className="space-y-2">
            <h2 className="text-xl font-semibold">
              {t('sessions:goalsTitle')}
            </h2>
            <ListItem items={session?.goals || []} />
          </div>
        ) : null}

        <div className="space-y-2">
          <h2 className="text-xl font-semibold">
            {t('sessions:emergenceQuestion')}
          </h2>
          <p className="whitespace-pre-line text-lg font-light">
            {
              sessionTemplate?.activities?.post?.screens?.create?.content
                .template.editableContent.question
            }
          </p>
        </div>
        <div className="space-y-2">
          <div className="flex flex-row items-center space-x-4">
            <h2 className="text-xl font-semibold">
              {participantId
                ? t('sessions:myProductions')
                : t('sessions:peerLearningPlan')}
            </h2>
            {!participantId ? (
              <div className="flex flex-row items-center text-lg">
                <p>{'('}</p>
                <div className="flex flex-row space-x-2 text-black">
                  <div className="flex flex-row items-center space-x-1">
                    <Puzzle className="h-6 w-6 stroke-2" />
                    <p>{orderedActivities.length}</p>
                  </div>
                  <div className="flex flex-row items-center space-x-1">
                    <Clock className="h-6 w-6 stroke-2" />
                    <p>
                      {formatTime(
                        orderedActivities.reduce(
                          (duration, activity) =>
                            duration + (activity.duration || 0),
                          0
                        )
                      )}
                    </p>
                  </div>
                </div>
                <p>{')'}</p>
              </div>
            ) : null}
          </div>
          <div className="space-y-6">
            {orderedActivities.map((activity, index) =>
              participantId ? (
                <ActivityUserDetails
                  key={index}
                  participantId={participantId}
                  userId={userId}
                  sessionId={sessionId}
                  activity={activity}
                  index={index}
                  showPersonalDetails={
                    userId === participantId || role !== 'participant'
                  }
                  setActivityProduction={
                    setProductions
                      ? (value, visibility) =>
                          setProductions((state) => {
                            state[index] = {
                              infos: {
                                name: activity.humanName || activity.name,
                                visibility: visibility,
                              },
                              productions: value,
                            };
                            return state;
                          })
                      : undefined
                  }
                />
              ) : (
                <ActivityDetails
                  key={index}
                  readOnly={readOnly}
                  sessionId={sessionId}
                  activity={activity}
                  index={index}
                  activitiesNames={activitiesNames}
                  criteriaNames={criteriaNames}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionPlanView;
