import React, { Fragment } from 'react';
import { RadioGroup as Radio } from '@headlessui/react';

export type RadioItem<T> = {
  value: T;
  description: string;
};
export type RadioGroupProps<T> = {
  design?: 'classic' | 'wap';
  options: RadioItem<T>[];
  value?: T;
  setValue?: (value: T) => void;
  disabled?: boolean;
};

const RadioGroup = <T,>({
  design = 'classic',
  value,
  setValue = () => {},
  options,
  disabled = false,
}: RadioGroupProps<T>): JSX.Element => {
  return (
    <Radio
      value={value}
      onChange={setValue}
      className={'flex grow'}
      disabled={disabled}
    >
      <div className={'flex flex-row flex-wrap items-center'}>
        {options.map((option, index) => {
          return (
            <Radio.Option key={index} as={Fragment} value={option.value}>
              {({ checked }) =>
                design === 'classic' ? (
                  <div
                    className={`flex ${
                      disabled ? 'cursor-not-allowed' : ' cursor-pointer'
                    } flex-row items-center space-x-1 rounded-md p-1 hover:bg-primary-soft`}
                  >
                    <span
                      className={`relative h-5 w-5 rounded-full border border-primary bg-white selection:flex`}
                    >
                      {checked ? (
                        <span
                          className={`absolute inset-0 rounded-full border-2 border-white bg-primary `}
                        />
                      ) : null}
                    </span>
                    <p>{option.description}</p>
                  </div>
                ) : (
                  <div
                    className={`relative m-2 flex min-w-[80px] shrink-0 rounded-lg p-3 font-semibold ${
                      checked
                        ? disabled
                          ? 'bg-gray-300 text-gray-600'
                          : 'bg-primary-soft text-primary'
                        : disabled
                        ? 'bg-gray-100 text-gray-400'
                        : 'bg-gray-200 text-gray-500'
                    } ${disabled ? 'cursor-not-allowed' : ' cursor-pointer'}`}
                  >
                    <p className="w-full text-center">{option.description}</p>
                  </div>
                )
              }
            </Radio.Option>
          );
        })}
      </div>
    </Radio>
  );
};

export default RadioGroup;
