/* eslint-disable react/no-deprecated */
//dependency libs
import React, { useEffect, useState, useRef, Suspense } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import DocumentTitle from 'components/DocumentTitle';

//css
import './App.less';

//conf
import { auth } from 'services/firebase';
import { LOGIN_PATH } from 'constants/AppConfig';
import history from 'utils/history';
import { themeClassName } from './config';

// components
import PrivateRoute from 'componentsOld/PrivateRoute';

// screens
import Authentication from 'screens/login/Authentication';
import SignIn from 'screens/login/SignIn';
import SignUp from 'screens/login/SignUp';
import ForgetPassword from 'screens/login/ForgetPassword';
import ResetPassword from 'screens/login/ResetPassword';
import AppAdminScreen from './screens/AppAdminScreen';
import SessionGuard from './screens/SessionScreen/SessionGuard';
import ProfileScreen from 'screens/ProfileScreen';
import NewTemplateScreen from 'screens/TemplateScreen/NewTemplateScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import FastOnboardScreen from 'screens/login/FastOnboardScreen';

import { identify } from 'services/helpChat';
import { identify as FSidentify } from 'services/fullstory';
import remoteConfig from 'services/remoteConfig';

import ActivityManager from 'services/activityManager';

import {
  isGlobalAdmin,
  refreshCurrentEmail,
  setUserLastActive,
} from 'model/users';
import LoadingScreen from 'screens/LoadingScreen';
import CommunityDashboard from 'screens/CommunityScreen/CommunityDashboard';

import { AlertProvider } from 'contexts/Alerts';

import { ModalProvider } from 'contexts/GlobalModal';
import { Maintenance } from 'assets/undraw';
import ConfidentialBarrier from 'ConfidentialBarrier';
import NewSessionprocessing from 'screens/SessionScreen/NewSessionProcessingScreen';
import {
  UserContextProvider,
  useUserContextWithDefaults,
} from 'contexts/UserContext';

const HomePage = () => {
  const { userType } = useUserContextWithDefaults();

  return isGlobalAdmin(userType) ? (
    <Redirect to={'/admin/all_sessions'} />
  ) : (
    <Redirect
      to={{
        pathname: `/home`,
      }}
    />
  );
};

const AppAdminPage = () => {
  const { userId, userName, userEmail, userType } =
    useUserContextWithDefaults();
  return (
    <DocumentTitle title="Admin">
      <AppAdminScreen
        userId={userId}
        userName={userName}
        userEmail={userEmail}
        userType={userType}
      />
    </DocumentTitle>
  );
};

const App = () => {
  const activityManager: any = useRef(null);
  const [userReady, setUserReady] = useState(false);
  const [isBootstrapped, setIsBootstrapped] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  useFirebaseConnect(['app/inMaintenance']);

  const appInMaintenance = useSelector(
    ({ firebase: { data } }: any) => data.app?.inMaintenance
  );

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        // do something here if needed with conf
        setIsBootstrapped(true);
      })
      .catch((err) => {
        console.error(err);
        setIsBootstrapped(true);
      });
  }, []);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      if (activityManager.current) {
        activityManager.current.destroy();
        activityManager.current = null;
      }

      let userUid = user?.uid;
      if (userUid) {
        activityManager.current = new ActivityManager();

        refreshCurrentEmail(userUid).catch((e) => console.error(e));
        identify();
        FSidentify();

        activityManager.current.heartCallback = async () => {
          try {
            if (userUid) {
              await setUserLastActive(userUid);
            }
          } catch (e) {
            console.error(e);
          }
        };
        setUserLastActive(userUid);
        setUserId(userUid);
      }
      setUserReady(true);
    });
  }, []);

  if (appInMaintenance)
    return (
      <div className="flex h-screen w-full px-4">
        <div className="m-auto flex max-w-3xl flex-col items-center space-y-8">
          <p className="text-center text-2xl">
            Wap.live n'est pas disponnible pour le moment, une maintenance est
            en cours.
          </p>
          <p className="text-2xl font-semibold">Promis on fait vite !</p>
          <Maintenance className="w-full fill-current text-primary sm:w-80 lg:w-96" />
          <p className="text-center text-2xl">
            Unfortunately Wap.live is down for a maintenance right now.
          </p>
          <p className="text-2xl font-semibold">We'll be back soon !</p>
        </div>
      </div>
    );

  return userReady && isBootstrapped && isLoaded(appInMaintenance) ? (
    <div id="App" className={`h-screen w-full bg-white ${themeClassName}`}>
      <DocumentTitle title="WAP | We Are Peers">
        <Suspense fallback={<LoadingScreen />}>
          <AlertProvider>
            <UserContextProvider userId={userId}>
              <ConfidentialBarrier>
                <Router history={history}>
                  <ModalProvider userId={userId || ''}>
                    <Switch>
                      <PrivateRoute exact path="/">
                        <HomePage />
                      </PrivateRoute>
                      <Route
                        exact
                        path="/onboard"
                        render={() => <FastOnboardScreen />}
                      />
                      <Route
                        exact
                        path="/auth"
                        render={() => <Authentication />}
                      />
                      <Route path={LOGIN_PATH} render={() => <SignIn />} />
                      <Route
                        exact
                        path={'/sign-up'}
                        render={() => <SignUp />}
                      />
                      <Route
                        exact
                        path={'/forget-password'}
                        render={() => <ForgetPassword />}
                      />
                      <Route
                        exact
                        path={'/reset-password'}
                        render={() => <ResetPassword />}
                      />

                      <PrivateRoute
                        path="/profile/:key"
                        hasRight={({ userId, params }) => params.key === userId}
                      >
                        <ProfileScreen />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={['/home', '/sessions', '/communities']}
                      >
                        <HomeScreen />
                      </PrivateRoute>
                      {/* <Route
                        path="/content"
                        render={(routeProps) => (
                          <ContentShareScreen
                            {...routeProps}
                            userId={userId || ''}
                          />
                        )}
                      /> */}
                      <PrivateRoute path="/admin" hasRight={() => false}>
                        <AppAdminPage />
                      </PrivateRoute>
                      {/* <Route
                      path="/share/:sessionId"
                      render={(routeProps) => (
                        <ContentShareScreen
                          {...routeProps}
                          userId={userId || ''}
                        />
                      )}
                    /> */}
                      <PrivateRoute
                        path="/community/:communityId"
                        hasRight={({ communitiesIds, params }) =>
                          !!communitiesIds[params['communityId']]
                        }
                      >
                        <CommunityDashboard />
                      </PrivateRoute>
                      <PrivateRoute
                        path="/template/:accessCode"
                        hasRight={() => false}
                      >
                        <NewTemplateScreen />
                      </PrivateRoute>
                      <PrivateRoute exact path="/new/session">
                        <NewSessionprocessing userId={userId || ''} />
                      </PrivateRoute>
                      <PrivateRoute path="/:accessCode">
                        <DocumentTitle title={`Live`}>
                          <SessionGuard />
                        </DocumentTitle>
                      </PrivateRoute>
                    </Switch>
                  </ModalProvider>
                </Router>
              </ConfidentialBarrier>
            </UserContextProvider>
          </AlertProvider>
        </Suspense>
      </DocumentTitle>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default App;
